import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { PageHeader } from 'shared';
import SortControls from './SortControls';

import { reducerUtil } from 'base-client';
import { actions as listActions, reducerData as listData } from 'leadsTwo';
import SearchBar from './SearchBar';
import ListControls from './ListControls';
import BulkControls from './BulkControls';

const Container = styled.div``;

const StyledPageHeader = styled(PageHeader)`
  margin-bottom: 0rem;
  justify-content: space-between;
`;

const UserHeader = styled.div`
  display: flex;
  width: 80%;
  justify-content: space-between;
`;

const HeaderControls = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const HeaderControl = styled.div`
  margin-right: 1rem;
`;

const SearchBarContainer = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  width: 50%;
`;

const Header = ({ grid, selected, query, meta, dispatch, ...props }) => {
  const { sortBy, sortDir } = query || {};
  const selectedCount = selected.length || 0;
  const enable = selectedCount > 0;
  const hitCount = meta ? meta.totalHits : 0;
  const { listMode, setListMode, optionKeys, optionIcons } = props;

  const setSortBy = sorter => dispatch(listActions.setSort(sorter));

  return (
    <Container className="container insights">
      <StyledPageHeader
        title="Contacts"
        subtitle={
          selectedCount ? `${hitCount} Found (${selectedCount} Selected)` : `${hitCount} Found`
        }
      >
        <UserHeader>
          <SearchBarContainer>
            <SearchBar />
          </SearchBarContainer>
          <HeaderControls>
            {!grid && <BulkControls {...{ enable }} />}
            <HeaderControl>
              <ListControls {...{ listMode, setListMode, optionKeys, optionIcons }} />
            </HeaderControl>
            <SortControls {...{ setSortBy, sortBy, sortDir }} isButton />
          </HeaderControls>
        </UserHeader>
      </StyledPageHeader>
    </Container>
  );
};

Header.propTypes = {
  selected: PropTypes.array
};

Header.defaultProps = {
  selected: []
};

const mapStateToProps = state => ({
  query: reducerUtil.getSlice(listData, listData.query, state),
  selected: reducerUtil.getSlice(listData, listData.selected, state) || [],
  meta: reducerUtil.getSlice(listData, listData.meta, state)
});

export default connect(mapStateToProps)(Header);
