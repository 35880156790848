import React from 'react';
import PropTypes from 'prop-types';

import Header from './Header';
import Content from './Content';
import NewUser from '../modals/NewUser';
import EditUser from '../modals/EditUser';

const UserAccounts = ({
  headerHeight, noRowsRenderer, overscanRowCount, rowCount, rowGetter, rowHeight,
  rowRenderer, usersCount, clickOpenNewUser, canAddUser,
}) => (
  <div>
    <Header {...{ usersCount, clickOpenNewUser, canAddUser }} />
    <Content
      headerHeight={headerHeight}
      noRowsRenderer={noRowsRenderer}
      overscanRowCount={overscanRowCount}
      rowCount={rowCount}
      rowGetter={rowGetter}
      rowHeight={rowHeight}
      rowRenderer={rowRenderer}
    />
    {canAddUser && <NewUser />}
    <EditUser />
  </div>
);

UserAccounts.propTypes = {
  headerHeight: PropTypes.number.isRequired,
  noRowsRenderer: PropTypes.func.isRequired,
  overscanRowCount: PropTypes.number.isRequired,
  rowCount: PropTypes.number.isRequired,
  rowGetter: PropTypes.func.isRequired,
  rowHeight: PropTypes.number.isRequired,
  rowRenderer: PropTypes.func.isRequired,
  usersCount: PropTypes.number.isRequired,
  clickOpenNewUser: PropTypes.func.isRequired,
  canAddUser: PropTypes.bool,
};

UserAccounts.defaultProps = {
  canAddUser: false,
};

export default UserAccounts;
