import React from 'react';
import { FormGroup, ButtonToolbar } from 'react-bootstrap';
import PropTypes from 'prop-types';

import Card from 'shared/PageLayout/Card/index';
import Button from 'shared/Buttons/index';

const Controls = ({ type, id, ...props }) => {
  const renderControls = (controlType, { cancelJob, ...controlProps }) => {
    switch (controlType) {
      case 'failure':
        return (
          <ButtonToolbar>
            <Button {...controlProps} buttonStyle="blue">
              Retry Action
            </Button>
            <Button {...controlProps} buttonStyle="link">
              Dismiss
            </Button>
            <Button {...controlProps} buttonStyle="link">
              View in Queue
            </Button>
          </ButtonToolbar>
        );
      default:
        return (
          <ButtonToolbar key={id}>
            <Button onClick={() => cancelJob(id)} buttonStyle="red">{`Cancel ${type}`}</Button>
          </ButtonToolbar>
        );
    }
  };

  return (
    <FormGroup className="JobQueueControls">
      <ButtonToolbar>{renderControls(type, props)}</ButtonToolbar>
    </FormGroup>
  );
};

Controls.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
};

const Info = ({ title, description, type, ...props }) => (
  <div className="job-queue-info">
    <h4 className="mb-1">{title}</h4>
    <p>{description}</p>
    <Controls {...{ type, ...props }} />
  </div>
);

Info.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
};

const Meta = ({ startTime, startTimeTitle, timeRemaining }) => (
  <div className="job-queue-meta">
    <div className="meta-item">
      <p className="meta-item__header">{startTimeTitle}</p>
      <p className="meta-item__content meta-item__content--flush-left">{startTime}</p>
    </div>

    <div className="meta-item">
      <p className="meta-item__header">Time Remaining</p>
      <p className="meta-item__content meta-item__content--flush-left">~{timeRemaining}</p>
    </div>
  </div>
);

Meta.propTypes = {
  startTime: PropTypes.string.isRequired,
  startTimeTitle: PropTypes.string.isRequired,
  timeRemaining: PropTypes.number.isRequired
};

const JobQueueCard = ({
  startTime,
  startTimeTitle,
  timeRemaining,
  title,
  description,
  type,
  id,
  ...props
}) => (
  <Card>
    <div className="job-queue-summary">
      <Info {...{ title, description, type, id, ...props }} />
      {/* { (type === 'publish' || type === 'update') && <Meta {...{ startTime, startTimeTitle, timeRemaining }} />} */}
    </div>
  </Card>
);

JobQueueCard.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  startTime: PropTypes.string,
  startTimeTitle: PropTypes.string,
  timeRemaining: PropTypes.number,
  timeRemainingTitle: PropTypes.string,
  type: PropTypes.string.isRequired
};

JobQueueCard.defaultProps = {
  startTime: null,
  startTimeTitle: null,
  timeRemaining: null,
  timeRemainingTitle: null
};

export default JobQueueCard;
