import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from './../SvgIcon';

const StarOutlineIcon = (props) => (
  <SvgIcon {...props}>
		<path d="M18,22.001c-0.193,0-0.387-0.056-0.555-0.168L12,18.203l-5.445,3.63 c-0.348,0.232-0.805,0.224-1.145-0.024c-0.338-0.247-0.486-0.679-0.371-1.082l1.838-6.435L2.293,9.708 c-0.286-0.285-0.371-0.716-0.217-1.09C2.23,8.245,2.596,8.001,3,8.001h5.382l2.724-5.447c0.339-0.677,1.45-0.677,1.789,0 l2.724,5.447H21c0.404,0,0.77,0.244,0.924,0.617c0.154,0.374,0.069,0.805-0.217,1.09l-4.584,4.584l1.838,6.435 c0.115,0.403-0.033,0.835-0.371,1.082C18.414,21.937,18.207,22.001,18,22.001z M12,16.001c0.193,0,0.387,0.057,0.555,0.168 l3.736,2.491l-1.252-4.384c-0.101-0.35-0.003-0.726,0.254-0.982l3.293-3.293H15c-0.379,0-0.725-0.214-0.895-0.553L12,5.237 L9.895,9.448C9.725,9.787,9.379,10.001,9,10.001H5.414l3.293,3.293c0.257,0.257,0.354,0.633,0.254,0.982L7.709,18.66l3.736-2.491 C11.613,16.058,11.807,16.001,12,16.001z"/>
	</SvgIcon>
)

StarOutlineIcon.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  viewBox: PropTypes.string.isRequired,
};

StarOutlineIcon.defaultProps = {
  width: 24,
  height: 24,
  viewBox: '0 0 24 24',
};

export default StarOutlineIcon;