import React from 'react';
import Stats from './StatsContainer';
import Map from './Map';

const Snapshot = props => (
  <div className="insights-snapshot">
    <Stats {...props} />
    <Map {...props} />
  </div>
);

export default Snapshot;
