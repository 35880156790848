import { api, login as baseLogin, history, config } from 'base-client';
import { configMap } from 'configurations';
import { sendNotifications } from '../shared/Toast';

import { modalConfirmName, modalConfirmDisplay, openModal } from 'modal/modal';
import { actions as permissionsActions } from 'permissions';
import { removeServiceKeyAndTokenPairInLocalStorage } from 'utils/redirect/utils';

const tenantId = process.env.REACT_APP_TENANT_ID;

const login = (user, pass) => async dispatch => {
  const error = await dispatch(baseLogin.actions.userLogin(user, pass, { tenant_id: tenantId }));

  if (error) return error;

  dispatch(permissionsActions.getPermissions());
};

const googleLogin = () => dispatch => {
  dispatch(baseLogin.actions.googleLogin({ tenant_id: tenantId }));
};

const logout = () => dispatch => {
  removeServiceKeyAndTokenPairInLocalStorage();
  dispatch(baseLogin.actions.logOut());
  dispatch(permissionsActions.getPermissions());

  const { domain: redirectDomain } = dispatch(config.actions.getData(configMap.redirect.name));
  const { enable, paramName } = dispatch(config.actions.getData(configMap.logoutRedirect.name));

  if(enable) {
    const redirectTo = redirectDomain + '/logout';
    const returnTo = window.location.origin + '/logout';

    window.location.href = redirectTo + '?' + paramName + '=' + returnTo; 
  };
};

const resetPasswordLink = ({ hostname, origin, tenantId, user }) => async (dispatch, getState) => {
  return await dispatch(
    baseLogin.actions.changePassword({ hostname, origin, tenant_id: tenantId, username: user })
  );
};

const openLogout = () => (dispatch, getState) => {
  if (getState().modal[modalConfirmName]) return;
  dispatch(
    modalConfirmDisplay(
      displayLogout(
        () => {
          dispatch(openModal(modalConfirmName, false));
          dispatch(logout());
        },
        () => dispatch(openModal(modalConfirmName, false))
      )
    )
  );
  dispatch(openModal(modalConfirmName));
};

const displayLogout = (onConfirm, onCancel) => {
  const title = 'Confirm Logout';
  const text = 'Are you sure you want to log out?';
  return {
    title,
    text,
    buttonList: [
      {
        text: 'Logout',
        onClick: onConfirm
      },
      {
        text: 'Cancel',
        onClick: onCancel
      }
    ],
    noClose: false
  };
};

const changePassword = ({ auth0Id, password, username }) => async dispatch => {
  try {
    await dispatch(
      api.actions.post('users/password-change', { auth0Id, password, username }, true)
    );
    await dispatch(
      sendNotifications([
        {
          type: 'success',
          message: 'Password Reset Succesful!'
        }
      ])
    );
    setTimeout(function() {
      history.push('/login');
    }, 4000);
  } catch (err) {
    dispatch(
      sendNotifications([
        {
          type: 'error',
          message: 'Password Reset Failed'
        }
      ])
    );
  }
};

export default { changePassword, login, googleLogin, openLogout, resetPasswordLink, logout };
