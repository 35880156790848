export default [
  {
    name: 'Most Recent',
    sortBy: 'updated_at',
    sortDir: 'desc'
  },
  {
    name: 'Oldest',
    sortBy: 'updated_at',
    sortDir: 'asc'
  },
  {
    name: 'Project Name A-Z',
    sortBy: 'name',
    sortDir: 'asc'
  },
  {
    name: 'Project Name Z-A',
    sortBy: 'name',
    sortDir: 'desc'
  },
  {
    name: 'Lowest Budget',
    sortBy: 'project_budget',
    sortDir: 'asc'
  },
  {
    name: 'Highest Budget',
    sortBy: 'project_budget',
    sortDir: 'desc'
  },
  {
    name: 'Phase A-Z',
    sortBy: 'project_phase',
    sortDir: 'asc'
  },
  {
    name: 'Phase Z-A',
    sortBy: 'project_phase',
    sortDir: 'desc'
  },
  {
    name: 'Submitter A-Z',
    sortBy: 'user.firstName',
    sortDir: 'asc'
  },
  {
    name: 'Submitter Z-A',
    sortBy: 'user.firstName',
    sortDir: 'desc'
  },
  {
    name: 'Company A-Z',
    sortBy: 'user.profile.companyName',
    sortDir: 'asc'
  },
  {
    name: 'Company Z-A',
    sortBy: 'user.profile.companyName',
    sortDir: 'desc'
  }
];
