import React from 'react';
import PropTypes from 'prop-types';
import { DownArrowIcon, UpArrowIcon } from 'shared/Miscellaneous/Icons';

import '../assets/AnalyticsCard.scss';

const AnalyticsCard = ({ title, subtitle, data, days }) => {
  const { count, change, trend } = data;
  const percent = change && Math.round(change * 100);

  return (
    <div className="analytic-card text-center">
      <div className="card-title">{title}</div>
      <div className="card-content">
        <span className="aggregate-value">{count || '0'}</span>
        {!change ? null : (
          <div className="trend">
            <span className={trend}>{trend === 'up' ? <UpArrowIcon /> : <DownArrowIcon />}</span>
            <span className={'trend-value ' + trend}> {`${percent || 0}%`}</span>
          </div>
        )}
      </div>
      <div className="card-footer">
        <div>{subtitle || `Unique ${title}`}</div>
        {!change ? null : (
          <div className="card-date-range">{`(% change over last ${days} days)`}</div>
        )}
      </div>
    </div>
  );
};

AnalyticsCard.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  data: PropTypes.shape({
    count: PropTypes.number,
    change: PropTypes.number,
    trend: PropTypes.oneOf(['up', 'down'])
  }),
  days: PropTypes.number
};

AnalyticsCard.defaultProps = {
  data: {}
};

export default AnalyticsCard;
