import React from 'react';
import PropTypes from 'prop-types';

const Header = ({ children }) => <h4 className="mt-2">{children}</h4>;

Header.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
    PropTypes.string
  ])
};

export default Header;
