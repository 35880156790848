import React from 'react';
import PropTypes from 'prop-types';
import { SortDirection, SortIndicator } from 'react-virtualized';
import { connect } from 'react-redux';
import moment from 'moment';

import { reducerUtil } from 'base-client';

import LeadTableView from './LeadTableView';
import { reducerData as leadsData, actions as leadActions } from 'leads';
import { reducerData as permissionsData } from 'permissions';

const compare = key => (a, b) => {
  const x = a[key];
  const y = b[key];
  if (key === 'lastEvent') {
    const xDate = moment(x);
    if (xDate.isBefore(y)) return -1;
    if (xDate.isAfter(y)) return 1;
    return 0;
  }
  if (x.toLowerCase && y.toLowerCase) {
    return x.toLowerCase().localeCompare(y.toLowerCase());
  }
  if (x < y) return -1;
  if (x > y) return 1;
  return 0;
};

const leadTableColumns = [
  { label: 'Name', dataKey: 'name' },
  { label: 'Occupation', dataKey: 'occupation' },
  { label: 'Company', dataKey: 'company' },
  { label: 'Location', dataKey: 'location' },
  { label: 'Event Count', dataKey: 'eventCount' },
  { label: 'Last Event', dataKey: 'lastEvent' }
];

class LeadsTable extends React.Component {
  constructor(props) {
    super(props);
    const sortBy = 'eventCount';
    const sortDirection = SortDirection.DESC;
    const sortedList = this.sortList({ sortBy, sortDirection });

    this.state = {
      sortBy,
      sortDirection,
      sortedList
    };

    this.sort = this.sort.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { table } = this.props;
    const { sortBy, sortDirection } = this.state;
    if (table !== prevProps.table) {
      // not using prevState so OK to use setState
      const sortedList = this.sortList({ sortBy, sortDirection });
      this.setState({ sortedList }); // eslint-disable-line react/no-did-update-set-state
    }
  }

  sort({ sortBy, sortDirection }) {
    const sortedList = this.sortList({ sortBy, sortDirection });
    this.setState({ sortBy, sortDirection, sortedList });
  }

  sortList({ sortBy, sortDirection }) {
    const { table } = this.props;
    let sortedList = JSON.parse(JSON.stringify(table)); // deep clone
    sortedList = sortedList.sort(compare(sortBy));
    if (sortDirection === SortDirection.DESC) sortedList.reverse();
    return sortedList;
  }

  render() {
    const { sortedList, ...state } = this.state;
    const { canView, ...props } = this.props;
    if (!canView) return null;
    return (
      <LeadTableView
        {...props}
        {...state}
        table={sortedList}
        tableColumns={leadTableColumns}
        sort={this.sort}
        title="Leads"
        headerBorder
      />
    );
  }
}

LeadsTable.propTypes = {
  canView: PropTypes.bool,
  productKey: PropTypes.string,
  table: PropTypes.array,
  isInternalHeader: PropTypes.bool,
  optionsStream: PropTypes.array
};

LeadsTable.defaultProps = {
  canView: false,
  productKey: null,
  table: [],
  isInternalHeader: false,
  optionsStream: undefined
};

const mapStateToProps = state => {
  const leadsTable = reducerUtil.getSlice(leadsData, leadsData.leadList, state) || [];
  const canView = reducerUtil.getSlice(permissionsData, permissionsData.leads, state);
  const leadList = reducerUtil.getSlice(leadsData, leadsData.leadList, state) || {};
  const { list: table = [] } = leadList;
  return {
    table,
    canView,
    leadsTable
  };
};

const mapDispatchToProps = dispatch => ({
  onSelect: ({ stream, interval, productKey }) => {
    dispatch(leadActions.getLeadList(stream, interval, productKey));
  },
  onDownloadClick: () => dispatch(leadActions.downloadLeadList())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LeadsTable);
