/* vendor imports */
import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

/* system imports */
import SelectField from './SelectField';
import { distForm } from 'productAttributes/utils';

const Type = ({ attributeTypes, ...props }) => (
  <div className="form__attribute-unit">
    <div className="form__attribute-title">Select a Type</div>
    <div className="form__attribute-line">
      <Field name={distForm.type} component={SelectField} options={attributeTypes} {...props} />
    </div>
  </div>
);

Type.propTypes = {
  attributeTypes: PropTypes.array.isRequired
};

export default Type;
