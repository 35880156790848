import { toTitleCase } from 'utils/miscUtils';
import timeUtils from 'utils/timeUtils';

const allGroup = 'All';
const form = {
  name: 'productForm',
  system: 'system',
  product: 'product',
  sustainability: 'sustainability'
};
const systemNames = {
  title: 'productName',
  image: 'productThumbnail',
  manufacturer: 'manufacturer',
  category: 'productCategory',
  description: 'productDescription',
  revitFile: 'revitFile',
  revitImage: 'revitFileThumbnail',
  revitCatalog: 'revitTypeCatalog',
  revitTable: 'revitLookupTable',
  revitIes: 'revitIESProfile'
};
const statusNames = {
  created: 'createdAt',
  updated: 'updatedAt',
  published: 'publishedAt'
};
const attributeTypeNames = {
  assetList: 'assetselection',
  list: 'list',
  unit: 'number',
  textArea: 'multilinetext',
  dateTime: 'datetime',
  check: 'checkbox'
};
const noAsset = { name: 'None', filename: 'None', id: '' };

const fixName = (name = '') => {
  if (!name.toLowerCase().match(/pg_/g)) return name;
  return toTitleCase(
    name
      .toLowerCase()
      .replace(/pg_/g, '')
      .replace(/_/g, ' ')
  );
};

const formatTime = (value, eventText) => {
  if (!value) return `Not yet ${eventText}`;
  if (timeUtils.isToday(value)) {
    return timeUtils.formatAs(timeUtils.formats.PRODUCT_ACTION_TIME, new Date(value));
  } else {
    return timeUtils.formatAs(timeUtils.formats.NUMBER, new Date(value));
  }
};

const modalNames = {
  unsaved: 'unsaved',
  discard: 'discard',
  saveStart: 'saveStart',
  saveFail: 'saveFail',
  saveSuccess: 'saveSuccess',
  publishStart: 'publishStart',
  publishFail: 'publishFail',
  publishSuccess: 'publishSuccess',
  deleteStart: 'deleteStart',
  deleteFail: 'deleteFail',
  deleteSuccess: 'deleteSuccess',
  downloadUpdate: 'downloadUpdate',
  downloadFail: 'downloadFail',
  downloadUpdateFail: 'downloadUpdateFail',
  importRevitConfirmation: 'importRevitConfirmation',
  updateRevitConfirmation: 'updateRevitConfirmation',
  removeElement: 'removeElement'
};

const revitExtensions = ['rfa', 'rvt', 'rte'];
const catelogExtensions = ['txt'];
const tableExtensions = ['mep', 'csv'];
const iesExtensions = ['ies'];

const getFileSize = size => {
  if (size < 1000) return `${size} B`;
  const kbSize = size / 1000;
  if (kbSize < 1000) return `${Math.round(kbSize * 100) / 100} KB`;
  return `${Math.round((kbSize / 1000) * 100) / 100} MB`;
};

const sustainabilityGroups = [
  'Certificates & Documents',
  'Environmental Characteristics',
  'Rating Systems'
];

const sustainabilityModalLabels = {
  characteristics: {
    columnLabels: ['name', 'type'],
    addButtonLabel: 'Add Environmental Characteristic',
    modalTitle: 'Add Environmental Characteristics',
    modalButtonLabel: 'Add Selected Attributes'
  },
  certificates: {
    columnLabels: ['name'],
    addButtonLabel: 'Add Certificates & Documents',
    modalTitle: 'Add Certifications',
    modalButtonLabel: 'Add Selected Certifications'
  }
};

export default {
  allGroup,
  form,
  noAsset,
  fixName,
  systemNames,
  statusNames,
  formatTime,
  attributeTypeNames,
  modalNames,
  revitExtensions,
  catelogExtensions,
  tableExtensions,
  iesExtensions,
  getFileSize,
  sustainabilityGroups,
  sustainabilityModalLabels
};
