import React from 'react';
import PropTypes from 'prop-types';

import './assets/radio-group.scss';

const RadioGroupRow = ({ children }) => (
  <div className='controls'>
    {children}
  </div>
);

RadioGroupRow.propTypes = {
  children: PropTypes.node.isRequired,
};

export default RadioGroupRow;
