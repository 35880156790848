import React from 'react';
import { connect } from 'react-redux';
import { reducerUtil } from 'base-client';

import DocumentListItem from './DocumentListItem';
import ProjectSectionHeader from './ProjectSectionHeader';
import { reducerData as detailsData } from 'projectDetails';

const SubmittalList = ({ submittals, brochures }) => {
  if (!submittals && !brochures) return null;

  return (
    <React.Fragment>
      <ProjectSectionHeader title="Documents Created" />
      {submittals && submittals.map(submittal => <DocumentListItem submittal={submittal} />)}
      {brochures && brochures.map(brochure => <DocumentListItem brochure={brochure} />)}
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  submittals: reducerUtil.getSlice(detailsData, detailsData.submittals, state),
  brochures: reducerUtil.getSlice(detailsData, detailsData.brochures, state)
});

export default connect(mapStateToProps)(SubmittalList);
