import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from './../SvgIcon';

const AddBoxIcon = (props) => (
  <SvgIcon {...props}>
		<path d="M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z M17,13h-4v4h-2v-4H7v-2h4V7h2v4h4V13z"/>
  </SvgIcon>
)

AddBoxIcon.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  viewBox: PropTypes.string.isRequired,
};

AddBoxIcon.defaultProps = {
  width: 24,
  height: 24,
  viewBox: '0 0 24 24',
};

export default AddBoxIcon;