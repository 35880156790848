import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { reducerUtil } from 'base-client';
import moment from 'moment';

import AnalyticsCard from './AnalyticsCard';
import { reducerData as analyticsData } from 'analyticsDashboard';
import { analyzers } from 'analyticsDashboard/utils';
import { interval } from 'utils/datePickerUtil';

const Container = styled.div`
  padding: 2%;
  display: flex;
  flex-wrap: wrap;
  .analytic-card {
    margin-right: 2%;
    &:nth-child(2n) {
      margin-right: 32%;
    }
  }
`;

const TrafficTabContent = ({ data, query }) => {
  const { dateRange } = query;
  const { start, end } = dateRange || {
    start: interval.initial.startDate.toISOString(),
    end: interval.initial.endDate.toISOString()
  };

  const days = start && end && moment(end).diff(moment(start), 'days') + 1;

  return (
    <Container>
      <AnalyticsCard title="Visitors" data={data[analyzers.visitors]} {...{ days }} />
      <AnalyticsCard title="Sessions" data={data[analyzers.sessions]} {...{ days }} />
      <AnalyticsCard
        title="Loyalty"
        subtitle="Repeat Visitors"
        data={data[analyzers.loyalty]}
        {...{ days }}
      />
      <AnalyticsCard
        title="Conversion"
        subtitle="Downloads per Visitor"
        data={data.conversion}
        {...{ days }}
      />
    </Container>
  );
};

TrafficTabContent.propTypes = {
  data: PropTypes.object,
  query: PropTypes.object
};

TrafficTabContent.defaultProps = {
  data: {},
  query: {}
};

const mapStateToProps = state => ({
  data: reducerUtil.getSlice(analyticsData, analyticsData.data, state),
  query: reducerUtil.getSlice(analyticsData, analyticsData.query, state)
});

export default connect(mapStateToProps)(TrafficTabContent);
