import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import DebounceField from 'shared/DebounceField';
import { actions as listActions } from 'productList';
import { reducerUtil } from 'base-client';
import { reducerData as listData } from 'productList';
import { getlocationQuery } from 'utils/miscUtils';

const SearchField = ({ dispatch, ...props }) => {
  const callback = value => dispatch(listActions.setText(value));

  setTimeout(() => {
    const searchMeta = getlocationQuery();
    props.value = props.value || searchMeta.name || '';
  }, 100);
  return <DebounceField {...props} {...{ callback }} />;
};

SearchField.propTypes = {
  dispatch: PropTypes.func.isRequired,
  value: PropTypes.string
};

const mapStateToProps = state => {
  const { queryString: text } = reducerUtil.getSlice(listData, listData.query, state) || {};
  return {
    value: text
  };
};

export default connect(mapStateToProps)(SearchField);
