import React from 'react';
import styled from 'styled-components';

import { Container, Row, Column } from 'shared-features-client';
import sbManagerStyles from 'utils/globalStyles';

import ProjectInfoCard from './ProjectInfoCard';

const StyledProjectInfoDescription = styled(Row)`
  padding-top: 2rem;
  margin-bottom: 2rem;
`;

const Notes = styled.p`
  font-size: 1.4rem;
  color: ${sbManagerStyles.greyMedium};
`;

const ProjectInfoDescription = ({ ...generalData }) => {
  if (!generalData) return null;

  const { notes, project_phase, occupant_type, project_scope, owner_occupied } = generalData;

  return (
    <Container>
      <StyledProjectInfoDescription>
        <Column lg={6}>
          <Notes>{notes}</Notes>
        </Column>
        <Column lg={6}>
          <Row>
            <Column mdUp={6}>
              <ProjectInfoCard title="Phase" content={project_phase} defaultValue="-" />
              <ProjectInfoCard title="Scope" content={project_scope} defaultValue="-" />
            </Column>
            <Column mdUp={6}>
              <ProjectInfoCard title="Owner Occupied" content={owner_occupied ? 'Yes' : 'No'} />
            </Column>
          </Row>
        </Column>
      </StyledProjectInfoDescription>
    </Container>
  );
};

export default ProjectInfoDescription;
