import React from 'react';
import PropTypes from 'prop-types';

import './assets/panel-button.scss';

const PanelButtonIndex = ({
  onClick,
  name,
  text,
  helper,
}) => {
  return (
    <div className='panel-button' onClick={ onClick } >
      { name && <a href="#">{ name }</a> }
      { text && <a href="#">{ text }</a> }
      { helper && <p className="panel-button__helper-text">{ helper }</p> }
    </div>
  )
};

PanelButtonIndex.propTypes = {
  onClick: PropTypes.func.isRequired,
  name: PropTypes.string,
  text: PropTypes.string,
  helper: PropTypes.string,
};

export default PanelButtonIndex;
