/* vendor imports */
import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, ButtonToolbar } from 'react-bootstrap';

/* system imports */
import Button from 'shared/Buttons';
import Checkbox from 'shared/InputGroups/Checkbox';

const Controls = ({ hasKey, controls, disabled, isProduct, isRemove, allType, dirty }) => {
  if (isProduct) {
    return (
      <FormGroup className="AttributeContentControls">
        <div className="checkbox--inline mr-1">
          <Checkbox
            isChecked={allType}
            onChange={controls.changeFamily}
            size={18}
            viewBox="0 0 18 18"
            isLocked={!hasKey}
          />
          All Types
        </div>
        <ButtonToolbar>
          {!isRemove && (
            <Button buttonStyle="green" onClick={controls.addAttribute} disabled={!hasKey}>
              Add
            </Button>
          )}
          {isRemove && (
            <Button buttonStyle="red" onClick={controls.removeAttribute} disabled={!hasKey}>
              Remove
            </Button>
          )}
        </ButtonToolbar>
      </FormGroup>
    );
  }
  return (
    <FormGroup className="AttributeContentControls">
      <ButtonToolbar>
        <Button
          buttonStyle="green"
          onClick={controls.updateAttribute}
          disabled={disabled || !dirty}
        >
          {hasKey ? 'Save' : 'Create'}
        </Button>
        {hasKey && (
          <Button buttonStyle="red" onClick={controls.deleteAttribute} {...{ disabled }}>
            Delete
          </Button>
        )}
        <Button buttonStyle="link" onClick={controls.resetAttribute} disabled={disabled || !dirty}>
          Cancel
        </Button>
      </ButtonToolbar>
    </FormGroup>
  );
};

Controls.propTypes = {
  controls: PropTypes.shape({
    changeFamily: PropTypes.func.isRequired,
    addAttribute: PropTypes.func.isRequired,
    removeAttribute: PropTypes.func.isRequired,
    updateAttribute: PropTypes.func.isRequired,
    deleteAttribute: PropTypes.func.isRequired,
    resetAttribute: PropTypes.func.isRequired
  }).isRequired,
  hasKey: PropTypes.bool,
  disabled: PropTypes.bool,
  isProduct: PropTypes.bool,
  isRemove: PropTypes.bool,
  allType: PropTypes.bool,
  dirty: PropTypes.bool
};

Controls.defaultProps = {
  hasKey: false,
  disabled: false,
  isProduct: false,
  isRemove: false,
  allType: false,
  dirty: false
};

export default Controls;
