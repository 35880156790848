import { api, config, history, reducerUtil } from 'base-client';

import { reducerData as tenantData } from 'tenant';
import { reducerData as projectsData } from 'projects';

import { analyzers } from 'projects/utils';
import { toNumber, getlocationQuery } from 'utils';

import { defaultISOInterval } from 'shared-features-client';

const defaultQuery = {
  analyzers: Object.keys(analyzers).map(key => analyzers[key]),
  sort: {
    sortBy: 'date',
    sortDir: 'desc'
  },
  dateRange: {
    start: defaultISOInterval.startDate,
    end: defaultISOInterval.endDate
  },
  includePrevious: true
};

const getProjectsData = () => async (dispatch, getState) => {
  const state = getState();

  // get the querystring
  const queryPrams = getlocationQuery();

  //get tenant ID
  const tenantId = reducerUtil.getSlice(tenantData, tenantData.tenant, state);

  //merge query
  const apiParams = { ...defaultQuery, ...queryPrams, tenant_id: tenantId };
  try {
    const {
      current: { results: currentData = {}, availableFacets: meta },
      previous: { results: previousData = {} }
    } = await dispatch(api.actions.post('projects/analytics', JSON.stringify(apiParams), true));

    const data = {};

    data[analyzers.projectsUpdated] = getCardData(
      analyzers.projectsUpdated,
      currentData,
      previousData
    );

    data[analyzers.projectPhases] = getDonutChartData(
      analyzers.projectPhases,
      'project_phase',
      currentData
    );

    data[analyzers.averageBudget] = getCardData(analyzers.averageBudget, currentData, previousData);

    data[analyzers.averageSize] = getCardData(analyzers.averageSize, currentData, previousData);

    data[analyzers.highValueCustomers] = getCardData(
      analyzers.highValueCustomers,
      currentData,
      previousData
    );

    data[analyzers.topProjectTypes] = getDonutChartData(
      analyzers.topProjectTypes,
      'project_type',
      currentData
    );

    const { filters = [] } = queryPrams || {};
    const formattedMeta = !meta ? meta : formatMeta(meta, filters);

    dispatch(reducerUtil.setSlice(projectsData, projectsData.metaAnalytics, formattedMeta));
    dispatch(reducerUtil.setSlice(projectsData, projectsData.analyticsData, data));
    dispatch(reducerUtil.setSlice(projectsData, projectsData.query, queryPrams));
  } catch (error) {
    dispatch(config.actions.error(error));
  }
};

const getDonutChartData = (analyzer, fieldName, data = []) => {
  const filteredList = data[analyzer].filter(({ [fieldName]: name }) => name);
  const totalCount = filteredList.reduce((total, { count }) => total + toNumber(count), 0);

  return filteredList.map(({ count, [fieldName]: name }) => ({
    count: toNumber(count) / totalCount,
    name
  }));
};

const getCardData = (analyzer, current, previous) => {
  const currentTotal = toNumber(current[analyzer]);
  const previousTotal = toNumber(previous[analyzer]);
  return {
    count: Math.round(currentTotal * 100) / 100,
    change: previousTotal > 0 ? Math.abs(currentTotal - previousTotal) / previousTotal : undefined,
    trend: currentTotal >= previousTotal ? 'up' : 'down'
  };
};

const setQuery = newData => (dispatch, getState) => {
  const oldQuery = reducerUtil.getSlice(projectsData, projectsData.query, getState()) || {};
  const query = { ...oldQuery, ...newData };
  dispatch(reducerUtil.setSlice(projectsData, projectsData.query, query));
  history.push(`${history.location.pathname}?${encodeURIComponent(JSON.stringify(query))}`);
};

const setDate = ({ startDate, endDate }) => dispatch => {
  const dateRange = { start: startDate, end: endDate };
  dispatch(resetFilters());
  dispatch(setQuery({ dateRange }));
};

const formatMeta = (data, selected = []) => {
  if (!data) return data;

  const { country: location } = (data && data[0]) || {};
  const { facets: selectedLocation = [] } =
    selected.find(({ attribute }) => attribute === 'country') || {};

  const locationFacets = [];
  if (location) {
    [...location].forEach(item => {
      const value = item;
      const label = item;
      if (label && value) {
        locationFacets.push({ value, label });
      }
    });
  }

  const locations = {
    selected: selectedLocation,
    attribute: 'country',
    name: 'Location',
    facets: locationFacets
  };

  return { locations };
};

const updateFilter = ({ attribute, facets }) => (dispatch, getState) => {
  const query = reducerUtil.getSlice(projectsData, projectsData.query, getState()) || {};
  const { filters = [] } = query;

  let newFilters;
  if (!facets) {
    newFilters = filters.filter(({ attribute: name }) => name !== attribute);
  } else if (!filters) {
    newFilters = [{ attribute, facets: [facets] }];
  } else {
    const index = filters.findIndex(({ attribute: name }) => name === attribute);
    if (index < 0) {
      newFilters = [...filters, { attribute, facets: [facets] }];
    } else {
      newFilters = [...filters];
      newFilters[index] = { attribute, facets: [facets] };
    }
  }

  dispatch(setQuery({ filters: newFilters.length > 0 ? newFilters : undefined }));
};

const resetFilters = () => dispatch => dispatch(setQuery({ filters: undefined }));

const getProjects = () => async dispatch => {
  try {
    const projects = await dispatch(api.actions.get('projects', true));
    dispatch(reducerUtil.setSlice(projectsData, projectsData.projectList, projects));
  } catch (error) {
    dispatch(config.actions.error(error));
  }
};

export default {
  getProjectsData,
  getProjects,
  setDate,
  updateFilter,
  resetFilters
};
