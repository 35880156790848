import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../SvgIcon';

const ChevronLeftIcon = (props) => (
  <SvgIcon {...props}>
    <polygon points="9.1,1 0.1,10 9.1,19 10.9,17.1 3.8,10 10.9,2.9     "/>
  </SvgIcon>
)

ChevronLeftIcon.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  viewBox: PropTypes.string.isRequired,
};

ChevronLeftIcon.defaultProps = {
  width: 11,
  height: 20,
  viewBox: '0 0 11 20',
};

export default ChevronLeftIcon;
