/* vendor imports */
import React from "react";
import PropTypes from "prop-types";

/* system imports */
import Header from "./Header";
import Body from "./Body";
import ModalContainer from "../../Overlays/Modal";
import { modalName } from "../utils";

const CreateModal = ModalContainer(modalName);

const Component = ({ closeModal, toSelect, csvMode, ...bodyProps }) => (
  <CreateModal
    header={<Header {...{ toSelect, csvMode }} />}
    onClose={closeModal}
  >
    <Body {...bodyProps} {...{ csvMode }} />
  </CreateModal>
);

Component.propTypes = {
  closeModal: PropTypes.func.isRequired,
  toSelect: PropTypes.func,
  csvMode: PropTypes.bool
};

Component.defaultProps = {
  toSelect: undefined,
  csvMode: undefined
};

export default Component;
