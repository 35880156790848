/* vendor imports */
import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

/* system imports */

const Header = ({ title }) => (
  <div className="title-container">
    <Modal.Title componentClass="h3">{title}</Modal.Title>
  </div>
);

Header.propTypes = {
  title: PropTypes.string
};

Header.defaultProps = {
  title: 'Attribute Editor'
};

export default Header;
