const RevitParameterGroups = [
  {
    key: 'PG_ANALYSIS_RESULTS',
    display: 'Analysis Results'
  },
  {
    key: 'PG_AELECTRICAL',
    display: 'Electrical - Engineering'
  },
  {
    key: 'PG_MECHANICAL_AIRFLOW',
    display: 'Mechanical - Flow'
  },
  {
    key: 'PG_RELEASES_MEMBER_FORCES',
    display: 'Releases / Member Forces'
  },
  {
    key: 'PG_ANALYTICAL_ALIGNMENT',
    display: 'Analytics Alignment'
  },
  {
    key: 'PG_ENERGY_ANALYSIS',
    display: 'Energy Analysis'
  },
  {
    key: 'PG_MECHANICAL_LOADS',
    display: 'Mechanical - Loads'
  },
  {
    key: 'PG_SECONDARY_END',
    display: 'Secondary End'
  },
  {
    key: 'PG_CONSTRAINTS',
    display: 'Constraints'
  },
  {
    key: 'PG_FIRE_PROTECTION',
    display: 'Fire Protection'
  },
  {
    key: 'PG_ADSK_MODEL_PROPERTIES',
    display: 'Model Properties'
  },
  {
    key: 'PG_SEGMENTS_FITTINGS',
    display: 'Segments and Fittings'
  },
  {
    key: 'PG_CONSTRUCTION',
    display: 'Construction'
  },
  {
    key: 'PG_FORCES',
    display: 'Forces'
  },
  {
    key: 'PG_MOMENTS',
    display: 'Moments'
  },
  {
    key: 'PG_DATA',
    display: 'Data'
  },
  {
    key: 'PG_GENERAL',
    display: 'General'
  },
  {
    key: 'PG_SLAB_SHAPE_EDIT',
    display: 'Slab Shape Edit'
  },
  {
    key: 'PG_GEOMETRY',
    display: 'Dimensions'
  },
  {
    key: 'PG_GREEN_BUILDING',
    display: 'Green Building Properties'
  },
  {
    key: 'PG_OVERALL_LEGEND',
    display: 'Overall Legend'
  },
  {
    key: 'PG_STRUCTURAL',
    display: 'Structural'
  },
  {
    key: 'PG_DIVISION_GEOMETRY',
    display: 'Division Geometry'
  },
  {
    key: 'PG_IDENTITY_DATA',
    display: 'Identity Data'
  },
  {
    key: 'PG_PHASING',
    display: 'Phasing'
  },
  {
    key: 'PG_STRUCTURAL_ANALYSIS',
    display: 'Structural Analysis'
  },
  {
    key: 'PG_ELECTRICAL',
    display: 'Electrical'
  },
  {
    key: 'PG_IFC',
    display: 'IFC Parameters'
  },
  {
    key: 'PG_LIGHT_PHOTOMETRICS',
    display: 'Photometrics'
  },
  {
    key: 'PG_TEXT',
    display: 'Text'
  },
  {
    key: 'PG_ELECTRICAL_CIRCUITING',
    display: 'Electrical - Circuiting'
  },
  {
    key: 'PG_REBAR_SYSTEM_LAYERS',
    display: 'Layers'
  },
  {
    key: 'PG_PLUMBING',
    display: 'Plumbing'
  },
  {
    key: 'PG_TITLE',
    display: 'Title'
  },
  {
    key: 'PG_ELECTRICAL_LIGHTING',
    display: 'Electrical - Lighting'
  },
  {
    key: 'PG_MATERIALS',
    display: 'Materials and Finishes'
  },
  {
    key: 'PG_PRIMARY_END',
    display: 'Primary End'
  },
  {
    key: 'PG_VISIBILITY',
    display: 'Visibility'
  },
  {
    key: 'PG_ELECTRICAL_LOADS',
    display: 'Electrical - Loads'
  },
  {
    key: 'PG_MECHANICAL',
    display: 'Mechanical'
  },
  {
    key: 'PG_REBAR_ARRAY',
    display: 'Rebar Set'
  },
  {
    key: 'PG_GRAPHICS',
    display: 'Graphics'
  },
  {
    key: 'PG_MECHANICAL_AIRFLOW',
    display: 'Mechanical - Air Flow'
  },
  {
    key: 'INVALID',
    display: 'Other'
  }
];

export default RevitParameterGroups;
