import React from 'react';
import PropTypes from 'prop-types';

import { DropdownButton, DropdownMenuItem } from 'shared/Buttons';
import { DownArrowIcon, UpArrowIcon } from 'shared/Miscellaneous/Icons';
import { querySortBy } from 'leadsTwo/utils';

const sortOptions = [
  {
    label: 'Relevance',
    value: querySortBy.score
  },
  {
    label: 'Name',
    value: querySortBy.name
  },
  {
    label: 'Recent Activity',
    value: querySortBy.lastActivity
  },
  {
    label: 'Contact Stage',
    value: querySortBy.leadScore
  },
  {
    label: 'Industry',
    value: querySortBy.industry
  },
  {
    label: 'Occupation',
    value: querySortBy.occupation
  },
  {
    label: 'Company',
    value: querySortBy.company
  }
];

const sortMap = {}; // create map from options e.g. { modified: 'Last Update' }
sortOptions.forEach(({ label, value }) => {
  sortMap[value] = label;
});

const SortIndicator = ({ sortDir }) =>
  sortDir === 'desc' ? <DownArrowIcon height={15} /> : <UpArrowIcon height={15} />;

SortIndicator.propTypes = {
  sortDir: PropTypes.string
};

const SortControls = ({ setSortBy, sortBy, sortDir }) => (
  <DropdownButton
    id="grid-sort"
    title={sortBy ? sortMap && sortMap[sortBy] : sortOptions[0].label}
    icon={<SortIndicator {...{ sortDir }} />}
  >
    {sortOptions.map(({ label, value }, index) => (
      <DropdownMenuItem
        key={`sort-${label}`}
        eventKey={index}
        onClick={() => setSortBy(value)}
        isSort
      >
        {' '}
        {label}
        {value && value === sortBy && <SortIndicator {...{ sortDir }} />}
      </DropdownMenuItem>
    ))}
  </DropdownButton>
);

SortControls.propTypes = {
  setSortBy: PropTypes.func.isRequired,
  sortBy: PropTypes.string,
  sortDir: PropTypes.number
};

export default SortControls;
