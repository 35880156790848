import React from 'react';

import ListView from './ListView';
import LoadingSingle from 'shared/Miscellaneous/Loading/ProductListLoadingSingle';

import 'react-virtualized/styles.css';
import '../../../styles/global/table.scss';

const ListViewContainer = ({ height, width, users, sortDir, setSort, ...props }) => {
  const rowCount = users ? users.length : 0;
  const rowGetter = ({ index }) => {
    return users[index] || {};
  };
  const rowRenderer = ({ className, columns, index, key, style, rowData }) => {
    const content = rowData.id ? columns : <LoadingSingle />;
    return (
      <div className={className} key={key} role="row" style={style}>
        {content}
      </div>
    );
  };

  return (
    <ListView
      {...{
        rowCount,
        rowGetter,
        rowRenderer,
        height,
        width,
        sortDirection: sortDir
      }}
      {...props}
      onHeaderClick={({ dataKey }) => setSort(dataKey)}
      headerHeight={55}
      overscanRowCount={10}
      rowHeight={40}
      headerClassName="table-header"
      rowClassName="table-row"
      gridClassName="table__visible-items"
      scrollToAlignment="start"
    />
  );
};

ListViewContainer.defaultProps = {
  height: 0,
  width: 0
};

export default ListViewContainer;
