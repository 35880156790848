import React, { Component } from 'react';
import LoadingOverlay from 'react-loading-overlay';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import { reducerUtil } from 'base-client';
import { SortDirection } from 'react-virtualized';
import Header from './Header';
import AssetTable from './AssetTable';
import { actions as detailsActions, reducerData as detailsData } from 'productDetails';

const Container = styled.div``;

const compare = key => (a, b) => {
  if (!key) return;
  const x = key === 'size' ? parseFloat(a[key]) : a[key];
  const y = key === 'size' ? parseFloat(b[key]) : b[key];
  if (key === 'updated_at') {
    const xDate = moment(x);
    if (xDate.isBefore(y)) return -1;
    if (xDate.isAfter(y)) return 1;
    return 0;
  }
  if (x.toLowerCase && y.toLowerCase) {
    return x.toLowerCase().localeCompare(y.toLowerCase());
  }
  if (x < y) return -1;
  if (x > y) return 1;
  return 0;
};

class AssetsContainer extends Component {
  static propTypes = {
    assets: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        filename: PropTypes.string.isRequired,
        size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        mimetype: PropTypes.string,
        dateAdded: PropTypes.string,
        fileType: PropTypes.string,
        fileSize: PropTypes.string
      })
    ),
    dispatch: PropTypes.func.isRequired
  };

  static defaultProps = {
    assets: []
  };

  state = {
    sortBy: 'updated_at',
    sortDir: SortDirection.DESC,
    selected: [],
    sortedList: [],
    isActive: false
  };

  componentDidMount() {
    this.sortList();
  }

  componentDidUpdate(prevProps) {
    const { assets: prevAssets } = prevProps;
    const { assets } = this.props;

    if (assets === prevAssets) return;
    this.sortList();
  }

  sortList = () => {
    const { sortBy, sortDir } = this.state;
    const { assets } = this.props;
    const sortedList = assets.sort(compare(sortBy));
    if (sortDir === SortDirection.DESC) sortedList.reverse();
    this.setState({ sortedList });
  };

  handleSort = ({ sortBy, sortDir }) => {
    this.setState({ sortBy, sortDir }, () => this.sortList());
  };

  render() {
    const { selected, sortedList, sortBy, sortDir } = this.state;

    const { dispatch, selectedNav } = this.props;
    const headerProps = {
      selectedCount: selected.length,
      actionHandler:
        selected.length < 2
          ? files => dispatch(detailsActions.addFiles(files))
          : removeFiles => dispatch(detailsActions.removeFiles(removeFiles))
    };

    const list =
      selectedNav && selectedNav !== "All"
        ? sortedList
          .filter(
            (obj) =>
              obj.group.toLowerCase() === selectedNav.toLowerCase()
          )
          .map((item) => ({ ...item, isChecked: selected.includes(item.id) }))
        : sortedList.map((item) => ({
          ...item,
          isChecked: selected.includes(item.id),
        }));

    const listProps = {
      sortBy,
      sortDir,
      list,
      selected,
      onRemove: id => dispatch(detailsActions.removeFiles(id)),
      onSelect: ({ index, rowData }) => this.handleSelect({ index, rowData }),
      onSort: ({ sortBy, sortDirection }) => {
        this.handleSort({ sortBy, sortDir: sortDirection });
      },
      onDataUpdate: async ({ updatedData }) => {
        this.setState({ ...this.state, isActive: true })
        await dispatch(detailsActions.updateAsset(updatedData))
        this.setState({ ...this.state, isActive: false })

      }, 
      dispatch
    };

    return (
      <Container>
        <Header {...headerProps} />
        <LoadingOverlay
          active={this.state.isActive}
          spinner
          text='Loading your content...'
        >
          <AssetTable {...listProps} />
        </LoadingOverlay>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  assets: reducerUtil.getSlice(detailsData, detailsData.assets, state)
});

export default connect(mapStateToProps)(AssetsContainer);
