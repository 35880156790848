import { config, reducerUtil } from 'base-client';

import { reducerData as permissionsData } from 'permissions';
import timeUtils from 'utils/timeUtils';
import { actions as cacheActions, utils as cacheUtils } from 'cache';
import { reducerData as leadsData, utils as leadsUtils } from 'leadsTwo';
import fileDownload from 'utils/fileDownload';

import { actions as insightActions, utils as insightUtils } from 'insights';

const leadPermission = state => reducerUtil.getSlice(permissionsData, permissionsData.leads, state);

const leadApi = (
  site,
  user_id,
  { stream, interval = leadsUtils.defaultInterval, count = 1, ...bodyData } = {}
) => async (dispatch, getState) => {
  const state = getState();

  if (!leadPermission(state)) return null;

  const url = user_id
    ? 'insights/null/null/leadProfile/'
    : `insights/${site}/${leadsUtils.streamConvert[stream] || stream}/allLeads/`;

  const tick = insightUtils.chooseTick(interval, count);
  const key = cacheUtils.insightKey({ url, interval, count, key: user_id, ...bodyData });
  const body = insightUtils.insightBody({ tick, interval, user_id, ...bodyData });

  return dispatch(cacheActions.postCache(key, url, body)).then(data => {
    return Promise.resolve({ data, tick });
  });
};

const getLeadLocation = lead => {
  const { city, state, country } = lead;

  if (city && state && country) {
    return `${city}, ${state} ${country}`;
  }
  if (state && country) {
    return `${state} ${country}`;
  }
  if (city && country) {
    return `${city} ${country}`;
  }
  if (country) {
    return country;
  }
  return 'Unknown User Location';
};

const getLeadList = (stream, interval, key) => async (dispatch, getState) => {
  const state = getState();

  if (!leadPermission(state)) return null;

  try {
    const { data } = await dispatch(leadApi('all', null, { stream, interval, key }));
    if (data) {
      const formattedData = formatLeadsData(data, stream, interval);
      dispatch(reducerUtil.setSlice(leadsData, leadsData.leadList, formattedData));
      dispatch(insightActions.setProductInfoInterval(interval));
    } else {
      dispatch(reducerUtil.setSlice(leadsData, leadsData.leadList, undefined));
    }
  } catch (error) {
    dispatch(config.actions.error(error));
  }
};

const formatLeadsData = (data, stream, interval) => {
  return {
    stream,
    interval,
    total: data.length,
    list: data.map(lead => {
      const {
        id,
        firstName,
        lastName,
        username,
        companyName,
        occupation,
        eventCount,
        lastEvent
      } = lead;

      return {
        name: firstName && lastName ? `${firstName} ${lastName}` : username,
        company: companyName || 'Anonymous Company',
        occupation: occupation || 'Anonymous Occupation',
        location: getLeadLocation(lead),
        eventCount: parseInt(eventCount),
        lastEvent: lastEvent && timeUtils.format.toNumber(new Date(lastEvent)),
        url: `/insights/contacts/${id}`
      };
    })
  };
};

const downloadLeadList = () => async (dispatch, getState) => {
  const leadList = reducerUtil.getSlice(leadsData, leadsData.leadList, getState()) || {};
  const { stream, interval } = leadList;
  try {
    const { data } = await dispatch(leadApi('all', null, { stream, interval, download: true }));
    if (data) fileDownload(data, `${'Leads'}.csv`);
  } catch (error) {
    dispatch(config.actions.error(error));
  }
};

export default {
  leadApi,
  getLeadLocation,
  getLeadList,
  downloadLeadList
};
