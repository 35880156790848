/* vendor imports */
import React from 'react';
import PropTypes from 'prop-types';

/* system imports */
import '../assets/attribute-editor.scss';
import Component from '../components';
import { pages, newAttribute } from 'productAttributes/utils';

class Container extends React.Component {
  constructor(props) {
    super(props);
    this.state = { page: pages.main };
    this.setKey = this.setKey.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.toPage = this.toPage.bind(this);
    this.checkProducts = this.checkProducts.bind(this);
    this.updateAttribute = this.updateAttribute.bind(this);
    this.deleteAttribute = this.deleteAttribute.bind(this);
    this.resetAttribute = this.resetAttribute.bind(this);
    this.changeFamily = this.changeFamily.bind(this);
    this.addAttribute = this.addAttribute.bind(this);
    this.removeAttribute = this.removeAttribute.bind(this);
  }
  componentDidMount() {
    const { openAttributeRef } = this.props;
    if (openAttributeRef) openAttributeRef(this.openModal);
  }
  componentWillUnmount() {
    const { openAttributeRef } = this.props;
    if (openAttributeRef) openAttributeRef(null);
  }
  setKey(selectedKey) {
    this.setState({ selectedKey, page: pages.main });
  }
  openModal(openData) {
    const { selectedKey, ...productData } = openData || {};
    const { openModal, attributeActions } = this.props;
    attributeActions.get();
    this.setState({ page: pages.main, selectedKey, productData });
    openModal();
  }
  closeModal() {
    const { openModal } = this.props;
    openModal(false);
  }
  toPage(page) {
    this.setState({ page });
  }
  checkProducts(confirm) {
    const { selectedKey } = this.state;
    if (selectedKey === null) return;
    if (selectedKey === newAttribute) {
      confirm.onClick();
      return;
    }
    const { attributeActions } = this.props;
    attributeActions.products(selectedKey).then(({ updated }) => {
      if (updated) this.setState({ confirm, page: pages.confirm });
      else confirm.onClick();
    });
  }
  updateAttribute() {
    const { selectedKey } = this.state;
    if (selectedKey === null) return;
    const { attributeActions } = this.props;
    attributeActions
      .update(selectedKey === newAttribute ? null : selectedKey)
      .then(({ key }) => this.setKey(key));
  }
  deleteAttribute() {
    const { selectedKey } = this.state;
    if (selectedKey === null) return;
    const { attributeActions } = this.props;
    attributeActions.delete(selectedKey).then(() => this.setKey(undefined));
  }
  resetAttribute() {
    const { reset } = this.props;
    reset();
    this.toPage(pages.main);
  }
  changeFamily() {
    const { allType } = this.state;
    this.setState({ allType: !allType });
  }
  addAttribute() {
    const { selectedKey, allType, productData } = this.state;
    if (!selectedKey) return;
    const { attributeActions } = this.props;
    attributeActions.add(selectedKey, allType, productData);
    this.closeModal();
  }
  removeAttribute() {
    const { selectedKey, allType, productData } = this.state;
    if (!selectedKey) return;
    const { attributeActions } = this.props;
    attributeActions.remove(selectedKey, allType, productData);
    this.closeModal();
  }
  render() {
    const { canView, button, attributeActions, openAttributeRef, ...props } = this.props;
    if (!canView) return null;
    const modalProps = {
      title: openAttributeRef ? 'Add or Remove an Attribute' : 'Attribute Editor',
      closeModal: this.closeModal,
      setKey: this.setKey,
      newKey: () => this.setKey(newAttribute),
      controls: {
        updateAttribute: () => this.checkProducts({ onClick: this.updateAttribute }),
        deleteAttribute: () =>
          this.checkProducts({ onClick: this.deleteAttribute, isDelete: true }),
        resetAttribute: this.resetAttribute,
        changeFamily: this.changeFamily,
        addAttribute: this.addAttribute,
        removeAttribute: this.removeAttribute
      }
    };
    return (
      <div>
        {button && React.cloneElement(button, { onClick: this.openModal })}
        <Component {...props} {...modalProps} {...this.state} />
      </div>
    );
  }
}

Container.propTypes = {
  openModal: PropTypes.func.isRequired,
  button: PropTypes.element,
  attributeActions: PropTypes.shape({
    get: PropTypes.func.isRequired,
    products: PropTypes.func.isRequired,
    update: PropTypes.func.isRequired,
    delete: PropTypes.func.isRequired,
    add: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired
  }).isRequired,
  reset: PropTypes.func.isRequired,
  canView: PropTypes.bool,
  openAttributeRef: PropTypes.func
};

Container.defaultProps = {
  button: null,
  canView: false,
  openAttributeRef: null
};

export default Container;
