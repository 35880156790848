import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { reducerUtil } from 'base-client';
import { Card, Row, Column } from 'shared-features-client';

import sbManagerStyles from 'utils/globalStyles';
import { reducerData as detailsData } from 'projectDetails';
import { addComma } from 'shared-features-client';
import ProjectInfoCard from './ProjectInfoCard';

const ProjectInfoColumn = styled(Column)`
  border-right: 1px solid ${sbManagerStyles.accentGrey};
  &:last-of-type {
    border: 0;
  }
`;

const StyledProjectDetailsTop = styled(Card)`
  margin-bottom: 2rem;

  p {
    color: ${sbManagerStyles.blueDark};
    font-size: 2.4rem;
    font-weight: 600;
  }
`;

const ProjectInfoTop = ({ ...generalData }) => {
  if (!generalData) return null;

  const { project_budget, project_size, products } = generalData;

  return (
    <StyledProjectDetailsTop>
      <Row>
        <ProjectInfoColumn>
          <ProjectInfoCard
            top
            title="Budget"
            content={project_budget ? addComma(project_budget) : null}
            prefix="$"
            defaultValue="-"
          />
        </ProjectInfoColumn>
        <ProjectInfoColumn>
          <ProjectInfoCard
            top
            title="Size"
            content={project_size ? addComma(project_size) : null}
            suffix="sqft"
            defaultValue="-"
          />
        </ProjectInfoColumn>
        <ProjectInfoColumn>
          <ProjectInfoCard
            top
            title="Products"
            content={(products && products.length) || null}
            defaultValue={0}
          />
        </ProjectInfoColumn>
      </Row>
    </StyledProjectDetailsTop>
  );
};

const mapStateToProps = state => ({
  products: reducerUtil.getSlice(detailsData, detailsData.products, state)
});

export default connect(mapStateToProps)(ProjectInfoTop);
