import React from 'react';
import PropTypes from 'prop-types';

import RadioGroup, { RadioGroupRow } from 'shared/InputGroups/RadioGroup';
import { DateRangePicker } from 'shared-features-client';

const HeaderControls = ({
  onSelectStream,
  onSelectInterval,
  stream,
  interval,
  controlId,
  optionsStream,
  hideStream,
  hideDatePicker,
  extraControls
}) => (
  <RadioGroupRow>
    {extraControls}
    {!hideStream && (
      <RadioGroup
        namespace={`stream-${controlId}`}
        controlFunc={onSelectStream}
        options={optionsStream}
        selectedOption={stream}
      />
    )}
    {!hideDatePicker && <DateRangePicker {...{ interval, onSelectInterval }} />}
  </RadioGroupRow>
);

HeaderControls.propTypes = {
  onSelectStream: PropTypes.func.isRequired,
  onSelectInterval: PropTypes.func.isRequired,
  stream: PropTypes.string.isRequired,
  interval: PropTypes.object.isRequired,
  controlId: PropTypes.string.isRequired,
  optionsStream: PropTypes.arrayOf(PropTypes.string).isRequired,
  hideStream: PropTypes.bool,
  hideDatePicker: PropTypes.bool,
  extraControls: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element])
};

HeaderControls.defaultProps = {
  extraControls: null,
  hideStream: false,
  hideDatePicker: false
};

export default HeaderControls;
