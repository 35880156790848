import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { Tabs, Tab } from 'react-bootstrap';
import { reducerUtil } from 'base-client';

import { actions as analyticActions, reducerData as analyticsData } from 'analyticsDashboard';

import Card from 'shared/PageLayout/Card';
import 'shared/Navigation/Tabs/assets/tabs.scss';

import { AnalyticsFilters } from 'shared/Filters';
import { defaultISOInterval } from 'shared-features-client';
import { PageHeader } from 'shared';
import ActionsBarContainer from './ActionsBarContainer';
import ActionsTabContent from './ActionsTabContent';
import Loading from 'shared/Miscellaneous/Loading/Loading.jsx';
import TrafficTabContent from './TrafficTabContent';

const tabs = {
  traffic: 'traffic',
  actions: 'actions'
};

const Container = styled.div`
  .card {
    position: relative;
  }
  .tab-content {
    position: relative;
    overflow: hidden;
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 20%;
      bottom: 0;
      right: 0;
      width: 1px;
      color: #c3cdd9;
      background-color: #c3cdd9;
    }
    .filters {
      width: 20%;
      float: left;
      border: none;
    }
  }
  .tab-pane {
    width: 80%;
    float: right;
  }
`;

const Notification = styled.h3`
  font-size: 1.6rem;
  margin: 0 0 1.5rem;
  padding: 0px 15px;
`

const SpinnerContainer = styled.div`
  width: 20%;
  height: 100%;
  position: absolute;
`;

class Dashboard extends Component {
  static propTypes = {
    data: PropTypes.object,
    dispatch: PropTypes.func.isRequired,
    fetchId: PropTypes.string,
    history: PropTypes.object.isRequired,
    meta: PropTypes.object,
    notification: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string
    ]),
    query: PropTypes.object
  };

  state = {
    initialSearchData: null,
    initialSearchMeta: null,
    query: PropTypes.object,
    showSpinner: false,
    tabKey: tabs.actions,
    visualization: PropTypes.string
  };

  componentDidMount() {
    this.fetchSearchData();
  }

  componentDidUpdate(prevProps, prevState) {
    // set initial search state
    if (this.state.initialSearchMeta === null && this.props.meta && this.props.meta.locations && this.props.meta.locations.facets && this.props.meta.locations.facets.length) {
      this.setState({
        initialSearchMeta: JSON.parse(JSON.stringify(this.props.meta)),
        initialSearchData: JSON.parse(JSON.stringify(this.props.data))
      });
    }

    // set spinner
    if (
      this.state.showSpinner &&
      JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)
    ) {
      this.setState({ showSpinner: false });
    }

    // reset filters if 0 matching results
    if (!this.props.notification && this.props.meta && this.props.meta.locations && this.props.meta.industries && this.props.meta.occupations && this.props.meta.locations.facets && this.props.meta.industries.facets && this.props.meta.occupations.facets) {
      if (!this.props.meta.locations.facets.length && !this.props.meta.industries.facets.length && !this.props.meta.occupations.facets.length) {
        this.resetFilters();
        this.props.dispatch(analyticActions.setNotification({
          message: '0 Matching Results',
          title: 'Filters Reset'
        }));
      }
    }

    this.fetchSearchData();
  }

  updateFilter = ({ checked, attribute, facets }) => {
    const { dispatch } = this.props;

    this.setState({ showSpinner: true });

    if (attribute === 'location') {
      return dispatch(analyticActions.updateFilter({ attribute, facets }));
    }

    return checked
      ? dispatch(analyticActions.addFilter({ attribute, facets }))
      : dispatch(analyticActions.removeFilter({ attribute, facets }));
  };

  // if only one filter is present it should be selected automatically 
  selectSingleFilters = (filter) => {
    if (Object.keys(filter).includes('facets') && filter.facets.length === 1) { // checkboxes
      filter.checkedAll = true;
      filter.facets[0].checked = true;
      filter.facets[0].disabled = true;
    } else if (Object.keys(filter).includes('selected') && filter.facets.length === 1) { // dropdown
      filter.selected.push(JSON.parse(JSON.stringify(filter.facets[0].value)))
    }
    return filter
  }

  fetchSearchData = () => {
    const {
      history: {
        location: { search }
      }
    } = this.props;

    const { lastSearch } = this.state;

    if (search !== lastSearch) {
      const { dispatch } = this.props;
      this.setState({ lastSearch: search });
      return dispatch(analyticActions.search(true));
    }
  };

  resetFilters = () => {
    this.props.dispatch(analyticActions.setMeta(this.state.initialSearchMeta));
    this.props.dispatch(analyticActions.setData(this.state.initialSearchData));
  }

  isFiltersActive = ({ cb, industriesProps = false, locationsProps = false, occupationsProps = false }) => {
    if (!industriesProps.facets || !locationsProps.selected || !occupationsProps.facets) {
      return false;
    }

    const locationActive = locationsProps && locationsProps.selected.length
    const industriesActive = industriesProps && industriesProps.facets.find(facet => {
      return facet.checked;
    })
    const occupationActive = occupationsProps && occupationsProps.facets.find(facet => {
      return facet.checked;
    })

    if (locationActive || industriesActive || occupationActive) {
      return cb;
    } else {
      return false;
    }
  }

  render() {
    const { meta, query, notification, visualization = "bar" } = this.props;
    const { showSpinner, tabKey } = this.state;

    const { locations = {}, industries = {}, occupations = {} } = meta || {};
    const { dateRange } = query || {};

    let interval = defaultISOInterval;
    if (dateRange) {
      const { start: startDate, end: endDate } = dateRange;
      interval = {
        startDate,
        endDate
      };
    }

    const locationsProps = {
      ...this.selectSingleFilters(locations),
      updateFilter: this.updateFilter
    };
    const industriesProps = {
      ...this.selectSingleFilters(industries),
      updateFilter: this.updateFilter
    };
    const occupationsProps = {
      ...this.selectSingleFilters(occupations),
      updateFilter: this.updateFilter
    };

    return (
      <Container className="analytics">
        <PageHeader title="Dashboard" />
        <Card key="dashboard">
          <Tabs
            id="mainContainer"
            activeKey={tabKey}
            onSelect={tabKey => this.setState({ tabKey })}
          >
            {showSpinner ? (
              <SpinnerContainer>
                <Loading />
              </SpinnerContainer>
            ) : (
              <>
                {notification && <Notification style={{ color: "darkred" }}>{notification.title}<br></br>{notification.message}</Notification>}
                <AnalyticsFilters
                  {...{
                    industries: industriesProps,
                    locations: locationsProps,
                    occupations: occupationsProps,
                    resetFilters: this.isFiltersActive({ industriesProps, locationsProps, occupationsProps, cb: this.resetFilters }),
                    viewToggle: tabKey.toLowerCase() === "actions" ? true : false, // actions === "events"
                    visualization
                  }}
                />
              </>
            )}
            <Tab eventKey={tabs.traffic} title="Traffic">
              <TrafficTabContent />
            </Tab>
            <Tab eventKey={tabs.actions} title="Events">
              <ActionsTabContent />
            </Tab>
          </Tabs>
          <ActionsBarContainer {...{ interval }} />
        </Card>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  data: reducerUtil.getSlice(analyticsData, analyticsData.data, state),
  meta: reducerUtil.getSlice(analyticsData, analyticsData.meta, state),
  notification: reducerUtil.getSlice(analyticsData, analyticsData.notification, state),
  query: reducerUtil.getSlice(analyticsData, analyticsData.query, state),
  visualization: reducerUtil.getSlice(analyticsData, analyticsData.visualization, state)
});

export default withRouter(connect(mapStateToProps)(Dashboard));
