import React from 'react';
import styled from 'styled-components';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { PropTypes } from 'prop-types';
import sbManagerStyles from 'utils/globalStyles';

const StyledSideNav = styled.div`
  .side-nav {
    height: 100%;
    width: 100%;
    display: flex;
    margin-top: 2rem;

    .react-tabs__tab-list {
      border-top: none !important;
      border-bottom: none !important;
    }

    .tab-content {
      flex: 0 0 85%;
    }

    .tab-list {
      display: inline-block;
      flex: 0 0 15%;
      align-self: baseline;
      padding: 1rem 0 2rem;
      border-right: 1px solid ${() => sbManagerStyles.greyLight};

      li {
        outline: none;
        box-shadow: none;
        padding: 0 0.8rem 0 3.2rem;
        margin-bottom: 10px;
        text-decoration: none;
        display: block;
        transition: 0.3s;
        text-align: right;
        border-top: 0;
        border-left: 0;
        font-weight: 400;
        font-size: 1.4rem;
        cursor: pointer;
        &.react-tabs__tab--selected {
          border-bottom: none;
          border-radius: 0;
          border-right: 3px solid ${() => sbManagerStyles.blueDark};
          color: ${() => sbManagerStyles.blueDark};
          font-weight: 500;
          cursor: default;
          &:before {
            opacity: 0;
          }
        }
      }
    }
  }
`;

const TabContent = styled.div`
  display: inline-block;
  vertical-align: top;
  padding: 0 2rem 2rem;
`;

const SideNav = ({ options, ...props }) => {
  if (!options) {
    return '';
  }

  return (
    <StyledSideNav {...props}>
      <Tabs className="side-nav">
        <TabList className="tab-list">
          {options.map((o, index) => (
            <Tab key={index}>{o.label}</Tab>
          ))}
        </TabList>
        <TabContent className="tab-content">
          {options.map((o, index) => {
            const Elem = o.element;
            return (
              <TabPanel key={index}>
                <Elem data={o.data} />
              </TabPanel>
            );
          })}
        </TabContent>
      </Tabs>
    </StyledSideNav>
  );
};

SideNav.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      element: PropTypes.func.isRequired,
      data: PropTypes.any
    })
  ).isRequired
};

export default SideNav;
