import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../SvgIcon';

const LeadIcon = (props) => (
  <SvgIcon {...props}>
		<path d="M20.001,6v12h-16V6H20.001 M20.001,4h-16c-1.1,0-2,0.9-2,2v12c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2 V6C22.001,4.9,21.101,4,20.001,4L20.001,4z"/>
		<rect x="14.001" y="11" width="4" height="2"/>
		<rect x="14.001" y="14" width="4" height="2"/>
		<path d="M6.001,16c0-1.841,1.159-3,3-3l0,0c1.841,0,3,1.159,3,3H6.001z"/>
		<circle cx="9.001" cy="10" r="2"/>
  </SvgIcon>
)

LeadIcon.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  viewBox: PropTypes.string.isRequired,
};

LeadIcon.defaultProps = {
  width: 24,
  height: 24,
  viewBox: '0 0 24 24',
};

export default LeadIcon;
