import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { actions as detailsActions } from 'productDetails';

class ImageContainer extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    input: PropTypes.shape({
      value: PropTypes.string
    })
  };
  static defaultProps = {
    input: {}
  };

  state = {};

  componentDidMount() {
    this.onChangeImage();
  }
  componentDidUpdate() {
    this.onChangeImage();
  }

  onChangeImage = async () => {
    const {
      input: { value }
    } = this.props;
    const { imageId } = this.state;

    if (imageId === value) return;
    this.setState({ imageId: value });

    const { dispatch } = this.props;
    if (value) {
      const imageUrl = await dispatch(detailsActions.getImage(value));
      this.setState({ imageUrl });
    } else this.setState({ imageUrl: null });
  };

  render() {
    const { imageId, imageUrl } = this.state;
    return imageId ? <img src={imageUrl} className="revit-source__img" alt="" /> : null;
  }
}

export default connect()(ImageContainer);
