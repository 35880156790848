/* vendor imports */
import React from 'react';
import PropTypes from 'prop-types';

/* system imports */
import { LinkButton } from '../Buttons';

const Link = ({ cellData, rowData }) => <LinkButton to={rowData.url}>{cellData}</LinkButton>;

Link.propTypes = {
  cellData: PropTypes.any,
  rowData: PropTypes.object
};

Link.defaultProps = {
  cellData: null,
  rowData: {}
};

export default {
  Link
};
