import React from 'react';
import PropTypes from 'prop-types';

import Button from 'shared/Buttons';

const HeaderControls = ({ clickOpenNewUser, canAddUser }) => null;

/* (
  <Button onClick={canAddUser ? clickOpenNewUser : null} disabled={!canAddUser}>
    Add User
  </Button>
); */

HeaderControls.propTypes = {
  clickOpenNewUser: PropTypes.func.isRequired,
  canAddUser: PropTypes.bool
};

HeaderControls.defaultProps = {
  canAddUser: false
};

export default HeaderControls;
