import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'react-virtualized';
import 'react-virtualized/styles.css';

const GridView = ({ registerChild, ...gridProps }) => <Grid {...gridProps} ref={registerChild} />;

GridView.propTypes = {
  registerChild: PropTypes.func.isRequired
};

export default GridView;
