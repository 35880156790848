import React from 'react';
import PropTypes from 'prop-types';
import { AutoSizer, Column, Table } from 'react-virtualized';
import 'react-virtualized/styles.css';

import Card from 'shared/PageLayout/Card';
import { PageHeader } from 'shared';

const JobQueueTable = ({ rowCount, rowGetter, actionsCellRenderer, dateCellRenderer }) => (
  <React.Fragment>
    <PageHeader title="Job Queue" />
    <Card>
      <div className="table--AutoSizer">
        <AutoSizer>
          {({ height, width }) => (
            <Table
              headerHeight={55}
              headerClassName="table-header"
              rowClassName="table-row"
              rowHeight={70}
              height={height}
              {...{ rowCount, rowGetter, width }}
            >
              <Column
                label="Date"
                dataKey="updated"
                width={400}
                flexGrow={1}
                cellRenderer={dateCellRenderer}
              />

              <Column label="Job Type" dataKey="name" width={200} flexGrow={1} />

              <Column label="Status" dataKey="status" width={200} flexGrow={1} />

              <Column
                label="Actions"
                dataKey="actions"
                width={100}
                flexGrow={1}
                cellRenderer={actionsCellRenderer}
              />
            </Table>
          )}
        </AutoSizer>
      </div>
    </Card>
  </React.Fragment>
);

JobQueueTable.propTypes = {
  rowCount: PropTypes.number.isRequired,
  rowGetter: PropTypes.func.isRequired,
  actionsCellRenderer: PropTypes.func.isRequired,
  dateCellRenderer: PropTypes.func.isRequired
};

export default JobQueueTable;
