import { reducerUtil } from 'base-client';

import { reducerData as insightsData } from 'insights';
import actions from './productInfo';

// ------------------------------------
// Product Top List
// ------------------------------------
const getProductTop = (stream, interval, key) => async dispatch => {
  if (stream === 'views') stream = 'productviews';
  const results = await dispatch(
    actions.insightApi('all', stream, 'topProducts', { interval, key })
  );

  const { data } = results || {};

  const topData = productTable(data);

  dispatch(reducerUtil.setSlice(insightsData, insightsData.productTop, topData));
};

const productTable = data => {
  if (!data || data.length < 1) return null;
  return data.map((product, index) => ({
    name: `${product.inactive ? '(Deleted) ' : ''}${product.productName}`,
    category: product.productCategory,
    total: parseInt(product.count),
    url: `/products/${product.product_id}`,
    rank: index + 1
  }));
};

export default {
  getProductTop
};
