import React from 'react';
import PropTypes from 'prop-types';
import { List, AutoSizer } from 'react-virtualized';

import '../assets/autosizer-list.scss';

const AutoSizerList = ({ id, rowRenderer, rowCount, rowHeight, title, controls }) => (
  <div className={id}>
    <div className='bottom-border'>
      { title && <div className={`${id}__title`}> {title} </div> }
      { controls && (
        <div className={`${id}__controls`}>
          {controls}
        </div>
      )}
    </div>
    <div className={`${id}__content AutoSizerStuff`}>
      <AutoSizer>
        {({ width, height }) => (
          <List
            height={height}
            width={width}
            rowRenderer={rowData => rowRenderer(rowData)}
            rowCount={rowCount}
            rowHeight={rowHeight}
          />
        )}
      </AutoSizer>
    </div>
  </div>
);

AutoSizerList.propTypes = {
  controls: PropTypes.node,
  id: PropTypes.string,
  rowRenderer: PropTypes.func.isRequired,
  rowCount: PropTypes.number,
  rowHeight: PropTypes.number,
  title: PropTypes.string,
};

AutoSizerList.defaultProps = {
  controls: null,
  id: 'AutoSizerList',
  rowCount: 0,
  rowHeight: 40,
  title: null,
};

export default AutoSizerList;
