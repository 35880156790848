import React from 'react';
import PropTypes from 'prop-types';
import { FormControl } from 'react-bootstrap';

import Button from 'shared/Buttons';
import { CloseCircleOutlineIcon } from 'shared/Miscellaneous/Icons';

const ElementList = ({ elements, elementIndex, switchElement, removeElement }) => (
  <div className="type-selector">
    <span className="type-selector__label">
      <strong>Type</strong>
    </span>
    <FormControl componentClass="select" className="" value={elementIndex} onChange={switchElement}>
      {elements.map(({ name }, index) => (
        <option key={index} value={index}>
          {name}
        </option>
      ))}
    </FormControl>
    <Button
      buttonStyle="link-red"
      iconPosition="after"
      onClick={removeElement}
      icon={<CloseCircleOutlineIcon />}
    />
  </div>
);

ElementList.propTypes = {
  switchElement: PropTypes.func.isRequired,
  removeElement: PropTypes.func.isRequired,
  elements: PropTypes.array.isRequired,
  elementIndex: PropTypes.oneOfType([PropTypes.number || PropTypes.string])
};

ElementList.defaultProps = {
  elementIndex: 0
};

export default ElementList;
