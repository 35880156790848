import { history, config, reducerUtil } from 'base-client';
import { reducerData as permissionsData } from 'permissions';
import { reducerData as leadsData } from 'leads';
import leadTableActions from './leadList';
import { actions as messagesActions, reducerData as messagesData } from 'directMessages';
import timeUtils from 'utils/timeUtils';

// ------------------------------------
// Lead User Profile Data
// ------------------------------------

const leadPermission = state => reducerUtil.getSlice(permissionsData, permissionsData.leads, state);

const getLeadData = (user_id, callback) => async (dispatch, getState) => {
  const state = getState();
  if (!leadPermission(state)) return null;
  if (!user_id) {
    dispatch(backToLeads());
    if (callback) callback();
    return;
  }
  //clear old data before fetching new data
  dispatch(reducerUtil.setSlice(leadsData, leadsData.leadProfile, undefined));

  try {
    const { data } = await dispatch(leadTableActions.leadApi('all', user_id));
    if (data && data[0]) {
      const formattedData = formatLeadData(data[0]);
      dispatch(reducerUtil.setSlice(leadsData, leadsData.leadProfile, formattedData));

      dispatch(messagesActions.getMessages({ userId: user_id }, messagesData.leadMessages));
    } else if (callback) {
      dispatch(backToLeads());
      callback();
    } else {
      return;
    }
  } catch (error) {
    dispatch(config.actions.error(error));
  }
};

const formatLeadData = data => {
  const leadData = {};
  if (!data) return leadData;

  const {
    id,
    firstName,
    lastName,
    username,
    companyName,
    occupation,
    email,
    industry,
    phoneNumber: phone,
    picture,
    events
  } = data;

  leadData.user = {
    email,
    industry,
    phone,
    picture,
    id,
    name: firstName && lastName ? `${firstName} ${lastName}` : username,
    company: companyName || 'Anonymous Company',
    occupation: occupation || 'Anonymous Occupation',
    location: leadTableActions.getLeadLocation(data)
  };

  leadData.products =
    events &&
    events
      .filter(({ type }) => type === 'product' || type === 'download')
      .map(({ datetime: date, eventMetadata: meta, type }) => {
        const { product_id: id, productCategory, productName } = meta;
        const event = type === 'product' ? 'View' : 'Download';
        return {
          event,
          productKey: id,
          name: productName,
          category: productCategory,
          date: date ? timeUtils.format.toStatus(new Date(date)) : 'Unknown',
          url: `/products/${id}`
        };
      });

  leadData.tables = getLeadTables(data);
  return leadData;
};

const getLeadTables = lead => {
  if (!lead) return {};
  const { eventCountsBySource: sourceCount = [], eventCountsByType: typeCount = [] } = lead;

  // Order matters - sort based on priority.
  const actions = percentTable([
    {
      key: 'Downloads',
      count: getCount(typeCount, 'type', 'download')
    },
    {
      key: 'Page Views',
      count: getCount(typeCount, 'type', 'pageview')
    },
    {
      key: 'Product Views',
      count: getCount(typeCount, 'type', 'product')
    },
    {
      key: 'Searches',
      count: getCount(typeCount, 'type', 'search')
    }
  ]);

  const sites = percentTable([
    {
      key: 'Design Studio',
      count: getCount(sourceCount, 'source', 'designStudio')
    },
    {
      key: 'Library',
      count: getCount(sourceCount, 'source', 'library')
    },
    {
      key: 'API',
      count: getCount(sourceCount, 'source', 'api')
    }
  ]);

  const categories = percentTable(
    lead.eventCategories.map(category => ({ key: category.category, count: category.count }))
  );
  const totalActivity = lead.eventCount || 0;
  const lastActivity = timeUtils.format.toNumber(new Date(lead.lastEvent));
  return {
    actions,
    sites,
    categories,
    totalActivity,
    lastActivity
  };
};

const getCount = (infoArray, key, match) => {
  const info = infoArray.find(item => item[key] === match) || {};
  return info.count || 0;
};

const percentTable = data => {
  if (!data || data.length < 1) return;
  const total = data.reduce((sum, value) => sum + value.count, 0);
  return data.map(value => ({
    name: value.key,
    y: precisionRound((value.count / total) * 100, 2)
  }));
};

const precisionRound = (number, precision) => {
  const factor = 10 ** precision;
  return Math.round(number * factor) / factor;
};

const backToLeads = () => dispatch => history.push('/insights/leads');

export default {
  getLeadData
};
