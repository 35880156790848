import { destroy } from 'redux-form';
import { api, config, reducerUtil } from 'base-client';

import { modalConfirmName, modalConfirmDisplay, openModal } from 'modal/modal';
import { reducerData as permissionsData } from 'permissions';
import { reducerData as adminData } from 'admin';
import { formUserNewName, formUserEditName } from 'admin/utils';

const canAdmin = state => reducerUtil.getSlice(permissionsData, permissionsData.admin, state);

const getUserList = () => async (dispatch, getState) => {
  if (!canAdmin(getState())) return;

  try {
    const userList = await dispatch(api.actions.get('tenantAdmin/users'));
    dispatch(fixUserList(userList));
    dispatch(getRoles());
  } catch (error) {
    dispatch(config.actions.error(error));
  }
};

const fixUserList = users => dispatch => {
  const userList = users.map(user => ({
    name: user.username,
    role: user.tenantRole.role,
    key: user.user_id,
    status: user.isActive ? 'Active' : 'Inactive',

    onClickEdit: () =>
      dispatch(
        openUserEditModal({
          user_id: user.user_id,
          userName: user.username,
          userRole: user.tenantRole.role,
          userActive: user.isActive ? 'Active' : 'Inactive'
        })
      ),

    onClickDelete: () => dispatch(openUserDeleteModal(user.user_id))
  }));
  dispatch(reducerUtil.setSlice(adminData, adminData.users, users));
  dispatch(reducerUtil.setSlice(adminData, adminData.userDisplay, userList));
};

const getRoles = () => async (dispatch, getState) => {
  if (!canAdmin(getState())) return;

  try {
    const tenant = await dispatch(api.actions.get('tenantAdmin/tenant'));
    if (tenant.length > 0) {
      const roles = tenant.map(role => ({ role: role.role, id: role.id }));
      dispatch(reducerUtil.setSlice(adminData, adminData.roles, roles));
    }
  } catch (error) {
    dispatch(config.actions.error(error));
  }
};

const openUserNewModal = () => dispatch => {
  dispatch(destroy(formUserNewName));
  dispatch(openModal(formUserNewName));
};

const postUserNew = () => async (dispatch, getState) => {
  const state = getState();
  if (!canAdmin(state)) return;
  const { email, role } = state.form[formUserNewName].values;
  const roles = reducerUtil.getSlice(adminData, adminData.roles, state) || {};
  const body = { email, roles: JSON.stringify([role || roles[0]]) };

  try {
    await dispatch(api.actions.post('', body));
    dispatch(openModal(formUserNewName, false));
    dispatch(getUserList());
  } catch (error) {
    dispatch(config.actions.error(error));
  }
};

const openUserEditModal = user => dispatch => {
  dispatch(reducerUtil.setSlice(adminData, adminData.editUser, user));
  dispatch(openModal(formUserEditName));
};

const putUserEdit = () => (dispatch, getState) => {
  const state = getState();
  const editUser = reducerUtil.getSlice(adminData, adminData.editUser, state);
  const { user_id: userId } = editUser;
  const users = reducerUtil.getSlice(adminData, adminData.users, state);

  const userData = users.find(data => data.user_id === userId);

  if (userData) {
    const formData = state.form ? state.form[formUserEditName] : null;
    const { values } = formData || {};
    if (values && values.role) {
      const roles = reducerUtil.getSlice(adminData, adminData.roles, state) || {};
      const role = roles.find(adminRole => adminRole.role === values.role);
      if (role && role.id) userData.role = role.id;
    }

    if (values && values.active) userData.isActive = values.active === 'Active';
    dispatch(putUserData(userId, userData, () => dispatch(openModal(formUserEditName, false))));
  }
};

const openUserDeleteModal = user_id => (dispatch, getState) => {
  const state = getState();
  const users = reducerUtil.getSlice(adminData, adminData.users, state);

  const userData = users.find(data => data.user_id === user_id);
  if (!userData) return;
  dispatch(
    modalConfirmDisplay({
      modalStyle: 'danger',
      title: `Remove Access for ${userData.username}`,
      text: `Are you sure you want to remove access for ${userData.username}?`,
      buttonList: [
        {
          text: 'Yes, Remove Access',
          buttonStyle: 'red',
          onClick: () => {
            dispatch(
              deleteUser(user_id, userData, () => dispatch(openModal(modalConfirmName, false)))
            );
          }
        },
        {
          text: 'Cancel',
          onClick: () => dispatch(openModal(modalConfirmName, false))
        }
      ]
    })
  );
  dispatch(openModal(modalConfirmName));
};

const putUserData = (user_id, userData, callback) => async (dispatch, getState) => {
  const state = getState();
  if (!canAdmin(state)) return;

  try {
    await dispatch(api.actions.post(`tenantAdmin/users/${user_id}`, JSON.stringify(userData)));
    if (callback) callback();
    dispatch(getUserList());
  } catch (error) {
    dispatch(config.actions.error(error));
  }
};

const deleteUser = (user_id, userData, callback) => async (dispatch, getState) => {
  const state = getState();
  if (!canAdmin(state)) return;

  try {
    await dispatch(api.actions.delete(`tenantAdmin/users/${user_id}`));
    if (callback) callback();
    dispatch(getUserList());
  } catch (error) {
    dispatch(config.actions.error(error));
  }
};

export default { getUserList, openUserNewModal, postUserNew, putUserEdit };
