/** This removes the ? from the beginning of a url query string.
 *  @param {string} [query] The string to check.
 * @returns {string} The trimmed query.
 */
const trimQuery = query => {
  if (typeof query !== 'string') return query;
  while (query.charAt(0) === '?') query = query.substr(1);
  return query;
};

export default trimQuery;
