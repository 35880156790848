import { config, api, login } from "base-client";

import { configMap } from "configurations";
import { actions as permissionsActions } from "permissions";
import { serviceKeyActions } from "utils/redirect/actions";

const start = () => async (dispatch, getState) => {
  await dispatch(config.actions.start(configMap));

  const host = process.env.REACT_APP_API_GATEWAY || dispatch(config.actions.getData(configMap.api.name, configMap.api.host.name));
  const gateway = dispatch(config.actions.getData(configMap.api.name, configMap.api.gateway.name));
  const publisher = dispatch(
    config.actions.getData(configMap.lambda.name, configMap.lambda.publisher.name)
  );

  dispatch(api.actions.start(host, gateway, true));

  const {
    [configMap.auth.domain.name]: domain,
    [configMap.auth.clientId.name]: clientID,
    [configMap.auth.audience.name]: audience,
  } = dispatch(config.actions.getData(configMap.auth.name)) || {};
  dispatch(login.actions.start({ domain, clientID, audience }, true));

  await dispatch(login.actions.checkLogin());
  dispatch(permissionsActions.getPermissions());
  dispatch(serviceKeyActions.fetchServiceKey());
};

export default { start };
