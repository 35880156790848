import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from 'shared/Buttons';
import { DownloadIcon } from 'shared/Miscellaneous/Icons';
import { DateRangePicker } from 'shared-features-client';

import { actions as eventsLogActions } from 'eventsLog';

const Container = styled.div`
  display: flex;
  .DateRangePickerInput {
    border-width: 2px;
    height: 36px;
  }
`;

const ActionsBar = ({ interval, dispatch }) => (
  <Container>
    <Button icon={<DownloadIcon />} onClick={() => dispatch(eventsLogActions.exportData())}>
      Export
    </Button>
    <DateRangePicker
      interval={interval}
      onSelectInterval={({ startDate, endDate }) =>
        dispatch(eventsLogActions.setDate({ startDate, endDate }))
      }
    />
  </Container>
);

ActionsBar.propTypes = {
  interval: PropTypes.shape({
    startDate: PropTypes.any,
    endDate: PropTypes.any
  }),
  dispatch: PropTypes.func.isRequired
};

export default connect()(ActionsBar);
