const EVENT_TYPE_KEYS = {
  DOWNLOAD: 'download',
  SEARCH: 'search',
  PRODUCT_VIEW: 'product',
  PAGE_VIEW: 'pageview',
  REFERRAL: 'referral',
  SUBMITTAL: 'submittal',
  BROCHURE: 'brochure'
};

const EVENT_TYPE_FACETS = [
  {
    attribute: 'Downloads',
    key: EVENT_TYPE_KEYS.DOWNLOAD
  },
  {
    attribute: 'Searches',
    key: EVENT_TYPE_KEYS.SEARCH
  },
  {
    attribute: 'Product Views',
    key: EVENT_TYPE_KEYS.PRODUCT_VIEW
  },
  {
    attribute: 'Page Views',
    key: EVENT_TYPE_KEYS.PAGE_VIEW
  },
  {
    attribute: 'Referrals',
    key: EVENT_TYPE_KEYS.REFERRAL
  },
  {
    attribute: 'Submittals',
    key: EVENT_TYPE_KEYS.SUBMITTAL
  },
  {
    attribute: 'Brochures',
    key: EVENT_TYPE_KEYS.BROCHURE
  }
];

const FILTERS = [
  {
    name: 'Event Type',
    attribute: 'eventType',
    facets: []
  },
  {
    name: 'Visitor Type',
    attribute: 'visitorType',
    facets: [
      {
        attribute: 'Registered Only',
        key: 'hideAnonymous'
      }
    ]
  },
  {
    name: 'Occupation',
    attribute: 'occupation',
    facets: []
  },
  {
    name: 'Country',
    attribute: 'location',
    facets: []
  }
];

const EVENTS_TYPE_ATTRIBUTE = 'eventType';
const VISITOR_TYPE_ATTRIBUTE = 'visitorType';
const NUMBER_FILTER_SHOW = 5;
const RADIO_LIST = [EVENTS_TYPE_ATTRIBUTE];

const GENERAL_TABLE_COLUMNS = [
  {
    label: 'date',
    path: 'datetime'
  },
  {
    label: 'name',
    path: 'fullName'
  },
  {
    label: 'userId',
    path: 'userID'
  }
];

const GENERAL_EXPORT_COLUMNS = [
  {
    label: 'Date',
    path: 'datetime'
  },
  {
    label: 'Name',
    path: 'fullName'
  }
];

const DOWNLOAD_COLUMNS = {
  TABLE: [
    ...GENERAL_TABLE_COLUMNS,
    {
      label: 'productName',
      path: 'eventMetadata.productName'
    },
    {
      label: 'productId',
      path: 'eventMetadata.product_id'
    },
    {
      label: 'fileType',
      path: 'ext'
    },
    {
      label: 'fileName',
      path: 'fileName'
    }
  ],
  EXPORTS: [
    ...GENERAL_EXPORT_COLUMNS,
    {
      label: 'Product Name',
      path: 'eventMetadata.productName'
    },
    {
      label: 'File Type',
      path: 'ext'
    },
    {
      label: 'File Name',
      path: 'fileName'
    }
  ]
};

const SEARCH_COLUMNS = {
  TABLE: [
    ...GENERAL_TABLE_COLUMNS,
    {
      label: 'occupation',
      path: 'occupation'
    },
    {
      label: 'company',
      path: 'companyName'
    },
    {
      label: 'searchQuery',
      path: 'eventMetadata.queryString'
    }
  ],
  EXPORTS: [
    ...GENERAL_EXPORT_COLUMNS,
    {
      label: 'Occupation',
      path: 'occupation'
    },
    {
      label: 'Company',
      path: 'companyName'
    },
    {
      label: 'Search Query',
      path: 'eventMetadata.queryString'
    }
  ]
};

const PRODUCT_VIEW_COLUMNS = {
  TABLE: [
    ...GENERAL_TABLE_COLUMNS,
    {
      label: 'occupation',
      path: 'occupation'
    },
    {
      label: 'company',
      path: 'companyName'
    },
    {
      label: 'productName',
      path: 'eventMetadata.productName'
    },
    {
      label: 'productId',
      path: 'eventMetadata.product_id'
    },
    {
      label: 'productUrl',
      path: 'eventMetadata.page.url'
    }
  ],
  EXPORTS: [
    ...GENERAL_EXPORT_COLUMNS,
    {
      label: 'Occupation',
      path: 'occupation'
    },
    {
      label: 'Company',
      path: 'companyName'
    },
    {
      label: 'Product Name',
      path: 'eventMetadata.productName'
    },
    {
      label: 'Product URL',
      path: 'eventMetadata.page.url'
    }
  ]
};

const PAGE_VIEW_COLUMNS = {
  TABLE: [
    ...GENERAL_TABLE_COLUMNS,
    {
      label: 'occupation',
      path: 'occupation'
    },
    {
      label: 'company',
      path: 'companyName'
    },
    {
      label: 'pageURL',
      path: 'eventMetadata.page.url'
    }
  ],
  EXPORTS: [
    ...GENERAL_EXPORT_COLUMNS,
    {
      label: 'Occupation',
      path: 'occupation'
    },
    {
      label: 'Company',
      path: 'companyName'
    },
    {
      label: 'Page URL',
      path: 'eventMetadata.page.url'
    }
  ]
};

const REFERRAL_COLUMNS = {
  TABLE: [
    ...GENERAL_TABLE_COLUMNS,
    {
      label: 'occupation',
      path: 'occupation'
    },
    {
      label: 'company',
      path: 'companyName'
    },
    {
      label: 'referralURL',
      path: 'eventMetadata.referrer.url'
    }
  ],
  EXPORTS: [
    {
      label: 'Date',
      path: 'datetime'
    },
    {
      label: 'Name',
      path: 'fullName'
    },
    {
      label: 'Occupation',
      path: 'occupation'
    },
    {
      label: 'Company',
      path: 'companyName'
    },
    {
      label: 'Referral URL',
      path: 'eventMetadata.referrer.url'
    }
  ]
};

const SUBMITTAL_COLUMNS = {
  TABLE: [
    ...GENERAL_TABLE_COLUMNS,
    {
      label: 'company',
      path: 'companyName'
    },
    {
      label: 'projectName',
      path: 'eventMetadata.name'
    },
    {
      label: 'projectId',
      path: 'eventMetadata.project_id'
    }
  ],
  EXPORTS: [
    {
      label: 'Date',
      path: 'datetime'
    },
    {
      label: 'Name',
      path: 'fullName'
    },
    {
      label: 'Company',
      path: 'companyName'
    },
    {
      label: 'Project Name',
      path: 'eventMetadata.name'
    }
  ]
};

const EXPORTS_COLUMNS = {
  [EVENT_TYPE_KEYS.DOWNLOAD]: DOWNLOAD_COLUMNS.EXPORTS,
  [EVENT_TYPE_KEYS.SEARCH]: SEARCH_COLUMNS.EXPORTS,
  [EVENT_TYPE_KEYS.PRODUCT_VIEW]: PRODUCT_VIEW_COLUMNS.EXPORTS,
  [EVENT_TYPE_KEYS.PAGE_VIEW]: PAGE_VIEW_COLUMNS.EXPORTS,
  [EVENT_TYPE_KEYS.REFERRAL]: REFERRAL_COLUMNS.EXPORTS,
  [EVENT_TYPE_KEYS.SUBMITTAL]: SUBMITTAL_COLUMNS.EXPORTS
};

const TABLE_COLUMNS = {
  [EVENT_TYPE_KEYS.DOWNLOAD]: DOWNLOAD_COLUMNS.TABLE,
  [EVENT_TYPE_KEYS.SEARCH]: SEARCH_COLUMNS.TABLE,
  [EVENT_TYPE_KEYS.PRODUCT_VIEW]: PRODUCT_VIEW_COLUMNS.TABLE,
  [EVENT_TYPE_KEYS.PAGE_VIEW]: PAGE_VIEW_COLUMNS.TABLE,
  [EVENT_TYPE_KEYS.REFERRAL]: REFERRAL_COLUMNS.TABLE,
  [EVENT_TYPE_KEYS.SUBMITTAL]: SUBMITTAL_COLUMNS.TABLE
};

export {
  EVENT_TYPE_KEYS,
  NUMBER_FILTER_SHOW,
  RADIO_LIST,
  EVENTS_TYPE_ATTRIBUTE,
  VISITOR_TYPE_ATTRIBUTE,
  EVENT_TYPE_FACETS,
  FILTERS,
  EXPORTS_COLUMNS,
  TABLE_COLUMNS
};
