import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { reducerUtil } from 'base-client';
import { reducerData as permissionsData } from 'permissions';

import Snapshot from './SnapshotContainer';
import ProductGrowth from './ProductGrowth';
import { LeadTable } from 'leads';
import TopProducts from './TopProductsContainer';

const InsightsContainer = ({ canView }) => {
  if (!canView) return null;

  return (
    <div>
      <Snapshot />
      <ProductGrowth />
      <LeadTable />
      <TopProducts />
    </div>
  );
};

InsightsContainer.propTypes = {
  canView: PropTypes.bool.isRequired
};

InsightsContainer.defaultProps = {
  canView: false
};

const mapStateToProps = state => ({
  canView: reducerUtil.getSlice(permissionsData, permissionsData.insights, state)
});

export default connect(mapStateToProps)(InsightsContainer);
