import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { FormControl } from 'react-bootstrap';
import cn from 'classnames';

import FormulaContainer from './FormulaContainer';

const DateTime = props => (
  <Field {...props} component={FormulaContainer}>
    <DateTimeField />
  </Field>
);

const DateTimeField = ({ bsClass, value, onChange, disabled }) => (
  <FormControl
    {...{ onChange, disabled }}
    value={value || ''}
    type="datetime-local"
    bsClass={cn('form-control', bsClass)}
  />
);

DateTimeField.propTypes = {
  bsClass: PropTypes.object,
  value: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool
};

export default DateTime;
