import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormGroup, ControlLabel } from 'react-bootstrap';

import Facet from './Facet';

const Container = styled(FormGroup)`
  display: block !important;
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  padding: 1rem 0;
  justify-content: space-between;
`;

const Title = styled(ControlLabel)`
  font-size: 1.6rem;
`;

const FilterSearchForm = styled.form`
  position: relative;
`;

const ToggleElm = styled.button`
  cursor: pointer;
  color: #0a7be4;
  text-transform: uppercase;
  font-size: 1rem;
  background: transparent;
  border: none;
  font-weight: 400;
  outline: none;
  padding-right: 0;
  padding-left: 0;
`;

const Filter = ({ checkedAll, name, attribute, facets, updateFilter }) => {
  if (!facets || (facets && facets.length < 1)) return null;
  return (
    <Container>
      <Header>
        <Title>{name}</Title>
        {facets && facets.length > 1 && 
          <ToggleElm
            type="button"
            className="toggleBtn"
            onClick={() =>
              updateFilter({
                checked: !checkedAll,
                attribute,
                facets: facets.map(({ name }) => name)
              })
            }
          >
            {checkedAll ? 'Deselect All' : 'Select All'}
          </ToggleElm>
        }
      </Header>
      <FilterSearchForm />
      {facets.map(facet => (
        <Facet key={facet.name} filterAttribute={attribute} {...facet} {...{ updateFilter }} />
      ))}
    </Container>
  );
};

Filter.propTypes = {
  checkedAll: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  attribute: PropTypes.string.isRequired,
  facets: PropTypes.arrayOf(
    PropTypes.shape({ name: PropTypes.string.isRequired, checked: PropTypes.bool })
  ),
  updateFilter: PropTypes.func.isRequired
};

Filter.defaultProps = {
  checkedAll: false,
  name: '',
  attribute: ''
};

export default Filter;
