import React from 'react';
import { Field } from 'redux-form';

import ImageContainer from './ImageContainer';
import detailsUtils from 'productDetails/utils';

const Image = props => (
  <Field name={detailsUtils.systemNames.revitImage} {...props} component={ImageContainer} />
);

export default Image;
