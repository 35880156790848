import { login, reducerUtil } from 'base-client';

import { reducerData as permissionsData } from 'permissions';

const getPermissions = () => (dispatch, getState) => {
  dispatch(clearPermissions());

  const state = getState();
  const token = reducerUtil.getSlice(login.reducerData, login.reducerData.token, state);
  if (!token) return;

  const profile = reducerUtil.getSlice(login.reducerData, login.reducerData.profile, state);
  if (!profile) return;

  const { 'https://permissions': permissions } = profile;
  if (!permissions) return;

  const { scopes } = permissions;

  dispatch(
    reducerUtil.setSlice(
      permissionsData,
      permissionsData.manager,
      scopes.includes('manager:access')
    )
  );
  dispatch(
    reducerUtil.setSlice(
      permissionsData,
      permissionsData.csv,
      scopes.includes('manager:csv:import')
    )
  );
  dispatch(
    reducerUtil.setSlice(
      permissionsData,
      permissionsData.insights,
      scopes.includes('manager:insights:read')
    )
  );
  dispatch(
    reducerUtil.setSlice(
      permissionsData,
      permissionsData.leads,
      scopes.includes('manager:leads:read')
    )
  );
  dispatch(
    reducerUtil.setSlice(
      permissionsData,
      permissionsData.admin,
      scopes.includes('manager:users:read')
    )
  );

  dispatch(reducerUtil.setSlice(permissionsData, permissionsData.checked, true));
};

const clearPermissions = () => dispatch => {
  dispatch(reducerUtil.setSlice(permissionsData, permissionsData.manager, undefined));
  dispatch(reducerUtil.setSlice(permissionsData, permissionsData.csv, undefined));
  dispatch(reducerUtil.setSlice(permissionsData, permissionsData.insights, undefined));
  dispatch(reducerUtil.setSlice(permissionsData, permissionsData.leads, undefined));
  dispatch(reducerUtil.setSlice(permissionsData, permissionsData.admin, undefined));
  dispatch(reducerUtil.setSlice(permissionsData, permissionsData.checked, false));
};

export default { getPermissions };
