import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../SvgIcon';

const UserGroupIcon = (props) => (
  <SvgIcon {...props}>
		<path d="M20.372,19.059c0.69-0.641,1.129-1.546,1.129-2.559c0-1.93-1.57-3.5-3.5-3.5s-3.5,1.57-3.5,3.5 c0,1.013,0.439,1.918,1.129,2.559c-1.478,0.534-2.629,1.562-2.629,2.941h2c0-0.598,1.413-1.357,3-1.357s3,0.76,3,1.357h2 C23.001,20.62,21.85,19.593,20.372,19.059z M18.001,15c0.827,0,1.5,0.673,1.5,1.5s-0.673,1.5-1.5,1.5s-1.5-0.673-1.5-1.5 S17.174,15,18.001,15z"/>
		<path d="M8.372,19.059c0.69-0.641,1.129-1.546,1.129-2.559c0-1.93-1.57-3.5-3.5-3.5s-3.5,1.57-3.5,3.5 c0,1.013,0.439,1.918,1.129,2.559C2.152,19.593,1.001,20.62,1.001,22h2c0-0.598,1.413-1.357,3-1.357s3,0.76,3,1.357h2 C11.001,20.62,9.85,19.593,8.372,19.059z M6.001,15c0.827,0,1.5,0.673,1.5,1.5S6.828,18,6.001,18s-1.5-0.673-1.5-1.5 S5.174,15,6.001,15z"/>
		<path d="M15.001,11h2c0-1.38-1.151-2.407-2.629-2.941c0.69-0.641,1.129-1.546,1.129-2.559 c0-1.93-1.57-3.5-3.5-3.5s-3.5,1.57-3.5,3.5c0,1.013,0.439,1.918,1.129,2.559C8.152,8.593,7.001,9.62,7.001,11h2 c0-0.598,1.413-1.357,3-1.357S15.001,10.402,15.001,11z M12.001,4c0.827,0,1.5,0.673,1.5,1.5S12.828,7,12.001,7 s-1.5-0.673-1.5-1.5S11.174,4,12.001,4z"/>
  </SvgIcon>
)

UserGroupIcon.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  viewBox: PropTypes.string.isRequired,
};

UserGroupIcon.defaultProps = {
  width: 24,
  height: 24,
  viewBox: '0 0 24 24',
};

export default UserGroupIcon;
