/* vendor imports */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactHighcharts from 'react-highcharts';

/* system imports */
import toTitleCase from '../../../../../../utils/miscUtils';

class Container extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.state.chart) return;
    const { data, xAxis, stream } = nextProps;
    const chart = this.state.chart.getChart();
    if (data) {
      chart.series[0].setData(data);
      chart.series[0].name = toTitleCase(stream);
      // bugfix for chart type rerender not displaying tooltip labeling correctly
      ReactHighcharts.Highcharts.setOptions({
        lang: {
          thousandsSep: ','
        },
        tooltip: {
          headerFormat: '<span class="sb-highcharts-tooltip-header">{point.key}</span><br/>',
          pointFormat:
            `<span class="sb-highcharts-tooltip-point">\u25CF</span> ${toTitleCase(stream)}: <b>{point.y}</b><br/>`
        }
      });
      if (xAxis) chart.xAxis[0].update({ categories: xAxis }, true);
      chart.hideLoading();
      chart.reflow();
    } else chart.showLoading();
  }
  shouldComponentUpdate(nextProps) {
    return JSON.stringify(nextProps.config) !== JSON.stringify(this.props.config);
  }
  render() {
    const { config } = this.props;
    return (
      <>
        { config &&
          <ReactHighcharts
            isPureConfig
            config={config}
            domProps={{ className: 'sb-chart' }}
            ref={c => {
              this.setState({ chart: c });
            }}
          />
      }
      </>
    );
  }
}

Container.propTypes = {
  config: PropTypes.object,
  data: PropTypes.array,
  xAxis: PropTypes.array,
  stream: PropTypes.string
};

Container.defaultProps = {
  config: null,
  data: null,
  xAxis: null,
  stream: null
};

export default Container;

// globally style some chart elements
ReactHighcharts.Highcharts.setOptions({
  lang: {
    thousandsSep: ','
  },
  tooltip: {
    headerFormat: '<span class="sb-highcharts-tooltip-header">{point.key}</span><br/>',
    pointFormat:
      '<span class="sb-highcharts-tooltip-point">\u25CF</span> {series.name}: <b>{point.y}</b><br/>'
  }
});