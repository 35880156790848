import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../SvgIcon';

const InsightsIcon = (props) => (
  <SvgIcon {...props}>
    <path id="shadow" className="color--light" d="M23,18.7h-1v0.8v2H2v-2v-0.8H1c-0.6,0-1,0.4-1,1v2.8c0,0.6,0.4,1,1,1h22c0.6,0,1-0.4,1-1v-2.8
      C24,19.1,23.6,18.7,23,18.7z"/>
    <g className="icon--active">
      <path className="color--dark" d="M18,9.5v10h-4v-4c0-0.6-0.4-1-1-1h-2c-0.6,0-1,0.4-1,1v4H6v-7c0-0.6-0.4-1-1-1H3c-0.6,0-1,0.4-1,1v9v0h20v0
        v-12c0-0.6-0.4-1-1-1h-2C18.4,8.5,18,9,18,9.5z"/>
      <path className="color--medium" d="M5,7.5c0.4,0,0.7-0.1,1-0.3l4,2.3c0,1.1,0.9,2,2,2c1.1,0,2-0.9,2-2c0-0.2,0-0.3-0.1-0.5l4.6-4.6
      c0.2,0,0.3,0.1,0.5,0.1c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2s-2,0.9-2,2c0,0.2,0,0.3,0.1,0.5l-4.6,4.6c-0.2,0-0.3-0.1-0.5-0.1
      c-0.4,0-0.7,0.1-1,0.3L7,5.5c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2C3,6.6,3.9,7.5,5,7.5z"/>
    </g>
    <g className="icon--inactive">
      <path className="color--medium" d="M22,19.5v-10c0-0.6-0.4-1-1-1h-4c-0.6,0-1,0.4-1,1v10h-1v-4c0-0.6-0.4-1-1-1h-4c-0.6,0-1,0.4-1,1v4H8v-7
      c0-0.6-0.4-1-1-1H3c-0.6,0-1,0.4-1,1v7l0,0v2h20V19.5L22,19.5z M4,19.5v-6h2v6H4z M11,19.5v-3h2v3H11z M18,19.5v-9h2v9H18z"/>
    <path className="color--medium" d="M5,7.5c0.4,0,0.7-0.1,1-0.3l4,2.3c0,1.1,0.9,2,2,2c1.1,0,2-0.9,2-2c0-0.2,0-0.3-0.1-0.5l4.6-4.6
      c0.2,0,0.3,0.1,0.5,0.1c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2s-2,0.9-2,2c0,0.2,0,0.3,0.1,0.5l-4.6,4.6c-0.2,0-0.3-0.1-0.5-0.1
      c-0.4,0-0.7,0.1-1,0.3L7,5.5c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2C3,6.6,3.9,7.5,5,7.5z"/>
    </g>
  </SvgIcon>
)

InsightsIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

InsightsIcon.defaultProps = {
  width: 22,
  height: 22
};


export default InsightsIcon;
