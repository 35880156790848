import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../SvgIcon';

const ProjectDeletedIcon = props => <SvgIcon {...props}>
  <path d="M18 3.16444H10L8.553 1.17294C8.214 0.460041 7.521 0.0100098 6.764 0.0100098H1C0.447 0.0100098 0 0.48002 0 1.06149V15.7822C0 16.943 0.895 17.8851 2 17.8851H18C19.104 17.8851 20 16.943 20 15.7822V5.26739C20 4.10656 19.104 3.16444 18 3.16444ZM14 11.5762H11H9H6V9.47329H9H11H14V11.5762Z" fill="#D95A5A"/>
</SvgIcon>;

ProjectDeletedIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
};

ProjectDeletedIcon.defaultProps = {
  width: 20,
  height: 19
};

export default ProjectDeletedIcon;
