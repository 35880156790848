import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { reducerUtil, config } from 'base-client';

import { InfiniteList } from 'shared-features-client';
import HeaderContainer from './HeaderContainer';
import { listOptions, storageKey } from 'productList/constants';
import Card from 'shared/PageLayout/Card';
import ListViewContainer from './ListViewContainer';
import Filters from './Filters';
import GridViewContainer from './GridViewContainer';
import { reducerData as listData, actions as searchActions } from 'productList';
import { querySortBy } from 'productList/utils';
import { reducerData as permissionsData } from 'permissions';
import { reducerData as jobsData } from 'jobs';

import { configMap } from 'configurations';

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  &.grid {
    .card {
      background-color: inherit;
      box-shadow: none;
    }
  }
  .ReactVirtualized__Table__headerRow {
    border-bottom: 1px solid #f0f3f6;
    .table-header {
      text-transform: uppercase;
      font-weight: 500;
      color: #77899e;
      cursor: pointer;
    }
  }
  .ReactVirtualized__Table__rowColumn {
    overflow: visible !important;
  }
  .ReactVirtualized__Table__Grid,
  .ReactVirtualized__Grid__innerScrollContainer {
    overflow: visible !important;
  }
`;

const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  &.grid {
    .card {
      background-color: inherit;
      box-shadow: none;
    }
  }
  .card {
    height: 100%;
    flex: 0 0 80%;
    margin-bottom: 0;
  }
`;

class ProductList extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    canView: PropTypes.bool,
    list: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })),
    selected: PropTypes.arrayOf(PropTypes.string),
    query: PropTypes.shape({ sortBy: PropTypes.string, sortDir: PropTypes.string }),
    meta: PropTypes.shape({}),
    fetchId: PropTypes.string,
    jobList: PropTypes.arrayOf(PropTypes.shape({ external_id: PropTypes.string }))
  };

  static defaultProps = {
    selected: [],
    jobList: []
  };

  state = { listMode: localStorage.getItem(storageKey) || listOptions[0].key };

  componentDidMount() {
    this.startSearch();
  }

  componentDidUpdate(prevProps) {
    const { canView: prevView } = prevProps;
    const { canView } = this.props;

    this.startSearch(prevView !== canView);
  }

  startSearch = permissionChange => {
    const { lastSearch } = this.state;
    const {
      canView,
      dispatch,
      history: {
        location: { search }
      }
    } = this.props;

    if (!canView) return;

    if (permissionChange || search !== lastSearch) {
      this.setState({ lastSearch: search });
      dispatch(searchActions.search());
    }
  };

  setListMode = e => {
    const listMode = e.target.value;
    localStorage.setItem(storageKey, listMode);
    this.setState({ listMode });
  };

  updateFilter = ({ checked, removeAll, attribute, facets, isSingleSelect }) => {
    const { dispatch } = this.props;
    if (isSingleSelect) return dispatch(searchActions.updateFilter({ attribute, facets }));
    if (removeAll) dispatch(searchActions.clearFilter({ attribute, facets }));
    if (!removeAll && !checked) dispatch(searchActions.removeFilter({ attribute, facets }));
    else dispatch(searchActions.addFilter({ attribute, facets }));
  };

  setSort = header => {
    const { dispatch } = this.props;

    const newSortBy = querySortBy[header];
    if (newSortBy) dispatch(searchActions.setSort(newSortBy));
  };

  render() {
    const { canView, selected, list, jobList, query, meta, fetchId, dispatch } = this.props;

    if (!canView) return null;

    const { listMode } = this.state;
    const grid = this.state.listMode === listOptions[0].key;
    const optionKeys = listOptions.map(option => option.key);
    const optionIcons = listOptions.map(option => <option.icon />);

    const jobProducts = [];
    if (jobList) {
      jobList.forEach(job => {
        const { external_id: productId } = job;
        if (!jobProducts.includes(productId)) {
          jobProducts.push(productId);
        }
      });
    }

    const productList =
      list &&
      list.map(item => ({
        ...item,
        status: jobProducts.includes(item.id) ? 'Processing' : 'Available',
        isChecked: selected.includes(item.id)
      }));

    /* Determine if there is more to load based on the metadata */
    let hasNextPage;
    if (meta) {
      const {
        totalHits,
        pagination: { page, limit }
      } = meta;
      hasNextPage = page * limit < totalHits;
    }

    const { sortBy, sortDir } = query || {};

    const { filters: baseFilters = [] } = meta || {};
    const { filters: selectedFilters = [] } = query || {};
    const filterProps = {
      filters: baseFilters.sort((a, b) => (parseInt(a.priority) > parseInt(b.priority) ? 1 : -1)),
      selected: selectedFilters,
      updateFilter: this.updateFilter
    };

    const disableFilters = dispatch(
      config.actions.getData(configMap.disabled.name, configMap.disabled.productFilters.name)
    );

    const viewProps = {
      productList,
      sortBy,
      sortDir,
      setSort: this.setSort
    };

    const infiniteProps = {
      list: productList,
      hasNextPage,
      fetching: !!fetchId,
      getNextPage: () => dispatch(searchActions.nextPage())
    };

    return disableFilters ? (
      <Container className={grid ? 'grid' : 'table'}>
        <HeaderContainer
          {...{ grid, optionKeys, optionIcons, listMode }}
          setListMode={this.setListMode}
        />
        <Card>
          <InfiniteList {...infiniteProps}>
            {grid ? (
              <GridViewContainer className="product-grid" {...viewProps} />
            ) : (
              <ListViewContainer {...viewProps} />
            )}
          </InfiniteList>
        </Card>
      </Container>
    ) : (
      <Container>
        <HeaderContainer
          {...{ grid, optionKeys, optionIcons, listMode }}
          setListMode={this.setListMode}
        />
        <SearchContainer className={grid ? 'grid' : 'table'}>
          <Filters {...filterProps} />
          <Card>
            <InfiniteList {...infiniteProps}>
              {grid ? (
                <GridViewContainer className="product-grid" {...viewProps} />
              ) : (
                <ListViewContainer {...viewProps} />
              )}
            </InfiniteList>
          </Card>
        </SearchContainer>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  canView: reducerUtil.getSlice(permissionsData, permissionsData.manager, state),
  list: reducerUtil.getSlice(listData, listData.list, state),
  selected: reducerUtil.getSlice(listData, listData.selected, state),
  query: reducerUtil.getSlice(listData, listData.query, state),
  meta: reducerUtil.getSlice(listData, listData.meta, state),
  fetchId: reducerUtil.getSlice(listData, listData.fetchId, state),
  jobList: reducerUtil.getSlice(jobsData, jobsData.list, state)
});

export default withRouter(connect(mapStateToProps)(ProductList));
