import { api, config, reducerUtil } from 'base-client';

import { reducerData as detailsData } from 'productDetails';
import detailsUtils from 'productDetails/utils';
import loadActions from './load';
import sustainabilityActions from './sustainability';
import { openModal, closeModal } from './modals';

const save = formId => (dispatch, getState) => {
  const [elementId, attributeId] = formId.split('.');
  const state = getState();
  const value = state.form[detailsUtils.form.name].values[elementId][attributeId];
  const initial = state.form[detailsUtils.form.name].initial[elementId][attributeId];
  if (value === initial) return Promise.resolve();

  const productId = reducerUtil.getSlice(detailsData, detailsData.productId, getState());
  const saveData = { productId, elementId, attributeId, value };

  switch (elementId) {
    case detailsUtils.form.system:
      return dispatch(saveSystem(saveData));
    case detailsUtils.form.product:
      return dispatch(saveProduct(saveData));
    default:
      return dispatch(saveElement(saveData));
  }
};

const saveSustainability = formId => async (dispatch, getState) => {
  const [elementId, optionId, group, attributeId] = formId.split('.');
  const state = getState();
  const value = state.form[detailsUtils.form.name].values[elementId][optionId][group][attributeId];
  const initial =
    state.form[detailsUtils.form.name].initial[elementId][optionId][group][attributeId];

  const productId = reducerUtil.getSlice(detailsData, detailsData.productId, getState());
  if (value === initial) return Promise.resolve();
  const saveData = { productId, optionId, attributeId, value };

  let promise;

  switch (group) {
    case detailsUtils.sustainabilityGroups[0]: //save Certificates
      promise = dispatch(sustainabilityActions.saveCertificate(saveData));
      break;
    case detailsUtils.sustainabilityGroups[1]: //save Environmental Characteristics
      promise = dispatch(sustainabilityActions.saveCharacteristics(saveData));
      break;
    case detailsUtils.sustainabilityGroups[2]: //save Rating Systems
      promise = dispatch(sustainabilityActions.updateRatingAttribute(saveData));
      break;
    default:
      break;
  }

  await promise;

  await dispatch(loadActions.refreshProduct());
};

const saveSystem = ({ productId, attributeId, value }) => async dispatch => {
  try {
    await dispatch(
      api.actions.patch(
        `products/${productId}/productVersions`,
        JSON.stringify({ [attributeId]: value })
      )
    );
    await dispatch(loadActions.refreshProduct());
  } catch (error) {
    dispatch(config.actions.error(error));
  }
};

const saveProduct = ({ productId, attributeId, value }) => async dispatch => {
  try {
    return await dispatch(
      api.actions.post(
        `products/${productId}/attributevalues/${attributeId}`,
        JSON.stringify({ isFormula: false, formula: null, value: null, ...value })
      )
    );
  } catch (error) {
    dispatch(config.actions.error(error));
  }
};

const saveElement = ({ productId, elementId, attributeId, value }) => async dispatch => {
  try {
    return await dispatch(
      api.actions.put(
        `products/${productId}/elements/${elementId}/attributevalues/${attributeId}`,
        JSON.stringify({ isFormula: false, formula: null, value: null, ...value })
      )
    );
  } catch (error) {
    dispatch(config.actions.error(error));
  }
};

const removeElement = index => (dispatch, getState) => {
  const state = getState();
  const productId = reducerUtil.getSlice(detailsData, detailsData.productId, state);
  const elements = reducerUtil.getSlice(detailsData, detailsData.elements, state);

  const { id: elementId, name: elementName } = elements[index || 0];
  dispatch(
    openModal(detailsUtils.modalNames.removeElement, {
      elementName,
      onConfirm: async () => {
        dispatch(closeModal());
        try {
          await dispatch(api.actions.delete(`products/${productId}/elements/${elementId}`));
          return dispatch(loadActions.refreshProduct());
        } catch (error) {
          dispatch(config.actions.error(error));
        }
      }
    })
  );
};

const addAttribute = (attributeId, all, { onRevit, envChar, index }) => async (
  dispatch,
  getState
) => {
  if (!attributeId) return;

  const state = getState();
  const productId = reducerUtil.getSlice(detailsData, detailsData.productId, state);
  const elements = reducerUtil.getSlice(detailsData, detailsData.elements, state);

  const saveData = { productId, attributeId, value: {} };

  let promises;
  if (envChar) {
    // promises = [dispatch(saveEnvCharacteristics({ ...saveData }))];
  } else if (!onRevit) {
    promises = [dispatch(saveProduct({ ...saveData }))];
  } else if (all) {
    promises = elements.map(({ id }) => dispatch(saveElement({ ...saveData, elementId: id })));
  } else {
    promises = [dispatch(saveElement({ ...saveData, elementId: elements[index || 0].id }))];
  }

  try {
    await Promise.all(promises);
    return dispatch(loadActions.refreshProduct());
  } catch (error) {
    dispatch(config.actions.error(error));
  }
};

const removeAttribute = (attributeId, all, { onRevit, envChar, index }) => async (
  dispatch,
  getState
) => {
  if (!attributeId) return;

  const state = getState();
  const productId = reducerUtil.getSlice(detailsData, detailsData.productId, state);
  const elements = reducerUtil.getSlice(detailsData, detailsData.elements, state);

  let promises;
  if (envChar) {
    // promises = [dispatch(api.actions.delete('envCharacteristics'))];
  } else if (!onRevit) {
    promises = [
      dispatch(api.actions.delete(`products/${productId}/attributevalues/${attributeId}`))
    ];
  } else if (all) {
    promises = elements.map(({ id }) =>
      dispatch(
        api.actions.delete(`products/${productId}/elements/${id}/attributevalues/${attributeId}`)
      )
    );
  } else {
    promises = [
      dispatch(
        api.actions.delete(
          `products/${productId}/elements/${elements[index || 0].id}/attributevalues/${attributeId}`
        )
      )
    ];
  }

  try {
    await Promise.all(promises);
    return dispatch(loadActions.refreshProduct());
  } catch (error) {
    dispatch(config.actions.error(error));
  }
};

export default {
  save,
  saveSustainability,
  removeElement,
  addAttribute,
  removeAttribute
};
