import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../SvgIcon';

const ProductsAddedIcon = props => (
  <SvgIcon {...props}>
    <path
      d="M10 0.00097649C4.486 0.00097649 0 4.48698 0 10.001C0 15.515 4.486 20.001 10 20.001C15.514 20.001 20 15.515 20 10.001C20 4.48698 15.514 0.00097649 10 0.00097649ZM15 11.001H11V15.001H9V11.001H5V9.00098H9V5.00098H11V9.00098H15V11.001Z"
      fill="#6ABF43"
    />
  </SvgIcon>
);

ProductsAddedIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
};

ProductsAddedIcon.defaultProps = {
  width: 20,
  height: 20
};

export default ProductsAddedIcon;
