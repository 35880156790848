import uuid from 'uuid/v4';
import { api, config, reducerUtil } from 'base-client';

import { reducerData as tenantData } from 'tenant';
import { reducerData as projectDetailData } from 'projectDetails';

const loadSavedProducts = (projectId, restart) => async (dispatch, getState) => {
  if (!projectId) return;

  let state = getState();

  //clear old data if restart loading data
  if (restart)
    dispatch(reducerUtil.setSlice(projectDetailData, projectDetailData.products, undefined));

  //get tenant ID
  const tenantId = reducerUtil.getSlice(tenantData, tenantData.tenant, state);

  // get the page information
  const { page: currentPage = 0 } =
    reducerUtil.getSlice(projectDetailData, projectDetailData.pagination, state) || {};
  const page = restart ? 1 : currentPage + 1;
  const limit = 100;

  const fetchId = uuid();
  dispatch(reducerUtil.setSlice(projectDetailData, projectDetailData.fetchId, fetchId));

  try {
    const apiEndpoint = `projects/${projectId}/products?tenant_id=${tenantId}&order[name]=asc&page=${page}&limit=${limit}`;

    const { projectProducts: products = [], pagination } = await dispatch(
      api.actions.get(apiEndpoint, true)
    );

    // check that this is the correct fetch
    state = getState();
    if (fetchId !== reducerUtil.getSlice(projectDetailData, projectDetailData.fetchId, state))
      return;
    // get current product info if not restarting
    const currentList =
      (!restart && reducerUtil.getSlice(projectDetailData, projectDetailData.products, state)) ||
      [];

    //check if the product has been deleted then add text to describe it.
    const formattedList = products.map(({ product, name }) => {
      const { isActive, publishedAt, activeVersion, id } = product || {};

      if (isActive) {
        const { productThumbnail: imageId } = activeVersion;
        return {
          id,
          isActive,
          imageId,
          name: !publishedAt ? `${name} (Unpublished)` : name
        };
      } else {
        return {
          id,
          isActive,
          name: `${name} (Deleted)`
        };
      }
    });

    dispatch(
      reducerUtil.setSlice(projectDetailData, projectDetailData.products, [
        ...currentList,
        ...formattedList
      ])
    );
    dispatch(reducerUtil.setSlice(projectDetailData, projectDetailData.pagination, pagination));
    // allow another search
    dispatch(reducerUtil.setSlice(projectDetailData, projectDetailData.fetchId, undefined));
  } catch (error) {
    dispatch(config.actions.error(error));
  }
};

const loadNextPage = projectId => dispatch => dispatch(loadSavedProducts(projectId));

export default {
  loadSavedProducts,
  loadNextPage
};
