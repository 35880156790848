import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../SvgIcon';

const AlertIcon = (props) => (
  <SvgIcon {...props}>
		<path d="M 10 0C 4.486 0 0 4.486 0 10C 0 15.515 4.486 20 10 20C 15.514 20 20 15.515 20 10C 20 4.486 15.514 0 10 0ZM 9 4L 11 4L 11 11L 9 11L 9 4ZM 10 15.25C 9.31 15.25 8.75 14.691 8.75 14C 8.75 13.31 9.31 12.75 10 12.75C 10.69 12.75 11.25 13.31 11.25 14C 11.25 14.691 10.69 15.25 10 15.25Z" />
  </SvgIcon>
)

AlertIcon.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  viewBox: PropTypes.string.isRequired,
};

AlertIcon.defaultProps = {
  width: 20,
  height: 20,
  viewBox: '0 0 20 20',
};

export default AlertIcon;
