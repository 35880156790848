import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Checkbox from '../../InputGroups/Checkbox';

const Container = styled.div`
  display: flex;
  margin-bottom: 1rem;
`;

const Label = styled.label`
  margin-left: 0.35rem;
  font-size: 1.4rem;
  position: relative;
  top: -3px;
`;

const Facet = ({ name, filterAttribute, checked, disabled = false, updateFilter }) => (
  <Container>
    <Checkbox
      size={18}
      isChecked={checked}
      isLocked={disabled}
      onChange={() =>
        updateFilter({ checked: !checked, attribute: filterAttribute, facets: [name] })
      }
    />
    <Label htmlFor={name}>{name}</Label>
  </Container>
);

Facet.propTypes = {
  name: PropTypes.string.isRequired,
  filterAttribute: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  updateFilter: PropTypes.func.isRequired
};

Facet.defaultProps = {
  checked: false
};

export default Facet;
