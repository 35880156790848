import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from './../SvgIcon';

const DownloadIcon = (props) => (
  <SvgIcon {...props}>
		<polygon points="15.293,6.294 13,8.587 13,2.001 11,2.001 11,8.587 8.707,6.294 7.293,7.708 12,12.416 16.707,7.708 "/>
		<path d="M20,14.002H4c-1.103,0-2,0.896-2,2v4c0,1.102,0.897,2,2,2h16c1.104,0,2-0.898,2-2v-4 C22,14.898,21.104,14.002,20,14.002z M15,19.001c-0.553,0-1-0.447-1-1s0.447-1,1-1s1,0.447,1,1S15.553,19.001,15,19.001z M18,19.001c-0.553,0-1-0.447-1-1s0.447-1,1-1s1,0.447,1,1S18.553,19.001,18,19.001z"/>
  </SvgIcon>
)

DownloadIcon.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  viewBox: PropTypes.string.isRequired,
};

DownloadIcon.defaultProps = {
  width: 20,
  height: 14,
  viewBox: '0 0 24 22',
};

export default DownloadIcon;