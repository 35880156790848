import React from 'react';
import styled from 'styled-components';

const Styled = styled.div`
  header {
    margin-top: 20px;
    font-size: 2.2rem;
    font-weight: 500;
  }
  label {
    display: block;
    font-size: 1.4rem;
    margin-top: 30px;
    font-weight: 500;
  }
`;

class ContactSalesMessage extends React.Component {
  render() {
    const { data } = this.props;
    return (
      <Styled>
        <header>Contact Sales</header>
        <label>Message Details</label>
        <p>{data.message}</p>
      </Styled>
    );
  }
}

export default ContactSalesMessage;
