import Button from "shared/Buttons";
import { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { lambdaApiCall } from "utils/fetch";
import { Spinner } from "shared-features-client";
import { removeNotification, sendNotifications } from "../../shared/Toast";
import { AlertIcon } from "shared/Miscellaneous/Icons";
import { configMap } from "configurations";
import { config } from "base-client";

const StyledTable = styled.table`
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 16px;
  background-color: #fff;
`;

const StyledTd = styled.td`
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
`;

const StyledTBody = styled.tbody`
  tr {
    &:hover {
      background-color: #eee;
    }
  }
`;

const StyledTh = styled.th`
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
`;

class APIKeys extends Component {
  constructor() {
    super();
    this.concoraExternalAPIEndpoint = "";

    this.state = {
      keys: null,
      newKey: null,
      isLoading: false,
    };
  }

  fetchKeys = async () => {
    this.setState({ isLoading: true });
    try {
      const res = await this.props.dispatch(
        lambdaApiCall({
          endpoint: `${this.concoraExternalAPIEndpoint}`,
          method: "GET",
        })
      );
      if (res?.data) this.setState({ keys: res.data });
      else {
        this.props.dispatch(
          sendNotifications([
            { type: "error", message: res?.message || "Something went wrong!" },
          ])
        );
        this.setState({ keys: null });
      }
    } catch (error) {
      this.props.dispatch(
        sendNotifications([{ type: "error", message: "Something Went wrong!" }])
      );
    }
    this.setState({ isLoading: false });
  };

  createKey = async () => {
    if (this.state.isLoading) return;
    this.setState({ isLoading: true });
    try {
      const res = await this.props.dispatch(
        lambdaApiCall({
          endpoint: `${this.concoraExternalAPIEndpoint}`,
          method: "POST",
        })
      );
      if (res?.data) {
        if (res.message) {
          this.props.dispatch(
            sendNotifications([{ type: "success", message: res.message }])
          );
        }
        this.setState({ newKey: res.data });
      } else {
        this.props.dispatch(
          sendNotifications([
            { type: "error", message: res?.message || "Something went wrong." },
          ])
        );
        this.setState({ newKey: null });
      }
    } catch (error) {
      this.props.dispatch(
        sendNotifications([{ type: "error", message: "Something Went wrong!" }])
      );
    }
    this.setState({ isLoading: false });
  };

  deleteKey = async (id) => {
    if (this.state.isLoading) return;
    this.setState({ isLoading: true });
    try {
      const res = await this.props.dispatch(
        lambdaApiCall({
          endpoint: `${this.concoraExternalAPIEndpoint}/${id}`,
          method: "DELETE",
        })
      );
      if (res?.message) {
        this.props.dispatch(
          sendNotifications([{ type: "success", message: res.message }])
        );
      }
    } catch (error) {
      this.props.dispatch(
        sendNotifications([{ type: "error", message: "Something Went wrong!" }])
      );
    }

    this.fetchKeys();
  };

  componentDidMount() {
    this.concoraExternalAPIEndpoint = this.props.dispatch(
      config.actions.getData(configMap.lambda.name, configMap.lambda.concoraExternalAPI.name)
    );
    this.fetchKeys();
  }

  render() {
    return (
      <>
        {this.state.isLoading && <Loader />}
        <div className="container">
          <div style={{ marginBottom: "16px" }}>
            <Button buttonStyle="create" onClick={this.createKey}>
              Create New Key
            </Button>

            {this.state.newKey && (
              <>
                <div style={{ padding: "16px" }}>
                  <div>
                    <b>Key:</b> {this.state.newKey.apiKey}
                  </div>
                  <div>
                    <b>Secret Key:</b> {this.state.newKey.secretKey}
                  </div>
                </div>

                <div
                  style={{
                    backgroundColor: "#fcf8e3",
                    borderColor: "#faebcc",
                    color: "#8a6d3b",
                    border: "1px solid transparent",
                    borderRadius: "4px",
                    marginBottom: "20px",
                    padding: "15px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <AlertIcon style={{ marginRight: "16px" }} />
                  <strong>Secret Key will only be shown once.</strong> If you loose it, you will
                  have to create a new one
                </div>
              </>
            )}
          </div>

          <h3>Api Keys</h3>

          <StyledTable>
            <thead>
              <tr>
                <StyledTh>ID</StyledTh>
                <StyledTh>Keys</StyledTh>
                <StyledTh>Secret Key</StyledTh>
                <StyledTh>Action</StyledTh>
              </tr>
            </thead>
            <StyledTBody>
              {this.state.keys &&
                this.state.keys.map((item) => (
                  <tr key={item.id}>
                    <StyledTd>{item.id}</StyledTd>
                    <StyledTd>{item.apiKey}</StyledTd>
                    <StyledTd>{item.secretKey || "*****"}</StyledTd>
                    <StyledTd>
                      <Button buttonStyle="red" onClick={() => this.deleteKey(item.id)}>
                        Delete
                      </Button>
                    </StyledTd>
                  </tr>
                ))}
            </StyledTBody>
          </StyledTable>

          <a href="#">Documentation Link</a>
        </div>
      </>
    );
  }
}

function Loader() {
  return (
    <div
      style={{
        position: "fixed",
        padding: "32px",
        top: "0px",
        left: "0px",
        width: "100vw",
        height: "100vh",
        overflow: "hidden",
        textAlign: "center",
      }}
    >
      <Spinner size={50} />
    </div>
  );
}

export default connect()(APIKeys);
