import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../SvgIcon';

const ProductsRemovedIcon = props => <SvgIcon {...props}>
  <path d="M10 0.00097649C4.486 0.00097649 0 4.48698 0 10.001C0 15.515 4.486 20.001 10 20.001C15.514 20.001 20 15.515 20 10.001C20 4.48698 15.514 0.00097649 10 0.00097649ZM15 11.001H11H9H5V9.00098H9H11H15V11.001Z" fill="#D95A5A"/>
</SvgIcon>;

ProductsRemovedIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
};

ProductsRemovedIcon.defaultProps = {
  width: 20,
  height: 20
};

export default ProductsRemovedIcon;
