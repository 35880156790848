import { api, config, reducerUtil } from "base-client";
import uuid from "uuid/v4";

import { actions as detailsActions, reducerData as detailsData } from "productDetails";

const maxAttempts = 3;

const addExternalFiles = (files) => async (dispatch, getState) => {
  const fileArr = Array.isArray(files) ? files : [files];
  const product_id = reducerUtil.getSlice(detailsData, detailsData.productId, getState());
  const fileList = reducerUtil.getSlice(detailsData, detailsData.fileList, getState()) || [];
  const newFileList = [...fileList];

  for await (const file of fileArr) {
    const currentFile = {
      group: "External Asset",
      name: file.assetName,
      location: file.url,
      mimetype: 'url',
      size: 0,
    };
    try {
      await dispatch(
        api.actions.post(`products/${product_id}/assets/external`, { ...currentFile, product_id })
      );
      newFileList.push({ ...currentFile, success: true });
    } catch (err) {
      console.error({ err });
      newFileList.push({ ...currentFile, failed: true });
    }
  }

  dispatch(reducerUtil.setSlice(detailsData, detailsData.fileList, newFileList));
  dispatch(detailsActions.refreshProduct());
};

const addFiles = (files) => (dispatch) => {
  if (!Array.isArray(files)) files = [files];
  return files.map((file) => dispatch(trackFile(file)));
};

const trackFile = (file) => async (dispatch, getState) => {
  let fileList = reducerUtil.getSlice(detailsData, detailsData.fileList, getState()) || [];
  const currentFile = { id: uuid(), name: file.name };
  dispatch(reducerUtil.setSlice(detailsData, detailsData.fileList, [...fileList, currentFile]));

  try {
    const productId = reducerUtil.getSlice(detailsData, detailsData.productId, getState());
    const body = new FormData();
    body.append("file", file);

    await dispatch(tryUpload(`products/${productId}/assets`, body));

    fileList = reducerUtil.getSlice(detailsData, detailsData.fileList, getState()) || [];
    const index = fileList.findIndex((item) => currentFile.id === item.id);

    if (index < 0) return;

    const newFileList = [...fileList];
    newFileList[index] = { ...fileList[index], success: true };
    dispatch(reducerUtil.setSlice(detailsData, detailsData.fileList, newFileList));
  } catch (error) {
    dispatch(config.actions.error(error));

    fileList = reducerUtil.getSlice(detailsData, detailsData.fileList, getState()) || [];
    const index = fileList.findIndex((item) => currentFile.id === item.id);

    if (index < 0) return;

    const newFileList = [...fileList];
    newFileList[index] = { ...fileList[index], failed: true };
    dispatch(reducerUtil.setSlice(detailsData, detailsData.fileList, newFileList));
  }

  dispatch(detailsActions.refreshProduct());
};

const tryUpload =
  (url, body, attempts = 0) =>
  async (dispatch) => {
    try {
      return await dispatch(api.actions.postFile(url, body));
    } catch (error) {
      if (attempts < maxAttempts) dispatch(tryUpload(url, body, attempts + 1));
      else throw error;
    }
  };

const stopTrack = () => (dispatch, getState) => {
  const fileList = reducerUtil.getSlice(detailsData, detailsData.fileList, getState()) || [];
  let done = true;

  for (let i = 0; i < fileList.length && done; ++i) {
    const { success, failed } = fileList[i] || {};
    if (!success && !failed) done = false;
  }

  if (!done) return;

  dispatch(clearTrack());
};

const clearTrack = () => (dispatch) => {
  dispatch(reducerUtil.setSlice(detailsData, detailsData.fileList, undefined));
};

const removeFiles = (assetIds) => async (dispatch, getState) => {
  if (!Array.isArray(assetIds)) assetIds = [assetIds];
  const productId = reducerUtil.getSlice(detailsData, detailsData.productId, getState());

  try {
    await Promise.all(
      assetIds.map((assetId) =>
        dispatch(api.actions.delete(`products/${productId}/assets/${assetId}`))
      )
    );
  } catch (error) {
    dispatch(config.actions.error(error));
  }

  dispatch(detailsActions.refreshProduct());
};

const updateAsset = ( updatedAsset) => async(dispatch, getState) => {
  const {id} = updatedAsset
  const productId = reducerUtil.getSlice(detailsData, detailsData.productId, getState());

  try{
    let res = await dispatch(api.actions.put(`products/${productId}/assets/${id}`, JSON.stringify(updatedAsset)))
    if(res){
      await dispatch(detailsActions.refreshProduct());
    }
  } catch (e){
    dispatch(config.actions.error(e));
  }
  
}

export default { addExternalFiles, addFiles, removeFiles, stopTrack, clearTrack, updateAsset };
