/* vendor imports */
import { connect } from 'react-redux';
import { login, reducerUtil } from 'base-client';

/* system imports */
import HeaderView from '../components/Header';
import { actions as loginActions } from 'login';
import { reducerData as permissionsData } from 'permissions';
import { reducerData as listData, actions as listActions } from 'productList';

const mapStateToProps = state => {
  const { queryString: text } = reducerUtil.getSlice(listData, listData.query, state) || {};
  return {
    loggedIn: !!reducerUtil.getSlice(login.reducerData, login.reducerData.token, state),
    searchValue: text,
    canView: reducerUtil.getSlice(permissionsData, permissionsData.manager, state)
  };
};

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(loginActions.openLogout()),
  handleSearch: name => dispatch(listActions.setText(name))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderView);
