const querySortBy = {
  _score: '_score',
  name: 'name',
  category: 'category',
  updated: 'updatedAt',
  published: 'publishedAt'
};

const querySortDir = {
  descending: 'desc',
  ascending: 'asc'
};

const querySortByDirections = {
  _score: 'desc',
  name: 'asc',
  category: 'asc',
  updated: 'desc',
  published: 'desc'
};

const searchPage = '/products';

export { querySortBy, querySortByDirections, querySortDir, searchPage };
