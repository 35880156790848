import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reducerUtil } from 'base-client';

import { SectionHeader } from 'shared';
import Button from 'shared/Buttons';
import { DownloadIcon, DeleteIcon } from 'shared/Miscellaneous/Icons';
import Card from 'shared/PageLayout/Card';
import PublishButton from './PublishButton';
import { actions as detailsActions, reducerData as detailsData } from 'productDetails';
import detailsUtils from 'productDetails/utils';
import 'shared/Navigation/Tabs/assets/tabs.scss';

const EditContainer = ({ dispatch, status, children }) => {
  const published = !!status[detailsUtils.statusNames.published];
  return (
    <div>
      <SectionHeader title="Edit & Publish Product">

        <div className="product__controls">

          <Button
            onClick={() => dispatch(detailsActions.downloadProduct())}
            icon={<DownloadIcon />}
          >
            Download
          </Button>
          <Button
            buttonStyle="red"
            onClick={() => dispatch(detailsActions.deleteProduct())}
            icon={<DeleteIcon />}
          >
            Delete
          </Button>
          <PublishButton
            onClick={(publish = true) => dispatch(detailsActions.publishProduct(publish))}
            published={published}
          />
        </div>
      </SectionHeader>
      <Card>{children}</Card>
    </div>
  );
};

EditContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]),
  status: PropTypes.shape({
    [detailsUtils.statusNames.published]: PropTypes.string
  }).isRequired
};

const mapStateToProps = state => ({
  status: reducerUtil.getSlice(detailsData, detailsData.status, state)
});

export default connect(mapStateToProps)(EditContainer);
