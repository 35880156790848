import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { actions as detailsActions } from 'productDetails';
import { ProductOption } from './FormFields';
import { AddButton } from 'shared/Buttons';
import EditProductOption from './EditProductOption';

const StyledWrapper = styled.div`
  width: 100%;
`;

const ProductOptions = ({ options, handleSelectOption, selectedOption, dispatch }) => {
  const [open, setOpen] = useState(false);
  const { id: activeId } = options[selectedOption] || {};

  const onClickOpen = () => {
    setOpen(true);
  };

  const onClickClose = () => setOpen(false);

  const onClickCreate = async name => {
    await dispatch(detailsActions.createOption({ name }));
    setOpen(false);
  };

  const onClickSave = async (optionId, name) => {
    await dispatch(detailsActions.saveOption({ optionId, name }));
    setOpen(false);
  };

  const onClickDelete = optionId => {
    dispatch(detailsActions.deleteOption({ optionId }));
  };

  const onClick = id => {
    const option = options.findIndex(x => x.id === id);
    handleSelectOption(option);
  };

  const addProps = { options, onClickClose, onClickSave };
  const optionProps = {
    options,
    onClickOpen,
    onClickSave,
    onClickCreate,
    onClickDelete,
    onClick,
    activeId,
    dispatch
  };

  return (
    <StyledWrapper>
      {options &&
        options.map(({ id, name }) => (
          <ProductOption key={id} {...optionProps} {...{ id, name }} />
        ))}
      {open && <EditProductOption {...addProps} />}
      <AddButton onClick={() => onClickOpen()}>Add Product Option</AddButton>
    </StyledWrapper>
  );
};

ProductOptions.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string
    })
  )
};

ProductOptions.defaultProps = {
  options: [],
  selectedOption: 0
};

export default connect()(ProductOptions);
