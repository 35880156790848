import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../SvgIcon';

const InformationIcon = (props) => (
  <SvgIcon {...props}>
		<path d="M12,2C6.486,2,2,6.487,2,12c0,5.515,4.486,10,10,10s10-4.485,10-10C22,6.487,17.514,2,12,2z M12,6.751 c0.69,0,1.25,0.56,1.25,1.25c0,0.691-0.56,1.25-1.25,1.25S10.75,8.691,10.75,8C10.75,7.31,11.31,6.751,12,6.751z M15,17H9v-2h2v-3 h-1v-2h2c0.553,0,1,0.448,1,1v4h2V17z"/>
  </SvgIcon>
)

InformationIcon.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  viewBox: PropTypes.string.isRequired,
};

InformationIcon.defaultProps = {
  width: 24,
  height: 24,
  viewBox: '0 0 24 24',
};

export default InformationIcon;
