import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../SvgIcon';

const CheckIcon = (props) => (
  <SvgIcon {...props}>
		<path d="M 10 0C 4.477 0 5.53131e-08 4.477 5.53131e-08 10C 5.53131e-08 15.523 4.477 20 10 20C 15.523 20 20 15.523 20 10C 20 4.477 15.522 0 10 0ZM 9.25 15.75L 4.25 12L 5.75 10L 8.75 12.25L 14 5.25L 16 6.75L 9.25 15.75Z" />
  </SvgIcon>
)

CheckIcon.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  viewBox: PropTypes.string.isRequired,
};

CheckIcon.defaultProps = {
  width: 21,
  height: 20,
  viewBox: '0 0 21 20',
};

export default CheckIcon;
