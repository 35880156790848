/* vendor imports */
import React from 'react';
import PropTypes from 'prop-types';

/* system imports */
import ModalContainer from 'shared/Overlays/Modal';
import Button from 'shared/Buttons';
import { formUserNewName } from 'admin/utils';
import chooseForm from 'shared/Forms/redux-form';

const NewUserModal = ModalContainer(formUserNewName);

const NewUserView = ({ roles, handleSubmit, clickCreateNewUser, disableCreateUser }) => (
  <NewUserModal>
    <form onSubmit={handleSubmit}>
      <div>
        {chooseForm({
          key: 'email',
          title: 'Email',
          type: 'email'
        })}
      </div>
      <div>
        {chooseForm({
          key: 'role',
          title: 'Role',
          type: 'select',
          options: roles
        })}
      </div>
    </form>
    <Button onClick={clickCreateNewUser} disabled={disableCreateUser}>
      Add User
    </Button>
  </NewUserModal>
);

NewUserView.propTypes = {
  roles: PropTypes.array,
  handleSubmit: PropTypes.func.isRequired,
  clickCreateNewUser: PropTypes.func.isRequired,
  disableCreateUser: PropTypes.bool
};

NewUserView.defaultProps = {
  roles: [],
  disableCreateUser: true
};

export default NewUserView;
