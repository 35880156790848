import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../SvgIcon';

const TotalIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M20,21h-6v-2h5v-5h2v6C21,20.553,20.553,21,20,21z"/>
    <path d="M21,10h-2V5h-5V3h6c0.553,0,1,0.448,1,1V10z"/>
    <path d="M5,10H3V4c0-0.552,0.447-1,1-1h6v2H5V10z"/>
    <path d="M10,21H4c-0.553,0-1-0.448-1-1v-6h2v5h5V21z"/>
  </SvgIcon>
)

TotalIcon.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  viewBox: PropTypes.string.isRequired,
};

TotalIcon.defaultProps = {
  width: 24,
  height: 24,
  viewBox: '0 0 24 24',
};

export default TotalIcon;
