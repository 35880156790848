import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { Row } from 'shared-features-client';
import { actions as detailsActions, utils as detailsUtils } from 'productDetails';
import Checkbox from './SustainabilityCheckbox';
import { Modal } from 'shared/Modals';
import { AddButton } from 'shared/Buttons';
import sbManagerStyles from 'utils/globalStyles';
import SearchBar from 'shared/Searchbar';
import debounce from 'lodash/debounce';

const modalStyles = {
  width: '90%',
  height: '90%'
};

const StyledRow = styled(Row)`
  border-bottom: 1px solid ${() => sbManagerStyles.greyLight};
  padding: 2rem;
  margin: 0 -2rem;
  &:last-of-type {
    border-bottom: none;
    padding-bottom: 0;
  }
  align-items: center;
`;

const Header = styled(StyledRow)`
  justify-content: space-between;
`;

const Values = styled.div`
  width: 85%;
  margin-left: 2rem;
  display: flex;
  justify-content: flex-start;
`;

const Subheader = styled(Values)`
  margin-left: ${props =>
    props.group === detailsUtils.sustainabilityGroups[0] ? '6.5rem' : '2rem'};
  text-transform: uppercase;
  color: ${() => sbManagerStyles.greyMedium};
  font-weight: bold;
  div {
    cursor: pointer;
  }
`;

const List = styled.div`
  overflow: auto;
  max-height: 72%;
`;

const Column = styled.div`
  text-align: left;
  line-height: 3rem;
  height: 3rem;
  width: 50%;
`;

const Image = styled.img`
  height: 3rem;
  margin-right: 2rem;
`;

const SustainabilityModal = ({ modalList, selectedItems, modalProps, group, dispatch }) => {
  const { addButtonLabel, modalTitle, columnLabels, modalButtonLabel } = modalProps;
  const [checkedItems, setCheckedItems] = useState(selectedItems || []);
  const [sortOption, setSort] = useState(undefined);
  const [searchValue, setSearchValue] = useState('');
  const [list, setList] = useState(modalList);
  const [modalIsOpen, setModal] = useState(false);
  const [allChecked, setAllChecked] = useState(false);

  useEffect(() => {
    setCheckedItems(selectedItems); // bugfix for checkedItems not updating when !selectedItems
  }, [selectedItems])

  const disabled = checkedItems.length < 1 ? true : false;

  const handleCheck = e => {
    const { id, checked } = e.target;
    if (checked) setCheckedItems([...checkedItems, id]);
    else {
      setCheckedItems(checkedItems.filter(item => item !== id));
    }
  };

  const selectAll = () => {
    if (allChecked) {
      setCheckedItems([]);
      setAllChecked(false);
    } else {
      setCheckedItems(list.map(({ id }) => id));
      setAllChecked(true);
    }
  };

  const openModal = () => setModal(true);
  const closeModal = () => {
    setModal(false);
  };

  const handleSubmit = () => {
    if (group === detailsUtils.sustainabilityGroups[1])
      dispatch(detailsActions.addCharacteristics({ attributeIds: checkedItems }));
    if (group === detailsUtils.sustainabilityGroups[0])
      dispatch(detailsActions.addCertificates({ attributeIds: checkedItems }));
    closeModal();
  };

  const sort = sortBy => {
    let sortedList;

    if (!sortOption || sortOption !== sortBy) {
      sortedList = dispatch(detailsActions.sortList(sortBy, 'a-z', list));
      setSort(sortBy);
    } else {
      sortedList = dispatch(detailsActions.sortList(sortBy, 'z-a', list));
      setSort(undefined);
    }

    setList(sortedList);
  };

  const handleSearch = text => {
    setSearchValue(text);
    filter(text);
  };

  const filter = debounce(text => {
    const filteredList = dispatch(detailsActions.filterList(text, group));
    setList(filteredList);
  }, 300);

  const searchProps = {
    searchValue: searchValue,
    handleSearch: handleSearch,
    placeholder: 'Search'
  };

  return (
    <div>
      <AddButton iconPosition="after" onClick={() => openModal()}>
        {addButtonLabel}
      </AddButton>
      <Modal
        open={modalIsOpen}
        title={modalTitle}
        onClose={() => closeModal()}
        customStyles={modalStyles}
      >
        <Header>
          <SearchBar {...searchProps} />
          <AddButton
            disabled={disabled}
            iconPosition="before"
            addIcon="addIcon"
            border
            onClick={() => handleSubmit()}
          >
            {modalButtonLabel}
          </AddButton>
        </Header>
        <StyledRow>
          <Checkbox
            id={`select-all-${group}`}
            name="Select All"
            checked={allChecked}
            onChange={() => selectAll()}
          />
          <Subheader group={group}>
            {columnLabels &&
              columnLabels.map(label => (
                <Column key={label} onClick={() => sort(label)}>
                  {label}
                </Column>
              ))}
          </Subheader>
        </StyledRow>
        <List>
          {list &&
            list.map(({ id, name, image, type }) => (
              <StyledRow key={id}>
                <Checkbox
                  id={id}
                  name={name}
                  checked={!!checkedItems.find(item => item === id)}
                  onChange={e => handleCheck(e)}
                />
                <Values>
                  {image && <Image src={image} alt="logo" />}
                  <Column>{name}</Column>
                  {type && <Column>{type}</Column>}
                </Values>
              </StyledRow>
            ))}
        </List>
      </Modal>
    </div>
  );
};

SustainabilityModal.propTypes = {
  modalList: PropTypes.array.isRequired,
  modalTitle: PropTypes.string,
  addButtonLabel: PropTypes.string,
  columnLabels: PropTypes.array,
  group: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired
};

export default connect()(SustainabilityModal);
