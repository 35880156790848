import { connect } from 'react-redux';
import { history } from 'base-client';

import { toggleSidebar } from 'sidebar/sidebar';
import SidebarNavigationItemView from '../components/SidebarNavigationItemView';

const mapDispatchToProps = dispatch => ({
  push: url => history.push(url),
  toggleSidebar: () => dispatch(toggleSidebar())
});

export default connect(
  null,
  mapDispatchToProps
)(SidebarNavigationItemView);
