import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

import sbManagerStyles from 'utils/globalStyles';

const Container = styled.div`
  width: 100%;
`;

const Label = styled.div`
  color: ${() => sbManagerStyles.greyDarkest01};
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.6rem;
`;

const Input = styled.input`
  width: 45rem;
  height: 3.5rem;
  border: 1px solid ${() => sbManagerStyles.greyLight};
  border-radius: 4px;
  padding-left: 1rem;
  font-size: 1.2rem;
  font-family: inherit;
  cursor: pointer;
  background-color: ${() => sbManagerStyles.white};
  ::placeholder {
    color: ${() => sbManagerStyles.greyDarkest};
    opacity: 1;
  }
  &:focus {
    outline: none;
  }
`;

const ExpirationDate = ({ input, handleOnChange }) => {
  return (
    <Container>
      <Label>Expiration Date</Label>
      <Input
        id="expiration-date"
        type="date"
        {...input}
        onBlur={() => {
          if (handleOnChange) handleOnChange(input.name);
        }}
      />
    </Container>
  );
};

export default ExpirationDate;
