import { api, config, reducerUtil } from 'base-client';

import { reducerData as cacheData } from 'cache';

/* variables */
const postDelay = 5 * 60 * 1000;

const addCache = (key, data) => (dispatch, getState) => {
  if (!key || !data) return;
  const oldCache = reducerUtil.getSlice(cacheData, cacheData.cache, getState()) || {};
  const cache = { ...oldCache, [key]: { data, time: Date.now() } };
  dispatch(reducerUtil.setSlice(cacheData, cacheData.cache, cache));
};

const removeCache = key => (dispatch, getState) => {
  if (!key) return;
  const oldCache = reducerUtil.getSlice(cacheData, cacheData.cache, getState()) || {};
  const cache = { ...oldCache };
  delete cache[key];
  dispatch(reducerUtil.setSlice(cacheData, cacheData.cache, cache));
};

const postCache = (key, url, body) => async (dispatch, getState) => {
  const cache = reducerUtil.getSlice(cacheData, cacheData.cache, getState()) || {};
  const item = cache[key];

  if (item && Date.now() - item.time < postDelay) {
    return new Promise(resolve => setTimeout(() => resolve(item.data), 20));
  }

  try {
    const data = await dispatch(api.actions.post(url, body));
    dispatch(addCache(key, data));
    return data;
  } catch (error) {
    dispatch(config.actions.error(error));
  }
};

export default {
  addCache,
  removeCache,
  postCache
};
