import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from './../SvgIcon';

const FileRfaIcon = (props) => (
  <SvgIcon {...props}>
	<g id="Canvas" transform="translate(-4933 -897)">
		<g id="File-rfa">
			<g id="Filled Icons">
				<g id="Vector">
					<path d="M 11.414 0L 2 0C 0.897 0 0 0.898 0 2L 0 18C 0 19.103 0.897 20 2 20L 14 20C 15.103 20 16 19.103 16 18L 16 4.586L 11.414 0ZM 10 6L 10 1L 15 6L 10 6Z" fill="#77899E" transform="translate(4933 897)"/>
				</g>
				<g id="RFA">
					<path d="M 4.30851 6L 3.09651 6L 2.36451 4.65C 2.27251 4.494 2.12651 4.416 1.92651 4.416L 1.59051 4.416L 1.59051 6L 0.504508 6L 0.504508 1.77L 2.52051 1.77C 3.03251 1.77 3.41651 1.882 3.67251 2.106C 3.93251 2.326 4.06251 2.646 4.06251 3.066C 4.06251 3.386 3.97451 3.658 3.79851 3.882C 3.62251 4.102 3.37451 4.25 3.05451 4.326C 3.29851 4.378 3.49251 4.536 3.63651 4.8L 4.30851 6ZM 2.34651 3.588C 2.59051 3.588 2.76451 3.55 2.86851 3.474C 2.97651 3.398 3.03051 3.274 3.03051 3.102C 3.03051 2.926 2.97651 2.8 2.86851 2.724C 2.76451 2.644 2.59051 2.604 2.34651 2.604L 1.57851 2.604L 1.57851 3.588L 2.34651 3.588ZM 5.3467 6L 5.3467 1.77L 8.2927 1.77L 8.2927 2.634L 6.4327 2.634L 6.4327 3.414L 8.1727 3.414L 8.1727 4.278L 6.4327 4.278L 6.4327 6L 5.3467 6ZM 12.321 6L 11.943 5.124L 10.071 5.124L 9.69304 6L 8.58304 6L 10.557 1.77L 11.433 1.77L 13.413 6L 12.321 6ZM 10.425 4.296L 11.589 4.296L 11.007 2.94L 10.425 4.296Z" fill="#FFFFFF" transform="translate(4934 909)"/>
				</g>
			</g>
		</g>
	</g>
	<defs>
		<path id="path0_fill" d="M 11.414 0L 2 0C 0.897 0 0 0.898 0 2L 0 18C 0 19.103 0.897 20 2 20L 14 20C 15.103 20 16 19.103 16 18L 16 4.586L 11.414 0ZM 10 6L 10 1L 15 6L 10 6Z"/>
		<path id="path1_fill" d="M 4.30851 6L 3.09651 6L 2.36451 4.65C 2.27251 4.494 2.12651 4.416 1.92651 4.416L 1.59051 4.416L 1.59051 6L 0.504508 6L 0.504508 1.77L 2.52051 1.77C 3.03251 1.77 3.41651 1.882 3.67251 2.106C 3.93251 2.326 4.06251 2.646 4.06251 3.066C 4.06251 3.386 3.97451 3.658 3.79851 3.882C 3.62251 4.102 3.37451 4.25 3.05451 4.326C 3.29851 4.378 3.49251 4.536 3.63651 4.8L 4.30851 6ZM 2.34651 3.588C 2.59051 3.588 2.76451 3.55 2.86851 3.474C 2.97651 3.398 3.03051 3.274 3.03051 3.102C 3.03051 2.926 2.97651 2.8 2.86851 2.724C 2.76451 2.644 2.59051 2.604 2.34651 2.604L 1.57851 2.604L 1.57851 3.588L 2.34651 3.588ZM 5.3467 6L 5.3467 1.77L 8.2927 1.77L 8.2927 2.634L 6.4327 2.634L 6.4327 3.414L 8.1727 3.414L 8.1727 4.278L 6.4327 4.278L 6.4327 6L 5.3467 6ZM 12.321 6L 11.943 5.124L 10.071 5.124L 9.69304 6L 8.58304 6L 10.557 1.77L 11.433 1.77L 13.413 6L 12.321 6ZM 10.425 4.296L 11.589 4.296L 11.007 2.94L 10.425 4.296Z"/>
	</defs>
  </SvgIcon>
)

FileRfaIcon.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  viewBox: PropTypes.string.isRequired,
};

FileRfaIcon.defaultProps = {
  width: 16,
  height: 20,
  viewBox: '0 0 16 20',
};

export default FileRfaIcon;