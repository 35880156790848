/* vendor imports */
import React from 'react';
import PropTypes from 'prop-types';

/* system imports */
import Component from '../components';
import { SectionHeader } from 'shared';
import { distForm } from 'productAttributes/utils';

class Container extends React.Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { controls } = this.props;
    const { attributeKey: key } = this.props.attribute || {};
    const { attributeKey: nextKey } = nextProps.attribute || {};
    if (key !== nextKey) controls.resetAttribute();
  }
  render() {
    const { attribute, controls, disabled, ...props } = this.props;
    const header = {};
    if (!attribute) {
      if (disabled) {
        header.subtitle = 'Use the column on the left to add or remove an attribute.';
      } else {
        header.title = 'Edit or Create an Attribute';
        header.subtitle =
          'Use the column on the left to create an attribute or edit an already existing one.';
      }
      return (
        <div className="AttributeContentForm">
          <SectionHeader {...header} />
        </div>
      );
    }
    if (!disabled) {
      header.title = attribute.id ? 'Edit Attribute' : 'Create Attribute';
    }
    const revit = attribute.revitDistribution
      ? {
          [distForm.revit.option]: true,
          [distForm.revit.discipline]: attribute.revitDistribution.discipline,
          [distForm.revit.revitAttribute]: attribute.revitDistribution.distributionAttributeType_id,
          [distForm.revit.group]: attribute.revitDistribution.group,
          [distForm.revit.instance]: attribute.revitDistribution.instanceOrType
        }
      : {};
    const initialValues = {
      group: 'General',
      attributeType: 'text',
      revitGroup: 'PG_IDENTITY_DATA',
      revitAttributeType: '3bba70b4-0466-4110-b090-6d238092fe95',
      revitDiscipline: 'Common',
      revitInstanceOrType: 'familytype',
      ...attribute,
      ...revit
    };
    return (
      <div className="AttributeContentForm">
        <SectionHeader {...header} />
        {attribute && <Component {...{ initialValues, disabled }} {...props} />}
      </div>
    );
  }
}

Container.propTypes = {
  controls: PropTypes.shape({
    resetAttribute: PropTypes.func.isRequired
  }).isRequired,
  attribute: PropTypes.shape({
    attributeKey: PropTypes.string,
    revitDistribution: PropTypes.shape({
      distributionAttributeType_id: PropTypes.string,
      discipline: PropTypes.string,
      group: PropTypes.string,
      instanceOrType: PropTypes.string
    })
  }),
  disabled: PropTypes.bool
};

Container.defaultProps = {
  attribute: null,
  disabled: false
};

export default Container;
