import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

import ModalContainer from '../../../index';
import { modalConfirmName } from 'modal/modal';
import Button from '../../../../../Buttons/index';

const ModalConfirmModal = ModalContainer(modalConfirmName);

const ModalConfirmHeader = title => <Modal.Title>{title}</Modal.Title>;

const ModalConfirmFooter = buttonList => {
  if (buttonList && buttonList.length > 0) {
    return buttonList.map(({ text, onClick, buttonStyle }) => (
      <Button key={`ModalConfirm:${text}`} onClick={onClick} buttonStyle={buttonStyle}>
        {text}
      </Button>
    ));
  }
  return null;
};

const ModalConfirmView = ({ title, text, buttonList, ...modalProps }) => (
  <ModalConfirmModal
    header={ModalConfirmHeader(title)}
    footer={ModalConfirmFooter(buttonList)}
    {...modalProps}
  >
    {text}
  </ModalConfirmModal>
);

ModalConfirmView.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  buttonList: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      onClick: PropTypes.func
    })
  ),
  modalStyle: PropTypes.string,
  noClose: PropTypes.bool
};

ModalConfirmView.defaultProps = {
  title: null,
  text: null,
  buttonList: null,
  modalStyle: null,
  noClose: false
};

export default ModalConfirmView;
