import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import sbManagerStyles from 'utils/globalStyles';

const Input = styled.input`
  & + label {
    position: relative;
    margin-left: 1rem;
    cursor: pointer;
    &:before {
      content: '';
      display: inline-block;
      height: 1.6rem;
      width: 1.6rem;
      background: #ffffff;
      box-shadow: inset 0 0 0 0.2rem ${sbManagerStyles.greyLight};
      border-radius: 0.3rem;
      position: absolute;
      left: -28px;
      top: 1px;
    }
    &:after {
      content: none;
      display: inline-block;
      height: 0.6rem;
      width: 1rem;
      border-left: 0.2rem solid #ffffff;
      border-bottom: 0.2rem solid #ffffff;
      transform: rotate(-45deg);
      position: absolute;
      left: -25px;
      top: 5px;
    }
  }
  &:checked {
    & + label {
      &:before {
        background: ${sbManagerStyles.greyMedium};
        box-shadow: none;
      }
      &:after {
        content: '';
      }
    }
  }
  &:hover + label:before {
    box-shadow: inset 0 0 0 2px ${sbManagerStyles.greyMedium};
  }
`;

const Checkbox = ({ labelText, id, name, checked, onChange }) => (
  <div onChange={onChange}>
    <Input type="checkbox" id={id} name={name} checked={checked} />
    <label htmlFor={id}>{labelText}</label>
  </div>
);

export default Checkbox;
