import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import map from 'lodash/map';
import { history, reducerUtil } from 'base-client';

import Button, { DropdownButton, DropdownMenuItem } from 'shared/Buttons';
import { reducerData as jobsData, actions as jobsActions } from 'jobs';
import './job-notifications.scss';
import timeUtils from 'utils/timeUtils';
import { NotificationIcon } from '../../shared/Miscellaneous/Icons';

class JobNotifications extends React.Component {
  static propTypes = {
    notifications: PropTypes.array,
    dispatch: PropTypes.func.isRequired
  };

  static defaultProps = {
    notifications: []
  };

  state = {
    notifications: [],
    newNotifications: [],
    hasNewNotifications: false
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!prevState.notifications.length) {
      return {
        notifications: nextProps.notifications.slice(0)
      };
    }

    const newNotifications = nextProps.notifications.filter(
      n => !prevState.notifications.includes(n)
    );
    return {
      notifications: nextProps.notifications.slice(0),
      newNotifications
    };
  }

  handleDropdownToggle(isOpen) {
    if (!isOpen) {
      this.setState({ newNotifications: [] });
    }
  }

  handleClick() {
    this.setState({ hasNewNotifications: false });
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(jobsActions.getJobs());
  }

  render() {
    const { notifications } = this.props;
    const { newNotifications, hasNewNotifications } = this.state;

    if (!notifications) {
      return null;
    }

    return (
      <div className={'job-notifications ' + (hasNewNotifications ? 'new-notifications' : '')}>
        <DropdownButton
          onClick={() => {
            this.handleClick();
          }}
          icon={<NotificationIcon />}
          title=""
          id="job-notifications-dropdown-icon"
          onToggle={isOpen => {
            this.handleDropdownToggle(isOpen);
          }}
        >
          {map(notifications, (job, index) => (
            <DropdownMenuItem eventKey={index} key={index}>
              <div className="display-inline-block notification-job-type-block">
                <div className="notification-job-time">
                  {timeUtils.format.toLocalizedTime(job.updated, 'MMM DD, YYYY [at] HH:mm')}
                </div>
                <div className="notification-job-type">{job.name || 'Job Type'}</div>
              </div>
              <div className="display-inline-block notification-job-status">{job.status}</div>
              <div
                className={
                  'display-inline-block ' +
                  (newNotifications.includes(job.id) ? 'notification-unread' : '')
                }
              />
            </DropdownMenuItem>
          ))}
          <DropdownMenuItem eventKey={notifications.length}>
            <div className="notification-job-queue-link">
              <Button buttonStyle="blue" onClick={() => history.push('/admin/jobs')}>
                See all in Job Queue
              </Button>
            </div>
          </DropdownMenuItem>
        </DropdownButton>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  notifications: reducerUtil.getSlice(jobsData, jobsData.notifications, state)
});

export default connect(mapStateToProps)(JobNotifications);
