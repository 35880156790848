/* vendor imports */
import React from 'react';
import PropTypes from 'prop-types';
import { AutoSizer, Column, Table, SortIndicator } from 'react-virtualized';

/* system imports */
import { Rows } from '../../shared/TablesAndLists';

const TopProductsContentIndex = props => (
  <AutoSizer disableHeight>
    {({ height, width }) => (
      <Table
        headerClassName="table-header"
        rowClassName="table-row"
        {...{ height, width, ...props }}
      >
        <Column
          label="Rank"
          dataKey="rank"
          width={80}
          headerRenderer={headerRenderer}
          cellRenderer={Rows.Link}
        />
        <Column
          label="Name"
          dataKey="name"
          width={400}
          flexGrow={1}
          headerRenderer={headerRenderer}
          cellRenderer={Rows.Link}
        />
        <Column
          label="Category"
          dataKey="category"
          width={150}
          flexGrow={1}
          headerRenderer={headerRenderer}
          cellRenderer={Rows.Link}
        />
        <Column
          label="Count"
          dataKey="total"
          width={80}
          flexGrow={1}
          headerRenderer={headerRenderer}
          cellRenderer={Rows.Link}
        />
      </Table>
    )}
  </AutoSizer>
);

export default TopProductsContentIndex;

const headerRenderer = ({ label, dataKey, sortBy, sortDirection }) => (
  <div>
    {label}
    {sortBy === dataKey && <SortIndicator sortDirection={sortDirection} />}
  </div>
);

headerRenderer.propTypes = {
  label: PropTypes.string.isRequired,
  dataKey: PropTypes.string.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortDirection: PropTypes.string
};
