const analyzers = {
  visitors: 'uniqueVisitors',
  sessions: 'uniqueSessions',
  loyalty: 'loyalVisitors',
  actions: 'actions',
  engagement: 'engagement',
  fileTypes: 'downloadFileTypes'
};

const events = [
  { key: 'brochure', name: 'Brochures' },
  { key: 'click', name: 'Clicks' },
  { key: 'download', name: 'Downloads' },
  { key: 'message', name: 'Messages' },
  { key: 'pageview', name: 'Page Views' },
  { key: 'product', name: 'Product Views' },
  { key: 'referral', name: 'Referrals' },
  { key: 'search', name: 'Searches' },
  { key: 'submittal', name: 'Submittals' }
];

const filters = {
  location: 'location',
  industry: 'industry',
  occupation: 'occupation'
};

export { analyzers, filters, events };
