const modalName = 'productAttribute';
const newAttribute = 'new';
const pages = {
  main: 'main',
  confirm: 'confirm'
};
const sharedStatus = {
  process: 'process',
  error: 'error',
  success: 'success'
};

const form = 'attribute';
const distForm = {
  revit: {
    option: 'revitOption',
    discipline: 'revitDiscipline',
    revitAttribute: 'revitAttributeType',
    group: 'revitGroup',
    instance: 'revitInstanceOrType'
  },
  type: 'attributeType_id'
};

export { modalName, pages, newAttribute, form, distForm, sharedStatus };
