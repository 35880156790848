import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import SidebarNavigationItem from 'shared/Navigation/SidebarNavigationItem';
import { actions as searchActions } from 'productList';
import { actions as userSearchActions } from 'leadsTwo';

import {
  DashboardIcon,
  ProductsIcon,
  InsightsIcon,
  CollapseIcon,
  UsersIcon,
  AttributesIcon,
  ManagementIcon,
  JobQueueIcon,
  LeadsIcon,
  EventsIcon,
  MessagesIcon,
  ProjectsIcon,
  LinkIcon
} from 'shared/Miscellaneous/Icons';

import '../assets/sidebar.scss';

const SidebarNavigation = ({
  activeIndex,
  activeIndexSearch,
  onCollapseClick,
  permissions,
  enableProject,
  enableApi,
  showMessages,
  dispatch
}) => (
  <div>
    <SidebarNavigationItem
      isActive={activeIndex === '/'}
      name="Dashboard"
      tooltip="Dashboard"
      url="/"
    >
      <DashboardIcon />
    </SidebarNavigationItem>
    {permissions.manager && (
      <SidebarNavigationItem
        isActive={activeIndex.startsWith('/products')}
        name="Products"
        tooltip="Products"
        url="/products"
        onClick={
          activeIndexSearch
            ? () => dispatch(searchActions.clearSearch())
            : () => dispatch(searchActions.setSearch())
        }
      >
        <ProductsIcon />
      </SidebarNavigationItem>
    )}
    {permissions.insight && (
      <React.Fragment>
        <div className="sidebar__section-header">Insights</div>
        <SidebarNavigationItem
          isActive={activeIndex.startsWith('/insights/contacts')}
          name="Contacts"
          tooltip="Contacts"
          url="/insights/contacts"
          onClick={
            activeIndexSearch
              ? () => dispatch(userSearchActions.clearSearch())
              : () => dispatch(userSearchActions.setSearch())
          }
        >
          <LeadsIcon viewBox="0 0 24 18" />
        </SidebarNavigationItem>
        {enableProject && (
          <SidebarNavigationItem
            isActive={activeIndex.startsWith('/insights/projects')}
            name="Projects"
            tooltip="Projects"
            url="/insights/projects"
          >
            <ProjectsIcon />
          </SidebarNavigationItem>
        )}
        <SidebarNavigationItem
          isActive={activeIndex.startsWith('/insights/events')}
          name="Events"
          tooltip="Events"
          url="/insights/events"
        >
          <EventsIcon />
        </SidebarNavigationItem>
        <SidebarNavigationItem
          isActive={activeIndex.startsWith('/insights/charts')}
          name="Charts"
          tooltip="Charts"
          url="/insights/charts"
        >
          <InsightsIcon />
        </SidebarNavigationItem>
        {showMessages && (
          <SidebarNavigationItem
            isActive={activeIndex.startsWith('/insights/messages')}
            name="Messages"
            tooltip="Messages"
            url="/insights/messages"
          >
            <MessagesIcon viewBox="0 0 24 18" />
          </SidebarNavigationItem>
        )}
      </React.Fragment>
    )}
    {permissions.admin && (
      <React.Fragment>
        <div className="sidebar__section-header">Admin</div>
        <SidebarNavigationItem
          isActive={activeIndex.startsWith('/admin/attributes')}
          name="Attributes"
          tooltip="Attributes"
          url="/admin/attributes"
        >
          <AttributesIcon />
        </SidebarNavigationItem>

        <SidebarNavigationItem
          isActive={activeIndex.startsWith('/admin/users')}
          name="Users"
          tooltip="Users"
          url="/admin/users"
        >
          <UsersIcon />
        </SidebarNavigationItem>

        {enableApi && (
          <SidebarNavigationItem
            isActive={activeIndex.startsWith("/admin/api-keys")}
            name="Manage API Keys"
            tooltip="Manage API Keys"
            url="/admin/api-keys"
          >
            <ManagementIcon />
          </SidebarNavigationItem>
        )}
        
        <SidebarNavigationItem
          isActive={activeIndex.startsWith("/admin/integrations")}
          name="Integrations"
          tooltip="Integrations"
          url="/admin/integrations"
        >
          <LinkIcon />
        </SidebarNavigationItem>
      </React.Fragment>
    )}
    <div className="sidebar-divider visible-sm-up" />
    <SidebarNavigationItem name="Collapse" tooltip="Expand" url="" isCollapse>
      <CollapseIcon />
    </SidebarNavigationItem>
  </div>
);

SidebarNavigation.propTypes = {
  activeIndex: PropTypes.string,
  activeIndexSearch: PropTypes.string,
  permissions: PropTypes.object.isRequired
};

export default connect()(SidebarNavigation);
