import React from 'react';
import PropTypes from 'prop-types';

import RadioGroup, { RadioGroupRow } from 'shared/InputGroups/RadioGroup';
import { DateRangePicker } from 'shared-features-client';

const Controls = ({
  additionalRadioGroup,
  onSelectStream,
  onSelectInterval,
  stream,
  interval,
  controlId,
  optionsStream,
  controlButtons,
  hideStream,
  hideInterval
}) => (
  <RadioGroupRow>
    {controlButtons}
    {additionalRadioGroup}
    {!hideStream && (
      <RadioGroup
        namespace={`stream-${controlId}`}
        controlFunc={onSelectStream}
        options={optionsStream}
        selectedOption={stream}
      />
    )}
    {!hideInterval && <DateRangePicker {...{ interval, onSelectInterval }} />}
  </RadioGroupRow>
);

Controls.propTypes = {
  onSelectStream: PropTypes.func.isRequired,
  onSelectInterval: PropTypes.func.isRequired,
  stream: PropTypes.string.isRequired,
  interval: PropTypes.object.isRequired,
  controlId: PropTypes.string.isRequired,
  optionsStream: PropTypes.arrayOf(PropTypes.string).isRequired,
  hideStream: PropTypes.bool,
  hideInterval: PropTypes.bool,
  controlButtons: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element])
};

Controls.defaultProps = {
  hideStream: false,
  hideInterval: false,
  controlButtons: null
};

export default Controls;
