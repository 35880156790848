import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { DEFAULT_FILTER_SHOW } from '../utils';

const Container = styled.div`
  margin-bottom: 1.3rem;
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  padding: 0;
  justify-content: space-between;
`;

const Title = styled.h4`
  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 0.3px;
  margin-bottom: 0.5rem;
  color: #77899e;
`;

const FilterSearchForm = styled.form`
  position: relative;
`;

const SelectAllElm = styled.button`
  cursor: pointer;
  color: #0a7be4;
  text-transform: uppercase;
  font-size: 1.4rem;
  background: transparent;
  border: none;
  font-weight: 400;
  outline: none;
  padding-right: 0;
  padding-left: 0;
`;

const ToggleElm = styled.div`
  cursor: pointer;
  color: rgb(10, 123, 228);
`;

const Facet = styled.div`
  display: flex;
  margin-bottom: 0.5rem;
`;

const FacetLabel = styled.label`
  margin-left: 0.35rem;
`;

const defaultShowMore = {
  disabled: true,
  numberShow: DEFAULT_FILTER_SHOW
};

const CheckboxFilter = ({
  name,
  attribute,
  facets,
  updateFilter,
  disabledSelectAll,
  checkedAll,
  customShowMore,
  ...props
}) => {
  if (!facets || (facets && facets.length < 1)) return null;

  const { disabled: isShowAll, numberShow } = { ...defaultShowMore, ...customShowMore };

  const [showAll, setShowAll] = useState(isShowAll);

  const displayFacets =
    !showAll && facets.length > numberShow ? facets.slice(0, numberShow) : facets;

  return (
    <Container className="radio-filters">
      <Header>
        <Title>{name}</Title>
        {!disabledSelectAll && (
          <SelectAllElm
            type="button"
            className="toggleBtn"
            onClick={() =>
              updateFilter({
                checked: !checkedAll,
                attribute,
                facets: facets.map(({ key }) => key)
              })
            }
          >
            {checkedAll ? 'Deselect All' : 'Select All'}
          </SelectAllElm>
        )}
      </Header>
      <FilterSearchForm />
      {displayFacets.map(({ key, checked, name, count }) => (
        <Facet>
          <input
            type="radio"
            id={name}
            onClick={() => updateFilter({ checked: !checked, attribute, facets: [key] })}
            readOnly
            {...{ name, checked }}
          />
          <FacetLabel htmlFor={name}>{count ? `${name} (${count})` : name}</FacetLabel>
        </Facet>
      ))}
      {!isShowAll && facets.length > numberShow && (
        <ToggleElm className="toggleBtn" onClick={() => setShowAll(!showAll)}>
          {showAll ? 'Show less...' : 'Show more...'}
        </ToggleElm>
      )}
    </Container>
  );
};

CheckboxFilter.propTypes = {
  name: PropTypes.string.isRequired,
  attribute: PropTypes.string.isRequired,
  facets: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      checked: PropTypes.bool,
      count: PropTypes.number
    })
  ),
  updateFilter: PropTypes.func.isRequired,
  customShowMore: PropTypes.shape({
    disabled: PropTypes.bool,
    numberShow: PropTypes.number
  }),
  disabledSelectAll: PropTypes.bool,
  checkedAll: PropTypes.bool
};

CheckboxFilter.defaultProps = {
  facets: [],
  customShowMore: {}
};

export default CheckboxFilter;
