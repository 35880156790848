import moment from 'moment';
import { interval as defaultInterval, timeUtil } from 'utils';

const getDatetimeInfor = query => {
  const { dateRange } = query || {};

  const { start, end } = dateRange || {
    start: defaultInterval.initial.startDate.toISOString(),
    end: defaultInterval.initial.endDate.toISOString()
  };

  const days = start && end && moment(end).diff(moment(start), 'days') + 1;

  const interval = {
    startDate: timeUtil.toMoment(start),
    endDate: timeUtil.toMoment(end)
  };

  return {
    days,
    interval
  };
};

export default { getDatetimeInfor };
