import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../SvgIcon';

const UpArrowIcon = props => (
  <SvgIcon {...props}>
    <path d='M4.77,4.93V19.85H7.35V4.93l3,3,1.82-1.82L6.06,0,0,6.06,1.82,7.88Z' />
  </SvgIcon>
);

UpArrowIcon.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  viewBox: PropTypes.string.isRequired,
};

UpArrowIcon.defaultProps = {
  width: 12.1,
  height: 19.8,
  viewBox: '0 0 12.13 19.85',
};

export default UpArrowIcon;
