import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { reducerUtil } from 'base-client';

import { reducerData as permissionsData } from 'permissions';
import { actions as leadsActions, reducerData as leadsData } from 'leads';

import NavigationToParent from 'shared/Navigation/NavigationToParent';
import PersonalInformation from './PersonalInformation';
import ProductUsage from './ProductUsage';
import EventLog from './EventLog';
import LeadProjects from './LeadProjects';
import DirectMessages from './Messages';

import '../../assets/lead.scss';

class LeadProfile extends React.Component {
  static propTypes = {
    leadProfileData: PropTypes.object,
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string
      })
    }),
    canView: PropTypes.bool
  };
  static defaultProps = {
    canView: undefined
  };

  componentDidMount() {
    const { match, dispatch } = this.props;
    if (match && match.params) dispatch(leadsActions.getLeadData(match.params.id));
  }

  render() {
    const { canView, leadProfileData } = this.props;
    if (!canView) return null;

    const { products = [], tables: productUsageData = {} } = leadProfileData || {};

    return (
      <React.Fragment>
        <NavigationToParent text="Back to All Leads" url="/insights/leads" />
        <React.Fragment>
          <PersonalInformation />
          <ProductUsage {...productUsageData} />
          <DirectMessages />
          <EventLog data={products} />
          <LeadProjects />
        </React.Fragment>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  leadProfileData: reducerUtil.getSlice(leadsData, leadsData.leadProfile, state) || {},
  canView: reducerUtil.getSlice(permissionsData, permissionsData.leads, state)
});

export default withRouter(connect(mapStateToProps)(LeadProfile));
