import uuid from 'uuid/v4';
import { history, api, config, reducerUtil } from 'base-client';

import { reducerData as permissionsData } from 'permissions';
import {
  EVENT_TYPE_KEYS,
  EVENTS_TYPE_ATTRIBUTE,
  EVENT_TYPE_FACETS,
  FILTERS,
  EXPORTS_COLUMNS,
  TABLE_COLUMNS
} from 'eventsLog/utils';
import eventsLogData from 'eventsLog/reducerData';
import downloadUtil from 'utils/fileDownload';
import { getlocationQuery } from 'utils/miscUtils';

import { defaultISOInterval } from 'shared-features-client';

const defaultQuery = {
  sort: {
    sortBy: 'date',
    sortDir: 'desc'
  },
  dateRange: {
    start: defaultISOInterval.startDate,
    end: defaultISOInterval.endDate
  },
  filters: undefined,
  includeAnonymous: true
};

const defaultLimit = 100;

const canView = state => reducerUtil.getSlice(permissionsData, permissionsData.insights, state);

/** This performs a event logs search.
 * @param {bool} [restart] Whether or not to restart the list.
 * @returns {promise} The api request.
 */
const search = (restart, limit) => async (dispatch, getState) => {
  let state = getState();

  if (!canView(state)) return null;

  // clear old data
  if (restart) dispatch(reducerUtil.setSlice(eventsLogData, eventsLogData.list, undefined));

  // get the querystring
  const query = getlocationQuery();

  // get the page information from the meta if not restarting
  const { pagination } = reducerUtil.getSlice(eventsLogData, eventsLogData.meta, state) || {};
  const page = restart || !pagination ? 1 : pagination.page + 1;

  const fetchId = uuid();
  dispatch(reducerUtil.setSlice(eventsLogData, eventsLogData.fetchId, fetchId));

  const apiData = { ...defaultQuery, ...query };

  const { includeAnonymous, filters: queryFilters } = apiData;
  apiData.includeAnonymous = includeAnonymous ? 'true' : 'false';

  const { list: currFilters, eventTypes } =
    reducerUtil.getSlice(eventsLogData, eventsLogData.filters, state) || {};

  if (!eventTypes || eventTypes.length < 1) return null;

  const defaultEvent = eventTypes.includes(EVENT_TYPE_KEYS.DOWNLOAD)
    ? EVENT_TYPE_KEYS.DOWNLOAD
    : currFilters[0].facets[0].key;

  if (!queryFilters) {
    //if there is no query, will use the default query
    apiData.filters = [
      {
        attribute: EVENTS_TYPE_ATTRIBUTE,
        facets: [defaultEvent]
      }
    ];
    apiData.columns = TABLE_COLUMNS[defaultEvent];
    dispatch(
      reducerUtil.setSlice(eventsLogData, eventsLogData.query, {
        ...defaultQuery,
        ...query,
        filters: [
          {
            attribute: EVENTS_TYPE_ATTRIBUTE,
            facets: [defaultEvent]
          }
        ]
      })
    );
  } else {
    const index = queryFilters.findIndex(({ attribute }) => attribute === EVENTS_TYPE_ATTRIBUTE);
    if (index >= 0 && !eventTypes.includes(apiData.filters[index].facets[0])) {
      apiData.filters[index].facets = [defaultEvent];
      apiData.columns = TABLE_COLUMNS[defaultEvent];
    } else {
      apiData.columns = TABLE_COLUMNS[apiData.filters[index].facets[0]];
    }

    dispatch(
      reducerUtil.setSlice(eventsLogData, eventsLogData.query, {
        ...query,
        filters: [...apiData.filters]
      })
    );
  }

  // return api request
  try {
    const { searchResults: result, searchMetaData: meta } = await dispatch(
      api.actions.post(
        `analytics/visitors/events/search?page=${page}&limit=${limit || defaultLimit}`,
        JSON.stringify(apiData)
      )
    );

    // check that this is the correct fetch
    state = getState();
    if (fetchId !== reducerUtil.getSlice(eventsLogData, eventsLogData.fetchId, state)) return;

    // get current list if not restarting
    const list = (!restart && reducerUtil.getSlice(eventsLogData, eventsLogData.list, state)) || [];

    // set the new list
    dispatch(reducerUtil.setSlice(eventsLogData, eventsLogData.list, [...list, ...result]));
    dispatch(reducerUtil.setSlice(eventsLogData, eventsLogData.meta, meta));

    // allow another search
    dispatch(reducerUtil.setSlice(eventsLogData, eventsLogData.fetchId, undefined));
  } catch (error) {
    dispatch(config.actions.error(error));
  }
};

const getFilters = dateRange => async (dispatch, getState) => {
  const { start, end } = dateRange;
  const insightParams = {
    dateRange: { start, end },
    includePrevious: false
  };
  try {
    const { availableFacets = [] } = await dispatch(
      api.actions.post('insights', JSON.stringify(insightParams))
    );

    const { eventType: eventTypes } = availableFacets[0] || {};

    if (!eventTypes || eventTypes.length < 1) return null;

    const filters = formatFilters(availableFacets[0]);

    dispatch(
      reducerUtil.setSlice(eventsLogData, eventsLogData.filters, {
        ...{ list: filters, eventTypes }
      })
    );

    return { filters, eventTypes };
  } catch (error) {
    dispatch(config.actions.error(error));
  }
};

const formatFilters = data => {
  if (!data) return undefined;
  const defaultFilters = JSON.parse(JSON.stringify(FILTERS));
  return defaultFilters.map(filter => {
    const { attribute, facets: newFacets } = filter;
    const facetValues = data[attribute];
    if (!facetValues) return filter;

    if (attribute === 'eventType') {
      EVENT_TYPE_FACETS.forEach(item => {
        if (facetValues.includes(item.key)) newFacets.push(item);
      });
    } else if (attribute === 'occupation') {
      facetValues.forEach(item => {
        newFacets.push({ key: item, attribute: item });
      });
    } else if (attribute === 'location') {
      facetValues.forEach(item => {
        const key = Object.keys(item)[0];
        const attribute = Object.values(item)[0];
        if (key && attribute) {
          newFacets.push({ key, attribute, count: 0 });
        }
      });
    }
    return { ...filter, facets: newFacets };
  });
};

const getFullData = dateRange => async dispatch => {
  await dispatch(getFilters(dateRange));
  return dispatch(search(true));
};

/** This searches for the next page, using the current search parameters.
 * @returns {promise} The api request.
 */
const nextPage = () => (dispatch, getState) => dispatch(search());

/** This sets the search text and starts a timer before searching.
 * @param {Object} [queryParams] The new string to search.
 */
const setQuery = newData => (dispatch, getState) => {
  const oldQuery = reducerUtil.getSlice(eventsLogData, eventsLogData.query, getState()) || {};
  const query = { ...oldQuery, ...newData };
  dispatch(reducerUtil.setSlice(eventsLogData, eventsLogData.query, query));
  history.push(`${history.location.pathname}?${encodeURIComponent(JSON.stringify(query))}`);
};

/** This sets the sort method part of the product query object.
 * @param {string} [sortKey] The new query string.
 */
const setSort = ({ sortBy, sortDir }) => (dispatch, getState) => {
  const query = reducerUtil.getSlice(eventsLogData, eventsLogData.query, getState()) || {};
  return dispatch(setQuery({ ...query, sort: { sortBy, sortDir } }));
};

const setDate = ({ startDate, endDate }) => async (dispatch, getState) => {
  const dateRange = { start: startDate, end: endDate };
  await dispatch(getFilters(dateRange));
  return dispatch(setQuery({ ...defaultQuery, dateRange }));
};

const updateVisitorType = () => (dispatch, getState) => {
  const query = reducerUtil.getSlice(eventsLogData, eventsLogData.query, getState()) || {};
  const { includeAnonymous } = query || {};
  return dispatch(setQuery({ ...query, includeAnonymous: !includeAnonymous }));
};

const addFilter = ({ attribute, facets }) => (dispatch, getState) => {
  if (!Array.isArray(facets)) facets = [facets];
  const query = reducerUtil.getSlice(eventsLogData, eventsLogData.query, getState()) || {};
  const { filters } = query;

  let newFilters;
  if (!filters) {
    newFilters = [{ attribute, facets }];
  } else {
    const index = filters.findIndex(({ attribute: name }) => name === attribute);
    if (index < 0) {
      newFilters = [...filters, { attribute, facets }];
    } else {
      const { facets: preFacets } = filters[index];
      newFilters = [...filters];
      newFilters[index] = { attribute, facets: [...preFacets, ...facets] };
    }
  }

  return dispatch(setQuery({ ...query, filters: newFilters }));
};

const removeFilter = ({ attribute, facets }) => (dispatch, getState) => {
  if (!Array.isArray(facets)) facets = [facets];
  const query = reducerUtil.getSlice(eventsLogData, eventsLogData.query, getState()) || {};
  const { filters } = query;
  let newFilters;
  const index = filters.findIndex(({ attribute: name }) => name === attribute);
  const { facets: prevFacets } = filters[index];
  const newFacets = prevFacets.filter(name => name !== facets[0]);
  if (newFacets.length > 0) {
    newFilters = [...filters];
    newFilters[index] = { attribute, facets: newFacets };
  } else if (filters.length > 1) {
    newFilters = filters.filter((item, filterIndex) => filterIndex !== index);
  }

  return dispatch(setQuery({ ...query, filters: newFilters }));
};

const updateFilter = ({ attribute, facets }) => (dispatch, getState) => {
  if (!Array.isArray(facets)) facets = [facets];
  const query = reducerUtil.getSlice(eventsLogData, eventsLogData.query, getState()) || {};
  const { filters } = query;
  let newFilters;
  const index = filters.findIndex(({ attribute: name }) => name === attribute);

  if (facets.length > 0) {
    newFilters = [...filters];
    newFilters[index] = { attribute, facets };
  } else if (filters.length > 1) {
    newFilters = filters.filter((item, filterIndex) => filterIndex !== index);
  }

  //update default sorting for new table
  const { sort } = defaultQuery;
  return dispatch(setQuery({ ...query, filters: newFilters, sort }));
};

const clearAllFilters = () => (dispatch, getState) => {
  const query = reducerUtil.getSlice(eventsLogData, eventsLogData.query, getState()) || {};
  return dispatch(setQuery({ ...query, filters: undefined }));
};

const exportData = () => async (dispatch, getState) => {
  const state = getState();
  if (!canView(state)) return null;

  const query = reducerUtil.getSlice(eventsLogData, eventsLogData.query, state) || {};

  const apiData = {
    ...defaultQuery,
    ...query,
    export: true
  };

  const { filters, includeAnonymous } = apiData;
  const eventType = getEventType(filters);
  const exportColumns = EXPORTS_COLUMNS[eventType];

  try {
    const result = await dispatch(
      api.actions.post(
        'analytics/visitors/events/search',
        JSON.stringify({
          ...apiData,
          columns: exportColumns,
          includeAnonymous: includeAnonymous ? 'true' : 'false'
        })
      )
    );
    if (result) downloadUtil(result, `${'EventsLog'}.csv`);
  } catch (error) {
    dispatch(config.actions.error(error));
  }
};

const getEventType = filters => {
  let eventType = EVENT_TYPE_FACETS[0].key;
  if (filters && filters.length > 0) {
    const eventTypes = filters.find(({ attribute }) => attribute === EVENTS_TYPE_ATTRIBUTE);
    eventType = eventTypes.facets[0];
  }
  return eventType;
};

export default {
  search,
  nextPage,
  setSort,
  setDate,
  getFilters,
  getFullData,
  addFilter,
  removeFilter,
  updateFilter,
  clearAllFilters,
  updateVisitorType,
  exportData
};
