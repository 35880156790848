import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../SvgIcon';

const ChevronThickIcon = (props) => (
  <SvgIcon {...props}>
		<path d="M18.50563,18.04407L11.9447,11.51175,5.3838,18.04407a0.77624,0.77624,0,0,1-1.1058,0L2.2323,16.00731a0.76848,0.76848,0,0,1,0-1.101l9.2148-9.17463a0.77636,0.77636,0,0,1,1.10579,0l9.21479,9.17463a0.76855,0.76855,0,0,1,0,1.101L19.722,18.04407a0.77595,0.77595,0,0,1-1.21636,0h0Z"/>
  </SvgIcon>
)

ChevronThickIcon.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  viewBox: PropTypes.string.isRequired,
};

ChevronThickIcon.defaultProps = {
  width: 24,
  height: 24,
  viewBox: '0 0 24 24',
};

export default ChevronThickIcon;
