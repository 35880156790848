import { api, config, reducerUtil } from 'base-client';

import { reducerData as attributesData } from 'productAttributes';
import { reducerData as permissionsData } from 'permissions';
import { form, distForm } from 'productAttributes/utils';

import { configMap } from 'configurations';

const getAttributes = () => async (dispatch, getState) => {
  if (!checkPermission(getState())) return;

  await dispatch(getAllAttributeTypes());
  try {
    const result = await dispatch(api.actions.get('attributes'));
    dispatch(reducerUtil.setSlice(attributesData, attributesData.attributes, result));
    await dispatch(getSustainabilityAttributes());
  } catch (error) {
    dispatch(config.actions.error(error));
  }
};

const getSustainabilityAttributes = () => async (dispatch, getState) => {
  const state = getState();

  const disableSustainability = dispatch(
    config.actions.getData(configMap.disabled.name, configMap.disabled.sustainability.name)
  );

  if (disableSustainability) return;

  if (!checkPermission(state)) return;
  if (reducerUtil.getSlice(attributesData, attributesData.sustainability, state)) return;

  try {
    const getData = async (url, slice, name) => {
      const { [name]: result = [] } = await dispatch(api.actions.get(url));
      if (name === 'environmentCharacteristics') {
        const getType = (attribute_type_id, internal_storage) => {
          if (attribute_type_id === 'checkbox') {
            return 'compliant';
          } else if (attribute_type_id === 'number' && internal_storage === 'percentage') {
            return 'percentage';
          } else return attribute_type_id;
        };
        const formattedResult = result
          .map(
            ({ id, attribute_type_id, internal_storage, name, default_value: defaultValue }) => ({
              id,
              name,
              defaultValue,
              type: getType(attribute_type_id, internal_storage)
            })
          )
          .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
        dispatch(reducerUtil.setSlice(attributesData, slice, formattedResult));
      } else {
        dispatch(reducerUtil.setSlice(attributesData, slice, result));
      }
    };
    await Promise.all([
      getData('sustainability/documents?limit=250', attributesData.certificates, 'documents'),
      getData(
        'sustainability/ratingSystems?limit=250',
        attributesData.ratingSystems,
        'ratingSystems'
      ),
      getData(
        'sustainability/environmentCharacteristics?limit=250',
        attributesData.characteristics,
        'environmentCharacteristics'
      )
    ]);
  } catch (error) {
    dispatch(config.actions.error(error));
  }
};

const getAllAttributeTypes = () => async (dispatch, getState) => {
  const state = getState();
  if (!checkPermission(state)) return;
  if (reducerUtil.getSlice(attributesData, attributesData.attributeTypes, state)) return;

  try {
    await Promise.all([
      dispatch(getTypes('attributetypes', attributesData.attributeTypes)),
      dispatch(getTypes('distributionattributetypes', attributesData.distributionTypes))
    ]);
  } catch (error) {
    dispatch(config.actions.error(error));
  }
};

const getTypes = (typeUrl, typeSlice) => async dispatch => {
  const result = await dispatch(api.actions.get(typeUrl));
  dispatch(reducerUtil.setSlice(attributesData, typeSlice, result));
};

const getAttributeData = () => (dispatch, getState) => {
  const state = getState();
  if (!checkPermission(state)) return;
  const data = state.form && state.form[form] && { ...state.form[form].values };
  if (!data) return;
  if (data[distForm.revit.option]) {
    if (!data.revitDistribution) data.revitDistribution = {};
    data.revitDistribution.discipline = data[distForm.revit.discipline];
    data.revitDistribution.group = data[distForm.revit.group];
    data.revitDistribution.instanceOrType = data[distForm.revit.instance];
    const distributionTypes = reducerUtil.getSlice(
      attributesData,
      attributesData.distributionTypes,
      state
    );
    const distribution = distributionTypes.find(item => {
      return item.id === data[distForm.revit.revitAttribute];
    });
    if (distribution) {
      data.revitDistribution.distributionAttributeType_id = distribution.id;
      data.internalStorage = distribution.internalStorage || 'general';
    } else {
      data.revitDistribution.distributionAttributeType_id = '';
      data.internalStorage = 'general';
    }
    data.revitDistribution.readOnly = false;
    data.revitDistribution.shared = false;
  } else {
    if (data.revitDistribution) delete data.revitDistribution;
    data.internalStorage = 'general';
  }
  delete data[distForm.revit.option];
  delete data[distForm.revit.discipline];
  delete data[distForm.revit.revitAttribute];
  delete data[distForm.revit.group];
  delete data[distForm.revit.instance];
  dispatch(reducerUtil.setSlice(attributesData, attributesData.attributeData, data));
};

const updateAttribute = id => async (dispatch, getState) => {
  dispatch(getAttributeData());
  const state = getState();
  if (!checkPermission(state)) return;

  const url = id ? `attributes/${id}` : 'attributes';
  const data = reducerUtil.getSlice(attributesData, attributesData.attributeData, state);

  if (!data.revitDistribution) {
    data.revitDistribution = null;
  }

  try {
    const result = await dispatch(api.actions.post(url, JSON.stringify(data)));
    dispatch(getAttributes());
    return { key: id || result.id };
  } catch (error) {
    dispatch(config.actions.error(error));
    return { key: id };
  }
};

const deleteAttribute = id => async (dispatch, getState) => {
  const state = getState();
  if (!checkPermission(state)) return;
  if (!id) return;

  try {
    await dispatch(api.actions.delete(`attributes/${id}`));
    dispatch(getAttributes());
    return {};
  } catch (error) {
    dispatch(config.actions.error(error));
  }
};

const checkProducts = id => async (dispatch, getState) => {
  const state = getState();
  if (!checkPermission(state)) return;
  if (!id) return { id, updated: false };

  try {
    const result = await dispatch(api.actions.get(`attributes/${id}/products`));
    dispatch(getAttributeData());
    dispatch(reducerUtil.setSlice(attributesData, attributesData.products, result));
    return { id, updated: result.length > 0 };
  } catch (error) {
    dispatch(config.actions.error(error));
  }
};

// ------------------------------------
// Permissions
// ------------------------------------
const checkPermission = state =>
  reducerUtil.getSlice(permissionsData, permissionsData.manager, state);

// ------------------------------------
// Exports
// ------------------------------------
export default {
  getAttributes,
  checkProducts,
  updateAttribute,
  deleteAttribute,
  getSustainabilityAttributes
};
