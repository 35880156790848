const DEFAULT_FILTER_SHOW = 5;

const formatFilter = (attribute, name, data, selected) => {
  const { facets: selectedFacets = [] } =
    selected.find(({ attribute: selectedAttribute }) => attribute === selectedAttribute) || {};
  const filter = {
    attribute,
    name,
    checkedAll: data.length > 0 && selectedFacets.length === data.length,
    facets: data.map(item => {
      return {
        key: item,
        name: item,
        checked: selectedFacets.includes(item)
      };
    })
  };
  return filter;
};

export { DEFAULT_FILTER_SHOW, formatFilter };
