import React from 'react';
import PropTypes from 'prop-types';
import { AutoSizer, Column, Table } from 'react-virtualized';

import { SectionHeader } from 'shared';
import { Rows } from 'shared/TablesAndLists';
import Card from 'shared/PageLayout/Card';

const EventLog = ({ data }) => {
  const rowGetter = ({ index }) => (data && data[index]) || {};
  const rowCount = (data && data.length) || 0;
  const cellRenderer = ({ cellData, rowData }) =>
    !rowData.productKey ? cellData : <Rows.Link {...{ cellData, rowData }} />;

  return (
    <React.Fragment>
      <SectionHeader title="Event Log" />
      <Card>
        <div className="table--AutoSizer">
          <AutoSizer>
            {({ height, width }) => (
              <Table
                headerHeight={55}
                headerClassName="table-header"
                rowClassName="table-row"
                rowHeight={70}
                height={height}
                {...{ rowCount, rowGetter, width }}
              >
                <Column
                  label="Product Name"
                  dataKey="name"
                  width={500}
                  flexGrow={1}
                  cellRenderer={cellRenderer}
                />
                <Column
                  label="Event"
                  dataKey="event"
                  width={100}
                  flexGrow={1}
                  cellRenderer={cellRenderer}
                />
                <Column
                  label="Date"
                  dataKey="date"
                  width={100}
                  flexGrow={1}
                  cellRenderer={cellRenderer}
                />
              </Table>
            )}
          </AutoSizer>
        </div>
      </Card>
    </React.Fragment>
  );
};

EventLog.propTypes = {
  table: PropTypes.arrayOf(
    PropTypes.shape({
      productKey: PropTypes.string,
      event: PropTypes.string,
      name: PropTypes.string,
      category: PropTypes.string,
      date: PropTypes.string,
      url: PropTypes.string
    })
  )
};

export default EventLog;
