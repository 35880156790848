import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { AutoSizer, List } from 'react-virtualized';
import { connect } from 'react-redux';
import { reducerUtil } from 'base-client';

import { reducerData as detailsData } from 'projectDetails';
import sbManagerStyles from 'utils/globalStyles';
import HistoryTag from './HistoryTag';
import ProjectSectionHeader from './ProjectSectionHeader';

const StyledWrapper = styled.div`
  .history-action {
    position: relative;
    &:before {
      position: absolute;
      content: '';
      height: calc(100% - 3rem);
      width: 1px;
      background-color: ${() => sbManagerStyles.greyLight};
      left: 8px;
      top: 33px;
    }
    &:last-child {
      &:before {
        width: 0;
      }
    }
  }
`;

const Subtitle = styled.div`
  font-size: 1rem;
  text-transform: uppercase;
  color: ${() => sbManagerStyles.greyMedium};
`;

const Body = styled.div`
  padding: 1rem 0 1rem 2rem;
`;

const ROW_HEIGHT = 55;

const ProjectHistory = ({ projectHistory, brochures }) => {
  const length = (projectHistory && projectHistory.length) || 0;

  const rowRenderer = ({ key, style, index }) => {
    const rowData = projectHistory[index] || {};
    return <HistoryTag {...{ key, style }} {...rowData} />;
  };
  // Currently, I am rendering all the data that we have because 25 events, it's not too long.
  // We can change this value later when we want to fix height for this part.
  const viewHeight = ROW_HEIGHT * length;

  return (
    <StyledWrapper>
      {({ backgroundUrl }) => `url("${backgroundUrl}")`}
      <ProjectSectionHeader title="Project Actions">
        <Subtitle>Last 25 events</Subtitle>
      </ProjectSectionHeader>
      {/* <Header>
        <Title>Project Actions</Title>
      </Header> */}
      <Body>
        <AutoSizer disableHeight>
          {({ width }) => (
            <List
              ref="List"
              height={viewHeight}
              rowCount={length}
              rowHeight={ROW_HEIGHT}
              rowRenderer={rowRenderer}
              width={width}
            />
          )}
        </AutoSizer>
      </Body>
    </StyledWrapper>
  );
};

ProjectHistory.propTypes = {
  projectHistory: PropTypes.arrayOf(
    PropTypes.shape({
      datetime: PropTypes.string,
      type: PropTypes.string,
      eventMetadata: PropTypes.any
    })
  )
};

ProjectHistory.defaultProps = {
  projectHistory: []
};

const mapStateToProps = state => ({
  projectHistory: reducerUtil.getSlice(detailsData, detailsData.history, state),
  brochures: reducerUtil.getSlice(detailsData, detailsData.brochures, state)
});

export default connect(mapStateToProps)(ProjectHistory);
