import React from 'react';
import PropTypes from 'prop-types';
import { Column, Table, SortIndicator } from 'react-virtualized';
import 'react-virtualized/styles.css';

import SelectControls from './SelectControls';
import Checkbox from 'shared/InputGroups/Checkbox';
import { EditIcon, DeleteIcon } from 'shared/Miscellaneous/Icons';
import { querySortBy } from 'productList/utils';
import Tooltip from './Tooltip';

const headerRenderer = ({ dataKey, label, sortBy, sortDirection }) => {
  return (
    <div>
      {label}
      {querySortBy[dataKey] && sortBy === querySortBy[dataKey] && (
        <SortIndicator sortDirection={sortDirection} />
      )}
    </div>
  );
};

const cellLinkRenderer = props => <Tooltip {...props} />;

const cellOptionRenderer = ({ rowData }) => (
  <div>
    <EditIcon onClick={rowData.onClick} addClass="icon--table" width={18} height={18} />
    <DeleteIcon
      onClick={rowData.onClickDelete}
      addClass="icon--table icon--hover-danger"
      width={20}
      height={20}
    />
  </div>
);

const ListView = ({ registerChild, ...props }) => (
  <Table {...props} ref={registerChild}>
    <Column
      cellRenderer={({ rowData }) => (
        <Checkbox isChecked={rowData.isChecked} onChange={rowData.onSelect} />
      )}
      dataKey="select"
      disableSort
      headerRenderer={() => <SelectControls />}
      label="Select"
      width={75}
    />
    <Column
      cellRenderer={cellLinkRenderer}
      dataKey="name"
      disableSort
      flexGrow={1}
      headerRenderer={headerRenderer}
      label="Product Name"
      width={360}
    />
    <Column
      cellRenderer={cellLinkRenderer}
      dataKey="category"
      disableSort
      className="pl-3"
      headerClassName="pl-3"
      flexGrow={1}
      headerRenderer={headerRenderer}
      label="Category"
      width={200}
    />
    <Column
      cellRenderer={cellLinkRenderer}
      className="text-center"
      dataKey="published"
      disableSort
      flexGrow={1}
      headerClassName="text-center"
      headerRenderer={headerRenderer}
      label="Published"
      width={100}
    />
    <Column
      cellRenderer={cellLinkRenderer}
      className="text-center"
      dataKey="status"
      disableSort
      flexGrow={1}
      headerClassName="text-center"
      headerRenderer={headerRenderer}
      label="Status"
      width={100}
    />
    <Column
      cellRenderer={cellLinkRenderer}
      className="text-center"
      dataKey="updated"
      disableSort
      flexGrow={1}
      headerClassName="text-center"
      headerRenderer={headerRenderer}
      label="Last Update"
      width={150}
    />
    <Column
      cellRenderer={cellOptionRenderer}
      className="text-center"
      dataKey="options"
      disableSort
      flexGrow={1}
      headerClassName="text-center"
      headerRenderer={headerRenderer}
      label="Actions"
      width={100}
    />
  </Table>
);

ListView.propTypes = {
  registerChild: PropTypes.func.isRequired
};

export default ListView;
