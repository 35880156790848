import { reducerUtil } from 'base-client';

import { reducerData as insightsData, actions, utils } from 'insights';

// ------------------------------------
// Product Snapshot
// ------------------------------------
const getProductSnapshot = (stream, interval, key) => async dispatch => {
  const sum = await dispatch(actions.insightApi('all', stream, 'sum', { interval, count: 1, key }));
  const { data: sumData } = sum || {};
  dispatch(receiveProductSnapshot('sum', sumData));

  const unique = await dispatch(
    actions.insightApi('all', stream, 'uniqueVisitors', { interval, count: 6, key })
  );
  const { data: uniqueData } = unique || {};
  dispatch(receiveProductSnapshot('unique', uniqueData));

  const geo = await dispatch(actions.insightApi('all', stream, 'geo', { interval, key }));

  const { data: geoData } = geo || {};
  dispatch(receiveProductSnapshot('map', geoData));
};

const receiveProductSnapshot = (key, data) => (dispatch, getState) => {
  const snapshot =
    reducerUtil.getSlice(insightsData, insightsData.productSnapshot, getState()) || {};
  const productSnapshot = Object.assign({}, snapshot);

  productSnapshot[key] = key === 'map' ? productSnapshotMap(data) : utils.productInfoNumber(data);
  dispatch(reducerUtil.setSlice(insightsData, insightsData.productSnapshot, productSnapshot));
};

const productSnapshotMap = data => {
  if (!data || data.length < 1) return null;
  const map = [];
  data.forEach(datum => {
    if (datum && datum.count) {
      const i = map.findIndex(value => value.code === datum.countryCode);
      if (i < 0) {
        map.push({
          code: datum.countryCode,
          value: parseInt(datum.count)
        });
      } else map[i].value += parseInt(datum.count);
    }
  });
  return map;
};

export default {
  getProductSnapshot,
  receiveProductSnapshot
};
