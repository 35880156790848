import React from 'react';
import PropTypes from 'prop-types';

import { UpdateIcon, TotalIcon } from 'shared/Miscellaneous/Icons';
import detailsUtils from 'productDetails/utils';

const Metadata = ({ sharedParametersData: { updated, total } }) => (
  <div className="shared-parameter__meta">
    <div className="meta-item">
      <p className="meta-item__header">
        <span className="meta-item__icon">
          <UpdateIcon width={18} height={18} />
        </span>
        Uploaded
      </p>
      <p className="meta-item__content">{updated ? detailsUtils.formatTime(updated) : '—'}</p>
    </div>
    <div className="meta-item">
      <p className="meta-item__header">
        <span className="meta-item__icon">
          <TotalIcon width={18} height={18} />
        </span>
        Total Parameters
      </p>
      <p className="meta-item__content">{total ? total : '—'}</p>
    </div>
  </div>
);

Metadata.propTypes = {
  sharedParametersData: PropTypes.shape({
    updated: PropTypes.string,
    total: PropTypes.number
  })
};

Metadata.defaultProps = {
  sharedParametersData: {}
};

export default Metadata;
