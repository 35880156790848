import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Select from 'react-select';

const Container = styled.div`
  font-size: 1.4rem;
  margin-bottom: 1rem;
`;

const Label = styled.label`
  font-weight: 500;
  line-height: 1.25;
`;

const Content = styled.div`
  margin-top: 1rem;
`;

const DropdownFilter = ({
  selected,
  attribute,
  name,
  facets,
  updateFilter,
  customStyles,
  placeholder
}) => {
  if (!facets || facets.length < 1) return null;

  const defaultArgs = { checked: false, attribute, facets: '' };

  const selectedOption =
    (selected[0] ? facets.find(({ value }) => value === selected[0]) : selected[0]) || '';

  return (
    <Container>
      <Label>{name}</Label>
      <Content>
        <Select
          className="selectLocation"
          styles={customStyles}
          value={selectedOption}
          options={facets}
          placeholder={placeholder || 'Select...'}
          isClearable={true}
          onChange={item =>
            !item ? updateFilter(defaultArgs) : updateFilter({ ...defaultArgs, facets: item.value })
          }
        />
      </Content>
    </Container>
  );
};

DropdownFilter.propTypes = {
  attribute: PropTypes.string,
  name: PropTypes.string,
  facets: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  ),
  selected: PropTypes.arrayOf(PropTypes.string),
  updateFilter: PropTypes.func.isRequired,
  customStyles: PropTypes.object,
  placeholder: PropTypes.string
};

DropdownFilter.defaultProps = {
  locations: [],
  selected: [],
  customStyles: {}
};

export default DropdownFilter;
