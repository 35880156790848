import React from 'react';
import PropTypes from 'prop-types';

import RadioGroup from 'shared/InputGroups/RadioGroup';

const ListControls = ({ listMode, setListMode, optionKeys, optionIcons }) => (
  <RadioGroup
    controlFunc={e => setListMode(e)}
    namespace="ProductsViewSelector"
    options={optionKeys}
    selectedOption={listMode}
    optionIcons={optionIcons}
    button
  />
);

ListControls.propTypes = {
  listMode: PropTypes.string.isRequired,
  setListMode: PropTypes.func.isRequired,
  optionKeys: PropTypes.array.isRequired,
  optionIcons: PropTypes.array.isRequired
};

export default ListControls;
