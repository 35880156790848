/* vendor imports */
import React from "react";
import PropTypes from "prop-types";

/* system imports */
import PanelButton from "../../../../Buttons/components/PanelButton";

const Select = ({ toManual, toUpload }) => (
  <div>
    <h4 className="mb-1">How would you like to create your Product(s)?</h4>
    <PanelButton
      text="Start from scratch"
      helper="One product at a time"
      onClick={toManual}
    />
    <PanelButton
      text="Import Revit file(s)"
      helper="One or more files accepted"
      onClick={toUpload}
    />
  </div>
);

Select.propTypes = {
  toManual: PropTypes.func.isRequired,
  toUpload: PropTypes.func.isRequired
};

export default Select;
