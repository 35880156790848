import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { DownloadIcon } from 'shared/Miscellaneous/Icons';
import PropTypes from 'prop-types';

import Button from 'shared/Buttons';
import searchActions from '../../actions/leadSearch';

const BulkControls = ({ enable, dispatch }) => (
  <Button
    icon={<DownloadIcon />}
    style={{ marginRight: '1rem' }}
    disabled={!enable}
    onClick={() => dispatch(searchActions.extractSelectedUsers())}
  >
    Export
  </Button>
);

BulkControls.propTypes = {
  enable: PropTypes.bool,
  dispatch: PropTypes.func.isRequired
};

BulkControls.defaultProps = {
  enable: false
};

export default connect()(BulkControls);
