import { api, config, reducerUtil } from "base-client";
import { sendNotifications } from "../../shared/Toast";
import { lambdaApiCall } from "utils/fetch";

import { reducerData as searchData, actions as searchActions } from "productList";
import { actions as detailsActions } from "productDetails";
import detailsUtils from "productDetails/utils";
import { actions as jobActions } from "jobs";
import { configMap } from "configurations";

const selectProduct = (newId) => (dispatch, getState) => {
  const selected = reducerUtil.getSlice(searchData, searchData.selected, getState()) || [];

  const newSelection = [...selected];
  const index = newSelection.findIndex((id) => id === newId);
  if (index < 0) newSelection.push(newId);
  else newSelection.splice(index, 1);
  dispatch(reducerUtil.setSlice(searchData, searchData.selected, newSelection));
};

const selectAll = () => (dispatch, getState) => {
  const state = getState();
  const selected = reducerUtil.getSlice(searchData, searchData.selected, getState()) || [];

  const disabled = dispatch(
    config.actions.getData(configMap.disabled.name, configMap.disabled.newSelectAll.name)
  );

  let list;

  if (disabled) {
    const products = reducerUtil.getSlice(searchData, searchData.list, state) || [];
    list = products.map(({ id }) => id);
  } else {
    list = reducerUtil.getSlice(searchData, searchData.listId, state) || [];
  }

  if (list.length === 0) return;
  if (selected.length > 0) {
    dispatch(selectNone());
    return;
  }
  const newSelection = list.slice();
  dispatch(reducerUtil.setSlice(searchData, searchData.selected, newSelection));
};

const reset = (query) => async (dispatch) => {
  const disabled = dispatch(
    config.actions.getData(configMap.disabled.name, configMap.disabled.newSelectAll.name)
  );
  if (disabled) {
    dispatch(selectNone());
    return;
  }

  const disableFilters = dispatch(
    config.actions.getData(configMap.disabled.name, configMap.disabled.productFilters.name)
  );

  try {
    if (disableFilters) {
      const { searchResults: listIds } = await dispatch(
        api.actions.post(`search/products/ids?${query}`)
      );
      dispatch(reducerUtil.setSlice(searchData, searchData.listId, listIds));
    } else {
      const { searchResults: listIds } = await dispatch(
        api.actions.post("search/products/ids", JSON.stringify(query))
      );
      dispatch(reducerUtil.setSlice(searchData, searchData.listId, listIds));
    }
  } catch (error) {
    dispatch(config.actions.error(error));
  }
  dispatch(selectNone());
};

const selectNone = () => (dispatch) =>
  dispatch(reducerUtil.setSlice(searchData, searchData.selected, undefined));

const deleteSelected = (id) => (dispatch, getState) => {
  const selected = id ? [id] : reducerUtil.getSlice(searchData, searchData.selected, getState());

  if (!selected) return;

  const name = `${selected.length} Product${selected.length !== 1 ? "s" : ""}`;
  dispatch(detailsActions.deleteKey(selected, name, true));
};

const publishSelected = (id) => (dispatch) => {
  dispatch(publishModal(id, "Publish", "publish"));
};

const unpublishSelected = (id) => (dispatch) =>
  dispatch(publishModal(id, "Unpublish", "unpublish"));

const publishModal = (id, publishName, action) => (dispatch, getState) => {
  const selected = id ? [id] : reducerUtil.getSlice(searchData, searchData.selected, getState());

  if (!selected) {
    return;
  }

  const productName = `${selected.length} Product${selected.length !== 1 ? "s" : ""}`;

  dispatch(
    detailsActions.openModal(detailsUtils.modalNames.publishStart, {
      publishName,
      productName,
      onConfirm: async () => {
        dispatch(detailsActions.closeModal());
        const result = await dispatch(bulkCallPublish(id, action));

        if (result) {
          dispatch(
            detailsActions.openModal(detailsUtils.modalNames.publishSuccess, {
              productName,
              publishName,
              onConfirm: () => {
                dispatch(detailsActions.closeModal());
                dispatch(searchActions.search());
              },
            })
          );
        } else {
          dispatch(
            detailsActions.openModal(detailsUtils.modalNames.publishFail, {
              productName,
              publishName,
              onConfirm: () => {
                dispatch(detailsActions.closeModal());
                dispatch(publishSelected(id));
              },
            })
          );
        }
      },
    })
  );
};

const exportSelected = (id) => async (dispatch) => {

  dispatch(
    sendNotifications(
      [
        {
          type: "info",
          message: "Please be patient while your csv export is being prepared",
        },
      ],
      reducerUtil
    )
  );

  const result = await dispatch(bulkCallExportAssets(id, "exports"));

  if (!result) {
    dispatch(
      sendNotifications(
        [
          {
            type: "error",
            message: "There was an error with the export",
          },
        ],
        reducerUtil
      )
    );
  } else {
    dispatch(
      sendNotifications(
        [
          {
            type: "success",
            message: "Success, your csv files will be emailed shortly.",
          },
        ],
        reducerUtil
      )
    );
  }
};

const downloadSelected = (id) => async (dispatch) => {
  const result = await dispatch(bulkCallDownloadCSV(id, { structure: "all" }));

  if (!result) {
      dispatch(
        notificationActions.sendNotifications(
          [
            {
              type: "error",
              message: "There was an error with the download",
            },
          ],
          reducerUtil
        )
      );
    } else {
      dispatch(
        notificationActions.sendNotifications(
          [
            {
              type: "success",
              message: "Success, your download will be emailed shortly.",
            },
          ],
          reducerUtil
        )
      );
    }
  // }
};

const bulkCall = (id, endpoint, data = {}) =>
  async (dispatch, getState) => {
    const selected = id ? [id] : reducerUtil.getSlice(searchData, searchData.selected, getState());

    if (!selected) {
      return;
    }

    try {
      return await dispatch(
        api.actions.post(`products/${endpoint}`, JSON.stringify({ product_ids: selected, ...data }))
      );
    } catch (error) {
      dispatch(config.actions.error(error));
    }
};

const bulkCallPublish =
  (id, action ) =>
  async (dispatch, getState) => {
    const selected = id ? [id] : reducerUtil.getSlice(searchData, searchData.selected, getState());

    if (!selected) {
      return;
    }

    let res;
    try {
      const publishLambdaEndpoint = await dispatch(
        config.actions.getData(configMap.lambda.name, configMap.lambda.publisher.name)
      );
      res = await dispatch(
        lambdaApiCall({
          endpoint: `${publishLambdaEndpoint}`,
          method: "POST",
          body: JSON.stringify({ product_ids: selected, ...{action} }),
          async: true,
        })
      );
    } catch (error) {
      dispatch(config.actions.error(error));
    }
    return res;
};

const bulkCallDownloadCSV =
  (id, extras ) =>
  async (dispatch, getState) => {
    const selected = id ? [id] : reducerUtil.getSlice(searchData, searchData.selected, getState());

    if (!selected) {
      return;
    }

    let res;
    try {
      const downloadCsvLambdaEndpoint = await dispatch(
        config.actions.getData(configMap.lambda.name, configMap.lambda.exporterCsv.name)
      );
      res = await dispatch(
        lambdaApiCall({
          endpoint: `${downloadCsvLambdaEndpoint}`,
          method: "POST",
          body: JSON.stringify({ product_ids: selected, type: 'csv', ...extras }),
          async: true,
        })
      );
    } catch (error) {
      dispatch(config.actions.error(error));
    }
    return res;
};

const bulkCallExportAssets =
  (id, action ) =>
  async (dispatch, getState) => {
    const selected = id ? [id] : reducerUtil.getSlice(searchData, searchData.selected, getState());

    if (!selected) {
      return;
    }

    let res;
    try {
      const exportLambdaEndpoint = await dispatch(
        config.actions.getData(configMap.lambda.name, configMap.lambda.exporterAssets.name)
      );
      res = await dispatch(
        lambdaApiCall({
          endpoint: `${exportLambdaEndpoint}`,
          method: "POST",
          body: JSON.stringify({ product_ids: selected, type: 'csv', ...{action} }),
          async: true,
        })
      );
    } catch (error) {
      dispatch(config.actions.error(error));
    }
    return res;
};




export default {
  selectProduct,
  selectAll,
  selectNone,
  deleteSelected,
  publishSelected,
  unpublishSelected,
  exportSelected,
  downloadSelected,
  reset,
};
