import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import '../../assets/checkbox.scss';

const PartialCheckbox = ({ checked, isLocked, icon, onChange, size }) => (
  <label
    className={cn('checkbox', { checked, disabled : isLocked })}
    style={{ width: size }}
  >
    {icon}
    <input
      type='checkbox'
      disabled={isLocked}
      checked={checked}
      onChange={onChange}
    />
  </label>
);

PartialCheckbox.propTypes = {
  checked: PropTypes.bool,
  isLocked: PropTypes.bool,
  icon: PropTypes.element.isRequired,
  onChange: PropTypes.func.isRequired,
  size: PropTypes.number,
};

PartialCheckbox.defaultProps = {
  checked: false,
  isLocked: false,
  size: 21,
};

export default PartialCheckbox;
