import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../SvgIcon';

const Users = props => (
  <SvgIcon {...props}>
    <path
      id="shadow"
      className="color--light"
      d="M1 19C0.4 19 0 19.4167 0 20.0417V22.9583C0 23.5833 0.4 24 1 24H23C23.6 24 24 23.5833 24 22.9583V20.0417C24 19.4167 23.6 19 23 19L21 19L19 21L17 19L14 19L12 21L10 19H7L5 21L3 19H1Z"
     />
    <g className="icon--active">
      <path
        className="color--dark"
        d="M20 16.184V14C20 12.346 18.654 11 17 11H13V8.837C14.44 8.403 15.5 7.08 15.5 5.5C15.5 3.57 13.93 2 12 2C10.07 2 8.5 3.57 8.5 5.5C8.5 7.08 9.56 8.403 11 8.837V11H7C5.346 11 4 12.346 4 14V16.184C2.838 16.599 2 17.698 2 19C2 20.654 3.346 22 5 22C6.654 22 8 20.654 8 19C8 17.698 7.162 16.599 6 16.184V14C6 13.449 6.449 13 7 13H11V16.184C9.838 16.599 9 17.698 9 19C9 20.654 10.346 22 12 22C13.654 22 15 20.654 15 19C15 17.698 14.162 16.599 13 16.184V13H17C17.551 13 18 13.449 18 14V16.184C16.838 16.599 16 17.699 16 19C16 20.654 17.346 22 19 22C20.654 22 22 20.654 22 19C22 17.698 21.162 16.599 20 16.184ZM10.5 5.5C10.5 4.673 11.173 4 12 4C12.827 4 13.5 4.673 13.5 5.5C13.5 6.327 12.827 7 12 7C11.173 7 10.5 6.327 10.5 5.5ZM5 20C4.449 20 4 19.551 4 19C4 18.449 4.449 18 5 18C5.551 18 6 18.449 6 19C6 19.551 5.551 20 5 20ZM12 20C11.449 20 11 19.551 11 19C11 18.449 11.449 18 12 18C12.551 18 13 18.449 13 19C13 19.551 12.551 20 12 20ZM19 20C18.449 20 18 19.551 18 19C18 18.449 18.449 18 19 18C19.551 18 20 18.449 20 19C20 19.551 19.551 20 19 20Z"
       />
      <path
        className="color--medium"
        d="M4 19C4 19.551 4.449 20 5 20C5.551 20 6 19.551 6 19C6 18.449 5.551 18 5 18C4.449 18 4 18.449 4 19ZM11 19C11 19.551 11.449 20 12 20C12.551 20 13 19.551 13 19C13 18.449 12.551 18 12 18C11.449 18 11 18.449 11 19ZM18 19C18 19.551 18.449 20 19 20C19.551 20 20 19.551 20 19C20 18.449 19.551 18 19 18C18.449 18 18 18.449 18 19Z"
        className="color--medium"
       />
      <path
        className="color--medium"
        d="M10.5 5.5C10.5 4.673 11.173 4 12 4C12.827 4 13.5 4.673 13.5 5.5C13.5 6.327 12.827 7 12 7C11.173 7 10.5 6.327 10.5 5.5Z"
       />
    </g>
    <g className="icon--inactive">
      <path
        className="color--medium"
        d="M20 16.184V14C20 12.346 18.654 11 17 11H13V8.837C14.44 8.403 15.5 7.08 15.5 5.5C15.5 3.57 13.93 2 12 2C10.07 2 8.5 3.57 8.5 5.5C8.5 7.08 9.56 8.403 11 8.837V11H7C5.346 11 4 12.346 4 14V16.184C2.838 16.599 2 17.698 2 19C2 20.654 3.346 22 5 22C6.654 22 8 20.654 8 19C8 17.698 7.162 16.599 6 16.184V14C6 13.449 6.449 13 7 13H11V16.184C9.838 16.599 9 17.698 9 19C9 20.654 10.346 22 12 22C13.654 22 15 20.654 15 19C15 17.698 14.162 16.599 13 16.184V13H17C17.551 13 18 13.449 18 14V16.184C16.838 16.599 16 17.699 16 19C16 20.654 17.346 22 19 22C20.654 22 22 20.654 22 19C22 17.698 21.162 16.599 20 16.184ZM10.5 5.5C10.5 4.673 11.173 4 12 4C12.827 4 13.5 4.673 13.5 5.5C13.5 6.327 12.827 7 12 7C11.173 7 10.5 6.327 10.5 5.5ZM5 20C4.449 20 4 19.551 4 19C4 18.449 4.449 18 5 18C5.551 18 6 18.449 6 19C6 19.551 5.551 20 5 20ZM12 20C11.449 20 11 19.551 11 19C11 18.449 11.449 18 12 18C12.551 18 13 18.449 13 19C13 19.551 12.551 20 12 20ZM19 20C18.449 20 18 19.551 18 19C18 18.449 18.449 18 19 18C19.551 18 20 18.449 20 19C20 19.551 19.551 20 19 20Z"
       />
    </g>
  </SvgIcon>
);

Users.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
};

Users.defaultProps = {
  width: 22,
  height: 22
};

export default Users;
