import React from 'react';
import PropTypes from 'prop-types';

import { ChevronLeftIcon } from '../../../Miscellaneous/Icons';

import './parent-navigation.scss';

const NavigationToParentView = ({ push, text, url }) => (
  <div className="parent-nav" onClick={() => push(url)}>
    <ChevronLeftIcon width={11} height={11} />
    {text}
  </div>
);

NavigationToParentView.propTypes = {
  push: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
};

export default NavigationToParentView;
