/* vendor imports */
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const CardHeaderIndex = ({ title, subtitle, isBordered, controls, slimPadding }) => {
  const classNames = cn('card__header', { 'card__header--bordered': isBordered, 'card__header--slim': slimPadding });

  return (
    <div className={classNames}>
      <h3 className="card__title">
        {title}
        <span className="card__subtitle">{subtitle}</span>
      </h3>
      {controls}
    </div>
  );
};

CardHeaderIndex.propTypes = {
  isBordered: PropTypes.bool,
  slimPadding: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  controls: PropTypes.node
};

CardHeaderIndex.defaultProps = {
  isBordered: false,
  hasControls: false
};

export default CardHeaderIndex;
