import React from 'react';

import '../assets/footer.scss';

const FooterView = () => {
  const year = new Date().getFullYear();
  return <footer className="footer">&copy;{year} Concora</footer>;
};

export default FooterView;
