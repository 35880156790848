class Message {
  constructor({
    id,
    name,
    fromAddress,
    message,
    messageType,
    datetime,
    userId,
    productName,
    productId,
    projectName,
    projectValue,
    projectSegment,
    projectLocation,
    projectStage,
    productCountNeeded
  }) {
    this.id = id;
    this.name = name;
    this.fromAddress = fromAddress;
    this.message = message;
    this.messageType = messageType;
    this.datetime = datetime;
    this.userId = userId;
    this.productName = productName;
    this.productId = productId;
    this.projectName = projectName;
    this.projectValue = projectValue;
    this.projectSegment = projectSegment;
    this.projectLocation = projectLocation;
    this.projectStage = projectStage;
    this.productCountNeeded = productCountNeeded;
  }
}

export default Message;
