import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import sbManagerStyles from 'utils/globalStyles';
import detailsUtils from 'productDetails/utils';

const StyledWrapper = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const Group = styled.li`
  line-height: 2rem;
  margin: 0;
  padding: 0.5rem 0;
  cursor: pointer;
  transition: color 0.3s ease;
  &:hover {
    color: ${sbManagerStyles.blueDark};
  }
  &.active {
    font-weight: 500;
    color: ${sbManagerStyles.blueDark};
    border-right: 0.3rem solid ${sbManagerStyles.blueDark};
  }
`;

const SustainabilityGroup = ({
  activeValue = detailsUtils.sustainabilityGroups[0],
  onChangeGroup
}) => (
  <StyledWrapper>
    {detailsUtils.sustainabilityGroups.map(groupName => (
      <Group
        key={groupName}
        className={activeValue === groupName && 'active'}
        onClick={() => onChangeGroup(groupName)}
      >
        {groupName}
      </Group>
    ))}
  </StyledWrapper>
);

SustainabilityGroup.propTypes = {
  activeValue: PropTypes.string,
  onChangeGroup: PropTypes.func.isRequired
};

export default SustainabilityGroup;
