import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../SvgIcon';

const ChevronIcon = (props) => (
  <SvgIcon {...props}>
		<path d="M12,9.8236L4.2219,17.2891a0.50831,0.50831,0,0,1-.7054-0.00893l-1.3663-1.335a0.484,0.484,0,0,1-.009-0.69208L11.6383,6.1355A0.46648,0.46648,0,0,1,12,6.0015a0.49414,0.49414,0,0,1,.36167.134l9.49708,9.11755a0.484,0.484,0,0,1-.00893.69208l-1.36629,1.335a0.50841,0.50841,0,0,1-.70548.00893Z"/>
  </SvgIcon>
)

ChevronIcon.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  viewBox: PropTypes.string.isRequired,
};

ChevronIcon.defaultProps = {
  width: 24,
  height: 24,
  viewBox: '0 0 24 24',
};

export default ChevronIcon;
