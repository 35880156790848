import React, { Component } from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid';
import moment from 'moment';

import Controls from './Controls';
import Card from 'shared/PageLayout/Card';
import toTitleCase from 'utils/miscUtils';
import '../../../styles/global/table.scss';
import '../assets/insights.scss';

import { defaultMomentObjInterval } from 'shared-features-client';

const optionsStream = ['views', 'downloads'];

class InsightCard extends Component {
  constructor(props) {
    super(props);
    const { initialStream, initialInterval, optionsStream } = this.props;
    this.state = {
      stream: initialStream || optionsStream[0],
      interval: initialInterval || defaultMomentObjInterval,
      id: uuid()
    };
    this.onSelectStream = this.onSelectStream.bind(this);
    this.onSelectInterval = this.onSelectInterval.bind(this);
  }
  componentDidMount() {
    const { onSelect, productKey } = this.props;
    if (onSelect) {
      onSelect({ productKey, ...this.state });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const { onSelect, productKey, nextInterval } = this.props;
    const { nextInterval: lastInterval } = prevProps;
    if (nextInterval && !compareInterval(lastInterval, nextInterval)) {
      this.state = Object.assign({}, this.state, { interval: nextInterval });
    }
    if (this.state.interval && !compareInterval(this.state.interval, prevState.interval)) {
      onSelect({ productKey, ...this.state });
      return;
    }
    const { stream: prevStream } = prevState;
    const { stream } = this.state;
    if (prevStream !== stream) {
      onSelect({ productKey, ...this.state });
    }
  }

  onSelectStream(e) {
    this.setState({ stream: e.target.value });
  }

  onSelectInterval(interval) {
    const { startDate, endDate } = interval;
    this.setState({ interval: { startDate: moment(startDate), endDate: moment(endDate) } });
  }

  render() {
    const { title, subtitle, headerBorder, children, ...displayProps } = this.props;
    const { stream, interval } = this.state;
    const controlId = this.state.id;

    const { startDate, endDate } = interval;
    const strISOInterval = {
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString()
    };

    const controlProps = {
      onSelectStream: this.onSelectStream,
      onSelectInterval: this.onSelectInterval,
      stream,
      interval: strISOInterval,
      controlId,
      ...displayProps
    };
    return (
      <Card
        title={toTitleCase(title || stream)}
        subtitle={toTitleCase(subtitle || '')}
        headerControls={<Controls {...controlProps} />}
        headerBorder={headerBorder}
        {...displayProps}
      >
        {children &&
          React.Children.map(children, child => React.cloneElement(child, { stream, interval }))}
      </Card>
    );
  }
}

InsightCard.propTypes = {
  optionsStream: PropTypes.arrayOf(PropTypes.string),
  initialStream: PropTypes.string,
  initialInterval: PropTypes.object,
  nextInterval: PropTypes.object,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  headerBorder: PropTypes.bool,
  productKey: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element])
};

InsightCard.defaultProps = {
  optionsStream: optionsStream,
  initialStream: null,
  initialInterval: null,
  nextInterval: null,
  title: null,
  subtitle: null,
  headerBorder: false,
  productKey: null,
  children: null,
  hideHeader: false
};

const compareInterval = (a, b) => {
  return (
    a &&
    b &&
    moment(a.startDate).isSame(moment(b.startDate), 'day') &&
    moment(a.endDate).isSame(moment(b.endDate), 'day')
  );
};

export default InsightCard;
