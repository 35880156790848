import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { reducerUtil } from 'base-client';

import { reducerData as detailsData } from 'productDetails';
import detailsUtils from 'productDetails/utils';

const FormContainer = ({ handleSubmit, children }) => (
  <form onSubmit={handleSubmit}>{children}</form>
);

FormContainer.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element])
};

const mapStateToProps = state => ({
  initialValues: reducerUtil.getSlice(detailsData, detailsData.values, state)
});

export default connect(mapStateToProps)(
  reduxForm({
    form: detailsUtils.form.name,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true
  })(FormContainer)
);
