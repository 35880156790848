import React from 'react';
import PropTypes from 'prop-types';
import ReactHighcharts from 'react-highcharts';

const ChartIndex = ({
  chartInterval,
  chartState,
  config,
  handleOptionSelection,
  interval,
  namespace,
  onSelectInterval,
  options,
  title,
}) => (
  <div className='bar-chart'>
    <h3 className='stat-title text-center'>{title}</h3>
    <ReactHighcharts
      isPureConfig
      config={config}
      domProps={{ className: 'sb-chart' }}
      ref={(c) => {
        chartState[namespace]= c;
      }}
    />
  </div>
);

ChartIndex.propTypes = {
  chartState: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
}

export default ChartIndex;
