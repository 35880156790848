import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from './../SvgIcon';

const CheckBoxIcon = (props) => (
  <SvgIcon {...props}>
		<path d="M 0 4C 0 1.79086 1.79086 0 4 0L 14 0C 16.2091 0 18 1.79086 18 4L 18 14C 18 16.2091 16.2091 18 14 18L 4 18C 1.79086 18 0 16.2091 0 14L 0 4Z"/>
    <path d="M 12.1 1.8L 6.1 9.8L 5.2 11L 4 10.1L 0 7.1L 1.8 4.7L 4.6 6.8L 9.7 0L 12.1 1.8Z" transform='translate(3 4)' fill='#FFFFFF'/>
  </SvgIcon>
)

CheckBoxIcon.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  viewBox: PropTypes.string.isRequired,
};

CheckBoxIcon.defaultProps = {
  width: 24,
  height: 24,
  viewBox: '0 0 24 24',
};

export default CheckBoxIcon;