import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { connect } from 'react-redux';
import { reducerUtil } from 'base-client';

import Image from './Image';
import Buttons from './Buttons';
import Metadata from './Metadata';
import ListContainer from './ListContainer';
import { actions as detailsActions, reducerData as detailsData } from 'productDetails';
import detailsUtils from 'productDetails/utils';

const RevitContainer = ({ dispatch, assetList, input, status, disabled }) => {
  const { value } = input;
  const revitFile = assetList.find(item => item.id === value);
  const onImport = () => dispatch(detailsActions.importRevit());
  const onUpdate = () => dispatch(detailsActions.updateRevit());
  return (
    <div className="revit-source">
      <div className="file-edit">
        <h4 className="revit-source__title">Source Revit File</h4>

        <div className="details">
          <Image />
          <Buttons {...{ status, revitFile, onImport, onUpdate, disabled }} />
        </div>

        <div className="file-selection">
          <div className="file-selection--revit">
            <ListContainer
              title="Revit File"
              extensions={detailsUtils.revitExtensions}
              {...{ input, assetList, disabled }}
            />
          </div>
          <Field
            name={detailsUtils.systemNames.revitCatalog}
            title="Type Catalog"
            extensions={detailsUtils.catelogExtensions}
            component={ListContainer}
            {...{ assetList, disabled }}
          />
          <Field
            name={detailsUtils.systemNames.revitTable}
            title="Lookup Table"
            extensions={detailsUtils.tableExtensions}
            component={ListContainer}
            {...{ assetList, disabled }}
          />
          <Field
            name={detailsUtils.systemNames.revitIes}
            title="IES Profile"
            extensions={detailsUtils.iesExtensions}
            component={ListContainer}
            {...{ assetList, disabled }}
          />
        </div>
      </div>

      <Metadata {...{ revitFile }} />
    </div>
  );
};

RevitContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  assetList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string
    })
  ),
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string
  }),
  status: PropTypes.object,
  disabled: PropTypes.bool
};

RevitContainer.defaultProps = {
  assetList: [],
  input: {}
};

const mapStateToProps = state => ({
  status: reducerUtil.getSlice(detailsData, detailsData.status, state),
  assetList: reducerUtil.getSlice(detailsData, detailsData.assetList, state)
});

export default connect(mapStateToProps)(RevitContainer);
