export const Groups = [
  { key: 'Resources', display: 'Resources' },
  { key: 'General', display: 'General' },
  { key: 'Dimensional', display: 'Dimensional' },
  { key: 'Structural', display: 'Structural' },
  { key: 'Geometric', display: 'Geometric' },
  { key: 'MEP', display: 'MEP' },
  { key: 'Parametric', display: 'Parametric' },
  { key: 'Sustainability', display: 'Sustainability' },
  { key: 'Custom', display: 'Custom' },
  { key: 'Links', display: 'Links' }
];

export default Groups;
