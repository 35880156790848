import React from 'react';
import styled from 'styled-components';

import { LinkIcon } from 'shared/Miscellaneous/Icons';

const Container = styled.div`
  cursor: pointer;
`;

const ExternalAssetButton = ({ setShowModal }) => {
    return (
      <Container className="button button--default" onClick={() => { setShowModal(true) }}>
        <LinkIcon />
        <span>&nbsp;Add External Assets</span>
      </Container>
    );
}

export default ExternalAssetButton;
