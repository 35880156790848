import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reducerUtil } from 'base-client';

import JobQueueTable from './JobQueueTable';
import Button from 'shared/Buttons';
import { CloseCircleOutlineIcon } from 'shared/Miscellaneous/Icons';
import { reducerData as jobsData, actions as jobsActions } from 'jobs';
import detailsUtils from 'productDetails/utils';

class JobQueueContainer extends React.Component {
  static propTypes = {
    getJobs: PropTypes.func.isRequired,
    cancelJob: PropTypes.func.isRequired,
    jobsList: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string }))
  };

  static defaultProps = {
    jobsList: []
  };

  componentDidMount() {
    const { getJobs } = this.props;
    getJobs();
  }

  actionsCellRenderer = ({ cellData, rowData }) => {
    const { id, status, filename } = rowData;
    const { downloadFile, cancelJob } = this.props;
    switch (status) {
      case 'Failed':
      case 'Canceled':
        return null;
      case 'Completed':
        return filename && <Button onClick={() => downloadFile(filename)}> Download</Button>;
      default:
        return (
          <Button
            onClick={() => cancelJob(id)}
            icon={<CloseCircleOutlineIcon width={16} height={16} />}
          >
            Cancel
          </Button>
        );
    }
  };

  dateCellRenderer = ({ cellData }) => {
    return <div>{detailsUtils.formatTime(cellData)}</div>;
  };

  render() {
    const { jobsList } = this.props;

    const tableProps = {
      jobsList,
      rowCount: jobsList.length,
      actionsCellRenderer: this.actionsCellRenderer,
      dateCellRenderer: this.dateCellRenderer,
      rowGetter: ({ index }) => jobsList[index]
    };

    return <JobQueueTable {...tableProps} />;
  }
}

const mapStateToProps = state => ({
  jobsList: reducerUtil.getSlice(jobsData, jobsData.list, state)
});

const mapDispatchToProps = dispatch => ({
  getJobs: () => dispatch(jobsActions.getJobs()),
  cancelJob: id => dispatch(jobsActions.cancelJob(id)),
  downloadFile: filename => dispatch(jobsActions.downloadFile(filename))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JobQueueContainer);

export { JobQueueContainer, mapStateToProps, mapDispatchToProps };
