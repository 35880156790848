import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Tab } from 'react-bootstrap';
import { reducerUtil } from 'base-client';

import { reducerData as detailsData } from 'productDetails';
import { reducerData as permissionsData } from 'permissions';
import { SectionHeader } from 'shared';
import Card from 'shared/PageLayout/Card';
import TabsGroup from 'shared/Navigation/Tabs';
import { Snapshot, ProductGrowth } from 'insights';
import { LeadTable } from 'leads';

const InsightsContainer = ({ canView, productId, ...props }) => {
  if (canView && productId) {
    const insightProps = { productKey: productId, ...props };
    return (
      <div>
        <SectionHeader title="Insights" />
        <Card>
          <TabsGroup defaultActiveKey={0}>
            <Tab eventKey={0} title="Snapshot">
              <Snapshot {...insightProps} hideHeader />
            </Tab>
            <Tab eventKey={1} title="Growth">
              <ProductGrowth {...insightProps} hideHeader />
            </Tab>
            <Tab eventKey={2} title="Leads">
              <LeadTable {...insightProps} isInternalHeader />
            </Tab>
          </TabsGroup>
        </Card>
      </div>
    );
  }
  return null;
};

InsightsContainer.propTypes = {
  canView: PropTypes.bool,
  productId: PropTypes.string
};

const mapStateToProps = state => ({
  canView: reducerUtil.getSlice(permissionsData, permissionsData.insights, state),
  productId: reducerUtil.getSlice(detailsData, detailsData.productId, state)
});

export default connect(mapStateToProps)(InsightsContainer);
