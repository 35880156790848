import React, { useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { FormSection } from 'redux-form';

import { reducerUtil } from 'base-client';
import { Row, Column } from 'shared-features-client';

import detailsUtils from 'productDetails/utils';
import { actions as detailsActions, reducerData as detailsData } from 'productDetails';
import { reducerData as attributesData } from 'productAttributes';
import SustainabilitySection from './SustainabilitySection';
import ProductOptions from './ProductOptionsContainer';
import RatingSystems from './RatingSystemList';
import SustainabilityModal from './SustainabilityModal';
import CharacteristicsList from './CharacteristicsList';
import SustainabilityGroup from './SustainabilityGroup';
import Certificates from './CertificatesContainer';

const StyledWrapper = styled(Row)``;
const StyledColumn = styled(Column)`
  padding: 0;
`;

const SustainabilityContainer = ({
  certificates,
  characteristics,
  dispatch,
  options,
  ratingSystems,
  selectedOption
}) => {
  const [selectedGroup, setSelectedGroup] = useState(detailsUtils.sustainabilityGroups[0]);

  const handleOnChange = id => dispatch(detailsActions.saveSustainability(id));

  const onDeleteEnvChar = attributeId =>
    dispatch(detailsActions.deleteCharacteristics({ attributeId }));

  const handleSelectOption = newOption => {
    dispatch(detailsActions.setOption(newOption));
    setSelectedGroup(detailsUtils.sustainabilityGroups[0]);
  };

  const option = options[selectedOption || 0] || {};

  const {
    id: optionId,
    [detailsUtils.sustainabilityGroups[0]]: optionCert,
    [detailsUtils.sustainabilityGroups[1]]: optionChars
  } = option;

  return (
    <FormSection name={detailsUtils.form.sustainability}>
      <StyledWrapper>
        <StyledColumn mdUp={4}>
          <SustainabilitySection title="Product Options" noBorder>
            <ProductOptions {...{ options: options.sort((a, b) => { return a.name.localeCompare(b.name); }), handleSelectOption, selectedOption }} />
          </SustainabilitySection>
        </StyledColumn>
        <StyledColumn mdUp={8}>
          <FormSection name={optionId}>
            <Row>
              <StyledColumn mdUp={3}>
                <SustainabilitySection title="Groups">
                  <SustainabilityGroup
                    activeValue={selectedGroup}
                    onChangeGroup={group => setSelectedGroup(group)}
                  />
                </SustainabilitySection>
              </StyledColumn>
              <StyledColumn mdUp={9}>
                <SustainabilitySection title="Attributes">
                  {selectedGroup === detailsUtils.sustainabilityGroups[0] && (
                    <FormSection name={detailsUtils.sustainabilityGroups[0]}>
                      <Certificates data={optionCert} {...{ handleOnChange, certificates }} />
                      <SustainabilityModal
                        modalProps={detailsUtils.sustainabilityModalLabels.certificates}
                        group={detailsUtils.sustainabilityGroups[0]}
                        modalList={certificates}
                        selectedItems={optionCert}
                      />
                    </FormSection>
                  )}

                  {selectedGroup === detailsUtils.sustainabilityGroups[1] && (
                    <FormSection name={detailsUtils.sustainabilityGroups[1]}>
                      <CharacteristicsList
                        data={optionChars}
                        {...{ handleOnChange, onDeleteEnvChar, characteristics }}
                      />
                      <SustainabilityModal
                        modalProps={detailsUtils.sustainabilityModalLabels.characteristics}
                        group={detailsUtils.sustainabilityGroups[1]}
                        modalList={characteristics}
                        selectedItems={optionChars}
                      />
                    </FormSection>
                  )}

                  {selectedGroup === detailsUtils.sustainabilityGroups[2] && (
                    <FormSection name={detailsUtils.sustainabilityGroups[2]}>
                      <RatingSystems data={ratingSystems} {...{ optionId, handleOnChange }} />
                    </FormSection>
                  )}
                </SustainabilitySection>
              </StyledColumn>
            </Row>
          </FormSection>
        </StyledColumn>
      </StyledWrapper>
    </FormSection>
  );
};

SustainabilityContainer.defaultProps = {
  options: [],
  selectedOption: 0
};

const mapStateToProps = state => ({
  selectedOption: reducerUtil.getSlice(detailsData, detailsData.selectedOption, state),
  options: reducerUtil.getSlice(detailsData, detailsData.options, state) || [],
  certificates: reducerUtil.getSlice(attributesData, attributesData.certificates, state),
  characteristics: reducerUtil.getSlice(attributesData, attributesData.characteristics, state),
  ratingSystems: reducerUtil.getSlice(attributesData, attributesData.ratingSystems, state)
});

export default connect(mapStateToProps)(SustainabilityContainer);
