import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { reducerUtil } from 'base-client';

import { SectionHeader } from 'shared';
import ProductAttributes, {
  SharedParameters,
  actions as attributeActions
} from 'productAttributes';
import { reducerData as permissionsData } from 'permissions';

class AttributesContainer extends React.Component {
  static propTypes = {
    getAttributes: PropTypes.func.isRequired,
    canView: PropTypes.bool
  };
  static defaultProps = {
    canView: false
  };

  componentDidMount() {
    const { getAttributes } = this.props;
    getAttributes();
  }

  render() {
    const { canView } = this.props;
    if (!canView) return null;
    return (
      <React.Fragment>
        <SharedParameters />
        <SectionHeader
          title="Attribute Management"
          subtitle="Create, edit, or remove attributes from the attribute library"
        />
        <ProductAttributes noModal />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  canView: reducerUtil.getSlice(permissionsData, permissionsData.admin, state)
});

const mapDispatchToProps = dispatch => ({
  getAttributes: () => dispatch(attributeActions.getAttributes())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AttributesContainer);

export { AttributesContainer, mapStateToProps, mapDispatchToProps };
