import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { history, login, config, reducerUtil } from 'base-client';
import { reducerData as permissionsData } from 'permissions';
import { reducerData as tenantData } from 'tenant';
import { reducerData as listData, actions as listActions } from 'productList';

import SidebarNavigation from './SidebarNavigation';

const ApplicationSidebar = ({
  activeIndex,
  activeIndexSearch,
  handleSearch,
  searchValue,
  permissions,
  enableProject,
  enableApi,
  showMessages,
}) => (
  <SidebarNavigation
    {...{ activeIndex, activeIndexSearch, permissions, enableProject, enableApi, showMessages }}
  />
);

ApplicationSidebar.propTypes = {
  activeIndex: PropTypes.string,
  searchValue: PropTypes.string,
  handleSearch: PropTypes.func.isRequired,
  permissions: PropTypes.object.isRequired,
  showMessages: PropTypes.bool.isRequired
};

ApplicationSidebar.defaultProps = {
  activeIndex: null,
  searchValue: ''
};

const SidebarView = ({
  activeIndex,
  activeIndexSearch,
  searchValue,
  showSidebar,
  permissions,
  dispatch,
  enableFeatures
}) => {
  const handleSearch = name => dispatch(listActions.setText(name));

  const { messages: showMessages, projects: enableProject, api: enableApi } = enableFeatures || {};
  return (
    <div className={`sidebar${!showSidebar ? ' is-collapsed test' : ''}`}>
      <ApplicationSidebar
        {...{
          activeIndex,
          activeIndexSearch,
          handleSearch,
          searchValue,
          permissions,
          enableProject,
          enableApi,
          showMessages,
        }}
      />
    </div>
  );
};

SidebarView.propTypes = {
  userName: PropTypes.string.isRequired,
  userImage: PropTypes.string.isRequired,
  activeIndex: PropTypes.string,
  searchValue: PropTypes.string,
  showSidebar: PropTypes.bool,
  permissions: PropTypes.object,
  tenant: PropTypes.object
};

SidebarView.defaultProps = {
  userName: '',
  userImage: '',
  searchValue: '',
  permissions: {},
  tenant: {}
};

const mapStateToProps = state => {
  const { queryString: text } = reducerUtil.getSlice(listData, listData.query, state) || {};
  const profile = reducerUtil.getSlice(login.reducerData, login.reducerData.profile, state) || {};
  return {
    activeIndex: history.location.pathname,
    activeIndexSearch: history.location.search,
    userName: profile.name,
    userImage: profile.picture,
    searchValue: text,
    showSidebar: state.sidebar.showSidebar,
    permissions: {
      manager: reducerUtil.getSlice(permissionsData, permissionsData.manager, state),
      insight: reducerUtil.getSlice(permissionsData, permissionsData.insights, state),
      admin: reducerUtil.getSlice(permissionsData, permissionsData.admin, state)
    },
    enableFeatures: reducerUtil.getSlice(tenantData, tenantData.enableFeatures, state)
  };
};

export default connect(mapStateToProps)(SidebarView);
