import React from 'react';
import SvgIcon from '../SvgIcon';

import PropTypes from 'prop-types';

const WarningIcon = (props) => (
  <SvgIcon {...props}>
		<path d="M 19.8943 18.5077L 10.8943 0.50775C 10.5553 -0.16925 9.44434 -0.16925 9.10534 0.50775L 0.105343 18.5077C -0.0486568 18.8177 -0.0336566 19.1847 0.149343 19.4818C 0.331343 19.7758 0.652343 19.9548 0.999343 19.9548L 18.9993 19.9548C 19.3463 19.9548 19.6673 19.7747 19.8503 19.4807C 20.0323 19.1847 20.0483 18.8188 19.8943 18.5077ZM 8.99934 7.95475L 10.9993 7.95475L 10.9993 13.9548L 8.99934 13.9548L 8.99934 7.95475ZM 9.99934 17.2048C 9.30934 17.2048 8.74934 16.6458 8.74934 15.9548C 8.74934 15.2648 9.30934 14.7048 9.99934 14.7048C 10.6893 14.7048 11.2493 15.2648 11.2493 15.9548C 11.2493 16.6458 10.6893 17.2048 9.99934 17.2048Z"/>
  </SvgIcon>
)

WarningIcon.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  viewBox: PropTypes.string.isRequired,
};

WarningIcon.defaultProps = {
  width: 20,
  height: 20,
  viewBox: '0 0 20 20',
};

export default WarningIcon;
