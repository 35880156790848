/* vendor imports */
import React from 'react';
import PropTypes from 'prop-types';
import { AutoSizer, Column, Table } from 'react-virtualized';

/* system imports */

const List = ({ products }) => (
  <div>
    <h4 className="mb-1">{`${products.length} Products Created`}</h4>
    <AutoSizer disableHeight>
      {({ width }) => (
        <Table
          height={products.length * 50 + 55}
          width={width}
          headerHeight={55}
          rowCount={products.length}
          rowHeight={50}
          headerClassName="table-header"
          rowClassName="table-row"
          rowGetter={({ index }) => products[index]}
          onRowClick={({ rowData }) => rowData.onClick()}
          style={{ cursor: 'pointer' }}
        >
          <Column label="Product Name" dataKey="name" width={400} />
        </Table>
      )}
    </AutoSizer>
  </div>
);

List.propTypes = {
  products: PropTypes.array
};

List.defaultProps = {
  products: []
};

export default List;
