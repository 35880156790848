/* vendor imports */
import React from 'react';
import PropTypes from 'prop-types';

/* system imports */
import Form, { Controls } from '../components';
import AutoSizerList from 'shared/TablesAndLists/components/AutoSizerList';
import { AddButton } from 'shared/Buttons';
import { newAttribute } from 'productAttributes/utils';
import { LinkIcon } from 'shared/Miscellaneous/Icons';

class Container extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.setDirty = this.setDirty.bind(this);
  }
  setDirty(dirty = true) {
    this.setState({ dirty });
  }
  render() {
    const {
      attributes,
      selectedKey: key,
      setKey,
      newKey,
      controls,
      allType,
      formAttributes,
      ...props
    } = this.props;
    const { dirty } = this.state;
    const sortedAttributes = [].concat(attributes).sort((a, b) => a.name.localeCompare(b.name));

    const data = sortedAttributes.map(({ id, name, sharedParameter }) => (
      <li
        key={id}
        onClick={() => setKey(id)}
        className={`attribute__item ${id === key ? 'attribute__item--selected' : ''}`}
      >
        {sharedParameter && <LinkIcon />}
        {name}
      </li>
    ));
    const attribute = key === newAttribute ? {} : key && attributes.find(item => item.id === key);
    const isProduct = !!formAttributes;
    const isRemove = attribute && isProduct && !!formAttributes.find(id => key === id);
    const controlProps = {
      hasKey: key && key !== newAttribute,
      disabled: (!formAttributes && !key) || (attribute && !!attribute.sharedParameter),
      controls,
      allType,
      isProduct,
      isRemove,
      dirty
    };
    return (
      <div className="attribute__list">
        <AutoSizerList
          id="attribute-library"
          title="Attribute Library"
          {...{ data }}
          controls={!isProduct && <CreateButton onClick={newKey} />}
        />
        <div className="AttributeContent">
          <Form
            disabled={isProduct || (attribute && !!attribute.sharedParameter)}
            {...{ attribute, controls, isRemove, setDirty: this.setDirty }}
            {...props}
          />
          <Controls {...controlProps} />
        </div>
      </div>
    );
  }
}

Container.propTypes = {
  setKey: PropTypes.func.isRequired,
  newKey: PropTypes.func.isRequired,
  controls: PropTypes.object.isRequired,
  attributes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
      shared: PropTypes.bool
    })
  ),
  selectedKey: PropTypes.string,
  isProduct: PropTypes.bool,
  allType: PropTypes.bool,
  formAttributes: PropTypes.array
};

Container.defaultProps = {
  attributes: [],
  selectedKey: null,
  isProduct: false,
  allType: false,
  formAttributes: null
};

const CreateButton = ({ onClick }) => (
  <AddButton iconPosition="after" {...{ onClick }}>
    Create Attribute
  </AddButton>
);

CreateButton.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default Container;
