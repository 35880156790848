import React from 'react';
import BaseGallery from 'react-fine-uploader';

import Dropzone from 'react-fine-uploader/dropzone';
import Filename from 'react-fine-uploader/filename';
import Filesize from 'react-fine-uploader/filesize';
import RetryButton from 'react-fine-uploader/retry-button';
import PauseResumeButton from 'react-fine-uploader/pause-resume-button';
import ProgressBar from 'react-fine-uploader/progress-bar';
import Status from 'react-fine-uploader/status';
import Thumbnail from 'react-fine-uploader/thumbnail';

import UploadIcon from 'react-fine-uploader/gallery/upload-icon';
import UploadFailedIcon from 'react-fine-uploader/gallery/upload-failed-icon';
import UploadSuccessIcon from 'react-fine-uploader/gallery/upload-success-icon';

import CancelButton from './Cancel';
import DeleteButton from './Delete';
import FileInput from './FileInput';

const getComponentProps = (componentName, allProps) => {
  const componentProps = {};
  Object.keys(allProps).forEach(propName => {
    if (propName.indexOf(`${componentName}-`) === 0) {
      const componentPropName = propName.substr(componentName.length + 1);
      componentProps[componentPropName] = allProps[propName];
    }
  });
  return componentProps;
};

const getDefaultMaybeDropzoneContent = ({ content, disabled, multi }) => {
  const className = `react-fine-uploader-gallery-${
    disabled ? 'nodrop-content' : 'dropzone-content'
  }`;
  if (disabled && !content) {
    return <span className={className}>{multi ? 'Upload files' : 'Upload a file'}</span>;
  } else if (content) {
    return <span className={className}>{content}</span>;
  } else if (!disabled) {
    return (
      <span className={className}>
        <UploadIcon className="react-fine-uploader-gallery-dropzone-upload-icon" />
        {multi ? 'Drop files here' : 'Drop a file here'}
      </span>
    );
  }
};

const getVisibleFiles = uploader =>
  uploader.methods.getUploads().map(file => ({
    id: file.id,
    status: file.status,
    fromServer: true
  }));

const MaybeDropzone = ({ children, content, hasVisibleFiles, uploader, ...props }) => {
  const { disabled, ...dropzoneProps } = props;
  let dropzoneDisabled = disabled;
  if (!dropzoneDisabled) {
    dropzoneDisabled = !uploader.qq.supportedFeatures.fileDrop;
  }
  if (hasVisibleFiles) {
    content = <span />;
  } else {
    content =
      content ||
      getDefaultMaybeDropzoneContent({
        content,
        disabled: dropzoneDisabled,
        multi: dropzoneProps.multiple
      });
  }
  if (dropzoneDisabled) {
    return (
      <div className="react-fine-uploader-gallery-nodrop-container">
        {content}
        {children}
      </div>
    );
  }
  return (
    <Dropzone
      className="react-fine-uploader-gallery-dropzone"
      uploader={uploader}
      {...dropzoneProps}
    >
      {content}
      {children}
    </Dropzone>
  );
};

const FileInputComponent = ({ uploader, ...props }) => {
  const { children, ...fileInputProps } = props;
  const content = children || (
    <span>
      <UploadIcon className="react-fine-uploader-gallery-file-input-upload-icon" />
      Select Files
    </span>
  );
  return (
    <FileInput
      className="react-fine-uploader-gallery-file-input-container"
      uploader={uploader}
      {...fileInputProps}
    >
      <span className="react-fine-uploader-gallery-file-input-content">{content}</span>
    </FileInput>
  );
};

class Gallery extends BaseGallery {
  constructor(props) {
    super(props);
    this.updateStatus = this.updateStatus.bind(this);
    this.removeVisibleFile = this.removeVisibleFile.bind(this);
  }

  componentDidMount() {
    this.props.uploader.on('statusChange', this.updateStatus);
  }

  componentWillMount() {
    this.setState({ visibleFiles: getVisibleFiles(this.props.uploader) });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.uploader === nextProps.uploader) return;
    this.setState({ visibleFiles: getVisibleFiles(nextProps.uploader) });
    this.props.uploader.off('statusChange', this.updateStatus);
    nextProps.uploader.on('statusChange', this.updateStatus);
  }

  componentWillUnmount() {
    this.props.uploader.off('statusChange', this.updateStatus);
  }

  updateStatus(id, oldStatus, status) {
    const { uploader, multi } = this.props;
    const statusEnum = uploader.qq.status;
    const { _onStatusChange, removeVisibleFile } = this;
    _onStatusChange(id, oldStatus, status);
    if (status === statusEnum.REJECTED || status === statusEnum.DELETED) removeVisibleFile(id);
    if (status === statusEnum.SUBMITTED && !multi) {
      this.state.visibleFiles.forEach(file => {
        if (file.id !== id) removeVisibleFile(file.id);
      });
    }
  }

  removeVisibleFile(id) {
    const visibleFileIndex = this.state.visibleFiles.findIndex(file => file.id === id);
    if (visibleFileIndex >= 0) {
      const { visibleFiles } = this.state;
      visibleFiles.splice(visibleFileIndex, 1);
      this.setState({ visibleFiles });
    }
  }

  render() {
    const cancelButtonProps = getComponentProps('cancelButton', this.props);
    const dropzoneProps = Object.assign(getComponentProps('dropzone', this.props), {
      multiple: this.props.multi
    });
    const fileInputProps = Object.assign(getComponentProps('fileInput', this.props), {
      multiple: this.props.multi
    });
    const filenameProps = getComponentProps('filename', this.props);
    const filesizeProps = getComponentProps('filesize', this.props);
    const progressBarProps = getComponentProps('progressBar', this.props);
    const retryButtonProps = getComponentProps('retryButton', this.props);
    const statusProps = getComponentProps('status', this.props);
    const thumbnailProps = getComponentProps('thumbnail', this.props);
    const { uploader } = this.props;
    const chunkingEnabled = uploader.options.chunking && uploader.options.chunking.enabled;
    const deleteEnabled =
      uploader.options.deleteFile && uploader.options.deleteFile.enabled && !this.props.disabled;
    const deleteButtonProps = deleteEnabled && getComponentProps('deleteButton', this.props);
    const pauseResumeButtonProps =
      chunkingEnabled && getComponentProps('pauseResumeButton', this.props);

    return (
      <MaybeDropzone
        content={this.props.children}
        hasVisibleFiles={this.state.visibleFiles.length > 0}
        uploader={uploader}
        {...dropzoneProps}
        disabled={this.props.disabled}
      >
        {!fileInputProps.disabled &&
          !this.props.disabled && <FileInputComponent uploader={uploader} {...fileInputProps} />}
        <ProgressBar
          className="react-fine-uploader-gallery-total-progress-bar"
          uploader={uploader}
          {...progressBarProps}
        />
        <ul className="react-fine-uploader-gallery-files" component="ul">
          {this.state.visibleFiles.map(({ id, status, fromServer }) => (
            <li key={id} className="react-fine-uploader-gallery-file">
              <ProgressBar
                className="react-fine-uploader-gallery-progress-bar"
                id={id}
                uploader={uploader}
                {...progressBarProps}
              />
              <Thumbnail
                className="react-fine-uploader-gallery-thumbnail"
                id={id}
                fromServer={fromServer}
                uploader={uploader}
                {...thumbnailProps}
              />
              {status === 'upload successful' && (
                <span>
                  <UploadSuccessIcon className="react-fine-uploader-gallery-upload-success-icon" />
                  <div className="react-fine-uploader-gallery-thumbnail-icon-backdrop" />
                </span>
              )}
              {status === 'upload failed' && (
                <span>
                  <UploadFailedIcon className="react-fine-uploader-gallery-upload-failed-icon" />
                  <div className="react-fine-uploader-gallery-thumbnail-icon-backdrop" />
                </span>
              )}
              <div className="react-fine-uploader-gallery-file-footer">
                <Filename
                  className="react-fine-uploader-gallery-filename"
                  id={id}
                  uploader={uploader}
                  {...filenameProps}
                />
                <Status
                  className="react-fine-uploader-gallery-status"
                  id={id}
                  uploader={uploader}
                  {...statusProps}
                />
                <Filesize
                  className="react-fine-uploader-gallery-filesize"
                  id={id}
                  uploader={uploader}
                  {...filesizeProps}
                />
              </div>
              <CancelButton
                className="react-fine-uploader-gallery-cancel-button"
                id={id}
                uploader={uploader}
                {...cancelButtonProps}
              />
              <RetryButton
                className="react-fine-uploader-gallery-retry-button"
                id={id}
                uploader={uploader}
                {...retryButtonProps}
              />
              {deleteEnabled && (
                <DeleteButton
                  className="react-fine-uploader-gallery-delete-button"
                  id={id}
                  uploader={uploader}
                  {...deleteButtonProps}
                />
              )}
              {chunkingEnabled && (
                <PauseResumeButton
                  className="react-fine-uploader-gallery-pause-resume-button"
                  id={id}
                  uploader={uploader}
                  {...pauseResumeButtonProps}
                />
              )}
            </li>
          ))}
        </ul>
      </MaybeDropzone>
    );
  }
}

export default Gallery;
