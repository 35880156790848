import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../SvgIcon';

const SolidConcoraLogo = ({ color, ...props }) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M9.17118 12.753C8.69724 12.8741 8.40961 13.3561 8.53055 13.8316C8.63296 14.233 8.99359 14.5002 9.38908 14.5002C9.46099 14.5002 9.53508 14.4915 9.60807 14.4729C12.8287 13.6539 15.0033 10.8609 15.0535 7.50073H13.2808C13.2307 10.0353 11.5932 12.1379 9.17118 12.753Z"
        fill={color}
      />
      <path
        d="M5.82137 14.4729C5.89436 14.4915 5.96736 14.5002 6.04036 14.5002C6.43585 14.5002 6.79648 14.233 6.8989 13.8316C7.01983 13.3572 6.7322 12.8741 6.25826 12.753C3.83627 12.1379 2.19873 10.0353 2.14971 7.50073H0.375977C0.425005 10.8609 2.59967 13.6539 5.82137 14.4729Z"
        fill={color}
      />
      <path
        d="M7.67935 4.31982C5.92523 4.31982 4.50342 5.74305 4.50342 7.49891C4.50342 9.25478 5.92523 10.678 7.67935 10.678C9.43347 10.678 10.8553 9.25478 10.8553 7.49891C10.8553 5.74305 9.43347 4.31982 7.67935 4.31982Z"
        fill={color}
      />
      <path
        d="M13.7917 3.41401C12.8177 1.95588 11.4231 0.957985 9.75836 0.52829C9.28442 0.406143 8.80068 0.690789 8.67865 1.1652C8.55663 1.63961 8.84099 2.12383 9.31493 2.24598C12.2043 2.99304 13.2808 5.79369 13.2808 7.38269C13.2808 7.42195 13.2797 7.46012 13.2786 7.49938H15.0512C15.0523 7.46012 15.0534 7.42195 15.0534 7.38269C15.0534 6.06089 14.5936 4.61476 13.7917 3.41401Z"
        fill={color}
      />
      <path
        d="M6.11103 2.24622C6.58497 2.12408 6.87042 1.63985 6.74731 1.16544C6.62528 0.691033 6.14154 0.406387 5.6676 0.528534C4.00282 0.95932 2.60824 1.95722 1.63422 3.41425C0.832334 4.615 0.372559 6.06113 0.372559 7.38403C0.372559 7.42329 0.374738 7.46146 0.374738 7.50072H2.14738C2.14629 7.46146 2.1452 7.42329 2.1452 7.38403C2.1452 5.79394 3.22164 2.99328 6.11103 2.24622Z"
        fill={color}
      />
    </SvgIcon>
  );
};

SolidConcoraLogo.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired
};

SolidConcoraLogo.defaultProps = {
  width: 16,
  height: 15,
  color: '#096ECC'
};

export default SolidConcoraLogo;
