/* vendor imports */
import React from 'react';
import { connect } from 'react-redux';

import { reducerData as insightsData, actions } from 'insights';
import { reducerUtil } from 'base-client';
import Chart from '../../shared/Insights/Chart';


const ProductGrowth = props => (
  <Chart title="Growth" subtitle="See how much your company has grown" {...props} />
)

const mapStateToProps = state => {
  const productGrowth = reducerUtil.getSlice(insightsData, insightsData.productGrowth, state) || {};
  return {
    chart: productGrowth
  };
};

const mapDispatchToProps = dispatch => ({
  onSelect: ({ stream, interval, productKey }) =>
    dispatch(actions.getProductGrowth(stream, interval, productKey))
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductGrowth);
