import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reducerUtil } from 'base-client';

import { actions as detailsActions, reducerData as detailsData } from 'productDetails';
import Progress from './Progress';
import Success from './Success';
import Failed from './Failed';

const menus = {
  none: 'none',
  progress: 'progress',
  success: 'success',
  failed: 'failed'
};

const ProgressBar = ({ dispatch, fileList }) => {
  const [state, setState] = useState({ menu: menus.none });
  const { menu, open } = state;

  const successes = fileList.reduce((total, item) => {
    if (item.success) return total + 1;
    return total;
  }, 0);

  const fails = fileList.reduce((total, item) => {
    if (item.failed) return total + 1;
    return total;
  }, 0);

  useEffect(() => {
    if (fileList.length <= 0) {
      setState({ ...state, menu: menus.none, open: false });
    } else if (successes + fails < fileList.length) {
      if (menu === menus.none) setState({ ...state, menu: menus.progress, open: true });
    } else if (fails) {
      setState({ ...state, menu: menus.failed, open: true });
    } else {
      setState({ ...state, menu: menus.success, open: true });
    }
  }, [fileList, successes, fails]);

  const onClick = () => {
    dispatch(detailsActions.stopTrack());
    setState({ ...state, open: false });
  };

  if (!open || menu === menus.none) return null;

  const childProps = { onClick, successes, fails, fileList };

  switch (menu) {
    case menus.progress:
      return <Progress {...childProps} />;
    case menus.failed:
      return <Failed {...childProps} />;
    case menus.success:
      return <Success {...childProps} />;
  }
};

ProgressBar.propTypes = {
  dispatch: PropTypes.func.isRequired,
  fileList: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      success: PropTypes.bool,
      failed: PropTypes.bool
    })
  )
};

ProgressBar.defaultProps = {
  fileList: []
};

const mapStateToProps = state => ({
  fileList: reducerUtil.getSlice(detailsData, detailsData.fileList, state)
});

export default connect(mapStateToProps)(ProgressBar);
