import Name from './Name';
import Group from './Group';
import Type from './Type';
import Distribution from './Distribution';
import SharedParameterGuid from './SharedParameterGuid';

export default {
  Name,
  Group,
  Type,
  Distribution,
  SharedParameterGuid
};
