import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment';

import sbManagerStyles from 'utils/globalStyles';

import {
  ProjectCreatedIcon,
  ProjectUpdatedIcon,
  ProductsAddedIcon,
  ProductsRemovedIcon,
  ProjectDeletedIcon,
  SubmittalGeneratedIcon
} from 'shared/Miscellaneous/Icons';

const StyledWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 1rem 2rem 1rem 0;
`;

const ActionInfor = styled.div`
  padding: 0.3rem 0 0 1rem;
`;

const Days = styled.div`
  font-size: 1.1rem;
  line-height: 1.4rem;
  font-weight: normal;
  text-transform: uppercase;
  color: ${() => sbManagerStyles.greyMedium};
`;

const Text = styled.p`
  margin: 0;
  font-size: 1.4rem;
  line-height: 1.8rem;
  font-weight: normal;
  color: ${() => sbManagerStyles.greyDarkest01};
`;

const HistoryTag = ({ datetime, type, eventMetadata: { products }, ...props }) => {
  let Icon;
  let text;

  const count = (products && products.length) || 0;
  const date = moment(datetime).fromNow();

  switch (type) {
    case 'projectupdate':
      Icon = ProjectUpdatedIcon;
      text = 'Project updated';
      break;
    case 'projectaddproduct':
      Icon = ProductsAddedIcon;
      text = `${count} product${count > 1 ? 's' : ''} added`;
      break;
    case 'projectremoveproduct':
      Icon = ProductsRemovedIcon;
      text = `${count} product${count > 1 ? 's' : ''} removed`;
      break;
    case 'projectdelete':
      Icon = ProjectDeletedIcon;
      text = 'Project deleted';
      break;
    case 'submittal':
      Icon = SubmittalGeneratedIcon;
      text = 'Submittal created';
      break;
    case 'brochure':
      Icon = SubmittalGeneratedIcon;
      text = 'Brochure created';
      break;
    case 'projectclone':
      Icon = ProjectCreatedIcon;
      text = 'Project cloned';
      break;
    default:
      Icon = ProjectCreatedIcon;
      text = 'Project created';
      break;
  }

  return (
    <StyledWrapper className="history-action" {...props}>
      <Icon />
      <ActionInfor>
        <Days>{date}</Days>
        <Text>{text}</Text>
      </ActionInfor>
    </StyledWrapper>
  );
};

HistoryTag.propTypes = {
  datetime: PropTypes.string,
  type: PropTypes.string,
  eventMetadata: PropTypes.shape({ products: PropTypes.array })
};

HistoryTag.defaultProps = {
  eventMetadata: {}
};

export default HistoryTag;
