/* vendor imports */
import React from 'react';
import PropTypes from 'prop-types';

/* system imports */
import Button from '../../../../Buttons';
import Loading from '../../../../Loading';
import Gallery from '../../../../Forms/FineUploader/Gallery';

const Upload = ({ uploader, finishUpload, disableProcess }) => (
  <div>
    <h4 className='mb-1'>Import file(s) here.</h4>
    {uploader ? <Gallery {...{ uploader }} multi /> : <Loading />}
    <Button buttonStyle='green' onClick={finishUpload} disabled={disableProcess}>Start Processing</Button>
  </div>
);

Upload.propTypes = {
  finishUpload: PropTypes.func.isRequired,
  uploader: PropTypes.object,
  disableProcess: PropTypes.bool,
};

Upload.defaultProps = {
  uploader: null,
  disableProcess: false,
};

export default Upload;
