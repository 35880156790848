import React from 'react';
import styled from 'styled-components';

import sbManagerStyles from 'utils/globalStyles';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-left: ${props => (props.noBorder ? 'none' : `1px solid ${sbManagerStyles.greyLight}`)};
  min-height: 10rem;
`;

const Title = styled.h6`
  font-size: 1.4rem;
  font-weight: 500;
  padding: 1rem 1.5rem;
  margin: 0;
  border-bottom: 1px solid ${sbManagerStyles.greyLight};
`;

const Content = styled.div`
  padding: 1.5rem 0 1.5rem 1.5rem;
`;

const SustainabilitySection = ({ title, children, ...props }) => (
  <StyledContainer {...props}>
    <Title>{title}</Title>
    <Content>{children}</Content>
  </StyledContainer>
);

export default SustainabilitySection;
