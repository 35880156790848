import React from 'react';

import './assets/loading.scss';

const style = {
  marginLeft: 20,
};

const ProductListLoading = () => (
  <div style={style}>Loading</div>
);


export default ProductListLoading;
