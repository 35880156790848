import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from './../SvgIcon';

const EditIcon = (props) => (
  <SvgIcon {...props}>
    <rect x="5.5" y="8.416" transform="matrix(0.707 -0.7072 0.7072 0.707 -5.4119 11.819)" width="12.119" height="8.05"/>
    <path d="M20.104,9.59l0.72-0.719c1.569-1.569,1.569-4.123,0-5.693c-1.569-1.569-4.124-1.569-5.693,0l-0.719,0.72 L20.104,9.59z"/>
    <path d="M8.4,20.68l-5.156,1.288c-0.341,0.084-0.702-0.015-0.95-0.263c-0.248-0.248-0.348-0.609-0.263-0.949 L3.32,15.6L8.4,20.68z"/>
  </SvgIcon>
)

EditIcon.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  viewBox: PropTypes.string.isRequired,
};

EditIcon.defaultProps = {
  width: 24,
  height: 24,
  viewBox: '0 0 24 24',
};

export default EditIcon;