import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { FormControl } from 'react-bootstrap';
import cn from 'classnames';

import TextContainer from './TextContainer';

const TextArea = props => (
  <Field {...props} component={TextContainer}>
    <TextAreaField />
  </Field>
);

const TextAreaField = ({ edit, bsClass, input, ...props }) => {
  if (!edit) {
    const { value } = input;
    return <div className="editable-value">{value || ''}</div>;
  }
  return (
    <FormControl
      {...props}
      {...input}
      componentClass="textarea"
      bsClass={cn('form-control', bsClass)}
      className="input-editing--textarea"
      rows={5}
    />
  );
};

TextAreaField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string
  }),
  bsClass: PropTypes.object,
  edit: PropTypes.bool
};

TextAreaField.defaultProps = {
  input: { value: '', onChange: () => undefined }
};

export default TextArea;
export { TextAreaField };
