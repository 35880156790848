/* vendor imports */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { login, reducerUtil } from 'base-client';
import { actions as adminActions, reducerData as adminData } from 'admin';
import UserAccounts from '../components/UserAccounts';

class container extends PureComponent {
  static noRowsRenderer() {
    return <div>No Rows</div>;
  }

  constructor(props) {
    super(props);

    this.state = {
      headerHeight: 40, // table
      overscanRowCount: 10, // table
      rowCount: 0, // table
      rowHeight: 50, // table
      usersCount: 0
    };

    this.loadMoreRows = this.loadMoreRows.bind(this);
    this.noRowsRenderer = container.noRowsRenderer; // table
    this.rowRenderer = this.rowRenderer.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    const { users, maxUsers } = props;
    const rowCount = users ? users.length : 0;
    const usersCount = users ? users.length : 0;
    const canAddUser = maxUsers - state.usersCount > 0;
    return { ...state, ...{ users, rowCount, usersCount, canAddUser } };
  }

  getDatum(index) {
    const { users } = this.state;
    if (index === index % users.length) {
      const user = users[index % users.length];
      const canEdit = user.name !== this.props.currentUser.email;

      return { ...users[index % users.length], canEdit };
    }
    return 'loading';
  }

  isRowLoaded({ index }) {
    const { users } = this.state;
    return index < users.length;
  }

  loadMoreRows() {
    const { isNextPageLoading, nextPage } = this.props;
    return isNextPageLoading ? () => {} : nextPage();
  }

  rowRenderer({ className, columns, index, key, style }) {
    const content = !this.isRowLoaded({ index }) ? 'loading' : columns;

    return (
      <div className={className} key={key} role="row" style={style}>
        {content}
      </div>
    );
  }

  render() {
    const rowGetter = ({ index }) => this.getDatum(index);

    return (
      <UserAccounts
        {...this.props}
        {...this.state}
        noRowsRenderer={this.noRowsRenderer}
        rowGetter={rowGetter}
        rowRenderer={this.rowRenderer}
      />
    );
  }
}

container.propTypes = {
  users: PropTypes.array,
  maxUsers: PropTypes.number,
  currentUser: PropTypes.shape({
    email: PropTypes.string
  }).isRequired
};

container.defaultProps = {
  users: [],
  maxUsers: 0
};

const mapStateToProps = state => {
  return {
    currentUser: reducerUtil.getSlice(login.reducerData, login.reducerData.profile, state),
    users: reducerUtil.getSlice(adminData, adminData.userDisplay, state)
    // maxUsers: reducerUtil.getSlice(adminData, adminData.users, state)
  };
};

const mapDispatchToProps = dispatch => ({
  getUsers: () => dispatch(adminActions.getUserList()),
  clickOpenNewUser: () => dispatch(adminActions.openUserNewModal())
});

export default connect(mapStateToProps, mapDispatchToProps)(container);
