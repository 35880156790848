import { history, api, config, reducerUtil } from "base-client";

import { reducerData as detailsData } from "productDetails";
import loadActions from "./load";
import { actions as listActions } from "productList";
import { openModal, closeModal, getProductName } from "./modals";
import fileDownload from "utils/fileDownload";
import detailsUtils from "productDetails/utils";
import { configMap } from "configurations";
import { lambdaApiCall } from "utils/fetch";

// ------------------------------------
// Product Saving
// ------------------------------------
const publish = (shouldPublish) => (dispatch, getState) => {
  const state = getState();
  const productId = reducerUtil.getSlice(detailsData, detailsData.productId, state);

  if (!productId) return;

  const productName = getProductName(state);
  const publishName = shouldPublish ? "Publish" : "Unpublish";

  dispatch(
    openModal(
      detailsUtils.modalNames.publishStart,
      {
        publishName,
        onConfirm: () => {
          dispatch(closeModal());
          return dispatch(handlePublish([productId], productName, publishName));
        },
      },
      productName
    )
  );
};

const publishKey = (productKey, productName, publishName, modal) => (dispatch) => {
  const productKeyArray = Array.isArray(productKey) ? productKey : [productKey];
  if (modal) {
    dispatch(
      openModal(
        detailsUtils.modalNames.publishStart,
        {
          publishName,
          onConfirm: () => {
            dispatch(closeModal());
            return dispatch(handlePublish(productKeyArray, productName, publishName, false));
          },
        },
        productName
      )
    );
  } else return dispatch(handlePublish(productKeyArray, productName, publishName, false));
};

const handleSinglePublish =
  (productKey, productName, publishName, onProduct) => async (dispatch) => {
    const action = publishName.toLowerCase()

    try {
      const res = await dispatch(callPublish(productKey, action));
      dispatch(
        openModal(
          detailsUtils.modalNames.publishSuccess,
          {
            productName,
            publishName,
            onConfirm: () => {
              dispatch(closeModal());
              if (!onProduct) {
                history.push("/products");
              }
            },
          },
          productName
        )
      );
      if (onProduct) {
        dispatch(loadActions.refreshProduct());
      }
    } catch (error) {
      dispatch(config.actions.error(error));
      dispatch(
        openModal(
          detailsUtils.modalNames.publishFail,
          {
            publishName,
            onConfirm: () => {
              dispatch(closeModal());
              dispatch(handlePublish([productKey], publishName));
            },
          },
          productName
        )
      );
    }
  };

const callPublish =
  (id, action) =>
  async (dispatch, getState) => {
    const selected = id ? [id] : reducerUtil.getSlice(searchData, searchData.selected, getState());

    if (!selected) {
      return;
    }

    let res;

    try {
      const lambdaEndpoint = await dispatch(
        config.actions.getData(configMap.lambda.name, configMap.lambda.publisher.name)
      );
      res = await dispatch(
        lambdaApiCall({
          endpoint: `${lambdaEndpoint}`,
          method: "POST",
          body: JSON.stringify({ product_ids: selected, ...{action} }),
          async: true,
        })
      );
    } catch (error) {
      dispatch(config.actions.error(error));
    }
    return res;
  };

const handleBulkPublish = (productKeys, productName, publishName) => async (dispatch) => {
  const action = publishName === "Publish" ? "publishVersion" : "unpublishVersion";
  
  try {
    await Promise.all(
      productKeys.map(async (productKey) => {
        await dispatch(
          api.actions.post(`products/${productKey}/productVersions/Active/manage/${action}`)
        );
      })
    );
    dispatch(
      openModal(
        detailsUtils.modalNames.publishSuccess,
        {
          productName,
          publishName,
          onConfirm: () => {
            dispatch(closeModal());
          },
        },
        productName
      )
    );
  } catch (error) {
    dispatch(config.actions.error(error));
    dispatch(
      openModal(detailsUtils.modalNames.publishFail, {
        publishName,
        onConfirm: () => {
          dispatch(closeModal());
          dispatch(handlePublish(productKeys, publishName));
        },
      })
    );
  }
};

const handlePublish =
  (productKeys, productName, publishName, onProduct = true) =>
  (dispatch) => {
    if (productKeys.length < 1) {
      return;
    }
    if (productKeys.length === 1) {
      return dispatch(handleSinglePublish(productKeys[0], productName, publishName, onProduct));
    } else {
      return dispatch(handleBulkPublish(productKeys, productName, publishName));
    }
  };

const deleteProduct = () => (dispatch, getState) => {
  const state = getState();
  const productId = reducerUtil.getSlice(detailsData, detailsData.productId, state);

  if (!productId) return;

  dispatch(
    openModal(detailsUtils.modalNames.deleteStart, {
      onConfirm: () => {
        dispatch(closeModal());
        dispatch(handleDelete([productId], getProductName(state)));
      },
    })
  );
};

const deleteKey = (productKey, productName, modal) => (dispatch) => {
  const productKeyArray = Array.isArray(productKey) ? productKey : [productKey];
  if (modal) {
    dispatch(
      openModal(
        detailsUtils.modalNames.deleteStart,
        {
          onConfirm: () => {
            dispatch(closeModal());
            dispatch(handleDelete(productKeyArray, productName, false));
          },
        },
        productName
      )
    );
  } else dispatch(handleDelete(productKeyArray, productName, false));
};

const handleSingleDelete = (productKey, productName, onProduct) => async (dispatch) => {
  try {
    await dispatch(api.actions.delete(`products/${productKey}`));
    dispatch(
      openModal(
        detailsUtils.modalNames.deleteSuccess,
        {
          onConfirm: () => {
            dispatch(closeModal());
            history.push("/products");
            dispatch(listActions.search());
          },
        },
        productName
      )
    );
  } catch (error) {
    dispatch(config.actions.error(error));
    dispatch(
      openModal(
        detailsUtils.modalNames.deleteFail,
        {
          onConfirm: () => {
            dispatch(closeModal());
            dispatch(handleDelete([productKey]));
          },
        },
        productName
      )
    );
  }
};

const handleBulkDelete = (productKeys, productName) => async (dispatch) => {
  try {
    await dispatch(
      api.actions.delete("products", JSON.stringify({ product_ids: productKeys, force: false }))
    );
    dispatch(
      openModal(
        detailsUtils.modalNames.deleteSuccess,
        {
          onConfirm: () => {
            dispatch(closeModal());
            dispatch(listActions.search());
          },
        },
        productName
      )
    );
  } catch (error) {
    dispatch(config.actions.error(error));
    dispatch(
      openModal(
        detailsUtils.modalNames.deleteFail,
        {
          onConfirm: () => {
            dispatch(closeModal());
            dispatch(handleDelete(productKeys));
          },
        },
        productName
      )
    );
  }
};

const handleDelete =
  (productKeys, productName, onProduct = true) =>
  (dispatch) => {
    if (productKeys.length < 1) return Promise.resolve();
    if (productKeys.length === 1) {
      dispatch(handleSingleDelete(productKeys[0], productName, onProduct));
    } else {
      dispatch(handleBulkDelete(productKeys, productName));
    }
  };

const download = () => (dispatch, getState) => {
  const productId = reducerUtil.getSlice(detailsData, detailsData.productId, getState());
  if (productId) dispatch(handleDownload(productId));
};

const downloadKey = (productKey) => (dispatch) => dispatch(handleDownload(productKey));

const handleDownload = (productKey) => async (dispatch, getState) => {
  try {
    const result = await dispatch(api.actions.get(`package/product/${productKey}`));
    fileDownload(result, `${getProductName(getState())}.zip`);
  } catch (error) {
    dispatch(config.actions.error(error));
    dispatch(
      openModal(detailsUtils.modalNames.downloadFail, {
        onConfirm: () => {
          dispatch(closeModal());
          dispatch(handleDownload(productKey));
        },
      })
    );
  }
};

const updateRevit = () => (dispatch, getState) => {
  const productId = reducerUtil.getSlice(detailsData, detailsData.productId, getState());
  if (!productId) return;
  dispatch(
    openModal(detailsUtils.modalNames.updateRevitConfirmation, {
      onConfirm: () => {
        dispatch(closeModal());
        dispatch(handleUpdate(productId));
      },
    })
  );
};

const handleUpdate = (productKey) => async (dispatch) => {
  try {
    await dispatch(
      api.actions.post(`products/${productKey}/manage/exportRevit`, JSON.stringify({}))
    );
    dispatch(loadActions.refreshProduct());
  } catch (error) {
    dispatch(config.actions.error(error));
    dispatch(
      openModal(detailsUtils.modalNames.downloadUpdateFail, {
        onConfirm: () => {
          dispatch(closeModal());
          dispatch(handleUpdate(productKey));
        },
      })
    );
  }
};

const importRevit = () => (dispatch, getState) => {
  const productId = reducerUtil.getSlice(detailsData, detailsData.productId, getState());
  if (!productId) return;

  dispatch(
    openModal(detailsUtils.modalNames.importRevitConfirmation, {
      onConfirm: () => {
        dispatch(closeModal());
        dispatch(handleImportRevit(productId));
      },
    })
  );
};

// This is only different from handleExportRevit in endpoint, but keep it
//   like this for clarity, please.
const handleImportRevit = (productKey) => async (dispatch) => {
  try {
    await dispatch(
      api.actions.post(`products/${productKey}/manage/importRevit`, JSON.stringify({}))
    );
    dispatch(closeModal());
    dispatch(loadActions.refreshProduct());
  } catch (error) {
    dispatch(config.actions.error(error));
    dispatch(closeModal());
  }
};

const exportRevit = () => (dispatch, getState) => {
  const productId = reducerUtil.getSlice(detailsData, detailsData.productId, getState());
  if (productId) dispatch(handleExportRevit(productId));
};

const handleExportRevit = (productKey) => async (dispatch) => {
  try {
    await dispatch(
      api.actions.post(`products/${productKey}/manage/exportRevit`, JSON.stringify({}))
    );
    dispatch(closeModal());
    dispatch(loadActions.refreshProduct());
  } catch (error) {
    dispatch(config.actions.error(error));
    dispatch(closeModal());
  }
};

export default {
  publish,
  publishKey,
  deleteProduct,
  deleteKey,
  download,
  downloadKey,
  importRevit,
  exportRevit,
  updateRevit,
  downloadProduct: download,
  publishProduct: publish,
};
