export const apiData = {
  name: "api",
  host: "host",
  gateway: "gateway",
  headers: "headers",
  fileCount: "fileCount",
  disableRedirect: "disableRedirect",
};

export const loginData = {
  name: "login",
  permission: "permission",
  profile: "profile",
  token: "token",
  lastPage: "lastPage",
  loginPage: "loginPage",
  loginRequired: "loginRequired",
};
