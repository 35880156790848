import React, { useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { connect } from "react-redux";
import { reducerUtil } from "base-client";
import moment from "moment";

import { actions as growthActions } from "../../insights";
import { analyzers } from "analyticsDashboard/utils";
import { interval } from "utils/datePickerUtil";
import { reducerData as analyticsData } from "analyticsDashboard";
import { reducerData as insightsData } from "insights";
import AnalyticsBarCard from "./AnalyticsBarCard";
import AnalyticsCard from "./AnalyticsCard";
import AnalyticsDonutCard from "./AnalyticsDonutCard";
import ProductGrowth from "../../insights/components/ProductGrowth";

const Container = styled.div`
  padding: 2%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .donut-card {
    display: inline-flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: calc(100% - 3rem);
    width: 100%;
    .highcharts-legend-box {
      stroke: transparent;
    }
  }
  .analytic-card {
    margin-right: 2%;
    width: 32%;
    &:nth-child(3) {
      margin-right: 0;
    }
  }
`;

const FullWidth = styled.div`
  flex-direction: row;
  flex-basis: 100%;
  width: 100%;

  & > div {
    width: 100%;
  }
`;

// passing props to fix jest testing issue with styled components
const StyledProductGrowth = styled((props) => <ProductGrowth {...props} />)`
  border: 1px solid #c3cdd9;
  border-radius: 0px;
  margin-top: 15px !important;

  .card__title {
    font-size: 1.8rem !important;
    text-align: center !important;
    margin-bottom: 2rem !important;
    width: 100%;
  }

  .controls {
    display: none !important;
  }
`;

const ThirdWidth = styled.div`
  display: flex;
  flex-direction: row;
  flex-basis: 100%;
  justify-content: space-between;
`;

const ActionsTabContent = ({ data, insights, query, updateGrowthChart, visualization }) => {
  const { dateRange } = query;
  const { start, end } = dateRange || {
    start: interval.initial.startDate.toISOString(),
    end: interval.initial.endDate.toISOString(),
  };
  const days = start && end && moment(end).diff(moment(start), "days") + 1;
  const insightDonutData = insights?.data
    ? insights?.data.map((datum, idx) => ({
        count: datum,
        name: insights.xAxis[idx],
      }))
    : null;

  useEffect(() => {
    updateGrowthChart("views", { startDate: moment(start), endDate: moment(end) }, null);
  }, [query]);

  return (
    <Container>
      {visualization === "pie" ? (
        <>
          <AnalyticsCard title="Downloads" data={data.downloads} {...{ days }} className="item" />
          <AnalyticsCard title="Views" data={data.views} {...{ days }} />
          <AnalyticsCard
            title="Engagement"
            subtitle="Users who Downloaded Multiple Products"
            data={data[analyzers.engagement]}
            {...{ days }}
          />
          <AnalyticsDonutCard title="Total Events" data={data[analyzers.actions]} />
          <AnalyticsDonutCard title="Downloads by File Type" data={data[analyzers.fileTypes]} />
          <AnalyticsDonutCard
            title="Views"
            data={insightDonutData}
            style={{ marginRight: "0px" }}
          />
        </>
      ) : (
        <>
          <ThirdWidth>
            <AnalyticsCard title="Downloads" data={data.downloads} {...{ days }} className="item" />
            <AnalyticsCard title="Views" data={data.views} {...{ days }} />
            <AnalyticsCard
              title="Engagement"
              subtitle="Users who Downloaded Multiple Products"
              data={data[analyzers.engagement]}
              {...{ days }}
            />
          </ThirdWidth>
          <FullWidth>
            <AnalyticsBarCard title="Total Events" data={data[analyzers.actions]} />
            <br />
            <AnalyticsBarCard title="Downloads by File Type" data={data[analyzers.fileTypes]} />
          </FullWidth>
          <FullWidth>
            <StyledProductGrowth {...insights} hideHeader />
          </FullWidth>
        </>
      )}
    </Container>
  );
};

ActionsTabContent.propTypes = {
  data: PropTypes.object,
  insight: PropTypes.object,
  query: PropTypes.object,
  updateGrowthChart: PropTypes.func,
  visualization: PropTypes.string,
};

ActionsTabContent.defaultProps = {
  data: {},
  insights: {},
  query: {},
  updateGrowthChart: () => {},
  visualization: "bar",
};

const mapStateToProps = (state) => ({
  data: reducerUtil.getSlice(analyticsData, analyticsData.data, state),
  insights: reducerUtil.getSlice(insightsData, insightsData.productGrowth, state),
  query: reducerUtil.getSlice(analyticsData, analyticsData.query, state),
  visualization: reducerUtil.getSlice(analyticsData, analyticsData.visualization, state),
});

const mapDispatchToProps = (dispatch) => {
  return {
    updateGrowthChart: (stream, interval, key) =>
      dispatch(growthActions.getProductGrowth(stream, interval, key)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActionsTabContent);
