import React from 'react';
import moment from 'moment';

import styled from 'styled-components';

import { PageHeader } from 'shared';
import { formatAddress } from '../utils';
import sbManagerStyles from 'utils/globalStyles';

const StyledUpdatedDate = styled.div`
  font-size: 1.2rem;
  color: ${() => sbManagerStyles.greyMedium};
`;

const ProjectDetailsHeader = ({ ...generalData }) => {
  if (!generalData) return null;

  const { name, address, updated_at, is_active: active } = generalData;

  const formattedAddress = formatAddress(address, 'header');

  const title = active ? name : `${name} (deleted)`;

  return (
    <PageHeader {...{ title }} subtitle={formattedAddress}>
      <StyledUpdatedDate>Updated: {moment(updated_at).format('MMM DD, YYYY')}</StyledUpdatedDate>
    </PageHeader>
  );
};

export default ProjectDetailsHeader;
