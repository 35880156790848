import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';

import { Notification } from './shared/Toast';
import Config from 'configurations';
import Routes from 'routes';

const App = ({ store, history }) => (
  <Provider {...{ store }}>
    <Router {...{ history }}>
      <Config>
        <Notification />
        <Routes />
      </Config>
    </Router>
  </Provider>
);

App.propTypes = {
  store: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default App;
