import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../SvgIcon';

const IncreaseIcon = props => (
  <SvgIcon {...props}>
    <path d='M 11 11L 11 7L 14 7L 7 -7.24792e-08L 0 7L 3 7L 3 11L 11 11Z' />
    <path d='M11 12L3 12L3 16L11 16L11 12z' />
    <path d='M11 17L3 17L3 20L11 20L11 17z' />
  </SvgIcon>
);

IncreaseIcon.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  viewBox: PropTypes.string.isRequired,
};

IncreaseIcon.defaultProps = {
  width: 14,
  height: 21,
  viewBox: '0 0 14 21',
};

export default IncreaseIcon;
