import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reducerUtil } from 'base-client';

import { actions as detailsActions } from 'productDetails';
import detailsUtils from 'productDetails/utils';
import { reducerData as attributesData } from 'productAttributes';
import List from './List';
import Unit from './Unit';
import TextArea from './TextArea';
import DateTime from './DateTime';
import Check from './Check';
import Text from './Text';

class AttributesContainer extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    onSelectAttribute: PropTypes.func.isRequired,
    attributes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        attributeType_id: PropTypes.string,
        revitDistribution: PropTypes.shape({
          distributionAttributeType_id: PropTypes.string
        })
      })
    ).isRequired,
    distributionTypes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string
      })
    ).isRequired,
    attributeTypes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string
      })
    ).isRequired,
    list: PropTypes.arrayOf(PropTypes.string)
  };
  static defaultProps = {
    list: []
  };

  chooseField = id => {
    const {
      attributes,
      attributeTypes,
      distributionTypes,
      onSelectAttribute,
      dispatch,
      ...props
    } = this.props;

    const attribute = attributes.find(item => item.id === id);
    const { attributeType_id: typeId } = attribute;
    const { name: typeName } = attributeTypes.find(item => item.id === typeId);

    const distribution =
      attribute.revitDistribution &&
      distributionTypes.find(
        item => item.id === attribute.revitDistribution.distributionAttributeType_id
      );

    const onClickAttribute = () => onSelectAttribute(id);
    const save = id => dispatch(detailsActions.save(id));
    const formProps = {
      key: id,
      name: id,
      onClickAttribute,
      attribute,
      distribution,
      save,
      ...props
    };

    switch (typeName) {
      case detailsUtils.attributeTypeNames.assetList:
        return <List {...formProps} asset />;
      case detailsUtils.attributeTypeNames.list:
        return <List {...formProps} />;
      case detailsUtils.attributeTypeNames.unit:
        return <Unit {...formProps} />;
      case detailsUtils.attributeTypeNames.textArea:
        return <TextArea {...formProps} />;
      case detailsUtils.attributeTypeNames.dateTime:
        return <DateTime {...formProps} />;
      case detailsUtils.attributeTypeNames.check:
        return <Check {...formProps} />;
      default:
        return <Text {...formProps} />;
    }
  };

  render() {
    const { list } = this.props;

    return <React.Fragment>{list.map(id => this.chooseField(id))}</React.Fragment>;
  }
}

const mapStateToProps = state => ({
  attributeTypes: reducerUtil.getSlice(attributesData, attributesData.attributeTypes, state),
  distributionTypes: reducerUtil.getSlice(attributesData, attributesData.distributionTypes, state),
  attributes: reducerUtil.getSlice(attributesData, attributesData.attributes, state)
});

export default connect(mapStateToProps)(AttributesContainer);
