import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  font-size: 1.2rem;
`;

const Title = styled.div`
  font-weight: bold;
  line-height: 2;
`;

const Address = ({ address }) => {
  if (!address) return null;

  const { address_line_1, address_line_2, city, state, zipcode, country } = address;

  return (
    <Wrapper>
      <Title>Address</Title>
      <div>{address_line_1 || null}</div>
      <div>{address_line_2 || null}</div>
      <div>
        {city || null}
        {state ? ` ${state}` : null}
        {zipcode ? ` ${zipcode}` : null}
      </div>
      <div>{country || null}</div>
    </Wrapper>
  );
};

export default Address;
