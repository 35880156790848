import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../SvgIcon';

const CloseIcon = props => (
  <SvgIcon {...props}>
    <polygon
      points="20.749,4.707 19.334,3.293 12.042,10.586 4.749,3.293 3.334,4.707 10.627,12 3.334,19.293
		4.749,20.707 12.042,13.414 19.334,20.707 20.749,19.293 13.456,12"
    />
  </SvgIcon>
);

CloseIcon.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  viewBox: PropTypes.string.isRequired
};

CloseIcon.defaultProps = {
  width: 20,
  height: 14,
  viewBox: '0 0 24 22'
};

export default CloseIcon;
