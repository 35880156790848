import React from "react";
import PropTypes from "prop-types";
import FineUploaderTraditional from "fine-uploader-wrappers";
import { connect } from "react-redux";
import { login, reducerUtil, api, config } from "base-client";
import { reducerData as tenantData } from "tenant";
import { configMap } from "configurations";
import "../assets/create-product.scss";
import Component from "../components";
import Button from "../../Buttons";
import { ImportIcon, AddIcon } from "../../Miscellaneous/Icons";
import { pages } from "../utils";
import { lambdaApiCall } from "utils/fetch";
import { sendNotifications } from '../../Toast';

class Container extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    toProduct: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
    canView: PropTypes.bool,
    csv: PropTypes.bool,
    bearer: PropTypes.string.isRequired,
    tenantId: PropTypes.string.isRequired,
  };
  static defaultProps = {
    canView: false,
    csv: false,
  };

  lambdaHost = "http://localhost:6001";
  state = { page: pages.select };
  importTypeArr = [];
  uploadArr = [];

  componentDidMount() {
    this.startUpload();
  }

  checkHeaders = async (headerArr) => {
    const { dispatch } = this.props;
    const lambdaEndpoint = await dispatch(
      config.actions.getData(configMap.lambda.name, configMap.lambda.headerCheck.name)
    );
    let res = await dispatch(
      lambdaApiCall({
        endpoint: `${lambdaEndpoint}`,
        method: "POST",
        body: JSON.stringify(headerArr)
      })
    );
    if (!res) {
      return null;
    }
    
    return res;
  };

  getUploadUrl = async (importType) => {
    const { dispatch } = this.props;
    const lambdaEndpoint = await dispatch(
      config.actions.getData(configMap.lambda.name, configMap.lambda.getUploadUrl.name)
    );
    const { bearer, tenantId } = this.props;
    let body = { tenant_id: tenantId, upload_type: importType };
    const headers = new Headers({ Authorization: bearer });
    let res = await dispatch(
      lambdaApiCall({
        endpoint: `${lambdaEndpoint}`,
        method: "POST",
        headers,
        body
      })
    );
    let json = res
    let url = json.message.url;
    let uploadId = json.message.uploadId;
    return { url, uploadId };
  };

  setName = (e) => {
    this.setState({ name: e.target.value });
  };

  uploadFile = async (uploadUrl, content) => {
    const { dispatch } = this.props;
    const myHeaders = new Headers({ "Content-Type": "multipart/form-data" });
    let res = await dispatch(
      lambdaApiCall({
        endpoint: `${uploadUrl}`,
        method: "PUT",
        headers: myHeaders,
        body: content
      })
    );
    return res;
  };

  sendToImporter = async (upload_id, importType) => {
    const { dispatch } = this.props;
    const lambdaEndpoint = await dispatch(
      config.actions.getData(configMap.lambda.name, configMap.lambda.importer.name)
    );
    const { tenantId } = this.props;
    let body = { tenant_id: tenantId, upload_type: importType, upload_id };
    let res = await dispatch(
      lambdaApiCall({
        endpoint: `${lambdaEndpoint}`,
        method: "POST",
        body:JSON.stringify(body) ,
        async: true,
      })
    );
    return res;
  };

  startUpload = (modalProps) => {
    const { csvMode } = modalProps || {};
    const { dispatch } = this.props;
    const headers = dispatch(api.actions.getHeaders());
    const host = dispatch(api.actions.getHost());

    const uploader = new FineUploaderTraditional({
      options: {
        chunking: {
          enabled: false,
        },
        customHeaders: { "Content-Type": "application/json", Authorization: this.props.bearer },
        deleteFile: {
          enabled: false,
        },
        request: {
          customHeaders: csvMode ? { "Content-Type": "text/csv" } : headers,
          method: csvMode ? "PUT" : "POST",
          inputName: "file",
          omitDefaultParams: csvMode ? true : false,
        },
        retry: {
          enabled: csvMode ? false : true,
          enableAuto: csvMode ? false : true,
        },
        session: {
          refreshOnReset: false,
        },
        validation: {
          itemLimit: csvMode ? 1 : 0,
        },
      },
    });

    uploader.on("submit", (id) => {
      return new Promise((resolve, reject) => {
        const setEndpoint = async (key) => {
          if (!csvMode) {
            uploader.methods.setEndpoint(`${host}/import/products/${key}`);
          } else {
            let ext = uploader.methods._handler.getFile(id).name.split(".")[1];
            if (ext !== "csv") {
              reject();
              throw "File not a csv";
            }
            let blob = await uploader.methods._handler.getFile(id).text();
            let headers = blob.split("\r")[0].split(",");
            let res = await this.checkHeaders(headers);
            if (!res) {
              reject();
              throw "Error checking headers";
            }
            this.importTypeArr[id] = res.message.importType;
            let result = await this.getUploadUrl(this.importTypeArr[id]);
            this.uploadArr[id] = result;
            uploader.options.request.customHeaders = {};
            uploader.methods.setEndpoint(this.uploadArr[id].url);
          }
          this.setState({ done: false });
          resolve(false);
        };

        const checkKey = async () => {
          const { importKey } = this.state;
          if (importKey || csvMode) {
            setEndpoint(importKey);
          } else if (this.fetchKey) {
            setTimeout(checkKey, 20);
          } else {
            this.fetchKey = true;
            const newKey = await dispatch(
              api.actions.post("import/products/create", JSON.stringify({}))
            );
            this.fetchKey = false;
            this.setState({ importKey: newKey });
            setEndpoint(newKey);
          }
        };
        checkKey();
      });
    });

    if (csvMode) {
      uploader.on("complete", async (id, name, response, k) => {
        let { success, error, response: responseObject } = response;
        let res = await this.sendToImporter(this.uploadArr[id].uploadId, this.importTypeArr[id]);
        this.setState({ done: true });
        if (success && res) {
          await dispatch(
            sendNotifications([
              {
                type: 'success',
                message: `File sent to be processed! 
                You will get and email when the import is complete.`
              }
            ])
          );
          this.setState({ done: true });
          this.closeModal();
        }
      });
    } else {
      uploader.on("allComplete", () => {
        return this.setState({ done: true });
      });
    }
    this.setState({ uploader });
  };
  finishUpload = async () => {
    const { importKey, done, finishing } = this.state;
    if (!importKey || !done || finishing) return;
    const { toProduct, dispatch } = this.props;
    const body = { templatekey: "00000000-0000-0000-0000-000000000000", importkey: importKey };
    this.setState({ finishing: true });
    const result = await dispatch(api.actions.post(`import/products/${importKey}/complete`, body));
    if (result) {
      const products = [];
      result.forEach((asset) => {
        if (asset.product_id) {
          products.push({
            name: asset.name || "Unknown",
            onClick: () => {
              this.closeModal();
              toProduct(asset.product_id);
            },
          });
        }
      });
      if (products.length === 1) {
        this.setState({ products, importKey: null });
        products[0].onClick();
      } else this.setState({ products, importKey: null, page: pages.list });
    }
    const { uploader } = this.state;
    uploader.methods.reset();
    this.setState({ finishing: false });
  };
  createProduct = async () => {
    const { name } = this.state;
    const { dispatch } = this.props;
    const body = { productName: name };
    const result = await dispatch(api.actions.post("products", body));
    if (result && result.id) {
      const { toProduct } = this.props;
      toProduct(result.id);
    }
    this.closeModal();
  };
  openModal = (modalProps) => {
    const { csvMode } = modalProps || {};
    const { openModal } = this.props;
    openModal();
    this.startUpload(modalProps);
    this.setState({
      products: null,
      page: csvMode ? pages.upload : pages.select,
      name: "",
      done: false,
      csvSuccess: false,
      csvMode,
    });
  };
  closeModal = () => {
    const { openModal } = this.props;
    openModal(false);
  };
  toPage = (page) => {
    this.setState({ page });
    const { csvMode, uploader } = this.state;
    if (csvMode && page === pages.upload) uploader.methods.reset();
  };

  render() {
    const { canView, csv } = this.props;
    if (!canView) {
      return null;
    }
    const { page, importKey, done, finishing, csvMode, csvSuccess, headerList } = this.state;
    const startPage = csvMode ? pages.upload : pages.select;
    const modalProps = {
      closeModal: this.closeModal,
      setName: this.setName,
      createProduct: this.createProduct,
      finishUpload: this.finishUpload,
      toManual: () => this.toPage(pages.manual),
      toUpload: () => this.toPage(pages.upload),
      toSelect: page !== startPage ? () => this.toPage(startPage) : null,
      disableProcess: !importKey || !done || finishing,
      csvMode,
      csvSuccess,
      headerList,
    };
    return (
      <div>
        {csv && (
          <Button
            buttonStyle="grey"
            icon={<ImportIcon />}
            onClick={() => this.openModal({ csvMode: true })}
          >
            Import CSV
          </Button>
        )}
        <Button buttonStyle="create" icon={<AddIcon />} onClick={() => this.openModal()}>
          Create Product
        </Button>
        <Component {...modalProps} {...this.state} {...{ csv }} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tenantId: reducerUtil.getSlice(tenantData, tenantData.tenant, state),
    bearer: `Bearer ${reducerUtil.getSlice(login.reducerData, login.reducerData.token, state)}`,
  };
};

export default connect(mapStateToProps)(Container);
