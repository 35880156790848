import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../SvgIcon';

const LineIcon = (props) => (
  <SvgIcon {...props}>
		<path d="M2,11a1,1,0,0,1,1-1H21a1,1,0,0,1,1,1v2a1,1,0,0,1-1,1H3a1,1,0,0,1-1-1V11Z"/>
  </SvgIcon>
)

LineIcon.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  viewBox: PropTypes.string.isRequired,
};

LineIcon.defaultProps = {
  width: 24,
  height: 24,
  viewBox: '0 0 24 24',
};

export default LineIcon;
