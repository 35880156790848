import React from 'react';
import PropTypes from 'prop-types';
import { AutoSizer, Column, Table } from 'react-virtualized';

import Card from 'shared/PageLayout/Card';
import optionsCellRenderer from './cellTypes/optionsCellRenderer';

const Content = ({
  noRowsRenderer,
  overscanRowCount,
  rowCount,
  rowGetter,
  rowHeight,
  rowRenderer
}) => (
  <Card>
    <div id="UserAccountsAutoSizerContainer" className="table--AutoSizer table--half-page">
      <AutoSizer>
        {({ height, width }) => (
          <Table
            headerHeight={55}
            headerClassName="table-header"
            height={height}
            noRowsRenderer={noRowsRenderer}
            overscanRowCount={overscanRowCount}
            rowCount={rowCount}
            rowGetter={rowGetter}
            rowHeight={rowHeight}
            rowRenderer={rowRenderer}
            rowClassName="table-row"
            gridClassName="table__visible-items"
            width={width}
          >
            <Column dataKey="name" label="User" flexGrow={1} width={450} />
            <Column dataKey="role" label="Role" flexGrow={1} width={200} />
            <Column dataKey="status" label="Status" flexGrow={1} width={200} />
            <Column
              cellRenderer={optionsCellRenderer}
              className="text-center"
              headerClassName="text-center"
              dataKey="options"
              disableSort
              label="Options"
              flexGrow={1}
              width={100}
            />
          </Table>
        )}
      </AutoSizer>
    </div>
  </Card>
);

Content.propTypes = {
  headerHeight: PropTypes.number.isRequired,
  noRowsRenderer: PropTypes.func.isRequired,
  overscanRowCount: PropTypes.number.isRequired,
  rowCount: PropTypes.number.isRequired,
  rowGetter: PropTypes.func.isRequired,
  rowHeight: PropTypes.number.isRequired,
  rowRenderer: PropTypes.func.isRequired
};

export default Content;
