import React from 'react';
import styled from 'styled-components';
import { Row } from 'shared-features-client';
import moment from 'moment';

import { DeleteModal } from 'shared/Modals';
import sbManagerStyles from 'utils/globalStyles';
import { AssetDropdown, TextField, ExpirationDate } from './FormFields';

const Card = styled.div`
  border: 1px solid ${() => sbManagerStyles.greyLight};
  margin: 0 2rem 2rem 0;
  border-radius: 4px;
  padding: 1.7rem;
  position: relative;
`;

const Header = styled.div`
  margin-left: -1rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const Title = styled.div`
  width: 50%;
  display: flex;
  display: flex-start;
  line-height: 3.5rem;

  img {
    height: 3.5rem;
    margin-right: 1.5rem;
  }
`;

const DeleteAttribute = styled(DeleteModal)`
  fill: ${() => sbManagerStyles.greyLight};
  cursor: pointer;
`;

const StyledRow = styled(Row)`
  :not(:last-child) {
    margin-bottom: 2rem;
  }
  width: 45rem;
`;

const UrlField = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  :placeholder {
    opacity: 1
    color: black
  }
`;

const Label = styled.div`
  font-size: 1.2rem;
  font-weight: 600;
`;

const CertificateCard = ({ labelText, logo, assets, onDelete, handleOnChange, input }) => {
  const { name, value, onChange } = input;
  const { assetId, url, date } = value || {};

  const filteredAssets = assets.filter(asset => {
    const { mimetype } = asset;
    if (mimetype) {
      return mimetype.includes('image') || mimetype.includes('pdf') || mimetype.includes('text');
    } else {
      return asset;
    }
  });

  const onChangeAsset = e => {
    onChange({ assetId: e.target.value, url, date });
    handleOnChange(name);
  };

  const onChangeUrl = e => {
    onChange({ assetId, url: e.target.value, date });
  };

  const onChangeDate = e => {
    onChange({ assetId, url, date: moment(e.target.value).toISOString() });
  };

  return (
    <Card>
      <Header>
        <Title>
          <div>
            <img src={logo} alt="logo" />
          </div>
          <div>{labelText}</div>
        </Title>
        <DeleteAttribute height={20} width={20} deleteItem={() => onDelete()} />
      </Header>
      <StyledRow>
        <AssetDropdown
          input={{ value: assetId || '', onChange: onChangeAsset, name }}
          {...{ assets: filteredAssets }}
        />
      </StyledRow>
      <StyledRow>
        <UrlField>
          <Label>URL</Label>
          <TextField
            input={{ value: url || '', onChange: onChangeUrl, name }}
            {...{ handleOnChange }}
          />
        </UrlField>
      </StyledRow>
      <StyledRow>
        <ExpirationDate
          input={{
            value: date ? moment(date).format('YYYY-MM-DD') : '',
            onChange: onChangeDate,
            name
          }}
          {...{ handleOnChange }}
        />
      </StyledRow>
    </Card>
  );
};

export default CertificateCard;
