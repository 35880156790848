import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { DeleteIcon, CloseIcon } from 'shared/Miscellaneous/Icons';
import AssetModalChildren from './AssetModalChildren';
import ExternalAssetButton from './ExternalAssetButton';
import Modal from '../../../shared/Modals/Modal';
import sbManagerStyles from 'utils/globalStyles';
import UploadButton from './UploadButton';


const Container = styled.div`
  color: ${() => sbManagerStyles.greyMedium};
  display: flex;
  width: 100%;
  align-items: center;
  align-content: space-around;
  padding: 1rem 2rem;
  border-bottom: 1px solid ${() => sbManagerStyles.greyLightest};
  height: 55px;
  &.selectedHeader {
    background-color: ${() => sbManagerStyles.greyDarkest01};
    color: ${() => sbManagerStyles.white};
    p {
      .icon {
        margin-right: 2rem;
      }
    }
  }
`;

const Text = styled.p`
  flex-grow: 1;
  margin: 0;
  font-size: 1.4rem;
`;

class Header extends Component {
  static propTypes = {
    selectedCount: PropTypes.number,
    actionHandler: PropTypes.func.isRequired
  };

  static defaultProps = {
    selectedCount: 0
  };

  state = {
    isDeleting: this.props.selectedCount > 1,
    showModal: false
  };

  render() {
    const { selectedCount, actionHandler } = this.props;
    const { isDeleting, showModal } = this.state;

    const setShowModal = (val) => {
      this.setState({ showModal: val })
    }

    return !isDeleting ? (
      <Container>
        <Text>Drag and drop files to upload</Text>
        <ExternalAssetButton setShowModal={setShowModal} />
        <UploadButton onUpload={actionHandler} />
        <Modal onClose={() => setShowModal(false)} open={showModal} title="Add External Assets">
          <AssetModalChildren setShowModal={setShowModal} />
        </Modal>
      </Container>
    ) : (
      <Container className="selectedHeader">
        <Text>
          <CloseIcon width={15} height={11} onClick={() => this.setState({ isDeleting: false })} />
          {`${selectedCount} Selected`}
        </Text>
        <DeleteIcon onClick={actionHandler} width={20} height={20} fill={sbManagerStyles.white} />
      </Container>
    );
  }
}

export default Header;
