import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactHighmaps from 'react-highcharts/ReactHighmaps.src';

import worldMap from '../utils/world';
import toTitleCase from '../../utils/miscUtils';

class Container extends Component {
  constructor(props) {
    super(props);
    const { displayMap } = this.props;
    this.state = { config: config(displayMap) };
  }

  componentDidUpdate() {
    if (!this.state.chart) return;
    const { map, stream } = this.props;
    const chart = this.state.chart.getChart();
    const combinedData = (map || []).map(point => ({
      ...point,
      display: `Total ${toTitleCase(stream)}: ${point.value}`
    }));
    chart.series[0].setData(combinedData);
    if (map) {
      chart.hideLoading();
      chart.reflow();
    } else chart.showLoading();
  }
  shouldComponentUpdate(nextProps) {
    return nextProps.displayMap !== this.props.displayMap || nextProps.map !== this.props.map;
  }
  render() {
    return (
      <div className="insights-map">
        <ReactHighmaps
          isPureConfig
          config={this.state.config}
          domProps={{ className: 'sb-map' }}
          ref={chart => this.setState({ chart })}
        />
      </div>
    );
  }
}

Container.propTypes = {
  displayMap: PropTypes.object,
  map: PropTypes.array,
  stream: PropTypes.string.isRequired
};

Container.defaultProps = {
  displayMap: worldMap,
  map: null
};

export default Container;

const config = map => ({
  chart: {
    animation: false
  },
  credits: {
    enabled: false
  },
  mapNavigation: {
    enabled: true,
    buttonOptions: {
      align: 'left',
      verticalAlign: 'bottom',
      alignTo: 'spacingBox'
    }
  },
  colorAxis: {
    stops: [
      [0, '#A7E48A'],
      [0.25, '#6ABF43'],
      [1, '#33840E']
    ]
  },
  legend: {
    enabled: false
  },
  series: [
    {
      mapData: map,
      data: [],
      joinBy: ['iso-a2', 'code'],
      borderColor: '#BBBBBB',
      borderWidth: 0.2,
      states: {
        hover: {
          borderWidth: 1
        }
      },
      tooltip: {
        enabled: true,
        headerFormat: '',
        pointFormat:
          '<span style="font-size: 12px">{point.name}</span><br/>' +
          '<span style="font-size: 12px">{point.display}</span>'
      },
      turboThreshold: 0
    }
  ]
});
