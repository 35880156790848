import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { config } from 'base-client';

import { configMap } from 'configurations';

const Privacy = ({ dispatch }) => {
  const privacy = dispatch(
    config.actions.getData(configMap.agreement.name, configMap.agreement.privacy.name)
  );

  return (
    <div>
      By using our services, you agree to our{' '}
      <a href={privacy} target="blank">
        Privacy Policy
      </a>
      .
    </div>
  );
};

Privacy.propTypes = {
  dispatch: PropTypes.func.isRequired
};

export default connect()(Privacy);
