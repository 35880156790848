import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import sbManagerStyles from 'utils/globalStyles';

const Container = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h2`
  font-size: 2rem;
`;
  
const Subtitle = styled.p`
  font-size: 1.4rem;
  margin: 0;
  color: ${() => sbManagerStyles.greyMedium};
`;

const SectionHeader = ({ title, subtitle, children }) => (
  <Container>
    <Header>
      <Title>{title}</Title>
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
    </Header>
    {children}
  </Container>
);

SectionHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  children: PropTypes.node
}

export default SectionHeader;
