import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import makeAnimated from 'react-select/animated';

import globalStyles from 'utils/globalStyles';

import { CheckboxFilter, RadioFilter } from 'shared/Filters';

const Container = styled.div`
  flex: 0 0 15%;
  font-size: 1.4rem;
  margin-top: 20px;
`;

const Header = styled.h3`
  font-size: 1.6rem;
  margin: 0 0 1.5rem;
`;

const OptionContainer = styled.div``;
const OptionTitle = styled.div``;

const Filter = ({ updateFilter, facets, selectedFacets, attribute }) => {
  const FilterStyles = {
    option: (provided, state) => ({
      ...provided,
      color: globalStyles.greyDarkest
    })
  };
  const onChange = values => {
    updateFilter({
      attribute,
      facets: values ? values.map(({ value }) => value) : []
    });
  };

  const formatOptionLabel = ({ label, count }) => {
    return (
      <OptionContainer>
        <OptionTitle>{`${label}${count ? ` (${count})` : ''}`}</OptionTitle>
      </OptionContainer>
    );
  };

  const animatedComponents = makeAnimated();
  return (
    <Select
      closeMenuOnSelect={false}
      formatOptionLabel={formatOptionLabel}
      components={animatedComponents}
      value={selectedFacets ? selectedFacets.map(facet => ({ value: facet, label: facet })) : []}
      isMulti
      styles={FilterStyles}
      onChange={values => onChange(values)}
      options={facets.map(({ key, count }) => ({ value: key, label: key, count }))}
    />
  );
};

const FilterContainer = styled.div`
  margin-bottom: 2rem;
`;

const FilterTitle = styled.div`
  margin-bottom: 0.2rem;
  font-size: 1.2rem;
`;

const Filters = ({ filters, selected, updateFilter, ...props }) => {
  const renderFilter = filters && filters.length > 0;

  const getCheckStatus = ({ selectedFacets, key, name }) => {
    return selectedFacets && selectedFacets.some(facet => facet === key || facet === name);
  };
  return (
    <Container>
      <Header>Refine By</Header>
      {renderFilter &&
        filters.map(({ attribute, name, single_select_facet, facets }) => {
          if (attribute === 'leadStatus') return null;
          const { facets: selectedFacets } =
            selected.find(({ attribute: selectedAttribute }) => selectedAttribute === attribute) ||
            {};
          if (single_select_facet) {
            const filterProps = {
              checkedAll: false,
              name,
              attribute,
              isSingleSelect: true,
              disabledSelectAll: true,
              showMore: {
                disabled: false,
                numberShow: 4
              },
              updateFilter,
              facets: facets.map(({ attribute: name, key, count }) => ({
                key,
                name,
                count,
                checked: getCheckStatus({
                  selectedFacets,
                  key,
                  name
                })
              }))
            };

            return <CheckboxFilter key={attribute} {...filterProps} {...props} />;
          }
          return (
            <FilterContainer>
              <FilterTitle>{name}</FilterTitle>
              <Filter
                {...{ updateFilter, facets, selectedFacets: selectedFacets || [], attribute }}
              />
            </FilterContainer>
          );
          // const filterProps = {
          //   name,
          //   attribute,
          //   showMore: {
          //     disabled: false
          //   },
          //   facets: facets.map(({ attribute: name, key, count }) => ({
          //     key,
          //     name,
          //     count,
          //     checked: !!(selectedFacets && selectedFacets.find(facet => facet === key))
          //   }))
          // };
          // return (
          //   <CheckboxFilter key={attribute} {...filterProps} {...props} disabledSelectAll={true} />
          // );
        })}
    </Container>
  );
};

Filters.propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      attribute: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      facets: PropTypes.arrayOf(
        PropTypes.shape({
          attribute: PropTypes.string.isRequired,
          count: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        })
      )
    })
  ),
  selected: PropTypes.arrayOf(
    PropTypes.shape({
      attribute: PropTypes.string.isRequired,
      facets: PropTypes.arrayOf(PropTypes.string).isRequired
    })
  )
};

Filters.defaultProps = {
  filters: [],
  selected: []
};

export default Filters;
