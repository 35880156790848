import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from './../SvgIcon';

const AddIcon = (props) => (
  <SvgIcon {...props}>
		<path d="M 12 5L 7 5L 7 0L 5 0L 5 5L 0 5L 0 7L 5 7L 5 12L 7 12L 7 7L 12 7L 12 5Z"/>
  </SvgIcon>
)

AddIcon.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  viewBox: PropTypes.string.isRequired,
};

AddIcon.defaultProps = {
  width: 14,
  height: 14,
  viewBox: '0 0 12 12',
};

export default AddIcon;

