import React from 'react';
import { config, reducerUtil } from 'base-client';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';

import marker from './assets/marker.svg';
import { configMap } from 'configurations';
import { reducerData as projectData } from 'projectDetails';

const nullUndef = test => test === null || test === undefined;

const ProjectMap = ({ generalData, dispatch }) => {
  const mapKey = dispatch(
    config.actions.getData(configMap.google.name, configMap.google.mapKey.name)
  );

  const { address } = generalData || {};
  const { latitude: lat, longitude: lng } = address || {};

  if (nullUndef(lat) || nullUndef(lng)) return null;

  return (
    // Always set the container height explicitly - this is a maps limitation
    <div style={{ height: '19rem', width: '100%', background: '#f3f3f3' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: mapKey }}
        defaultCenter={{ lat, lng }}
        defaultZoom={10}
      >
        <div {...{ lat, lng }}>
          <img src={marker} alt="map marker" />
        </div>
      </GoogleMapReact>
    </div>
  );
};

ProjectMap.propTypes = {
  dispatch: PropTypes.func.isRequired,
  generalData: PropTypes.object
};

const mapStateToProps = state => ({
  generalData: reducerUtil.getSlice(projectData, projectData.generalData, state)
});

export default connect(mapStateToProps)(ProjectMap);
