import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import sbManagerStyles from 'utils/globalStyles';

const Wrapper = styled.div`
  padding: 1.2rem 1.5rem;
  border-top: 1px solid ${() => sbManagerStyles.accentGrey};
  background-color: ${() => sbManagerStyles.greyLightest};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.h4`
  font-size: 1.4rem;
  font-weight: 500;
  color: ${() => sbManagerStyles.greyDark};
`;

const ProjectSectionHeader = ({ title, children }) => (
  <Wrapper>
    <Title>{title}</Title>
    {children}
  </Wrapper>
);

PropTypes.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node
};

export default ProjectSectionHeader;
