/* vendor imports */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

/* system imports */
import { SectionHeader } from 'shared';
import Card from '../../Card';
import Content from '../components';
import RadioGroup from 'shared/InputGroups/RadioGroup'

const defaultConfig = {
  chart: {
    type: 'column'
  },
  credits: {
    enabled: false
  },
  title: {
    text: null
  },
  xAxis: {
    categories: []
  },
  yAxis: {
    minRange: 1,
    min: 0,
    allowDecimals: false,
    labels: {
      enables: false
    },
    title: {
      text: null
    }
  },
  series: [
    {
      showInLegend: false,
      data: [],
      maxPointWidth: 30
    }
  ]
};
const viewOptions = { "bar": "column", "line": "line" }; // keys for display only - highchart uses column/line

const Container = ({ title, subtitle, hideHeader, chart, config, ...cardProps }) => {
  const [visualization, setVisualization] = useState('bar')
  const [configState, setConfigState] = useState(config || defaultConfig) // needed for changing chart type

  useEffect(() => {
    setConfigState({...configState, chart: { type: viewOptions[visualization] }, xAxis: { categories: chart.xAxis }})
  }, [chart, visualization])

  return (
    <div>
      {!hideHeader && title && <SectionHeader {...{ title, subtitle }} />}
      <Card {...cardProps} additionalRadioGroup={
        <RadioGroup 
          controlFunc={(e) => { setVisualization(e.target.value) }} 
          options={Object.keys(viewOptions)} 
          selectedOption={visualization} 
          style={{ marginRight: "8px" }} 
        />
      }>
        <Content {...chart} {...{ config: configState }} />
      </Card>
    </div>
  )
};

Container.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  hideHeader: PropTypes.bool,
  chart: PropTypes.object,
  config: PropTypes.object,
};

Container.defaultProps = {
  title: null,
  subtitle: null,
  hideHeader: false,
  chart: null,
  config: null,
};

export default Container;
