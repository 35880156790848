import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../SvgIcon';

const DownArrowIcon = props => (
  <SvgIcon {...props}>
    <path d='M7.35,14.92V0H4.77V14.92l-3-3L0,13.78l6.06,6.06,6.06-6.06L10.3,12Z' />
  </SvgIcon>
);

DownArrowIcon.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  viewBox: PropTypes.string.isRequired,
};

DownArrowIcon.defaultProps = {
  width: 12.1,
  height: 19.8,
  viewBox: '0 0 12.13 19.85',
};

export default DownArrowIcon;
