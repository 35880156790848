import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from './../SvgIcon';

const PublishIcon = (props) => (
  <SvgIcon {...props}>	
		<path d="M 13 0C 10.49 0 8.26 1.31 7.021 3.357C 7.987 3.814 9.017 4.62 9.992 6.003L 9.175 6.579C 7.678 4.454 6.393 4 5 4C 2.243 4 0 6.243 0 9C 0 11.757 2.243 14 5 14L 13 14C 16.859 14 20 10.86 20 7C 20 3.141 16.859 0 13 0Z" />
  </SvgIcon>
)

PublishIcon.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  viewBox: PropTypes.string.isRequired,
};

PublishIcon.defaultProps = {
  width: 20,
  height: 12,
  viewBox: '0 0 20 14',
};

export default PublishIcon;