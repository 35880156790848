import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from './../SvgIcon';

const CheckboxFormulaOnIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M22,11A11,11,0,1,1,11,0,11,11,0,0,1,22,11Z" fill='#1385f0'/>
    <path d="M16.12,12a1.61,1.61,0,0,1-.88.15c0-.43-.09-.64-.32-.64s-.25.09-.39.28a4.08,4.08,0,0,0-.4.76,11.77,11.77,0,0,0,.56,1.51.92.92,0,0,0,.24.36.53.53,0,0,0,.3.09.7.7,0,0,0,.45-.22,1.07,1.07,0,0,1,.3.61,2.17,2.17,0,0,1-.74.55,1.8,1.8,0,0,1-.82.22,1.21,1.21,0,0,1-1.18-.61A5.64,5.64,0,0,1,12.81,14a4.41,4.41,0,0,1-.94,1.26,1.58,1.58,0,0,1-1.08.46,1.06,1.06,0,0,1-.33,0,.66.66,0,0,1-.27-.12,6.19,6.19,0,0,0,.21-.87,6.41,6.41,0,0,0,.07-.9,1.77,1.77,0,0,1,.92-.16c0,.38.1.57.29.57a.55.55,0,0,0,.36-.22,2.37,2.37,0,0,0,.39-.62c0-.16-.08-.33-.14-.53s-.09-.36-.12-.46L12,11.86a1,1,0,0,0-.35-.57,1,1,0,0,0-.67-.12,1.61,1.61,0,0,1,.1-.71,5.58,5.58,0,0,0,1-.18,4.24,4.24,0,0,0,.86-.31.54.54,0,0,1,.34.18,1,1,0,0,1,.2.45q.21.9.27,1.29a4.61,4.61,0,0,1,1-1.39A1.59,1.59,0,0,1,15.86,10a1.21,1.21,0,0,1,.66.18,4.84,4.84,0,0,0-.28.91A5.25,5.25,0,0,0,16.12,12Z" fill='#ffffff'/>
    <path d="M11.57,5a4.11,4.11,0,0,1,.82.07,1.91,1.91,0,0,1,.57.18,5.34,5.34,0,0,0-.31,1.63,1.35,1.35,0,0,1-.4.1,4.57,4.57,0,0,1-.5,0,3.4,3.4,0,0,0-.14-.94.41.41,0,0,0-.4-.34.56.56,0,0,0-.44.21,2,2,0,0,0-.32.72c-.1.34-.21.85-.34,1.52h1.14a2.28,2.28,0,0,1-.09.76A5.47,5.47,0,0,0,10,9.08c-.08.44-.2,1.15-.35,2.14l-.1.61a10.44,10.44,0,0,1-.67,2.39,4.81,4.81,0,0,1-1.13,1.7,2.12,2.12,0,0,1-1.49.62A1.68,1.68,0,0,1,5,16a2,2,0,0,1,0-.53A2.18,2.18,0,0,1,5.19,15a3.29,3.29,0,0,0,1.31.24.86.86,0,0,0,.57-.16,1.3,1.3,0,0,0,.32-.62A10.11,10.11,0,0,0,7.64,13l.07-.6q.25-2.14.45-3.36a3.82,3.82,0,0,0-.93-.14,1.49,1.49,0,0,1,.16-.73h.9V8.12a4.39,4.39,0,0,1,1-2.35A3,3,0,0,1,11.57,5Z" fill='#ffffff'/>
  </SvgIcon>
)

CheckboxFormulaOnIcon.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  viewBox: PropTypes.string.isRequired,
};

CheckboxFormulaOnIcon.defaultProps = {
  width: 22,
  height: 22,
  viewBox: '0 0 22 22',
};

export default CheckboxFormulaOnIcon;