import { api, config, reducerUtil } from 'base-client';

import detailsData from 'productDetails/reducerData';
import loadActions from './load';
import detailsUtils from 'productDetails/utils';
import { reducerData as attributesData } from 'productAttributes';

const createOption = ({ name = '' }) => async (dispatch, getState) => {
  const state = getState();
  const productId = reducerUtil.getSlice(detailsData, detailsData.productId, state);
  const options = reducerUtil.getSlice(detailsData, detailsData.options, state);
  const values = reducerUtil.getSlice(detailsData, detailsData.values, state);

  const optionValues = {
    [detailsUtils.sustainabilityGroups[0]]: {},
    [detailsUtils.sustainabilityGroups[1]]: {},
    [detailsUtils.sustainabilityGroups[2]]: {}
  };

  // copy other option if found
  const found = options.find(item => item.name === name);
  if (found) {
    Object.keys(optionValues).forEach(group => {
      optionValues[group] = values[detailsUtils.form.sustainability][found.id][group];
    });
    // append "Copy of" if "name" is already found
    name = `Copy of ${name}`;
    // append number to end if "copy of name" is already found
    if (options.find(item => item.name === name)) {
      let number = 1;
      while (options.find(item => item.name === `${name} ${number}`)) number++;
      name = `${name} ${number}`;
    }
  }

  // save option
  try {
    const { id: optionId } = await dispatch(
      api.actions.post(
        `products/${productId}/productVersions/${productId}/productOptions`,
        JSON.stringify({ name })
      )
    );

    const certs = optionValues[[detailsUtils.sustainabilityGroups[0]]] || {};
    const chars = optionValues[[detailsUtils.sustainabilityGroups[1]]] || {};
    const ratings = optionValues[[detailsUtils.sustainabilityGroups[2]]] || {};

    await Promise.all([
      dispatch(
        api.actions.post(
          `products/${productId}/productVersions/${productId}/productOptions/` +
            `${optionId}/documents`,
          JSON.stringify(
            Object.keys(certs).map(id => {
              const { assetId: asset_id, date: expiration_date, url } = certs[id];
              return { document_id: id, asset_id, expiration_date, url };
            })
          )
        )
      ),
      dispatch(
        api.actions.post(
          `products/${productId}/productVersions/${productId}/productOptions/` +
            `${optionId}/environmentCharacteristics`,
          JSON.stringify(
            Object.keys(chars).map(id => ({ environment_characteristic_id: id, value: chars[id] }))
          )
        )
      ),
      dispatch(
        api.actions.post(
          `products/${productId}/productVersions/` +
            `${productId}/productOptions/${optionId}/ratingSystems`,
          JSON.stringify(Object.keys(ratings).map(id => ({ rating_system_id: id })))
        )
      )
    ]);

    await dispatch(loadActions.refreshProduct());
  } catch (error) {
    dispatch(config.actions.error(error));
  }
};

const saveOption = ({ optionId, name }) => async (dispatch, getState) => {
  if (!optionId) return dispatch(createOption({ name }));

  const state = getState();
  const productId = reducerUtil.getSlice(detailsData, detailsData.productId, state);

  try {
    await dispatch(
      api.actions.patch(
        `products/${productId}/productVersions/${productId}/productOptions/${optionId}`,
        JSON.stringify({ name })
      )
    );

    // either way, reload the product with the new info
    await dispatch(loadActions.refreshProduct());
  } catch (error) {
    dispatch(config.actions.error(error));
  }
};

const deleteOption = ({ optionId }) => async (dispatch, getState) => {
  const state = getState();
  const productId = reducerUtil.getSlice(detailsData, detailsData.productId, state);

  try {
    await dispatch(
      api.actions.delete(
        `products/${productId}/productVersions/${productId}/productOptions/${optionId}`
      )
    );
    await dispatch(loadActions.refreshProduct());
  } catch (error) {
    dispatch(config.actions.error(error));
  }
};

const sortList = (sortBy, sortDir, list) => () => {
  let sortedList;

  if (!list) return;

  if (sortDir === 'a-z') {
    sortedList = list.sort((a, b) => (a[sortBy].toLowerCase() > b[sortBy].toLowerCase() ? 1 : -1));
  } else {
    sortedList = list.sort((a, b) => (a[sortBy].toLowerCase() < b[sortBy].toLowerCase() ? 1 : -1));
  }
  return sortedList;
};

const filterList = (string, group) => (dispatch, getState) => {
  let list;
  if (group === detailsUtils.sustainabilityGroups[1]) {
    list = reducerUtil.getSlice(attributesData, attributesData.characteristics, getState()) || [];
  }
  if (group === detailsUtils.sustainabilityGroups[0]) {
    list = reducerUtil.getSlice(attributesData, attributesData.certificates, getState()) || [];
  }

  if (string === '') return list;
  return list.filter(item => item.name.toLowerCase().includes(string.toLowerCase()));
};

const getOptions = (productId, versionId) => async dispatch => {
  if (!productId || !versionId) return [];
  try {
    const results = await dispatch(
      api.actions.get(`products/${productId}/productVersions/${versionId}/productOptions?limit=250`)
    );

    const { productOptions } = results;

    return productOptions || [];
  } catch (error) {
    dispatch(config.actions.error(error));
  }
};

const setOption = option => (dispatch, getState) => {
  const state = getState();
  const { product_version_id: productVersionId } = reducerUtil.getSlice(
    detailsData,
    detailsData.options,
    state
  );

  const productId = reducerUtil.getSlice(detailsData, detailsData.productId, state);
  dispatch(reducerUtil.setSlice(detailsData, detailsData.selectedOption, option));
  return dispatch(getOptionValues(productId, productVersionId));
};

/**
 *
 * @param {*} productId
 * @param {*} productVersionId
 */
const getOptionValues = (productId, versionId, optionId) => async dispatch => {
  const [certs, ratings, chars] = await Promise.all([
    dispatch(getCertificateData(productId, versionId, optionId)),
    dispatch(getRatingSystemData(productId, versionId, optionId)),
    dispatch(getCharacteristicData(productId, versionId, optionId))
  ]);

  const values = {};
  values[detailsUtils.sustainabilityGroups[0]] = certs;
  values[detailsUtils.sustainabilityGroups[1]] = chars;
  values[detailsUtils.sustainabilityGroups[2]] = ratings;
  return values;
};

/** This function is using to get certificate data of a specific product option
 * @param {string} productOptionId The selected product option id.
 * @return {array} list of certificate.
 **/
const getCertificateData = (productId, versionId, optionId) => async dispatch => {
  if (!optionId) return;

  try {
    const certificateApi =
      `products/${productId}/productVersions/${versionId}` +
      `/productOptions/${optionId}/documents?limit=250`;
    const { productOptionDocuments: documents } = await dispatch(api.actions.get(certificateApi));
    const formattedDocuments =
      documents &&
      documents.map(item => {
        const { document_id: id, asset_id: assetId, expiration_date: date, url } = item;
        return { id, url, date, assetId };
      });
    return formattedDocuments;
  } catch (error) {
    dispatch(config.actions.error(error));
  }
};

/** This function is using to get rating system data of a specific product option
 * @param {string} productOptionId The selected product option id.
 * @return {array} list of rating systems.
 **/
const getRatingSystemData = (productId, versionId, optionId) => async dispatch => {
  if (!optionId) return;

  try {
    const ratingApi =
      `products/${productId}/productVersions/${versionId}` +
      `/productOptions/${optionId}/ratingSystems?limit=250`;
    const results = await dispatch(api.actions.get(ratingApi));
    const { productOptionRatingSystems: ratingSystems } = results;
    const formattedRatings = ratingSystems.map(rating => {
      const { rating_system_id: id } = rating;
      return { id, value: true };
    });
    return formattedRatings;
  } catch (error) {
    dispatch(config.actions.error(error));
  }
};

/** This function is using to get characteristic data of a specific product option
 * @param {string} productOptionId The selected product option id.
 * @return {array} list of characteristic.
 **/
const getCharacteristicData = (productId, versionId, optionId) => async dispatch => {
  if (!optionId) return;

  try {
    const charsApi =
      `products/${productId}/productVersions/${versionId}` +
      `/productOptions/${optionId}/environmentCharacteristics?limit=250`;
    const { productOptionEnvironmentCharacteristics: characteristics } = await dispatch(
      api.actions.get(charsApi)
    );
    const formattedCharacteristics = characteristics.map(characteristic => {
      const { environment_characteristic_id: id, value } = characteristic;
      return { id, value };
    });
    return formattedCharacteristics;
  } catch (error) {
    dispatch(config.actions.error(error));
  }
};

/** This function is using to add a new list of certificate into a specific product option
 * @param {string} productOptionId The selected product option id.
 * @param {array} certificates The certificates list that is adding.
 * @return refresh the product after adding.
 **/
const addCertificates = ({ attributeIds }, newOption) => async (dispatch, getState) => {
  const state = getState();
  const productId = reducerUtil.getSlice(detailsData, detailsData.productId, state);
  const selectedOption = reducerUtil.getSlice(detailsData, detailsData.selectedOption, state) || 0;
  const options = reducerUtil.getSlice(detailsData, detailsData.options, state) || [];

  const { id: optionId, [detailsUtils.sustainabilityGroups[0]]: list } =
    options[selectedOption] || {};

  const newIds = dispatch(checkCurrentData(list, attributeIds));

  if (!newIds || newIds.length < 1) return;
  try {
    await dispatch(
      api.actions.post(
        `products/${productId}/productVersions/${productId}/productOptions/` +
          `${optionId}/documents`,
        JSON.stringify(newIds.map(id => ({ document_id: id })))
      )
    );
    return dispatch(loadActions.refreshProduct());
  } catch (error) {
    dispatch(config.actions.error(error));
  }
};

const saveCertificate = ({ productId, optionId, attributeId, value }) => async dispatch => {
  const { assetId: asset_id, date: expiration_date, url } = value;
  try {
    await dispatch(
      api.actions.patch(
        `products/${productId}/productVersions/${productId}/productOptions/` +
          `${optionId}/documents/${attributeId}`,
        JSON.stringify({ asset_id, expiration_date, url })
      )
    );
  } catch (error) {
    dispatch(config.actions.error(error));
  }
};

const deleteCertificate = ({ attributeId }) => async (dispatch, getState) => {
  const state = getState();
  const productId = reducerUtil.getSlice(detailsData, detailsData.productId, state);
  const selectedOption = reducerUtil.getSlice(detailsData, detailsData.selectedOption, state) || 0;
  const options = reducerUtil.getSlice(detailsData, detailsData.options, state) || [];
  const { id: optionId } = options[selectedOption] || {};

  try {
    await dispatch(
      api.actions.delete(
        `products/${productId}/productVersions/${productId}/productOptions/` +
          `${optionId}/documents/${attributeId}`
      )
    );
    return dispatch(loadActions.refreshProduct());
  } catch (error) {
    dispatch(config.actions.error(error));
  }
};

/** This function is using to update value of a specific rating system attribute
 * @param {string} prodOptId The selected product option id.
 * @param {string} attributeId The selected rating system id.
 * @param {boolean} value The value that is going to update (false: deleting, true: adding).
 * @return {nothing} don't need to return anything.
 **/
const updateRatingAttribute = ({ productId, optionId, attributeId, value }) => async dispatch => {
  const apiEndpoint =
    `products/${productId}/productVersions/` +
    `${productId}/productOptions/${optionId}/ratingSystems`;

  try {
    if (value) {
      await dispatch(
        api.actions.post(apiEndpoint, JSON.stringify([{ rating_system_id: attributeId }]))
      );
    } else {
      await dispatch(api.actions.delete(apiEndpoint + `/${attributeId}`));
    }
  } catch (error) {
    dispatch(config.actions.error(error));
  }
};

/** This function is clears all selected ratings from a product option
 * @param {string} optionId The selected product option id.
 * @return refresh the product after adding.
 **/
const clearRatings = optionId => async (dispatch, getState) => {
  const state = getState();
  const productId = reducerUtil.getSlice(detailsData, detailsData.productId, state);
  const values =
    state.form[detailsUtils.form.name].values.sustainability[optionId][
      detailsUtils.sustainabilityGroups[2]
    ];

  const formattedValues = Object.keys(values).map(attributeId => attributeId);

  const apiEndpoint =
    `products/${productId}/productVersions/` +
    `${productId}/productOptions/${optionId}/ratingSystems`;

  try {
    await dispatch(api.actions.delete(apiEndpoint, JSON.stringify(formattedValues)));
  } catch (error) {
    dispatch(config.actions.error(error));
  }

  dispatch(loadActions.refreshProduct());
};

/** This function is using to add a new list of characteristic into a specific product option
 * @param {string} productOptionId The selected product option id.
 * @param {array} characteristics The characteristics list that is adding.
 * @return refresh the product after adding.
 **/
const addCharacteristics = ({ attributeIds }) => async (dispatch, getState) => {
  const state = getState();
  const productId = reducerUtil.getSlice(detailsData, detailsData.productId, state);
  const selectedOption = reducerUtil.getSlice(detailsData, detailsData.selectedOption, state) || 0;
  const options = reducerUtil.getSlice(detailsData, detailsData.options, state) || [];
  const characteristics =
    reducerUtil.getSlice(attributesData, attributesData.characteristics, state) || [];

  const { id: optionId, [detailsUtils.sustainabilityGroups[1]]: list } =
    options[selectedOption] || {};

  const newIds = dispatch(checkCurrentData(list, attributeIds));

  if (!newIds || newIds.length < 1) return;
  try {
    await dispatch(
      api.actions.post(
        `products/${productId}/productVersions/${productId}/productOptions/` +
          `${optionId}/environmentCharacteristics`,
        JSON.stringify(
          newIds.map(id => {
            const { defaultValue } = characteristics.find(item => item.id === id) || {};
            return { environment_characteristic_id: id, value: defaultValue };
          })
        )
      )
    );
    return dispatch(loadActions.refreshProduct());
  } catch (error) {
    dispatch(config.actions.error(error));
  }
};

/** This function is using to check if there are any item in the adding data are
 * existed in current data or not
 * @param {string} productOptionId The selected product option id.
 * @param {array} data The data that is adding.
 * @param {array} group The group (certificate | environment characteristics | rating systems).
 * @return {array} new data that has been filtered with current data.
 **/
const checkCurrentData = (list, ids) => () => {
  if (!list || list.length < 1) return ids;
  else {
    return ids.filter(id => !list.find(item => item === id));
  }
};

/** This function is using to update value of a specific characteristic attribute
 * @param {string} prodOptId The selected product option id.
 * @param {string} attributeId The id of characteristic that is updating.
 * @param {boolean|number|string} value The value that is going to update.
 * @return {nothing} don't need to return anything.
 **/
const saveCharacteristics = ({ productId, optionId, attributeId, value }) => async dispatch => {
  try {
    await dispatch(
      api.actions.patch(
        `products/${productId}/productVersions/${productId}/productOptions/` +
          `${optionId}/environmentCharacteristics/${attributeId}`,
        JSON.stringify({ value })
      )
    );
  } catch (error) {
    dispatch(config.actions.error(error));
  }
};

/** This function is using to delete a specific characteristic attribute
 * @param {string} prodOptId The selected product option id.
 * @param {string} attributeId The id of characteristic that is deleting.
 * @return {nothing} don't need to return anything but need to refresh product information.
 **/
const deleteCharacteristics = ({ attributeId }) => async (dispatch, getState) => {
  const state = getState();
  const productId = reducerUtil.getSlice(detailsData, detailsData.productId, state);
  const selectedOption = reducerUtil.getSlice(detailsData, detailsData.selectedOption, state) || 0;
  const options = reducerUtil.getSlice(detailsData, detailsData.options, state) || [];
  const { id: optionId } = options[selectedOption] || {};

  try {
    await dispatch(
      api.actions.delete(
        `products/${productId}/productVersions/${productId}/productOptions/` +
          `${optionId}/environmentCharacteristics/${attributeId}`
      )
    );
    return dispatch(loadActions.refreshProduct());
  } catch (error) {
    dispatch(config.actions.error(error));
  }
};

export default {
  createOption,
  saveOption,
  deleteOption,
  getOptions,
  addCertificates,
  saveCertificate,
  deleteCertificate,
  updateRatingAttribute,
  clearRatings,
  saveCharacteristics,
  deleteCharacteristics,
  addCharacteristics,
  sortList,
  filterList,
  getCertificateData,
  getRatingSystemData,
  getCharacteristicData,
  getOptionValues,
  setOption
};
