// ------------------------------------
// Constants
// ------------------------------------
const SIDEBAR_TOGGLE = 'SIDEBAR_TOGGLE';

// ------------------------------------
// Dispatches
// ------------------------------------

const sidebarToggle = () => ({
  type: SIDEBAR_TOGGLE
});

// ------------------------------------
// Actions
// ------------------------------------

export const toggleSidebar = () => dispatch => {
  dispatch(sidebarToggle());
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [SIDEBAR_TOGGLE]: (state, action) => ({ ...state, showSidebar: !state.showSidebar })
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = { showSidebar: true };

export default function sidebarReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
