import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { reducerUtil } from 'base-client';

import { Container, Row, Column, Card } from 'shared-features-client';

import sbManagerStyles from 'utils/globalStyles';
import ProjectDetailsHeader from './ProjectDetailsHeader';
import ProjectUserProfile from './ProjectUserProfile';
import ProjectInfoTop from './ProjectInfoTop';
import ProjectInfoRight from './ProjectInfoRight';
import ProjectInfoDescription from './ProjectInfoDescription';
import ProjectContacts from './ProjectContacts';
import DocumentList from './DocumentList';
import ProjectMap from './ProjectMap';
import ProductList from './ProductList';
import ProjectHistory from './ProjectHistory';

import { actions as detailsActions, reducerData as detailsData } from 'projectDetails';

const MainContent = styled(Card)`
  padding: 2rem 1rem;
`;

const ProjectSection = styled.div`
  border-bottom: 1px solid ${sbManagerStyles.accentGrey};
`;

const StyledRow = styled(Row)`
  padding-bottom: 2rem;
`;

const BottomRow = styled(Row)`
  margin: 0 -20px -20px;
`;

const ProjectDetails = ({ dispatch, generalData, contactInformation, match }) => {
  const id = match && match.params && match.params.id;
  const { id: current } = generalData;

  useEffect(() => {
    dispatch(detailsActions.loadProject(id));
  }, [id]);

  if (current !== id) return null;

  return (
    <React.Fragment>
      <ProjectDetailsHeader {...generalData} />
      <Row>
        <Column md={12} lg={9}>
          <ProjectInfoTop {...generalData} />
          <MainContent>
            <Container>
              <ProjectSection>
                <StyledRow>
                  <Column>
                    <ProjectMap />
                  </Column>
                  <Column>
                    <ProjectInfoRight {...generalData} />
                  </Column>
                </StyledRow>
              </ProjectSection>
            </Container>
            <ProjectInfoDescription {...generalData} />
            <ProjectContacts {...contactInformation} />
            <BottomRow>
              <Column md={6} lg={8} paddingY="0">
                <ProductList projectId={id} />
              </Column>
              <Column md={6} lg={4} paddingY="0">
                <DocumentList />
              </Column>
            </BottomRow>
          </MainContent>
        </Column>
        <Column md={12} lg={3}>
          <Card>
            <ProjectUserProfile />
            <ProjectHistory />
          </Card>
        </Column>
      </Row>
    </React.Fragment>
  );
};

ProjectDetails.propTypes = {
  generalData: PropTypes.object
};

ProjectDetails.defaultProps = {
  generalData: {}
};

const mapStateToProps = state => ({
  generalData: reducerUtil.getSlice(detailsData, detailsData.generalData, state),
  contactInformation: reducerUtil.getSlice(detailsData, detailsData.contactInformation, state)
});

export default withRouter(connect(mapStateToProps)(ProjectDetails));
