import React from 'react';
import PropTypes from 'prop-types';

import { BoxIcon, CheckboxIcon, CheckboxPartialIcon } from '../../../../Miscellaneous/Icons';
import Component from '../components';

const checkRules = {
  checked: 1,
  partial: 0,
  unchecked: -1,
};

const setIcon = (checkState, props) => {
  switch (checkState) {
    case checkRules.checked: return <CheckboxIcon {...props} />;
    case checkRules.partial: return <CheckboxPartialIcon {...props} />;
    default: return <BoxIcon {...props} />;
  }
};

const PartialCheckbox = ({ checkState, size, ...checkProps }) => (
  <Component
    checked={checkState !== checkRules.unchecked}
    icon={setIcon(checkState, { viewBox: '0 0 18 18', width: size, height: size, addClass: 'checkbox__icon' })}
    {...{ size, ...checkProps }}
  />
);

PartialCheckbox.propTypes = {
  checkState: PropTypes.number,
  size: PropTypes.number,
};

PartialCheckbox.defaultProps = {
  checkState: checkRules.unchecked,
  size: 21,
};

PartialCheckbox.checkRules = checkRules;

export default PartialCheckbox;
