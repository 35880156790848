import { reducerUtil } from 'base-client';
import { interval as defaultInterval } from 'utils/datePickerUtil';
import { reducerData as insightsData, utils as insightUtils } from 'insights';
import { reducerData as permissionsData } from 'permissions';
import { actions as cacheActions, utils as cacheUtils } from 'cache';

// ------------------------------------
// General Insight Functions
// ------------------------------------
const insightApi = (site, stream, analysis, { interval, count, ...bodyData }) => async (
  dispatch,
  getState
) => {
  const insightPermission = reducerUtil.getSlice(
    permissionsData,
    permissionsData.insights,
    getState()
  );
  if (!insightPermission) return;

  const streamConvert = {
    views: 'pageview',
    productviews: 'product',
    downloads: 'download'
  };

  stream = streamConvert[stream] || stream;
  interval = interval || defaultInterval.initial;
  count = count || 1;
  const url = `insights/${site}/${stream}/${analysis}`;
  const tick = insightUtils.chooseTick(interval, count);
  const key = cacheUtils.insightKey({ url, interval, count, ...bodyData });
  const body = insightUtils.insightBody({ interval, tick, ...bodyData });
  const data = await dispatch(cacheActions.postCache(key, url, body));

  const results = { data, tick };
  return results;
};

// ------------------------------------
// Dashboard Analytics
// ------------------------------------
const getProductInfo = {
  viewUnique: interval => viewUnique(interval),
  viewTotal: interval => viewTotal(interval),
  download: interval => download(interval)
};

const viewUnique = interval => dispatch =>
  dispatch(getProductChart('views', 'uniqueVisitors', interval, 'viewUnique'));

const viewTotal = interval => dispatch =>
  dispatch(getProductChart('views', 'sum', interval, 'viewTotal'));

const download = interval => dispatch =>
  dispatch(getProductChart('download', 'sum', interval, 'download'));

const getProductChart = (stream, analysis, interval, chartKey) => async dispatch => {
  const results = await dispatch(insightApi('all', stream, analysis, { interval, count: 6 }));
  const { tick, data } = results || {};
  dispatch(receiveProductInfo(chartKey, data, tick));
  dispatch(receiveProductInfo(`${chartKey}Number`, data));
  dispatch(setProductInfoInterval(interval));
};

const setProductInfoInterval = interval => dispatch =>
  dispatch(reducerUtil.setSlice(insightsData, insightsData.productInfoInterval, interval));

const receiveProductInfo = (key, data, tick) => (dispatch, getState) => {
  const productInfo = Object.assign(
    {},
    reducerUtil.getSlice(insightsData, insightsData.productInfo, getState()) || {}
  );
  productInfo[key] = tick
    ? insightUtils.productChart(data, tick)
    : insightUtils.productInfoNumber(data);
  dispatch(reducerUtil.setSlice(insightsData, insightsData.productInfo, productInfo));
};

export default {
  insightApi,
  getProductInfo,
  receiveProductInfo,
  setProductInfoInterval
};
