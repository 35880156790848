import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import ButtonIndex from './../components/ButtonIndex';

const ButtonContainer = ({ buttonStyle, icon, iconPosition, ...other }) => {
  const buttonClassNames = cn('button', { [`button--${buttonStyle}`]: !!buttonStyle });
  const iconClassNames = cn('button__icon', { 'button__icon--after': iconPosition === 'after' });

  const iconCloned =
    icon &&
    cloneElement(icon, {
      className: iconClassNames,
      key: 'iconCloned'
    });

  return (
    <ButtonIndex
      buttonClassNames={buttonClassNames}
      icon={iconCloned}
      iconPosition={iconPosition}
      {...other}
    />
  );
};

ButtonContainer.propTypes = {
  buttonStyle: PropTypes.string, //  default, create, red, green, orange, link
  icon: PropTypes.node,
  iconPosition: PropTypes.oneOf([
    'before', // default
    'after'
  ])
};

ButtonContainer.defaultProps = {
  buttonStyle: 'default',
  icon: null,
  iconPosition: 'before'
};

export default ButtonContainer;
