const modalName = "createProduct";
const pages = {
  select: "select",
  manual: "manual",
  upload: "upload",
  list: "list",
  csvResult: "csvResult"
};

export { modalName, pages };
