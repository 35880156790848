import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import sbManagerStyles from 'utils/globalStyles';
import { PageHeader } from 'shared';
import BulkControls from './BulkControls';
import ListControls from './ListControls';
import SelectControls from './SelectControls';
import SortControls from './SortControls';

import { reducerUtil } from 'base-client';
import { reducerData as listData, actions as listActions } from 'productList';

const Container = styled.div`
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: ${() => sbManagerStyles.greyLightest};
  margin-left: 25rem;
  margin-top: -6rem;
  padding: 1rem;
`;

const ProductHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const HeaderControl = styled.div`
  margin-right: 1rem;
`;

const Header = ({ grid, selected, query, meta, dispatch, ...props }) => {
  const enable = selected.length > 0;
  const selectedCount = selected.length || 0;
  const { sortBy, sortDir } = query || {};
  const hitCount = meta ? meta.totalHits : 0;

  const { listMode, setListMode, optionKeys, optionIcons } = props;
  const setSortBy = sorter => dispatch(listActions.setSort(sorter));
  return (
    <>
      <PageHeader
        title="Products"
        subtitle={
          selectedCount ? `${hitCount} Found (${selectedCount} Selected)` : `${hitCount} Found`
        }
      />
      <Container>
        <ProductHeader className="products-header">
          {grid && (
            <HeaderControl className="products-header__controls">
              <SelectControls {...props} isButton />
            </HeaderControl>
          )}
          <HeaderControl className="products-header__controls">
            <SortControls {...{ setSortBy, sortBy, sortDir }} isButton />
          </HeaderControl>
          <HeaderControl className="products-header__controls">
            <ListControls {...{ listMode, setListMode, optionKeys, optionIcons }} />
          </HeaderControl>
          <BulkControls {...{ enable }} />
        </ProductHeader>
      </Container>
    </>
  );
};

Header.propTypes = {
  dispatch: PropTypes.func.isRequired,
  grid: PropTypes.bool,
  selected: PropTypes.array,
  query: PropTypes.object,
  meta: PropTypes.object
};

Header.defaultProps = {
  selected: []
};

const mapStateToProps = state => ({
  selected: reducerUtil.getSlice(listData, listData.selected, state),
  query: reducerUtil.getSlice(listData, listData.query, state),
  meta: reducerUtil.getSlice(listData, listData.meta, state)
});

export default connect(mapStateToProps)(Header);
