import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../SvgIcon';

const DecreaseIcon = props => (
  <SvgIcon {...props}>
    <path d='M11 10L11 14 14 14 7 21 0 14 3 14 3 10 11 10z'/>
    <path d='M11 5L3 5 3 9 11 9 11 5z'/>
    <path d='M11 1L3 1 3 4 11 4 11 1z'/>
  </SvgIcon>
);

DecreaseIcon.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  viewBox: PropTypes.string.isRequired,
};

DecreaseIcon.defaultProps = {
  width: 14,
  height: 21,
  viewBox: '0 0 14 21',
};

export default DecreaseIcon;

