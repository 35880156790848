import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { CheckboxFormulaOnIcon, CheckboxFormulaOffIcon } from '../../Miscellaneous/Icons';
import './assets/checkbox.scss';

const Checkbox = ({ size, checked, disabled, ...inputProps }) => {
  const iconProps = {
    width: size,
    height: size
  };
  return (
    <label className={cn('checkbox', { checked, disabled })} style={{ width: size }}>
      {(checked && 
        <div className='tooltip-trigger'>
          <CheckboxFormulaOnIcon {...iconProps} />
          <span className="formula-tooltip">Turn formula off</span>  
        </div>)
       || 
        <div className='tooltip-trigger'>
          <CheckboxFormulaOffIcon {...iconProps} />          
          <span className="formula-tooltip">Turn formula on</span>  
        </div>}
      <input type='checkbox' {...{ checked, disabled }} {...inputProps} />
    </label>
  );
};

Checkbox.propTypes = {
  checked: PropTypes.any,
  disabled: PropTypes.bool,
  size: PropTypes.number,
};

Checkbox.defaultProps = {
  checked: false,
  disabled: false,
  size: 22,
};

export default Checkbox;
