import React from 'react';
import PropTypes from 'prop-types';

import { Table, Column, SortIndicator } from 'react-virtualized';
import { LinkButton } from 'shared/Buttons';

const BrochuresTable = ({ data, registerChild, nameRenderer, dateRenderer, ...rest }) => {
  const rowGetter = ({ index }) => {
    return data[index] || {};
  };

  const tableProps = {
    ...rest,
    headerHeight: 55,
    rowHeight: 55,
    rowGetter,
    rowClassName: 'table-row',
    headerClassName: 'table-header',
    girdClassName: 'table__visible-items',
    scrollToAlignment: 'start'
  };

  const headerRenderer = ({ label, dataKey, sortBy, sortDirection }) => {
    return <div>
      {label}
      {sortBy === dataKey && <SortIndicator {...{ sortDirection }} />}
    </div>
  };

  const companyRenderer = ({ rowData }) => {
    const { companyName } = rowData;

    return companyName ? <div>{companyName}</div> : null;
  };

  const projectNameRenderer = ({ rowData }) => {
    const { eventMetadata } = rowData;

    return eventMetadata && eventMetadata.project_id ? (
      <LinkButton to={`/insights/projects/${eventMetadata.project_id}`}>{eventMetadata.name}</LinkButton>
    ) : null;
  };

  return (
    <Table {...tableProps} ref={registerChild}>
      <Column
        dataKey="date"
        label="Date"
        cellRenderer={dateRenderer}
        headerRenderer={headerRenderer}
        width={250}
        flexGrow={0}
      />
      <Column
        dataKey="name"
        label="Name"
        cellRenderer={nameRenderer}
        headerRenderer={headerRenderer}
        width={250}
        flexGrow={0}
      />
      <Column
        dataKey="company"
        label="Company"
        headerRenderer={headerRenderer}
        cellRenderer={companyRenderer}
        width={250}
        flexGrow={0}
      />
      <Column
        dataKey="projectName"
        label="Project Name"
        headerRenderer={headerRenderer}
        cellRenderer={projectNameRenderer}
        width={350}
        flexGrow={1}
      />
    </Table>
  );
};

BrochuresTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string,
      name: PropTypes.string,
      company: PropTypes.string,
      projectName: PropTypes.string,
      projectId: PropTypes.string
    })
  ).isRequired,
  registerChild: PropTypes.any
};

BrochuresTable.defaultTypes = {
  data: []
};

export default BrochuresTable;
