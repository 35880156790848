import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Login from './Login';
import ForgotPassword from './ForgotPassword';
import PasswordReset from './PasswordReset';
import loginActions from 'login/actions';
import loginUtils from 'login/utils';

class LoginContainer extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired
  };

  state = {};

  componentDidMount() {
    window.addEventListener('keyup', this.enterListener);
  }

  componentWillUnmount() {
    window.removeEventListener('keyup', this.enterListener);
  }

  enterListener = e => {
    if (e.key === 'Enter' || e.code === 'Enter') {
      this.onClickLogin();
    }
  };

  onClickLogin = async () => {
    if (!this.checkUser()) return;
    this.setState({ message: null });

    const { user, pass } = this.state;
    const { dispatch } = this.props;

    const error = await dispatch(loginActions.login(user, pass));
    if (error) this.errorMessage();
  };

  onClickForgot = () => this.setState({ message: null, forgot: true });

  onClickBack = () => this.setState({ message: null, forgot: false });

  onClickReset = async () => {
    // first stage for password reset
    if (!this.checkUser()) return;

    const { user } = this.state;
    const { dispatch } = this.props;
    const { hostname, origin } = window.location;

    // eslint-disable-next-line max-len
    await dispatch(loginActions.resetPasswordLink({ hostname, origin, tenantId: false, user })); // base-client

    this.setState({
      forgot: false,
      message: {
        text: 'We have sent you an email for a password reset',
        style: 'success'
      }
    });
  };

  onClickPasswordReset = async ({ auth0Id, password, username }) => {
    // second stage for password reset
    const { dispatch } = this.props;
    await dispatch(
      loginActions.changePassword({
        auth0Id,
        password,
        username
      })
    ); // not base-client
  };

  setUser = e => this.setState({ user: e.target.value });

  setPass = e => this.setState({ pass: e.target.value });

  checkUser = () => {
    const { user } = this.state;
    if (loginUtils.regExEmail.test(user)) {
      this.setState({ message: null });
      return true;
    }
    this.errorMessage();
    return false;
  };

  errorMessage = () =>
    this.setState({
      message: {
        text: 'The email or password was incorrect',
        style: 'danger'
      }
    });

  render() {
    const { user, pass, message, forgot } = this.state;
    const props = {
      user,
      pass,
      message,
      invalid: {
        user: !user && message && message.style === 'danger',
        pass: !pass && message && message.style === 'danger'
      },
      setUser: this.setUser,
      setPass: this.setPass,
      onClickLogin: this.onClickLogin,
      onClickForgot: this.onClickForgot,
      onClickBack: this.onClickBack,
      onClickReset: this.onClickReset,
      onClickPasswordReset: this.onClickPasswordReset
    };

    if (window.location.href.includes('?')) return <PasswordReset {...props} />;
    if (forgot) return <ForgotPassword {...props} />;
    return <Login {...props} />;
  }
}

export default connect()(LoginContainer);
