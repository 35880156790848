import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import sbManagerStyles from 'utils/globalStyles';
import { DeleteIcon } from 'shared/Miscellaneous/Icons';
import Label from './Label';
import { FieldWrapper, InputWrapper, StyledInput, Error, HelpText } from './Styles';

const StyledPercentage = styled.span`
  content: '%';
  position: absolute;
  height: 3.3rem;
  right: 3.7rem;
  top: 0.1rem;
  border-radius: 0 0.2rem 0.2rem 0;
  padding: 0 1rem;
  line-height: 3.3rem;
  color: ${sbManagerStyles.greyMedium};
  background-color: ${sbManagerStyles.accentGrey};
  border-left: 0.1rem solid ${sbManagerStyles.greyLight};
`;

const NumberField = ({
  labelText,
  placeholder,
  required,
  className,
  inline,
  percentage,
  type = 'number',
  handleOnChange,
  onDelete,
  input = {},
  meta: { touched, error, helptext } = {}
}) => (
  <FieldWrapper className={inline ? 'inline' : ''}>
    {labelText && <Label {...{ required }}>{labelText}</Label>}
    <InputWrapper>
      <StyledInput
        {...input}
        {...{ placeholder, className, type }}
        className={`${percentage && 'percentage'} ${touched && error && 'error'}`}
        onBlur={() => handleOnChange(input.name)}
      />
      {percentage && <StyledPercentage>%</StyledPercentage>}
      {onDelete && (
        <DeleteIcon
          onClick={onDelete}
          addClass="icon--neutral icon--hover-danger"
          width={28}
          height={28}
          viewBox="0 0 28 34"
        />
      )}
    </InputWrapper>
    {touched &&
      ((error && <Error>{error}</Error>) || (helptext && <HelpText>{helptext}</HelpText>))}
  </FieldWrapper>
);

NumberField.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string
  }),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string
  }),
  type: PropTypes.string,
  labelText: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  inline: PropTypes.bool,
  percentage: PropTypes.bool,
  helperText: PropTypes.string,
  handleOnChange: PropTypes.func.isRequired
};

export default NumberField;
