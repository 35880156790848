import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import sbManagerStyles from 'utils/globalStyles';
import { SectionHeader } from 'shared';
import NoDataCallout from './NoDataCallout';
import PieChart from './Pie';
import BarChart from './Bar';

const ProductUsage = ({ actions = [], categories = [], sites = [] }) => {
  const AnalyticsContainer = styled.div`
    display: flex;
    flex-direction: row;
  `;
  const AnalyticsSection = styled.div`
    padding: 2rem;
    display: flex;
    flex-direction: row;
    width: 33%;
    .donut-card {
      width: 30rem;
      height: 30rem;
      .highcharts-legend-box {
        stroke: transparent;
      }
    }
  `;
  return (
    <AnalyticsContainer>
      {sites.find(site => site.y > 0) && (
        <>
          <AnalyticsSection>
            <PieChart data={actions} namespace="ActivityType" title="Total Events" />
          </AnalyticsSection>
          <AnalyticsSection>
            <PieChart data={sites} namespace="ChannelActivity" title="Channel Activity" />
          </AnalyticsSection>
          <AnalyticsSection style={{ width: '40%' }}>
            <BarChart data={categories} namespace="CategoryUsage" title="Category Usage" />
          </AnalyticsSection>
        </>
      )}
      {!sites.find(site => site.y > 0) && (
        <NoDataCallout
          dataTitle={'Analytics'}
          flavor={
            'You should add them to an email list and drive them to your site to capture activity. Get this user into your funnel.'
          }
        />
      )}
    </AnalyticsContainer>
  );
};
ProductUsage.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      y: PropTypes.number.isRequired
    })
  ),
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      y: PropTypes.number.isRequired
    })
  ),
  sites: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      y: PropTypes.number.isRequired
    })
  )
};

export default ProductUsage;
