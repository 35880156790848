/* vendor imports */
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

/* system imports */
import { actions as adminActions, reducerData as adminData } from 'admin';
import { formUserEditName } from 'admin/utils';
import EditUserContainer from './EditUserContainer';
import reducerUtil from 'base-client/dist/reducerUtil';

const mapStateToProps = state => {
  const user = reducerUtil.getSlice(adminData, adminData.editUser, state);
  const userName = (user && user.userName) || 'User';
  const initialValues = {
    role: user && user.userRole,
    active: user && user.userActive
  };

  return {
    initialValues,
    roles: reducerUtil.getSlice(adminData, adminData.roles, state),
    title: `Edit Access for ${userName}`
  };
};

const mapDispatchToProps = dispatch => ({
  clickEditUser: () => dispatch(adminActions.putUserEdit())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: formUserEditName,
    enableReinitialize: true
  })(EditUserContainer)
);
