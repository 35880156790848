import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from './../SvgIcon';

const PublishOutlineIcon = (props) => (
  <SvgIcon {...props}>
		<path d="M 13 14L 5 14C 2.243 14 0 11.757 0 9C 0 6.243 2.243 4 5 4C 5.533 4 6.061 4.086 6.563 4.254C 7.642 1.699 10.141 0 13 0C 16.859 0 20 3.141 20 7C 20 10.86 16.859 14 13 14ZM 5 6C 3.346 6 2 7.346 2 9C 2 10.654 3.346 12 5 12L 13 12C 15.757 12 18 9.757 18 7C 18 4.243 15.757 2 13 2C 10.663 2 8.662 3.59 8.135 5.867C 8.061 6.183 7.838 6.445 7.535 6.567C 7.234 6.689 6.892 6.658 6.618 6.48C 6.131 6.166 5.57 6 5 6Z"/>
  </SvgIcon>
)

PublishOutlineIcon.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  viewBox: PropTypes.string.isRequired,
};

PublishOutlineIcon.defaultProps = {
  width: 20,
  height: 12,
  viewBox: '0 0 20 14',
};

export default PublishOutlineIcon;