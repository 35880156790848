import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from './../SvgIcon';

const DeleteIcon = (props) => (
  <SvgIcon {...props}>
    <polygon points="15,4.999 15,3 9,3 9,4.999 3,4.999 3,6.999 21,6.999 21,4.999 "/>
    <path d="M5,7.999v12c0,1.102,0.897,2,2,2h10c1.103,0,2-0.898,2-2v-12H5z M11,18H9v-6h2V18z M15,18h-2v-6h2V18z"/>
  </SvgIcon>
)

DeleteIcon.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  viewBox: PropTypes.string.isRequired,
};

DeleteIcon.defaultProps = {
  width: 20,
  height: 14,
  viewBox: '0 0 24 22',
};

export default DeleteIcon;