import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../SvgIcon';

const ProjectCreatedIcon = props => (
  <SvgIcon {...props}>
    <path
      d="M18 3.91456H10L8.553 1.92306C8.214 1.21016 7.521 0.760132 6.764 0.760132H1C0.447 0.760132 0 1.23014 0 1.81161V16.5323C0 17.6931 0.895 18.6352 2 18.6352H18C19.104 18.6352 20 17.6931 20 16.5323V6.01751C20 4.85668 19.104 3.91456 18 3.91456ZM14 12.3264H11V15.4808H9V12.3264H6V10.2234H9V7.06899H11V10.2234H14V12.3264Z"
      fill="#0A7BE4"
    />
  </SvgIcon>
);

ProjectCreatedIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
};

ProjectCreatedIcon.defaultProps = {
  width: 20,
  height: 19
};

export default ProjectCreatedIcon;
