import uuid from 'uuid/v4';
import { api, config, history, reducerUtil } from 'base-client';

import { reducerData as analyticsData } from 'analyticsDashboard';
import { analyzers, events } from 'analyticsDashboard/utils';
import trimQuery from 'utils/trimQuery';
import toNumber from 'utils/apiNumberUtil';

import { defaultISOInterval } from 'shared-features-client';

const defaultQuery = {
  analyzers: Object.keys(analyzers).map(key => analyzers[key]),
  dateRange: {
    start: defaultISOInterval.startDate,
    end: defaultISOInterval.endDate
  },
  includePrevious: true
};

/** This performs an analytics search.
 * @returns {promise} The api request.
 */
const search = () => async (dispatch, getState) => {
  let state = getState();
  // get the querystring
  const {
    location: { search: queryJson }
  } = history;

  const query = queryJson ? JSON.parse(decodeURIComponent(trimQuery(queryJson))) : {};

  const fetchId = uuid();
  dispatch(reducerUtil.setSlice(analyticsData, analyticsData.fetchId, fetchId));

  const apiData = { ...defaultQuery, ...query };

  try {
    const {
      current: { results: current, availableFacets: meta },
      previous: { results: previous }
    } = await dispatch(api.actions.post('insights', JSON.stringify(apiData)));

    // check that this is the correct fetch
    state = getState();
    if (fetchId !== reducerUtil.getSlice(analyticsData, analyticsData.fetchId, state)) return;

    // transform the results
    const data = {};
    data[analyzers.visitors] = dispatch(getData(analyzers.visitors, current, previous));
    data[analyzers.sessions] = dispatch(
      getData(analyzers.sessions, current, previous, 'total_unique_sessions')
    );
    data[analyzers.loyalty] = dispatch(
      getData(analyzers.loyalty, current, previous, 'loyal_visitors')
    );
    data[analyzers.engagement] = dispatch(
      getData(analyzers.engagement, current, previous, 'engagement_count')
    );

    //handle views and downloads
    data.downloads = dispatch(getActionData(current, previous, 'download'));
    data.views = dispatch(getActionData(current, previous, 'pageview'));

    //handle conversions
    data.conversion = dispatch(getConversion(current, previous));

    //handle actions
    data[analyzers.actions] = dispatch(getActions(current));

    // handle file types
    data[analyzers.fileTypes] = dispatch(getFileTypes(current));

    dispatch(reducerUtil.setSlice(analyticsData, analyticsData.data, data));

    //update the meta
    const { filters = [] } = query || {};
    const formattedMeta = !meta ? meta : dispatch(formatMeta(meta, filters));
    dispatch(reducerUtil.setSlice(analyticsData, analyticsData.meta, formattedMeta));

    //update the query
    dispatch(reducerUtil.setSlice(analyticsData, analyticsData.query, query));

    // allow another search
    dispatch(reducerUtil.setSlice(analyticsData, analyticsData.fetchId, undefined));
  } catch (error) {
    dispatch(config.actions.error(error));
  }
};

const getData = (analyzer, current, previous, countName = 'count') => dispatch => {
  const currentTotal = current[analyzer].reduce(
    (sum, { [countName]: count }) => sum + toNumber(count),
    0
  );
  const previousTotal = previous[analyzer].reduce(
    (sum, { [countName]: count }) => sum + toNumber(count),
    0
  );

  return dispatch(getTrend(currentTotal, previousTotal));
};

const getActionData = (current, previous, type) => dispatch => {
  const countName = 'unique_count';
  const currentObject = current[analyzers.actions].find(item => item.type === type);
  const previousObject = previous[analyzers.actions].find(item => item.type === type);
  const currentTotal = toNumber(currentObject && currentObject[countName]);
  const previousTotal = toNumber(previousObject && previousObject[countName]);

  return dispatch(getTrend(currentTotal, previousTotal));
};

const getConversion = (current, previous) => dispatch => {
  const countName = 'unique_count';
  const typeName = 'download';
  const currentObject = current[analyzers.actions].find(item => item.type === typeName);
  const previousObject = previous[analyzers.actions].find(item => item.type === typeName);
  const curDownloads = toNumber(currentObject && currentObject[countName]);
  const preDownloads = toNumber(previousObject && previousObject[countName]);

  const curVisitors = current[analyzers.visitors].reduce(
    (sum, { count }) => sum + toNumber(count),
    0
  );
  const preVisitors = previous[analyzers.visitors].reduce(
    (sum, { count }) => sum + toNumber(count),
    0
  );

  const currentTotal = curVisitors > 0 ? curDownloads / curVisitors : 0;
  const previousTotal = preVisitors > 0 ? preDownloads / preVisitors : 0;

  return dispatch(getTrend(currentTotal, previousTotal));
};

const getTrend = (currentTotal, previousTotal) => () => ({
  count: Math.round(currentTotal * 100) / 100,
  change: previousTotal > 0 ? Math.abs(currentTotal - previousTotal) / previousTotal : undefined,
  trend: currentTotal >= previousTotal ? 'up' : 'down'
});

const getActions = current => () => {
  let result = [];

  current[analyzers.actions].forEach(({ total_count: count, type }) => {
    const existed = events.find(e => e.key === type);
    if (existed) {
      const { name } = existed;
      result = [...result, { count: toNumber(count), name }];
    }
  });

  return result;
};

const getFileTypes = current => () =>
  current[analyzers.fileTypes].map(({ count, ext: name }) => ({
    count: toNumber(count),
    name
  }));

const setQuery = newData => (dispatch, getState) => {
  const oldQuery = reducerUtil.getSlice(analyticsData, analyticsData.query, getState()) || {};
  const query = { ...oldQuery, ...newData };
  dispatch(reducerUtil.setSlice(analyticsData, analyticsData.query, query));
  history.push(`${history.location.pathname}?${encodeURIComponent(JSON.stringify(query))}`);
};

const setDate = ({ startDate, endDate }) => dispatch => {
  const dateRange = { start: startDate, end: endDate };
  dispatch(clearAllFilters());
  dispatch(setQuery({ dateRange }));
};

const formatFilter = (attribute, name, data, selected) => {
  const { facets: selectedFacets = [] } =
    selected.find(({ attribute: selectedAttribute }) => attribute === selectedAttribute) || {};
  const filter = {
    attribute,
    name,
    checkedAll: data.length > 0 && selectedFacets.length === data.length,
    facets: data.map(item => {
      return {
        name: item,
        checked: selectedFacets.includes(item)
      };
    })
  };
  return filter;
};

const formatMeta = (meta, selected) => () => {
  if (!meta) return meta;
  const { location, industry, occupation } = (meta && meta[0]) || {};
  const { facets: selectedLocation = [] } =
    selected.find(({ attribute }) => attribute === 'location') || {};

  const locationFacets = [];
  location.forEach(item => {
    const value = Object.keys(item)[0];
    const label = Object.values(item)[0];
    if (label && value) {
      locationFacets.push({ value, label });
    }
  });

  const locations = {
    selected: selectedLocation,
    attribute: 'location',
    name: 'Location',
    facets: locationFacets
  };

  const industries = formatFilter('industry', 'Industry', industry, selected);
  const occupations = formatFilter('occupation', 'Occupation', occupation, selected);

  return { locations, industries, occupations };
};

const setMeta = meta => dispatch => {
  if (!meta) return;
  dispatch(reducerUtil.setSlice(analyticsData, analyticsData.meta, meta));
}

const setData = data => dispatch => {
  if (!data) return;
  dispatch(reducerUtil.setSlice(analyticsData, analyticsData.data, data));
}

const addFilter = ({ attribute, facets }) => (dispatch, getState) => {
  const query = reducerUtil.getSlice(analyticsData, analyticsData.query, getState()) || {};
  const { filters } = query;

  let newFilters;
  if (!filters) {
    newFilters = [{ attribute, facets: [...facets] }];
  } else {
    const index = filters.findIndex(({ attribute: name }) => name === attribute);
    if (index < 0) {
      newFilters = [...filters, { attribute, facets: [...facets] }];
    } else {
      const { facets: prevFacets } = filters[index];
      newFilters = [...filters];
      newFilters[index] = {
        attribute,
        facets: [...prevFacets, ...facets]
      };
    }
  }

  dispatch(setQuery({ filters: newFilters }));
};

const removeFilter = ({ attribute, facets }) => (dispatch, getState) => {
  const query = reducerUtil.getSlice(analyticsData, analyticsData.query, getState()) || {};
  const { filters } = query;

  let newFilters;
  if (attribute === 'location' && filters.length > 0) {
    newFilters = filters.filter(({ attribute: name }) => name !== attribute);
  } else {
    const index = filters.findIndex(({ attribute: name }) => name === attribute);
    const { facets: prevFacets } = filters[index];
    const newFacets = prevFacets.filter(name => !facets.includes(name));
    if (newFacets.length > 0) {
      newFilters = [...filters];
      newFilters[index] = { attribute, facets: newFacets };
    } else if (filters.length > 1) {
      newFilters = filters.filter((item, filterIndex) => filterIndex !== index);
    }
  }
  dispatch(setQuery({ filters: newFilters }));
};

const updateFilter = ({ attribute, facets }) => (dispatch, getState) => {
  const query = reducerUtil.getSlice(analyticsData, analyticsData.query, getState()) || {};
  const { filters = [] } = query;

  let newFilters;
  if (!facets) {
    newFilters = filters.filter(({ attribute: name }) => name !== attribute);
  } else if (!filters) {
    newFilters = [{ attribute, facets: [facets] }];
  } else {
    const index = filters.findIndex(({ attribute: name }) => name === attribute);
    if (index < 0) {
      newFilters = [...filters, { attribute, facets: [facets] }];
    } else {
      newFilters = [...filters];
      newFilters[index] = { attribute, facets: [facets] };
    }
  }

  dispatch(setQuery({ filters: newFilters.length > 0 ? newFilters : undefined }));
};

const clearAllFilters = () => (dispatch, getState) => dispatch(setQuery({ filters: undefined }));

const setNotification = notification => (dispatch, getState) => {
  if (!notification) return;
  dispatch(reducerUtil.setSlice(analyticsData, analyticsData.notification, notification))
  setTimeout(function () {
    dispatch(reducerUtil.setSlice(analyticsData, analyticsData.notification, false));
  }, 5000);
}

const updateVisualization = (visualization) => dispatch => {
  dispatch(reducerUtil.setSlice(analyticsData, analyticsData.visualization, visualization));
}

export default {
  addFilter,
  clearAllFilters,
  removeFilter,
  search,
  setData,
  setDate,
  setMeta,
  setNotification,
  updateFilter,
  updateVisualization
};
