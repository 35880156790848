import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { BoxIcon, CheckboxIcon } from '../../Miscellaneous/Icons/index';
import './assets/checkbox.scss';

const Checkbox = ({ isChecked, isLocked, onChange, size, viewBox }) => (
  <label
    className={cn('checkbox', { checked: isChecked, disabled: isLocked })}
    style={{ width: size }}
  >
    {isChecked ? (
      <CheckboxIcon width={size} height={size} viewBox={viewBox} addClass="checkbox__icon" />
    ) : (
      <BoxIcon width={size} height={size} viewBox={viewBox} addClass="checkbox__icon" />
    )}
    <input type="checkbox" disabled={isLocked} checked={isChecked} onChange={onChange} />
  </label>
);

Checkbox.propTypes = {
  isChecked: PropTypes.any,
  isLocked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  size: PropTypes.number
};

Checkbox.defaultProps = {
  isChecked: false,
  isLocked: false,
  size: 28
};

export default Checkbox;
