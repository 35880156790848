import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ImportIcon, DownloadIcon, DeleteIcon } from 'shared/Miscellaneous/Icons';
import Button from 'shared/Buttons';

const SharedParamFooter = styled.div`
  margin-bottom: 1rem;
  font-size: 1.4rem;
`;

const Buttons = ({
  sharedParametersData: { filename },
  onClickShowDropzone,
  onClickDownload,
  onClickDelete
}) => (
  <React.Fragment>
    <SharedParamFooter>{filename}</SharedParamFooter>
    <Button onClick={onClickShowDropzone} icon={<ImportIcon width={16} height={16} />}>
      Select New File
    </Button>
    <Button onClick={onClickDownload} icon={<DownloadIcon width={16} height={16} />}>
      Download
    </Button>
    <Button onClick={onClickDelete} buttonStyle="red" icon={<DeleteIcon width={16} height={16} />}>
      Delete
    </Button>
  </React.Fragment>
);

Buttons.propTypes = {
  onClickShowDropzone: PropTypes.func.isRequired,
  onClickDownload: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  sharedParametersData: PropTypes.shape({
    filename: PropTypes.string
  }).isRequired
};

export default Buttons;
