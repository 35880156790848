import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { config } from 'base-client';
import { Table, Column, SortIndicator } from 'react-virtualized';

import { configMap } from 'configurations';

const ProjectsTable = ({ data, nameRenderer, registerChild, dispatch, ...rest }) => {
  const disableCompany = dispatch(
    config.actions.getData(configMap.disabled.name, configMap.disabled.projectTableCompany.name)
  );

  const rowGetter = ({ index }) => {
    return data[index] || {};
  };

  const tableProps = {
    ...rest,
    headerHeight: 55,
    rowHeight: 55,
    rowGetter,
    rowClassName: 'table-row',
    headerClassName: 'table-header',
    girdClassName: 'table__visible-items',
    scrollToAlignment: 'start'
  };

  const headerRenderer = ({ label, dataKey, sortBy, sortDirection }) => (
    <div>
      {label}
      {sortBy === dataKey && <SortIndicator {...{ sortDirection }} />}
    </div>
  );

  if (disableCompany) {
    return (
      <Table {...tableProps} ref={registerChild}>
        <Column
          dataKey="updated_at"
          label="Date Updated"
          headerRenderer={headerRenderer}
          width={200}
          flexGrow={0}
        />
        <Column
          dataKey="name"
          label="Project Name"
          headerRenderer={headerRenderer}
          width={350}
          flexGrow={1}
        />
        <Column
          dataKey="project_budget"
          label="Budget"
          headerRenderer={headerRenderer}
          cellRenderer={({ rowData: { project_budget } }) => project_budget || 'N/A'}
          width={350}
          flexGrow={1}
        />
        <Column
          dataKey="project_phase"
          label="Phase"
          headerRenderer={headerRenderer}
          cellRenderer={({ rowData: { project_phase } }) => project_phase || 'N/A'}
          width={350}
          flexGrow={1}
        />
      </Table>
    );
  }

  return (
    <Table {...tableProps} ref={registerChild}>
      <Column
        dataKey="updated_at"
        label="Date Updated"
        headerRenderer={headerRenderer}
        width={200}
        flexGrow={0}
      />
      <Column
        dataKey="name"
        label="Project Name"
        headerRenderer={headerRenderer}
        width={350}
        flexGrow={1}
      />
      <Column
        dataKey="project_budget"
        label="Budget"
        headerRenderer={headerRenderer}
        cellRenderer={({ rowData: { project_budget } }) => project_budget || 'N/A'}
        width={350}
        flexGrow={1}
      />
      <Column
        dataKey="project_phase"
        label="Phase"
        headerRenderer={headerRenderer}
        cellRenderer={({ rowData: { project_phase } }) => project_phase || 'N/A'}
        width={350}
        flexGrow={1}
      />
      <Column
        dataKey="user.firstName"
        label="Submitter"
        headerRenderer={headerRenderer}
        width={200}
        flexGrow={0}
      />
      <Column
        dataKey="user.profile.companyName"
        label="Company"
        headerRenderer={headerRenderer}
        width={200}
        flexGrow={0}
      />
    </Table>
  );
};

ProjectsTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string,
      name: PropTypes.string,
      company: PropTypes.string,
      projectName: PropTypes.string
    })
  ).isRequired
};

ProjectsTable.defaultTypes = {
  data: []
};

export default connect()(ProjectsTable);
