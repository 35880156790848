import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reducerUtil } from 'base-client';

import JobQueueCard from './JobQueueCard';
import { actions as detailsActions, reducerData as detailsData } from 'productDetails';

const JobQueueContainer = ({ jobs, dispatch }) => {
  const cancelJob = id => dispatch(detailsActions.cancelJob(id));
  const jobComponents = jobs.map(item => {
    const props = { cancelJob, key: item.id, ...item };
    switch (item.type) {
      case 'publish':
        return (
          <JobQueueCard
            title="Product is Being Published..."
            description="This product is currently publishing and is locked for editing or publishing. Please check back later."
            startTimeTitle="Time in Stage"
            {...props}
          />
        );
      case 'update':
        return (
          <JobQueueCard
            title="Product is Being Updated..."
            description="This product is currently updating and is locked for editing or publishing. Please check back later."
            startTimeTitle="Time in Processing"
            {...props}
          />
        );
      case 'failure':
        return (
          <JobQueueCard
            title="Product Creation Failed."
            description="This product could not be created for the following reason: Revit file corrupted."
            {...props}
          />
        );
      default:
        return null;
    }
  });
  return <React.Fragment>{jobComponents}</React.Fragment>;
};

JobQueueContainer.propTypes = {
  jobs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      startTime: PropTypes.string,
      timeRemaining: PropTypes.number
    })
  ),
  dispatch: PropTypes.func.isRequired
};

JobQueueContainer.defaultProps = {
  jobs: []
};

const mapStateToProps = state => ({
  jobs: reducerUtil.getSlice(detailsData, detailsData.jobs, state)
});

export default connect(mapStateToProps)(JobQueueContainer);
