import React, { useState } from 'react';
import styled from 'styled-components';
import { Tab, TabList } from 'react-tabs';
import sbManagerStyles from 'utils/globalStyles';

import Card from 'shared/PageLayout/Card';
import Analytics from './Analytics';
import Messages from './Messages/Messages';
import Projects from './Projects';
import Events from './Events';

const MetadataSection = ({ events, tables, ...props }) => {
  const [selectedTab, setSelectedTab] = useState('Analytics');
  const tabs = ['Analytics', 'Messages', 'Projects', 'Activity'];
  const MetadataContainer = styled(Card)`
    width: 100%;
    display: flex;
    flex-direction: column;
  `;
  const SectionContainer = styled.div``;
  const getSection = () => {
    switch (selectedTab) {
      case 'Activity':
        return <Events data={events} />;
      case 'Projects':
        return <Projects />;
      case 'Messages':
        return <Messages />;
      case 'Analytics':
      default:
        return <Analytics {...tables} />;
    }
  };
  const TabSection = styled.div`
    .tab-list {
      padding-inline-start: 0px;
      display: flex;
      flex-direction: row;
      border-bottom: 1px solid ${() => sbManagerStyles.greyLight};
      margin-block-start: 0px;
      margin-block-end: 0px;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      li {
        padding-left: 2rem;
        padding-right: 2rem;
        padding-top: 2rem;
        outline: none;
        box-shadow: none;
        padding-bottom: 10px;
        text-decoration: none;
        display: block;
        transition: 0.3s;
        text-align: right;
        border-top: 0;
        border-left: 0;
        font-weight: 500;
        font-size: 1.6rem;
        cursor: pointer;
        &.react-tabs__tab--selected {
          border-radius: 0;
          border-bottom: 3px solid ${() => sbManagerStyles.blueDark};
          color: ${() => sbManagerStyles.blueDark};
          font-weight: 700;
          cursor: default;
          &:before {
            opacity: 0;
          }
        }
      }
    }
  `;
  return (
    <MetadataContainer>
      <TabSection>
        <TabList className="tab-list">
          {tabs.map((tab, index) => (
            <Tab selected={selectedTab === tab} onClick={() => setSelectedTab(tab)} key={index}>
              {tab}
            </Tab>
          ))}
        </TabList>
      </TabSection>
      <SectionContainer>{getSection()}</SectionContainer>
    </MetadataContainer>
  );
};

export default MetadataSection;
