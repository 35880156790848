import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from './../SvgIcon';

const AddBoxOutlineIcon = (props) => (
  <SvgIcon {...props}>
		<polygon points="11,17.001 13,17.001 13,13.001 17,13.001 17,11.001 13,11.001 13,7.001 11,7.001 
      11,11.001 7,11.001 7,13.001 11,13.001     "/>
    <path d="M19,3.001H5c-1.104,0-2,0.896-2,2v14c0,1.104,0.896,2,2,2h14c1.104,0,2-0.896,2-2v-14
      C21,3.897,20.104,3.001,19,3.001z M18.997,19.001H5v-14h14L18.997,19.001z"/>
  </SvgIcon>
)

AddBoxOutlineIcon.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  viewBox: PropTypes.string.isRequired,
};

AddBoxOutlineIcon.defaultProps = {
  width: 24,
  height: 24,
  viewBox: '0 0 24 24',
};

export default AddBoxOutlineIcon;