import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { reducerUtil } from 'base-client';

import { reducerData as permissionsData } from 'permissions';
import { actions as leadsActions, reducerData as leadsData } from 'leadsTwo';

import NavigationToParent from 'shared/Navigation/NavigationToParent';
import LeadCard from '../LeadCard';
import LeadStatus from './LeadStatus';
import MetadataSection from './MetadataSection';
import '../../assets/lead.scss';

class LeadProfile extends React.Component {
  static propTypes = {
    leadProfileData: PropTypes.object,
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string
      })
    }),
    canView: PropTypes.bool
  };
  static defaultProps = {
    canView: undefined
  };

  componentDidMount() {
    const { match, dispatch } = this.props;
    if (match && match.params) dispatch(leadsActions.getLeadData(match.params.id));
  }

  render() {
    const ProfileContainer = styled.div`
      display: flex;
      flex-direction: row;
    `;
    const LeftColumn = styled.div`
      max-height: 26rem;
      width: 20%;
    `;
    const RightColumn = styled.div`
      margin-left: 3rem;
      width: 80%;
      min-width: min-content;
      display: flex;
      flex-direction: column;
    `;
    const { canView, leadProfileData, lastQueryURL } = this.props;
    if (!canView || !leadProfileData) return null;
    const { events = [], tables = {}, user } = leadProfileData || {};
    return (
      <React.Fragment>
        <NavigationToParent
          text="Back to All Contacts"
          url={lastQueryURL ? `/insights/contacts?${lastQueryURL}` : '/insights/contacts'}
        />
        <ProfileContainer>
          <LeftColumn>
            <LeadCard user={user} />
          </LeftColumn>
          <RightColumn>
            <LeadStatus leadStage={user.leadStage} />
            <MetadataSection user={user} tables={tables} events={events} />
          </RightColumn>
        </ProfileContainer>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  leadProfileData: reducerUtil.getSlice(leadsData, leadsData.leadProfile, state),
  lastQueryURL: reducerUtil.getSlice(leadsData, leadsData.lastQueryURL, state),
  canView: reducerUtil.getSlice(permissionsData, permissionsData.leads, state)
});

export default withRouter(connect(mapStateToProps)(LeadProfile));
