import React from 'react';
import styled from 'styled-components';
import LeadCard from '../LeadCard';

const GridStyle = styled.div`
  padding: 0 1rem 2rem;
`;

const GridItem = ({ style, data, ...props }) => {
  return (
    <GridStyle style={style}>
      <LeadCard user={data} {...props} />
    </GridStyle>
  );
};

export default GridItem;
