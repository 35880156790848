import moment from 'moment';

const interval = {
  initial: {
    startDate: moment().subtract(29, 'days'),
    endDate: moment()
  },
  year: {
    startDate: moment().subtract(364, 'days'),
    endDate: moment()
  }
};

export { interval };
