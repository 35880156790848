import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import sbManagerStyles from 'utils/globalStyles';

const Container = styled.div`
  height: 1rem;
  width: 100%;
  border-radius: 0.5rem;
  margin: 1rem 0;
  position: relative;
  background-color: ${() => sbManagerStyles.greyLight};
`;

const Filter = styled.div`
  width: ${props => (props.failed ? '100%' : props.width ? props.width : '60%')};
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: ${props => (props.failed ? sbManagerStyles.red : sbManagerStyles.green)};
  border-radius: 0.5rem;
`;

// TODO: Style me! Color should change with failed!
const Bar = ({ percentage, failed }) => (
  <Container className="progress-bar">
    <Filter className="filler" width={`${percentage * 100}%`} failed={failed} />
  </Container>
);

Bar.propTypes = {
  percentage: PropTypes.number.isRequired,
  failed: PropTypes.bool
};

export default Bar;
