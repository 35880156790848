import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../SvgIcon';

const DashboardIcon = (props) => (
  <SvgIcon {...props} >
    <path id="shadow" className="color--light" d="M20.5,18.2H19v1.6c0,0.6-0.4,1-1,1H6c-0.6,0-1-0.4-1-1v-1.6h-1c-0.9,0-1.5,0.7-1.5,1.5v1.9
      c0,0.9,0.7,1.5,1.5,1.5h16.5c0.9,0,1.5-0.7,1.5-1.5v-1.9C22,18.9,21.3,18.2,20.5,18.2z"/>
    <g className="icon--active">
      <circle className="color--medium" cx="12" cy="11.8" r="2"/>
      <path className="color--medium" d="M12,14.8c-1.8,0-3,1.2-3,3h6C15,16,13.8,14.8,12,14.8z"/>
      <path className="color--dark" d="M21.4,9.4l-8-8C13,1,12.5,0.8,12,0.8S11,1,10.6,1.4l-8,8C1.9,10,1.8,11,2.3,11.8c0.4,0.6,1.1,1,1.8,1H5v7
        c0,0.6,0.4,1,1,1h12c0.6,0,1-0.4,1-1v-7h1c0.8,0,1.5-0.5,1.8-1.2C22.2,10.8,22,10,21.4,9.4z M9,17.8c0-1.8,1.2-3,3-3s3,1.2,3,3H9z
         M10,11.8c0-1.1,0.9-2,2-2s2,0.9,2,2s-0.9,2-2,2S10,12.9,10,11.8z"/>
    </g>
    <g className="icon--inactive">
      <path className="color--medium" d="M9,17.8c0-1.8,1.2-3,3-3l0,0c1.8,0,3,1.2,3,3H9z"/>
      <circle className="color--medium" cx="12" cy="11.8" r="2"/>
      <path className="color--medium" d="M18,20.8H6c-0.6,0-1-0.4-1-1v-7H4c-0.8,0-1.5-0.5-1.8-1.2C1.8,10.8,2,10,2.6,9.4l8-8C11,1,11.5,0.8,12,0.8
        l0,0c0.5,0,1,0.2,1.4,0.6l8,8c0.6,0.6,0.7,1.4,0.4,2.2c-0.3,0.8-1,1.2-1.8,1.2h-1v7C19,20.3,18.6,20.8,18,20.8z M7,18.8h10v-7
        c0-0.6,0.4-1,1-1h2l-8-8l-8,8l2,0c0.6,0,1,0.4,1,1V18.8z"/>
    </g>
  </SvgIcon>
)

DashboardIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

DashboardIcon.defaultProps = {
  width: 22,
  height: 22
};


export default DashboardIcon;
