import React from 'react';
import PropTypes from 'prop-types';
import { FormControl } from 'react-bootstrap';
import cn from 'classnames';

const Formula = ({ bsClass, value, onChange, disabled }) => (
  <React.Fragment>
    <FormControl
      {...{ value, onChange, disabled }}
      type="text"
      bsClass={cn('form-control', bsClass)}
      className="formula-active"
      placeholder="Enter a formula"
    />
    <span className="formula-equals">=</span>
  </React.Fragment>
);

Formula.propTypes = {
  bsClass: PropTypes.object,
  value: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool
};

export default Formula;
