export default {
  features: {
    name: "features",
    require_login:{
      name: "require_login",
      key: "manager.features.require_login",
      type: "bool",
    },
  }, 
  redirect: {
    name: "redirect",
    domain: {
      name: "domain",
      key: "manager.redirect.domain",
      type: "string",
    },
    routes: {
      name: "routes",
      key: "manager.redirect.routes",
      type: "string",
    },
    serviceKeyAPI: {
      name: "serviceKeyAPI",
      key: "manager.redirect.serviceKeyAPI",
      type: "string",
    },
    paramName: {
      name: "paramName",
      key: "manager.redirect.paramName",
      type: "string",
    },
  },
  logoutRedirect: {
    name: "logoutRedirect",
    enable: {
      name: "enable",
      key: "manager.logoutRedirect.enable",
      type: "bool",
    },
    paramName: {
      name: "paramName",
      key: "manager.logoutRedirect.paramName",
      type: "string",
    },
  },
  auth: {
    name: "auth",
    domain: {
      name: "domain",
      key: "auth0.domain",
      type: "string",
    },
    clientId: {
      name: "clientId",
      key: "manager.auth0.client.id",
      type: "string",
    },
    audience: {
      name: "audience",
      key: "auth0.manager_api_audience",
      type: "string",
    },
  },
  api: {
    name: "api",
    host: {
      name: "host",
      key: "api.endpoint.manager",
      type: "string",
    },
    gateway: {
      name: "gateway",
      key: "api.endpoint.gateway",
      type: "string",
    },
  },
  google: {
    name: "google",
    mapKey: {
      name: "mapKey",
      key: "google.mapKey",
      type: "string",
    },
  },
  agreement: {
    name: "agreement",
    terms: {
      name: "terms",
      key: "client.agreement.terms",
      type: "string",
    },
    privacy: {
      name: "privacy",
      key: "client.agreement.privacy",
      type: "string",
    },
  },
  disabled: {
    name: "disabled",
    tenant: {
      name: "tenant",
      key: "manager.disabled.tenant",
      type: "bool",
    },
    projects: {
      name: "projects",
      key: "manager.disabled.projects",
      type: "bool",
    },
    newSelectAll: {
      name: "newSelectAll",
      key: "manager.disabled.newSelectAll",
      type: "bool",
    },
    leadScoring: {
      name: "leadScoring",
      key: "manager.disabled.leadScoring",
      type: "bool",
    },
    sustainability: {
      name: "sustainability",
      key: "manager.disabled.sustainability",
      type: "bool",
    },
    projectTableCompany: {
      name: "projectTableCompany",
      key: "manager.disabled.projectTableCompany",
      type: "bool",
    },
    productFilters: {
      name: "productFilters",
      key: "manager.disabled.productFilters",
      type: "bool",
    },
  },
  lambda: {
    name: "lambda",
    publisher: {
      name: "publisher",
      key: "lambda.endpoint.publisher",
      type: "string",
    },
    getUploadUrl: {
      name: "getUploadUrl",
      key: "lambda.endpoint.getUploadUrl",
      type: "string",
    },
    headerCheck: {
      name: "headerCheck",
      key: "lambda.endpoint.headerCheck",
      type: "string",
    },
    importer: {
      name: "importer",
      key: "lambda.endpoint.importer",
      type: "string",
    },
    exporterAssets: {
      name: "exporterAssets",
      key: "lambda.endpoint.export-assets",
      type: "string",
    },
    exporterCsv: {
      name: "exporterCsv",
      key: "lambda.endpoint.export",
      type: "string",
    },
    concoraExternalAPI: {
      name: "concoraExternalAPI",
      key: "lambda.endpoint.concoraExternalAPI",
      type: "string",
    },
  },
};
