/* vendor imports */
import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, FormControl } from 'react-bootstrap';
import cn from 'classnames';

/* system imports */

const SelectField = ({ input, options, className, disabled }) => (
  <FormGroup controlId={input.name} bsClass={cn('form-group', className)}>
    <FormControl componentClass='select' {...input} {...{ disabled }}>
      {options.map(({ key, display }) => <option key={key} value={key}>{display || key}</option>)}
    </FormControl>
  </FormGroup>
);

SelectField.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  options: PropTypes.array.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

SelectField.defaultProps = {
  className: null,
  disabled: false,
};

export default SelectField;
