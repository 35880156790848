/* vendor imports */
import React, { PureComponent } from 'react';

/* system imports */
import config from '../utils/index';
import ChartIndex from '../components/ChartIndex';

class ChartContainer extends PureComponent {
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { namespace } = this.props;
    if (this[namespace]) {
      const chart = this[namespace].getChart();

      if (nextProps.data) {
        const { data } = nextProps;

        let nameValues = [];
        let yValues = [];

        for (let datum of data) {
          datum.name && nameValues.push(datum.name);
          datum.y && yValues.push(datum.y);
        }

        chart.series[0].setData(yValues);
        chart.xAxis[0].setCategories(nameValues);
        chart.hideLoading();
        chart.reflow();
      } else chart.showLoading();
    }
  }

  componentDidMount() {
    const { namespace } = this.props;
    if (this[namespace]) {
      const chart = this[namespace].getChart();

      if (this.props.data) {
        const { data } = this.props;

        let nameValues = [];
        let yValues = [];

        for (let datum of data) {
          datum.name && nameValues.push(datum.name);
          datum.y && yValues.push(datum.y);
        }

        chart.series[0].setData(yValues);
        chart.xAxis[0].setCategories(nameValues);
        chart.hideLoading();
        chart.reflow();
      } else chart.showLoading();
    }
  }

  render() {
    const { data } = this.props;
    const chartConfig = config;
    chartConfig.data = data;

    return <ChartIndex {...this.props} {...this.state} config={chartConfig} chartState={this} />;
  }
}

export default ChartContainer;
