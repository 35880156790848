import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { reducerUtil } from 'base-client';

import UserAccounts from 'shared/Admin/components/UserAccounts';
import { reducerData as permissionsData } from 'permissions';
import { actions as adminActions } from 'admin';

class UsersContainer extends React.Component {
  static propTypes = {
    getUsers: PropTypes.func.isRequired,
    canView: PropTypes.bool
  };
  static defaultProps = {
    canView: false
  };

  componentDidMount() {
    const { getUsers } = this.props;
    getUsers();
  }

  render() {
    const { canView } = this.props;
    if (!canView) return null;
    return <UserAccounts />;
  }
}

const mapStateToProps = state => ({
  canView: reducerUtil.getSlice(permissionsData, permissionsData.admin, state)
});

const mapDispatchToProps = dispatch => ({
  getUsers: () => dispatch(adminActions.getUserList())
});

export default connect(mapStateToProps, mapDispatchToProps)(UsersContainer);
