import React from 'react';
import PropTypes from 'prop-types';
import 'react-virtualized/styles.css';

import GridLoading from 'shared/Miscellaneous/Loading/ProductGridLoading';
import GridCell from './GridCell';
import GridView from './GridView';
import { GRID_COLUMN_WIDTH, GRID_ROW_HEIGHT } from '../constants';
import '../assets/product-grid.scss';

class GridViewContainer extends React.Component {
  static propTypes = {
    onRowsRendered: PropTypes.func.isRequired,
    width: PropTypes.number,
    productList: PropTypes.arrayOf(
      PropTypes.shape({
        productKey: PropTypes.string,
        name: PropTypes.string,
        manufacturer: PropTypes.string,
        category: PropTypes.string,
        subcategory: PropTypes.string,
        thumbnail: PropTypes.string
      })
    )
  };

  static defaultProps = {
    width: 0,
    productList: []
  };

  state = {};

  /** Convert the array into a matrix of rows and columns for react-virtualized */
  static getDerivedStateFromProps(props) {
    const { width, productList } = props;
    const length = productList ? productList.length : 0;
    const columnCount = Math.max(Math.floor(width / GRID_COLUMN_WIDTH), 1);
    const columnWidth = Math.floor(width / columnCount);
    const rowCount = Math.ceil(length / columnCount);
    const rowHeight = Math.round((GRID_ROW_HEIGHT * columnWidth) / GRID_COLUMN_WIDTH);

    return { columnCount, columnWidth, rowCount, rowHeight };
  }

  /** Rendering function for react-virtualized */
  onSectionRendered = ({ columnStartIndex, columnStopIndex, rowStartIndex, rowStopIndex }) => {
    const { onRowsRendered } = this.props;
    const { columnCount } = this.state;
    const startIndex = rowStartIndex * columnCount + columnStartIndex;
    const stopIndex = rowStopIndex * columnCount + columnStopIndex;

    onRowsRendered({ startIndex, stopIndex });
  };

  /** The cell renderer must be inside the container due to its dependency on props
   * that cannot be passed via react-virtualized (products, isRowLoaded, columnCount)
   */
  cellRenderer = ({ style, columnIndex, rowIndex }) => {
    const { productList } = this.props;
    const { columnCount } = this.state;
    const index = rowIndex * columnCount + columnIndex;
    const rowData = productList[index];

    if (rowData) {
      return (
        <div className="product-grid__item" {...{ key: rowData.id, style }}>
          <GridCell {...{ rowData }} />
        </div>
      );
    }

    return null;
  };

  render() {
    const { productList } = this.props;
    if (!productList) return <GridLoading />;

    const gridProps = {
      cellRenderer: this.cellRenderer,
      onSectionRendered: this.onSectionRendered
    };

    return <GridView {...this.props} {...this.state} {...gridProps} />;
  }
}

export default GridViewContainer;
