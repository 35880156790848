import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from './../SvgIcon';

const CheckStatusIcon = (props) => (
  <SvgIcon {...props}>
		<path d="M11.042,22c-4.962,0-9-4.037-9-9s4.038-9,9-9v2c-3.86,0-7,3.14-7,7c0,3.86,3.14,7,7,7s7-3.14,7-7
        h2C20.042,17.963,16.004,22,11.042,22z"/>
    <polygon points="12.134,16.86 7.158,11.884 8.925,10.116 11.949,13.14 20.074,3.208 22.009,4.792      "/>
  </SvgIcon>
)

CheckStatusIcon.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  viewBox: PropTypes.string.isRequired,
};

CheckStatusIcon.defaultProps = {
  width: 24,
  height: 24,
  viewBox: '0 0 24 24',
};

export default CheckStatusIcon;