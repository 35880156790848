import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../SvgIcon';

const AddIcon = props => (
  <SvgIcon {...props}>
    <path className="cls-1" d="M17,6H1V8H17Z" />
    <path className="cls-1" d="M17,3H1V5H17Z" />
    <path className="cls-1" d="M17,0H1V2H17Z" />
    <path className="cls-1" d="M11,9H7v2h4Z" />
    <path className="cls-2" d="M12,12.5h.5V10a.5.5,0,0,1,.5-.5h4a.5.5,0,0,1,.5.5v4A1.5,1.5,0,0,1,16,15.5H2A1.5,1.5,0,0,1,.5,14V10A.5.5,0,0,1,1,9.5H5a.5.5,0,0,1,.5.5v2.5H12Z" />
  </SvgIcon>
);

AddIcon.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  viewBox: PropTypes.string.isRequired
};

AddIcon.defaultProps = {
  width: 24,
  height: 24,
  viewBox: '0 0 18 16'
};

export default AddIcon;
