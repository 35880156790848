import _ from 'lodash';
import timeUtils from 'utils/timeUtils';
import { defaultMomentObjInterval } from 'shared-features-client';

/* Functions */

const insightBody = ({ tick, interval, user_id, key, download }) => {
  const { startDate, endDate } = interval || defaultMomentObjInterval;

  return JSON.stringify({
    interval: tick,
    dateRange: {
      start: startDate.toISOString(),
      end: endDate.toISOString()
    },
    product_id: key,
    user_id,
    export: download
  });
};

const chooseTick = (interval, count) => {
  const { startDate, endDate } = interval;
  const days = endDate.diff(startDate, 'days') + 1;
  if (days > 365 * count) return 'year';
  if (days > 30 * count) return 'month';
  if (days > 7 * count) return 'week';
  return 'day';
};

const productChart = (data, tick) => {
  if (!data || !tick) return null;
  const chart = {
    xAxis: [],
    data: []
  };
  data.forEach(datum => {
    const date = new Date(datum.interval);
    chart.xAxis.push(timeUtils.formatAs(timeUtils.formats.CHART, date, tick));
    chart.data.push(parseInt(datum.count));
  });
  return chart;
};

const productInfoNumber = data =>
  (data && data.length > 0 && _.sumBy(data, datum => parseInt(datum.count))) || null;

export default {
  insightBody,
  chooseTick,
  productChart,
  productInfoNumber
};
