import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../SvgIcon';

const ProjectUpdatedIcon = props => (
  <SvgIcon {...props}>
    <path
      d="M18.8209 1.17995C18.0599 0.419953 17.0499 0.00195312 15.9739 0.00195312C14.8999 0.00195312 13.8879 0.419953 13.1279 1.18095L1.71994 12.59C1.59194 12.718 1.49994 12.879 1.45694 13.054L0.0299365 18.759C-0.0540635 19.099 0.0459364 19.461 0.293936 19.707C0.540936 19.956 0.901937 20.056 1.24294 19.971L6.94794 18.544C7.12294 18.501 7.28394 18.409 7.41194 18.281L18.8199 6.87195C19.5799 6.11195 19.9979 5.09995 19.9989 4.02595C19.9989 2.94995 19.5809 1.93895 18.8199 1.17895L18.8209 1.17995ZM7.07694 14.338L14.5479 6.86695L15.2749 7.59195L7.31994 15.547L7.07694 14.338ZM5.66294 12.923L4.45394 12.682L12.4099 4.72695L13.1349 5.45295L5.66294 12.923ZM3.16594 14.464L5.14194 14.859L5.53794 16.836L2.37494 17.627L3.16594 14.464ZM13.8229 3.31295L14.5429 2.59495C15.3089 1.83095 16.6429 1.83095 17.4069 2.59495L17.4049 2.59395C18.1699 3.35895 18.1699 4.69295 17.4049 5.45795L16.6869 6.17795L13.8229 3.31295Z"
      fill="#6ABF43"
    />
  </SvgIcon>
);

ProjectUpdatedIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
};

ProjectUpdatedIcon.defaultProps = {
  width: 20,
  height: 20
};

export default ProjectUpdatedIcon;
