import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import sbManagerStyles from 'utils/globalStyles';
import { DeleteIcon } from 'shared/Miscellaneous/Icons';
import Label from './Label';

const StyledCheckbox = styled.div`
  padding-bottom: 1rem;
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  &.right {
    label {
      margin-right: 1rem;
      padding-right: 0;
      &:before {
        right: -2.7rem;
      }
      &:after {
        right: -2.4rem;
      }
    }
    input {
      padding-right: 1rem;
    }
  }
  &.left {
    label {
      margin-left: 1rem;
      &:before {
        left: -2.7rem;
      }
      &:after {
        left: -2.4rem;
      }
    }
  }
  label {
    position: relative;
    cursor: pointer;
    &:before {
      content: '';
      display: inline-block;
      height: 1.6rem;
      width: 1.6rem;
      background: ${sbManagerStyles.white};
      box-shadow: inset 0 0 0 0.2rem ${sbManagerStyles.greyLight};
      border-radius: 0.3rem;
      position: absolute;
      bottom: 1px;
    }
    &:after {
      content: none;
      display: inline-block;
      height: 0.6rem;
      width: 1rem;
      border-left: 0.2rem solid ${sbManagerStyles.white};
      border-bottom: 0.2rem solid ${sbManagerStyles.white};
      transform: rotate(-45deg);
      position: absolute;
      bottom: 0.8rem;
    }
  }
  &.checked {
    label {
      &:before {
        background: ${sbManagerStyles.greyMedium};
        box-shadow: none;
      }
      &:after {
        content: '';
      }
    }
  }
  &:hover label:before {
    box-shadow: inset 0 0 0 0.2rem ${sbManagerStyles.greyMedium};
  }
`;

const InputWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-right: 1rem;
  ${props => (props.leftMargin ? 'margin-left: 2rem;' : '')}
  input {
    display: none;
  }
`;

const Checkbox = ({ inputPos = 'left', labelText, handleOnChange, onDelete, input = {} }) => {
  const { name, value, onChange } = input;

  const newInput = {
    ...input,
    onChange: () => {
      onChange(!value);
      handleOnChange(name);
    }
  };

  const getContent = () => {
    if (inputPos === 'right') {
      return (
        <InputWrapper>
          <Label htmlFor={name}>{labelText}</Label>
          <input type="checkbox" {...newInput} id={name} checked={value} />
        </InputWrapper>
      );
    }
    return (
      <InputWrapper leftMargin>
        <input type="checkbox" {...newInput} id={name} checked={value} />
        <Label htmlFor={name}>{labelText}</Label>
      </InputWrapper>
    );
  };

  return (
    <StyledCheckbox className={`${inputPos} ${value && 'checked'}`}>
      {getContent()}
      {onDelete && (
        <DeleteIcon
          onClick={onDelete}
          addClass="icon--neutral icon--hover-danger"
          width={28}
          height={28}
          viewBox="0 0 28 34"
        />
      )}
    </StyledCheckbox>
  );
};

Checkbox.propTypes = {
  inputPos: PropTypes.string,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.bool
  }),
  handleOnChange: PropTypes.func.isRequired
};

export default Checkbox;
