import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { FormControl } from 'react-bootstrap';
import cn from 'classnames';
import { connect } from 'react-redux';
import { reducerUtil } from 'base-client';

import FormulaContainer from './FormulaContainer';
import { reducerData as detailsData } from 'productDetails';

const List = props => (
  <Field {...props} component={FormulaContainer}>
    <ListField />
  </Field>
);

const ListField = ({ bsClass, assetList, asset, list, value, disabled, onChange }) => {
  const options = asset ? assetList : list;
  return (
    <FormControl
      {...{ value, onChange, disabled }}
      componentClass="select"
      bsClass={cn('form-control', bsClass)}
    >
      {options.map(({ id, filename }) => (
        <option key={`${id}${filename}`} value={id}>
          {filename}
        </option>
      ))}
    </FormControl>
  );
};

ListField.propTypes = {
  assetList: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
      mimetype: PropTypes.string
    })
  ),
  list: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string
    })
  ).isRequired,
  bsClass: PropTypes.object,
  asset: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool
};

ListField.defaultProps = {
  assetList: [],
  list: []
};

const mapStateToProps = state => ({
  assetList: reducerUtil.getSlice(detailsData, detailsData.assetList, state)
});

export default connect(mapStateToProps)(List);
