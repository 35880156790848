/* vendor imports */
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { CardHeader } from './';
import './assets/card.scss';

const CardIndex = ({
  children,
  padded,
  title,
  subtitle,
  headerBorder,
  headerControls,
  headerPadding,
  hideHeader,
  className
}) => {
  const classNames = cn(className, 'card', { 'card--padded': padded });
  const isHeader = (title || headerControls) && !hideHeader;

  return (
    <div className={classNames}>
      {isHeader && (
        <CardHeader
          title={title}
          subtitle={subtitle}
          isBordered={headerBorder}
          controls={headerControls}
          slimPadding={headerPadding}
        />
      )}
      {children}
    </div>
  );
};

CardIndex.propTypes = {
  padded: PropTypes.bool,
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  headerBorder: PropTypes.bool,
  headerPadding: PropTypes.bool,
  headerControls: PropTypes.node
};

CardIndex.defaultProps = {
  title: null,
  subtitle: null,
  headerControls: null,
  headerBorder: false,
  headerPadding: false,
  padded: false
};

export default CardIndex;
