import { config } from "base-client";
import { configMap } from "configurations";
import { useEffect } from "react";
import { sendNotifications } from "shared/Toast";

import {
  parseRedirectRoutes,
  getTokenAndServiceKeyFromLocalStorage,
  setServiceKeyAndTokenPairInLocalStorage,
} from "./utils";

import { serviceKeyServices } from "./services";

export const useRedirects = ({ dispatch }) => {
  const { domain, routes, serviceKeyAPI, paramName } = dispatch(
    config.actions.getData(configMap.redirect.name)
  );

  const parsedRoutes = parseRedirectRoutes({ routes });
  const fromRoute = window.location.pathname;
  const toRouteIndex = parsedRoutes.findIndex((x) => x?.from === fromRoute);
  const toRoute = parsedRoutes[toRouteIndex]?.to;

  const redirect = (paramValue) => {
    const url = new URL(domain + toRoute);
    url.searchParams.append(paramName, paramValue);
    window.location.href = url.toString();
  };

  useEffect(() => {
    if (!toRoute) {
      return;
    }

    const { token: savedToken, serviceKey: savedServiceKey } =
      getTokenAndServiceKeyFromLocalStorage();

    if (savedServiceKey) {
      redirect(savedServiceKey);
      return;
    }

    serviceKeyServices
      .fetchServiceKey({
        token: savedToken,
        serviceKeyAPI,
      })
      .then((newServiceKey) => {
        setServiceKeyAndTokenPairInLocalStorage({
          token: savedToken,
          serviceKey: newServiceKey,
        });
        redirect(newServiceKey);
      })
      .catch(() => {
        dispatch(
          sendNotifications([
            {
              type: "error",
              message:
                "Service key could not be fetched. Redirecting without silent login.",
              overrideProps: {
                onClose: () => {
                  redirect(null);
                },
              },
            },
          ])
        );
      });
  }, [fromRoute]);

  return {
    shouldRedirect: !!toRoute,
  };
};
