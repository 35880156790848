/* vendor imports */
import React from "react";
import PropTypes from "prop-types";

/* system imports */
import { Manual, Upload, List, CsvResult, Select } from "../components";
import { pages } from "../../../utils";

const Container = ({ page, ...pageProps }) => {
  switch (page) {
    case pages.manual:
      return <Manual {...pageProps} />;
    case pages.upload:
      return <Upload {...pageProps} />;
    case pages.list:
      return <List {...pageProps} />;
    case pages.csvResult:
      return <CsvResult {...pageProps} />;
    default:
      return <Select {...pageProps} />;
  }
};

Container.propTypes = {
  page: PropTypes.string
};

Container.defaultProps = {
  page: pages.select
};

export default Container;
