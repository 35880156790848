import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Tabs } from 'react-bootstrap';
import { reducerUtil, config } from 'base-client';

import Header from './Header';
import JobQueueContainer from './JobQueueContainer';
import EditContainer from './EditContainer';
import InsightsContainer from './InsightsContainer';
import Revit from './Revit';
import FormContainer from './FormContainer';
import DetailsTab from './DetailsTab';
import ElementList from './ElementList';
import ProductLoading from 'shared/Miscellaneous/Loading/ProductLoading';
import { Page404 } from 'shared/404';
import ProductAttributes from 'productAttributes';
import Upload from './Upload';
import AssetsContainer from './Assets';
import Sustainability from './Sustainability';

import { actions as detailsActions, reducerData as detailsData } from 'productDetails';
import detailsUtils from 'productDetails/utils';
import { reducerData as attributesData } from 'productAttributes';
import { reducerData as permissionsData } from 'permissions';

import { configMap } from 'configurations';

import '../../styles/global/_input.scss';
import '../assets/products.scss';

class DetailsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.handleNavChange = this.handleNavChange.bind(this);
  }

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    canView: PropTypes.bool,
    attributes: PropTypes.arrayOf(PropTypes.shape({ group: PropTypes.string })),
    match: PropTypes.shape({
      params: PropTypes.shape({ id: PropTypes.string.isRequired })
    }),
    status: PropTypes.shape({ failed: PropTypes.bool }),
    jobs: PropTypes.array,
    elements: PropTypes.arrayOf(PropTypes.shape({ list: PropTypes.array, id: PropTypes.string })),
    information: PropTypes.array,
    assets: PropTypes.array
  };
  static defaultProps = {
    attributes: [],
    match: {}
  };

  state = { activeKey: 0, navKey: 0, elementIndex: 0, selectedNav: '' };

  componentDidMount() {
    this.startUp();
  }
  componentDidUpdate() {
    this.startUp();
  }

  startUp = () => {
    const { match, dispatch } = this.props;
    const { id } = match.params;
    const { id: lastId } = this.state;
    if (id !== lastId) {
      this.setState({ id });
      dispatch(detailsActions.getProduct(id));
    }
  };

  buildNavList = (attributesList, onRevit) => {
    const { attributes } = this.props;

    const groups = attributes
      .filter(item => attributesList.includes(item.id))
      .map(item => (onRevit && item.revitDistribution ? item.revitDistribution.group : item.group))
      .filter((item, index, array) => array.indexOf(item) === index);
    if (attributesList.length > 0) groups.unshift(detailsUtils.allGroup);

    const navList = groups.map(group => ({
      name: onRevit ? detailsUtils.fixName(group) : group,
      attributes: attributesList.filter(id => {
        const attribute = attributes.find(item => item.id === id);
        const itemGroup =
          onRevit && attribute.revitDistribution
            ? attribute.revitDistribution.group
            : attribute.group;
        return group === detailsUtils.allGroup || group === itemGroup;
      })
    }));

    return navList;
  };

  buildAssetTabNavList = assets => {
    if (!assets) return null;

    let assetNavList = [];

    if (assets) {
      assetNavList.push('All');

      assets.map(asset => {
        if (!asset.group) {
          asset.group = 'Uncategorized';
        }
        assetNavList.push(asset.group);
        return assetNavList;
      });
    }

    assetNavList = [...new Set(assetNavList)];

    assetNavList = assetNavList.map(assetGroup => {
      return { group: assetGroup };
    });

    return assetNavList;
  };

  onSelectAttribute = id => {
    const { openAttribute, activeKey, elementIndex } = this.state;
    if (openAttribute) {
      openAttribute({ selectedKey: id, onRevit: activeKey === 1, index: elementIndex });
    }
  };
  setOpenAttribute = openAttribute => {
    this.setState({ openAttribute });
  };

  handleNavChange = value => {
    this.setState({ selectedNav: value });
  };

  onSelectTab = activeKey => this.setState({ activeKey, navKey: 0 });
  onSelectNav = navKey => this.setState({ navKey });

  onSelectElement = event => {
    const {
      target: { value: elementIndex }
    } = event;
    const { elements } = this.props;
    const { elementIndex: lastIndex, navKey: lastNavKey } = this.state;

    const lastAttributesList = (elements[lastIndex] && elements[lastIndex].list) || [];
    const lastNavList = this.buildNavList(lastAttributesList, true);

    const attributesList = (elements[elementIndex] && elements[elementIndex].list) || [];
    const navList = this.buildNavList(attributesList, true);

    const { name: lastNavName } = lastNavList[lastNavKey] || {};
    const navKey = Math.max(
      navList.findIndex(({ name }) => lastNavName === name),
      0
    );

    this.setState({ elementIndex, navKey });
  };

  onRemoveElement = () => {
    const { dispatch } = this.props;
    const { elementIndex } = this.state;
    dispatch(detailsActions.removeElement(elementIndex));
    this.setState({ elementIndex: 0 });
  };

  render() {
    const { status, jobs, elements, information, canView, assets, dispatch } = this.props;

    if (!canView) return null;
    if (!status) return <ProductLoading />;
    if (status.failed) return <Page404 />;

    const { activeKey, navKey, elementIndex } = this.state;

    const formAttributes = [...information];
    if (elements[elementIndex]) formAttributes.push(...elements[elementIndex].list);

    const disabled = jobs && jobs.length > 0;

    const onRevit = activeKey === 1;
    const AssetTabKey = 3;
    const isOnAssetTab = activeKey === AssetTabKey;
    const assetTabNavItems = this.buildAssetTabNavList(assets);

    const attributesList =
      (onRevit ? elements[elementIndex] && elements[elementIndex].list : information) || [];
    const navList = this.buildNavList(attributesList, onRevit);

    const detailProps = {
      onSelectAttribute: this.onSelectAttribute,
      onSelectNav: this.onSelectNav,
      onSelectNavItem: this.onSelectNavItem,
      activeKey,
      navKey,
      onRevit
    };

    const disabledSustainability = dispatch(
      config.actions.getData(configMap.disabled.name, configMap.disabled.sustainability.name)
    );

    return (
      <Upload>
        <FormContainer>
          <Header />
          <JobQueueContainer />
          <EditContainer>
            <Tabs id="productEdit" onSelect={this.onSelectTab} {...{ activeKey }}>
              <DetailsTab
                eventKey={0}
                title="Product Information"
                section={detailsUtils.form.product}
                navList={(!onRevit && navList) || []}
                {...detailProps}
              />
              <DetailsTab
                eventKey={1}
                title="Revit"
                section={elements[elementIndex] && elements[elementIndex].id}
                navList={(onRevit && navList) || []}
                disableAttribute={disabled}
                {...detailProps}
              >
                <Revit {...{ disabled }} />
                {elements.length > 0 && (
                  <ElementList
                    switchElement={this.onSelectElement}
                    removeElement={this.onRemoveElement}
                    {...{ elements, elementIndex }}
                  />
                )}
              </DetailsTab>
              {!disabledSustainability && (
                <DetailsTab eventKey={2} title="Sustainability" navList={[]} {...detailProps}>
                  <Sustainability />
                </DetailsTab>
              )}
              <DetailsTab
                eventKey={AssetTabKey}
                title={`Assets (${assets ? assets.length : 0})`}
                navList={(isOnAssetTab && assetTabNavItems) || []}
                navChange={this.handleNavChange}
                {...detailProps}
              >
                <AssetsContainer selectedNav={this.state.selectedNav} />
              </DetailsTab>
            </Tabs>
          </EditContainer>
        </FormContainer>
        <InsightsContainer />
        <ProductAttributes openAttributeRef={this.setOpenAttribute} {...{ formAttributes }} />
      </Upload>
    );
  }
}

const mapStateToProps = state => ({
  canView: reducerUtil.getSlice(permissionsData, permissionsData.manager, state),
  attributes: reducerUtil.getSlice(attributesData, attributesData.attributes, state),
  status: reducerUtil.getSlice(detailsData, detailsData.status, state),
  elements: reducerUtil.getSlice(detailsData, detailsData.elements, state),
  information: reducerUtil.getSlice(detailsData, detailsData.information, state),
  jobs: reducerUtil.getSlice(detailsData, detailsData.jobs, state),
  assets: reducerUtil.getSlice(detailsData, detailsData.assets, state)
});

export default withRouter(connect(mapStateToProps)(DetailsContainer));
