import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from './../SvgIcon';

const BoxIcon = (props) => (
  <SvgIcon {...props}>
		<path d="M 0 4C 0 1.79086 1.79086 0 4 0L 14 0C 16.2091 0 18 1.79086 18 4L 18 14C 18 16.2091 16.2091 18 14 18L 4 18C 1.79086 18 0 16.2091 0 14L 0 4Z"/>
    <path d="M 0 2C 0 0.895431 0.895431 0 2 0L 12 0C 13.1046 0 14 0.895431 14 2L 14 12C 14 13.1046 13.1046 14 12 14L 2 14C 0.895431 14 0 13.1046 0 12L 0 2Z" transform='translate(2 2)' fill='#FFFFFF'/>
  </SvgIcon>
)

BoxIcon.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  viewBox: PropTypes.string.isRequired,
};

BoxIcon.defaultProps = {
  width: 24,
  height: 24,
  viewBox: '0 0 24 24',
};

export default BoxIcon;