import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import sbManagerStyles from 'utils/globalStyles';

export const Container = styled.div`
  margin-bottom: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h1`
  font-size: 2.3rem;
  line-height: 1;
`;

const Subtitle = styled.div`
  margin: 5px 0 0;
  color: ${() => sbManagerStyles.greyMedium};
  font-size: 1.4rem;
`;

const PageHeader = ({ title, subtitle, children, className }) => (
  <Container {...{ className }}>
    <Header>
      <Title>{title}</Title>
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
    </Header>
    {children}
  </Container>
);

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.any,
  children: PropTypes.any
};

export default PageHeader;
