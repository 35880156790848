import React from 'react';
import PropTypes from 'prop-types';

import { Row, Column, Card } from 'shared-features-client';

import { actions as projectsAnalyticsActions } from 'projectsAnalytics';

import { AnalyticsFilters } from 'shared/Filters';
import { AnalyticsCard, AnalyticsDonutCard, analyticUtils } from 'shared/analytics';

class ProjectsAnalytics extends React.Component {
  containerRef = React.createRef();
  state = {};

  onResize = () => {
    const { current } = this.containerRef;
    if (!current) return;
    const { width } = current.getBoundingClientRect();
    this.setState({ cardWidth: width / 3 - 20, cardHeight: 340 });
  };

  componentDidMount() {
    window.addEventListener('resize', this.onResize);
  }

  componentDidUpdate() {
    const { data } = this.props;
    const { cardWidth, cardHeight } = this.state;
    if (data && !cardWidth && !cardHeight) {
      this.onResize();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  updateFilter = ({ attribute, facets }) => {
    const { dispatch } = this.props;
    dispatch(projectsAnalyticsActions.updateFilter({ attribute, facets }));
  };

  render() {
    const { data, metaAnalytics, query } = this.props;

    if (!data) {
      return null;
    }

    const { locations = {} } = metaAnalytics;

    const locationsProps = {
      ...locations,
      updateFilter: this.updateFilter
    };

    const { days } = analyticUtils.getDatetimeInfor(query);

    const { cardWidth, cardHeight } = this.state;
    return (
      <React.Fragment>
        <Column mdUp={3}>
          <Card>
            <AnalyticsFilters {...{ locations: locationsProps, viewToggle: false }} />
          </Card>
        </Column>
        <Column mdUp={9}>
          <div ref={this.containerRef}>
            <Row>
              <Column>
                <AnalyticsCard
                  title="Projects Updated"
                  subtitle="All Projects Updated"
                  data={data.projectsUpdated}
                  {...{ days }}
                />
              </Column>
              <Column>
                <AnalyticsDonutCard
                  title="Project Phase"
                  data={data.projectPhases}
                  containerWidth={cardWidth}
                  containerHeight={cardHeight}
                />
              </Column>
              <Column>
                <AnalyticsCard
                  title="Average Budget"
                  prefix="$"
                  subtitle="For all Projects"
                  data={data.averageBudget}
                  {...{ days }}
                />
              </Column>
            </Row>
            <Row>
              <Column>
                <AnalyticsCard
                  title="Average Size"
                  ext="sqft"
                  subtitle="For all Projects"
                  data={data.averageSize}
                  {...{ days }}
                />
              </Column>
              <Column>
                <AnalyticsCard
                  title="High Value Customers"
                  subtitle="Users With >1 Project"
                  data={data.highValueCustomers}
                  {...{ days }}
                />
              </Column>
              <Column>
                <AnalyticsDonutCard
                  title="Top 5 Project Types"
                  data={data.topProjectTypes}
                  containerWidth={cardWidth}
                  containerHeight={cardHeight}
                />
              </Column>
            </Row>
          </div>
        </Column>
      </React.Fragment>
    );
  }
}

ProjectsAnalytics.propTypes = {
  data: PropTypes.shape({
    projectsUpdated: PropTypes.shape({
      count: PropTypes.number,
      change: PropTypes.number,
      trend: PropTypes.string
    }),
    averageBudget: PropTypes.shape({
      count: PropTypes.number,
      change: PropTypes.number,
      trend: PropTypes.string
    }),
    averageSize: PropTypes.shape({
      count: PropTypes.number,
      change: PropTypes.number,
      trend: PropTypes.string
    }),
    highValueCustomers: PropTypes.shape({
      count: PropTypes.number,
      change: PropTypes.number,
      trend: PropTypes.string
    }),
    projectPhases: PropTypes.arrayOf(
      PropTypes.shape({
        count: PropTypes.number,
        name: PropTypes.string
      })
    ),
    topProjectTypes: PropTypes.arrayOf(
      PropTypes.shape({
        count: PropTypes.number,
        name: PropTypes.string
      })
    )
  }),
  metaAnalytics: PropTypes.shape({
    locations: PropTypes.shape({
      selected: PropTypes.arrayOf(PropTypes.string),
      name: PropTypes.string,
      attribute: PropTypes.string,
      facets: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
          label: PropTypes.string
        })
      )
    })
  }),
  query: PropTypes.shape({
    filters: PropTypes.arrayOf(
      PropTypes.shape({
        attribute: PropTypes.string,
        facets: PropTypes.arrayOf(PropTypes.string)
      })
    ),
    dateRange: PropTypes.shape({
      start: PropTypes.string,
      end: PropTypes.string
    })
  })
};
ProjectsAnalytics.defaultProps = {};

export default ProjectsAnalytics;
