import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../SvgIcon';

const InputIcon = props => (
  <SvgIcon {...props}>
    <g transform="translate(-1269.000000, -10645.000000)" className="color--light">
      <g transform="translate(1269.000000, 10644.000000)">
        <path d="M381,323.7 L296.2,237.8 C310,213.7 317.2,186.9 317.2,159.9 C317.2,72.3 246,1 158.6,1 C71.2,1 0,72.3 0,159.9 C0,247.5 71.2,318.8 158.6,318.8 C186.5,318.8 214.1,311.1 238.7,296.4 L323.1,382 C325,383.9 327.7,385.1 330.4,385.1 C333.1,385.1 335.8,384 337.7,382 L381,338.2 C385,334.1 385,327.7 381,323.7 L381,323.7 Z M158.6,62.9 C212,62.9 255.4,106.4 255.4,159.9 C255.4,213.4 212,256.9 158.6,256.9 C105.2,256.9 61.8,213.4 61.8,159.9 C61.8,106.4 105.2,62.9 158.6,62.9 L158.6,62.9 Z" />
      </g>
    </g>
  </SvgIcon>
);

InputIcon.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  viewBox: PropTypes.string.isRequired
};

InputIcon.defaultProps = {
  width: 14,
  height: 14,
  viewBox: '0 0 384 385'
};

export default InputIcon;
