import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import uuid from 'uuid';
import moment from 'moment';

import { reducerUtil } from 'base-client';
import { actions as messagesActions, reducerData as messagesData } from 'directMessages';
import { messageTypes } from 'directMessages/utils';
import sbManagerStyles from 'utils/globalStyles';

import { PageHeader } from 'shared';
import Card from 'shared/Card';
import { defaultMomentObjInterval } from 'shared-features-client';

import DirectMessagesTable from './DirectMessagesTable';

const Container = styled.div`
  .ReactVirtualized__Table__rowColumn {
    white-space: nowrap;
    a {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      cursor: pointer;
      &:hover {
        color: ${() => sbManagerStyles.blueDark} !important;
      }
    }
  }
`;

const defaultState = {
  startDate: defaultMomentObjInterval.startDate.clone(),
  endDate: defaultMomentObjInterval.endDate.clone(),
  messageType: null,
  page: 1
};

class DirectMessages extends React.Component {
  refresh = true;
  constructor(props) {
    super(props);
    this.state = {
      interval: defaultMomentObjInterval,
      id: uuid(),
      stream: messageTypes[0].label,
      messageType: messageTypes[0].id,
      page: 1
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(messagesActions.getMessages(defaultState));
    this.refresh = false;
  }

  componentDidUpdate() {
    if (this.refresh) {
      this.refreshTable();
    }
  }

  refreshTable = () => {
    const { dispatch } = this.props;
    let {
      interval: { startDate, endDate },
      messageType,
      page
    } = this.state;

    if (messageType === 'all') {
      messageType = null;
    }

    dispatch(messagesActions.getMessages({ startDate, endDate, messageType, page }));
    this.refresh = false;
  };

  onSelectStream = e => {
    for (let i = 0; i < messageTypes.length; i++) {
      let mt = messageTypes[i];

      if (mt.label === e.target.value) {
        const newState = {
          ...this.state,
          messageType: mt.id,
          stream: mt.label,
          page: 1
        };
        this.setState(newState);
        return (this.refresh = true);
      }
    }
  };

  onSelectInterval = ({ startDate, endDate }) => {
    const newState = {
      ...this.state,
      stream: messageTypes[0].label,
      messageType: messageTypes[0].id,
      interval: { startDate: moment(startDate), endDate: moment(endDate) },
      page: 1
    };
    this.setState(newState);
    this.refresh = true;
  };

  getNextPage = () => {
    const { page: currentPage } = this.state;
    const newState = {
      ...this.state,
      page: currentPage + 1
    };
    this.setState(newState);
    this.refresh = true;
  };

  render() {
    const { interval, stream, id: controlId } = this.state;
    const { directMessages, fetchId } = this.props;
    const headerProps = {
      onSelectStream: this.onSelectStream,
      onSelectInterval: this.onSelectInterval,
      stream,
      interval,
      controlId,
      optionsStream: messageTypes.map(o => o.label)
    };

    if (!directMessages.emails) {
      return null;
    }

    return (
      <Container>
        <PageHeader title="Direct Messages" />
        <Card {...{ headerProps }}>
          <DirectMessagesTable
            data={directMessages.emails}
            pagination={directMessages.pagination}
            getNextPage={this.getNextPage}
            fetchId={fetchId}
          />
        </Card>
      </Container>
    );
  }
}

DirectMessages.propTypes = {
  directMessages: PropTypes.object,
  dispatch: PropTypes.func.isRequired
};
DirectMessages.defaultProps = {
  directMessages: {}
};

const mapStateToProps = state => ({
  fetchId: reducerUtil.getSlice(messagesData, messagesData.fetchId, state),
  directMessages: reducerUtil.getSlice(messagesData, messagesData.messages, state)
});

export default connect(mapStateToProps)(DirectMessages);
