import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

import { Rows } from 'shared/TablesAndLists';

const StyledTooltip = styled.div`
  position: relative;
  .tooltip {
    opacity: 0;
    left: 0;
    width: auto;
    top: -40px;
    &:before {
      top: 26px;
    }
  }
  a:hover ~ .tooltip {
    opacity: 1;
  }
`;

const Tooltip = props => {
  const [isOverflow, setOverflow] = useState(false);

  const textElement = useRef(null);

  useEffect(() => {
    setOverflow(
      (textElement &&
        textElement.current &&
        textElement.current.scrollWidth > textElement.current.clientWidth) ||
        textElement.current.firstElementChild.scrollWidth >
          textElement.current.firstElementChild.clientWidth ||
        textElement.current.lastElementChild.scrollWidth >
          textElement.current.lastElementChild.clientWidth
    );
  }, []);

  return (
    <StyledTooltip ref={textElement}>
      <Rows.Link {...props} />
      {props.dataKey === 'name' && isOverflow && <div className="tooltip">{props.cellData}</div>}
    </StyledTooltip>
  );
};

export default Tooltip;
