import React from 'react';
import styled from 'styled-components';
import 'react-virtualized/styles.css';
import { Grid } from 'react-virtualized';
import uuid from 'uuid';
import GridItem from './GridItem';
import LeadCard from '../LeadCard';
import { GRID_COLUMN_WIDTH, GRID_ROW_HEIGHT } from '../../constants';

const GridViewContainer = props => {
  const { onRowsRendered, users, width } = props;
  const length = users ? users.length : 0;
  const columnCount = Math.max(Math.floor(width / GRID_COLUMN_WIDTH), 1);
  const columnWidth = Math.floor(width / columnCount);
  const rowCount = Math.ceil(length / columnCount);
  const rowHeight = Math.round((GRID_ROW_HEIGHT * columnWidth) / GRID_COLUMN_WIDTH);

  const onSectionRendered = ({
    columnStartIndex,
    columnStopIndex,
    rowStartIndex,
    rowStopIndex
  }) => {
    const startIndex = rowStartIndex * columnCount + columnStartIndex;
    const stopIndex = rowStopIndex * columnCount + columnStopIndex;

    onRowsRendered({ startIndex, stopIndex });
  };

  const cellRenderer = ({ style, columnIndex, rowIndex }) => {
    const index = rowIndex * columnCount + columnIndex;
    const user = users[index];
    if (user) {
      return <GridItem {...{ style, data: user, key: uuid.v4(), showScore: true }} {...props} />;
    }

    return null;
  };

  const GridView = ({ registerChild, ...gridProps }) => (
    <div>
      <Grid {...gridProps} ref={registerChild} />
    </div>
  );

  const gridProps = {
    cellRenderer,
    onSectionRendered,
    columnCount,
    columnWidth,
    rowCount,
    rowHeight
  };

  return <GridView {...props} {...gridProps} />;
};

export default GridViewContainer;
