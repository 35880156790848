import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Modal } from 'react-bootstrap';
import * as ModalDialog from 'react-bootstrap/lib/ModalDialog';
import Draggable from 'react-draggable';

import { CheckIcon, AlertIcon, CloseCircleIcon } from '../../../Miscellaneous/Icons/index';

import '../../../../styles/global/modal.scss';

class DraggableModalDialog extends React.Component {
  render() {
    return (
      <Draggable handle=".modal-title">
        <ModalDialog {...this.props} />
      </Draggable>
    );
  }
}

const ModalView = ({
  children,
  footer,
  handleHide,
  header,
  name,
  noClose,
  onClose,
  show,
  modalStyle,
  titleIcon
}) => {
  const handleClose = () => {
    if (!noClose) {
      if (onClose) onClose();
      handleHide();
    }
  };
  if (!show) return null;
  const classNames = cn({ [`modal--${modalStyle}`]: !!modalStyle });
  switch (modalStyle) {
    case 'success':
      titleIcon = <CheckIcon addClass="icon--success" />;
      break;
    case 'warning':
      titleIcon = <AlertIcon addClass="icon--warning" />;
      break;
    case 'danger':
      titleIcon = <CloseCircleIcon addClass="icon--danger" />;
      break;
    default:
  }
  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby={name}
      dialogClassName={classNames}
      dialogComponentClass={DraggableModalDialog}
    >
      <Modal.Header closeButton={!noClose}>
        <div className="header-contents">
          {titleIcon}
          {header}
        </div>
      </Modal.Header>

      <Modal.Body>{children}</Modal.Body>

      {footer && <Modal.Footer>{footer}</Modal.Footer>}
    </Modal>
  );
};

ModalView.propTypes = {
  name: PropTypes.string.isRequired,
  show: PropTypes.bool,
  handleHide: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  header: PropTypes.element,
  children: PropTypes.any,
  footer: PropTypes.any,
  noClose: PropTypes.bool,
  modalStyle: PropTypes.string, // success, warning, danger
  titleIcon: PropTypes.element
};

ModalView.defaultProps = {
  show: false,
  onClose: null,
  header: null,
  children: null,
  footer: null,
  noClose: false,
  modalStyle: null,
  titleIcon: null
};

export default ModalView;
