import { api, history, config, reducerUtil } from 'base-client';
import { reducerData as permissionsData } from 'permissions';
import { reducerData as leadsData } from 'leadsTwo';
import leadTableActions from './leadList';
import { searchPage } from 'leadsTwo/utils';

import { actions as messagesActions, reducerData as messagesData } from 'directMessages';
import timeUtils from 'utils/timeUtils';

// ------------------------------------
// Lead User Profile Data
// ------------------------------------

const leadPermission = state => reducerUtil.getSlice(permissionsData, permissionsData.leads, state);

const getLeadData = (user_id, callback) => async (dispatch, getState) => {
  const state = getState();
  if (!leadPermission(state)) return null;

  if (!user_id) {
    dispatch(backToLeads());
    if (callback) callback();
    return;
  }
  //clear old data before fetching new data
  dispatch(reducerUtil.setSlice(leadsData, leadsData.leadProfile, undefined));

  // try {
  const { data } = await dispatch(leadTableActions.leadApi('all', user_id));
  let lead;
  try {
    lead = await dispatch(api.actions.get(`search/users/${user_id}`));
  } catch (err) {
    dispatch(config.actions.error(err));
    lead = {};
  }
  const { leadScore, leadStage } = lead;
  if (data && data[0]) {
    const formattedData = formatLeadData(data[0], leadScore, leadStage);
    dispatch(reducerUtil.setSlice(leadsData, leadsData.leadProfile, formattedData));

    dispatch(messagesActions.getMessages({ userId: user_id }, messagesData.leadMessages));
  } else if (callback) {
    dispatch(backToLeads());
    callback();
  } else {
    return;
  }
  // } catch (error) {
  //   dispatch(config.actions.error(error));
  // }
};

const formatLeadData = (data, leadScore, leadStage) => {
  const leadData = {};
  if (!data) return leadData;

  const {
    id,
    firstName,
    lastName,
    username,
    companyName,
    occupation,
    email,
    industry,
    phoneNumber: phone,
    picture,
    events,
    city,
    country,
    state
  } = data;
  leadData.user = {
    email,
    industry,
    phone,
    picture,
    image: picture,
    id,
    name: firstName && lastName ? `${firstName} ${lastName}` : username,
    company: companyName || 'Anonymous Company',
    occupation: occupation || 'Anonymous Occupation',
    location: leadTableActions.getLeadLocation(data),
    city,
    country,
    state,
    leadScore,
    leadStage
  };
  const cleanSource = source => {
    if (source === 'api') return 'API';
    if (source === 'library') return 'Concora Library';
    return 'Design Studio';
  };
  const getEventData = (type, meta) => {
    switch (type) {
      case 'referral':
        return `Referral URL: ${(meta.referrer && meta.referrer.url) || 'unknown'}`;
      case 'click':
        return `Clicked Button: ${meta.display || 'unknown'} | Page URL: ${(meta.page &&
          meta.page.path) ||
          'unknown'}`;
      case 'pageview':
        return `Page URL: ${(meta.page && meta.page.path) || 'unknown'}`;
      case 'search':
        return `Search Query: ${meta.queryString || 'empty'}`;
      case 'product':
        return `Product: ${meta.productName || 'unknown'}`;
      case 'download':
        return `Product: ${meta.productName || 'unknown'} | Asset: ${meta.assetName || 'unknown'}`;
      case 'projectcreate':
      case 'projectupdate':
      case 'projectaddproduct':
      case 'submittal':
        return `Project: ${meta.name || 'Unknown Project'}`;
      case 'brochure':
        return `Project: ${meta.name || 'Unknown Project'}`;
      default:
        return 'No Event Data';
    }
  };

  const getPrettyEventType = (type, meta) => {
    switch (type) {
      case 'referral':
        return 'Referral';
      case 'click':
        return 'Button Click';
      case 'pageview':
        return 'Page View';
      case 'search':
        return 'Product Search';
      case 'product':
        return 'Product View';
      case 'download':
        return 'Product Asset Download';
      case 'projectcreate':
        return 'Project Created';
      case 'projectupdate':
        return 'Project Updated';
      case 'projectaddproduct':
        return 'Product Added To Project';
      case 'submittal':
        return 'Submittal Generated For Project';
      case 'brochure':
        return 'Brochure';
      default:
        return type;
    }
  };

  const getUrlForRow = (type, meta) => {
    switch (type) {
      case 'product':
      case 'download':
        return meta.product_id ? `/products/${meta.product_id}` : '#';
      case 'projectcreate':
      case 'projectupdate':
      case 'projectaddproduct':
      case 'submittal':
        return meta.id ? `/insights/projects/${meta.id}` : '#';
      default:
        return '#';
    }
  };

  leadData.events =
    events &&
    events
      .sort((a, b) => b.datetime - a.datetime)
      .map(({ datetime, eventMetadata: meta, type, source }) => {
        return {
          event: getPrettyEventType(type),
          site: cleanSource(source),
          eventData: getEventData(type, meta),
          datetime,
          url: getUrlForRow(type, meta)
        };
      });

  leadData.tables = getLeadTables(data);
  return leadData;
};

const getLeadTables = lead => {
  if (!lead) return {};
  const { eventCountsBySource: sourceCount = [], eventCountsByType: typeCount = [] } = lead;

  // Order matters - sort based on priority.
  const actions = countTable([
    {
      key: 'Downloads',
      count: getCount(typeCount, 'type', 'download')
    },
    {
      key: 'Page Views',
      count: getCount(typeCount, 'type', 'pageview')
    },
    {
      key: 'Product Views',
      count: getCount(typeCount, 'type', 'product')
    },
    {
      key: 'Searches',
      count: getCount(typeCount, 'type', 'search')
    },
    {
      key: 'Brochures',
      count: getCount(typeCount, 'type', 'brochure')
    }
  ]);

  const sites = countTable([
    {
      key: 'Design Studio',
      count: getCount(sourceCount, 'source', 'designStudio')
    },
    {
      key: 'Library',
      count: getCount(sourceCount, 'source', 'library')
    },
    {
      key: 'API',
      count: getCount(sourceCount, 'source', 'api')
    }
  ]);

  const categories = percentTable(
    lead.eventCategories.map(category => ({ key: category.category, count: category.count }))
  );
  const totalActivity = lead.eventCount || 0;
  const lastActivity = timeUtils.format.toNumber(new Date(lead.lastEvent));
  return {
    actions,
    sites,
    categories,
    totalActivity,
    lastActivity
  };
};

const getCount = (infoArray, key, match) => {
  const info = infoArray.find(item => item[key] === match) || {};
  return info.count || 0;
};

const percentTable = data => {
  if (!data || data.length < 1) return;
  const total = data.reduce((sum, value) => sum + value.count, 0);
  return data.map(value => ({
    name: value.key,
    y: precisionRound((value.count / total) * 100, 2)
  }));
};

const countTable = data => {
  if (!data || data.length < 1) return;
  return data.map(value => ({
    name: value.key,
    y: value.count
  }));
};

const precisionRound = (number, precision) => {
  const factor = 10 ** precision;
  return Math.round(number * factor) / factor;
};

const backToLeads = () => dispatch => history.push('/insights/contacts');

export default {
  getLeadData
};
