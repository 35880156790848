import React from 'react';
import PropTypes from 'prop-types';

import '../assets/dashboard-tabs.scss';

const IconStatsTabTitle = ({ count, metric, icon }) => (
  <div className='dashboard-tab'>
    { icon }
    <div className='tab-stats'>
      <div className='tab-count'>{ count }</div>
      <div className='tab-metric'>{ metric }</div>
    </div>
  </div>
);

IconStatsTabTitle.propTypes = {
  icon: PropTypes.node.isRequired,
  count: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  metric: PropTypes.string.isRequired,
};

IconStatsTabTitle.defaultProps = {
  count: '0',
};

export default IconStatsTabTitle;
