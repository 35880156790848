import React from 'react';
import PropTypes from 'prop-types';

import { Table, Column, SortIndicator } from 'react-virtualized';
import { LinkButton } from 'shared/Buttons';

const ProductViewsTable = ({ data, registerChild, nameRenderer, dateRenderer, ...rest }) => {
  const rowGetter = ({ index }) => {
    return data[index] || {};
  };

  const tableProps = {
    ...rest,
    headerHeight: 55,
    rowHeight: 55,
    rowGetter,
    rowClassName: 'table-row',
    headerClassName: 'table-header',
    girdClassName: 'table__visible-items',
    scrollToAlignment: 'start'
  };

  const headerRenderer = ({ label, dataKey, sortBy, sortDirection }) => (
    <div>
      {label}
      {sortBy === dataKey && <SortIndicator {...{ sortDirection }} />}
    </div>
  );

  const productRenderer = ({ rowData: { productId, productName } }) => {
    return !productId ? (
      productName
    ) : (
      <LinkButton to={`/products/${productId}`}>{productName}</LinkButton>
    );
  };

  return (
    <Table {...tableProps} ref={registerChild}>
      <Column
        dataKey="date"
        label="Date"
        cellRenderer={dateRenderer}
        headerRenderer={headerRenderer}
        width={200}
        flexGrow={0}
      />
      <Column
        dataKey="name"
        label="Name"
        cellRenderer={nameRenderer}
        headerRenderer={headerRenderer}
        width={200}
        flexGrow={0}
      />
      <Column
        dataKey="occupation"
        label="Occupation"
        headerRenderer={headerRenderer}
        width={200}
        flexGrow={0}
      />
      <Column
        dataKey="company"
        label="Company"
        headerRenderer={headerRenderer}
        width={200}
        flexGrow={0}
      />
      <Column
        dataKey="productName"
        label="Product Name "
        cellRenderer={productRenderer}
        headerRenderer={headerRenderer}
        width={300}
        flexGrow={1}
      />
      <Column dataKey="productUrl" label="Product URL" width={300} flexGrow={1} />
    </Table>
  );
};

ProductViewsTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string,
      name: PropTypes.string,
      occupation: PropTypes.string,
      company: PropTypes.string,
      productUrl: PropTypes.string,
      productName: PropTypes.string
    })
  ).isRequired,
  registerChild: PropTypes.any
};

ProductViewsTable.defaultTypes = {
  data: []
};

export default ProductViewsTable;
