/* vendor imports */
import React from 'react';
import PropTypes from 'prop-types';

/* system imports */
import Stats from './Stats';
import toTitleCase, { numberToDash } from '../../utils/miscUtils';

const Container = ({ sum, unique, stream }) => {
  const type = toTitleCase(stream);
  const totalData = {
    title: `All ${type}`,
    current: numberToDash(sum)
  };
  const uniqueData = {
    title: `Unique ${type}`,
    current: numberToDash(unique)
  };

  return (
    <div className="insights-stats">
      <Stats {...totalData} />
      {stream === 'views' && <Stats {...uniqueData} />}
    </div>
  );
};

Container.propTypes = {
  stream: PropTypes.string.isRequired,
  total: PropTypes.number,
  unique: PropTypes.number
};

Container.defaultProps = {
  total: null,
  unique: null
};

export default Container;
