import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { PartialCheckbox } from 'shared/InputGroups/Checkbox';
import Button from 'shared/Buttons';

import { reducerUtil } from 'base-client';
import { reducerData as listData, actions as listActions } from 'productList';

const SelectControls = props => (
  <div>
    {props.isButton ? (
      <Button buttonStyle="select" onClick={props.onChange}>
        <PartialCheckbox {...props} />
      </Button>
    ) : (
      <PartialCheckbox {...props} />
    )}
  </div>
);

SelectControls.propTypes = {
  isButton: PropTypes.bool,
  onChange: PropTypes.func.isRequired
};

SelectControls.defaultProps = {
  isButton: false
};

const mapStateToProps = state => {
  const selected = reducerUtil.getSlice(listData, listData.selected, state) || [];
  const productList = reducerUtil.getSlice(listData, listData.list, state) || [];
  let checkState = PartialCheckbox.checkRules.unchecked;
  if (productList.length > 0 && selected.length === productList.length)
    checkState = PartialCheckbox.checkRules.checked;
  else if (selected.length > 0) checkState = PartialCheckbox.checkRules.partial;
  return { checkState };
};

const mapDispatchToProps = dispatch => ({
  onChange: () => dispatch(listActions.selectAll())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectControls);
