import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SortDirection } from 'react-virtualized';
import { connect } from 'react-redux';

import { reducerUtil } from 'base-client';
import { reducerData as insightsData, actions } from 'insights';
import { reducerData as permissionsData } from 'permissions';
import TopProductsTable from './TopProductsTable';
import Card from '../../shared/Insights/Card';
import { SectionHeader } from 'shared';

const rowHeight = 50;
const headerHeight = 55;

const compare = key => (a, b) => {
  const x = a[key];
  const y = b[key];
  if (typeof x === 'string') {
    x.toUpperCase();
    y.toUpperCase();
  }
  if (x < y) return -1;
  if (x > y) return 1;
  return 0;
};

class Container extends Component {
  constructor(props) {
    super(props);
    const sortBy = 'rank';
    const sortDirection = SortDirection.ASC;
    const sortedList = this.sortList({ sortBy, sortDirection });

    this.state = {
      sortBy,
      sortDirection,
      sortedList
    };

    this.sort = this.sort.bind(this);
  }

  componentDidUpdate(prevProps) {
    // not using prevState so OK to use setState
    const { productTop } = this.props;
    const { sortBy, sortDirection } = this.state;
    if (productTop !== prevProps.productTop) {
      const sortedList = this.sortList({ sortBy, sortDirection });
      this.setState({ sortedList }); // eslint-disable-line react/no-did-update-set-state
    }
  }

  sort({ sortBy, sortDirection }) {
    const sortedList = this.sortList({ sortBy, sortDirection });
    this.setState({ sortBy, sortDirection, sortedList });
  }

  sortList({ sortBy, sortDirection }) {
    const { productTop } = this.props;
    let sortedList = JSON.parse(JSON.stringify(productTop));
    sortedList = sortedList.sort(compare(sortBy));
    if (sortDirection === SortDirection.DESC) sortedList.reverse();
    return sortedList;
  }

  render() {
    const { sortedList } = this.state;
    const { productTop, hideHeader, ...cardProps } = this.props;
    const rowCount = sortedList ? sortedList.length : 0;
    const height = 10 * rowHeight + headerHeight;
    const rowGetter = ({ index }) => sortedList[index];
    return (
      <div>
        {!hideHeader && (
          <SectionHeader title="Top Products" subtitle="View your most popular products" />
        )}
        <Card headerBorder {...cardProps}>
          <TopProductsTable
            {...{ rowHeight, headerHeight, height, rowCount, rowGetter }}
            {...this.state}
            sort={this.sort}
          />
        </Card>
      </div>
    );
  }
}

Container.propTypes = {
  productTop: PropTypes.array,
  hideHeader: PropTypes.bool
};

Container.defaultProps = {
  productTop: [],
  hideHeader: false
};

const mapStateToProps = state => {
  const productTop = reducerUtil.getSlice(insightsData, insightsData.productTop, state) || [];
  return {
    canView: reducerUtil.getSlice(permissionsData, permissionsData.insights, state),
    productTop
  };
};

const mapDispatchToProps = dispatch => ({
  onSelect: ({ stream, interval, productKey }) =>
    dispatch(actions.getProductTop(stream, interval, productKey))
});

export default connect(mapStateToProps, mapDispatchToProps)(Container);
