import React from 'react';
import PropTypes from 'prop-types';
import { ControlLabel } from 'react-bootstrap';
import { connect } from 'react-redux';

import { EditIcon } from 'shared/Miscellaneous/Icons';
import { actions as detailsActions } from 'productDetails';

class TextContainer extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    input: PropTypes.shape({
      name: PropTypes.string.isRequired,
      onChange: PropTypes.func.isRequired,
      value: PropTypes.string
    }).isRequired,
    title: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element])
  };

  state = {};

  onStartEdit = () => {
    const {
      input: { value }
    } = this.props;
    this.setState({ edit: true, lastValue: value });
  };
  onFinishEdit = () => {
    const {
      dispatch,
      input: { name }
    } = this.props;
    dispatch(detailsActions.save(name));
    this.setState({ edit: false });
  };
  onCancelEdit = () => {
    const {
      input: { onChange }
    } = this.props;
    const { lastValue } = this.state;
    onChange(lastValue);
    this.setState({ edit: false });
  };

  render() {
    const { edit } = this.state;
    const { title, children, ...props } = this.props;
    if (!edit) {
      return (
        <div className="inline-edit__unit" onClick={this.onStartEdit}>
          {title && <ControlLabel>{title}</ControlLabel>}
          <div className="inline-editable">
            {children &&
              React.Children.map(children, child => React.cloneElement(child, { edit, ...props }))}
            <EditIcon width={18} height={18} className="icon--edit" />
          </div>
        </div>
      );
    }
    return (
      <div className="inline-edit__unit">
        {title && <ControlLabel>{title}</ControlLabel>}
        <div className="inline-edit--editing">
          {children &&
            React.Children.map(children, child => React.cloneElement(child, { edit, ...props }))}
          <div onClick={this.onFinishEdit} className="inline-editing inline-editing--save" />
          <div onClick={this.onCancelEdit} className="inline-editing inline-editing--cancel" />
        </div>
      </div>
    );
  }
}

export default connect()(TextContainer);
