import React from 'react';
import styled from 'styled-components';
import Card from 'shared/PageLayout/Card';
import sbManagerStyles from 'utils/globalStyles';

const LeadStatus = ({ leadStage }) => {
  const LeadStageBar = styled(Card)`
    display: flex;
    flex-direction: row;
    height: 5rem;
    min-width: max-content;
    background-color: white;
    margin-bottom: 0;
  `;

  const StageBarTitle = styled.div`
    width: 15%;
    margin-left: 1rem;
    margin-top: auto;
    margin-bottom: auto;
    font-size: 1.5rem;
    font-weight: 500;
  `;

  const ContactStage = styled.div`
    background-color: ${props => (props.highlight ? '#6ABF43' : '#F0F3F6')};
    display: flex;
    flex-direction: column;
    width: 17%;
    min-width: 10rem;
    height: 30px;
    position: relative;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 0.8rem;
    margin-left: 1rem;
    border-radius: 100px 0 0 100px
    &:before {
      content: '';
      position: absolute;
      right: -15px;
      bottom: 0;
      width: 0;
      height: 0;
      border-left: 15px solid ${props => (props.highlight ? '#6ABF43' : '#F0F3F6')};
      border-top: 15px solid transparent;
      border-bottom: 15px solid transparent;
    }
  `;

  const LeadStage = styled.div`
    background-color: ${props => (props.highlight ? '#6ABF43' : '#F0F3F6')};
    display: flex;
    flex-direction: column;
    width: 17%;
    min-width: 10rem;
    height: 30px;
    position: relative;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 0.8rem;
    margin-left: 0.8rem;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 0;
      height: 0;
      border-left: 15px solid white;
      border-top: 15px solid transparent;
      border-bottom: 15px solid transparent;
    }
    &:before {
      content: '';
      position: absolute;
      right: -15px;
      bottom: 0;
      width: 0;
      height: 0;
      border-left: 15px solid ${props => (props.highlight ? '#6ABF43' : '#F0F3F6')};
      border-top: 15px solid transparent;
      border-bottom: 15px solid transparent;
    }
  `;

  const ProspectStage = styled.div`
    background-color: ${props => (props.highlight ? '#6ABF43' : '#F0F3F6')};
    display: flex;
    flex-direction: column;
    width: 17%;
    min-width: 10rem;
    height: 30px;
    position: relative;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 0.8rem;
    margin-left: 0.8rem;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 0;
      height: 0;
      border-left: 15px solid white;
      border-top: 15px solid transparent;
      border-bottom: 15px solid transparent;
    }
    &:before {
      content: '';
      position: absolute;
      right: -15px;
      bottom: 0;
      width: 0;
      height: 0;
      border-left: 15px solid ${props => (props.highlight ? '#6ABF43' : '#F0F3F6')};
      border-top: 15px solid transparent;
      border-bottom: 15px solid transparent;
    }
  `;

  const VisitorStage = styled.div`
    background-color: ${props => (props.highlight ? '#6ABF43' : '#F0F3F6')};
    display: flex;
    flex-direction: column;
    width: 17%;
    min-width: 10rem;
    height: 30px;
    position: relative;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 0.8rem;
    margin-left: 0.8rem;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 0;
      height: 0;
      border-left: 15px solid white;
      border-top: 15px solid transparent;
      border-bottom: 15px solid transparent;
    }
    &:before {
      content: '';
      position: absolute;
      right: -15px;
      bottom: 0;
      width: 0;
      height: 0;
      border-left: 15px solid ${props => (props.highlight ? '#6ABF43' : '#F0F3F6')};
      border-top: 15px solid transparent;
      border-bottom: 15px solid transparent;
    }
  `;

  const OpportunityStage = styled.div`
    background-color: ${props => (props.highlight ? '#6ABF43' : '#F0F3F6')};
    display: flex;
    flex-direction: column;
    width: 17%;
    min-width: 10rem;
    height: 30px;
    position: relative;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 2rem;
    margin-left: 0.8rem;
    border-radius: 0 100px 100px 0;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 0;
      height: 0;
      border-left: 15px solid white;
      border-top: 15px solid transparent;
      border-bottom: 15px solid transparent;
    }
    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      width: 0;
      height: 0;
      border-left: 15px solid ${props => (props.highlight ? '#6ABF43' : '#F0F3F6')};
      border-top: 15px solid transparent;
      border-bottom: 15px solid transparent;
    }
  `;

  const StageTitle = styled.div`
    color: ${props => (props.highlight ? '#ffffff' : 'inherit')};
    margin: auto;
    font-size: 1.5rem;
    font-weight: 500;
  `;

  const shouldHighlightStage = stage => {
    let status;
    switch (leadStage) {
      case 'Visitor':
        status = 1;
        break;
      case 'Lead':
        status = 2;
        break;
      case 'Prospect':
        status = 3;
        break;
      case 'Opportunity':
        status = 4;
        break;
      case 'Contact':
      default:
        status = 0;
        break;
    }
    return stage <= status;
  };
  return (
    <LeadStageBar>
      <StageBarTitle>Contact Status</StageBarTitle>
      <ContactStage highlight={shouldHighlightStage(0)}>
        <StageTitle highlight={shouldHighlightStage(0)}>Contact</StageTitle>
      </ContactStage>
      <VisitorStage highlight={shouldHighlightStage(1)}>
        <StageTitle highlight={shouldHighlightStage(1)}>Visitor</StageTitle>
      </VisitorStage>
      <LeadStage highlight={shouldHighlightStage(2)}>
        <StageTitle highlight={shouldHighlightStage(2)}>Lead</StageTitle>
      </LeadStage>
      <ProspectStage highlight={shouldHighlightStage(3)}>
        <StageTitle highlight={shouldHighlightStage(3)}>Prospect</StageTitle>
      </ProspectStage>
      <OpportunityStage highlight={shouldHighlightStage(4)}>
        <StageTitle highlight={shouldHighlightStage(4)}>Opportunity</StageTitle>
      </OpportunityStage>
    </LeadStageBar>
  );
};

export default LeadStatus;
