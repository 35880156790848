export default {
  name: 'leadsTwo',
  leadList: 'leadList',
  leadProfile: 'leadProfile',
  list: 'list',
  meta: 'meta',
  query: 'query',
  lastQueryURL: 'lastQueryURL',
  fetchId: 'fetchId',
  listId: 'listId',
  selected: 'selected'
};
