import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reducerUtil } from 'base-client';
import { FormSection } from 'redux-form';

import Card from 'shared/PageLayout/Card';
import Text from './Text';
import TextArea from './TextArea';
import Title from './Title';
import Image from './Image';
import Button from 'shared/Buttons';
import {
  AddBoxOutlineIcon,
  CheckStatusIcon,
  EditOutlineIcon,
  PublishOutlineIcon,
  ShowIcon
} from 'shared/Miscellaneous/Icons';
import { reducerData as detailsData } from 'productDetails';
import detailsUtils from 'productDetails/utils';

const Header = ({ jobs, status, ...props }) => (
  <FormSection name={detailsUtils.form.system}>
    <Title name={detailsUtils.systemNames.title} {...props} />
    <Card>
      <div className="product-summary">
        <div className="product-img">
          <Image name={detailsUtils.systemNames.image} {...props} />
        </div>
        <div className="product-info">
          <Text title="Manufacturer" name={detailsUtils.systemNames.manufacturer} {...props} />
          <Text title="Category" name={detailsUtils.systemNames.category} {...props} />
          <TextArea title="Description" name={detailsUtils.systemNames.description} {...props} />
        </div>
        <div className="product-meta">
          <div className="meta-item">
            <p className="meta-item__header">
              <span className="meta-item__icon">
                <CheckStatusIcon width={18} height={18} />
              </span>
              Status
            </p>
            <p className="meta-item__content">
              {jobs && jobs.length > 0 ? 'Processing' : 'Available'}
            </p>
          </div>

          <div className="meta-item">
            <p className="meta-item__header">
              <span className="meta-item__icon">
                <AddBoxOutlineIcon width={18} height={18} />
              </span>
              Created At
            </p>
            <p className="meta-item__content">
              {detailsUtils.formatTime(status[detailsUtils.statusNames.created], 'created')}
            </p>
          </div>

          <div className="meta-item">
            <p className="meta-item__header">
              <span className="meta-item__icon">
                <EditOutlineIcon width={18} height={18} />
              </span>
              Updated At
            </p>
            <p className="meta-item__content">
              {detailsUtils.formatTime(status[detailsUtils.statusNames.updated], 'updated')}
            </p>
          </div>

          <div className="meta-item">
            <p className="meta-item__header">
              <span className="meta-item__icon">
                <PublishOutlineIcon width={18} height={18} />
              </span>
              Published At
            </p>
            <p className="meta-item__content">
              {detailsUtils.formatTime(status[detailsUtils.statusNames.published], 'published')}
            </p>
          </div>
          <div className="meta-item">
            <p className="meta-item__header">
            <Button
            onClick={() => {
              let host = window.location.hostname.split('.')[0]
              let env = window.location.hostname.split('.')[1] 
              if(host === 'localhost'){
                env = 'dev'
                const port = process.env.REACT_APP_LIBRARY_PORT
                const tenant = process.env.REACT_APP_LIBRARY_TENANT
                let url = `https://gdfstudio.dev.concora.com${window.location.pathname}`
                if(port){
                   url = `https://${window.location.hostname.split('.')[0]}:${port}${window.location.pathname}`
                } else if(tenant) {
                  url = `https://${tenant}.${env}.concora.com${window.location.pathname}`
                }
                window.open(url, '_blank')
              }

              if( env === 'smartbim-dev'){
                env = '.dev'
              }
              else if(env === 'smartbim'){
                env = '.qa'
              }
              else {
                env = ''
              }

              let url = `https://${window.location.hostname.split('.')[0]}${env}.concora.com${window.location.pathname}`
              window.open(url, '_blank')
            }}
            icon={<ShowIcon />}
          >
            View in Library
          </Button>
            </p>
          </div>

        </div>
      </div>
    </Card>
  </FormSection>
);

Header.propTypes = {
  status: PropTypes.shape({
    [detailsUtils.statusNames.created]: PropTypes.string,
    [detailsUtils.statusNames.updated]: PropTypes.string,
    [detailsUtils.statusNames.published]: PropTypes.string
  }).isRequired,
  jobs: PropTypes.array
};

const mapStateToProps = state => ({
  status: reducerUtil.getSlice(detailsData, detailsData.status, state),
  jobs: reducerUtil.getSlice(detailsData, detailsData.jobs, state)
});

export default connect(mapStateToProps)(Header);
