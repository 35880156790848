/* vendor imports */
import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { FormGroup, FormControl } from 'react-bootstrap';
import cn from 'classnames';

/* system imports */

const SharedParameterGuid = props => (
  <div className="form__attribute-unit">
    <div className="form__attribute-title">Shared Parameter GUID</div>
    <div className="form__attribute-line">
      <Field name="shared-paramter-guid" component={SharedParameterGuidField} {...props} />
    </div>
  </div>
);

const SharedParameterGuidField = ({ input, className }) => (
  <FormGroup controlId={input.name} bsClass={cn('form-group', className)}>
    <FormControl bsClass={cn('form-control', null)} {...input} disabled />
  </FormGroup>
);

SharedParameterGuidField.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.shape({
      sharedParameter: PropTypes.string
    })
  }).isRequired,
  className: PropTypes.string
};

SharedParameterGuidField.defaultProps = {
  className: null
};

export default SharedParameterGuid;
