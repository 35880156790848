import React from 'react';
import styled from 'styled-components';

import { FormDropdown, FormDropdownOption } from 'shared-features-client';
import sbManagerStyles from 'utils/globalStyles';

const DropdownWrapper = styled.div`
  width: 100%;
  font-weight: 600;
  font-size: 1.2rem;
`;

const StyledDropdown = styled(FormDropdown)`
  height: 3.5rem;
  border: 1px solid ${() => sbManagerStyles.greyLight};
  border-radius: 4px;
  color: ${() => sbManagerStyles.greyDarkest};
`;

const Label = styled.div`
  color: ${() => sbManagerStyles.greyDarkest01};
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.6rem;
`;

const AssetDropdown = ({ assets, input }) => {
  return (
    <DropdownWrapper>
      <Label>Select an Asset</Label>
      <StyledDropdown {...input}>
        {assets &&
          assets.map(({ id, name }) => (
            <FormDropdownOption key={id} value={id} optionLabel={name} />
          ))}
      </StyledDropdown>
    </DropdownWrapper>
  );
};

export default AssetDropdown;
