const config = {
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    height: 400,
    type: 'pie'
  },
  credits: {
    enabled: false
  },
  title: {
    text: null
  },
  yAxis: {
    minRange: 1,
    min: 0,
    allowDecimals: false,
    labels: {
      enables: false
    },
    title: {
      text: null
    }
  },
  plotOptions: {
    pie: {
      allowPointSelect: false,
      cursor: 'pointer',
      size: 200,
      dataLabels: {
        enabled: false
      },
      showInLegend: true
    }
  },
  tooltip: {
    valueSuffix: ' %'
  },
  series: [
    {
      name: 'Percentage',
      colorByPoint: true,
      innerSize: '50%',
      data: []
    }
  ]
};

export default config;
