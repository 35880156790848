import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from './../SvgIcon';

const CloseCircleOutlineIcon = (props) => (
  <SvgIcon {...props}>
		<path d="M12.042,22c-5.514,0-10-4.486-10-10s4.486-10,10-10s10,4.486,10,10S17.556,22,12.042,22z M12.042,4c-4.411,0-8,3.589-8,8s3.589,8,8,8s8-3.589,8-8S16.453,4,12.042,4z"/>
		<polygon points="16.749,8.707 15.334,7.293 12.042,10.586 8.749,7.293 7.334,8.707 10.627,12 7.334,15.293 8.749,16.707 12.042,13.415 15.334,16.707 16.749,15.293 13.456,12 "/>
  </SvgIcon>
)

CloseCircleOutlineIcon.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  viewBox: PropTypes.string.isRequired,
};

CloseCircleOutlineIcon.defaultProps = {
  width: 24,
  height: 24,
  viewBox: '0 0 24 24',
};

export default CloseCircleOutlineIcon;