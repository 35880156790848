import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../SvgIcon';

const JobQueue = props => (
  <SvgIcon {...props}>
    <path
      id="shadow"
      className="color--light"
      d="M23 17H20L18 19H6L4 17H1C0.4 17 0 17.4167 0 18.0417V20.9583C0 21.5833 0.4 22 1 22H23C23.6 22 24 21.5833 24 20.9583V18.0417C24 17.4167 23.6 17 23 17Z"
    />
    <g className="icon--active">
      <path className="color--medium" d="M20 9.99899H4V11.999H20V9.99899Z" />
      <path className="color--medium" d="M20 6.99899H4V8.99899H20V6.99899Z" />
      <path className="color--medium" d="M20 3.99899H4V5.99899H20V3.99899Z" />
      <path className="color--medium" d="M14 12.999H10V14.999H14V12.999Z" />
      <path
        className="color--dark"
        d="M20 12.999H16C15.447 12.999 15 13.447 15 13.999V15.999H9V13.999C9 13.447 8.553 12.999 8 12.999H4C3.447 12.999 3 13.447 3 13.999V17.999C3 19.102 3.897 19.999 5 19.999H19C20.104 19.999 21 19.102 21 17.999V13.999C21 13.447 20.553 12.999 20 12.999Z"
      />
    </g>
    <g className="icon--inactive">
      <path className="color--medium" d="M20 9.99899H4V11.999H20V9.99899Z" />
      <path className="color--medium" d="M20 6.99899H4V8.99899H20V6.99899Z" />
      <path className="color--medium" d="M20 3.99899H4V5.99899H20V3.99899Z" />
      <path className="color--medium" d="M14 12.999H10V14.999H14V12.999Z" />
      <path
        className="color--medium"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 12.999C15.447 12.999 15 13.447 15 13.999V15.999H9V13.999C9 13.447 8.553 12.999 8 12.999H4C3.447 12.999 3 13.447 3 13.999V17.999C3 19.102 3.897 19.999 5 19.999H19C20.104 19.999 21 19.102 21 17.999V13.999C21 13.447 20.553 12.999 20 12.999H16ZM17 18.5H18C18.5523 18.5 19 18.0523 19 17.5V14.999H17V16.5C17 17.0523 16.5523 17.5 16 17.5H8C7.44772 17.5 7 17.0523 7 16.5V14.999H5V17.5C5 18.0523 5.44772 18.5 6 18.5H7H17Z"
      />
    </g>
  </SvgIcon>
);

JobQueue.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
};

JobQueue.defaultProps = {
  width: 22,
  height: 22
};

export default JobQueue;
