import React, { useState } from 'react';
import styled from 'styled-components';

import Button from 'shared/Buttons';
import { Modal } from 'shared/Modals';
import { DeleteIcon } from 'shared/Miscellaneous/Icons';

const StyledContent = styled.div`
  font-size: 1.4rem;
  min-width: 25rem;
  h3 {
    padding-right: 2rem;
  }
  .body {
    font-size: 1.4rem;
    padding: 0 0 2rem;
  }
  .footer {
    padding: 0 0 2rem;
    button {
      &:first-child {
        margin-right: 3rem;
      }
    }
  }
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Message = styled.div`
  margin-bottom: 2rem;
`;

const ConfirmModal = ({
  btnText,
  onConfirm,
  title,
  messages,
  confirmBtnText,
  deleteIcon,
  height,
  width
}) => {
  const [state, setState] = useState({ isOpen: false });

  const { isOpen } = state;

  const closeModal = () => setState({ isOpen: false });

  const modalProps = {
    title,
    open: isOpen,
    onClose: closeModal
  };

  const handleOnConfirm = () => {
    onConfirm && onConfirm();
    closeModal();
  };

  return (
    <React.Fragment>
      {deleteIcon ? (
        <DeleteIcon
          addClass="icon--neutral icon--hover-danger"
          onClick={() => setState({ isOpen: true })}
          height={height}
          width={width}
        />
      ) : (
        <Button onClick={() => setState({ isOpen: true })}>{btnText}</Button>
      )}

      <Modal disableHeader {...modalProps}>
        <StyledContent>
          <Message>{messages}</Message>
          <Buttons>
            <Button onClick={closeModal}>Cancel</Button>
            <Button buttonStyle="red-fill" noUpperCase fontWeight="500" onClick={handleOnConfirm}>
              {confirmBtnText}
            </Button>
          </Buttons>
        </StyledContent>
      </Modal>
    </React.Fragment>
  );
};

export default ConfirmModal;
