/* vendor imports */
import React from 'react';
import PropTypes from 'prop-types';

/* system imports */
import AutoSizerList from 'shared/TablesAndLists/components/AutoSizerList';
import Component from '../components';

const Confirm = ({ products, ...props }) => {
  const data = products.map(({ id, activeVersion: { productName: name } }) => (
    <li key={id} className="attribute__item">
      {name}
    </li>
  ));
  return (
    <div className="affected-products">
      <AutoSizerList
        id="attribute-confirm"
        title={`${products.length} Products Affected`}
        {...{ data }}
      />
      <Component {...props} />
    </div>
  );
};

Confirm.propTypes = {
  products: PropTypes.array
};

Confirm.defaultProps = {
  products: []
};

export default Confirm;
