/* eslint-disable prefer-destructuring */
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { reducerUtil, login, config } from 'base-client';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { configMap } from 'configurations';
import { Layout } from 'dashboard';
import { LeadSearch, LeadProfile as NewLeadProfile } from 'leadsTwo';
import { LeadTable, LeadProfile } from 'leads';
import { reducerData as tenantData } from 'tenant';
import { Users, Attributes, APIKeys } from 'admin';
import Analytics from 'analyticsDashboard';
import EventsLog from 'eventsLog';
import GoogleLogin from 'login/components/GoogleLogin';
import Logout from 'login/components/Logout';
import Insights from 'insights';
import Jobs from 'jobs';
import Login from 'login';
import Messages from 'directMessages';
import ProductDetails from 'productDetails';
import ProductList from 'productList';
import ProjectDetails from 'projectDetails';
import ProjectsContainer from 'projects';
import Tenant from 'tenant';
import { useRedirects } from 'utils/redirect/hooks';

const Routes = ({ loginPage, enableFeatures, dispatch }) => {
  const {
    messages: enableMessages,
    projects: enableProjects,
    api: enableApi,
  } = enableFeatures || {};
  const disableScoring = dispatch(
    config.actions.getData(configMap.disabled.name, configMap.disabled.leadScoring.name)
  );
  const { shouldRedirect } = useRedirects({dispatch});
  return (
    <Switch>
      <Route path={`${loginPage}/google`} component={GoogleLogin} />
      <Route path={loginPage} component={Login} />
      <Route path={'/logout'} component={Logout} />
      <Route>
        { shouldRedirect ? <div>Redirecting...</div> : (<Tenant>
          <Layout>
            <Switch>
              <Route path="/products">
                <Switch>
                  <Route path="/products/:id" component={ProductDetails} />
                  <Route component={ProductList} />
                </Switch>
              </Route>
              <Route path="/insights">
                <Switch>
                  <Route path="/insights/contacts">
                    <Switch>
                      <Route
                        path="/insights/contacts/:id"
                        component={disableScoring ? LeadProfile : NewLeadProfile}
                      />
                      <Route component={disableScoring ? LeadTable : LeadSearch} />
                    </Switch>
                  </Route>
                  <Route path="/insights/leads">
                    <Switch>
                      <Route
                        path="/insights/leads/:id"
                        component={disableScoring ? LeadProfile : NewLeadProfile}
                      />
                      <Route component={disableScoring ? LeadTable : LeadSearch} />
                    </Switch>
                  </Route>
                  <Route path="/insights/events" component={EventsLog} />
                  <Route path="/insights/charts" component={Insights} />
                  {enableMessages && <Route path="/insights/messages" component={Messages} />}
                  {enableProjects && (
                    <Route path="/insights/projects">
                      <Switch>
                        <Route path="/insights/projects/:id" component={ProjectDetails} />
                        <Route component={ProjectsContainer} />
                      </Switch>
                    </Route>
                  )}
                  <Route>
                    <Redirect to="/" />
                  </Route>
                </Switch>
              </Route>
              <Route path="/admin">
                <Switch>
                  <Route path="/admin/users" component={Users} />
                  <Route path="/admin/jobs" component={Jobs} />
                  <Route path="/admin/attributes" component={Attributes} />
                  {enableApi && <Route path="/admin/api-keys" component={APIKeys} />}
                  <Route>
                    <Redirect to="/" />
                  </Route>
                </Switch>
              </Route>
              <Route path="/analytics" component={Analytics} />
              <Route path="/:stuff">
                <Redirect to="/" />
              </Route>
              <Route component={Analytics} />
            </Switch>
          </Layout>
        </Tenant>)}
      </Route>
      <Route>
        <Redirect to="/" />
      </Route>
    </Switch>
  );
};

Routes.propTypes = {
  dispatch: PropTypes.func.isRequired,
  loginPage: PropTypes.string
};

const mapStateToProps = state => ({
  loginPage: reducerUtil.getSlice(login.reducerData, login.reducerData.loginPage, state),
  enableFeatures: reducerUtil.getSlice(tenantData, tenantData.enableFeatures, state),
  tenantData: reducerUtil.getSlice(tenantData, tenantData.tenant, state),
});

export default withRouter(connect(mapStateToProps)(Routes));
