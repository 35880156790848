import React from 'react';
import PropTypes from 'prop-types';

import Button from 'shared/Buttons';
import Card from 'shared/PageLayout/Card';
import { LinkIcon } from 'shared/Miscellaneous/Icons';

const Instructions = ({ onHide }) => (
  <div className="instructions">
    <Card
      isHeader
      headerBorder
      title="Instructions"
      headerControls={
        <Button buttonStyle="link" onClick={onHide}>
          ×
        </Button>
      }
      
    >
      <div className="instructions__body">
        <p>
          Upload your Master Shared Parameters file by clicking the Select File button or by
          dragging a file into the dropzone.
        </p>
        <p>
          Once the file is uploaded, we will process the file and map attributes from the shared
          parameter file to attributes in your System.
        </p>
        <p>
          Shared parameters will have a <LinkIcon /> next to the name.
        </p>
      </div>
    </Card>
  </div>
);

Instructions.propTypes = {
  onHide: PropTypes.func.isRequired
};

export default Instructions;
