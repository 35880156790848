import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import { connect } from 'react-redux';

import { actions as detailsActions } from 'productDetails';

class ListContainer extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    assetList: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.string,
        mimetype: PropTypes.string
      })
    ),
    input: PropTypes.shape({
      name: PropTypes.string.isRequired,
      onChange: PropTypes.func.isRequired,
      value: PropTypes.string
    }),
    extensions: PropTypes.arrayOf(PropTypes.string),
    className: PropTypes.object,
    title: PropTypes.string,
    bsClass: PropTypes.object,
    disabled: PropTypes.bool
  };
  static defaultProps = {
    assetList: [],
    extensions: [],
    input: {}
  };

  onLeaveFocus = () => {
    const {
      dispatch,
      input: { name }
    } = this.props;
    dispatch(detailsActions.save(name));
  };

  render() {
    const {
      title,
      bsClass,
      assetList,
      extensions,
      className,
      disabled,
      input: { name, value, onChange }
    } = this.props;
    const options = assetList.filter(item => {
      if (extensions.length <= 0 || !item.id) return true;
      return item.filename ? item.filename.match(new RegExp(`.*\\.(${extensions.join('|')})$`)) : '';
    });
    return (
      <div className="product-details__input">
        <FormGroup controlId={name} onChange={this.onLeaveFocus} {...{ className }}>
          <ControlLabel>{title}</ControlLabel>
          <FormControl
            {...{ value, onChange, disabled }}
            componentClass="select"
            bsClass={cn('form-control', bsClass)}
          >
            {options.map(({ id, filename }) => (
              <option key={`${id}${filename}`} value={id}>
                {filename}
              </option>
            ))}
          </FormControl>
        </FormGroup>
      </div>
    );
  }
}

export default connect()(ListContainer);
