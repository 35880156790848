import React, { useState } from 'react';
import styled from 'styled-components';

import { EditIcon, CopyIcon, CloseCircleOutlineIcon } from 'shared/Miscellaneous/Icons';
import EditProductOption from '../EditProductOption';
import { actions as detailsActions } from 'productDetails';
import sbManagerStyles from 'utils/globalStyles';

const Option = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  line-height: 2.5rem;
  cursor: pointer;
  margin-bottom: 2rem;
  border-right: 0.3rem solid ${sbManagerStyles.white};
  &.active {
    border-right: 0.3rem solid ${sbManagerStyles.blueDark};
    background-color: ${sbManagerStyles.greyLightest};
  }
`;

const Name = styled.div`
  font-size: 1.4rem;
  color: ${() => sbManagerStyles.greyDark};
  font-weight: bold;
  margin-left: 2rem;
  &.active {
    color: ${sbManagerStyles.blueDark};
  }
`;

const Buttons = styled.div`
  display: flex;
  padding: 0 1rem;
  align-items: center;
  padding-top: 0.5rem;
`;

const Button = styled.div`
  margin-right: 0.5rem;
`;

const Copy = styled(CopyIcon)`
  margin-top: 0.5rem;
`;

const Delete = styled(CloseCircleOutlineIcon)`
  fill: ${() => sbManagerStyles.red};
`;

const ProductOption = ({
  options,
  id,
  name,
  onClick,
  activeId,
  onClickCreate,
  onClickDelete,
  dispatch
}) => {
  const [open, setOpen] = useState(false);
  const [editId, setEditId] = useState();
  const [duplicate, setDuplicate] = useState(false);

  const onClickOpen = id => {
    setEditId(id);
    setOpen(true);
  };

  const onClickSave = async (optionId, name) => {
    await dispatch(detailsActions.saveOption({ optionId, name }));
    setOpen(false);
  };

  const onClickClose = () => setOpen(false);

  const editProps = { id: editId, name, onClickClose, onClickSave, options, dispatch };

  return (
    <div>
      <Option className={activeId === id ? 'active' : ''} onClick={() => onClick(id)}>
        <Name className={activeId === id ? 'active' : ''}>{name}</Name>
        <Buttons>
          <Button onClick={() => onClickCreate(name)}>
            <Copy />
          </Button>
          <Button onClick={() => onClickOpen(id)}>
            <EditIcon height={16} width={16} />
          </Button>
          <Button onClick={() => onClickDelete(id)}>
            <Delete height={16} width={16} />
          </Button>
        </Buttons>
      </Option>
      {open && <EditProductOption {...editProps} />}
    </div>
  );
};

export default ProductOption;
