import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { ChevronLeftIcon } from 'shared/Miscellaneous/Icons';
import Privacy from './Privacy';
import 'login/assets/login.scss';

const Card = ({ brand, title, back, children, message }) => (
  <div className="auth">
    <div className="branding">
      <div className="branding__logo" />
      <div className="branding__name">{brand}</div>
    </div>
    <div className="auth__container">
      <h2 className="auth__title">
        {back && (
          <div className="login-back" onClick={back}>
            <ChevronLeftIcon width={8} height={12} />
          </div>
        )}
        {title}
      </h2>
      {message && (
        <div className={cn('message', { [`message--${message.style}`]: true })}>{message.text}</div>
      )}
      {children}
      <div className="auth__footer">
        <Privacy />
      </div>
    </div>
  </div>
);

Card.propTypes = {
  brand: PropTypes.string,
  title: PropTypes.string,
  back: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
    PropTypes.string
  ]),
  footer: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
    PropTypes.string
  ]),
  message: PropTypes.object
};

Card.defaultProps = {
  brand: 'Design Manager'
};

export default Card;
