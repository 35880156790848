import { connect } from 'react-redux';
import ModalView from '../components/ModalView';
import { openModal } from 'modal/modal';

export default name => {
  const mapStateToProps = state => ({
    name,
    show: state.modal[name]
  });
  const mapDispatchToProps = dispatch => ({
    handleHide: () => dispatch(openModal(name, false))
  });
  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(ModalView);
};
