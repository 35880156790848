import React from 'react';
import PropTypes from 'prop-types';
import { Column, Table, SortIndicator } from 'react-virtualized';
import 'react-virtualized/styles.css';

import SelectControls from './SelectControls';
import Checkbox from 'shared/InputGroups/Checkbox';
import { Rows } from 'shared/TablesAndLists';
import { querySortBy } from 'leadsTwo/utils';

const headerRenderer = ({ dataKey, label, sortBy, sortDirection, ...props }) => {
  return (
    <div>
      {label}
      {querySortBy[dataKey] && sortBy === querySortBy[dataKey] && (
        <SortIndicator sortDirection={sortDirection.toUpperCase()} />
      )}
    </div>
  );
};

const cellLinkRenderer = props => <Rows.Link {...props} />;

const ListView = ({ meta, selectedUsers, registerChild, ...props }) => {
  const hitCount = meta ? meta.totalHits : 0;
  return (
    <Table {...props} ref={registerChild}>
      <Column
        cellRenderer={({ rowData }) => (
          <Checkbox
            isChecked={rowData.isChecked}
            onChange={rowData.onSelect}
            isLocked={selectedUsers.length >= 10000}
          />
        )}
        dataKey="select"
        disableSort
        headerRenderer={() => (
          <SelectControls isLocked={hitCount > 10000 || selectedUsers.length >= 10000} />
        )}
        label="Select"
        width={60}
      />
      <Column
        cellRenderer={cellLinkRenderer}
        dataKey="name"
        disableSort
        flexGrow={1}
        headerRenderer={headerRenderer}
        label="Name"
        width={120}
      />
      <Column
        cellRenderer={cellLinkRenderer}
        dataKey="email"
        disableSort
        flexGrow={1}
        headerRenderer={headerRenderer}
        label="Email"
        width={160}
      />
      <Column
        cellRenderer={cellLinkRenderer}
        dataKey="occupation"
        disableSort
        flexGrow={1}
        headerRenderer={headerRenderer}
        label="Occupation"
        width={100}
      />
      <Column
        cellRenderer={cellLinkRenderer}
        dataKey="company"
        disableSort
        flexGrow={1}
        headerRenderer={headerRenderer}
        label="Company"
        width={100}
      />
      <Column
        cellRenderer={cellLinkRenderer}
        dataKey="state"
        disableSort
        flexGrow={1}
        headerRenderer={headerRenderer}
        label="State"
        width={100}
      />
      <Column
        cellRenderer={cellLinkRenderer}
        dataKey="leadStage"
        disableSort
        flexGrow={1}
        headerRenderer={headerRenderer}
        label="Contact Stage"
        width={100}
      />
      <Column
        cellRenderer={cellLinkRenderer}
        dataKey="lastActivity"
        disableSort
        flexGrow={1}
        headerRenderer={headerRenderer}
        label="Last Activity"
        width={100}
      />
    </Table>
  );
};

ListView.propTypes = {
  registerChild: PropTypes.func.isRequired
};

export default ListView;
