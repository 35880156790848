import React, { useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { actions as detailsActions } from '../../../productDetails'
import { DeleteIcon } from 'shared/Miscellaneous/Icons';
import Button from "../../../shared/Buttons"


const AssetTable = styled.table`
    margin-bottom: 30px;
    margin-top: 15px;
    width: 100%;

    th {
        text-align: left;
    }

    td {
        padding: 10px 15px 10px 0px;
    }

    tr td:last-of-type {
        padding-right: 0px;
        
        svg {
            cursor: pointer;
            display: block;
            margin: auto;
        }
    }

`

const InputWrap = styled.div`
    display: flex;
    flex-direction: row;
    width: 92%;

    input:first-of-type {
        flex-basis: 40%;
    }

    input:last-of-type {
        flex-basis: 57.5%;
        margin-left: 2.5%;
    }
`

const Field = styled.div`
    display: flex;
    flex-basis: 80%;
    flex-direction: row;
    justify-content: space-between;
    margin: 15px 0px;

    input {
        height: 30px;
    }

    button {
        margin-left: 20px;
    }
`

const AssetModalChildren = ({ setShowModal, submitAssets }) => {
    const [externalAssets, setExternalAssets] = useState([])
    const [field, setField] = useState({ assetName: '', url: '' })

    const deleteAsset = idx => {
        const assets = JSON.parse(JSON.stringify(externalAssets))
        assets.splice(idx, 1)
        setExternalAssets(assets)
    }

    const inputChange = (key, val) => {
        setField({ ...field, [key]: val })
    }

    const inputSubmit = () => {
        // fix protocol for external asset links
        if (field.url.length) {
            const hrefArr = field.url.includes('www.') ? field.url.split('www.') : field.url.split('//')
            inputChange('url', '//' + hrefArr[hrefArr.length - 1]);
        }
        setExternalAssets([...externalAssets, field])
        setField({ assetName: '', url: '' })
    }

    return (
        <div>
            {externalAssets.length > 0 &&
                <AssetTable>
                    <thead>
                        <tr>
                            <th>Asset Name</th>
                            <th>URL</th>
                            <th>Remove</th>
                        </tr>
                    </thead>
                    <tbody>
                        {externalAssets.map((elem, idx) => { 
                            return( 
                                <tr key={idx}>
                                    <td>{elem.assetName}</td>
                                    <td>{elem.url}</td>
                                    <td><DeleteIcon onClick={() => deleteAsset(idx)} /></td>
                                </tr>)
                            
                    })}
                    </tbody>
                </AssetTable>
            }
            <Field>
                <InputWrap>
                    <input placeholder="Enter Asset Name" type="text" onChange={(e) => { inputChange('assetName', e.target.value) }} value={field.assetName}></input>
                    <input placeholder="Enter URL" type="email" onChange={(e) => { inputChange('url', e.target.value) }} value={field.url}></input>
                </InputWrap>
                <Button onClick={() => inputSubmit()}>Add</Button>
            </Field>
            <div style={{ marginTop: "30px" }}>
                <Button onClick={() => submitAssets(externalAssets, setShowModal)}>Submit</Button>
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        submitAssets: async (externalAssets, setShowModal) => { 
            await dispatch(detailsActions.addExternalFiles(externalAssets)) 
            setShowModal(false)
        }
    }
}

export default connect(null, mapDispatchToProps)(AssetModalChildren)