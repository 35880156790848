import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../SvgIcon';

const ProductsIcon = (props) => (
  <SvgIcon {...props}>
    <ellipse id="shadow" className="color--light" cx="12" cy="22" rx="12" ry="2"/>
    <g className="icon--active">
      <path className="color--dark" d="M20,1h-3c-0.6,0-1,0.4-1,1v13c-1.3,0-2.4,0.8-2.8,2H5c-0.3,0-0.5,0.1-0.7,0.3l-2,2c-0.4,0.4-0.4,1,0,1.4
        C2.5,20.9,2.7,21,3,21s0.5-0.1,0.7-0.3L5.4,19h7.8c0.4,1.2,1.5,2,2.8,2c1.7,0,3-1.3,3-3c0-0.9-0.4-1.7-1-2.2V3h2c0.6,0,1-0.4,1-1
        S20.6,1,20,1z"/>
      <g>
        <path className="color--medium" d="M9,1v6h6V1H9z M13,6h-2V4h2V6z"/>
        <path className="color--medium" d="M15,13.1V8H5v8h6.4C12.1,14.5,13.4,13.4,15,13.1z M7,14v-2h3v2H7z"/>
      </g>
    </g>
    <g className="icon--inactive">
      <path className="color--medium" d="M5,16h5.6c0.6,0,1-0.4,1-1s-0.4-1-1-1H6V9h4h3v2.5c0,0.6,0.4,1,1,1s1-0.4,1-1V8V2c0-0.6-0.4-1-1-1h-4
        C9.4,1,9,1.4,9,2v5H5C4.4,7,4,7.4,4,8v7C4,15.6,4.4,16,5,16z M11,3h2v4h-2V3z"/>
      <path className="color--medium" d="M8.5,11c-0.6,0-1,0.4-1,1s0.4,1,1,1h1c0.6,0,1-0.4,1-1s-0.4-1-1-1H8.5z"/>
      <path className="color--medium" d="M20,1h-3c-0.6,0-1,0.4-1,1v12.5c-1.6,0-2.9,1.1-3.3,2.5H5c-0.3,0-0.5,0.1-0.7,0.3l-2,2
        c-0.4,0.4-0.4,1,0,1.4C2.5,20.9,2.7,21,3,21s0.5-0.1,0.7-0.3L5.4,19h7.3c0.4,1.4,1.8,2.5,3.3,2.5c1.9,0,3.5-1.6,3.5-3.5
        c0-1.2-0.6-2.2-1.5-2.9V3h2c0.6,0,1-0.4,1-1S20.6,1,20,1z M16,19.5c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5
        S16.8,19.5,16,19.5z"/>
    </g>
  </SvgIcon>
)

ProductsIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

ProductsIcon.defaultProps = {
  width: 22,
  height: 22
};

export default ProductsIcon;
