import { reducerUtil } from 'base-client';

import { reducerData as insightsData, utils as insightUtils } from 'insights';
import actions from './productInfo';

// ------------------------------------
// Product Growth
// ------------------------------------
const getProductGrowth = (stream, interval, key) => async dispatch => {
  const results = await dispatch(
    actions.insightApi('all', stream, 'sum', { interval, count: 6, key })
  );
  const { data, tick } = results || {};

  const chart = insightUtils.productChart(data, tick);
  dispatch(reducerUtil.setSlice(insightsData, insightsData.productGrowth, chart));
};

export default {
  getProductGrowth
};
