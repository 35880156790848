import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { reducerUtil } from 'base-client';

import { actions as detailsActions, reducerData as detailsData } from 'productDetails';
import detailsUtils from 'productDetails/utils';
import { UploadIcon } from 'shared/Miscellaneous/Icons';

const Container = styled.div`
  outline: none;
`;

const DragActive = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  flex-direction: column;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99999;
  transform: translate(-150px, -75px);
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8);
  width: calc(100% + 150px);
  height: 100vh;
  p {
    text-align: center;
  }
`;

const Dropzone = ({ dispatch, title, children }) => {
  const onDrop = useCallback(acceptedFiles => dispatch(detailsActions.addFiles(acceptedFiles)), []);
  const { getRootProps, isDragActive, getInputProps } = useDropzone({
    onDrop
  });

  if (isDragActive) {
    window.scrollTo(0, 0);
    document.querySelector('body').classList.add('bgDropzone');
  } else {
    document.querySelector('body').classList.remove('bgDropzone');
  }
  return (
    <Container
      {...getRootProps({
        onClick: event => event.stopPropagation()
      })}
    >
      <input {...getInputProps()} />
      {isDragActive ? (
        <DragActive>
          <UploadIcon width={40} height={40} />
          <p>Drops files to upload to {title}</p>
        </DragActive>
      ) : null}
      {children}
    </Container>
  );
};

Dropzone.propTypes = {
  dispatch: PropTypes.func.isRequired,
  title: PropTypes.string
};

Dropzone.defaultProps = {
  title: 'the product'
};

const mapStateToProps = state => {
  const values = reducerUtil.getSlice(detailsData, detailsData.values, state);
  return {
    title: values[detailsUtils.form.system][detailsUtils.systemNames.title]
  };
};

export default connect(mapStateToProps)(Dropzone);
