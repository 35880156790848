import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { List } from 'react-virtualized';

import { reducerUtil } from 'base-client';
import { InfiniteList, Spinner } from 'shared-features-client';

import { LinkButton } from 'shared/Buttons';
import sbManagerStyles from 'utils/globalStyles';

import { actions as detailsActions, reducerData as detailsData } from 'projectDetails';
import ProductImage from './ProductImage';
import ProjectSectionHeader from './ProjectSectionHeader';

const Body = styled.div`
  padding: 2rem 0 2rem 2rem;
  min-height: 32.5rem;
  font-size: 1.4rem;
  line-height: 2.5rem;
  border-right: 1px solid ${() => sbManagerStyles.accentGrey};
`;

const NoData = styled.div`
  justify-content: center;
  display: flex;
  align-items: center;
  min-height: 28.5rem;
`;

const NoProductsText = styled.p`
  font-size: 1.6rem;
  color: ${() => sbManagerStyles.greyMedium};
`;

const ProductRow = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem 0;
`;

const ImageContent = styled.div`
  height: 3rem;
  width: 3rem;
  margin: 0 2rem 0 0;
  img {
    width: 100%;
    height: 100%;
    border-radius: 0.3rem;
  }
`;

const ProductName = styled(LinkButton)`
  text-decoration: underline;
`;

const InactiveText = styled.p`
  max-height: 3rem;
  color: ${() => sbManagerStyles.greyLight};
`;

const ROW_HEIGHT = 50;

const ProductList = ({ projectId, fetchId, products = [], pagination = {}, dispatch }) => {
  useEffect(() => {
    dispatch(detailsActions.loadSavedProducts(projectId, true));
  }, []);

  const length = (products && products.length) || 0;

  /* Determine if there is more to load based on the metadata */
  let hasNextPage;
  if (pagination) {
    const { page, pageCount } = pagination;
    hasNextPage = page < pageCount;
  }

  const infiniteProps = {
    list: [...products],
    hasNextPage,
    noWindowScroller: true,
    viewHeight: 325,
    fetching: !!fetchId,
    getNextPage: () => {
      dispatch(detailsActions.loadNextPage(projectId));
    }
  };

  const rowRenderer = ({ index, key, style }) => {
    const { id: productId, name, imageId, isActive } = products[index] || {};
    return (
      <ProductRow {...{ style, key }}>
        <ImageContent>
          {isActive && imageId && <ProductImage {...{ productId, imageId }} />}
        </ImageContent>
        {isActive ? (
          <ProductName to={`/products/${productId}`}>{name}</ProductName>
        ) : (
          <InactiveText>{name}</InactiveText>
        )}
      </ProductRow>
    );
  };

  const getContentView = () => {
    if (fetchId) {
      return (
        <NoData>
          <Spinner size={50} />
        </NoData>
      );
    } else if (products.length < 1) {
      return (
        <NoData>
          <NoProductsText>
            This person hasn't added any products to this project yet.
          </NoProductsText>
        </NoData>
      );
    } else {
      return (
        <InfiniteList {...infiniteProps}>
          <List rowCount={length} rowHeight={ROW_HEIGHT} rowRenderer={rowRenderer} />
        </InfiniteList>
      );
    }
  };

  return (
    <React.Fragment>
      <ProjectSectionHeader title={`${pagination.totalRecords || 0} Products in This Project`} />
      <Body>{getContentView()}</Body>
    </React.Fragment>
  );
};

PropTypes.propTypes = {
  fetchId: PropTypes.string,
  products: PropTypes.arrayOf({}),
  pagination: PropTypes.shape()
};

const mapStateToProps = state => ({
  fetchId: reducerUtil.getSlice(detailsData, detailsData.fetchId, state),
  products: reducerUtil.getSlice(detailsData, detailsData.products, state),
  pagination: reducerUtil.getSlice(detailsData, detailsData.pagination, state)
});

export default connect(mapStateToProps)(ProductList);
