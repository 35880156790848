import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from './../SvgIcon';

const FileRvtIcon = (props) => (
  <SvgIcon {...props}>
	<g id="Canvas" transform="translate(-4933 -864)">
		<g id="File-rvt">
			<g id="Filled Icons">
				<g id="Vector">
					<path d="M 11.414 0L 2 0C 0.897 0 0 0.898 0 2L 0 18C 0 19.103 0.897 20 2 20L 14 20C 15.103 20 16 19.103 16 18L 16 4.586L 11.414 0ZM 10 6L 10 1L 15 6L 10 6Z" fill="#77899E" transform="translate(4933 864)"/>
				</g>
				<g id="RVT">
					<path d="M 4.51261 6L 3.30061 6L 2.56861 4.65C 2.47661 4.494 2.33061 4.416 2.13061 4.416L 1.79461 4.416L 1.79461 6L 0.70861 6L 0.70861 1.77L 2.72461 1.77C 3.23661 1.77 3.62061 1.882 3.87661 2.106C 4.13661 2.326 4.26661 2.646 4.26661 3.066C 4.26661 3.386 4.17861 3.658 4.00261 3.882C 3.82661 4.102 3.57861 4.25 3.25861 4.326C 3.50261 4.378 3.69661 4.536 3.84061 4.8L 4.51261 6ZM 2.55061 3.588C 2.79461 3.588 2.96861 3.55 3.07261 3.474C 3.18061 3.398 3.23461 3.274 3.23461 3.102C 3.23461 2.926 3.18061 2.8 3.07261 2.724C 2.96861 2.644 2.79461 2.604 2.55061 2.604L 1.78261 2.604L 1.78261 3.588L 2.55061 3.588ZM 8.5822 1.77L 9.6982 1.77L 7.7722 6L 6.8962 6L 4.9702 1.77L 6.1162 1.77L 7.3462 4.626L 8.5822 1.77ZM 11.6224 6L 11.6224 2.664L 10.3564 2.664L 10.3564 1.77L 14.0044 1.77L 14.0044 2.664L 12.7384 2.664L 12.7384 6L 11.6224 6Z" fill="#FFFFFF" transform="translate(4933.5 876)"/>
				</g>
			</g>
		</g>
	</g>
	<defs>
		<path id="path0_fill" d="M 11.414 0L 2 0C 0.897 0 0 0.898 0 2L 0 18C 0 19.103 0.897 20 2 20L 14 20C 15.103 20 16 19.103 16 18L 16 4.586L 11.414 0ZM 10 6L 10 1L 15 6L 10 6Z"/>
		<path id="path1_fill" d="M 4.51261 6L 3.30061 6L 2.56861 4.65C 2.47661 4.494 2.33061 4.416 2.13061 4.416L 1.79461 4.416L 1.79461 6L 0.70861 6L 0.70861 1.77L 2.72461 1.77C 3.23661 1.77 3.62061 1.882 3.87661 2.106C 4.13661 2.326 4.26661 2.646 4.26661 3.066C 4.26661 3.386 4.17861 3.658 4.00261 3.882C 3.82661 4.102 3.57861 4.25 3.25861 4.326C 3.50261 4.378 3.69661 4.536 3.84061 4.8L 4.51261 6ZM 2.55061 3.588C 2.79461 3.588 2.96861 3.55 3.07261 3.474C 3.18061 3.398 3.23461 3.274 3.23461 3.102C 3.23461 2.926 3.18061 2.8 3.07261 2.724C 2.96861 2.644 2.79461 2.604 2.55061 2.604L 1.78261 2.604L 1.78261 3.588L 2.55061 3.588ZM 8.5822 1.77L 9.6982 1.77L 7.7722 6L 6.8962 6L 4.9702 1.77L 6.1162 1.77L 7.3462 4.626L 8.5822 1.77ZM 11.6224 6L 11.6224 2.664L 10.3564 2.664L 10.3564 1.77L 14.0044 1.77L 14.0044 2.664L 12.7384 2.664L 12.7384 6L 11.6224 6Z"/>
	</defs>
  </SvgIcon>
)

FileRvtIcon.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  viewBox: PropTypes.string.isRequired,
};

FileRvtIcon.defaultProps = {
  width: 16,
  height: 20,
  viewBox: '0 0 16 20',
};

export default FileRvtIcon;