import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { DeleteIcon } from 'shared/Miscellaneous/Icons';
import Label from './Label';
import { FieldWrapper, InputWrapper, StyledInput, Error, HelpText } from './Styles';

const TextField = ({
  labelText,
  placeholder,
  required,
  className,
  type = 'text',
  inline,
  handleOnChange,
  onDelete,
  helperText,
  input,
  meta: { touched, error } = {}
}) => (
  <FieldWrapper className={inline ? 'inline' : ''}>
    {labelText && <Label {...{ required }}>{labelText}</Label>}
    <InputWrapper>
      <StyledInput
        {...input}
        {...{ placeholder, className, type }}
        className={touched && error ? 'error' : ''}
        onBlur={() => {
          if (handleOnChange) handleOnChange(input.name);
        }}
      />
      {onDelete && (
        <DeleteIcon
          onClick={onDelete}
          addClass="icon--neutral icon--hover-danger"
          width={28}
          height={28}
          viewBox="0 0 28 34"
        />
      )}
    </InputWrapper>
    {touched &&
      ((error && <Error>{error}</Error>) || (helperText && <HelpText>{helperText}</HelpText>))}
  </FieldWrapper>
);

TextField.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string
  }),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string
  }),
  type: PropTypes.string,
  labelText: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  inline: PropTypes.bool,
  helperText: PropTypes.string,
  handleOnChange: PropTypes.func.isRequired
};

export default TextField;
