import { toast } from 'react-toastify';
import uuid from 'uuid/v4';

import { default as reducerData } from './reducerData';
import { settings } from './constants'

const sendNotifications = (notifications, reducerUtil = false) => (dispatch, getState) => {
  if (!notifications) return;
  if (!Array.isArray(notifications)) notifications = [notifications];

  const newNotifications = notifications.map(({ type, message, overrideProps = {} }) => {
    const id = uuid();
    if (type === 'info') {
      toast.info(message, { ...settings, ...overrideProps })
    } else if (type === 'error') {
      toast.error(message, { ...settings, ...overrideProps })
    } else if (type === 'success') {
      toast.success(message, { ...settings, ...overrideProps })
    } else {
      toast(message, { ...settings, ...overrideProps })
    }

    if (reducerUtil) {
      setTimeout(function () {
        removeNotification(id, reducerUtil)
      }, overrideProps.autoClose)
    }

    return { id };
  });

  if (reducerUtil) {
    const oldNotifications =
      reducerUtil.getSlice(reducerData, reducerData.notifications, getState()) || [];

    dispatch(
      reducerUtil.setSlice(reducerData, reducerData.notifications, [
        ...oldNotifications,
        ...newNotifications
      ])
    );
  }
};

const removeNotification = (id, reducerUtil = false) => (dispatch, getState) => {
  if (reducerUtil) {
    const notifications = reducerUtil.getSlice(reducerData, reducerData.notifications, getState());
    const toRemove = notifications.find(({ id: itemId }) => itemId === id);
    if (toRemove) {
      const results = notifications.filter(n => n.id !== id);
      dispatch(reducerUtil.setSlice(reducerData, reducerData.notifications, results));
    }
  }
};

export { sendNotifications, removeNotification };