import React from 'react';
import PropTypes from 'prop-types';

import Loading from 'shared/Miscellaneous/Loading/ProductListLoading';
import LoadingSingle from 'shared/Miscellaneous/Loading/ProductListLoadingSingle';
import ListView from './ListView';
import { querySortDir } from 'productList/utils';

import 'react-virtualized/styles.css';
import '../../styles/global/table.scss';

const ListViewContainer = ({
  onRowsRendered,
  height,
  width,
  productList,
  bulkProductList,
  sortDir,
  setSort,
  ...props
}) => {
  const rowCount = productList ? productList.length : 0;
  const noRowsRenderer = productList ? () => <div /> : Loading;
  const rowGetter = ({ index }) => {
    return productList[index] || {};
  };
  const rowRenderer = ({ className, columns, index, key, style, rowData }) => {
    const content = rowData.id ? columns : <LoadingSingle />;
    return (
      <div className={className} key={key} role="row" style={style}>
        {content}
      </div>
    );
  };
  rowRenderer.propTypes = {
    className: PropTypes.any.isRequired,
    columns: PropTypes.any.isRequired,
    index: PropTypes.any.isRequired,
    key: PropTypes.any.isRequired,
    style: PropTypes.any.isRequired
  };

  const sortDirection = sortDir === querySortDir.ascending ? 'ASC' : 'DESC';

  return (
    <ListView
      {...{
        noRowsRenderer,
        rowCount,
        rowGetter,
        rowRenderer,
        onRowsRendered,
        height,
        width,
        sortDirection
      }}
      {...props}
      onHeaderClick={({ dataKey }) => setSort(dataKey)}
      headerHeight={55}
      overscanRowCount={10}
      rowHeight={70}
      headerClassName="table-header"
      rowClassName="table-row hasTooltip"
      gridClassName="table__visible-items"
      scrollToAlignment="start"
    />
  );
};

ListViewContainer.propTypes = {
  onRowsRendered: PropTypes.func.isRequired,
  height: PropTypes.number,
  width: PropTypes.number,
  productList: PropTypes.array,
  bulkProductList: PropTypes.array,
  setSort: PropTypes.func.isRequired,
  sortDir: PropTypes.string
};

ListViewContainer.defaultProps = {
  height: 0,
  width: 0
};

export default ListViewContainer;
