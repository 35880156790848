import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { reducerUtil } from 'base-client';
import Grid from './GridViewContainer';
import { InfiniteList } from 'shared-features-client';
import { AutoSizer, InfiniteLoader, WindowScroller } from 'react-virtualized';

import { querySortBy } from 'leadsTwo/utils';
import { listOptions, storageKey } from '../../constants';
import Filters from './Filters';
import HeaderContainer from './HeaderContainer';
import Card from 'shared/PageLayout/Card';
import ListViewContainer from './ListViewContainer';
import { actions as searchActions, reducerData as listData } from 'leadsTwo';
import { reducerData as permissionsData } from 'permissions';
import { reducerData as jobsData } from 'jobs';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  &.grid {
    .card {
      background-color: inherit;
      box-shadow: none;
    }
  }
  .ReactVirtualized__Table__headerRow {
    border-bottom: 1px solid #f0f3f6;
    .table-header {
      text-transform: uppercase;
      font-weight: 500;
      color: #77899e;
      cursor: pointer;
    }
  }
`;

const GridCard = styled(Card)`
  background-color: inherit;
  box-shadow: none;
  height: 100%;
  flex: 0 0 80%;
  margin-top: 0rem;
  overflow-y: hidden;
`;

const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  &.grid {
    .card {
      background-color: inherit;
      box-shadow: none;
    }
  }
  .card {
    height: 100%;
    flex: 0 0 80%;
    margin-bottom: 0;
  }
`;

class UserList extends Component {
  state = { listMode: localStorage.getItem(storageKey) || listOptions[0].key };
  componentDidMount() {
    this.startSearch();
  }

  componentDidUpdate(prevProps) {
    const { canView: prevView } = prevProps;
    const { canView } = this.props;

    this.startSearch(prevView !== canView);
  }

  startSearch = permissionChange => {
    const { lastSearch } = this.state;
    const {
      canView,
      dispatch,
      history: {
        location: { search }
      }
    } = this.props;

    if (!canView) return;

    if (permissionChange || search !== lastSearch) {
      this.setState({ lastSearch: search });
      dispatch(searchActions.search());
    }
  };

  setListMode = e => {
    const listMode = e.target.value;
    localStorage.setItem(storageKey, listMode);
    this.setState({ listMode });
  };

  setSort = header => {
    const { dispatch } = this.props;

    const newSortBy = querySortBy[header];
    if (newSortBy) dispatch(searchActions.setSort(newSortBy));
  };

  render() {
    const { canView, list, selectedUsers, query, meta, fetchId, dispatch } = this.props;

    if (!canView) return null;

    const { listMode } = this.state;
    const grid = this.state.listMode === listOptions[0].key;
    const optionKeys = listOptions.map(option => option.key);
    const optionIcons = listOptions.map(option => <option.icon />);

    const length = list ? list.length : 0;

    /* Determine if there is more to load based on the metadata */
    let hasNextPage;
    if (meta) {
      const {
        totalHits,
        pagination: { page, limit }
      } = meta;
      hasNextPage = page * limit < totalHits;
    }

    const updateFilter = ({ attribute, facets }) => {
      return dispatch(searchActions.updateFilter({ attribute, facets }));
    };

    const { filters: baseFilters = [] } = meta || {};
    const { filters: selected, sortBy, sortDir } = query || {};

    const viewProps = {
      users:
        list &&
        list.map(item => ({
          ...item,
          isChecked: selectedUsers.includes(item.id)
        })),
      sortBy,
      sortDir,
      setSort: this.setSort,
      meta,
      selectedUsers
    };

    const infiniteProps = {
      list:
        list &&
        list.map(item => ({
          ...item,
          isChecked: selectedUsers.includes(item.id)
        })),
      hasNextPage,
      fetching: !!fetchId,
      getNextPage: () => dispatch(searchActions.nextPage())
    };
    const rowCount = hasNextPage ? length + 1 : length + 0;
    const isRowLoaded = ({ index }) => !hasNextPage || index < length;
    const loadMoreRows = !!fetchId ? () => null : () => dispatch(searchActions.nextPage());

    return (
      <Container>
        <HeaderContainer
          {...{ grid, optionKeys, optionIcons, listMode }}
          setListMode={this.setListMode}
        />
        <SearchContainer className={grid ? 'grid' : 'table'}>
          <Filters
            {...{
              filters: baseFilters.sort((a, b) =>
                parseInt(a.priority) > parseInt(b.priority) ? 1 : -1
              ),
              selected,
              updateFilter
            }}
          />
          {grid ? (
            <GridCard>
              <InfiniteLoader {...{ rowCount, loadMoreRows, isRowLoaded }}>
                {({ onRowsRendered, registerChild }) => (
                  <WindowScroller>
                    {({ height, isScrolling, scrollTop }) => (
                      <AutoSizer disableHeight>
                        {({ width }) => (
                          <Grid
                            className="user-grid"
                            {...viewProps}
                            {...{
                              autoHeight: true,
                              registerChild,
                              onRowsRendered,
                              isScrolling,
                              scrollTop,
                              height,
                              width,
                              rowCount,
                              list
                            }}
                          />
                        )}
                      </AutoSizer>
                    )}
                  </WindowScroller>
                )}
              </InfiniteLoader>
            </GridCard>
          ) : (
            <Card>
              <InfiniteList {...infiniteProps}>
                <ListViewContainer {...viewProps} />
              </InfiniteList>
            </Card>
          )}
        </SearchContainer>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  canView: reducerUtil.getSlice(permissionsData, permissionsData.manager, state),
  list: reducerUtil.getSlice(listData, listData.list, state),
  selectedUsers: reducerUtil.getSlice(listData, listData.selected, state) || [],
  query: reducerUtil.getSlice(listData, listData.query, state),
  meta: reducerUtil.getSlice(listData, listData.meta, state),
  fetchId: reducerUtil.getSlice(listData, listData.fetchId, state),
  jobList: reducerUtil.getSlice(jobsData, jobsData.list, state)
});

export default withRouter(connect(mapStateToProps)(UserList));
