/*
Uncontrolled component. Differentiates from old Chart component in that data is received from ownProps.
*/

/* vendor imports */
import { connect } from 'react-redux';

/* system imports */
import ChartContainer from './ChartContainer';

const mapStateToProps = (state, ownProps) => ({
  data: ownProps.data,
  namespace: ownProps.namespace,
  title: ownProps.title,
});

export default connect(mapStateToProps)(ChartContainer);
