import { api, config, reducerUtil } from 'base-client';

import { reducerData as attributesData } from 'productAttributes';
import fileDownload from 'utils/fileDownload';

let timer;
const oneSecond = 1000;
const twentySeconds = 20 * oneSecond;

const startSharedParametersData = () => dispatch => {
  if (!timer) timer = setTimeout(() => dispatch(getSharedParameterData()), twentySeconds);
  return dispatch(getSharedParameterData());
};

const stopSharedParametersData = () => () => {
  if (timer) clearTimeout(timer);
  timer = undefined;
};

const getSharedParameterData = () => async dispatch => {
  try {
    const result = await dispatch(api.actions.get('sharedParameters'));
    const data = {
      filename: result.sharedParameterFileName,
      updated: result.sharedParameterUploadedAt,
      total: result.parameters.length
    };
    if (!data.filename) {
      dispatch(
        reducerUtil.setSlice(attributesData, attributesData.sharedParametersData, undefined)
      );
    } else {
      dispatch(reducerUtil.setSlice(attributesData, attributesData.sharedParametersData, data));
    }
  } catch (error) {
    dispatch(config.actions.error(error));
  }
};

const downloadSharedParametersFile = () => async dispatch => {
  try {
    const result = await dispatch(api.actions.get('sharedParameters/download'));
    fileDownload(result, 'Shared Parameters.txt');
  } catch (error) {
    dispatch(config.actions.error(error));
  }
};

const deleteSharedParametersFile = () => async dispatch => {
  try {
    await dispatch(api.actions.delete('sharedParameters'));
    dispatch(startSharedParametersData());
  } catch (error) {
    dispatch(config.actions.error(error));
  }
};

const downloadSharedParametersReport = () => async dispatch => {
  try {
    const result = await dispatch(api.actions.get('sharedParameters/downloadReport'));
    fileDownload(result, 'Shared Parameters.zip');
  } catch (error) {
    dispatch(config.actions.error(error));
  }
};

export default {
  startSharedParametersData,
  stopSharedParametersData,
  downloadSharedParametersFile,
  deleteSharedParametersFile,
  downloadSharedParametersReport
};
