import loadActions from './load';
import assetsActions from './assets';
import jobActions from './job';
import saveActions from './save';
import miscActions from './misc';
import modalActions from './modals';
import sustainabilityActions from './sustainability';

export default {
  ...loadActions,
  ...jobActions,
  ...saveActions,
  ...miscActions,
  ...assetsActions,
  ...modalActions,
  ...sustainabilityActions
};
