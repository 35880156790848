import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './assets/radio-group.scss';

const RadioGroup = ({
  button,
  controlFunc,
  namespace,
  optionIcons,
  options,
  selectedOption,
  style
}) => (
  <div className='control-buttons' style={style}>
    {options.map((opt, index) => {
      const id = `${namespace}:${opt}`;
      return (
        <div key={id}>
          <input
            id={id}
            className='radio-btn'
            name={namespace}
            onChange={controlFunc}
            value={opt}
            checked={selectedOption === opt}
            type='radio'
          />

          <label
            htmlFor={id}
            className={cn('radio-btn-label', { 'button-label': button })}
          >
            {optionIcons ? optionIcons[index] : opt}
          </label>
        </div>
      );
    })}
  </div>
);

RadioGroup.propTypes = {
  button: PropTypes.bool,
  controlFunc: PropTypes.func.isRequired,
  namespace: PropTypes.string.isRequired,
  optionIcons: PropTypes.arrayOf(PropTypes.node),
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedOption: PropTypes.string,
  style: PropTypes.object
};

RadioGroup.defaultProps = {
  button: false,
  optionIcons: null,
  selectedOption: '',
  style: {}
};

export default RadioGroup;
