import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Field } from 'redux-form';

import { CheckboxField, TextField, NumberField } from './FormFields';

const StyledWrapper = styled.div`
  padding-right: 1.5rem;
`;

const CharacteristicsList = ({ data, characteristics, handleOnChange, onDeleteEnvChar }) => (
  <StyledWrapper>
    {data
      .sort((a, b) => {
        const { name: aName } = characteristics.find(item => item.id === a) || {};
        const { name: bName } = characteristics.find(item => item.id === b) || {};
        return aName.toLowerCase().localeCompare(bName.toLowerCase());
      })
      .map(id => {
        const { type, name, defaultValue } = characteristics.find(item => item.id === id) || {};
        const formProps = {
          name: id,
          labelText: name,
          placeholder: defaultValue,
          handleOnChange,
          onDelete: () => onDeleteEnvChar(id)
        };
        switch (type) {
          case 'compliant':
            return <Field key={id} {...formProps} component={CheckboxField} inputPos="right" />;
          case 'number':
            return <Field key={id} {...formProps} component={NumberField} inline />;
          case 'percentage':
            return <Field key={id} {...formProps} component={NumberField} inline percentage />;
          default:
            return <Field key={id} {...formProps} component={TextField} inline />;
        }
      })}
  </StyledWrapper>
);

CharacteristicsList.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any
    })
  ),
  handleOnChange: PropTypes.func.isRequired,
  onDeleteEnvChar: PropTypes.func.isRequired
};

CharacteristicsList.defaultProps = {
  data: [],
  characteristics: []
};

export default CharacteristicsList;
