import React from 'react';
import PropTypes from 'prop-types';

import { EditIcon, DeleteIcon } from 'shared/Miscellaneous/Icons';

const optionsCellRenderer = ({ cellData, rowData }) => {
  if (rowData === 'loading') return <div className="placeholder" style={{ width: 100 }} />;
  else if (cellData === null || !rowData.canEdit) return <span />;
  return (
    <div>
      <EditIcon onClick={rowData.onClickEdit} addClass="icon--table" width={20} height={20} />
      <DeleteIcon
        onClick={rowData.onClickDelete}
        addClass="icon--table icon--hover-danger"
        width={21}
        height={21}
      />
    </div>
  );
};

optionsCellRenderer.propTypes = {
  cellData: PropTypes.any.isRequired,
  rowData: PropTypes.shape({
    category: PropTypes.any.isRequired,
    index: PropTypes.number.isRequired,
    key: PropTypes.string.isRequired,
    manufacturer: PropTypes.any,
    manufacturerImage: PropTypes.any,
    name: PropTypes.string.isRequired,
    omniclass: PropTypes.any,
    onClick: PropTypes.func.isRequired,
    productImage: PropTypes.any,
    valid: PropTypes.bool.isRequired,
    canEdit: PropTypes.bool.isRequired
  }).isRequired
};

export default optionsCellRenderer;
