import React, { useState } from 'react';
import styled from 'styled-components';

import { DeleteIcon, CheckboxIcon, AlertIcon } from 'shared/Miscellaneous/Icons';
import sbManagerStyles from 'utils/globalStyles';

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 2rem;
`;
const EditContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 3.5rem;
  width: 80%;
  cursor: pointer;
  border: 1px solid ${props => (props.duplicate ? sbManagerStyles.red : sbManagerStyles.greyLight)};
  border-radius: 4px;
`;

const Input = styled.input`
  width: 100%;
  padding-left: 1rem;
  ::placeholder {
    ${() => sbManagerStyles.greyDark}
    opactiy: 1;
  }
  outline: none;
`;

const Buttons = styled.div`
  width: 8rem;
  display: flex;
  background-color: ${() => sbManagerStyles.greyMedium};
  justify-content: space-between;
`;

const Button = styled.div`
  display: flex;
  height: 3.5rem;
  width: 4rem;
  border: 1px solid ${() => sbManagerStyles.greyDark};
  align-items: center;
  justify-content: center;
`;

const CheckMark = styled(CheckboxIcon)`
  fill: ${() => sbManagerStyles.greyMedium};
  margin: 0.5rem 0 0 0.5rem;
`;

const DeleteOption = styled(DeleteIcon)`
  fill: ${() => sbManagerStyles.white};
  margin-bottom: 0.5rem;
`;

const Error = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  width: 30rem;
  justify-content: space-between;
  &:before {
    position: absolute;
    top: 50%;
    left: 70%;
    margin-left: -25%;
    content: '';
    width: 0;
    height: 0;
    border-bottom: solid 5px ${() => sbManagerStyles.black};
    border-left: solid 5px transparent;
    border-right: solid 5px transparent;
  }
  background-color: ${() => sbManagerStyles.black};
  color: ${() => sbManagerStyles.red};
`;

const Message = styled.div`
  color: ${() => sbManagerStyles.white};
`;

const EditProductOption = ({ id, name, onClickClose, onClickSave, options }) => {
  const [value, setValue] = useState(name);
  const [duplicate, setDuplicate] = useState(false);
  const handleChange = value => {
    const isDuplicate =
      value.trim() !== name ? options.find(item => item.name === value.trim()) : false;
    setDuplicate(isDuplicate);
    setValue(value);
  };

  return (
    <Container>
      <EditContainer duplicate={duplicate}>
        <Input
          placeholder={'Enter a name'}
          onChange={e => handleChange(e.target.value)}
          {...{ value }}
        />
        <Buttons>
          <Button
            onClick={() => {
              if (value && !duplicate) onClickSave(id, value);
            }}
          >
            <CheckMark height={30} width={30} />
          </Button>
          <Button onClick={() => onClickClose()}>
            <DeleteOption height={20} width={20} />
          </Button>
        </Buttons>
      </EditContainer>
      {duplicate && (
        <Error>
          <AlertIcon width={15} height={15} />
          <Message>Product Option must have a unique name</Message>
        </Error>
      )}
    </Container>
  );
};

export default EditProductOption;
