import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { FormControl } from 'react-bootstrap';
import cn from 'classnames';

import { PageHeader } from 'shared';
import TextContainer from './TextContainer';

const Title = props => (
  <Field {...props} component={TextContainer}>
    <TitleField />
  </Field>
);

const TitleField = ({ edit, bsClass, input, ...props }) => {
  if (!edit) {
    const { value } = input;
    return <PageHeader className="editable-value" title={value || ''} />;
  }
  const { onChange } = input;
  return (
    <FormControl
      {...props}
      {...input}
      type="text"
      bsClass={cn('form-control', bsClass)}
      onChange={evt => onChange(evt)}
      className="input-editing--text"
    />
  );
};

TitleField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string
  }),
  bsClass: PropTypes.object,
  edit: PropTypes.bool
};

TitleField.defaultProps = {
  input: { value: '', onChange: () => undefined }
};

export default Title;
export { TitleField };
