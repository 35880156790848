import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import sbManagerStyles from 'utils/globalStyles';

const StyledLabel = styled.label`
  flex: 0 0 20%;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.25;
  padding-right: 1rem;
  &.required {
    position: relative;
    &:after {
      content: '*';
      margin-left: 0.5rem;
      color: ${sbManagerStyles.red};
    }
  }
`;

const Label = ({ required, children, ...props }) => (
  <StyledLabel className={required && 'required'} {...props}>
    {children}
  </StyledLabel>
);

Label.propTypes = {
  required: PropTypes.bool,
  children: PropTypes.any
};

export default Label;
