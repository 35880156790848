/* vendor imports */
import React from 'react';
import { NavLink } from 'react-router-dom';

/* system imports */

const LinkButton = props => (
  <NavLink style={{ display: 'block', height: '100%', color: 'inherit' }} {...props} />
);

export default LinkButton;
