/* vendor imports */
import React from 'react';
import PropTypes from 'prop-types';

/* system imports */
import Component from '../components';
import { pages } from 'productAttributes/utils';

const Container = ({ page, ...props }) => {
  switch (page) {
    case pages.confirm:
      return <Component.Confirm {...props} />;
    default:
      return <Component.Main {...props} />;
  }
};

Container.propTypes = {
  page: PropTypes.string
};

Container.defaultProps = {
  page: pages.main
};

export default Container;
