/* vendor imports */
import React from 'react';
import '../assets/404.scss';

const Page404 = () => (
  <div className="page404">
    <img className="img404" src="/404.svg" />
    <div className="page404-title">Hmm, there doesn't seem to be anything here</div>
    <div className="page404-content">Please check the URL and try again.</div>
  </div>
);

export default Page404;
