import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { DropdownButton } from 'react-bootstrap';

import './../assets/dropdown-button.scss';

const TITLE_CLASSNAME = 'dropdown__title';
const BUTTON_CLASSNAME = 'button';
const DEFAULT_CLASSNAME = 'button--default';
const DROPDOWN_CLASSNAME = 'button--dropdown';
const ICON_CLASSNAME = 'dropdown--icon';

const DropdownButtonIndex = ({ children, title, icon, color, ...props }) => {
  const buttonColor = color ? `button--${color}` : DEFAULT_CLASSNAME;

  return (
    <DropdownButton
      {...props}
      title={<div className={TITLE_CLASSNAME}>{title}{icon}</div>}
      className={cn(BUTTON_CLASSNAME, buttonColor, DROPDOWN_CLASSNAME, { [ICON_CLASSNAME]: icon })}
      bsStyle={null}
      noCaret
    >
      {children}
    </DropdownButton>
  );
};

DropdownButtonIndex.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.node,
  color: PropTypes.string,
};

DropdownButtonIndex.defaultProps = {
  icon: null,
  color: null,
};

export default DropdownButtonIndex;
