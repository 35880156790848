import React from 'react';
import PropTypes from 'prop-types';
import {Tabs} from 'react-bootstrap';

import './../assets/tabs.scss';
import './../assets/dashboard-tabs.scss';

const TabsGroup = ({defaultActiveKey, children}) => (
  <Tabs
    id="TabsGroupGroup"
    defaultActiveKey={ defaultActiveKey }    
  >
    {children}
  </Tabs>
);

TabsGroup.propTypes = {
  defaultActiveKey: PropTypes.any.isRequired,
  children: PropTypes.node.isRequired,
};

export default TabsGroup;
