/* vendor imports */
import React from 'react';
import PropTypes from 'prop-types';
import 'react-virtualized/styles.css';

const Loading = () => (
  <div>Loading</div>
);

Loading.propTypes = {
};

Loading.defaultProps = {
};

export default Loading;
