import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { DateRangePicker } from 'shared-features-client';

import { actions as analyticActions } from 'analyticsDashboard';

const Container = styled.div`
  display: flex;
  position: absolute;
  top: 11px;
  right: 15px;
  cursor: pointer;
  .DateRangePickerInput {
    border-width: 1px;
    height: 30px;
  }
`;

const ActionsBar = ({ interval, dispatch }) => (
  <Container>
    <DateRangePicker
      interval={interval}
      onSelectInterval={({ startDate, endDate }) =>
        dispatch(analyticActions.setDate({ startDate, endDate }))
      }
    />
  </Container>
);

ActionsBar.propTypes = {
  interval: PropTypes.shape({
    startDate: PropTypes.any,
    endDate: PropTypes.any
  }),
  dispatch: PropTypes.func.isRequired
};

export default connect()(ActionsBar);
