import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from './../SvgIcon';

const EditOutlineIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M20.821,3.179C20.061,2.418,19.049,2,17.974,2c-1.074,0-2.086,0.418-2.846,1.179L3.719,14.587
    c0,0-0.001,0.003-0.003,0.004c-0.081,0.082-0.219,0.298-0.261,0.461L2.03,20.756c-0.086,0.34,0.015,0.702,0.263,0.949l0.001,0.001
    l0.001,0.001c0.248,0.248,0.608,0.349,0.949,0.264l5.704-1.427c0.163-0.042,0.38-0.18,0.461-0.261
    c0.001-0.001,0.004-0.003,0.004-0.003L20.822,8.873c0.76-0.761,1.179-1.771,1.178-2.847c0-1.075-0.418-2.086-1.178-2.847
    L20.821,3.179z M14.411,6.728l2.863,2.863l-8.568,8.568l-2.863-2.863L14.411,6.728z M4.375,19.626l0.595-2.374l1.78,1.781
    L4.375,19.626z M19.409,4.594c0.764,0.765,0.764,2.099,0,2.864l-0.719,0.72l-2.866-2.865l0.721-0.719
    C17.31,3.83,18.644,3.829,19.409,4.594z"/>
  </SvgIcon>
)

EditOutlineIcon.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  viewBox: PropTypes.string.isRequired,
};

EditOutlineIcon.defaultProps = {
  width: 24,
  height: 24,
  viewBox: '0 0 24 24',
};

export default EditOutlineIcon;