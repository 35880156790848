import React from 'react';
import BaseFileInput from 'react-fine-uploader/file-input';
import StyleableElement from 'react-fine-uploader/file-input/styleable-element';

class FileInput extends BaseFileInput {
  render() {
    const { text, uploader, ...elementProps } = this.props; // eslint-disable-line no-unused-vars
    const onFileSelected = (event) => {
      this.setState({ key: newKey() });
      if (!event.target.files) {
        return;
      }
      Array.from(event.target.files).forEach((file) => {
        const found = uploader.methods.getUploads({
          name: file.name,
          size: file.size,
          status: uploader.qq.status.UPLOADING,
        });
        if (found.length < 1) {
          uploader.methods.addFiles(file);
        }
      });
    };
    return (
      <StyleableElement {...elementProps} key={this.state.key} onChange={onFileSelected}>
        {this.props.children || <span>{ elementProps.multiple ? text.selectFiles : text.selectFile }</span>}
      </StyleableElement>
    );
  }
}

const newKey = () => Date.now();

export default FileInput;
