import React from 'react';

import { Row, Column } from 'shared-features-client';

import ProjectInfoCard from './ProjectInfoCard';
import Address from './Address';

const ProjectInfoTop = ({ ...generalData }) => {
  if (!generalData) return null;

  const { project_type, owner_type, estimated_ship_date, address } = generalData;

  return (
    <Row>
      <Column>
        <Address address={address} defaultValue="-" />
      </Column>
      <Column>
        <ProjectInfoCard title="Type" content={project_type} defaultValue="-" />
        <ProjectInfoCard title="Owner Type" content={owner_type} defaultValue="-" />
        <ProjectInfoCard
          title="Estimated Ship Date"
          content={estimated_ship_date}
          defaultValue="-"
        />
      </Column>
    </Row>
  );
};

export default ProjectInfoTop;
