export default {
  name: 'projects',
  analyticsData: 'analyticsData',
  metaAnalytics: 'metaAnalytics',
  metaProjectList: 'metaProjectList',
  query: 'query',
  projectList: 'projectList',
  userProjectList: 'userProjectList',
  fetchId: 'fetchId'
};
