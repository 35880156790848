/* vendor imports */
import React from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";

/* system imports */
import Button from "../../Buttons";
import { ChevronLeftIcon } from "../../Miscellaneous/Icons";

const Header = ({ toSelect, csvMode }) => (
  <div className="title-container">
    {toSelect && (
      <Button
        buttonStyle="link"
        onClick={toSelect}
        icon={<ChevronLeftIcon width={11} height={16} />}
      />
    )}
    <Modal.Title componentClass="h3">
      {csvMode ? "Import CSV" : "Create a Product"}
    </Modal.Title>
  </div>
);

Header.propTypes = {
  toSelect: PropTypes.func,
  csvMode: PropTypes.bool
};

Header.defaultProps = {
  toSelect: undefined,
  csvMode: undefined
};

export default Header;
