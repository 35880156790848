import BaseCancel from 'react-fine-uploader/cancel-button';

class Cancel extends BaseCancel {
  componentWillMount() {
    const upload = this.props.uploader.methods.getUploads({ id: this.props.id });
    if (upload && upload.status) {
      this._onStatusChange(upload.id, null, upload.status); // eslint-disable-line no-underscore-dangle
    }
  }
}

export default Cancel;
