import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { actions as detailsActions } from 'productDetails';

const Image = ({ dispatch, asset }) => {
  const [image, setImage] = useState();
  const { id, mimetype } = asset || {};

  useEffect(() => {
    if (id && mimetype.match(/.*image.*/)) {
      const getImage = async () => {
        const newImage = await dispatch(detailsActions.getImage(id));
        setImage(newImage);
      };
      getImage();
    } else setImage(undefined);
  }, [id]);

  if (!image) return null;

  return <img src={image} alt="" />;
};

Image.propTypes = {
  dispatch: PropTypes.func.isRequired,
  asset: PropTypes.shape({
    id: PropTypes.string,
    mimetype: PropTypes.string
  })
};

export default connect()(Image);
