import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../SvgIcon';

const LinkIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M2.809,16.226l2.121-2.122l-0.353-0.352c-0.128-0.128-0.147-0.277-0.147-0.355c0-0.077,0.019-0.226,0.147-0.353l8.486-8.486c0.127-0.127,0.276-0.146,0.353-0.146c0.078,0,0.227,0.019,0.354,0.147l0.353,0.353l2.122-2.122L15.89,2.437c-1.322-1.323-3.628-1.322-4.95-0.001l-8.485,8.486c-0.661,0.66-1.025,1.54-1.025,2.475c0,0.935,0.364,1.814,1.025,2.476L2.809,16.226z"/>
    <path d="M21.193,7.74l-2.121,2.121l0.354,0.354c0.194,0.194,0.194,0.511,0,0.706l-8.485,8.485
      c-0.127,0.128-0.276,0.147-0.354,0.147c-0.078,0-0.226-0.019-0.354-0.146l-0.354-0.354l-2.121,2.122l0.354,0.353c0.661,0.661,1.54,1.025,2.475,1.025c0.935,0,1.814-0.364,2.475-1.025l8.486-8.485c1.364-1.366,1.364-3.585,0-4.949L21.193,7.74z"/>
    <rect x="15.451" y="2.032" transform="matrix(0.707 0.7072 -0.7072 0.707 9.9396 -9.9269)" width="3" height="10.001"/>
    <rect x="5.551" y="11.932" transform="matrix(0.7071 0.7071 -0.7071 0.7071 14.0384 -0.0266)" width="3" height="10"/>
  </SvgIcon>
)

LinkIcon.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  viewBox: PropTypes.string.isRequired,
};

LinkIcon.defaultProps = {
  width: 16,
  height: 14,
  viewBox: '0 0 24 22',
};

export default LinkIcon;
