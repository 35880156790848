import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import FormulaContainer from './FormulaContainer';
import Checkbox from 'shared/InputGroups/Checkbox';

const Check = props => (
  <Field {...props} component={FormulaContainer}>
    <CheckField />
  </Field>
);

const CheckField = ({ value, onChange, disabled }) => {
  const checkProps = {
    isChecked: value,
    isLocked: disabled,
    onChange: () => onChange({ target: { value: !value } })
  };
  return <Checkbox {...checkProps} size={18} viewBox="0 0 18 18" />;
};

CheckField.propTypes = {
  value: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool
};

export default Check;
