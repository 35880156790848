import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { reducerUtil, config } from 'base-client';
import { Row, SortDropdown } from 'shared-features-client';

import { actions as projectsAnalyticsActions } from 'projectsAnalytics';

import { actions as projectsActions, reducerData as projectsData } from 'projects';

import { PageHeader, SectionHeader } from 'shared';
import ProjectsAnalytics from 'projectsAnalytics';
import Button from 'shared/Buttons';
import { ImportIcon } from 'shared/Miscellaneous/Icons';
import sbManagerStyles from 'utils/globalStyles';
import { DateRangePicker, defaultISOInterval } from 'shared-features-client';
import ProjectsView from './ProjectsView';
import sortOptions from '../sortOptions';

import { configMap } from 'configurations';

const ProjectHeaderTools = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 30rem;
`;

const StyledProjectsList = styled.div`
  padding-top: 1.6rem;
  margin-top: 2rem;
  border-top: 1px solid ${() => sbManagerStyles.accentGrey};
`;

const StyledDropdown = styled(SortDropdown)`
  select {
    font-family: inherit;
    font-weight: 500;
    font-size: 1.4rem;
    border: 2px solid ${() => sbManagerStyles.greyMedium};
    color: ${() => sbManagerStyles.greyMedium};
    padding-right: 2rem;
    border-radius: 3px;
    height: 3.1rem;
  }
`;
const ExportButton = styled(Button)`
  margin-left: 1rem;
`;

class ProjectsContainer extends React.Component {
  state = {};

  fetchSearchData = () => {
    const {
      history: {
        location: { search }
      }
    } = this.props;

    const { lastSearch } = this.state;

    if (search !== lastSearch) {
      const { dispatch } = this.props;
      this.setState({ lastSearch: search });
      dispatch(projectsAnalyticsActions.getProjectsData());
      dispatch(projectsActions.getProjectList(true));
    }
  };

  componentDidMount() {
    const { dispatch } = this.props;
    let sort = sortOptions[0];
    dispatch(
      reducerUtil.setSlice(projectsData, projectsData.metaProjectList, {
        pagination: {},
        sort
      })
    );
    this.fetchSearchData();
  }

  componentDidUpdate() {
    this.fetchSearchData();
  }

  updateFilter = ({ attribute, facets }) => {
    const { dispatch } = this.props;
    dispatch(projectsAnalyticsActions.updateFilter({ attribute, facets }));
  };

  handleSortChange = val => {
    const { dispatch, metaProjectList } = this.props;
    let sort = sortOptions.find(o => o.name === val);
    dispatch(
      reducerUtil.setSlice(projectsData, projectsData.metaProjectList, { ...metaProjectList, sort })
    );
    dispatch(projectsActions.getProjectList(true));
  };

  render() {
    const { data, query, dispatch, projectList, metaProjectList, fetchId } = this.props;

    if (!data) {
      return null;
    }

    const { sort } = metaProjectList || {};
    const { sortBy, sortDir, name: sortName } = sort || {};
    const { dateRange } = query || {};

    let interval = defaultISOInterval;
    if (dateRange) {
      const { start: startDate, end: endDate } = dateRange;
      interval = {
        startDate,
        endDate
      };
    }

    const viewProps = {
      fetchId,
      sortBy,
      sortDir,
      setSort: ({ sortBy, sortDir }) => {
        const { dispatch, metaProjectList } = this.props;
        let sort = sortOptions.find(o => o.sortBy === sortBy && o.sortDir === sortDir);
        dispatch(
          reducerUtil.setSlice(projectsData, projectsData.metaProjectList, {
            ...metaProjectList,
            sort
          })
        );
        dispatch(projectsActions.getProjectList(true));
      }
    };

    const disableCompany = dispatch(
      config.actions.getData(configMap.disabled.name, configMap.disabled.projectTableCompany.name)
    );

    let displayOptions = sortOptions;
    if (disableCompany) {
      displayOptions = sortOptions.filter(
        ({ sortBy }) => !['user.firstName', 'user.profile.companyName'].includes(sortBy)
      );
    }

    return (
      <div className="projects-card">
        <PageHeader title="Projects Analytics">
          <DateRangePicker
            {...{ interval }}
            onSelectInterval={({ startDate, endDate }) =>
              dispatch(projectsAnalyticsActions.setDate({ startDate, endDate }))
            }
          />
        </PageHeader>
        <Row>
          <ProjectsAnalytics {...this.props} />
        </Row>
        <StyledProjectsList>
          <SectionHeader title="Projects">
            <ProjectHeaderTools>
              <StyledDropdown
                {...{
                  sortOptions: displayOptions,
                  selected: sortName,
                  handleChange: this.handleSortChange
                }}
              />
              <ExportButton
                icon={<ImportIcon />}
                onClick={() => dispatch(projectsActions.exportData())}
                className="button button--default"
              >
                Export to CSV
              </ExportButton>
            </ProjectHeaderTools>
          </SectionHeader>
          <ProjectsView list={projectList} {...viewProps} {...this.props} />
        </StyledProjectsList>
      </div>
    );
  }
}

ProjectsContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  data: PropTypes.shape({
    projectsUpdated: PropTypes.shape({
      count: PropTypes.number,
      change: PropTypes.number,
      trend: PropTypes.string
    }),
    averageBudget: PropTypes.shape({
      count: PropTypes.number,
      change: PropTypes.number,
      trend: PropTypes.string
    }),
    averageSize: PropTypes.shape({
      count: PropTypes.number,
      change: PropTypes.number,
      trend: PropTypes.string
    }),
    highValueCustomers: PropTypes.shape({
      count: PropTypes.number,
      change: PropTypes.number,
      trend: PropTypes.string
    }),
    projectPhases: PropTypes.arrayOf(
      PropTypes.shape({
        count: PropTypes.number,
        name: PropTypes.string
      })
    ),
    topProjectTypes: PropTypes.arrayOf(
      PropTypes.shape({
        count: PropTypes.number,
        name: PropTypes.string
      })
    )
  }),
  metaAnalytics: PropTypes.shape({
    locations: PropTypes.shape({
      selected: PropTypes.arrayOf(PropTypes.string),
      name: PropTypes.string,
      attribute: PropTypes.string,
      facets: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
          label: PropTypes.string
        })
      )
    })
  }),
  query: PropTypes.shape({
    filters: PropTypes.arrayOf(
      PropTypes.shape({
        attribute: PropTypes.string,
        facets: PropTypes.arrayOf(PropTypes.string)
      })
    ),
    dateRange: PropTypes.shape({
      start: PropTypes.string,
      end: PropTypes.string
    })
  })
};
ProjectsContainer.defaultProps = {
  projectList: []
};

const mapStateToProps = state => ({
  metaProjectList: reducerUtil.getSlice(projectsData, projectsData.metaProjectList, state),
  metaAnalytics: reducerUtil.getSlice(projectsData, projectsData.metaAnalytics, state),
  query: reducerUtil.getSlice(projectsData, projectsData.query, state),
  projectList: reducerUtil.getSlice(projectsData, projectsData.projectList, state),
  data: reducerUtil.getSlice(projectsData, projectsData.analyticsData, state),
  fetchId: reducerUtil.getSlice(projectsData, projectsData.fetchId, state)
});

export default withRouter(connect(mapStateToProps)(ProjectsContainer));
