import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { reducerUtil } from 'base-client';
import { reducerData as leadsData } from 'leads';

import { PageHeader } from 'shared';

import Card from 'shared/PageLayout/Card';

const PersonalInformation = props => {
  const {
    name,
    company,
    email,
    industry,
    lastActivity,
    occupation,
    phone,
    picture,
    location,
    totalActivity
  } = props;

  return (
    <div>
      <PageHeader title={name || ''} />
      <Card>
        <div className="lead-info">
          <div className="lead-img">
            <img src={picture} alt="" className="lead-img__img" />
          </div>
          <div className="lead-contact">
            <div className="lead-contact__details">
              {email && (
                <p className="contact-item">
                  <span className="contact-prop">Email</span>
                  <span className="contact-val">{email}</span>
                </p>
              )}
              {phone && (
                <p className="contact-item">
                  <span className="contact-prop">Phone</span>
                  <span className="contact-val">{phone}</span>
                </p>
              )}
              <p className="contact-item">
                <span className="contact-prop">Company</span>
                <span className="contact-val">{company}</span>
              </p>
              <p className="contact-item">
                <span className="contact-prop">Location</span>
                <span className="contact-val">{location}</span>
              </p>
              <p className="contact-item">
                <span className="contact-prop">Occupation</span>
                <span className="contact-val">{occupation}</span>
              </p>
              <p className="contact-item">
                <span className="contact-prop">Industry</span>
                <span className="contact-val">{industry}</span>
              </p>
            </div>
          </div>
          <div className="lead-activity">
            <div className="lead-activity__wrapper">
              <div className="lead-activity__group">
                <h3 className="stat-title">Total Activity</h3>
                <span className="stat-num">{totalActivity}</span>
              </div>
              <div className="lead-activity__group">
                <h3 className="stat-title">Last Activity</h3>
                <span className="stat-num">{lastActivity}</span>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

PersonalInformation.propTypes = {
  company: PropTypes.string,
  email: PropTypes.string,
  industry: PropTypes.string,
  lastActivity: PropTypes.string,
  name: PropTypes.string,
  occupation: PropTypes.string,
  phone: PropTypes.string,
  picture: PropTypes.string,
  location: PropTypes.string,
  totalActivity: PropTypes.any
};

const mapStateToProps = state => {
  const leadData = reducerUtil.getSlice(leadsData, leadsData.leadProfile, state) || {};
  const user = leadData.user || {};
  const tables = leadData.tables || {};
  return {
    ...user,
    lastActivity: tables.lastActivity,
    totalActivity: tables.totalActivity
  };
};

export default connect(mapStateToProps)(PersonalInformation);
