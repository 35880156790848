import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../SvgIcon';

const SubmittalGeneratedIcon = props => (
  <SvgIcon {...props}>
    <path
      d="M8.00009 12.773L13.4821 8.85595C13.1371 8.60595 12.8211 8.31995 12.5371 8.00195H2.00009C1.80709 8.00195 1.62209 8.03795 1.44409 8.08995L8.00009 12.773Z"
      fill="#6ABF43"
    />
    <path
      d="M15.57 9.82293L8.581 14.8149C8.407 14.9399 8.203 15.0019 8 15.0019C7.797 15.0019 7.593 14.9399 7.419 14.8149L0.055 9.55493C0.022 9.69893 0 9.84893 0 10.0019V18.0019C0 19.1049 0.896 20.0019 2 20.0019H14C15.104 20.0019 16 19.1049 16 18.0019V10.0019C16 9.97093 15.992 9.94093 15.99 9.91093C15.849 9.88693 15.709 9.85793 15.57 9.82293Z"
      fill="#6ABF43"
    />
    <path
      d="M16 7.41592L12.293 3.70892L13.707 2.29492L16 4.58792L20.293 0.294922L21.707 1.70892L16 7.41592Z"
      fill="#6ABF43"
    />
  </SvgIcon>
);

SubmittalGeneratedIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
};

SubmittalGeneratedIcon.defaultProps = {
  width: 22,
  height: 21
};

export default SubmittalGeneratedIcon;
