import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Field } from 'redux-form';

import { reducerUtil } from 'base-client';
import {
  reducerData as detailsData,
  utils as detailsUtils,
  actions as detailsActions
} from 'productDetails';
import { CheckboxField } from './FormFields';
import Button from 'shared/Buttons';

const StyledWrapper = styled.div`
  width: 100%;
  position: relative;
`;

const Subheader = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 5rem;
`;

const Title = styled.div`
  padding-bottom: 1.5rem;
`;

const RatingSystems = ({ data, handleOnChange, sustainabilityData, optionId, dispatch, name }) => {
  const { sustainability } = sustainabilityData || {};

  const ratings =
    sustainability && sustainability[optionId]
      ? sustainability[optionId][detailsUtils.sustainabilityGroups[2]]
      : {};

  const clearAll = () => {
    dispatch(detailsActions.clearRatings(optionId));
  };
  return (
    <StyledWrapper>
      <Subheader>
        <Title>Select the rating system(s) you would like to apply to this product:</Title>
        {Object.keys(ratings).length > 0 && <Button onClick={clearAll}>Clear All</Button>}
      </Subheader>

      {data
        .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
        .map(({ id, name: labelText }) => (
          <Field key={id} name={id} {...{ labelText, handleOnChange }} component={CheckboxField} />
        ))}
    </StyledWrapper>
  );
};

RatingSystems.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any
    })
  ),
  handleOnChange: PropTypes.func.isRequired
};

RatingSystems.defaultProps = {
  data: []
};

const mapStateToProps = state => ({
  sustainabilityData: reducerUtil.getSlice(detailsData, detailsData.values, state)
});

export default connect(mapStateToProps)(RatingSystems);
