import React from 'react';
import PropTypes from 'prop-types';

const Loading = ({ loadValue }) => (
  <div>
    <h4>Loading{!!loadValue ? ` ${loadValue}%` : null}!</h4>
  </div>
);

Loading.propTypes = {
  loadValue: PropTypes.number,
};

Loading.defaultProps = {
  loadValue: null,
};

export default Loading;
