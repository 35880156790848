import React from 'react';
import styled from 'styled-components';

import { Container, Row } from 'shared-features-client';

import sbManagerStyles from 'utils/globalStyles';
import ProjectContact from './ProjectContactItem';

import { isEmpty } from '../utils';

const StyledRow = styled(Row)`
  padding-top: 2rem;
  `;
  
const Contacts = styled.div`
  border-top: 1px solid ${sbManagerStyles.accentGrey};
`

const ProjectContacts = ({ ...contactInformation }) => {
  if (!contactInformation || isEmpty(contactInformation)) return null;
  const { architect, contractor, general_contractor, distributor } = contactInformation;

  return (
    <Container>
      <Contacts>
        <StyledRow>
          <ProjectContact contactInformation={contractor} type="Contractor Information" />
          <ProjectContact contactInformation={architect} type="Architect Information" />
          <ProjectContact contactInformation={general_contractor} type="GC Information" />
          <ProjectContact contactInformation={distributor} type="Distribution Information" />
        </StyledRow>
      </Contacts>
    </Container>
  );
};

export default ProjectContacts;
