import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { reducerUtil } from 'base-client';
import PropTypes from 'prop-types';

import { reducerData as tenantData } from 'tenant';

import SideNav from 'shared/SideNav';
import ContactSalesMessage from './ContactSalesMessage';
import RequestQuoteMessage from './RequestQuoteMessage';
import { actions as messagesActions, reducerData as messagesData } from 'directMessages';
import NoDataCallout from '../NoDataCallout';

const StyledSideNav = styled(SideNav)`
  height: 500px;
  background: white;
  .side-nav {
    height: 100%;
    margin-top: 0;
  }
  .tab-content {
    height: 100%;
    flex: 0 0 85% !important;
    p {
      word-break: break-word;
    }
  }
  .tab-list {
    height: 100%;
    overflow: hidden auto;
    margin: 0;
    cursor: pointer;
    flex: 0 0 15% !important;
    max-width: 150px;
  }
`;

class DirectMessages extends React.Component {
  static propTypes = {
    messages: PropTypes.object
  };

  static defaultProps = {
    messages: {}
  };

  componentDidUpdate() {
    let self = this;
    self.isLoading = false;
    const {
      messages: {
        pagination: { page, resultCount, limit },
        emails
      }
    } = this.props;

    let element = document.querySelector('.tab-list');
    if (!!element) {
      element.onscroll = onScrolled;
    }

    function onScrolled() {
      if (
        element.scrollTop + element.offsetHeight > 0.7 * element.scrollHeight &&
        resultCount === limit &&
        self.isLoading === false
      ) {
        const { dispatch } = self.props;
        const params = { userId: emails[0].userId, page: page + 1 };
        dispatch(messagesActions.getMessages(params, messagesData.leadMessages));
        self.isLoading = true;
      }
    }
  }

  render() {
    const { messages: enableMessages } = this.props.enableFeatures || {};

    if (!enableMessages) return null;

    const {
      messages: { emails }
    } = this.props;

    let messages = emails.map(m => ({
      label: m.datetime,
      data: m
    }));

    for (let i = 0; i < emails.length; i++) {
      let message = messages[i];
      switch (message.data.messageType) {
        case 'Contact Sales':
          message.element = ContactSalesMessage;
          break;

        case 'Request a Quote':
          message.element = RequestQuoteMessage;
          break;
      }
    }
    return (
      <React.Fragment>
        {messages.length === 0 && (
          <NoDataCallout
            dataTitle={'Messages'}
            flavor={
              'You should add them to an email list and invite them to' +
              ' request a quote through your Design Studio.'
            }
          />
        )}
        {messages.length > 0 && <StyledSideNav options={messages} />}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  messages: reducerUtil.getSlice(messagesData, messagesData.leadMessages, state),
  enableFeatures: reducerUtil.getSlice(tenantData, tenantData.enableFeatures, state)
});

export default connect(mapStateToProps)(DirectMessages);
