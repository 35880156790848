/* vendor imports */
import React from 'react';
import PropTypes from 'prop-types';

/* system imports */

const Stats = ({ current, title }) => (
  <div className="insights-stats-group">
    <div className="stats-wrapper">
      <div className="main-stat">
        <h3 className="stat-title">{title}</h3>
        <span className="stat-num stat-num--main">{current}</span>
      </div>
    </div>
  </div>
);

Stats.propTypes = {
  title: PropTypes.string.isRequired,
  current: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default Stats;
