import styled from 'styled-components';

import sbManagerStyles from 'utils/globalStyles';

const FieldWrapper = styled.div`
  padding-bottom: 1rem;
  &.inline {
    width: 100%;
    display: flex;
    flex-direction: column;
    label {
      padding-bottom: 0.5rem;
    }
  }
`;

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
`;

const StyledInput = styled.input`
  padding: 5px 10px;
  border-radius: 4px;
  font: inherit;
  font-size: 1.4rem;
  text-overflow: ellipsis;
  height: 3.5rem;
  width: 100%;
  margin-right: 1rem;
  border: 1px solid ${sbManagerStyles.greyLight};
  &:focus {
    border: 2px solid ${sbManagerStyles.blue};
    outline: 0;
  }
  &.error {
    border-color: ${sbManagerStyles.red};
  }
  ::placeholder {
    color: ${() => sbManagerStyles.greyDarkest};
    opacity: 1;
  }
`;

const Error = styled.span`
  padding-top: 0.5rem;
  display: block;
  color: ${sbManagerStyles.red};
`;

const HelpText = styled.span`
  font-size: 1.2rem;
  padding-top: 0.5rem;
  margin: 0;
`;

export { FieldWrapper, InputWrapper, StyledInput, Error, HelpText };
