import { reducerUtil, createStore } from 'base-client';
import { reducer as form } from 'redux-form';
import { reducerData as notificationData  } from './shared/Toast';

import { reducerData as adminData } from 'admin';
import { reducerData as analyticsDashboard } from 'analyticsDashboard';
import { reducerData as attributesData } from 'productAttributes';
import { reducerData as cacheData } from 'cache';
import { reducerData as detailsData } from 'productDetails';
import { reducerData as directMessages } from 'directMessages';
import { reducerData as eventsLogData } from 'eventsLog';
import { reducerData as imagesData } from 'images';
import { reducerData as insightsData } from 'insights';
import { reducerData as jobsData } from 'jobs';
import { reducerData as leadsData } from 'leads';
import { reducerData as leadsTwoData } from 'leadsTwo';
import { reducerData as listData } from 'productList';
import { reducerData as permissionsData } from 'permissions';
import { reducerData as projectDetails } from 'projectDetails';
import { reducerData as projectsData } from 'projects';
import { reducerData as tenantData } from 'tenant';
import modal from 'modal/modal';
import sidebar from 'sidebar/sidebar';

const store = createStore({
  [adminData.name]: reducerUtil.create(adminData),
  [analyticsDashboard.name]: reducerUtil.create(analyticsDashboard),
  [attributesData.name]: reducerUtil.create(attributesData),
  [cacheData.name]: reducerUtil.create(cacheData),
  [detailsData.name]: reducerUtil.create(detailsData),
  [directMessages.name]: reducerUtil.create(directMessages),
  [eventsLogData.name]: reducerUtil.create(eventsLogData),
  [imagesData.name]: reducerUtil.create(imagesData),
  [insightsData.name]: reducerUtil.create(insightsData),
  [jobsData.name]: reducerUtil.create(jobsData),
  [leadsData.name]: reducerUtil.create(leadsData),
  [leadsTwoData.name]: reducerUtil.create(leadsTwoData),
  [listData.name]: reducerUtil.create(listData),
  [notificationData.name]: reducerUtil.create(notificationData),
  [permissionsData.name]: reducerUtil.create(permissionsData),
  [projectDetails.name]: reducerUtil.create(projectDetails),
  [projectsData.name]: reducerUtil.create(projectsData),
  [tenantData.name]: reducerUtil.create(tenantData),
  form,
  modal,
  sidebar
});

export default store;
