import styled from 'styled-components';

import sbManagerStyles from 'utils/globalStyles';

const CloseIcon = styled.div`
  position: relative;
  width: ${props => (props.width ? props.width : 20)}px;
  height: ${props => (props.height ? props.height : 20)}px;
  cursor: pointer;
  &:before,
  &:after {
    content: '';
    width: 0.3rem;
    height: ${props => (props.height ? props.height : 20)}px;
    right: 1rem;
    background: ${() => sbManagerStyles.greyLight};
    position: absolute;
    transition: background 0.2s ease-in-out;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
  &:hover {
    &:before,
    &:after {
      background: ${() => sbManagerStyles.greyLight};
    }
  }
`;

export default CloseIcon;
