import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Tab, Nav, NavItem } from 'react-bootstrap';
import { FormSection } from 'redux-form';

import Attributes from './Attributes';
import { AddButton } from 'shared/Buttons';

const DetailsTab = ({
  eventKey,
  title,
  onSelectAttribute,
  activeKey,
  onSelectNav,
  navChange,
  navList,
  navKey,
  section,
  disableAttribute,
  children,
  ...props
}) => {
  const selectNone = () => onSelectAttribute(undefined);

  const assetTabKey = 3;
  const [navItem, setSelectedNavItem] = useState('')

  useEffect(() => {
    typeof navChange === 'function' &&  navChange(navItem)
  }, [navItem])

  if (activeKey !== eventKey && activeKey !== assetTabKey || navList.length < 1) {
    return (
      <Tab {...{ eventKey, title }}>
        <Tab.Container id="subsection1" className="subsection" defaultActiveKey={0}>
          {eventKey !== 2 && eventKey !== 3 ? (
            <div>
              <div className="subsection-nav">
                <Nav bsStyle={null} bsClass="subsection-menu" />
              </div>
              <Tab.Content animation className="subsection-content">
                <Tab.Pane eventKey={0}>
                  {children}
                  {section && (
                    <AddButton iconPosition="after" onClick={selectNone}>
                      Add Attribute
                    </AddButton>
                  )}
                </Tab.Pane>
              </Tab.Content>
            </div>
          ) : (
            children
          )}
        </Tab.Container>
      </Tab>
    );
  }
  return (
    <Tab {...{ eventKey, title }}>
      <Tab.Container
        id="subsection1"
        className="subsection"
        defaultActiveKey={0}
        activeKey={navKey}
        onSelect={onSelectNav}
      >
        <div>
          <div className="subsection-nav">
            <Nav bsStyle={null} bsClass="subsection-menu">
              {navList.map(({ name, group }, index) => (
                <NavItem eventKey={index} key={index} onClick={(e) => setSelectedNavItem(e.target.name)} name={name ? name : group}>
                  {name ? name : group}
                </NavItem>
              ))}
            </Nav>
          </div>
          <Tab.Content animation className="subsection-content">
            {navList.map(({ attributes: list }, index) => (
              <Tab.Pane eventKey={index} key={index}>
                {children}
                {section && (
                  <AddButton iconPosition="after" onClick={selectNone}>
                    Add Attribute
                  </AddButton>
                )}
                {navKey === index && (
                  <FormSection name={section}>
                    <Attributes {...props} {...{ list, disableAttribute, onSelectAttribute }} />
                  </FormSection>
                )}
              </Tab.Pane>
            ))}
          </Tab.Content>
        </div>
      </Tab.Container>
    </Tab>
  );
};

DetailsTab.propTypes = {
  onSelectAttribute: PropTypes.func,
  onSelectNav: PropTypes.func.isRequired,
  eventKey: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  activeKey: PropTypes.number,
  navKey: PropTypes.number,
  navList: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      attributes: PropTypes.arrayOf(PropTypes.string).isRequired
    })
  ).isRequired,
  section: PropTypes.string,
  disableAttribute: PropTypes.bool,
  children: PropTypes.any
};

export default DetailsTab;
