import React from 'react';
import styled from 'styled-components';
import { SortDirection } from 'react-virtualized';

import ProjectsTable from './ProjectsTable';
import { InfiniteList } from 'shared-features-client';
import { actions as projectsActions } from 'projects';
import { history } from 'base-client';

const Container = styled.div`
  flex: 0 0 86%;
  margin-top: 0;
  min-height: calc(100vh - 21rem);
  .ReactVirtualized__Table__row.table-row {
    cursor: pointer;
  }
`;

class ProjectsView extends React.Component {
  state = {};

  render() {
    const { fetchId, list, metaProjectList, sortBy, sortDir, setSort, dispatch } = this.props;
    if (!list) {
      return null;
    }

    const length = (list && list.length) || 0;

    let hasNextPage = false;
    if (metaProjectList) {
      const {
        pagination: { page, pageCount }
      } = metaProjectList;
      hasNextPage = page < pageCount;
    }

    const rowCount = hasNextPage ? length + 1 : length + 0;

    const tableProps = {
      rowCount,
      data: [...list],
      sortBy,
      sortDirection: sortDir === 'asc' ? SortDirection.ASC : SortDirection.DESC,
      sort: ({ sortBy, sortDirection }) => {
        setSort({ sortBy, sortDir: sortDirection.toLowerCase() });
      },
      onRowClick: ({ rowData }) => history.push(rowData.url)
    };

    const infiniteProps = {
      list,
      hasNextPage,
      fetching: !!fetchId,
      getNextPage: () => dispatch(projectsActions.loadNextPage())
    };

    return (
      <Container className="card">
        <InfiniteList {...infiniteProps}>
          <ProjectsTable {...tableProps} />
        </InfiniteList>
      </Container>
    );
  }
}

ProjectsView.propTypes = {};
ProjectsView.defaultProps = {};

export default ProjectsView;
