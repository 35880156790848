import React from 'react';
import PropTypes from 'prop-types';
import { FormControl } from 'react-bootstrap';
import cn from 'classnames';
import { connect } from 'react-redux';
import { reducerUtil } from 'base-client';

import noProductImage from 'styles/imgs/app/NoProductImage.jpg';
import { actions as detailsActions, reducerData as detailsData } from 'productDetails';

class ImageContainer extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    input: PropTypes.shape({
      name: PropTypes.string.isRequired,
      onChange: PropTypes.func.isRequired,
      value: PropTypes.string
    }),
    assetList: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.string,
        mimetype: PropTypes.string
      })
    ).isRequired
  };
  static defaultProps = {
    input: {}
  };

  state = {};

  componentDidMount() {
    this.onChangeImage(true);
  }
  componentDidUpdate() {
    this.onChangeImage();
  }

  onChangeImage = async startup => {
    const {
      input: { name, value }
    } = this.props;
    const { imageId } = this.state;

    if (imageId === value) return;
    this.setState({ imageId: value });

    const { dispatch } = this.props;
    if (value) {
      const imageUrl = await dispatch(detailsActions.getImage(value));
      this.setState({ imageUrl });
    }
    if (!startup) dispatch(detailsActions.save(name));
  };

  render() {
    const { input, assetList } = this.props;
    const { imageId, imageUrl } = this.state;
    const options = assetList.filter(item => {
      if (!item.id) return true;
      return item.mimetype.match(/.*image.*/);
    });
    return (
      <React.Fragment>
        <img src={imageId ? imageUrl : noProductImage} className="product-img__img" alt="" />
        <FormControl
          {...input}
          componentClass="select"
          bsClass={cn('form-control', null)}
          className="product-img__select"
        >
          {options.map(({ id, filename }) => (
            <option key={`${id}${filename}`} value={id}>
              {filename}
            </option>
          ))}
        </FormControl>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  assetList: reducerUtil.getSlice(detailsData, detailsData.assetList, state)
});

export default connect(mapStateToProps)(ImageContainer);
