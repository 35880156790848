import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../SvgIcon';

const LeadsIcon = props => (
  <SvgIcon {...props}>
    <path id="shadow" className="color--light" d="M22.4375 13.2159H23.4365C24.0365 13.2159 24.4365 13.6325 24.4365 14.2575V17.1742C24.4365 17.7992 24.0365 18.2159 23.4365 18.2159H1.43652C0.836523 18.2159 0.436523 17.7992 0.436523 17.1742V14.2575C0.436523 13.6325 0.836523 13.2159 1.43652 13.2159H2.43748V14.25C2.43748 15.35 3.33748 16.25 4.43748 16.25H20.4375C21.5375 16.25 22.4375 15.35 22.4375 14.25V13.2159Z"
    />
    <g className="icon--inactive">
      <path className="color--medium" d="M20.4375 2.25V14.25H4.4375V2.25H20.4375ZM20.4375 0.25H4.4375C3.3375 0.25 2.4375 1.15 2.4375 2.25V14.25C2.4375 15.35 3.3375 16.25 4.4375 16.25H20.4375C21.5375 16.25 22.4375 15.35 22.4375 14.25V2.25C22.4375 1.15 21.5375 0.25 20.4375 0.25Z" fill="#77899E"/>
      <path className="color--medium" d="M18.4375 7.25H14.4375V9.25H18.4375V7.25Z" />
      <path className="color--medium" d="M18.4375 10.25H14.4375V12.25H18.4375V10.25Z" />
      <path className="color--medium" d="M6.4375 12.25C6.4375 10.409 7.5965 9.25 9.4375 9.25C11.2785 9.25 12.4375 10.409 12.4375 12.25H6.4375Z" />
      <path className="color--medium" d="M9.4375 8.25C10.5421 8.25 11.4375 7.35457 11.4375 6.25C11.4375 5.14543 10.5421 4.25 9.4375 4.25C8.33293 4.25 7.4375 5.14543 7.4375 6.25C7.4375 7.35457 8.33293 8.25 9.4375 8.25Z" />
    </g>
    <g className="icon--active">
      <rect className="color--medium" x="5.21875" y="3.65274" width="7.348" height="10" />
      <path className="color--dark" d="M20 0.215912H4C2.9 0.215912 2 1.11591 2 2.21591V14.2159C2 15.3159 2.9 16.2711 4 16.2711H20C21.1 16.2711 22 15.3159 22 14.2159V2.21591C22 1.11591 21.1 0.215912 20 0.215912ZM9 4.21591C10.104 4.21591 11 5.11191 11 6.21591C11 7.31991 10.104 8.21591 9 8.21591C7.896 8.21591 7 7.31991 7 6.21591C7 5.11191 7.896 4.21591 9 4.21591ZM6 12.2159C6 10.3749 7.159 9.21591 9 9.21591C10.841 9.21591 12 10.3749 12 12.2159H6ZM18 12.2159H14V10.2159H18V12.2159ZM18 9.21591H14V7.21591H18V9.21591Z" />
    </g>
  </SvgIcon>
);

LeadsIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

LeadsIcon.defaultProps = {
  width: 22,
  height: 22
};

export default LeadsIcon;
