import React from 'react';
import { Field, FormSection } from 'redux-form';

import RevitContainer from './RevitContainer';
import detailsUtils from 'productDetails/utils';
import './styles.scss';

const RevitFile = props => (
  <FormSection name={detailsUtils.form.system}>
    <Field name={detailsUtils.systemNames.revitFile} {...props} component={RevitContainer} />
  </FormSection>
);

export default RevitFile;
