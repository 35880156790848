import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, ControlLabel } from 'react-bootstrap';

import Formula from './Formula';
import { FormulaCheckbox } from 'shared/InputGroups/Checkbox';
import { DeleteIcon, LinkIcon, LinkBadIcon } from 'shared/Miscellaneous/Icons';

class FormulaContainer extends React.Component {
  static propTypes = {
    save: PropTypes.func.isRequired,
    onClickAttribute: PropTypes.func.isRequired,
    attribute: PropTypes.shape({
      name: PropTypes.string.isRequired,
      revitDistribution: PropTypes.object
    }),
    input: PropTypes.shape({
      name: PropTypes.string,
      onChange: PropTypes.func.isRequired,
      value: PropTypes.shape({
        value: PropTypes.any,
        formula: PropTypes.string,
        isFormula: PropTypes.bool
      })
    }),
    meta: PropTypes.shape({
      error: PropTypes.object
    }),
    className: PropTypes.object,
    onRevit: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element])
  };
  static defaultProps = {
    attribute: {},
    input: { value: {}, onChange: () => undefined }
  };

  state = {};

  componentDidMount() {
    const { attribute } = this.props;
    this.setState({ showFormula: !!attribute.revitDistribution });
  }

  onLeaveFocus = () => {
    const {
      save,
      input: { name }
    } = this.props;
    save(name);
  };
  onChangeFormula = event => {
    const {
      input: { onChange, value }
    } = this.props;
    onChange({ ...value, isFormula: event.target.checked });
  };
  onChange = event => {
    const {
      input: { onChange, value }
    } = this.props;
    const { showFormula } = this.state;
    const { isFormula } = value;
    if (showFormula && isFormula) onChange({ ...value, formula: event.target.value });
    else onChange({ ...value, value: event.target.value });
  };

  render() {
    const {
      input,
      className,
      meta,
      onClickAttribute,
      attribute,
      onRevit,
      children,
      ...props
    } = this.props;
    const { showFormula } = this.state;

    const { bsClass, errors, warnings } = meta ? processError(meta.error) : {};
    const { name, value: valueObject } = input;
    const { value, formula, isFormula, sharedParameterGUID: sharedValue } = valueObject;
    const { sharedParameter: sharedAttribute } = attribute;

    const inputProps = {
      onChange: this.onChange,
      bsClass,
      attribute,
      input,
      ...props
    };

    return (
      <FormGroup controlId={name} onChange={this.onLeaveFocus} {...{ className }}>
        <div className="product-details__input">
          <ControlLabel>
            <div className="parameter-name">
              {onRevit &&
                (sharedValue || sharedAttribute) && (
                  <div className="tooltip-trigger">
                    {sharedValue === sharedAttribute ? (
                      <React.Fragment>
                        <LinkIcon />
                        <span className="parameter-tooltip">Shared Parameter</span>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <LinkBadIcon addClass="icon--danger" />
                        <span className="parameter-tooltip">Shared Parameter Conflict</span>
                      </React.Fragment>
                    )}
                  </div>
                )}
              {attribute.name}
            </div>
          </ControlLabel>
          {showFormula && isFormula ? (
            <Formula value={formula} {...inputProps} />
          ) : (
            children &&
            React.Children.map(children, child =>
              React.cloneElement(child, { value, ...inputProps })
            )
          )}
          {errors &&
            errors.length > 0 &&
            errors.map(message => (
              <span key={message} className="form-msg form-msg--error">
                {message}
              </span>
            ))}
          {warnings &&
            warnings.length > 0 &&
            warnings.map(message => (
              <span key={message} className="form-msg form-msg--warn">
                {message}
              </span>
            ))}
        </div>
        <div className="attribute-actions">
          {showFormula && <FormulaCheckbox checked={isFormula} onChange={this.onChangeFormula} />}
          <DeleteIcon
            onClick={onClickAttribute}
            addClass="icon--neutral icon--hover-danger"
            width={28}
            height={28}
            viewBox="0 0 28 34"
          />
        </div>
      </FormGroup>
    );
  }
}

const processError = error => {
  let bsClass = null;
  let status = null;
  const errors = [];
  const warnings = [];
  if (error && error.size > 0) {
    status = error.success;
    error = error.errors;
  }
  if (error && error.length > 0) {
    error.forEach(err => {
      if (err.type === 'WARN' && err.message) {
        warnings.push(err.message);
      } else if (err.type === 'ERROR' && err.message) {
        errors.push(err.message);
      }
    });
  }
  if (status === 'FAIL') bsClass = 'form-control--error';
  return { bsClass, errors, warnings };
};

export default FormulaContainer;
export { processError };
