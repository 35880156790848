import React from 'react';

import { ConfirmModal } from 'shared/Modals';

const DeleteModal = ({ messages, confirmBtnText, deleteItem, height, width }) => (
  <ConfirmModal
    messages={messages ? messages : 'Are you sure you want to delete?'}
    onConfirm={() => deleteItem()}
    confirmBtnText={confirmBtnText ? confirmBtnText : 'Delete'}
    deleteIcon
    height={height}
    width={width}
  />
);

export default DeleteModal;
