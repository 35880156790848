import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Bar from './Bar';
import { CloseIcon } from 'shared/Miscellaneous/Icons';
import sbManagerStyles from 'utils/globalStyles';

const Container = styled.div`
  padding: 2rem;
  position: sticky;
  position: -webkit-sticky;
  right: 0;
  margin-right: -1rem;
  bottom: 8.5rem;
  z-index: 99999;
  min-width: 400px;
  float: right;
  .icon {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
`;
const Header = styled.div`
  display: flex;
  align-items: center;
`;
const BoldText = styled.strong`
  margin-left: 0.2rem;
`;
const InforText = styled.div`
  color: ${() => sbManagerStyles.greyMedium};
`;
const FileList = styled.ul`
  list-style: none outside;
  padding: 0;
`;
const FileName = styled.li``;

// TODO: This needs styling.
const Failed = ({ onClick, fails, fileList }) => (
  <Container className="card">
    <Header>
      <BoldText>Asset Upload Failed</BoldText>
      <CloseIcon width={15} height={15} {...{ onClick }} />
    </Header>
    <Bar percentage={1} failed />
    <InforText>{`${fails} files failed`}</InforText>
    <BoldText>The following files could not be uploaded:</BoldText>
    <FileList>
      {fileList
        .filter(item => item.failed)
        .map(({ id, name }) => (
          <FileName key={id}>{name}</FileName>
        ))}
    </FileList>
  </Container>
);

Failed.propTypes = {
  onClick: PropTypes.func.isRequired,
  fails: PropTypes.number.isRequired,
  fileList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string,
      failed: PropTypes.bool
    })
  ).isRequired
};

export default Failed;
