import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { UploadIcon } from 'shared/Miscellaneous/Icons';

const Container = styled.div`
  cursor: pointer;
`;
const FileInput = styled.input`
  display: none;
`;

class UploadButton extends Component {
  static propTypes = {
    onUpload: PropTypes.func.isRequired
  };

  fileInputRef = React.createRef();

  openFileDialog = () => {
    const { current } = this.fileInputRef;
    current && current.click();
  };

  onFilesAdded = evt => {
    const fileObject = evt.target.files || {};
    const fileList = [];
    for (let i = 0; i < fileObject.length; ++i) {
      fileList.push(fileObject[i]);
    }
    this.props.onUpload(fileList);
  };

  render() {
    return (
      <Container className="button button--default" onClick={this.openFileDialog}>
        <UploadIcon />
        <FileInput
          type="file"
          multiple
          onChange={evt => this.onFilesAdded(evt)}
          ref={this.fileInputRef}
        />
        <span>Upload Files</span>
      </Container>
    );
  }
}

export default UploadButton;
