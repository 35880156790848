import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './assets/icons.scss';

const SvgIcon = ({ children, addClass, log, ...props }) => {
  const classNames = cn('icon', { [addClass]: addClass });
  return (
    <svg className={classNames} xmlns="http://www.w3.org/2000/svg" {...props}>
      {children}
    </svg>
  );
};

SvgIcon.propTypes = {
  children: PropTypes.node.isRequired,
  addClass: PropTypes.string,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  viewBox: PropTypes.string.isRequired
};

SvgIcon.defaultProps = {
  width: 24,
  height: 24,
  viewBox: '0 0 24 24',
  addClass: ''
};

export default SvgIcon;
