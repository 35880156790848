/* vendor imports */
import React from 'react';
import { Field } from 'redux-form';

/* system imports */
import SelectField from './SelectField';
import { Groups } from 'utils/groups';

const Group = props => (
  <div className="form__attribute-unit">
    <div className="form__attribute-title">Group</div>
    <div className="form__attribute-line">
      <Field name="group" component={SelectField} options={Groups} {...props} />
    </div>
  </div>
);

export default Group;
