import moment from 'moment';

const leadStream = ['all', 'views', 'downloads'];

const defaultInterval = {
  startDate: moment().subtract(365, 'days'),
  endDate: moment()
};

const streamConvert = {
  views: 'pageview',
  productviews: 'product',
  downloads: 'download'
};

const querySortBy = {
  score: '_score',
  name: 'name',
  lastActivity: 'lastActivity',
  industry: 'industry',
  occupation: 'occupation',
  company: 'company',
  leadScore: 'leadScore',
  leadStage: 'leadScore'
};

const querySortByDirections = {
  _score: 'desc',
  name: 'asc',
  lastActivity: 'desc',
  industry: 'asc',
  occupation: 'asc',
  company: 'asc',
  leadScore: 'desc'
};

const searchPage = '/insights/contacts';

export default { leadStream, defaultInterval, streamConvert };
export { querySortBy, querySortByDirections, searchPage };
