import React from 'react';
import PropTypes from 'prop-types';

import { AddBoxOutlineIcon, EditOutlineIcon } from 'shared/Miscellaneous/Icons';
import detailsUtils from 'productDetails/utils';

const Metadata = ({ revitFile }) => (
  <div className="revit-source__meta">
    <div className="meta-item">
      <p className="meta-item__header">
        <span className="meta-item__icon">
          <AddBoxOutlineIcon width={18} height={18} />
        </span>
        Created At
      </p>
      <p className="meta-item__content">
        {detailsUtils.formatTime(revitFile[detailsUtils.statusNames.created], 'created')}
      </p>
    </div>
    <div className="meta-item">
      <p className="meta-item__header">
        <span className="meta-item__icon">
          <EditOutlineIcon width={18} height={18} />
        </span>
        Updated At
      </p>
      <p className="meta-item__content">
        {detailsUtils.formatTime(revitFile[detailsUtils.statusNames.updated], 'updated')}
      </p>
    </div>
  </div>
);

Metadata.propTypes = {
  revitFile: PropTypes.shape({
    [detailsUtils.statusNames.created]: PropTypes.string,
    [detailsUtils.statusNames.updated]: PropTypes.string
  })
};

Metadata.defaultProps = {
  revitFile: {}
};

export default Metadata;
