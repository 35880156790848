import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { MenuItem } from 'react-bootstrap';

const DROPDOWN_CLASSNAME = 'dropdown__item';
const SORT_CLASSNAME = 'dropdown__item--sort';

const DropdownMenuItem = ({ children, isSort, ...props }) => (
  <MenuItem {...props} className={cn(DROPDOWN_CLASSNAME, { [SORT_CLASSNAME]: isSort })}>
    {children}
  </MenuItem>
);

DropdownMenuItem.propTypes = {
  children: PropTypes.node.isRequired,
  eventKey: PropTypes.any.isRequired,
  isSort: PropTypes.bool,
};

DropdownMenuItem.defaultProps = {
  isSort: false,
}

export default DropdownMenuItem;

