/* vendor imports */
import React from 'react';
import PropTypes from 'prop-types';

/* system imports */
import Header from './Header';
import Body from './Body';
import Card from 'shared/PageLayout/Card';
import ModalContainer from 'shared/Overlays/Modal';
import { modalName } from 'productAttributes/utils';

const CreateModal = ModalContainer(modalName);

const Component = ({ noModal, closeModal, title, ...props }) => {
  if (noModal)
    return (
      <Card>
        <Body {...props} />
      </Card>
    );
  return (
    <CreateModal header={<Header {...{ title }} />} onClose={closeModal}>
      <Body {...props} />
    </CreateModal>
  );
};

Component.propTypes = {
  closeModal: PropTypes.func.isRequired,
  title: PropTypes.string
};

Component.defaultProps = {
  title: null
};

export default Component;
