import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './toast.css';


/**
 * Primary UI component for user interaction with default props set
 */
export const Notification = () => (
  <ToastContainer />
);
