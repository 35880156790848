const messageTypes = [
  {
    id: 'all',
    label: 'All'
  },
  {
    id: 'reqForQuote',
    label: 'Request a Quote'
  },
  {
    id: 'salesReq',
    label: 'Contact Sales'
  }
];

export { messageTypes };
