import React from 'react';
import PropTypes from 'prop-types';

import { PageHeader } from 'shared';
import HeaderControls from './HeaderControls';

const Header = ({ usersCount, clickOpenNewUser, canAddUser }) => (
  <PageHeader title="User Accounts" subtitle={`${usersCount} Total`}>
    <HeaderControls {...{ clickOpenNewUser, canAddUser }} />
  </PageHeader>
);

Header.propTypes = {
  usersCount: PropTypes.number.isRequired,
  clickOpenNewUser: PropTypes.func.isRequired,
  canAddUser: PropTypes.bool
};

Header.defaultProps = {
  canAddUser: false
};

export default Header;
