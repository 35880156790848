

import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../SvgIcon';

const GridViewIcon = props => (
  <SvgIcon {...props}>
    <path d='M9,2H4A2,2,0,0,0,2,4V9a2,2,0,0,0,2,2H9a2,2,0,0,0,2-2V4A2,2,0,0,0,9,2Z' />
    <path d='M20,2H15a2,2,0,0,0-2,2V9a2,2,0,0,0,2,2h5a2,2,0,0,0,2-2V4A2,2,0,0,0,20,2Z' />
    <path d='M9,13H4a2,2,0,0,0-2,2v5a2,2,0,0,0,2,2H9a2,2,0,0,0,2-2V15A2,2,0,0,0,9,13Z' />
    <path d='M20,13H15a2,2,0,0,0-2,2v5a2,2,0,0,0,2,2h5a2,2,0,0,0,2-2V15A2,2,0,0,0,20,13Z'/>
  </SvgIcon>
);

GridViewIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  viewBox: PropTypes.string,
};

GridViewIcon.defaultProps = {
  width: 24,
  height: 24,
  viewBox: '0 0 24 24',
};

export default GridViewIcon;
