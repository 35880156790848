import React from "react";
import { reducerUtil } from "base-client";

import { reducerData as detailsData } from "productDetails";
import { openModal as modalOpen, modalConfirmName, modalConfirmDisplay } from "modal/modal";

// ------------------------------------
// Product Confirmation Modal Actions
// ------------------------------------
const openModal = (modalName, props, productName) => (dispatch, getState) => {
  productName = productName || getProductName(getState());
  const baseProps = {
    productName,
    publishName: "Publish",
    onConfirm: () => dispatch(closeModal()),
    onCancel: () => dispatch(closeModal()),
  };
  dispatch(modalConfirmDisplay(modals[modalName](Object.assign(baseProps, props || {}))));
  dispatch(modalOpen(modalConfirmName));
};

const closeModal = () => (dispatch) => dispatch(modalOpen(modalConfirmName, false));

const getProductName = (state) => {
  const values = reducerUtil.getSlice(detailsData, detailsData.values, state);
  return values && values.system && values.system.productName;
};

export { openModal, closeModal, getProductName };
export default { openModal, closeModal, getProductName };

// ------------------------------------
// Product Confirmation Modals
// ------------------------------------

const unsaved = ({ productName, onConfirm, onCancel }) => {
  const title = `Save ${productName || "Product"}`;
  const text = `${productName || "The product"} has not been saved.`;
  return {
    title,
    text,
    buttonList: [
      {
        text: "Continue",
        onClick: onConfirm,
      },
      {
        text: "Cancel",
        onClick: onCancel,
      },
    ],
    noClose: false,
  };
};

const discard = ({ productName, onConfirm, onCancel }) => {
  const title = `Discard Changes to ${productName || "Product"}`;
  const text =
    `Are you sure you want to discard all changes to ${productName || "The product"}?` +
    " This action cannot be undone.";
  return {
    title,
    text,
    buttonList: [
      {
        text: "Confirm",
        onClick: onConfirm,
      },
      {
        text: "Cancel",
        onClick: onCancel,
      },
    ],
    noClose: false,
  };
};

const saveStart = ({ productName }) => {
  const title = `Save ${productName || "Product"}`;
  const text = `Saving ${productName || "the product"}.`;
  return {
    title,
    text,
    noClose: true,
  };
};

const saveFail = ({ productName, onConfirm, onCancel }) => {
  const title = `Save ${productName || "Product"}`;
  const text = `Failed to save ${productName || "the product"}.`;
  return {
    title,
    text,
    buttonList: [
      {
        text: "Try Again",
        onClick: onConfirm,
      },
      {
        text: "Cancel",
        onClick: onCancel,
      },
    ],
    noClose: false,
  };
};

const saveSuccess = ({ productName, onConfirm }) => {
  const title = `Save ${productName || "Product"}`;
  const text = `Successfully saved ${productName || "the product"}.`;
  return {
    title,
    text,
    modalStyle: "success",
    buttonList: [
      {
        text: "Close",
        onClick: onConfirm,
      },
    ],
    noClose: false,
  };
};

const publishStart = ({ productName, publishName, onConfirm, onCancel }) => {
  const title = `${publishName} ${productName || "Product"}`;
  const text = `Are you sure you want to ${publishName.toLowerCase()} ${
    productName || "the product"
  }?`;
  return {
    title,
    text,
    buttonList: [
      {
        text: "Confirm",
        onClick: onConfirm,
      },
      {
        text: "Cancel",
        onClick: onCancel,
      },
    ],
    noClose: false,
  };
};

const publishFail = ({ productName, publishName, onConfirm, onCancel }) => {
  const title = `${publishName} ${productName || "Product"}`;
  const text = `Failed to ${publishName.toLowerCase()} ${productName || "the product"}.`;
  return {
    title,
    text,
    buttonList: [
      {
        text: "Retry",
        onClick: onConfirm,
      },
      {
        text: "Cancel",
        onClick: onCancel,
      },
    ],
    noClose: false,
  };
};

const publishSuccess = ({ productName, publishName, onConfirm }) => {
  const title = `${publishName} ${productName || "Product"}`;
  const text = `${
    productName || "The product"
  } have been sent to be ${publishName.toLowerCase()}ed.`;
  return {
    title,
    text,
    modalStyle: "success",
    buttonList: [
      {
        text: "Close",
        onClick: onConfirm,
      },
    ],
    noClose: false,
  };
};

const deleteStart = ({ productName, onConfirm, onCancel }) => {
  const title = `Delete ${productName || "Product"}`;
  const text = `Are you sure you want to delete ${
    productName || "the product"
  }? This action cannot be undone.`;
  return {
    title,
    text,
    buttonList: [
      {
        text: "Yes, Delete",
        onClick: onConfirm,
      },
      {
        text: "Cancel",
        onClick: onCancel,
      },
    ],
    modalStyle: "danger",
  };
};

const deleteFail = ({ productName, onConfirm, onCancel }) => {
  const title = `Delete ${productName || "Product"}`;
  const text = `Failed to delete ${productName || "the product"}.`;
  return {
    title,
    text,
    buttonList: [
      {
        text: "Try Again",
        onClick: onConfirm,
      },
      {
        text: "Cancel",
        onClick: onCancel,
      },
    ],
    noClose: false,
  };
};

const deleteSuccess = ({ productName, onConfirm }) => {
  const title = `Delete ${productName || "Product"}`;
  const text = `Successfully deleted ${productName || "the product"}.`;
  return {
    title,
    text,
    modalStyle: "success",
    buttonList: [
      {
        text: "Close",
        onClick: onConfirm,
      },
    ],
    onClose: onConfirm,
    noClose: false,
  };
};

/* eslint react/react-in-jsx-scope: 0 */
/* eslint react/jsx-filename-extension: 0 */
const downloadUpdate = ({ productName, onConfirm, onCancel }) => {
  const title = `Download ${productName || "Product"}`;
  const text = (
    <div>
      The Revit File associated does not match your current product data. You can either:
      <br />
      <ul>
        <li>Download the file without updating</li>
        <li>Update the Revit file to match the latest changes</li>
      </ul>
      Updating may take a few minutes. After updating, click Download to obtain the product’s files.
    </div>
  );
  return {
    title,
    text,
    buttonList: [
      {
        text: "Download",
        onClick: onConfirm,
      },
      {
        text: "Update",
        onClick: onCancel,
      },
    ],
    noClose: false,
    modalStyle: "warning",
  };
};

const downloadFail = ({ productName, onConfirm, onCancel }) => {
  const title = `Download ${productName || "Product"}`;
  const text = `Failed to download ${productName || "the product"}.`;
  return {
    title,
    text,
    buttonList: [
      {
        text: "Try Again",
        onClick: onConfirm,
      },
      {
        text: "Cancel",
        onClick: onCancel,
      },
    ],
    noClose: false,
  };
};

const downloadUpdateFail = ({ productName, onConfirm, onCancel }) => {
  const title = `Download ${productName || "Product"}`;
  const text = "Failed to update the Revit File.";
  return {
    title,
    text,
    buttonList: [
      {
        text: "Try Again",
        onClick: onConfirm,
      },
      {
        text: "Cancel",
        onClick: onCancel,
      },
    ],
    noClose: false,
  };
};

const importRevitConfirmation = ({ onConfirm, onCancel, productName }) => {
  return {
    title: "Import data from file",
    text:
      "Importing data from the source files will overwrite the attributes " +
      `in ${productName}. Click confirm if you want to update the product.`,
    buttonList: [
      {
        text: "Confirm",
        onClick: onConfirm,
      },
      {
        text: "Cancel",
        onClick: onCancel,
      },
    ],
    noClose: false,
  };
};

const updateRevitConfirmation = ({ onConfirm, onCancel, filename }) => {
  return {
    title: "Update file with data",
    text:
      "Updating source files with data from the product will overwrite " +
      `the parameters in ${filename} and its associated files. Click ` +
      "confirm if you want to update the source files.",
    buttonList: [
      {
        text: "Confirm",
        onClick: onConfirm,
      },
      {
        text: "Cancel",
        onClick: onCancel,
      },
    ],
    noClose: false,
  };
};

const removeElement = ({ elementName, onConfirm, onCancel }) => {
  const title = `Remove ${elementName || "Type"}`;
  const text = `Are you sure you want to delete ${
    elementName || "the type"
  }? This action cannot be undone.`;
  return {
    title,
    text,
    buttonList: [
      {
        text: "Yes, Delete",
        onClick: onConfirm,
      },
      {
        text: "Cancel",
        onClick: onCancel,
      },
    ],
    noClose: false,
  };
};

const modals = {
  unsaved,
  discard,
  saveStart,
  saveFail,
  saveSuccess,
  publishStart,
  publishFail,
  publishSuccess,
  deleteStart,
  deleteFail,
  deleteSuccess,
  downloadUpdate,
  downloadFail,
  downloadUpdateFail,
  importRevitConfirmation,
  updateRevitConfirmation,
  removeElement,
};
