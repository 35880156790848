const fetchServiceKey = async ({ token, serviceKeyAPI }) => {
  if (!token || !serviceKeyAPI) {
    return null;
  }

  try {
    const response = await fetch(serviceKeyAPI, {
      method: "POST",
      body: JSON.stringify({ authToken: token }),
      headers: { Authorization: "Bearer " + token },
    });

    const responseJson = await response.json();
    const serviceKey = responseJson.data.key;

    if (typeof serviceKey === "string" || serviceKey instanceof String) {
      return serviceKey;
    }

    return null;
  } catch (error) {
    console.error({ error });
    return null;
  }
};

export const serviceKeyServices = {
  fetchServiceKey,
};
