import uuid from 'uuid/v4';
import moment from 'moment';

import { reducerUtil, api, config, history } from 'base-client';

import { reducerData as tenantData } from 'tenant';
import { reducerData as projectData } from 'projects';
import { addComma } from 'shared-features-client';
import downloadUtil from 'utils/fileDownload';
import sortOptions from '../sortOptions';
import { getlocationQuery } from 'utils';

const defaultQuery = {
  sort: {
    sortBy: 'date',
    sortDir: 'desc'
  },
  includePrevious: true
};

// ------------------------------------
// Project List
// ------------------------------------
const getProjectList = restart => async (dispatch, getState) => {
  let state = getState();
  const fetchId = uuid();

  const tenantId = reducerUtil.getSlice(tenantData, tenantData.tenant, state);
  const query = getlocationQuery();
  const { dateRange, filters } = query;
  const metaProjectList =
    reducerUtil.getSlice(projectData, projectData.metaProjectList, state) || {};
  let { pagination, sort } = metaProjectList;
  if (!sort) sort = sortOptions[0];
  const page = restart ? 1 : ((pagination && pagination.page) || 0) + 1;
  const limit = 100;

  let queryStrings = [
    `tenant_id=${tenantId}`,
    `limit=${limit}`,
    `page=${page}`,
    `order[${sort.sortBy}]=${sort.sortDir}%20nulls%20last`
  ];

  if (dateRange) {
    queryStrings.push(`filter[updated_at][start]=${dateRange.start}`);
    queryStrings.push(`filter[updated_at][end]=${dateRange.end}`);
  }

  if (filters) {
    filters.forEach(f => {
      if (f.attribute === 'country') {
        queryStrings.push(`filter[country]=${f.facets.join(',')}`);
      }
    });
  }
  queryStrings = queryStrings.join('&');

  dispatch(reducerUtil.setSlice(projectData, projectData.fetchId, fetchId));

  try {
    const result = await dispatch(api.actions.get(`projects?${queryStrings}`, true));
    const { projects, pagination } = result;

    // check that this is the correct fetch
    state = getState();
    if (fetchId !== reducerUtil.getSlice(projectData, projectData.fetchId, state)) return;

    dispatch(
      reducerUtil.setSlice(projectData, projectData.metaProjectList, {
        ...metaProjectList,
        pagination,
        sort
      })
    );
    const currentList =
      (!restart && reducerUtil.getSlice(projectData, projectData.projectList, state)) || [];

    dispatch(createProjectList(projects, currentList, 'projectList'));
    // allow another search
    dispatch(reducerUtil.setSlice(projectData, projectData.fetchId, undefined));
  } catch (error) {
    dispatch(config.actions.error(error));
  }
};

const getUserProjects = (restart, id) => async (dispatch, getState) => {
  let state = getState();
  //get tenant ID
  if (restart) dispatch(clearUserProjects());
  const tenantId = reducerUtil.getSlice(tenantData, tenantData.tenant, state);

  const userProjectList =
    reducerUtil.getSlice(projectData, projectData.userProjectList, state) || {};
  let { pagination } = userProjectList;
  const page = restart ? 1 : ((pagination && pagination.page) || 0) + 1;
  const limit = 100;

  const fetchId = uuid();
  dispatch(reducerUtil.setSlice(projectData, projectData.fetchId, fetchId));

  try {
    const result = await dispatch(
      api.actions.get(
        // eslint-disable-next-line max-len
        `projects/?tenant_id=${tenantId}&user_id=${id}&limit=${limit}&page=${page}&order[updated_at]=desc`,
        true
      )
    );

    const { projects } = result;

    // check that this is the correct fetch
    state = getState();
    // if (fetchId !== reducerUtil.getSlice(projectData, projectData.fetchId, state)) return;
    const currentList =
      (!restart && reducerUtil.getSlice(projectData, projectData.userProjectList, state)) || [];

    dispatch(createProjectList(projects, currentList, 'userProjectList'));
    // allow another search
    dispatch(reducerUtil.setSlice(projectData, projectData.fetchId, undefined));
  } catch (error) {
    dispatch(config.actions.error(error));
  }
};

const clearUserProjects = () => dispatch => {
  dispatch(reducerUtil.setSlice(projectData, projectData.userProjectList, {}));
};

const loadNextPage = () => dispatch => dispatch(getProjectList());

const createProjectList = (projects, currentList, list) => dispatch => {
  let projectList = [];
  if (projects) {
    projectList = projects.map(project => {
      const { user } = project;
      const { firstName, lastName, profile } = user || {};
      const { companyName } = profile || {};
      return {
        updated_at: moment(project.updated_at).format('MMMM Do, YYYY') || 'N/A',
        name: project.name || 'N/A',
        project_type: project.project_type || 'N/A',
        project_budget: project.project_budget ? `$${addComma(project.project_budget)}` : 'N/A',
        project_phase: project.project_phase || 'N/A',
        numberOfProducts: project['products.count'] || 0,
        url: `/insights/projects/${project.id}`,
        'user.firstName': firstName || lastName ? `${firstName} ${lastName}` : '',
        'user.profile.companyName': companyName || 'N/A'
      };
    });
  }
  dispatch(reducerUtil.setSlice(projectData, projectData[list], [...currentList, ...projectList]));
};

const setQuery = newData => (dispatch, getState) => {
  const oldQuery = reducerUtil.getSlice(projectData, projectData.query, getState()) || {};
  const query = { ...oldQuery, ...newData };
  dispatch(reducerUtil.setSlice(projectData, projectData.query, query));
  history.push(`${history.location.pathname}?${encodeURIComponent(JSON.stringify(query))}`);
};

const resetFilters = () => dispatch => dispatch(setQuery({ filters: undefined }));

const exportData = () => async (dispatch, getState) => {
  const state = getState();
  const tenantId = reducerUtil.getSlice(tenantData, tenantData.tenant, state);
  const query = reducerUtil.getSlice(projectData, projectData.query, state) || {};
  const { dateRange, filters } = query;

  let queryStrings = [`tenant_id=${tenantId}`];

  if (dateRange) {
    queryStrings.push(`filter[updated_at][start]=${dateRange.start}`);
    queryStrings.push(`filter[updated_at][end]=${dateRange.end}`);
  }

  if (filters) {
    filters.forEach(f => {
      if (f.attribute === 'country') {
        queryStrings.push(`filter[country]=${f.facets.join(',')}`);
      }
    });
  }
  queryStrings = queryStrings.join('&');

  try {
    const result = await dispatch(api.actions.post('projects/export?' + queryStrings, null, true));
    if (result) downloadUtil(result, `${'Projects'}.csv`);
  } catch (error) {
    dispatch(config.actions.error(error));
  }
};

const setSort = ({ sortBy, sortDir }) => (dispatch, getState) => {
  const query = reducerUtil.getSlice(projectData, projectData.query, getState()) || {};
  return dispatch(setQuery({ ...query, sort: { sortBy, sortDir } }));
};

const updateFilter = ({ attribute, facets }) => (dispatch, getState) => {
  const query = reducerUtil.getSlice(projectData, projectData.query, getState()) || {};
  const { filters = [] } = query;

  let newFilters;
  if (!facets) {
    newFilters = filters.filter(({ attribute: name }) => name !== attribute);
  } else if (!filters) {
    newFilters = [{ attribute, facets: [facets] }];
  } else {
    const index = filters.findIndex(({ attribute: name }) => name === attribute);
    if (index < 0) {
      newFilters = [...filters, { attribute, facets: [facets] }];
    } else {
      newFilters = [...filters];
      newFilters[index] = { attribute, facets: [facets] };
    }
  }

  const { sort } = defaultQuery;

  dispatch(setQuery({ filters: newFilters.length > 0 ? newFilters : undefined, sort }));
};

export default {
  getProjectList,
  getUserProjects,
  clearUserProjects,
  loadNextPage,
  exportData,
  updateFilter,
  resetFilters,
  setSort
};
