import React from 'react';
import PropTypes from 'prop-types';
import { DownArrowIcon, UpArrowIcon } from 'shared/Miscellaneous/Icons';

import { Card } from 'shared-features-client';

import '../assets/AnalyticsCard.scss';
import { roundUpNumber } from 'utils/miscUtils';

const AnalyticsCard = ({ title, subtitle, data, days, prefix, ext }) => {
  const { count, change, trend } = data;
  const percent = change && Math.round(change * 100);
  const { value, suffix } = roundUpNumber(count);

  return (
    <Card className="analytic-card text-center">
      <div className="card-title">{title}</div>
      <div className="card-content">
        <span className="aggregate-value">
          {prefix && <span className="prefix"> {prefix}</span>}
          {value || '0'}
          {suffix && <span className="suffix">{suffix}</span>}
          {ext && <span className="ext">{ext}</span>}
        </span>
        {!change ? null : (
          <div className="trend">
            <span className={trend}>{trend === 'up' ? <UpArrowIcon /> : <DownArrowIcon />}</span>
            <span className={'trend-value ' + trend}> {`${percent || 0}%`}</span>
          </div>
        )}
      </div>
      <div className="card-footer">
        <div>{subtitle || `Unique ${title}`}</div>
        {!change ? null : (
          <div className="card-date-range">{`(% change over last ${days} days)`}</div>
        )}
      </div>
    </Card>
  );
};

AnalyticsCard.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  data: PropTypes.shape({
    count: PropTypes.number,
    change: PropTypes.number,
    trend: PropTypes.oneOf(['up', 'down'])
  }),
  days: PropTypes.number,
  prefix: PropTypes.string,
  ext: PropTypes.string
};

AnalyticsCard.defaultProps = {
  data: {}
};

export default AnalyticsCard;
