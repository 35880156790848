import productInfoActions from './productInfo';
import productSnapshotActions from './productSnapshot';
import productGrowthActions from './productGrowth';
import productTopActions from './productTop';

export default {
  ...productInfoActions,
  ...productSnapshotActions,
  ...productGrowthActions,
  ...productTopActions,
};
