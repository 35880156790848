import React, { useState } from "react";
import { config } from "base-client";
import { configMap } from "configurations";
import styled from "styled-components";
import PropTypes from "prop-types";
import { AutoSizer, Table, Column, SortIndicator } from "react-virtualized";
import "react-virtualized/styles.css";
import { actions as detailsActions, reducerData as detailsData } from "productDetails";
import { DeleteIcon, FileIcon } from "shared/Miscellaneous/Icons";
import { TABLE_HEADER_HEIGHT, TABLE_ROW_HEIGHT } from "utils/globalStyles";
import Checkbox from "shared/InputGroups/Checkbox";
import Image from "./Image";
import Tooltip from "react-tooltip";

const Container = styled.div`
  height: 100%;
  flex: 1;
`;

const ImageContent = styled.div`
  height: 40px;
  width: 40px;
  margin: 8px 8px 8px 0;
  img {
    width: 100%;
    height: 100%;
  }
`;

const FileName = styled.div`
  display: inline-flex;
  align-items: center;
  span {
    display: block;
  }
`;

const AssetTable = ({ list, onRemove, onSort, onDataUpdate, sortBy, sortDir, dispatch }) => {
  const [editableCell, setEditableCell] = useState(null);
  const handleCellClick = (id, field) => {
    setEditableCell({ id, field });
  };

  let requireLoginFeature = dispatch(config.actions.getData(configMap.features.name, configMap.features.require_login.name));

  const actionCellRenderer = ({ rowData }) => {
    const { id } = rowData;
    return (
      <div>
        <DeleteIcon
          onClick={() => onRemove(id)}
          addClass="icon--table icon--hover-danger"
          width={20}
          height={20}
        />
      </div>
    );
  };

  const filenameCellRenderer = ({ rowData }) => {
    return (
      <>
        {rowData.mimetype !== "url" ? (
          <FileName data-tip={rowData.name}>
            <ImageContent>
              <Image asset={rowData} />
            </ImageContent>
            <FileIcon />{" "}
            <div>
              <span>{rowData.name}</span>{" "}
              <span>
                {rowData.fileSize === "0 B" ? (
                  <a title={rowData.location} href={rowData.location} target="_blank">
                    {formatExternalFileName(rowData.filename)}
                  </a>
                ) : (
                  rowData.filename
                )}
              </span>
            </div>
          </FileName>
        ) : (
          <FileName data-tip={rowData.name}>
            <ImageContent>
              <Image asset={rowData} />
            </ImageContent>
            <FileIcon />{" "}
            <div>
              <span>{rowData.name}</span>
              <span>
                <a href={rowData.location}>{rowData.filename}</a>
              </span>
            </div>
          </FileName>
        )}
      </>
    );
  };

  const formatExternalFileName = (fileName) => {
    return fileName.split("/").reverse()[0];
  };

  const getExtension = (location) => {
    return location.split(".").reverse()[0];
  };

  const groupCellRenderer = ({ rowData }) => {
    const groupUpdate = (e) => {
      if (e.key === "Enter") {
        let updatedData = { updatedData: { ...rowData, group: e.target.value } };
        onDataUpdate(updatedData);
        setEditableCell(null);
      }
    };

    return (
      <span onClick={() => handleCellClick(rowData.id, "group")} style={{ cursor: "pointer" }}>
        {editableCell?.id === rowData.id && editableCell?.field === "group" ? (
          <input
            type="text"
            style={{ border: "0px", backgroundColor: "lightyellow" }}
            onKeyUp={(e) => groupUpdate(e)}
          />
        ) : (
          rowData.group
        )}
      </span>
    );
  };

  const headerRenderer = ({ dataKey, label, sortBy, sortDirection }) => {
    return (
      <div>
        {label}
        {sortBy === dataKey && <SortIndicator sortDirection={sortDirection} />}
      </div>
    );
  };

  const selectCellRenderer = ({ rowData, row, type }) => {
    console.log(rowData);
    console.log(type);

    if (type === "archived") {
      return (
        <Checkbox
          isChecked={rowData.archived}
          onChange={(e) => {
            console.log(e);
            let updatedData = { updatedData: { ...rowData, archived: !rowData.archived } };
            onDataUpdate(updatedData);
          }}
        />
      );
    }
    if (type === "requires_login") {
      return (
        <Checkbox
          isChecked={rowData.requires_login}
          onChange={(e) => {
            console.log(e);
            let updatedData = {
              updatedData: { ...rowData, requires_login: !rowData.requires_login },
            };
            onDataUpdate(updatedData);
          }}
        />
      );
    }
  };

  const height = list.length * TABLE_ROW_HEIGHT + TABLE_HEADER_HEIGHT;

  return (
    list && (
      <Container>
        <AutoSizer disableHeight>
          {({ width }) => (
            <Table
              width={width}
              height={height}
              headerHeight={TABLE_HEADER_HEIGHT}
              rowHeight={TABLE_ROW_HEIGHT}
              rowCount={list.length}
              rowGetter={({ index }) => list[index]}
              headerClassName="table-header"
              rowClassName="table-row"
              sort={onSort}
              sortBy={sortBy}
              sortDirection={sortDir}
            >
              <Column
                label="File Name"
                dataKey="filename"
                flexGrow={1}
                width={475}
                cellRenderer={filenameCellRenderer}
                headerRenderer={headerRenderer}
              />
              <Column
                label="Group"
                dataKey="group"
                flexGrow={1}
                width={125}
                cellRenderer={groupCellRenderer}
                headerRenderer={headerRenderer}
              />
              <Column
                width={100}
                label="File Type"
                dataKey="fileType"
                flexGrow={0}
                className="text-uppercase"
                cellRenderer={({ rowData }) =>
                  rowData.fileType ? rowData.fileType : getExtension(rowData.location)
                }
                headerRenderer={headerRenderer}
              />
              <Column
                width={100}
                label="Size"
                dataKey="size"
                flexGrow={0}
                cellRenderer={({ rowData }) => rowData.fileSize}
                headerRenderer={headerRenderer}
              />
              <Column
                width={100}
                label="Last Updated"
                dataKey="updated_at"
                flexGrow={0}
                cellRenderer={({ rowData }) => rowData.dateAdded}
                headerRenderer={headerRenderer}
              />
              <Column
                label="Archived"
                dataKey="isChecked"
                width={75}
                flexGrow={0}
                cellRenderer={({ rowIndex, row, rowData }) =>
                  selectCellRenderer({ rowIndex, rowData, type: "archived" })
                }
                style={{ overflow: "visible" }}
              />
              {requireLoginFeature && (
                <Column
                  label="Requires Login"
                  dataKey="isChecked"
                  width={80}
                  flexGrow={0}
                  cellRenderer={({ rowIndex, row, rowData }) =>
                    selectCellRenderer({ rowIndex, rowData, type: "requires_login" })
                  }
                  style={{ overflow: "visible" }}
                />
              )}

              <Column
                width={80}
                flexGrow={0}
                dataKey="actions"
                label=""
                cellRenderer={actionCellRenderer}
              />
            </Table>
          )}
        </AutoSizer>
        <Tooltip type="info" effect="solid" />
      </Container>
    )
  );
};

AssetTable.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({})),
  onRemove: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
  sortBy: PropTypes.string,
  sortDirection: PropTypes.string,
  dispatch: PropTypes.func
};

AssetTable.defaultProps = {
  list: [],
};

export default AssetTable;
