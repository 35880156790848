import React from "react";
import PropTypes from "prop-types";

import Button, { DropdownButton, DropdownMenuItem } from "shared/Buttons";
import { PublishIcon } from "shared/Miscellaneous/Icons";
import "shared/Navigation/Tabs/assets/tabs.scss";

const PublishButton = ({ onClick, published }) => {
  if (published) {
    return (
      <DropdownButton title="Publish" id="ProductHeaderControlsPublishButton" color="green">
        <DropdownMenuItem eventKey="0" onClick={() => onClick()}>
          Publish
        </DropdownMenuItem>
        <DropdownMenuItem eventKey="1" onClick={() => onClick(false)}>
          Unpublish
        </DropdownMenuItem>
      </DropdownButton>
    );
  }
  return (
    <Button buttonStyle="green" onClick={() => onClick()} icon={<PublishIcon />} type="button">
      Publish
    </Button>
  );
};

PublishButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  published: PropTypes.bool,
};

export default PublishButton;
