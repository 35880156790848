import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { reducerUtil } from 'base-client';

import { reducerData as projectsData, actions as projectActions } from 'projects';
import { reducerData as permissionsData } from 'permissions';
import { reducerData as tenantData } from 'tenant';
import { SectionHeader } from 'shared';

import Card from 'shared/Insights/Card';
import { AutoSizer, Column, Table, InfiniteLoader } from 'react-virtualized';
import { Rows } from 'shared/TablesAndLists';
import NoDataCallout from './NoDataCallout';

const projectsTable = [
  { label: 'Date Updated', dataKey: 'updated_at' },
  { label: 'Project Name', dataKey: 'name' },
  { label: 'Project Type', dataKey: 'project_type' },
  { label: 'Budget', dataKey: 'project_budget' },
  { label: 'Phase', dataKey: 'project_phase' },
  { label: 'Number of Products', dataKey: 'numberOfProducts' }
];
const LeadProjects = ({
  canView,
  projectList,
  pagination,
  fetchId,
  enableFeatures,
  dispatch,
  match,
  ...props
}) => {
  const { projects: enableProjects } = enableFeatures || {};
  const [hasLoaded, setHasLoaded] = useState(false);
  if (!canView || !enableProjects) return null;

  useEffect(() => {
    const id = match && match.params && match.params.id;
    dispatch(projectActions.getUserProjects(true, id));
    setHasLoaded(true);
  }, []);

  let hasNextPage;
  if (pagination) {
    const { page, pageCount } = pagination;
    hasNextPage = page < pageCount;
  }

  const length = (projectList && projectList.length) || 0;
  const rowCount = hasNextPage ? length + 1 : length + 0 || 0;
  const loadMoreRows = fetchId ? () => null : () => dispatch(projectActions.loadNextPage());
  const isRowLoaded = ({ index }) => !hasNextPage || index < length;

  const infiniteListProps = {
    rowCount,
    loadMoreRows,
    isRowLoaded
  };
  return (
    <>
      {rowCount === 0 && !fetchId && hasLoaded && (
        <NoDataCallout
          dataTitle={'Projects'}
          flavor={
            'You should add them to an email list and invite them to create a Project on your Design Studio.'
          }
        />
      )}
      {rowCount > 0 && (
        <div id="LeadsAutoSizerContainer" className="table--AutoSizer table--leads">
          <InfiniteLoader {...infiniteListProps}>
            {({ onRowsRendered, registerChild }) => (
              <AutoSizer>
                {({ height, width }) => (
                  <Table
                    width={width}
                    height={height}
                    headerHeight={55}
                    headerClassName="table-header"
                    rowClassName="table-row"
                    rowHeight={50}
                    rowCount={length}
                    rowGetter={({ index }) => projectList[index] || {}}
                    ref={registerChild}
                    onRowsRendered={onRowsRendered}
                  >
                    {projectsTable &&
                      projectsTable.map(column => (
                        <Column
                          key={column.dataKey}
                          cellRenderer={Rows.Link}
                          dataKey={column.dataKey}
                          label={column.label}
                          width={200}
                          flexGrow={2}
                        />
                      ))}
                  </Table>
                )}
              </AutoSizer>
            )}
          </InfiniteLoader>
        </div>
      )}
    </>
  );
};

LeadProjects.propTypes = {
  canView: PropTypes.bool,
  fetchId: PropTypes.string,
  pagination: PropTypes.object,
  enableFeatures: PropTypes.object,
  projectList: PropTypes.array
};

const mapStateToProps = state => ({
  projectList: reducerUtil.getSlice(projectsData, projectsData.userProjectList, state),
  canView: reducerUtil.getSlice(permissionsData, permissionsData.leads, state),
  fetchId: reducerUtil.getSlice(projectsData, projectsData.fetchId, state),
  pagination: reducerUtil.getSlice(projectsData, projectsData.pagination, state),
  enableFeatures: reducerUtil.getSlice(tenantData, tenantData.enableFeatures, state)
});

export default withRouter(connect(mapStateToProps)(LeadProjects));
