const blobFileDownload = async (blob, filename) => {
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    window.navigator.msSaveBlob(blob, filename);
  } else {
    const blobUrl = window.URL.createObjectURL(blob);
    urlFileDownload(blobUrl, filename);
    window.URL.revokeObjectURL(blobUrl);
  }
};

const urlFileDownload = (url, filename) => {
  const tempLink = document.createElement('a');
  tempLink.style.display = 'none';
  tempLink.href = url;
  tempLink.setAttribute('download', filename);
  tempLink.setAttribute('target', '_blank');
  document.body.appendChild(tempLink);
  tempLink.click();
  document.body.removeChild(tempLink);
};

export default blobFileDownload;
export { urlFileDownload };
