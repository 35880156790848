/* vendor imports */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import InputIcon from 'shared/Miscellaneous/Icons/Action/InputIcon';
import sbManagerStyles from 'utils/globalStyles';

const StyledSearchbar = styled.div`
  position: relative;
  width: 50rem;
  color: ${() => sbManagerStyles.greyLightest};
  font-size: 1.5rem;
`;

const InputField = styled.input`
  height: 3rem;
  padding-left: 3rem;
  width: 100%;
  border: none;
  :focus {
    outline-width: 1px;
  }
`;

const StyledInputIcon = styled(InputIcon)`
  position: absolute;
  top: 0.75rem;
  left: 1rem;
  color: ${() => sbManagerStyles.greyLight};
  font-size: 1.5rem;
`;

const SearchBar = ({ searchValue, handleSearch, placeholder, ...props }) => {
  return (
    <StyledSearchbar {...props}>
      <StyledInputIcon />
      <InputField
        type="text"
        placeholder={placeholder}
        value={searchValue}
        onChange={e => handleSearch(e.target.value)}
      />
    </StyledSearchbar>
  );
};

SearchBar.propTypes = {
  searchValue: PropTypes.string,
  handleSearch: PropTypes.func.isRequired,
  canView: PropTypes.bool
};

SearchBar.defaultProps = {
  searchValue: '',
  canView: false
};

export default SearchBar;
