import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from './../SvgIcon';

const ImportIcon = (props) => (
  <SvgIcon {...props}>
    <polygon points="17.707,10.708 16.293,9.294 13,12.587 13,2.001 11,2.001 11,12.587 7.707,9.294 6.293,10.708
      12,16.415     "/>
    <path d="M18,18.001v2H6v-2H4v2c0,1.102,0.897,2,2,2h12c1.104,0,2-0.898,2-2v-2H18z"/>
  </SvgIcon>
)

ImportIcon.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  viewBox: PropTypes.string.isRequired,
};

ImportIcon.defaultProps = {
  width: 20,
  height: 14,
  viewBox: '0 0 24 22',
};

export default ImportIcon;
