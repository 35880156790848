/* vendor imports */
import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';

/* system imports */
import { actions as adminActions, reducerData as adminData } from 'admin';
import { formUserNewName } from 'admin/utils';
import NewUserContainer from './NewUserContainer';
import reducerUtil from 'base-client/dist/reducerUtil';

const selector = formValueSelector(formUserNewName);

const mapStateToProps = state => {
  const roles = reducerUtil.getSlice(adminData, adminData.roles, state);
  return {
    disableCreateUser: !selector(state, 'email'),
    roles,
    initialValues: {
      role: roles && roles[0]
    }
  };
};

const mapDispatchToProps = dispatch => ({
  clickCreateNewUser: () => dispatch(adminActions.postUserNew())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: formUserNewName
  })(NewUserContainer)
);
