import React from 'react';
import PropTypes from 'prop-types';

import Button from 'shared/Buttons';
import Card from './Card';
import Header from './Header';

const ForgotPassword = ({ setUser, onClickBack, onClickReset, invalid, user, message }) => (
  <Card title={'Reset Password'} back={onClickBack} message={message}>
    <p className="state--neutral">
      Enter your login email address so we can send you steps on how to reset your password.
    </p>
    <Header>Email Address</Header>
    <input
      className="input--text"
      type="email"
      placeholder="Enter your email address"
      value={user || ''}
      onChange={e => setUser(e)}
    />
    {invalid.user && <p>Please enter an email address</p>}
    <Button buttonStyle="create" onClick={onClickReset}>
      Send Email
    </Button>
  </Card>
);

ForgotPassword.propTypes = {
  setUser: PropTypes.func.isRequired,
  onClickBack: PropTypes.func.isRequired,
  onClickReset: PropTypes.func.isRequired,
  invalid: PropTypes.object.isRequired,
  user: PropTypes.string,
  message: PropTypes.object
};

export default ForgotPassword;
