import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from './../SvgIcon';

const UploadIcon = props => (
  <SvgIcon {...props}>
    <path
      d="M15,2.001c-2.859,0-5.358,1.699-6.438,4.254C8.061,6.087,7.533,6.001,7,6.001c-2.757,0-5,2.243-5,5
			c0,2.414,1.721,4.435,4,4.898v-2.083c-1.162-0.414-2-1.514-2-2.815c0-1.654,1.346-3,3-3c0.57,0,1.131,0.166,1.618,0.48
			C8.893,8.659,9.235,8.69,9.535,8.568c0.303-0.122,0.525-0.384,0.6-0.7C10.662,5.591,12.663,4.001,15,4.001c2.757,0,5,2.243,5,5
			c0,1.627-0.793,3.061-2,3.975v2.34c2.361-1.126,4-3.529,4-6.314C22,5.142,18.859,2.001,15,2.001z"
    />
    <polygon
      points="16.707,14.294 12,9.587 7.293,14.294 8.707,15.708 11,13.415 11,22.001 13,22.001
			13,13.415 15.293,15.708"
    />
  </SvgIcon>
);

UploadIcon.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  viewBox: PropTypes.string.isRequired
};

UploadIcon.defaultProps = {
  width: 20,
  height: 14,
  viewBox: '0 0 24 22'
};

export default UploadIcon;
