/* vendor imports */
import React from 'react';
import PropTypes from 'prop-types';

/* system imports */
import Button from '../../Button';
import CloseCircleIcon from '../../../../Miscellaneous/Icons/Action/CloseCircleIcon';


const CancelButton = ({ children, ...other }) => (
  <Button
    buttonStyle='link'
    icon={<CloseCircleIcon />}
    iconPosition='after'
    { ...other }
  >
    { children }
  </Button>
);

CancelButton.propTypes = {
  children: PropTypes.node,
};

CancelButton.defaultProps = {
  children: null,
};

export default CancelButton;
