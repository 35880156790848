import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../SvgIcon';

const EventsIcon = props => (
  <SvgIcon {...props}>
    <path id="shadow" className="color--light" d="M22.4375 18.3378H23.4365C24.0365 18.3378 24.4365 18.7545 24.4365 19.3795V22.2961C24.4365 22.9211 24.0365 23.3378 23.4365 23.3378H1.43652C0.836523 23.3378 0.436523 22.9211 0.436523 22.2961V19.3795C0.436523 18.7545 0.836523 18.3378 1.43652 18.3378H2.43748V19.3719C2.43748 20.4719 3.33748 21.3719 4.43748 21.3719H20.4375C21.5375 21.3719 22.4375 20.4719 22.4375 19.3719V18.3378Z" />
    <g className="icon--inactive">
      <path className="color--medium" d="M20.4375 2.35001H13.4375V1.35001C13.4375 0.797006 12.9905 0.350006 12.4375 0.350006H6.4375C5.8845 0.350006 5.4375 0.797006 5.4375 1.35001V2.35001H4.4375C3.3335 2.35001 2.4375 3.24601 2.4375 4.35001V19.35C2.4375 20.454 3.3335 21.35 4.4375 21.35H20.4375C21.5415 21.35 22.4375 20.454 22.4375 19.35V4.35001C22.4375 3.24601 21.5415 2.35001 20.4375 2.35001ZM7.4375 2.35001H11.4375V10.936L10.1445 9.64301C9.9495 9.44801 9.6935 9.35001 9.4375 9.35001C9.1815 9.35001 8.9255 9.44801 8.7305 9.64301L7.4375 10.936V2.35001ZM20.4345 19.35H4.4375V4.35001H5.4375V13.35C5.4375 13.754 5.6815 14.12 6.0545 14.274C6.4285 14.428 6.8575 14.343 7.1445 14.057L9.4375 11.764L11.7305 14.057C11.9215 14.248 12.1775 14.35 12.4375 14.35C12.5665 14.35 12.6965 14.325 12.8205 14.274C13.1935 14.12 13.4375 13.754 13.4375 13.35V4.35001H20.4375L20.4345 19.35Z" />
      <path className="color--medium" d="M18.4375 6.35001H14.4375V8.35001H18.4375V6.35001Z" />
      <path className="color--medium" d="M18.4375 9.35001H14.4375V11.35H18.4375V9.35001Z" />
      <path className="color--medium" d="M18.4375 12.35H14.4375V14.35H18.4375V12.35Z" />
      <path className="color--medium" d="M18.4375 15.35H6.4375V17.35H18.4375V15.35Z" />
    </g>
    <g className="icon--active">
      <rect className="color--medium" x="5.4696" y="1.32721" width="7" height="10" />
      <path className="color--dark" d="M20.0004 2.35004H13.0004V1.35004C13.0004 0.797037 12.5534 0.350037 12.0004 0.350037H6.00037C5.44737 0.350037 5.00037 0.797037 5.00037 1.35004V2.35004H4.00037C2.89737 2.35004 2.00037 3.24704 2.00037 4.35004V19.35C2.00037 20.454 2.89737 21.35 4.00037 21.35H20.0004C21.1044 21.35 22.0004 20.454 22.0004 19.35V4.35004C22.0004 3.24704 21.1044 2.35004 20.0004 2.35004ZM7.00037 2.35004H11.0004V10.936L9.70737 9.64304C9.51237 9.44804 9.25637 9.35004 9.00037 9.35004C8.74437 9.35004 8.48837 9.44804 8.29337 9.64304L7.00037 10.936V2.35004ZM18.0004 17.35H6.00037V15.35H18.0004V17.35ZM18.0004 12.35H14.0004V10.35H18.0004V12.35ZM18.0004 8.35004H14.0004V6.35004H18.0004V8.35004Z" />
    </g>
  </SvgIcon>
);

EventsIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

EventsIcon.defaultProps = {
  width: 22,
  height: 22
};

export default EventsIcon;
