import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { reducerUtil } from "base-client";

import { SectionHeader } from "shared";

import { reducerData as permissionsData } from "permissions";

import APIKeys from "APIKeys";

class APIKeysContainer extends React.Component {
  static propTypes = {
    canView: PropTypes.bool,
  };
  static defaultProps = {
    canView: false,
  };

  render() {
    const { canView } = this.props;
    if (!canView) return null;
    return (
      <React.Fragment>
        <SectionHeader title="API Keys Management" subtitle="Add or manage API keys" />
        <APIKeys />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  canView: reducerUtil.getSlice(permissionsData, permissionsData.admin, state),
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(APIKeysContainer);

export { APIKeysContainer, mapStateToProps, mapDispatchToProps };
