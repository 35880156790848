import React from 'react';
import styled from 'styled-components';

import sbManagerStyles from 'utils/globalStyles';
import { utils as detailsUtils } from 'productDetails';

const StyledSubmittalItem = styled.div`
  line-height: 2rem;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: ${sbManagerStyles.greyMedium};
  font-size: 1.1rem;
`;

const Download = styled.div`
  width: 8rem;
  display: flex;
  justify-content: space-between;
`;

const Title = styled.div`
  text-transform: uppercase;
`;

const Name = styled.div`
  font-size: 1.4rem;
  color: ${sbManagerStyles.greyDarkest};
`;

const DocumentListItem = props => {
  const { submittal, brochure } = props;

  const getDocumentName = fileLocation => {
    const n = fileLocation.lastIndexOf('/');
    return fileLocation.substring(n + 1);
  };

  const submittalName = submittal ? getDocumentName(submittal.file_location) : null;
  const brochureName = brochure ? getDocumentName(brochure.file_location) : null;

  return (
    <>
      {submittal ? (
        <StyledSubmittalItem>
          <Title>Submittal</Title>
          <Name>{submittalName}</Name>
          <Download>
            {submittal.size ? (
              <React.Fragment>
                <div>{detailsUtils.getFileSize(submittal.size)}</div>|
              </React.Fragment>
            ) : null}
            <a href={submittal.link}>view</a>
          </Download>
        </StyledSubmittalItem>
      ) : (
        <StyledSubmittalItem>
          <Title>Brochure</Title>
          <Name>{brochureName}</Name>
          <Download>
            {brochure.size ? (
              <React.Fragment>
                <div>{detailsUtils.getFileSize(brochure.size)}</div>|
              </React.Fragment>
            ) : null}
            <a href={brochure.link}>view</a>
          </Download>
        </StyledSubmittalItem>
      )}
    </>
  );
};

export default DocumentListItem;
