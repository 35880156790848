import React from 'react';
import styled from 'styled-components';

import ProgressBar from './ProgressBar';
import Dropzone from './Dropzone';

const Container = styled.div``;

const Upload = ({ percentage, failed, children }) => (
  <Container>
    <Dropzone>{children}</Dropzone>
    <ProgressBar />
  </Container>
);

export default Upload;
