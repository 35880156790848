import { api, config, reducerUtil } from 'base-client';
import moment from 'moment';

import { isEmpty } from '../utils';
import { reducerData as tenantData } from 'tenant';
import { actions as leadsActions } from 'leads';
import { reducerData as projectDetailData } from 'projectDetails';

const loadProject = id => async (dispatch, getState) => {
  if (!id) return;

  const tenantId = reducerUtil.getSlice(tenantData, tenantData.tenant, getState());
  dispatch(reducerUtil.setSlice(projectDetailData, projectDetailData.generalData, undefined));

  return await Promise.all([
    dispatch(loadGeneralData(id, tenantId)),
    dispatch(loadContactInformation(id, tenantId)),
    dispatch(loadSubmittalData(id, tenantId)),
    dispatch(loadBrochureData(id, tenantId)),
    dispatch(loadActionData(id))
  ]);
};

const loadGeneralData = (id, tenantId) => async (dispatch, getState) => {
  try {
    const project = await dispatch(api.actions.get(`projects/${id}?tenant_id=${tenantId}`, true));

    const { user_id: userId } = project;
    dispatch(leadsActions.getLeadData(userId));
    dispatch(reducerUtil.setSlice(projectDetailData, projectDetailData.generalData, project));
  } catch (error) {
    dispatch(config.actions.error(error));
  }
};

const loadContactInformation = (id, tenantId) => async dispatch => {
  let contactInformation = {};
  try {
    const participants = await dispatch(
      api.actions.get(`projects/${id}/participants?tenant_id=${tenantId}`, true)
    );

    const { projectParticipants } = participants || {};

    projectParticipants.forEach(participant => {
      const { address } = participant;

      const parsedAddress = {
        addressLine1: address.address_line_1,
        addresLine2: address.address_line_2,
        city: address.city,
        state: address.state,
        zipcode: address.zipcode,
        country: address.country
      };

      const formattedAddress = isEmpty(parsedAddress) ? null : parsedAddress;

      contactInformation[participant.participant_type] = {
        companyName: participant.company_name,
        contactName: participant.contact_name,
        phone: participant.phone,
        fax: participant.fax,
        email: participant.email,
        address: formattedAddress
      };
    });

    dispatch(
      reducerUtil.setSlice(
        projectDetailData,
        projectDetailData.contactInformation,
        contactInformation
      )
    );
  } catch (error) {
    dispatch(config.actions.error(error));
  }
};
const loadSubmittalData = (id, tenantId) => async dispatch => {
  try {
    const { projectSubmittals: submittals } =
      (await dispatch(api.actions.get(`projects/${id}/submittals?tenant_id=${tenantId}`, true))) ||
      {};

    dispatch(reducerUtil.setSlice(projectDetailData, projectDetailData.submittals, submittals));
  } catch (error) {
    dispatch(config.actions.error(error));
  }
};
const loadBrochureData = (id, tenantId) => async dispatch => {
  try {
    const { projectBrochures: brochures } =
      (await dispatch(api.actions.get(`projects/${id}/brochures?tenant_id=${tenantId}`, true))) ||
      {};

    dispatch(reducerUtil.setSlice(projectDetailData, projectDetailData.brochures, brochures));
  } catch (error) {
    dispatch(config.actions.error(error));
  }
};

const loadActionData = id => async dispatch => {
  try {
    const { searchResults } = await dispatch(
      api.actions.post('analytics/visitors/events/search?page=1&limit=25', {
        sort: {
          sortBy: 'date',
          sortDir: 'desc'
        },
        dateRange: {
          start: moment(0).toISOString(),
          end: moment().toISOString()
        },
        includeAnonymous: 'true',
        filters: [
          {
            attribute: 'project_id',
            facets: [id]
          }
        ]
      })
    );

    dispatch(reducerUtil.setSlice(projectDetailData, projectDetailData.history, searchResults));
  } catch (error) {
    dispatch(config.actions.error(error));
  }
};

export default { loadProject };
