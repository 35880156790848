import React from 'react';
import PropTypes from 'prop-types';

import { SectionHeader } from 'shared';
import Card from 'shared/PageLayout/Card';
import PieChart from './PieChart';
import BarChart from './BarChart';

const ProductUsage = ({ actions = [], categories = [], sites = [] }) => (
  <div>
    <SectionHeader title="Product Usage" />
    <Card>
      <div className="lead-product-use">
        <div className="lead-product-use__chart activity-type-chart">
          <PieChart data={actions} namespace="ActivityType" title="Total Events" />
        </div>
        <div className="lead-product-use__chart channel-activity-chart">
          <PieChart data={sites} namespace="ChannelActivity" title="Channel Activity" />
        </div>
        <div className="lead-product-use__chart">
          <BarChart data={categories} namespace="CategoryUsage" title="Category Usage" />
        </div>
      </div>
    </Card>
  </div>
);

ProductUsage.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      y: PropTypes.number.isRequired
    })
  ),
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      y: PropTypes.number.isRequired
    })
  ),
  sites: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      y: PropTypes.number.isRequired
    })
  )
};

export default ProductUsage;
