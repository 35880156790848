import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { actions as imagesActions } from 'images';

const ProductImage = ({ imageId, productId, dispatch }) => {
  const [image, setImage] = useState();

  useEffect(() => {
    if (imageId) {
      const getImage = async () => {
        const newImage = await dispatch(imagesActions.addImage(imageId, productId));
        setImage(newImage);
      };
      getImage();
    } else setImage(undefined);
  }, [imageId]);

  if (!image) return null;

  return <img src={image} alt="" />;
};

Image.propTypes = {
  dispatch: PropTypes.func.isRequired,
  productId: PropTypes.string.isRequired,
  imageId: PropTypes.string.isRequired
};

export default connect()(ProductImage);
