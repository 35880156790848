import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from './../SvgIcon';

const LogOutIcon = (props) => (
  <SvgIcon {...props}>
    <polygon points="22,11 10.41,11 12.707,8.708 11.293,7.292 6.586,11.991 11.292,16.707 12.708,15.294
      10.418,13 22,13     "/>
    <path d="M19.411,15c-1.19,2.928-4.061,5-7.411,5c-4.411,0-8-3.589-8-8s3.589-8,8-8
      c3.35,0,6.221,2.073,7.411,5h2.127C20.261,4.949,16.469,2,12,2C6.486,2,2,6.487,2,12s4.486,10,10,10c4.469,0,8.261-2.948,9.539-7
      H19.411z"/>
  </SvgIcon>
)

LogOutIcon.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  viewBox: PropTypes.string.isRequired,
};

LogOutIcon.defaultProps = {
  width: 20,
  height: 20,
  viewBox: '0 0 24 24',
};

export default LogOutIcon;

