import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AutoSizerList from '../components/AutoSizerList';
import Loading from '../components/Loading';

class Container extends Component {
  constructor(props) {
    super(props);
    this.rowRenderer = this.rowRenderer.bind(this);
    this.state = {};
  }

  rowRenderer({ index, key, style }) {
    const { data } = this.props;
    let listKey = data ? data[index] : { name: 'waddup' };

    if (typeof (listKey) === 'object' && listKey.hasOwnProperty('name')) listKey = listKey.name;

    return (
      <div key={key} style={style} >{listKey}</div>
    );
  }
  render() {
    const { rowRenderer } = this;
    const { ...state } = this.state;
    const { data } = this.props;
    const noRowsRenderer = data ? () => <div /> : Loading;
    const rowCount = data ? data.length : 0;

    return (
      <AutoSizerList {...{
        noRowsRenderer,
        rowCount, rowRenderer, ...state, ...this.props,
      }}
      />
    );
  }
}

Container.propTypes = {
  data: PropTypes.array,
};

Container.defaultProps = {
  data: [],
};

export default Container;
