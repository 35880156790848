import { connect } from 'react-redux';
import ModalConfirmView from '../components/ModalConfirmView';

const mapStateToProps = state => {
  let display = {};
  if (state.modal && state.modal.confirmDisplay) {
    display = state.modal.confirmDisplay;
  }
  return { ...display };
};

export default connect(mapStateToProps)(ModalConfirmView);
