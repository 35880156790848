/* vendor imports */
import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { FormGroup, FormControl } from 'react-bootstrap';
import cn from 'classnames';

/* system imports */

const Name = props => (
  <div className="form__attribute-unit">
    <div className="form__attribute-title">Name</div>
    <div className="form__attribute-line">
      <Field name="name" component={NameField} {...props} />
    </div>
  </div>
);

const NameField = ({ input, className, disabled }) => (
  <FormGroup controlId={input.name} bsClass={cn('form-group', className)}>
    <FormControl bsClass={cn('form-control', null)} {...input} {...{ disabled }} />
  </FormGroup>
);

NameField.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string
  }).isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool
};

NameField.defaultProps = {
  className: null,
  disabled: false
};

export default Name;
