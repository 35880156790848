export default {
  name: 'projectDetails',
  generalData: 'generalData',
  contactInformation: 'contactInformation',
  products: 'products',
  pagination: 'pagination',
  fetchId: 'fetchId',
  submittals: 'submittals',
  brochures: 'brochures',
  history: 'history'
};
