import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { reducerUtil } from 'base-client';

import ModalConfirm from 'shared/Overlays/Modal/modals/ModalConfirm';
import Sidebar from './Sidebar';
import Header from './Header';
import Footer from './Footer';
import 'styles/app.scss';
import '../assets/dashboard-layout.scss';
import { reducerData as permissionsData } from 'permissions';

const DashboardLayout = ({ permissions, showSidebar, children }) => (
  <div>
    <ModalConfirm />
    <Header />
    <Sidebar />
    <main className={cn('main', { 'is-expanded': !showSidebar })}>{permissions && children}</main>
    <Footer />
  </div>
);

DashboardLayout.propTypes = {
  children: PropTypes.element.isRequired,
  showSidebar: PropTypes.bool,
  permissions: PropTypes.bool
};

const mapStateToProps = state => ({
  showSidebar: state.sidebar.showSidebar,
  permissions: reducerUtil.getSlice(permissionsData, permissionsData.checked, state)
});

export default withRouter(connect(mapStateToProps)(DashboardLayout));
