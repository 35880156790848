/* vendor imports */
import moment from 'moment';
import 'moment-timezone';

/* type constants */
const formats = {
  NUMBER: 'number',
  STATUS: 'status',
  CHART: 'chart',
  REVIT_FILE: 'revitFile',
  RELATIVE_TIME: 'relativeTime',
  LOCALIZED_TIME: 'localizedTime',
  PRODUCT_ACTION_TIME: 'productActionTime'
};

/* General utility functions */
const isToday = date => moment(date).isSame(moment(), 'date');
const isWeekOrMoreOld = date => moment(date).diff(moment(), 'days') > 6;

/* Formatting functions */
const dateNumberFormatter = new Intl.DateTimeFormat('en-US', {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric'
});
const toNumber = date => dateNumberFormatter.format(date);

const dateStatusFormatter = new Intl.DateTimeFormat('en-US', {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric'
});
const toStatus = date => dateStatusFormatter.format(date);

const dateChartFormatter = {
  year: date =>
    moment(date)
      .utcOffset(-4)
      .format('YYYY')
      .toString(),
  month: date =>
    moment(date)
      .utcOffset(-4)
      .format('MMM')
      .toString(),
  week: date =>
    moment(date)
      .utcOffset(-4)
      .format('MMM D')
      .toString(),
  day: date =>
    moment(date)
      .utcOffset(-4)
      .format('MMM D')
      .toString()
};
const toChart = (interval, date) => dateChartFormatter[interval](date);

// Format specific to metadata section of revit tab on product show page
const toRevitFile = datetime =>
  toStatus(datetime).replace(/AM|PM/, meridiem => meridiem.toLowerCase());

const toRelativeTime = date => moment(date).fromNow();

// For use with the following formatting options:
//   LT, LTS, L, l, LL, ll, LLL, lll, LLLL, llll
// Defaulting output is of format llll, e.g.: Thu, Sep 4, 1986 8:30 PM
const toLocalizedTime = (date, formatVal = 'llll') => moment(date).format(formatVal);

const toProductActionTime = datetime => {
  return isWeekOrMoreOld(datetime) ? toLocalizedTime(datetime) : toRelativeTime(datetime);
};

// Here, interval doesn't need a default value as it should throw and error if
//   toChart is not given a interval value.
const formatAs = (typeConstant, datetime = moment(), interval) => {
  switch (typeConstant) {
    case formats.NUMBER:
      return toNumber(datetime);
    case formats.STATUS:
      return toStatus(datetime);
    case formats.CHART:
      return toChart(interval, datetime);
    case formats.REVIT_FILE:
      return toRevitFile(datetime);
    case formats.RELATIVE_TIME:
      return toRelativeTime(datetime);
    case formats.LOCALIZED_TIME:
      return toLocalizedTime(datetime);
    case formats.PRODUCT_ACTION_TIME:
      return toProductActionTime(datetime);
    default:
      return datetime;
  }
};

const formatTime = (value, eventText) => {
  if (!value) return `Not yet ${eventText}`;
  if (isToday(value)) {
    return formatAs(formats.PRODUCT_ACTION_TIME, new Date(value));
  } else {
    return formatAs(formats.NUMBER, new Date(value));
  }
};

/* Timezone functions */
const zone = () => moment.tz.guess();
const date = utcDate => moment.tz(utcDate, zone()).format();

export default {
  // General utility functions are top-level.
  isToday,
  isWeekOrMoreOld,
  formats,
  formatAs,
  format: {
    toNumber,
    toStatus,
    toChart,
    toRevitFile,
    toRelativeTime,
    toLocalizedTime,
    toProductActionTime
  },
  timeZone: {
    zone,
    date
  },
  formatTime
};
