import { config } from "base-client";
import { configMap } from "configurations";

import { serviceKeyServices } from "./services";
import {
  getTokenAndServiceKeyFromLocalStorage,
  setServiceKeyAndTokenPairInLocalStorage,
} from "./utils";

const fetchServiceKey = () => (dispatch) => {
  const { token } = getTokenAndServiceKeyFromLocalStorage();

  const { serviceKeyAPI } = dispatch(
    config.actions.getData(configMap.redirect.name)
  );

  serviceKeyServices
    .fetchServiceKey({ token, serviceKeyAPI })
    .then((newServiceKey) => {
      setServiceKeyAndTokenPairInLocalStorage({
        token,
        serviceKey: newServiceKey,
      });
    })
    .catch((error) => {
      console.error(error);
    });
};

export const serviceKeyActions = {
  fetchServiceKey,
};
