import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from './../SvgIcon';

const StarHalfIcon = (props) => (
  <SvgIcon {...props}>
		<path d="M12.23,2.05a1,1,0,0,0-1.12.52L8.38,8H3a1,1,0,0,0-.71,1.71l4.58,4.58L5,20.75a1,1,0,0,0,1.52,1.11l6-4A1,1,0,0,0,13,17V3A1,1,0,0,0,12.23,2.05Z"/>
  </SvgIcon>
)

StarHalfIcon.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  viewBox: PropTypes.string.isRequired,
};

StarHalfIcon.defaultProps = {
  width: 24,
  height: 24,
  viewBox: '0 0 24 24',
};

export default StarHalfIcon;