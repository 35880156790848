import { GridViewIcon, ListViewIcon } from 'shared/Miscellaneous/Icons';

const listOptions = [
  {
    key: 'GRID',
    icon: GridViewIcon
  },
  {
    key: 'TABLE',
    icon: ListViewIcon
  }
];

const storageKey = 'productListView';

const directions = {
  asc: 'ASC',
  desc: 'DESC'
};

const toDirection = sortDir => {
  switch (sortDir) {
    case -1:
      return directions.desc;
    case 1:
      return directions.asc;
    default:
      return null;
  }
};

const GRID_COLUMN_WIDTH = 250;
const GRID_ROW_HEIGHT = 300;

export { listOptions, storageKey, directions, toDirection, GRID_COLUMN_WIDTH, GRID_ROW_HEIGHT };
