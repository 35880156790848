/* vendor imports */
import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { ControlLabel } from 'react-bootstrap';

/* system imports */
import SelectField from './SelectField';
import { distForm } from 'productAttributes/utils';
import { RevitParameterGroups } from 'utils/revit';
import Checkbox from 'shared/InputGroups/Checkbox';

const Distribution = ({ revitOption, ...props }) => (
  <div className="form__attribute-unit">
    <div className="form__attribute-title">Distribution Options</div>
    <Field name={distForm.revit.option} component={CheckField} title="Revit" {...props} />
    {revitOption && <RevitFields {...props} />}
  </div>
);

Distribution.propTypes = {
  revitOption: PropTypes.bool
};

Distribution.defaultProps = {
  revitOption: false
};

const CheckField = ({ input, disabled, title }) => {
  const { value, onChange } = input;
  const checkProps = {
    isChecked: value,
    isLocked: disabled,
    onChange
  };
  return (
    <div className="checkbox--inline mb-2">
      <Checkbox {...checkProps} size={18} viewBox="0 0 18 18" />
      <ControlLabel>{title}</ControlLabel>
    </div>
  );
};

CheckField.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired
  }).isRequired,
  title: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

CheckField.defaultProps = {
  disabled: false
};

const RevitFields = ({ disciplines, revitTypes, ...props }) => (
  <div className="mb-2">
    <div className="form__attribute-line">
      <div className="distribution-options__title">Discipline</div>
      <Field
        name={distForm.revit.discipline}
        component={SelectField}
        options={disciplines}
        {...props}
      />
    </div>
    <div className="form__attribute-line">
      <div className="distribution-options__title">Parameter Type</div>
      <Field
        name={distForm.revit.revitAttribute}
        component={SelectField}
        options={revitTypes}
        {...props}
      />
    </div>
    <div className="form__attribute-line">
      <div className="distribution-options__title">Parameter Group</div>
      <Field
        name={distForm.revit.group}
        component={SelectField}
        options={RevitParameterGroups}
        {...props}
      />
    </div>
    <div className="form__attribute-line">
      <Field
        name={distForm.revit.instance}
        component={RadioField}
        {...props}
        classNames={{ label: 'radio-label', input: 'sb-radio', containerDiv: 'radio-container' }}
        options={[
          { key: 'familytype', display: 'Type' },
          { key: 'instance', display: 'Instance' }
        ]}
      />
    </div>
  </div>
);

RevitFields.propTypes = {
  disciplines: PropTypes.array.isRequired,
  revitTypes: PropTypes.array.isRequired
};

const RadioField = ({ input: { value, onChange }, options, disabled, classNames }) => (
  <div>
    {options.map(option => (
      <div key={option.key} className={classNames.containerDiv}>
        <input
          {...{ onChange, disabled }}
          type="radio"
          className={classNames.input}
          id={option.key}
          value={option.key}
          checked={value === option.key}
        />
        <label className={classNames.label} htmlFor={option.key}>
          {option.display}
        </label>
      </div>
    ))}
  </div>
);

RadioField.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired
  }).isRequired,
  classNames: PropTypes.shape({
    input: PropTypes.string.isRequired
  }).isRequired,
  options: PropTypes.array.isRequired,
  disabled: PropTypes.bool
};

RadioField.defaultProps = {
  disabled: false
};

export default Distribution;
