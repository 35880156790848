import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { reducerUtil } from 'base-client';

import { reducerData as permissionsData } from 'permissions';
import { actions as eventsLogActions, reducerData as eventsLogData } from 'eventsLog';
import timeUtil from 'utils/newTimeUtil';
import { getlocationQuery } from 'utils/miscUtils';
import { LinkButton } from 'shared/Buttons';
import sbManagerStyles from 'utils/globalStyles';
import { defaultISOInterval } from 'shared-features-client';

import { PageHeader } from 'shared';
import Filters from './Filters';
import EventsLogView from './EventsLogView';
import ActionsBarContainer from './ActionsBarContainer';

import {
  RADIO_LIST as radioSelections,
  EVENTS_TYPE_ATTRIBUTE,
  VISITOR_TYPE_ATTRIBUTE,
  EVENT_TYPE_KEYS
} from '../utils';

const Container = styled.div``;
const MainContent = styled.div`
  display: flex;
  justify-content: space-between;
  .ReactVirtualized__Table__rowColumn {
    white-space: nowrap;
    a {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      cursor: pointer;
      &:hover {
        color: ${() => sbManagerStyles.blueDark} !important;
      }
    }
  }
`;

const EventsLogContainer = ({
  history,
  list,
  meta,
  filters,
  fetchId,
  query: queryParams,
  canView,
  dispatch
}) => {
  if (!canView) return null;

  const [state, setState] = useState();

  useEffect(() => {
    const {
      location: { search }
    } = history;

    const { lastSearch } = state || {};

    if (search !== lastSearch) {
      if (!lastSearch) {
        const { dateRange } = getlocationQuery();
        const defaultDateRange = {
          start: defaultISOInterval.startDate,
          end: defaultISOInterval.endDate
        };

        dispatch(eventsLogActions.getFullData(dateRange || defaultDateRange));
      } else {
        dispatch(eventsLogActions.search(true));
      }
      setState({ lastSearch: search });
    }
  });

  const updateFilter = ({ checked, attribute, facets }) => {
    if (radioSelections.includes(attribute))
      return dispatch(eventsLogActions.updateFilter({ attribute, facets }));

    if (attribute === VISITOR_TYPE_ATTRIBUTE) return dispatch(eventsLogActions.updateVisitorType());

    return !checked
      ? dispatch(eventsLogActions.removeFilter({ attribute, facets }))
      : dispatch(eventsLogActions.addFilter({ attribute, facets }));
  };

  const { list: filterList } = filters || {};
  const { filters: selected, sort, dateRange, includeAnonymous } = queryParams || {};

  let interval = defaultISOInterval;
  if (dateRange) {
    const { start: startDate, end: endDate } = dateRange;
    interval = {
      startDate,
      endDate
    };
  }

  const { sortBy, sortDir } = sort || {};

  const { facets: selectedEventType } =
    (selected && selected.find(({ attribute }) => attribute === EVENTS_TYPE_ATTRIBUTE)) || {};

  const nameRenderer = ({ rowData }) => {
    const { userId, name, fullName, userID } = rowData;

    const activeId = userId ? userId : userID;
    const activeName = name ? name : fullName;

    if (!activeName) {
      if (!activeId) {
        return '';
      } else {
        return <LinkButton to={`/insights/contacts/${activeId}`}>Anonymous User</LinkButton>;
      }
    } else if (!activeId) {
      return activeName;
    } else {
      return <LinkButton to={`/insights/contacts/${activeId}`}>{activeName}</LinkButton>;
    }
  };

  const dateRenderer = ({ rowData }) => {
    const { date, datetime } = rowData;

    if (date) {
      return timeUtil.toMoment(date).format('M/D/YY, h:mma');
    }

    if (datetime) {
      return timeUtil.toMoment(datetime).format('M/D/YY, h:mma');
    }
  };

  const viewProps = {
    list,
    meta,
    fetchId,
    nameRenderer,
    dateRenderer,
    sortBy,
    sortDir,
    setSort: ({ sortBy, sortDirection: sortDir }) =>
      dispatch(eventsLogActions.setSort({ sortBy, sortDir })),
    eventType: (selectedEventType && selectedEventType[0]) || EVENT_TYPE_KEYS.DOWNLOAD
  };

  return (
    <Container className="container insights">
      <PageHeader title="Events Log" className="page-title">
        <ActionsBarContainer {...{ interval }} />
      </PageHeader>
      <MainContent>
        <Filters
          {...{ filters: filterList, selected, updateFilter, radioSelections, includeAnonymous }}
        />
        <EventsLogView {...viewProps} />
      </MainContent>
    </Container>
  );
};

EventsLogContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  canView: PropTypes.bool.isRequired,
  page: PropTypes.number,
  list: PropTypes.arrayOf(PropTypes.shape({})),
  meta: PropTypes.shape({
    pagination: PropTypes.shape({
      page: PropTypes.number,
      pageCount: PropTypes.number
    })
  }),
  fetchId: PropTypes.string
};

EventsLogContainer.defaultProps = {
  list: []
};

const mapStateToProps = state => ({
  canView: reducerUtil.getSlice(permissionsData, permissionsData.insights, state),
  list: reducerUtil.getSlice(eventsLogData, eventsLogData.list, state),
  meta: reducerUtil.getSlice(eventsLogData, eventsLogData.meta, state),
  query: reducerUtil.getSlice(eventsLogData, eventsLogData.query, state),
  fetchId: reducerUtil.getSlice(eventsLogData, eventsLogData.fetchId, state),
  filters: reducerUtil.getSlice(eventsLogData, eventsLogData.filters, state)
});

export default withRouter(connect(mapStateToProps)(EventsLogContainer));
