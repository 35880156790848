/* vendor imports */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { reducerData as insightsData, actions } from 'insights';
import { reducerUtil } from 'base-client';
import Card from '../../shared/Insights/Card';
import { PageHeader } from 'shared';
import Snapshot from './Snapshot';
import Stats from './StatsContainer';
import Map from './Map';

const SnapshotContainer = ({ productSnapshot, hideHeader, ...cardProps }) => (
  <div>
    {!hideHeader && (
      <PageHeader title="Snapshot" subtitle="Check out a holistic performance overview" />
    )}
    <Card headerBorder {...cardProps}>
      <Snapshot {...productSnapshot} />
    </Card>
  </div>
);

SnapshotContainer.propTypes = {
  productSnapshot: PropTypes.object,
  hideHeader: PropTypes.bool
};

SnapshotContainer.defaultProps = {
  productSnapshot: {},
  hideHeader: false
};

const mapStateToProps = state => {
  const productSnapshot =
    reducerUtil.getSlice(insightsData, insightsData.productSnapshot, state) || {};
  return { productSnapshot };
};

const mapDispatchToProps = dispatch => ({
  onSelect: ({ stream, interval, productKey }) =>
    dispatch(actions.getProductSnapshot(stream, interval, productKey))
});

export default connect(mapStateToProps, mapDispatchToProps)(SnapshotContainer);
