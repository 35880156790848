import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { FormControl } from 'react-bootstrap';
import cn from 'classnames';
import { units } from 'smartbim-validation';

import FormulaContainer from './FormulaContainer';
import { TextField } from './Text';

const Unit = props => (
  <Field {...props} component={FormulaContainer}>
    <UnitField />
  </Field>
);

class UnitField extends React.Component {
  static propTypes = {
    bsClass: PropTypes.object,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
    onChange: PropTypes.func,
    disabled: PropTypes.bool
  };

  state = {};

  componentDidMount() {
    this.getOptions();
  }

  onChangeNumber = (e, index) => {
    const { unit, value } = this.state;
    const newValue = [].concat(value);

    if ((unit === 'ft_in_frac' && index !== 0) || unit === 'in_frac') {
      const inchValue = e.target.value;
      this.setState({ inchValue });
      let match = inchValue.match(/^\s*(?:(\d+)\s+)?(\d+)\s*\/\s*(\d+)\s*$/);
      if (!match) match = inchValue.match(/^\s*(\d+)?\s*$/);
      if (!match) {
        this.setState({ badInput: true });
        return;
      }
      if (match[1] && !match[2]) {
        newValue[0 + index] = match[1];
        newValue[1 + index] = 0;
        newValue[2 + index] = 2;
      } else {
        newValue[0 + index] = match[1] || 0;
        newValue[1 + index] = match[2];
        newValue[2 + index] = match[3];
      }
    } else newValue[index] = e.target.value;

    this.setState({ value: newValue, badInput: false });

    this.updateData(newValue, unit);
  };

  onChangeUnit = e => {
    const { value, unit, wrong, options } = this.state;
    const newUnit = e.target.value;
    if (!wrong) this.fixValue(value, unit, newUnit);
    else {
      this.setState({
        unit: newUnit,
        wrong: false,
        display: options.find(item => item.key === newUnit).name
      });

      this.updateData(value, newUnit);
    }
  };

  updateData = (value, unit) => {
    const {
      input: { onChange, value: valueObject }
    } = this.props;
    onChange({ ...valueObject, unit, value: scalarValue(value) });
  };

  conversionLessUnits = ['general', 'fixed', 'percentage', 'currency'];

  getOptions = () => {
    const {
      input: { value: valueObject },
      distribution
    } = this.props;
    let { value } = valueObject || {};
    const { unit: savedUnit } = valueObject;
    let unit = savedUnit;
    let unitList;
    let systemCheck;
    if (typeof value === 'string' && value.match(/(?:\d+,)+/)) value = JSON.parse(`[${value}]`);
    if (distribution) {
      const { internalStorage, systemUnit } = distribution;
      if (!unit) unit = internalStorage;
      try {
        unitList = units.get.units(systemUnit);
        systemCheck = systemUnit;
      } catch (e) {
        console.error(`Failed to get units from ${systemUnit}:\n${e}`);
        return;
      }
    }
    if (!unit) {
      const { internalStorage } = this.props;
      unit = internalStorage;
    }
    if (!unit) return;
    if (this.conversionLessUnits.includes(unit)) return;
    const bases = units.get.base();
    for (let i = 0; i < bases.length && !unitList; i += 1) {
      const unitCheck = units.get.units(bases[i]);
      if (unitCheck.includes(unit)) unitList = unitCheck;
    }
    if (!unitList || unitList.length < 1) return;
    const options = [];
    const prettyList = unitList.toPretty();
    for (let i = 0; i < unitList.length; i += 1) {
      options.push({ key: unitList[i], name: prettyList[i] });
    }
    if (!options.find(item => item.key === unit)) {
      console.error(`Failed to find ${unit} in the list.`);
      options.unshift({ key: unit, name: unit });
    }
    if (options.length > 1) {
      const otherUnit = options.find(item => item.key !== unit);
      try {
        let unitObject;
        if (otherUnit.key === 'ratio') {
          if (unit !== 'ratio10') unitObject = units.unit(0, 'ratio10').to(unit);
          else unitObject = units.unit(0, 'ratio12').to(unit);
        } else unitObject = units.unit(0, otherUnit.key).to(unit);
        this.setState({
          display: unitObject.display,
          value: arrayValue(value),
          inchValue: inchValue(value, unit),
          options,
          unit
        });
      } catch (e) {
        console.error(
          `Failed to get unit info by converting ${otherUnit.key} to ${unit}` +
            `${systemCheck ? ` based on ${systemCheck}` : ''}:\n${e}`
        );
        if (options[0].key === unit) options.shift();
        const optionIndex = options[0].key === 'ratio' ? 1 : 0;
        this.setState({
          display: options[optionIndex].name,
          options,
          unit: options[optionIndex].key,
          value: arrayValue(value),
          inchValue: inchValue(value, options[optionIndex].key),
          wrong: true
        });
      }
    } else {
      this.setState({
        display: arrayValue(options[0].name),
        value: arrayValue(value),
        inchValue: inchValue(value, unit),
        options,
        unit
      });
    }
  };

  fixValue = (value, oldUnit, newUnit) => {
    if (oldUnit === newUnit) return;
    const checkValue = scalarValue(value.map(item => item || 0));
    const unitObject = units.unit(checkValue || 0, oldUnit).to(newUnit);
    const newValue = arrayValue(unitObject.value);
    this.setState({
      value: newValue,
      unit: newUnit,
      display: unitObject.display,
      inchValue: inchValue(newValue, newUnit)
    });

    const {
      input: { onChange, value: valueObject }
    } = this.props;
    onChange({ ...valueObject, value: scalarValue(newValue), unit: newUnit });
  };

  render() {
    const { bsClass: nextBsClass, disabled } = this.props;
    const { display, options, unit, value, inchValue, badInput } = this.state;
    const bsClass = cn('form-control', nextBsClass);
    if (!unit) {
      return <TextField {...this.props} />;
    }
    let textInput;
    if (unit === 'ft_in_frac' || unit === 'in_frac') {
      textInput = [];
      let delta = 0;
      if (unit === 'ft_in_frac') {
        textInput.push({
          value: roundValue(value[0]),
          name: 'ft',
          onChange: e => this.onChangeNumber(e, 0),
          unitDisplay: 'ft',
          type: 'number'
        });
        delta = 1;
      }
      textInput.push({
        value: inchValue,
        name: 'in',
        onChange: e => this.onChangeNumber(e, delta),
        unitDisplay: 'in',
        type: 'text'
      });
    } else {
      textInput = value.map((item, index) => ({
        value: roundValue(item),
        name: index,
        onChange: e => this.onChangeNumber(e, index),
        unitDisplay: display && Array.isArray(display) && display.length > 1 && display[index],
        type: 'number'
      }));
    }
    const listInput = { value: unit, onChange: this.onChangeUnit };
    return (
      <React.Fragment>
        {textInput.map(({ unitDisplay, ...item }) => (
          <div key={item.name} className="product-details__input-unit">
            <FormControl {...item} {...{ bsClass, disabled }} className="appended-unit" />
            {unitDisplay && <span className="value-unit">{unitDisplay}</span>}
          </div>
        ))}
        <FormControl
          {...listInput}
          componentClass="select"
          disabled={badInput || disabled}
          {...{ bsClass }}
        >
          {options.map(({ key, name: oName }) => (
            <option key={`${key}${oName}`} value={key}>
              {oName || key}
            </option>
          ))}
        </FormControl>
      </React.Fragment>
    );
  }
}

const arrayValue = value => (Array.isArray(value) ? value : [value]);

const scalarValue = value => (Array.isArray(value) && value.length === 1 ? value[0] : value);

const inchValue = (value, unit) => {
  let inch;
  if (unit === 'ft_in_frac' || unit === 'in_frac') {
    const delta = unit === 'ft_in_frac' ? 1 : 0;
    const integer = value[0 + delta] || 0;
    const fraction = value[1 + delta] && `${value[1 + delta]}/${value[2 + delta]}`;
    if (integer && fraction) inch = `${integer} ${fraction}`;
    else if (fraction) inch = fraction;
    else if (integer) inch = integer;
    else inch = '0';
  }
  return inch;
};

const digits = 1000;
const roundValue = value => Math.round(parseFloat(value) * digits) / digits;

export default Unit;
export { UnitField, arrayValue, scalarValue, inchValue, roundValue };
