import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import styled from 'styled-components';

import sbManagerStyles from 'utils/globalStyles';
import ModalCloseIcon from './ModalCloseIcon';

const defaultStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    maxWidth: '90%',
    maxHeight: '95%',
    transform: 'translate(-50%, -50%)'
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
    zIndex: '9999'
  }
};

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: -2rem -2rem 0;
  padding: 2rem;
  border-bottom: 1px solid ${() => sbManagerStyles.greyLight};
`;

const Title = styled.h3`
  padding-right: 3rem;
`;

global.document && ReactModal.setAppElement(document.getElementById('root'));

const Modal = ({
  children,
  open,
  onOpen,
  onClose,
  title,
  disableCloseOutside,
  customStyles,
  closeTimeoutMS,
  disableHeader,
  ...props
}) => {
  let styles = { ...defaultStyles };

  if (customStyles) {
    const { content: defautl } = defaultStyles;
    styles = {
      ...styles,
      content: {
        ...defautl,
        ...customStyles
      }
    };
  }
  return (
    <ReactModal
      isOpen={open}
      onAfterOpen={onOpen}
      onRequestClose={disableCloseOutside ? undefined : onClose}
      style={styles}
      contentLabel={title}
      closeTimeoutMS={closeTimeoutMS || 500}
      {...props}
    >
      {!disableHeader && (
        <Header>
          {title && <Title>{title}</Title>} <ModalCloseIcon onClick={onClose} height={16} />
        </Header>
      )}
      {children}
    </ReactModal>
  );
};

Modal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element])
    .isRequired,
  onClose: PropTypes.func.isRequired,
  onOpen: PropTypes.func,
  disableCloseOutside: PropTypes.bool,
  open: PropTypes.bool,
  title: PropTypes.string,
  customStyles: PropTypes.object
};

Modal.defaultProps = {
  open: false,
  title: ''
};

export default Modal;
