/* vendor imports */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

/* system imports */
import config from '../utils';
import ChartIndex from '../components/ChartIndex';

class ChartContainer extends PureComponent {
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { namespace } = this.props;
    if (this[namespace]) {
      const chart = this[namespace].getChart();

      if (nextProps.data) {
        const { data } = nextProps;

        chart.series[0].setData(data);
        chart.hideLoading();
        chart.reflow();
      } else chart.showLoading();
    }
  }

  render() {
    const { data } = this.props;
    const chartConfig = config;
    chartConfig.data = data;

    return <ChartIndex {...this.props} {...this.state} config={chartConfig} chartState={this} />;
  }
}

export default ChartContainer;
