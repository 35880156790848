import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from './../SvgIcon';

const CheckboxPartialIcon = (props) => (
  <SvgIcon {...props}>
		<path d='M 0 4C 0 1.79086 1.79086 0 4 0L 14 0C 16.2091 0 18 1.79086 18 4L 18 14C 18 16.2091 16.2091 18 14 18L 4 18C 1.79086 18 0 16.2091 0 14L 0 4Z'/>
    <path d='M 0 0L 8 0L 8 2L 0 2L 0 0Z' transform='translate(5 8)' fill='#FFFFFF'/>
  </SvgIcon>
)

CheckboxPartialIcon.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  viewBox: PropTypes.string.isRequired,
};

CheckboxPartialIcon.defaultProps = {
  width: 24,
  height: 24,
  viewBox: '0 0 24 24',
};

export default CheckboxPartialIcon;

