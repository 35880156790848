import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../SvgIcon';

const ProjectsIcon = props => (
  <SvgIcon {...props}>
    <path className="color--light" d="M23.2188 17.6818L20.2124 17.6818V19.2819C20.2124 19.8819 19.8124 20.2819 19.2124 20.2819H5.20659C4.60659 20.2819 4.20659 19.8819 4.20659 19.2819V17.6818L1.21875 17.6818C0.61875 17.6818 0.21875 18.1818 0.21875 18.6818V21.4818C0.21875 22.0818 0.71875 22.4818 1.21875 22.4818H23.2188C23.8188 22.4818 24.2188 21.9818 24.2188 21.4818V18.6818C24.2188 18.1818 23.7188 17.6818 23.2188 17.6818Z" />
    <g className="icon--inactive">
      <path className="color--medium" d="M19.2188 6.31699H17.2188V3.31699C17.2188 2.93899 17.0048 2.59199 16.6658 2.42299L12.6657 0.422993C12.3847 0.282993 12.0528 0.282993 11.7708 0.422993L7.77075 2.42299C7.43175 2.59199 7.21875 2.93799 7.21875 3.31699V6.31699H5.21875C4.66675 6.31699 4.21875 6.76599 4.21875 7.31699V19.317C4.21875 19.87 4.66675 20.317 5.21875 20.317H19.2188C19.7707 20.317 20.2188 19.87 20.2188 19.317V7.31699C20.2188 6.76499 19.7707 6.31699 19.2188 6.31699ZM18.2188 18.317H6.21875V8.31699H8.21875C8.77075 8.31699 9.21875 7.86999 9.21875 7.31699V3.93699L12.2188 2.43699L15.2188 3.93699V7.31599C15.2188 7.86899 15.6668 8.31599 16.2188 8.31599H18.2188V18.317Z" />
      <path className="color--medium" d="M14.2188 4.31699H10.2188V6.31699H14.2188V4.31699Z" />
      <path className="color--medium" d="M11.2188 9.31699H8.21875V11.317H11.2188V9.31699Z" />
      <path className="color--medium" d="M16.2188 9.31699H13.2188V11.317H16.2188V9.31699Z" />
      <path className="color--medium" d="M11.2188 12.317H8.21875V14.317H11.2188V12.317Z" />
      <path className="color--medium" d="M16.2188 12.317H13.2188V14.317H16.2188V12.317Z" />
      <path className="color--medium" d="M11.2188 15.317H8.21875V17.317H11.2188V15.317Z" />
      <path className="color--medium" d="M16.2188 15.317H13.2188V17.317H16.2188V15.317Z" />
    </g>
    <g className="icon--active">
      <rect className="color--medium" x="8.78247" y="4.03574" width="6.4375" height="2.5625" />
      <rect className="color--medium" x="5.56641" y="12.0357" width="12.8696" height="2.5625" />
      <path className="color--dark" d="M19.0002 6.31699H17.0002V3.88299C17.0002 3.53099 16.8152 3.20599 16.5152 3.02599L12.5152 0.62599C12.1982 0.43599 11.8032 0.43599 11.4862 0.62599L7.48624 3.02599C7.18524 3.20699 7.00024 3.53199 7.00024 3.88299V6.31699H5.00024C4.44824 6.31699 4.00024 6.76499 4.00024 7.31699V19.317C4.00024 19.87 4.44824 20.317 5.00024 20.317H19.0002C19.5522 20.317 20.0002 19.87 20.0002 19.317V7.31699C20.0002 6.76499 19.5522 6.31699 19.0002 6.31699ZM9.00024 4.31699H15.0002V6.31699H9.00024V4.31699ZM11.0002 18.317H6.00024V16.317H11.0002V18.317ZM11.0002 14.317H6.00024V12.317H11.0002V14.317ZM11.0002 10.317H6.00024V8.31699H11.0002V10.317ZM18.0002 18.317H13.0002V16.317H18.0002V18.317ZM18.0002 14.317H13.0002V12.317H18.0002V14.317ZM18.0002 10.317H13.0002V8.31699H18.0002V10.317Z" />
    </g>
  </SvgIcon>
);

ProjectsIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
};

ProjectsIcon.defaultProps = {
  width: 22,
  height: 22
};

export default ProjectsIcon;
