import React from 'react';

import RadioGroup from 'shared/InputGroups/RadioGroup';

const ListControls = ({ listMode, setListMode, optionKeys, optionIcons }) => (
  <RadioGroup
    controlFunc={e => setListMode(e)}
    namespace="UsersViewSelector"
    options={optionKeys}
    selectedOption={listMode}
    optionIcons={optionIcons}
    button
  />
);

export default ListControls;
