import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../SvgIcon';

const ListViewIcon = props => (
  <SvgIcon {...props}>
    <path d='M5,2A3,3,0,0,0,2,5,3,3,0,0,0,5,8,3,3,0,0,0,8,5,3,3,0,0,0,5,2ZM5,6A0.94,0.94,0,0,1,4,5,0.94,0.94,0,0,1,5,4,0.94,0.94,0,0,1,6,5,0.94,0.94,0,0,1,5,6Z' />
    <path d='M5,9a3,3,0,0,0,0,6A3,3,0,0,0,5,9Zm0,4a1,1,0,0,1,0-2A1,1,0,0,1,5,13Z' />
    <path d='M5,16a3,3,0,0,0,0,6A3,3,0,0,0,5,16Zm0,4a1,1,0,0,1,0-2A1,1,0,0,1,5,20Z' />
    <rect height='2' width='12' x='10' y='4' />
    <rect height='2' width='12' x='10' y='11' />
    <rect height='2' width='12' x='10' y='18' />
  </SvgIcon>
);

ListViewIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  viewBox: PropTypes.string,
};

ListViewIcon.defaultProps = {
  width: 24,
  height: 24,
  viewBox: '0 0 24 24',
};

export default ListViewIcon;
