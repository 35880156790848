const analyzers = {
  projectPhases: 'projectPhases',
  projectsUpdated: 'projectsUpdated',
  averageBudget: 'averageBudget',
  averageSize: 'averageSize',
  highValueCustomers: 'highValueCustomers',
  topProjectTypes: 'topProjectTypes'
};

export { analyzers };
