import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Field } from 'redux-form';
import { reducerUtil } from 'base-client';

import { actions as detailsActions, reducerData as detailsData } from 'productDetails';
import CertificateCard from './CertificateCard';

const StyledWrapper = styled.div`
  padding-right: 1.5rem;
`;

const Certificates = ({ assets, data, handleOnChange, dispatch, certificates }) => {
  const onDelete = attributeId => dispatch(detailsActions.deleteCertificate({ attributeId }));

  return (
    <StyledWrapper>
      {data
        .sort((a, b) => {
          const { name: aName } = certificates.find(item => item.id === a) || {};
          const { name: bName } = certificates.find(item => item.id === b) || {};
          return aName.toLowerCase().localeCompare(bName.toLowerCase());
        })
        .map(id => {
          const { name, logo } = certificates.find(item => item.id === id) || {};
          const formProps = {
            name: id,
            labelText: name,
            logo,
            assets,
            onDelete: () => onDelete(id),
            handleOnChange
          };

          return <Field key={id} {...formProps} component={CertificateCard} />;
        })}
    </StyledWrapper>
  );
};

Certificates.defaultProps = {
  data: [],
  certificates: []
};

const mapStateToProps = state => ({
  productId: reducerUtil.getSlice(detailsData, detailsData.productId, state),
  assets: reducerUtil.getSlice(detailsData, detailsData.assetList, state)
});

export default connect(mapStateToProps)(Certificates);
