import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../SvgIcon';

const Attributes = (props) => (
  <SvgIcon {...props} >
    <path id="shadow" className="color--light" d="M1 18C0.4 18 0 18.4167 0 19.0417V21.9583C0 22.5833 0.4 23 1 23H23C23.6 23 24 22.5833 24 21.9583V19.0417C24 18.4167 23.6 18 23 18H14V19.0417L13 20H3L2 19.0417V18H1Z"/>
    <g className="icon--active">
      <path className='color--medium' d="M4 7H12V9H4V7Z"/>
      <path className='color--medium' d="M4 11H12V13H4V11Z"/>
      <path className='color--medium' d="M4 15H9V17H4V15Z"/>
      <path className='color--dark' d="M17.5 17.053L20 20.803L22.5 17.053V7H17.5V17.053Z"/>
      <path className='color--medium' d="M20 2C18.6084 2 17.5 3.18694 17.5 4.34347V6H22.5V4.34347C22.5 3.18694 21.3916 2 20 2Z"/>
      <path className='color--dark' d="M13 3H3C1.897 3 1 3.898 1 5V19C1 20.103 1.897 21 3 21H13C14.103 21 15 20.103 15 19V5C15 3.898 14.103 3 13 3ZM9 17H4V15H9V17ZM12 13H4V11H12V13ZM12 9H4V7H12V9Z"/>
    </g>
    <g className="icon--inactive">
        <path className='color--medium' d="M20 2C18.346 2 17 3.346 17 5V16.303L20 20.803L23 16.303V5C23 3.346 21.654 2 20 2ZM21 15.698L20 17.198L19 15.698V8H21V15.698ZM19 6V5C19 4.448 19.449 4 20 4C20.551 4 21 4.448 21 5V6H19Z"/>
        <path className='color--medium' d="M13 3H3C1.897 3 1 3.898 1 5V19C1 20.103 1.897 21 3 21H13C14.103 21 15 20.103 15 19V5C15 3.898 14.103 3 13 3ZM3 19V5H13L13.001 19H3Z"/>
        <path className='color--medium' d="M11 7H5V9H11V7Z"/>
        <path className='color--medium' d="M11 11H5V13H11V11Z"/>
        <path className='color--medium' d="M9 15H5V17H9V15Z"/>
    </g>
  </SvgIcon>
)

Attributes.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

Attributes.defaultProps = {
  width: 22,
  height: 22
};


export default Attributes;
