/* vendor imports */
import { connect } from 'react-redux';
import { reduxForm, formValueSelector, change } from 'redux-form';

/* system imports */
import Container from './container';
import { form, distForm } from 'productAttributes/utils';

const selector = formValueSelector(form);

const mapStateToProps = state => ({
  selectedAttributeType: selector(state, distForm.type),
  revitOption: selector(state, distForm.revit.option),
  discipline: selector(state, distForm.revit.discipline),
  revitType: selector(state, distForm.revit.revitAttribute)
});

const mapDispatchToProps = dispatch => ({
  changeType: newValue => dispatch(change(form, distForm.revit.revitAttribute, newValue))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form,
    enableReinitialize: true
  })(Container)
);
