export default {
  name: 'productAttributes',
  attributeTypes: 'attributeTypes',
  distributionTypes: 'distributionTypes',
  attributes: 'attributes',
  attributeData: 'attributeData',
  products: 'products',
  sharedParametersData: 'sharedParametersData',
  certificates: 'certificates',
  ratingSystems: 'ratingSystems',
  characteristics: 'characteristics'
};
