const sbManagerStyles = {
  white: '#fff',
  black: '#000',
  blueDark: '#1385f0',
  blue: '#44bff5',
  green: '#6abf43',
  red: '#d95a5a',
  orange: '#f59a38',
  teal: '#12ceb8',
  greyDarkest01: '#3a4858',
  greyDarkest: '#343e47',
  greyDark: '#5a6678',
  greyMedium: '#77899e',
  greyLight: '#c3cdd9',
  greyLightest: '#f0f3f6',
  accentGrey: '#dee5ed'
};

const graphColors = [
  '#6ABF43',
  '#1385F0',
  '#055DAF',
  '#14395B',
  '#058E8E',
  '#95C8F8',
  '#B9E9A3',
  '#343E47',
  '#0ED3BD',
  '#77899E'
];

const TABLE_HEADER_HEIGHT = 55;
const TABLE_ROW_HEIGHT = 55;

export default sbManagerStyles;
export { graphColors, TABLE_HEADER_HEIGHT, TABLE_ROW_HEIGHT };
