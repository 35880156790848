import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { FormControl } from 'react-bootstrap';
import cn from 'classnames';

import FormulaContainer from './FormulaContainer';

const TextArea = props => (
  <Field {...props} component={FormulaContainer}>
    <TextAreaField />
  </Field>
);

const TextAreaField = ({ bsClass, value, onChange, disabled }) => (
  <div className="product-details__input-unit">
    <FormControl
      {...{ onChange, disabled }}
      value={value || ''}
      componentClass="textarea"
      bsClass={cn('form-control', bsClass)}
    />
  </div>
);

TextAreaField.propTypes = {
  bsClass: PropTypes.object,
  value: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool
};

export default TextArea;
