import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../SvgIcon';

const LockIcon = (props) => (
  <SvgIcon {...props}>
		  <path d="M17,11V7c0-2.757-2.244-5-5-5C9.242,2,7,4.243,7,7v4c-1.103,0-2,0.896-2,2v7c0,1.103,0.897,2,2,2h10
    c1.103,0,2-0.897,2-2v-7C19,11.896,18.103,11,17,11z M12,18c-0.828,0-1.5-0.672-1.5-1.5S11.172,15,12,15s1.5,0.672,1.5,1.5
    S12.828,18,12,18z M15,11H9V7c0-1.654,1.346-3,3-3s3,1.346,3,3V11z"/>
  </SvgIcon>
)

LockIcon.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  viewBox: PropTypes.string.isRequired,
};

LockIcon.defaultProps = {
  width: 24,
  height: 24,
  viewBox: '0 0 24 24',
};

export default LockIcon;
