import moment from 'moment';

const leadStream = ['all', 'views', 'downloads'];

const defaultInterval = {
  startDate: moment()
    .subtract(365, 'days')
    .startOf('day'),
  endDate: moment().endOf('day')
};

const streamConvert = {
  views: 'pageview',
  productviews: 'product',
  downloads: 'download'
};

export default { leadStream, defaultInterval, streamConvert };
