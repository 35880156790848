import React from 'react';
import styled from 'styled-components';

import { LinkButton } from 'shared/Buttons';
import sbManagerStyles from 'utils/globalStyles';

const StyledLinkButton = styled(LinkButton)`
  color: ${() => sbManagerStyles.blueDark}!important;
  height: auto;
`;

const Styled = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  header {
    margin-top: 20px;
    font-size: 2.2rem;
    font-weight: 500;
  }
  label {
    display: block;
    font-size: 1.4rem;
    margin-top: 30px;
    font-weight: 500;
  }
  .column {
    display: flex;
    flex-direction: column;
    flex: 1 0 25%;
  }

  .first-column {
    display: flex;
    flex-direction: column;
    flex: 1 0 50%;
  }
`;

class RequestQuoteMessage extends React.Component {
  render() {
    const { data } = this.props;
    let nullMsg = 'User did not provide any details';

    return (
      <Styled>
        <div className="first-column">
          <header>Request A Quote</header>
          <label>Product Viewed</label>
          {!data.productId ? (
            data.productName
          ) : (
            <StyledLinkButton className="link-button" to={'/products/' + data.productId}>
              {data.productName}
            </StyledLinkButton>
          )}
          <label>Message Details</label>
          {data.message && <p>{data.message}</p> || <p className="grey-light">{nullMsg}</p>}
        </div>
        <div className="column">
          <label>Project Name</label>
          <div>{data.projectName}</div>
          <label>Project Value</label>
          {data.projectValue && <div>{data.projectValue}</div> || <div className="grey-light">{nullMsg}</div>}
          <label>Project Segment</label>
          {data.projectSegment && <div>{data.projectSegment}</div> || <div className="grey-light">{nullMsg}</div>}
        </div>
        <div className="column">
          <label>Project Location</label>
          <div>{data.projectLocation}</div>
          <label>Project Stage</label>
          {data.projectStage && <div>{data.projectStage}</div> || <div className="grey-light">{nullMsg}</div>}
          <label>Product Count Needed</label>
          {data.productCountNeeded && <div>{data.productCountNeeded}</div> || <div className="grey-light">{nullMsg}</div>}
        </div>
      </Styled>
    );
  }
}

export default RequestQuoteMessage;
