import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { actions as tenantActions } from 'tenant';

class Tenant extends React.Component {
  state = {};

  async componentDidMount() {
    const { dispatch } = this.props;
    await dispatch(tenantActions.checkTenant());
    this.setState({ started: true });
  }

  render() {
    const { started } = this.state;
    if (!started) return null;

    return <React.Fragment>{this.props.children}</React.Fragment>;
  }
}

export default withRouter(connect()(Tenant));
