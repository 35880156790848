import React from 'react';
import PropTypes from 'prop-types';

import Button from 'shared/Buttons';
import { UpdateIcon, ImportIcon } from 'shared/Miscellaneous/Icons';
import detailsUtils from 'productDetails/utils';

const Buttons = ({ status, revitFile, onUpdate, onImport, disabled }) => {
  if (!revitFile.id)
    return (
      <div className="status-message">
        <p className="state--error">No source file attached</p>
        <p>
          There is no source file attached. To attach a source file, drop a file in the
          corresponding fields to the right.
        </p>
      </div>
    );
  let statusMessage = 'There is a Revit file associated to product data.';
  if (
    new Date(status[detailsUtils.statusNames.updated]) >
    new Date(revitFile[detailsUtils.statusNames.updated])
  ) {
    statusMessage =
      'There is a Revit file associated to product data.' +
      ' If the file(s) linked do not match product data,' +
      ' choose an option below to resolve the issue.';
  }

  return (
    <span>
      <div className="status-message">
        <p>{statusMessage}</p>
      </div>

      <div>
        <div className="mb-1">
          <Button
            onClick={onUpdate}
            buttonStyle="blue"
            icon={<UpdateIcon width={16} height={16} />}
            {...{ disabled }}
          >
            Update file with data
          </Button>
        </div>
        <Button
          onClick={onImport}
          buttonStyle="blue"
          icon={<ImportIcon width={16} height={16} />}
          {...{ disabled }}
        >
          Import data from file
        </Button>
      </div>
    </span>
  );
};

Buttons.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  onImport: PropTypes.func.isRequired,
  revitFile: PropTypes.shape({
    id: PropTypes.string,
    [detailsUtils.statusNames.updated]: PropTypes.string
  }),
  status: PropTypes.shape({
    [detailsUtils.statusNames.updated]: PropTypes.string
  }),
  disabled: PropTypes.bool
};

Buttons.defaultProps = {
  revitFile: {},
  status: {}
};

export default Buttons;
