import React from 'react';
import {
  ProjectCreatedIcon,
  ProjectUpdatedIcon,
  ProductsAddedIcon,
  ProductsRemovedIcon,
  ProjectDeletedIcon,
  SubmittalGeneratedIcon
} from 'shared/Miscellaneous/Icons';

const formatAddress = (addressObject, style) => {
  if (!addressObject) return null;

  const { address_line_1, address_line_2, city, state, zipcode, country } = addressObject;
  if (style === 'header') {
    return (
      <div>
        {address_line_1
          ? `${address_line_1}${address_line_2 ? ` ${address_line_2}` : ''}${
              city ? `, ${city}` : ''
            }${state ? `, ${state}` : ''}${zipcode ? ` ${zipcode}` : ''}`
          : zipcode}
      </div>
    );
  }
};

const isEmpty = object => {
  for (let item in object) {
    if (object[item]) {
      return false;
    }
  }
  return true;
};

const HISTORY_TAGS = [
  { type: 0, text: 'Project created', icon: ProjectCreatedIcon },
  { type: 1, text: 'Project updated', icon: ProjectUpdatedIcon },
  { type: 2, text: 'Products added', icon: ProductsAddedIcon },
  { type: 3, text: 'Products removed', icon: ProductsRemovedIcon },
  { type: 4, text: 'Project deleted', icon: ProjectDeletedIcon },
  { type: 5, text: 'Submittal created', icon: SubmittalGeneratedIcon }
];

export { formatAddress, isEmpty, HISTORY_TAGS };
