import React from 'react';
import styled from 'styled-components';
import { SolidConcoraLogo } from 'shared/Miscellaneous/Icons';

const StatusContainer = styled.div`
  padding: 0.35rem 0.5rem 0.35rem 0.5rem;
  display: flex;
  flex-direction: row;
  border-radius: 100px;
  margin-top: auto;
  margin-bottom: auto;
`;

const Lead = styled(StatusContainer)`
  background-color: #0a7be420;
  color: #096ecc;
`;

const StyledIcon = styled(SolidConcoraLogo)`
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 1rem;
  margin-right: 0.6rem;
`;

const StageText = styled.div`
  font-size: 1.6rem;
  font-weight: 600;
  margin-right: 1rem;
  margin-top: auto;
  margin-bottom: auto;
`;

const Contact = styled(StatusContainer)`
  background-color: #c3cdd950;
  color: #77899e;
`;

const Opportunity = styled(StatusContainer)`
  background-color: #6abf4320;
  color: #43971c;
`;

const Visitor = styled(StatusContainer)`
  background-color: #ffefeb;
  color: #991f00;
`;

const Prospect = styled(StatusContainer)`
  background-color: #0a7be420;
  color: #096ecc;
`;

const ScoreContainer = styled.div`
  height: 2rem;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
`;

const LeadScore = ({ dispatch, stage }) => {
  const getScore = () => {
    switch (stage) {
      case 'Visitor':
        return (
          <Visitor>
            <StyledIcon color={'#991f00'} width={16} height={15} viewBox={'0 0 16 15'} />
            <StageText>{'Visitor'}</StageText>
          </Visitor>
        );
      case 'Opportunity':
        return (
          <Opportunity>
            <StyledIcon color={'#43971c'} width={16} height={15} viewBox={'0 0 16 15'} />
            <StageText>{stage}</StageText>
          </Opportunity>
        );
      case 'Lead':
        return (
          <Lead>
            <StyledIcon color={'#096ECC'} width={16} height={15} viewBox={'0 0 16 15'} />
            <StageText>{stage}</StageText>
          </Lead>
        );
      case 'Prospect':
        return (
          <Prospect>
            <StyledIcon color={'#096ECC'} width={16} height={15} viewBox={'0 0 16 15'} />
            <StageText>{stage}</StageText>
          </Prospect>
        );
      case 'Contact':
      default:
        return (
          <Contact>
            <StyledIcon color={'#77899e'} width={16} height={15} viewBox={'0 0 16 15'} />
            <StageText>{stage}</StageText>
          </Contact>
        );
    }
  };
  return <ScoreContainer>{getScore()}</ScoreContainer>;
};

export default LeadScore;
