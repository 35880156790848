// ------------------------------------
// Constants
// ------------------------------------
const MODAL_OPEN = 'MODAL_OPEN';
const SET_MODAL_CONFIRM_DISPLAY = 'SET_MODAL_CONFIRM_DISPLAY';

export const modalConfirmName = 'confirmationModal';

// ------------------------------------
// Dispatches
// ------------------------------------
const modalOpen = (modalName, bOpen) => ({
  type: MODAL_OPEN,
  modal: { [modalName]: bOpen }
});

const setModalConfirmDisplay = display => ({
  type: SET_MODAL_CONFIRM_DISPLAY,
  display
});

// ------------------------------------
// Actions
// ------------------------------------
export const openModal = (modalName, bOpen = true) => dispatch => {
  dispatch(modalOpen(modalName, bOpen));
  if (modalName === modalConfirmName && !bOpen) {
    dispatch(modalConfirmDisplay(null));
  }
};

export const modalConfirmDisplay = display => dispatch => dispatch(setModalConfirmDisplay(display));

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [MODAL_OPEN]: (state, action) => ({ ...state, ...action.modal }),
  [SET_MODAL_CONFIRM_DISPLAY]: (state, action) => ({ ...state, confirmDisplay: action.display })
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {};
export default function sessionReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
