import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { reducerUtil } from 'base-client';

import { Spinner } from 'shared-features-client';
import sbManagerStyles from 'utils/globalStyles';
import { reducerData as detailsData } from 'projectDetails';
import { reducerData as leadsData } from 'leads';

const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 1.5rem;
`;

const StyledProfile = styled(Link)`
  display: flex;
  width: 100%;
`;

const UserImage = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1.5rem;
  img {
    width: 3.5rem;
    margin: auto;
    border-radius: 100%;
  }
`;

const UserInformation = styled.div`
  display: flex;
  flex-direction: column;
`;

const Name = styled.h4`
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.3;
  color: ${() => sbManagerStyles.greyDarkest};
`;

const ViewProfileBtn = styled(Link)`
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  color: ${() => sbManagerStyles.blueDark};
  font-weight: 400;
`;

const Occupation = styled.div`
  font-size: 1.4rem;
  line-height: 1.9rem;
  color: ${() => sbManagerStyles.greyMedium};
`;

const Company = styled.div`
  font-size: 1.2rem;
  line-height: 1.7rem;
  color: ${() => sbManagerStyles.greyMedium};
`;

const ProjectUserProfile = ({ id, picture, occupation, name, company }) => {
  if (id) {
    return (
      <StyledWrapper>
        <StyledProfile to={`/insights/contacts/${id}`}>
          {picture && (
            <UserImage>
              <img src={picture} alt="" />
            </UserImage>
          )}
          <UserInformation>
            <Name>{name || 'Anonymous User'} </Name>
            {id && <ViewProfileBtn to={`/insights/contacts/${id}`}>View Profile</ViewProfileBtn>}
            {occupation && <Occupation>{occupation}</Occupation>}
            {company && <Company>{company}</Company>}
          </UserInformation>
        </StyledProfile>
      </StyledWrapper>
    );
  } else {
    return <></>;
  }
};
ProjectUserProfile.propTypes = {
  userId: PropTypes.string,
  picture: PropTypes.string,
  username: PropTypes.string,
  occupation: PropTypes.string,
  companyName: PropTypes.string
};
const mapStateToProps = state => {
  const { user_id: userId } =
    reducerUtil.getSlice(detailsData, detailsData.generalData, state) || {};
  const { user } = reducerUtil.getSlice(leadsData, leadsData.leadProfile, state) || {};

  if (!user || user.id !== userId) return {};
  return user;
};

export default connect(mapStateToProps)(ProjectUserProfile);
