import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../SvgIcon';

const ShowIcon = (props) => (
  <SvgIcon {...props}>
		<path d="M12,14a2,2,0,1,0-2-2A2,2,0,0,0,12,14Z"/>
		<path d="M12,5C5.648,5,1,12,1,12s4.648,7,11,7,11-7,11-7S18.352,5,12,5Zm0,11a3.99954,3.99954,0,1,1,.00092,0H12Z"/>
  </SvgIcon>
)

ShowIcon.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  viewBox: PropTypes.string.isRequired,
};

ShowIcon.defaultProps = {
  width: 24,
  height: 24,
  viewBox: '0 0 24 24',
};

export default ShowIcon;
