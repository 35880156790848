import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from 'shared/Buttons';
import Card from './Card';
import Header from './Header';
import PasswordInput from './PasswordInput';

const Login = ({ setUser, setPass, onClickLogin, onClickForgot, invalid, user, pass, message }) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Card title={'Log In'} message={message}>
      <Header>Email Address</Header>
      <input
        className="input--text"
        id="user_email"
        type="email"
        placeholder="Enter your email address"
        value={user || ''}
        onChange={e => setUser(e)}
      />
      {invalid.user && <p>Please enter an email address</p>}
      <Header>Password</Header>
      <PasswordInput
        className="input--text"
        id="user_password"
        type="password"
        placeholder="Enter your password"
        value={pass || ''}
        onChange={e => setPass(e)}
        showPassword={showPassword}
        setShowPassword={setShowPassword}
      />
      {invalid.pass && <p>Please enter a password</p>}
      <Button id="user_login" buttonStyle="create" onClick={onClickLogin}>
        Login
      </Button>
      <p className="reset-pswd">
        <a href="#" onClick={onClickForgot}>
          Forgot password?
        </a>
      </p>
    </Card>
  );
};

Login.propTypes = {
  setUser: PropTypes.func.isRequired,
  setPass: PropTypes.func.isRequired,
  onClickLogin: PropTypes.func.isRequired,
  onClickForgot: PropTypes.func.isRequired,
  invalid: PropTypes.object.isRequired,
  user: PropTypes.string,
  pass: PropTypes.string,
  message: PropTypes.object
};

export default Login;
