import React from 'react';
import PropTypes from 'prop-types';

import Button from 'shared/Buttons';
import { sharedStatus } from 'productAttributes/utils';

import { CheckIcon, LockIcon, CloseCircleIcon } from 'shared/Miscellaneous/Icons';

const Status = ({ status, onHide, hideStatus }) => {
  if (!status) return null;

  let canHide = true;
  let text = 'THE SHARED PARAMETER WAS SUCCESSFULLY IMPORTED!';
  let color = 'green';
  let icon = <CheckIcon width={16} height={16} />;
  switch (status) {
    case sharedStatus.process:
      text =
        'THE SHARED PARAMETER FILE IS CURRENTLY PROCESSING.' +
        ' PLEASE CHECK BACK LATER.' +
        ' ATTRIBUTE EDITING IS LOCKED DURING THIS PROCESS.';
      color = 'blue';
      canHide = false;
      icon = <LockIcon width={16} height={16} />;
      break;
    case sharedStatus.error:
      text = 'THE SHARED PARAMETER COULD NOT BE READ.';
      color = 'red';
      icon = <CloseCircleIcon width={16} height={16} />;
      break;
  }
  if (hideStatus && canHide) return null;
  return (
    <div className={`upload-info ${color}`}>
      <span className="upload-info__icon">{icon}</span>
      <p className="upload-info__text">{text}</p>
      {canHide && <Button onClick={onHide}>×</Button>}
    </div>
  );
};

Status.propTypes = {
  onHide: PropTypes.func.isRequired,
  status: PropTypes.string,
  hideStatus: PropTypes.bool
};

export default Status;
