import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import FineUploaderTraditional from 'fine-uploader-wrappers';
import { api, reducerUtil } from 'base-client';
import 'react-fine-uploader/gallery/gallery.css';

import { PageHeader } from 'shared';
import Card from 'shared/PageLayout/Card';
import Instructions from './Instructions';
import Buttons from './Buttons';
import Metadata from './Metadata';
import Status from './Status';
import Report from './Report';
import { reducerData as attributesData, actions as attributesActions } from 'productAttributes';
import { sharedStatus } from 'productAttributes/utils';
import Button from 'shared/Buttons';
import Gallery from 'shared/Forms/FineUploader/Gallery';
import 'styles/vendor/gallery.scss';
import './assets/shared-parameters.scss';

class SharedParametersContainer extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    sharedParametersData: PropTypes.shape({
      filename: PropTypes.string,
      updated: PropTypes.string,
      total: PropTypes.number,
      products: PropTypes.shape({
        total: PropTypes.number,
        conflicts: PropTypes.number
      }),
      status: PropTypes.string
    })
  };

  state = {};

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(attributesActions.startSharedParametersData());
    this.startUploader();
  }
  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(attributesActions.stopSharedParametersData());
  }

  onClickDownload = () => {
    const { dispatch } = this.props;
    dispatch(attributesActions.downloadSharedParametersFile());
  };
  onClickDelete = async () => {
    const { dispatch } = this.props;
    await dispatch(attributesActions.deleteSharedParametersFile());
    this.showElements();
  };
  onClickReport = () => {
    const { dispatch } = this.props;
    dispatch(attributesActions.downloadSharedParametersReport());
  };

  onClickShowDropzone = () => this.setState({ showDropzone: true });
  onClickHideInstructions = () => this.setState({ hideInstructions: true });
  onClickHideStatus = () => this.setState({ hideStatus: true });
  onClickHideReport = () => this.setState({ hideReport: true });

  showElements = status =>
    this.setState({ status, showDropzone: false, hideStatus: false, hideReport: false });

  startUploader = () => {
    const { uploader: lastUploader } = this.state;
    const { dispatch } = this.props;
    const headers = dispatch(api.actions.getHeaders());
    const host = dispatch(api.actions.getHost());

    if (lastUploader) {
      lastUploader.methods.reset();
      return;
    }
    const uploader = new FineUploaderTraditional({
      options: {
        chunking: {
          enabled: false
        },
        request: {
          customHeaders: headers,
          inputName: 'file',
          endpoint: `${host}/sharedParameters`
        },
        retry: {
          enableAuto: false
        },
        session: {
          refreshOnReset: false
        },
        validation: {
          itemLimit: 1
        }
      }
    });
    uploader.on('onComplete', async (id, name, response) => {
      await dispatch(attributesActions.startSharedParametersData());
      uploader.methods.reset();
      const { success } = response;
      this.showElements(success ? sharedStatus.success : sharedStatus.error);
    });
    this.setState({ uploader });
  };

  render() {
    const { sharedParametersData } = this.props;
    const { uploader, showDropzone, hideInstructions, hideStatus, hideReport, status } = this.state;
    const buttonProps = {
      onClickShowDropzone: this.onClickShowDropzone,
      onClickDownload: this.onClickDownload,
      onClickDelete: this.onClickDelete,
      sharedParametersData
    };
    const instructionProps = { onHide: this.onClickHideInstructions };
    const statusProps = {
      onHide: this.onClickHideStatus,
      status,
      hideStatus
    };
    const showElementsButton = sharedParametersData && showDropzone;

    return (
      <React.Fragment>
        <PageHeader
          title="Shared Parameters"
          subtitle="Add or update a shared parameter file"
        />
        {!sharedParametersData && !hideInstructions && <Instructions {...instructionProps} />}
        <Card>
          <div className="shared-parameters">
            <div className="shared-parameter__file">
              <div className="card__header card__header--slim">
                <h3 className="card__title">Shared Parameters File</h3>
                {showElementsButton && (
                  <Button buttonStyle="link" onClick={this.showElements}>
                    ×
                  </Button>
                )}
              </div>
              {!sharedParametersData || showDropzone ? (
                uploader && (
                  <React.Fragment>
                    <Gallery {...{ uploader }} />
                  </React.Fragment>
                )
              ) : (
                <Buttons {...buttonProps} />
              )}
            </div>
            <Metadata {...{ sharedParametersData }} />
          </div>
        </Card>
        {status && !hideReport && <Status {...statusProps} />}
        {/*  {sharedParametersData && !hideReport && <Report {...reportProps} />} */}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const sharedParametersData = reducerUtil.getSlice(
    attributesData,
    attributesData.sharedParametersData,
    state
  );
  return { sharedParametersData };
};

export default connect(mapStateToProps)(SharedParametersContainer);
