/* vendor imports */
import React from 'react';
import PropTypes from 'prop-types';

import ProductCreate from 'shared/ProductCreate';
import { LogOutIcon } from 'shared/Miscellaneous/Icons';
import { JobNotifications } from 'jobs';

import '../assets/header.scss';
import { SearchField } from 'productList';
import InputIcon from 'shared/Miscellaneous/Icons/Action/InputIcon';

const HeaderNavigation = props => (
  <div className="user-menu">
    <a href="#0" className="user-menu__icon" onClick={props.logout}>
      <LogOutIcon />
    </a>
    <span className="user-menu__tooltip">Log Out</span>
  </div>
);

HeaderNavigation.propTypes = {
  logout: PropTypes.func.isRequired
};

const HeaderView = ({ searchValue, handleSearch, logout, canView }) => (
  <header className="header">
    <div
      className="mobile-menu"
      tabIndex="0"
      aria-label="Menu"
      role="button"
      aria-controls="navigation"
    >
      <div className="mobile-menu__box">
        <div className="mobile-menu__inner" />
      </div>
    </div>
    <div className="header__left">
      <div className="branding">
        <div className="branding__logo" />
        <div className="branding__name">Manager</div>
      </div>
      <div className="header-search">
        {canView && (
          <React.Fragment>
            <SearchField
              className="input--text input--icon"
              type="text"
              placeholder="Search for Products"
            />
            <span className="input-icon">
              <InputIcon />
            </span>
          </React.Fragment>
        )}
      </div>
    </div>
    <div className="header__right">
      <ProductCreate />
      &nbsp;  
      &nbsp;
      <HeaderNavigation logout={logout} />
    </div>
  </header>
);

HeaderView.propTypes = {
  logout: PropTypes.func.isRequired,
  searchValue: PropTypes.string,
  handleSearch: PropTypes.func.isRequired,
  canView: PropTypes.bool
};

HeaderView.defaultProps = {
  searchValue: '',
  canView: false
};

export default HeaderView;
