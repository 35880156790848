import uuid from 'uuid/v4';
import { api, config, history, reducerUtil } from 'base-client';

import { reducerData as leadsData } from 'leadsTwo';
import { querySortByDirections, searchPage } from 'leadsTwo/utils';
import { actions as jobsActions } from 'jobs';
import { reducerData as permissionsData } from 'permissions';
import fileDownload from 'utils/fileDownload';
import timeUtils from 'utils/timeUtils';

const defaultLimit = 100;

/** This sets the search query string.
 */
const setSearch = () => (dispatch, getState) => {
  const queryParams = reducerUtil.getSlice(leadsData, leadsData.query, getState()) || {};
  history.push(`${searchPage}?${encodeURIComponent(JSON.stringify(queryParams))}`);
};

const clearSearch = () => (dispatch, getState) => {
  dispatch(reducerUtil.setSlice(leadsData, leadsData.query, {}));
  dispatch(reducerUtil.setSlice(leadsData, leadsData.lastQueryURL, null));
  dispatch(setSearch());
};

/** This performs a lead search.
 * @param {bool} [add] Whether or not to add to the list the list.
 * @param {number} [limit] Whether or not to add to the list the list.
 */
const search = (add, limit) => async (dispatch, getState) => {
  const trimQuery = query => {
    if (typeof query !== 'string') return query;
    while (query.charAt(0) === '?') query = query.substr(1);
    return query;
  };
  let state = getState();

  // check that they have permission
  if (!reducerUtil.getSlice(permissionsData, permissionsData.leads, state)) return;

  // get the querystring
  const {
    location: { search: queryJson }
  } = history;
  dispatch(reducerUtil.setSlice(leadsData, leadsData.lastQueryURL, queryJson));

  const query = queryJson ? JSON.parse(decodeURIComponent(trimQuery(queryJson))) : {};

  // get default sorting
  const defaultSort = { name: 'Relevance', sortBy: '_score', sortDir: 'desc' };
  const defaultSortQuery = { sortBy: defaultSort.sortBy, sortDir: defaultSort.sortDir };

  // get the page information
  const { pagination } = reducerUtil.getSlice(leadsData, leadsData.meta, state) || {};
  const page = add ? ((pagination && pagination.page) || 0) + 1 : 1;

  const fetchId = uuid();
  dispatch(reducerUtil.setSlice(leadsData, leadsData.fetchId, fetchId));
  const { filters: queryFilter } = query;

  if (queryFilter) {
    if (!queryFilter.find(filter => filter.attribute === 'leadStatus')) {
      queryFilter.push({ attribute: 'leadStatus', facets: ['My Contacts'] });
    }
  }

  const apiData = {
    ...defaultSortQuery,
    ...query,
    filters: queryFilter || [{ attribute: 'leadStatus', facets: ['My Contacts'] }]
  };

  if (!add) await dispatch(reset(apiData));

  await dispatch(jobsActions.getJobs());

  try {
    const { searchResults: results, searchMetaData: meta } = await dispatch(
      api.actions.post(
        `search/users?page=${page}&limit=${limit || defaultLimit}`,
        JSON.stringify(apiData)
      )
    );

    // check that this is the correct fetch
    state = getState();
    if (fetchId !== reducerUtil.getSlice(leadsData, leadsData.fetchId, state)) return;

    // get current product info if not restarting
    const list = (add && reducerUtil.getSlice(leadsData, leadsData.list, state)) || [];

    // create the new product list
    const newList = list.concat(dispatch(formatUsers(results)));

    // set the data
    dispatch(reducerUtil.setSlice(leadsData, leadsData.list, newList));
    dispatch(reducerUtil.setSlice(leadsData, leadsData.meta, meta));

    // update the search parameters
    dispatch(reducerUtil.setSlice(leadsData, leadsData.query, apiData));

    // allow another search
    dispatch(reducerUtil.setSlice(leadsData, leadsData.fetchId, undefined));
  } catch (error) {
    dispatch(config.actions.error(error));
  }
};

const getLeadLocation = lead => {
  const { city, state, country } = lead;

  if (city && state && country) {
    return `${city}, ${state} ${country}`;
  }
  if (state && country) {
    return `${state} ${country}`;
  }
  if (city && country) {
    return `${city} ${country}`;
  }
  if (country) {
    return country;
  }
  return 'Unknown User Location';
};

const formatUsers = rawUsers => dispatch =>
  rawUsers.map(user => {
    const info = { ...user };
    info.url = `${searchPage}/${user.id}`;
    info.to = `${searchPage}/${user.id}`;
    info.onSelect = () => dispatch(selectLead(user.id));
    info.onClick = () => history.push(user.url);
    info.location = getLeadLocation(user);
    info.lastActivity = info.lastActivity
      ? timeUtils.formatTime(new Date(info.lastActivity))
      : 'No Activity';
    return info;
  });

/** This searches for the next page, using the current search parameters.
 */
const nextPage = () => dispatch => dispatch(search(true));

const redoSearch = () => (dispatch, getState) => {
  // get the page information
  const { pagination } = reducerUtil.getSlice(leadsData, leadsData.meta, getState()) || {};
  const { page } = pagination || {};
  if (page) dispatch(search(false, defaultLimit * page));
  else dispatch(search());
};

/** This sets the search text and starts a timer before searching.
 * @param {Object} [newQuery] The new query object.
 * @param {bool} [replace] Should it replace the old search object.
 */
const setQuery = (newQuery, replace) => (dispatch, getState) => {
  if (replace) {
    dispatch(reducerUtil.setSlice(leadsData, leadsData.query, newQuery));
  } else {
    const query = reducerUtil.getSlice(leadsData, leadsData.query, getState()) || {};
    dispatch(reducerUtil.setSlice(leadsData, leadsData.query, { ...query, ...newQuery }));
  }

  dispatch(setSearch());
};

/** This sets the search text part of the product query object.
 * @param {string} [queryString] The new query string.
 */
const setQueryString = queryString => dispatch => {
  const {
    location: { pathname }
  } = history;
  return dispatch(setQuery({ queryString }, pathname !== searchPage));
};

/** This sets the sort method part of the product query object.
 * @param {string} [sortBy] The new query sorter.
 */
const setSort = sortBy => (dispatch, getState) => {
  const state = getState();
  const query = reducerUtil.getSlice(leadsData, leadsData.query, state) || {};

  let sortDir;
  if (query.sortBy === sortBy) {
    sortDir = query.sortDir === 'asc' ? 'desc' : 'asc';
  } else {
    sortDir = querySortByDirections[sortBy];
  }

  dispatch(setQuery({ sortBy, sortDir }));
};

const addFilter = ({ attribute, facets }) => (dispatch, getState) => {
  if (!Array.isArray(facets)) facets = [facets];
  const query = reducerUtil.getSlice(leadsData, leadsData.query, getState()) || {};
  const { filters } = query;

  let newFilters;
  if (!filters) {
    newFilters = [{ attribute, facets }];
  } else {
    const index = filters.findIndex(({ attribute: name }) => name === attribute);
    if (index < 0) {
      newFilters = [...filters, { attribute, facets }];
    } else {
      const { facets: prevFacets } = filters[index];
      newFilters = [...filters];
      newFilters[index] = { attribute, facets: [...prevFacets, ...facets] };
    }
  }

  return dispatch(setQuery({ ...query, filters: newFilters }));
};

const removeFilter = ({ attribute, facets }) => (dispatch, getState) => {
  if (!Array.isArray(facets)) facets = [facets];
  const query = reducerUtil.getSlice(leadsData, leadsData.query, getState()) || {};
  const { filters } = query;

  let newFilters;
  const index = filters.findIndex(({ attribute: name }) => name === attribute);
  const { facets: prevFacets } = filters[index];
  const newFacets = prevFacets.filter(name => name !== facets[0]);
  if (newFacets.length > 0) {
    newFilters = [...filters];
    newFilters[index] = { attribute, facets: newFacets };
  } else if (filters.length > 1) {
    newFilters = filters.filter((item, filterIndex) => filterIndex !== index);
  }

  return dispatch(setQuery({ ...query, filters: newFilters }));
};

const updateFilter = ({ attribute, facets }) => (dispatch, getState) => {
  if (!Array.isArray(facets)) facets = [facets];
  const query = reducerUtil.getSlice(leadsData, leadsData.query, getState()) || {};
  const { filters = [] } = query;
  let newFilters = [...filters];
  const index = filters.findIndex(({ attribute: name }) => name === attribute);
  if (index < 0) {
    if (facets.length > 0) {
      newFilters.push({ attribute, facets });
    }
  } else if (facets.length > 0) {
    newFilters[index] = { attribute, facets };
  } else {
    newFilters.splice(index, 1);
  }
  return dispatch(setQuery({ ...query, filters: newFilters }));
};

const selectLead = newId => (dispatch, getState) => {
  const selected = reducerUtil.getSlice(leadsData, leadsData.selected, getState()) || [];
  const newSelection = [...selected];
  const index = newSelection.findIndex(id => id === newId);
  if (index < 0) newSelection.push(newId);
  else newSelection.splice(index, 1);
  dispatch(reducerUtil.setSlice(leadsData, leadsData.selected, newSelection));
};

const selectAll = () => (dispatch, getState) => {
  const state = getState();
  const selected = reducerUtil.getSlice(leadsData, leadsData.selected, getState()) || [];

  let list = reducerUtil.getSlice(leadsData, leadsData.listId, state) || [];

  if (list.length === 0) return;
  if (selected.length > 0) {
    dispatch(selectNone());
    return;
  }
  const newSelection = list.slice();
  dispatch(reducerUtil.setSlice(leadsData, leadsData.selected, newSelection));
};

const reset = query => async dispatch => {
  try {
    const { searchResults: listIds } = await dispatch(
      api.actions.post('search/users/ids', JSON.stringify(query))
    );
    dispatch(reducerUtil.setSlice(leadsData, leadsData.listId, listIds));
  } catch (error) {
    dispatch(config.actions.error(error));
  }
  dispatch(selectNone());
};

const extractSelectedUsers = () => async (dispatch, getState) => {
  const selected = reducerUtil.getSlice(leadsData, leadsData.selected, getState()) || {};
  try {
    const data = await dispatch(
      api.actions.post('users/export', JSON.stringify({ ids: selected }))
    );
    if (data) fileDownload(data, `${'Leads'}.csv`);
  } catch (error) {
    dispatch(config.actions.error(error));
  }
};

const selectNone = () => dispatch =>
  dispatch(reducerUtil.setSlice(leadsData, leadsData.selected, undefined));

export default {
  search,
  nextPage,
  addFilter,
  removeFilter,
  updateFilter,
  setQueryString,
  setSort,
  redoSearch,
  selectLead,
  selectAll,
  extractSelectedUsers,
  setSearch,
  clearSearch
};
