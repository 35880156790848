import { reducerUtil } from "base-client";
import { reducerData as tenantData } from "tenant";
import { apiData, loginData } from "./reducerData";
export const lambdaApiCall =
  ({endpoint, method, body, async = false, headers}) =>
  async (dispatch, getState) => {
    const tenant_id = reducerUtil.getSlice(tenantData, tenantData.tenant, getState());

    const state = getState();
    const loginRequired = reducerUtil.getSlice(apiData, loginData.loginRequired, state);
    const token = reducerUtil.getSlice(loginData, loginData.token, state);

    if (loginRequired && !token) throw new Error("login token required");

    const config = { method, mode: "cors", headers: { ...headers, ...dispatch(getHeaders())} };

    if (body) {
      if (typeof body === "string") {
        body = JSON.parse(body);
      }
      body.tenant_id = tenant_id;

      if (body instanceof FormData) {
        config.body = body;
      } else {
        config.headers["Content-Type"] = "application/json";
        config.body = JSON.stringify(body);
      }
    }

    const response = await fetch(endpoint, config);
    if (response && response.ok) {
      const contentType = response.headers.get("content-type");
      if (!async && contentType.includes("application/json")) {
        let json = await response?.json();
        return json;
      } else if (contentType && contentType.includes("text/html")) {
        return await response.text();
      }
      return await response.blob();
    } else if (response.status === 302) {
      const disableRedirect = reducerUtil.getSlice(apiData, apiData.disableRedirect, state);
      if (disableRedirect) {
        throw response;
      }

      const url = await response.json();
      window.location.replace(url);
    } else {
      throw response;
    }
  };

/** This returns the header object */
const getHeaders = () => (dispatch, getState) => {
  const state = getState();
  const tenantId = reducerUtil.getSlice(tenantData, tenantData.tenant, getState());
  const token = reducerUtil.getSlice(loginData, loginData.token, state);
  const headers = reducerUtil.getSlice(apiData, apiData.headers, state) || {};
  return {
    Authorization: token ? `Bearer ${token}` : undefined,
    ...headers,
  };
};
