import 'react-fine-uploader/gallery/gallery.css';

import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { FormGroup, ControlLabel, FormControl } from 'react-bootstrap';
import cn from 'classnames';

import Gallery from '../FineUploader/Gallery';
import Button from '../../Buttons';

// import our styling overrides
import '../../../styles/vendor/gallery.scss';

export const inputField = ({ input, title, type, isLocked, className, meta: { error } }) => {
  const errorClass = 'form-msg form-msg--error';
  const warningClass = 'form-msg form-msg--warn';
  const errors = [];
  const warnings = [];
  let bsClass = null;
  let status = null;

  if (error && error.size > 0) {
    status = error.success;
    error = error.errors;
  }
  if (error && error.length > 0) {
    error.forEach(err => {
      if (err.type === 'WARN' && err.message) warnings.push(err.message);
      else if (err.type === 'ERROR' && err.message) errors.push(err.message);
    });
  }
  if (status === 'FAIL') bsClass = 'form-control--error';
  if (status === 'WARN') bsClass = 'form-control--warn';
  // if (status === 'PASS') bsClass = 'form-control--success';

  return (
    <FormGroup controlId={input.name} bsClass={cn('form-group', className)}>
      {title && <ControlLabel>{title}</ControlLabel>}
      <FormControl
        {...input}
        type={type !== 'readOnly' ? type : 'text'}
        disabled={type === 'readOnly' || isLocked}
        onChange={input.onChange}
        bsClass={cn('form-control', bsClass)}
      />
      {errors &&
        errors.map(msg => (
          <span key={msg + input.name} className={errorClass}>
            {msg}
          </span>
        ))}
      {warnings &&
        warnings.map(msg => (
          <span key={msg + input.name} className={warningClass}>
            {msg}
          </span>
        ))}
    </FormGroup>
  );
};

inputField.propTypes = {
  input: PropTypes.object.isRequired,
  title: PropTypes.string,
  type: PropTypes.string.isRequired,
  urlSafe: PropTypes.bool,
  className: PropTypes.string,
  meta: PropTypes.object.isRequired,
  isLocked: PropTypes.bool
};

inputField.defaultProps = {
  title: null,
  urlSafe: false,
  className: null,
  isLocked: false
};

const inputForm = props => <Field {...props} name={props.key} component={inputField} />;

inputForm.propTypes = {
  key: PropTypes.string.isRequired
};

const textAreaField = ({ input, title, isLocked, meta: { touched, error, warning }, key }) => {
  let message = null;
  let validationState = null;
  if (touched) {
    if (error) {
      validationState = 'error';
      message = error;
    } else if (warning) {
      validationState = 'warning';
      message = warning;
    }
  }
  return (
    <FormGroup controlId={input.name} key={key} validationState={validationState}>
      <ControlLabel>{title}</ControlLabel>
      <FormControl {...input} componentClass="textarea" disabled={isLocked} />
      {message && <span className="help-block">{message}</span>}
    </FormGroup>
  );
};

textAreaField.propTypes = {
  input: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  key: PropTypes.string.isRequired,
  isLocked: PropTypes.bool.isRequired
};

const textAreaForm = props => <Field {...props} name={props.key} component={textAreaField} />;

textAreaForm.propTypes = {
  key: PropTypes.string.isRequired
};

const dropzoneForm = ({ key, title, uploader, bMulti, isLocked }) => (
  <div key={key} className="dropzone">
    <ControlLabel>{title}</ControlLabel>
    <Gallery uploader={uploader} disabled={isLocked} multi={bMulti} />
  </div>
);

dropzoneForm.propTypes = {
  uploader: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  key: PropTypes.string.isRequired,
  bMulti: PropTypes.bool,
  isLocked: PropTypes.bool.isRequired
};

dropzoneForm.defaultProps = {
  bMulti: false
};

const selectField = ({ input, title, options, isLocked, meta: { touched, error, warning } }) => {
  let message = null;
  let validationState = null;
  if (touched) {
    if (error) {
      validationState = 'error';
      message = error;
    } else if (warning) {
      validationState = 'warning';
      message = warning;
    }
  }
  return (
    <FormGroup controlId={input.name} validationState={validationState}>
      <ControlLabel>{title}</ControlLabel>
      <FormControl {...input} componentClass="select" disabled={isLocked}>
        {options.map(option => (
          <option key={option.key} value={option.key}>
            {option.display || option.key}
          </option>
        ))}
      </FormControl>
      {message && <span className="help-block">{message}</span>}
    </FormGroup>
  );
};

selectField.propTypes = {
  input: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  meta: PropTypes.object.isRequired,
  isLocked: PropTypes.bool.isRequired
};

const selectForm = props => <Field {...props} name={props.key} component={selectField} />;

selectForm.propTypes = {
  key: PropTypes.string.isRequired
};

const revitForm = ({ key, dropzones, complete, isLocked, revitStart }) => (
  <div key={key}>
    <div className="revit-dropzones">
      {dropzones.map(dropzone => dropzoneForm({ isLocked, ...dropzone }))}
    </div>
    <Button buttonStyle="green" type="button" onClick={complete} disabled={isLocked || !revitStart}>
      Start Processing
    </Button>
  </div>
);

revitForm.propTypes = {
  dropzones: PropTypes.array.isRequired,
  key: PropTypes.string.isRequired,
  complete: PropTypes.func.isRequired,
  isLocked: PropTypes.bool.isRequired,
  revitStart: PropTypes.bool.isRequired
};

const chooseForm = contents => {
  if (contents.component === 'textarea') return textAreaForm(contents);
  if (contents.component === 'dropzone') return dropzoneForm(contents);
  if (contents.component === 'revit') return revitForm(contents);
  if (contents.type === 'select') return selectForm(contents);
  return inputForm(contents);
};

export default chooseForm;
