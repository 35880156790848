import { connect } from 'react-redux';
import { history, reducerUtil } from 'base-client';

import Container from './container';
import { modalName } from '../utils';
import { reducerData as permissionsData } from 'permissions';
import { openModal } from 'modal/modal';

const mapStateToProps = state => ({
  canView: reducerUtil.getSlice(permissionsData, permissionsData.manager, state),
  csv: reducerUtil.getSlice(permissionsData, permissionsData.csv, state)
});

const mapDispatchToProps = dispatch => ({
  openModal: (open = true) => dispatch(openModal(modalName, open)),
  toProduct: key => history.push(`/products/${key}`)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Container);
