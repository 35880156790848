import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Checkbox from 'shared/InputGroups/Checkbox';
import { EditIcon, DeleteIcon } from 'shared/Miscellaneous/Icons';
import { LinkButton } from 'shared/Buttons';
import { actions as imagesActions } from 'images';

const GridCell = ({ dispatch, rowData }) => {
  const {
    isChecked,
    onSelect,
    onClick,
    onClickDelete,
    url,
    id,
    imageId,
    name,
    category,
    status,
    updated,
    published
  } = rowData;

  const [image, setImage] = useState();

  useEffect(() => {
    if (imageId) {
      const getImage = async () => {
        const newImage = await dispatch(imagesActions.addImage(imageId, id));
        setImage(newImage);
      };
      getImage();
    } else setImage(undefined);
  }, [imageId]);

  return (
    <div className="product-grid__item-wrapper">
      <Checkbox isChecked={isChecked} onChange={onSelect} />
      <div className="product-grid__item-actions">
        <EditIcon onClick={onClick} addClass="icon--grid" width={22} height={22} />
        <DeleteIcon
          onClick={onClickDelete}
          addClass="icon--grid icon--hover-danger"
          width={24}
          height={24}
        />
      </div>
      <LinkButton to={url}>
        <div className="product-grid__item-img">
          <img src={image || '../../imgs/app/GridNoProductImage.jpg'} alt="" />
        </div>
        <div className="product-grid__item-main">
          <h4>{name}</h4>
          <p>{category}</p>
        </div>
        <div className="product-grid__item-meta">
          <p className="product-grid__item-meta-prop">{status}</p>
          <p className="product-grid__item-meta-prop">
            {updated !== 'Unmodified' ? `Modified ${updated}` : published}
          </p>
          <p className="product-grid__item-meta-prop">
            {published !== 'Unpublished' ? `Published ${published}` : published}
          </p>
        </div>
      </LinkButton>
    </div>
  );
};

GridCell.propTypes = {
  dispatch: PropTypes.func.isRequired,
  rowData: PropTypes.object
};

GridCell.defaultProps = {
  rowData: {}
};

export default connect()(GridCell);
