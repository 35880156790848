import { api, config, reducerUtil } from 'base-client';

import { reducerData as imagesData } from 'images';

const addImage = (assetId, productId) => async (dispatch, getState) => {
  const initialList = reducerUtil.getSlice(imagesData, imagesData.imageList, getState());
  if (initialList) {
    const { url: initialUrl } = initialList.find(item => item.assetId === assetId) || {};
    if (initialUrl) return initialUrl;
  }

  try {
    const result = await dispatch(
      api.actions.get(`products/${productId}/assets/${assetId}/download`)
    );
    if (result) {
      const urlCreator = window.URL || window.webkitURL;
      const url = urlCreator.createObjectURL(result);
      const currentList = reducerUtil.getSlice(imagesData, imagesData.imageList, getState()) || [];
      dispatch(
        reducerUtil.setSlice(imagesData, imagesData.imageList, [...currentList, { assetId, url }])
      );
      return url;
    }
  } catch (error) {
    dispatch(config.actions.error(error));
  }
};

export default {
  addImage
};
