import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { AutoSizer, Column, Table } from 'react-virtualized';
import _ from 'lodash';
import timeUtils from 'utils/timeUtils';

import { SectionHeader } from 'shared';
import { Rows } from 'shared/TablesAndLists';
import Card from 'shared/PageLayout/Card';
import NoDataCallout from './NoDataCallout';

const EventLog = ({ data }) => {
  const [sortByField, setSortByField] = useState('datetime');
  const [sortDir, setSortDir] = useState('DESC');
  const [list, setList] = useState(data);
  const sort = ({ sortBy, sortDirection }) => {
    if (sortDirection !== sortDir) {
      setSortDir(sortDirection);
    }
    setSortByField(sortBy);
    const newList = _.sortBy(list, sortBy);
    if (sortDir === 'DESC') {
      setList(newList.reverse());
    } else {
      setList(newList);
    }
  };
  const rowGetter = ({ index }) => (list && list[index]) || {};
  const rowCount = (list && list.length) || 0;
  const cellRenderer = ({ cellData, rowData }) =>
    rowData.url === '#' ? cellData : <Rows.Link {...{ cellData, rowData }} />;

  const dateCellRenderer = ({ cellData, rowData }) => {
    let prettyDate;
    if (cellData) {
      prettyDate = timeUtils.format.toStatus(new Date(cellData));
    } else {
      prettyDate = 'Unknown';
    }
    return rowData.url === '#' ? prettyDate : <Rows.Link {...{ cellData: prettyDate, rowData }} />;
  };

  return (
    <React.Fragment>
      {rowCount === 0 && (
        <NoDataCallout
          dataTitle={'Events'}
          flavor={
            'You should add them to an email list and drive them to your site to capture activity. Get this user into your funnel.'
          }
        />
      )}
      {rowCount > 0 && (
        <div className="table--AutoSizer">
          <AutoSizer>
            {({ height, width }) => (
              <Table
                headerHeight={55}
                headerClassName="table-header"
                rowClassName="table-row"
                sort={sort}
                sortBy={sortByField}
                sortDirection={sortDir}
                rowHeight={70}
                height={height}
                {...{ rowCount, rowGetter, width }}
              >
                <Column
                  label="Date"
                  dataKey="datetime"
                  width={120}
                  flexGrow={1}
                  cellRenderer={dateCellRenderer}
                />
                <Column
                  label="Activity"
                  dataKey="event"
                  width={50}
                  flexGrow={1}
                  cellRenderer={cellRenderer}
                />
                <Column
                  label="Site"
                  dataKey="site"
                  width={50}
                  flexGrow={1}
                  cellRenderer={cellRenderer}
                />
                <Column
                  label="Activity Data"
                  dataKey="eventData"
                  width={500}
                  flexGrow={1}
                  cellRenderer={cellRenderer}
                />
              </Table>
            )}
          </AutoSizer>
        </div>
      )}
    </React.Fragment>
  );
};

EventLog.propTypes = {
  table: PropTypes.arrayOf(
    PropTypes.shape({
      productKey: PropTypes.string,
      event: PropTypes.string,
      name: PropTypes.string,
      category: PropTypes.string,
      date: PropTypes.string,
      url: PropTypes.string
    })
  )
};

export default EventLog;
