import React from 'react';
import PropTypes from 'prop-types';

import './../assets/buttons.scss';

const ButtonIndex = ({ children, buttonClassNames, icon, iconPosition, type, ...other }) => (
  <button className={buttonClassNames} {...other} type={type}>
    {iconPosition === 'before' && icon}
    {children}
    {iconPosition === 'after' && icon}
  </button>
);

ButtonIndex.propTypes = {
  children: PropTypes.node,
  buttonClassNames: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  icon: PropTypes.node,
  iconPosition: PropTypes.oneOf([
    'before', // default
    'after'
  ]),
  type: PropTypes.string
};

ButtonIndex.defaultProps = {
  children: null,
  onClick: null,
  icon: null,
  iconPosition: 'before',
  type: 'button'
};

export default ButtonIndex;
