import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Bar from './Bar';
import { CloseIcon } from 'shared/Miscellaneous/Icons';
import sbManagerStyles from 'utils/globalStyles';

const Container = styled.div`
  padding: 2rem;
  position: sticky;
  position: -webkit-sticky;
  right: 0;
  margin-right: -1rem;
  bottom: 8.5rem;
  z-index: 99999;
  min-width: 400px;
  float: right;
  .icon {
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
  }
`;
const Header = styled.div`
  display: flex;
  align-items: center;
`;
const BoldText = styled.strong`
  margin-left: 0.2rem;
`;
const InforText = styled.div`
  color: ${() => sbManagerStyles.greyMedium};
`;

const Progress = ({ onClick, successes, fails, fileList }) => (
  <Container className="card">
    <Header>
      Uploading <BoldText>Asset(s)...</BoldText>
      <CloseIcon width={15} height={15} {...{ onClick }} />
    </Header>
    <Bar percentage={(successes + fails) / fileList.length} />
    <InforText>{`${fileList.length - successes - fails} files remaining`}</InforText>
  </Container>
);

Progress.propTypes = {
  onClick: PropTypes.func.isRequired,
  successes: PropTypes.number.isRequired,
  fails: PropTypes.number.isRequired,
  fileList: PropTypes.array.isRequired
};

export default Progress;
