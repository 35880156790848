import React from 'react';
import ReactDOM from 'react-dom';
import { history } from 'base-client';

import App from 'App';
import store from 'store';

ReactDOM.render(<App {...{ store, history }} />, document.getElementById('root'));

if (module.hot) {
  module.hot.accept();
}
