import { api, config, reducerUtil } from 'base-client';
import { actions as detailsActions, reducerData as detailsData } from 'productDetails';
import sustainabilityActions from './sustainability';
import detailsUtils from 'productDetails/utils';
import { reducerData as attributesData, actions as attributeActions } from 'productAttributes';
import { actions as imageActions } from 'images';
import { toTitleCase } from 'utils/miscUtils';
import { configMap } from 'configurations';

const getProduct = id => dispatch => {
  dispatch(reducerUtil.setSlice(detailsData, detailsData.status, undefined));
  dispatch(reducerUtil.setSlice(detailsData, detailsData.selectedOption, 0));
  dispatch(detailsActions.clearTrack());

  return dispatch(fetchProduct(id));
};

const fetchProduct = productId => async (dispatch, getState) => {
  await dispatch(attributeActions.getAttributes());

  try {
    const result = await dispatch(api.actions.get(`products/${productId}`));
    dispatch(reducerUtil.setSlice(detailsData, detailsData.productId, result.id));
    const state = getState();
    const attributes = reducerUtil.getSlice(attributesData, attributesData.attributes, state);
    const assetList = [detailsUtils.noAsset, ...result.assets];

    const values = {
      [detailsUtils.form.product]: {},
      [detailsUtils.form.system]: {},
      [detailsUtils.form.sustainability]: {}
    };

    const information = result.attributeValues
      .map(({ formula, isFormula, unit, value, sharedParameterGUID, attribute_id: id }) => {
        values[detailsUtils.form.product][id] = {
          formula,
          isFormula,
          unit,
          value,
          sharedParameterGUID
        };
        return id;
      })
      .sort((a, b) => {
        const firstName = attributes.find(item => item.id === a).name;
        const secondName = attributes.find(item => item.id === b).name;
        if (firstName === secondName) return 0;
        return firstName > secondName ? 1 : -1;
      });

    const elements = result.elements
      .map(item => {
        const element = {
          name: `${toTitleCase(item.elementType)}: ${item.name}`,
          id: item.id
        };
        values[element.id] = {};
        element.list = item.attributeValues
          .map(({ formula, isFormula, unit, value, sharedParameterGUID, attribute_id: id }) => {
            values[element.id][id] = {
              formula,
              isFormula,
              unit,
              value,
              sharedParameterGUID
            };
            return id;
          })
          .sort((a, b) => {
            const firstName = attributes.find(item => item.id === a).name;
            const secondName = attributes.find(item => item.id === b).name;
            if (firstName === secondName) return 0;
            return firstName > secondName ? 1 : -1;
          });
        return element;
      })
      .sort((a, b) => a.name.localeCompare(b.name));

    values[detailsUtils.form.system] = result.versions.find(item => item.id === result.id);

    const { assets } = result;
    const newAssets = dispatch(formatAssets(assets));

    const status = {
      [detailsUtils.statusNames.created]: result[detailsUtils.statusNames.created],
      [detailsUtils.statusNames.updated]: result[detailsUtils.statusNames.updated],
      [detailsUtils.statusNames.published]: result[detailsUtils.statusNames.published]
    };

    const disableSustainability = dispatch(
      config.actions.getData(configMap.disabled.name, configMap.disabled.sustainability.name)
    );

    if (!disableSustainability) {
      const { activeVersion } = result || {};
      const productVersionId = activeVersion.id || productId;
      const rawOptions = await dispatch(
        sustainabilityActions.getOptions(productId, productVersionId)
      );

      const optionValues = await Promise.all(
        rawOptions.map(({ id }) =>
          dispatch(sustainabilityActions.getOptionValues(productId, productVersionId, id))
        )
      );

      const productOptions = rawOptions.map(({ id, name }, index) => {
        const option = { id, name };
        values[detailsUtils.form.sustainability][id] = {};

        values[detailsUtils.form.sustainability][id][detailsUtils.sustainabilityGroups[0]] =
          optionValues[index][detailsUtils.sustainabilityGroups[0]];

        const { values: certValues, list: certList } = formatCertificates(
          optionValues[index][detailsUtils.sustainabilityGroups[0]]
        );

        option[detailsUtils.sustainabilityGroups[0]] = certList;
        values[detailsUtils.form.sustainability][id][
          detailsUtils.sustainabilityGroups[0]
        ] = certValues;

        const { values: charValues, list: charList } = formatCharacteristics(
          optionValues[index][detailsUtils.sustainabilityGroups[1]]
        );

        option[detailsUtils.sustainabilityGroups[1]] = charList;
        values[detailsUtils.form.sustainability][id][
          detailsUtils.sustainabilityGroups[1]
        ] = charValues;

        const { values: ratingValues } = formatRatings(
          optionValues[index][detailsUtils.sustainabilityGroups[2]]
        );

        values[detailsUtils.form.sustainability][id][
          detailsUtils.sustainabilityGroups[2]
        ] = ratingValues;

        return option;
      });

      dispatch(reducerUtil.setSlice(detailsData, detailsData.options, productOptions));
    }

    dispatch(reducerUtil.setSlice(detailsData, detailsData.assets, newAssets));
    dispatch(reducerUtil.setSlice(detailsData, detailsData.assetList, assetList));
    dispatch(reducerUtil.setSlice(detailsData, detailsData.information, information));
    dispatch(reducerUtil.setSlice(detailsData, detailsData.elements, elements));
    dispatch(reducerUtil.setSlice(detailsData, detailsData.values, values));
    dispatch(reducerUtil.setSlice(detailsData, detailsData.status, status));
    return dispatch(detailsActions.startJobList(productId));
  } catch (error) {
    dispatch(config.actions.error(error));
    dispatch(reducerUtil.setSlice(detailsData, detailsData.status, { failed: true }));
  }
};

const formatCertificates = certificates => {
  const values = {};
  const list = certificates.map(({ id, assetId, date, url }) => {
    values[id] = { assetId, date, url };
    return id;
  });
  return { values, list };
};

const formatRatings = ratings => {
  const values = {};
  ratings.forEach(({ id, value }) => {
    values[id] = value;
  });
  return { values };
};

const formatCharacteristics = characteristics => {
  const values = {};
  const list = characteristics.map(({ id, value }) => {
    values[id] = value;
    return id;
  });
  return { values, list };
};

const formatAssets = assets => dispatch => {
  if (!assets) return undefined;

  return assets.map(asset => {
    const { filename, size, updated_at } = asset;
    const fileNames = (filename && filename.split('.')) || [];
    return {
      ...asset,
      dateAdded: detailsUtils.formatTime(updated_at),
      fileSize: detailsUtils.getFileSize(size),
      fileType: fileNames.length > 1 ? fileNames[fileNames.length - 1] : ''
    };
  });
};

const refreshProduct = () => (dispatch, getState) => {
  const productId = reducerUtil.getSlice(detailsData, detailsData.productId, getState());
  if (!productId) return;
  return dispatch(fetchProduct(productId));
};

const getImage = assetId => (dispatch, getState) => {
  const productId = reducerUtil.getSlice(detailsData, detailsData.productId, getState());
  return dispatch(imageActions.addImage(assetId, productId));
};

export default {
  getProduct,
  fetchProduct,
  refreshProduct,
  getImage
};
