import { api, config, reducerUtil } from 'base-client';

import { reducerData as detailsData } from 'productDetails';
import { modalConfirmName, modalConfirmDisplay, openModal } from 'modal/modal';
import loadActions from './load';
import { actions as jobsActions } from 'jobs';

const oneSecond = 1000;
const delay = 20 * oneSecond;
let timer;

const startJobList = () => async dispatch => {
  if (timer) clearTimeout(timer);
  try {
    await dispatch(getJobList());
    timer = setTimeout(() => dispatch(startJobList()), delay);
  } catch {
    dispatch(stopJobList());
  }
};

const stopJobList = () => dispatch => {
  if (timer) clearTimeout(timer);
  dispatch(reducerUtil.setSlice(detailsData, detailsData.jobs, undefined));
};

const getJobList = () => async (dispatch, getState) => {
  const state = getState();
  const productId = reducerUtil.getSlice(detailsData, detailsData.productId, state);
  const lastJobs = reducerUtil.getSlice(detailsData, detailsData.jobs, state) || [];

  const jobTypes = ['publish', 'update'];

  try {
    const result = await dispatch(api.actions.get(`products/${productId}/jobs`));
    const { jobs: rawJobs } = result || {};
    const jobs = rawJobs.map(item => {
      const { created_at: createdAt, JobStages: jobStages, id } = item || {};
      const { JobType: typeInfo } = jobStages[jobStages.length - 1];
      let type;
      if (typeInfo) {
        const { name } = typeInfo;
        switch (name) {
          case 'publish-to-library':
            type = jobTypes[0];
            break;
          case 'extract-from-revit':
            type = jobTypes[1];
            break;
          case 'publish-to-revit':
            type = jobTypes[1];
            break;
        }
      }
      return { type, createdAt, id };
    });

    const filteredJobs = jobs.filter(job => jobTypes.includes(job.type));
    dispatch(reducerUtil.setSlice(detailsData, detailsData.jobs, filteredJobs));

    if (lastJobs.length > 0 && jobs.length <= 0) dispatch(loadActions.refreshProduct());
  } catch (error) {
    dispatch(config.actions.error(error));
    throw error;
  }
};

const cancelJob = id => dispatch => {
  dispatch(
    modalConfirmDisplay(
      displayCancel(
        async () => {
          dispatch(openModal(modalConfirmName, false));
          await dispatch(jobsActions.cancelJob(id));
          dispatch(startJobList());
        },
        () => dispatch(openModal(modalConfirmName, false))
      )
    )
  );
  dispatch(openModal(modalConfirmName));
};

const displayCancel = (onConfirm, onCancel) => {
  const title = 'Confirm Cancel';
  const text = 'Are you sure you want to cancel the task?';
  return {
    title,
    text,
    buttonList: [
      {
        text: 'Cancel Task',
        onClick: onConfirm
      },
      {
        text: 'Continue Task',
        onClick: onCancel
      }
    ],
    noClose: false
  };
};

const retryJob = id => async dispatch => {
  await dispatch(jobsActions.retryJob(id));
  dispatch(startJobList());
};

export default {
  startJobList,
  stopJobList,
  cancelJob,
  retryJob
};
