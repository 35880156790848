import uuid from 'uuid/v4';
import { api, config, history, reducerUtil } from 'base-client';

import { actions as searchActions, reducerData as searchData } from 'productList';
import { querySortDir, querySortByDirections, searchPage } from 'productList/utils';
import { actions as jobsActions } from 'jobs';
import { reducerData as permissionsData } from 'permissions';
import timeUtils from 'utils/timeUtils';
import { getlocationQuery } from 'utils/miscUtils';
import { configMap } from 'configurations';

const defaultLimit = 100;

/** This sets the search query string.
 */
const setSearch = () => (dispatch, getState) => {
  const queryParams = reducerUtil.getSlice(searchData, searchData.query, getState()) || {};
  history.push(`${searchPage}?${encodeURIComponent(JSON.stringify(queryParams))}`);
};

const clearSearch = () => (dispatch, getState) => {
  dispatch(reducerUtil.setSlice(searchData, searchData.query, {}));
  dispatch(setSearch());
};

const trimQuery = query => {
  if (typeof query !== 'string') return query;
  while (query.charAt(0) === '?') query = query.substr(1);
  return query;
};

/** This performs a product search.
 * @param {bool} [add] Whether or not to add to the list the list.
 * @param {number} [limit] Whether or not to add to the list the list.
 */
const search = (add, limit) => async (dispatch, getState) => {
  let state = getState();

  // check that they have permission
  if (!reducerUtil.getSlice(permissionsData, permissionsData.manager, state)) return;

  const disableFilters = dispatch(
    config.actions.getData(configMap.disabled.name, configMap.disabled.productFilters.name)
  );

  // get the querystring
  let query;

  if (disableFilters) {
    query = getlocationQuery();
  } else {
    const {
      location: { search: queryJson }
    } = history;
    query = queryJson ? JSON.parse(decodeURIComponent(trimQuery(queryJson))) : {};
  }

  // get default sorting
  const defaultSort = { name: 'Relevance', sortBy: '_score', sortDir: 'desc' };
  const defaultSortQuery = { sortBy: defaultSort.sortBy, sortDir: defaultSort.sortDir };

  // get the page information
  const { pagination } = reducerUtil.getSlice(searchData, searchData.meta, state) || {};
  const page = add ? ((pagination && pagination.page) || 0) + 1 : 1;

  const fetchId = uuid();
  dispatch(reducerUtil.setSlice(searchData, searchData.fetchId, fetchId));

  // assemble the search terms

  let searchTerms = [];
  let apiData;

  if (disableFilters) {
    if (query.queryString) searchTerms.push(`queryString=${encodeURIComponent(query.queryString)}`);
    if (query.sortBy) {
      searchTerms.push(`sortBy=${query.sortBy}`);
      searchTerms.push(`sortDir=${query.sortDir}`);
    }

    if (!add) await dispatch(searchActions.reset(searchTerms.join('&')));
    searchTerms.unshift(...[`page=${page}`, `limit=${limit || defaultLimit}`]);
  } else {
    const { filters: queryFilter } = query;

    if (queryFilter) {
      if (!queryFilter.find(filter => filter.attribute === 'isActive')) {
        queryFilter.push({ attribute: 'isActive', facets: [true] });
      }
    }

    apiData = {
      ...defaultSortQuery,
      ...query,
      filters: queryFilter || [{ attribute: 'isActive', facets: [true] }]
    };

    if (!add) await dispatch(searchActions.reset(apiData));
  }

  await dispatch(jobsActions.getJobs());

  try {
    let response;
    if (disableFilters) {
      response = await dispatch(api.actions.post(`search/products?${searchTerms.join('&')}`));
    } else {
      response = await dispatch(
        api.actions.post(
          `search/products?page=${page}&limit=${limit || defaultLimit}`,
          JSON.stringify(apiData)
        )
      );
    }

    const { searchResults: results, searchMetaData: meta } = response;
    // check that this is the correct fetch
    state = getState();
    if (fetchId !== reducerUtil.getSlice(searchData, searchData.fetchId, state)) return;

    // get current product info if not restarting
    const list = (add && reducerUtil.getSlice(searchData, searchData.list, state)) || [];

    // create the new product list
    const newList = list.concat(dispatch(formatProducts(results)));

    // set the data
    dispatch(reducerUtil.setSlice(searchData, searchData.list, newList));
    dispatch(reducerUtil.setSlice(searchData, searchData.meta, meta));

    // update the search parameters
    if (disableFilters) {
      dispatch(reducerUtil.setSlice(searchData, searchData.query, query));
    } else {
      dispatch(reducerUtil.setSlice(searchData, searchData.query, apiData));
    }

    // allow another search
    dispatch(reducerUtil.setSlice(searchData, searchData.fetchId, undefined));
  } catch (error) {
    dispatch(config.actions.error(error));
  }
};

const formatProducts = rawProducts => dispatch => {
  // image info should be handled by the component

  return rawProducts.map(({ publishedAt: publish, updatedAt: update, activeVersion }) => {
    const { id, productCategory, productName, productThumbnail } = activeVersion;

    let published = 'Unpublished';
    if (publish) {
      if (timeUtils.isToday(publish)) {
        published = timeUtils.formatAs(timeUtils.formats.PRODUCT_ACTION_TIME, new Date(publish));
      } else {
        published = timeUtils.formatAs(timeUtils.formats.NUMBER, new Date(publish));
      }
    }

    let updated = 'Unmodified';
    if (update) {
      if (timeUtils.isToday(update)) {
        updated = timeUtils.formatAs(timeUtils.formats.PRODUCT_ACTION_TIME, new Date(update));
      } else {
        updated = timeUtils.formatAs(timeUtils.formats.NUMBER, new Date(update));
      }
    }

    const url = `${searchPage}/${id}`;

    return {
      id,
      published,
      updated,
      url,
      name: productName,
      imageId: productThumbnail,
      category: productCategory,
      onSelect: () => dispatch(searchActions.selectProduct(id)),
      onClick: () => history.push(url),
      onClickDownload: () => dispatch(searchActions.downloadSelected(id)),
      onClickDelete: () => dispatch(searchActions.deleteSelected(id))
    };
  });
};

/** This searches for the next page, using the current search parameters.
 */
const nextPage = () => dispatch => dispatch(search(true));

const redoSearch = () => (dispatch, getState) => {
  // get the page information
  const { pagination } = reducerUtil.getSlice(searchData, searchData.meta, getState()) || {};
  const { page } = pagination || {};
  if (page) dispatch(search(false, defaultLimit * page));
  else dispatch(search());
};

/** This sets the search text and starts a timer before searching.
 * @param {Object} [newQuery] The new query object.
 * @param {bool} [replace] Should it replace the old search object.
 */
const setQuery = (newQuery, replace) => (dispatch, getState) => {
  if (replace) {
    dispatch(reducerUtil.setSlice(searchData, searchData.query, newQuery));
  } else {
    const query = reducerUtil.getSlice(searchData, searchData.query, getState());
    dispatch(reducerUtil.setSlice(searchData, searchData.query, { ...query, ...newQuery }));
  }

  dispatch(setSearch());
};

/** This sets the search text part of the product query object.
 * @param {string} [queryString] The new query string.
 */
const setText = queryString => dispatch => {
  const {
    location: { pathname }
  } = history;
  return dispatch(setQuery({ queryString }, pathname !== searchPage));
};

/** This sets the sort method part of the product query object.
 * @param {string} [sortBy] The new query sorter.
 */
const setSort = sortBy => (dispatch, getState) => {
  const state = getState();
  const query = reducerUtil.getSlice(searchData, searchData.query, state) || {};

  let sortDir;
  if (query.sortBy === sortBy) {
    sortDir = query.sortDir === 'asc' ? 'desc' : 'asc';
  } else {
    sortDir = querySortByDirections[sortBy];
  }

  dispatch(setQuery({ sortBy, sortDir }));
};

const addFilter = ({ attribute, facets }) => (dispatch, getState) => {
  if (!Array.isArray(facets)) facets = [facets];
  const query = reducerUtil.getSlice(searchData, searchData.query, getState()) || {};
  const { filters } = query;

  let newFilters;
  if (!filters) {
    newFilters = [{ attribute, facets }];
  } else {
    const index = filters.findIndex(({ attribute: name }) => name === attribute);
    if (index < 0) {
      newFilters = [...filters, { attribute, facets }];
    } else {
      const { facets: prevFacets } = filters[index];
      newFilters = [...filters];
      newFilters[index] = { attribute, facets: [...prevFacets, ...facets] };
    }
  }

  return dispatch(setQuery({ ...query, filters: newFilters }));
};

const removeFilter = ({ attribute, facets }) => (dispatch, getState) => {
  if (!Array.isArray(facets)) facets = [facets];
  const query = reducerUtil.getSlice(searchData, searchData.query, getState()) || {};
  const { filters } = query;
  if (!filters) {
    return;
  }
  let newFilters;
  const index = filters.findIndex(({ attribute: name }) => name === attribute);
  const { facets: prevFacets } = filters[index];
  const newFacets = prevFacets.filter(name => name !== facets[0]);
  if (newFacets.length > 0) {
    newFilters = [...filters];
    newFilters[index] = { attribute, facets: newFacets };
  } else if (filters.length > 1) {
    newFilters = filters.filter((item, filterIndex) => filterIndex !== index);
  }

  return dispatch(setQuery({ ...query, filters: newFilters }));
};

const updateFilter = ({ attribute, facets }) => (dispatch, getState) => {
  if (!Array.isArray(facets)) facets = [facets];
  const query = reducerUtil.getSlice(searchData, searchData.query, getState()) || {};
  const { filters = [] } = query;
  let newFilters = [...filters];
  const index = filters.findIndex(({ attribute: name }) => name === attribute);

  if (index < 0) {
    newFilters.push({ attribute, facets });
  } else {
    newFilters[index] = { attribute, facets };
  }
  return dispatch(setQuery({ ...query, filters: newFilters }));
};

const clearAllFilters = () => (dispatch, getState) => {
  const query = reducerUtil.getSlice(searchData, searchData.query, getState()) || {};
  return dispatch(
    setQuery({
      ...query,
      filters: []
    })
  );
};

export default {
  setSearch,
  search,
  nextPage,
  setText,
  setSort,
  addFilter,
  removeFilter,
  updateFilter,
  clearAllFilters,
  redoSearch,
  clearSearch
};
