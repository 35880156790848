import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../SvgIcon';

const LinkBadIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M21.525,8.161l-0.707-0.707l-2.121,2.121l0.706,0.707c0.127,0.128,0.147,0.276,0.147,0.354
      c0,0.078-0.019,0.226-0.147,0.354l-3.536,3.537l2.121,2.12l3.536-3.535c0.661-0.661,1.025-1.54,1.025-2.475
      C22.55,9.701,22.186,8.823,21.525,8.161z"/>
    <path d="M16.575,3.212l-0.707-0.707c-1.365-1.363-3.585-1.364-4.949,0.001L7.383,6.041l2.121,2.122l3.536-3.536
      c0.195-0.194,0.512-0.195,0.707,0l0.707,0.706L16.575,3.212z"/>
    <path d="M5.262,14.526l-0.707-0.707c-0.195-0.196-0.196-0.513-0.001-0.707L8.09,9.576L5.969,7.456l-3.536,3.535
      c-1.364,1.364-1.364,3.584,0,4.95l0.707,0.707L5.262,14.526z"/>
    <path d="M14.454,15.94l-3.535,3.535c-0.127,0.127-0.277,0.147-0.354,0.147c0,0,0,0,0,0
      c-0.094,0-0.231-0.025-0.353-0.147l-0.708-0.707L7.383,20.89l0.707,0.707c0.661,0.662,1.539,1.026,2.475,1.026
      c0,0,0.001,0,0.001,0c0.935-0.001,1.813-0.364,2.474-1.025l3.535-3.537L14.454,15.94z"/>
      <rect x="15.429" y="2.101" transform="matrix(0.7069 0.7073 -0.7073 0.7069 9.9835 -9.8922)" width="3" height="10"/>
      <rect x="5.529" y="12.001" transform="matrix(0.707 0.7072 -0.7072 0.707 14.0823 0.0098)" width="3" height="10"/>
    <rect x="21.171" y="16" width="2.829" height="2"/>
    <rect x="15.929" y="21.243" width="2" height="2.757"/>
    <rect x="6.03" y="0" width="2" height="2.858"/>
    <rect x="0" y="6.102" width="2.787" height="2"/>
  </SvgIcon>
)

LinkBadIcon.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  viewBox: PropTypes.string.isRequired,
};

LinkBadIcon.defaultProps = {
  width: 16,
  height: 14,
  viewBox: '0 0 24 22',
};

export default LinkBadIcon;
