import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { actions as loginActions } from "login";

const Logout = ({ dispatch }) => {
  useEffect(() => {
    dispatch(loginActions.logout());
  }, []);

  return <div>Logging out...</div>;
};

Logout.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect()(Logout);
