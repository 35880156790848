export default {
  name: 'productDetails',
  productId: 'productId',
  assets: 'assets',
  assetList: 'assetList',
  values: 'values',
  information: 'information',
  elements: 'elements',
  status: 'status',
  jobs: 'jobs',
  fileList: 'fileList',
  options: 'options',
  selectedOption: 'selectedOption'
};
