import { connect } from 'react-redux';
import { reset } from 'redux-form';
import { reducerUtil } from 'base-client';

import Container from './container';
import { modalName } from 'productAttributes/utils';
import { reducerData as permissionsData } from 'permissions';
import { reducerData as attributesData, actions as attributeActions } from 'productAttributes';
import { actions as productActions } from 'productDetails';
import { form } from 'productAttributes/utils';
import { openModal } from 'modal/modal';

const mapStateToProps = state => {
  const managerPermission = reducerUtil.getSlice(permissionsData, permissionsData.manager, state);
  const adminPermission = reducerUtil.getSlice(permissionsData, permissionsData.admin, state);
  const attributeType = reducerUtil.getSlice(attributesData, attributesData.attributeTypes, state);
  const distributionType = reducerUtil.getSlice(
    attributesData,
    attributesData.distributionTypes,
    state
  );
  const attributes = reducerUtil.getSlice(attributesData, attributesData.attributes, state);
  const attributeData = reducerUtil.getSlice(attributesData, attributesData.attributeData, state);
  const products = reducerUtil.getSlice(attributesData, attributesData.products, state);
  return {
    canView: managerPermission && adminPermission,
    attributeType,
    distributionType,
    attributes,
    attributeData,
    products
  };
};

const mapDispatchToProps = dispatch => ({
  openModal: (open = true) => dispatch(openModal(modalName, open)),
  attributeActions: {
    get: () => dispatch(attributeActions.getAttributes()),
    products: key => dispatch(attributeActions.checkProducts(key)),
    update: key => dispatch(attributeActions.updateAttribute(key)),
    delete: key => dispatch(attributeActions.deleteAttribute(key)),
    add: (key, all, data) => dispatch(productActions.addAttribute(key, all, data)),
    remove: (key, all, data) => dispatch(productActions.removeAttribute(key, all, data))
  },
  reset: () => dispatch(reset(form))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Container);
