import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const SidebarNavigationItemView = ({
  eventKey,
  isActive,
  name,
  tooltip,
  push,
  onClick,
  url,
  isCollapse,
  toggleSidebar,
  children
}) => {
  const classNames = cn('sidebar-nav__item', {
    'sidebar-nav__item--active': isActive,
    'sidebar-nav__item--collapse': isCollapse
  });
  onClick = (!isCollapse && onClick) || (isCollapse ? () => toggleSidebar() : () => push(url));

  return (
    <div className={classNames} onClick={onClick}>
      {children}
      <span className="sidebar-nav__link">{name}</span>
      <span className="sidebar-nav__tooltip">{tooltip}</span>
    </div>
  );
};

SidebarNavigationItemView.propTypes = {
  isActive: PropTypes.bool,
  name: PropTypes.string.isRequired,
  tooltip: PropTypes.string.isRequired,
  push: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired
};

export default SidebarNavigationItemView;
