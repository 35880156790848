import React from 'react';
import styled from 'styled-components';

import CheckboxFilter from 'shared/Filters/components/CheckboxFilter';

export const Container = styled.div`
  flex: 0 0 20%;
  padding-right: 2rem;
  font-size: 1.4rem;
  &.filter-sticky {
    position: sticky;
    left: 0;
    top: 25px;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    max-height: calc(100vh - 35px);
  }
  .toggleBtn {
    cursor: pointer;
    font-size: 1.3rem;
    color: ${props => props.theme.purple1};
    &:hover,
    &:focus {
      color: ${props => props.theme.purple2};
    }
  }
`;

const Header = styled.h3`
  font-size: 1.4rem;
  margin: 0 0 2rem;
`;

const Filters = ({ filters, selected, defaultFilter, ...props }) => {
  const renderFilter = filters && filters.length > 0;

  const getCheckStatus = ({ selectedFacets, key, name }) => {
    return selectedFacets && selectedFacets.some(facet => facet === key || facet === name);
  };

  return (
    <Container className="filter">
      <Header>Refine By</Header>
      {renderFilter &&
        filters.map(filter => {
          if (filter.attribute === 'isActive') return null;
          const { name, attribute, facets, single_select_facet: isSingleSelect } = filter;
          const { facets: selectedFacets } =
            selected.find(({ attribute: selectedAttribute }) => selectedAttribute === attribute) ||
            {};
          const filterProps = {
            checkedAll:
              facets.length > 0 && selectedFacets && selectedFacets.length === facets.length,
            name,
            attribute,
            isSingleSelect,
            disabledSelectAll: true,
            showMore: {
              disabled: false,
              numberShow: 4
            },
            facets: facets
              .map(({ attribute: name, key, count }) => ({
                key,
                name,
                count,
                checked: getCheckStatus({
                  selectedFacets,
                  key,
                  name
                })
              }))
              .sort((a, b) =>
                a.checked && a.checked === b.checked ? b.count - a.count : a.checked ? -1 : 1
              )
          };

          return <CheckboxFilter key={attribute} {...filterProps} {...props} />;
        })}
    </Container>
  );
};

export default Filters;
