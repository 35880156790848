import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import sbManagerStyles, { graphColors } from 'utils/globalStyles';

import { Card } from 'shared-features-client';

const DonutCardWrapper = styled(Card)`
  color: ${() => sbManagerStyles.greyDarkest};
  height: 34rem;
  margin: 0 0 2rem 0;
  position: relative;
  padding-top: 1.5rem;
  text-align: center;
  position: relative;
  overflow: hidden;
`;

const Title = styled.h4`
  color: ${() => sbManagerStyles.greyDarkest};
  font-size: 1.8rem;
  font-weight: 500;
`;

const NoDataWrapper = styled.div`
  padding: 0 2rem;
  color: ${() => sbManagerStyles.greyMedium};
`;

const LegendStyles = {
  color: sbManagerStyles.greyDarkest,
  fontSize: '14px',
  fontWeight: '400',
  textOverflow: 'ellipsis',
  cursor: 'pointer'
};

const AnalyticsDonutCard = ({ title, data, containerWidth, containerHeight }) => {
  const options = {
    title: {
      text: ''
    },
    tooltip: {
      pointFormat: '{series.name}: <b>{point.percentage:.2f}%</b>'
    },
    credits: {
      enabled: false
    },
    legend: {
      itemStyle: LegendStyles
    },
    series: [
      {
        name: title,
        type: 'pie',
        data: data.map(({ name, count }) => ({ name, y: count })),
        size: '100%',
        innerSize: '65%',
        showInLegend: true,
        dataLabels: {
          enabled: false
        },
        colors: graphColors
      }
    ]
  };

  const containerProps = {
    style: { width: containerWidth, height: containerHeight - 45 },
    className: 'donut-card'
  };

  return (
    <DonutCardWrapper className="donut-card-wrapper">
      <Title>{title}</Title>
      {data && data.length > 0 && containerWidth && containerHeight ? (
        <HighchartsReact highcharts={Highcharts} {...{ options, containerProps }} />
      ) : (
        <NoDataWrapper className="donut-card">
          No data to display. Please try selecting different filters.
        </NoDataWrapper>
      )}
    </DonutCardWrapper>
  );
};

AnalyticsDonutCard.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      count: PropTypes.number
    })
  ),
  containerWidth: PropTypes.number,
  containerHeight: PropTypes.number
};

AnalyticsDonutCard.defaultProps = {
  data: []
};

export default AnalyticsDonutCard;
