import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from './../SvgIcon';

const SearchIcon = (props) => (
  <SvgIcon {...props}>
		<path d="M21.707,20.293L16.314,14.9A7.99533,7.99533,0,0,0,4.344,4.344,7.99482,7.99482,0,0,0,14.9,16.314l5.393,5.392ZM10,16a6.00048,6.00048,0,1,1,4.242-1.757A5.95892,5.95892,0,0,1,10,16Z"/>
  </SvgIcon>
)

SearchIcon.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  viewBox: PropTypes.string.isRequired,
};

SearchIcon.defaultProps = {
  width: 24,
  height: 24,
  viewBox: '0 0 24 24',
};

export default SearchIcon;