import React from 'react';
import styled from 'styled-components';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import sbManagerStyles, { graphColors } from 'utils/globalStyles';

const DonutCardWrapper = styled.div`
  background-color: #fff;
  color: ${() => sbManagerStyles.greyDarkest};
  margin: 0 2% 2rem 0;
  padding-top: 1.5rem;
  text-align: center;
  overflow: visible;
`;

const Title = styled.h4`
  color: ${() => sbManagerStyles.greyDarkest};
  font-size: 1.8rem;
  font-weight: 500;
`;

const NoDataWrapper = styled.div`
  padding: 0 2rem;
  color: ${() => sbManagerStyles.greyMedium};
`;

const LegendStyles = {
  color: sbManagerStyles.greyDarkest,
  fontSize: '14px',
  fontWeight: '400',
  textOverflow: 'ellipsis',
  cursor: 'pointer'
};

const Pie = ({ title, data }) => {
  const options = {
    title: {
      text: ''
    },
    credits: {
      enabled: false
    },
    legend: {
      itemStyle: LegendStyles,
      width: 240,
      height: '100%'
    },
    series: [
      {
        name: title,
        type: 'pie',
        data,
        size: '100%',
        innerSize: '65%',
        showInLegend: true,
        dataLabels: {
          enabled: false
        },
        colors: graphColors
      }
    ]
  };

  const containerProps = { className: 'donut-card' };

  return (
    <DonutCardWrapper className="donut-card-wrapper">
      <Title>{title}</Title>
      {data && data.length > 0 ? (
        <HighchartsReact highcharts={Highcharts} {...{ options, containerProps }} />
      ) : (
        <NoDataWrapper className="donut-card">
          No data to display. Please try selecting different filters.
        </NoDataWrapper>
      )}
    </DonutCardWrapper>
  );
};

export default Pie;
