/* vendor imports */
import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, ButtonToolbar } from 'react-bootstrap';

/* system imports */
import Button from 'shared/Buttons';

const Controls = ({ controls, confirm: { onClick, isDelete }, disabled }) => (
  <FormGroup className="AttributeContentControls">
    <ButtonToolbar>
      {!isDelete && (
        <Button buttonStyle="green" {...{ onClick, disabled }}>
          Confirm Save
        </Button>
      )}
      {isDelete && (
        <Button buttonStyle="red" {...{ onClick, disabled }}>
          Confirm Delete
        </Button>
      )}
      <Button buttonStyle="link" onClick={controls.resetAttribute} {...{ disabled }}>
        Cancel
      </Button>
    </ButtonToolbar>
  </FormGroup>
);

Controls.propTypes = {
  controls: PropTypes.shape({
    resetAttribute: PropTypes.func.isRequired
  }).isRequired,
  confirm: PropTypes.shape({
    onClick: PropTypes.func,
    isDelete: PropTypes.bool
  }).isRequired,
  disabled: PropTypes.bool
};

Controls.defaultProps = {
  disabled: false
};

export default Controls;
