import React from 'react';
import PropTypes from 'prop-types';

const CsvResult = ({ csvSuccess, headerList }) => {
  if (csvSuccess) {
    return (
      <div>
        Your CSV file is currently being imported. Check the updated products in a few minutes.
      </div>
    );
  }
  return (
    <div>
      <h4>
        We could not verify the data in your CSV file. Please check your CSV file and try again.
      </h4>
      {headerList && (
        <div className="mt-2">
          <h4 className="mb-2">Incorrect Header(s)</h4>
          <div>
            The following headers from your CSV files are missing. Please review your CSV file and
            try again.
          </div>
          {headerList.map((header, id) => <div key={id}>{`- ${header}`}</div>)}
        </div>
      )}
    </div>
  );
};

CsvResult.propTypes = {
  csvSuccess: PropTypes.bool
};

export default CsvResult;
