import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { InfiniteLoader, AutoSizer, Table, Column, WindowScroller } from 'react-virtualized';
import 'react-virtualized/styles.css';

import { LinkButton } from 'shared/Buttons';
import { TABLE_HEADER_HEIGHT, TABLE_ROW_HEIGHT } from 'utils/globalStyles';

const Container = styled.div`
  height: 100%;
  flex: 1;
  min-height: calc(100vh - 28rem);
`;

const DirectMessagesTable = ({ data, pagination, getNextPage, fetchId }) => {
  const rowGetter = ({ index }) => data[index] || {};

  const length = (data && data.length) || 0;

  let hasNextPage = false;
  if (pagination) {
    const { page, pageCount } = pagination;
    hasNextPage = page < pageCount;
  }

  const rowCount = hasNextPage ? length + 1 : length + 0;
  const isRowLoaded = ({ index }) => !hasNextPage || index < length;
  const loadMoreRows = fetchId ? () => null : getNextPage;

  const nameRenderer = ({ rowData }) => {
    const { userId, name } = rowData;
    return !userId ? name : <LinkButton to={`/insights/contacts/${userId}`}>{name}</LinkButton>;
  };

  return (
    <Container>
      <InfiniteLoader {...{ rowCount, loadMoreRows, isRowLoaded }}>
        {({ onRowsRendered, registerChild }) => (
          <WindowScroller>
            {({ height, isScrolling, scrollTop }) => (
              <AutoSizer disableHeight>
                {({ width }) => (
                  <Table
                    autoHeight={true}
                    ref={registerChild}
                    onRowsRendered={onRowsRendered}
                    isScrolling={isScrolling}
                    scrollTop={scrollTop}
                    height={height}
                    width={width}
                    headerHeight={TABLE_HEADER_HEIGHT}
                    rowHeight={TABLE_ROW_HEIGHT}
                    rowCount={length}
                    rowGetter={rowGetter}
                    headerClassName="table-header"
                    rowClassName="table-row"
                    overscanRowCount={10}
                    gridClassName="table__visible-items"
                    scrollToAlignment="start"
                  >
                    <Column width={200} label="Date" dataKey="datetime" flexGrow={0} />
                    <Column width={250} label="Action" dataKey="messageType" flexGrow={0} />
                    <Column width={250} label="Requested By" dataKey="fromAddress" flexGrow={0} />
                    <Column
                      width={200}
                      label="Name"
                      dataKey="name"
                      flexGrow={0}
                      cellRenderer={nameRenderer}
                    />
                    <Column width={400} label="Message Preview" dataKey="message" flexGrow={1} />
                  </Table>
                )}
              </AutoSizer>
            )}
          </WindowScroller>
        )}
      </InfiniteLoader>
    </Container>
  );
};

DirectMessagesTable.propTypes = {
  data: PropTypes.array,
  pagination: PropTypes.object,
  getNextPage: PropTypes.func,
  fetchId: PropTypes.string
};

DirectMessagesTable.defaultProps = {
  data: []
};

export default DirectMessagesTable;
