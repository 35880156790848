/* vendor imports */
import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

/* system imports */
import ModalContainer from 'shared/Overlays/Modal';
import Button from 'shared/Buttons';
import { formUserEditName } from 'admin/utils';
import chooseForm from 'shared/Forms/redux-form';

const EditUserModal = ModalContainer(formUserEditName);

const EditUserModalHeader = title => <Modal.Title>{title}</Modal.Title>;

const EditUserView = ({ roles, handleSubmit, clickEditUser, title }) => (
  <EditUserModal header={EditUserModalHeader(title)}>
    <form onSubmit={handleSubmit}>
      {chooseForm({
        key: 'role',
        title: 'Role',
        type: 'select',
        isLocked: false,
        options: [...roles].map(role => ({ key: role.role }))
      })}
      {chooseForm({
        key: 'active',
        title: 'Status',
        type: 'select',
        isLocked: false,
        options: [{ key: 'Active' }, { key: 'Inactive' }]
      })}
    </form>
    <Button onClick={clickEditUser}>Update User</Button>
  </EditUserModal>
);

EditUserView.propTypes = {
  clickEditUser: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  roles: PropTypes.array,
  title: PropTypes.string
};

EditUserView.defaultProps = {
  roles: [],
  title: null
};

export default EditUserView;
