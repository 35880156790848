import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { reducerUtil } from 'base-client';
import useDebounce from 'utils/useDebounce';
import SearchBarComponent from 'shared/Searchbar';

import { actions, reducerData } from 'leadsTwo';

const SearchBar = ({ dispatch, query }) => {
  const [text, setText] = useState(query && query.queryString);
  const [prevText, setPrevText] = useState();

  const debouncedText = useDebounce(text, 1000);
  useEffect(() => {
    if (text !== prevText) {
      dispatch(actions.setQueryString(debouncedText));
    }
  }, [debouncedText, prevText]);

  useEffect(() => {
    if (!query || query === {}) {
      setText();
      return;
    }
    const { queryString } = query;
    if (!!queryString === !!prevText) return;
    setText(query.queryString);
    setPrevText(query.queryString);
  }, [query, prevText]);

  const handleChange = value => {
    setText(value);
  };

  const searchProps = {
    handleSearch: value => handleChange(value),
    searchValue: text,
    placeholder: 'Search User Profiles',
    style: { width: '100%' }
  };

  return <SearchBarComponent {...searchProps} />;
};

const mapStateToProps = state => ({
  query: reducerUtil.getSlice(reducerData, reducerData.query, state)
});

export default connect(mapStateToProps)(SearchBar);
