import React from 'react';
import PropTypes from 'prop-types';

import Card from 'shared/PageLayout/Card';
import HeaderControls from './HeaderControls';

const ControlsCard = ({ headerProps, children }) => {
  const { stream, subtitle, optionsStream } = headerProps;
  return (
    <Card
      title={stream || optionsStream[0]}
      subtitle={subtitle || ''}
      headerControls={<HeaderControls {...headerProps} />}
      initialStream={stream || optionsStream[0]}
      headerBorder
    >
      {children}
    </Card>
  );
};

ControlsCard.propTypes = {
  headerProps: PropTypes.object,
  children: PropTypes.any
};
ControlsCard.defaultProps = {
  headerProps: {}
};

export default ControlsCard;
