/* vendor imports */
import React from 'react';
import PropTypes from 'prop-types';

/* system imports */
import Button from '../../Button';
import AddCircleIcon from '../../../../Miscellaneous/Icons/Action/AddCircleIcon';
import AddIcon from '../../../../Miscellaneous/Icons/Action/AddIcon';

const AddButton = ({ children, iconPosition, icon, border, ...other }) => (
  <Button
    buttonStyle={border ? 'green' : 'link-green'}
    icon={icon === 'addIcon' ? <AddIcon /> : <AddCircleIcon />}
    iconPosition={iconPosition}
    {...other}
  >
    {children}
  </Button>
);

AddButton.propTypes = {
  children: PropTypes.node,
  iconPosition: PropTypes.string
};

AddButton.defaultProps = {
  children: null,
  iconPosition: 'before'
};

export default AddButton;
