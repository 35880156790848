import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../SvgIcon';

const MessagesIcon = props => (
  <SvgIcon {...props}>
    <path id="shadow" className="color--light" d="M12 21.2159C18.6274 21.2159 24 20.3205 24 19.2159C24 18.1113 18.6274 17.2159 12 17.2159C5.37258 17.2159 0 18.1113 0 19.2159C0 20.3205 5.37258 21.2159 12 21.2159Z" />
    <g className="icon--inactive">
      <path className="color--medium" d="M6 20.2159V16.2159H4C2.897 16.2159 2 15.3189 2 14.2159V2.21588C2 1.11188 2.897 0.215881 4 0.215881H20C21.103 0.215881 22 1.11188 22 2.21588V14.2159C22 15.3189 21.103 16.2159 20 16.2159H11.333L6 20.2159ZM4 2.21588V14.2159H8V16.2159L10.667 14.2159H20V2.21588H4Z" />
      <path className="color--medium" d="M18 4.21588H6V6.21588H18V4.21588Z" />
      <path className="color--medium" d="M18 7.21588H6V9.21588H18V7.21588Z" />
      <path className="color--medium" d="M14 10.2159H6V12.2159H14V10.2159Z" />
    </g>
    <g className="icon--active">
      <rect className="color--medium" x="4" y="1.21588" width="15" height="9" />
      <path className="color--dark" d="M20 0.215881H4C2.896 0.215881 2 1.11088 2 2.21588V14.2159C2 15.3199 2.896 16.2159 4 16.2159H5V20.2159L9 16.2159H19.999C21.104 16.2159 21.999 15.3199 21.999 14.2159V2.21588C22 1.11088 21.105 0.215881 20 0.215881ZM13 13.2159H6V11.2159H13V13.2159ZM18 9.21588H6V7.21588H18V9.21588ZM18 5.21588H6V3.21588H18V5.21588Z" />
    </g>
  </SvgIcon>
);

MessagesIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

MessagesIcon.defaultProps = {
  width: 22,
  height: 22
};

export default MessagesIcon;
