import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { reducerUtil } from 'base-client';
import PropTypes from 'prop-types';

import {sendNotifications} from '../../shared/Toast';
import Button from 'shared/Buttons';
import Card from './Card';
import Header from './Header';
import PasswordInput from './PasswordInput';

const getParams = () => {
  const params = new URL(decodeURIComponent(window.location.href)).searchParams;

  const auth0Id = params.get('auth0Id');
  const username = params.get('username');

  return { auth0Id, username };
};

const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/;

const PasswordReset = ({ displayError, onClickPasswordReset }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');

  const checkPasswordReqs = () => {
    if (!regex.test(password)) {
      setError('Password does not meet requirement of an uppercase, lowercase, and number.');
    } else if (password !== confirmPassword) {
      setError('Passwords do not match');
    } else {
      onClickPasswordReset({ ...getParams(), password });
    }
  };

  useEffect(() => {
    if (error && error.length) {
      displayError(error);
      setTimeout(function() {
        setError('');
      }, 3000);
    }
  }, [error]);

  return (
    <Card title="Reset Password">
      <p className="state--neutral">
        Please enter a new password with at least 8 characters. Must contain both upper and
        lowercase letters, and minimum one number.
      </p>
      <Header>New Password</Header>
      <PasswordInput
        className="input--text"
        type="password"
        placeholder="Enter your new password"
        value={password || ''}
        onChange={e => setPassword(e.target.value)}
        showPassword={showPassword}
        setShowPassword={setShowPassword}
      />
      <Header>Confirm Password</Header>
      <PasswordInput
        className="input--text"
        type="password"
        placeholder="Confirm your new password"
        value={confirmPassword || ''}
        onChange={e => setConfirmPassword(e.target.value)}
        showPassword={showPassword}
        setShowPassword={setShowPassword}
      />
      <Button buttonStyle="create" onClick={checkPasswordReqs}>
        Reset Password
      </Button>
    </Card>
  );
};

const mapDispatchToProps = dispatch => ({
  displayError: error => {
    dispatch(
      sendNotifications(
        [
          {
            type: 'error',
            message: error
          }
        ],
        reducerUtil
      )
    );
  }
});

PasswordReset.propTypes = {
  setUser: PropTypes.func.isRequired,
  onClickBack: PropTypes.func.isRequired,
  onClickReset: PropTypes.func.isRequired,
  invalid: PropTypes.object.isRequired,
  user: PropTypes.string,
  message: PropTypes.object
};

export default connect(null, mapDispatchToProps)(PasswordReset);
