export const config = {
  chart: {
    type: 'bar'
  },
  title: {
    text: ''
  },
  credits: {
    enabled: false
  },
  xAxis: {
    categories: [],
    title: {
      text: null
    }
  },
  yAxis: {
    min: 0,
    title: {
      text: 'Percentage',
      align: 'high'
    },
    labels: {
      overflow: 'justify',
      valueSuffix: ' %'
    }
  },
  tooltip: {
    valueSuffix: ' %'
  },
  plotOptions: {
    bar: {
      dataLabels: {
        enabled: true,
        formatter: function() {
          return this.y + '%';
        }
      }
    },
    series: {
      pointWidth: 25,
      pointPadding: 0
    }
  },
  series: [
    {
      name: 'Percentage',
      data: [],
      showInLegend: false
    }
  ]
};

export default config;
