import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;
const Callout = styled.div`
  width: 80%;
  font-size: 1.4rem;
  font-weight: 400;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
  margin-bottom: 2rem;
`;
const NoDataCallout = ({ dataTitle, flavor }) => {
  return (
    <Container>
      <Callout>{`There are no current ${dataTitle} for this user. `}</Callout>
      <Callout>{flavor}</Callout>
    </Container>
  );
};

export default NoDataCallout;
