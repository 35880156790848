import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Column, Table, SortIndicator } from 'react-virtualized';

import { utils as leadsUtils } from 'leads';
import { PageHeader } from 'shared';
import Button from 'shared/Buttons';
import Card from 'shared/Insights/Card';
import { Rows } from 'shared/TablesAndLists';

import { InfiniteList } from 'shared-features-client';

const StyledTable = styled.div`
  min-height: calc(100vh - 21rem);
`;

const LeadTableView = ({
  title,
  fetchId,
  table = [],
  tableColumns,
  sort,
  sortBy,
  sortDirection,
  onDownloadClick,
  optionsStream,
  isInternalHeader,
  ...props
}) => {
  const headerRenderer = ({ label, dataKey, sortBy, sortDirection }) => (
    <div>
      {label}
      {sortBy === dataKey && <SortIndicator {...{ sortDirection }} />}
    </div>
  );

  const cardProps = {
    ...props,
    optionsStream: optionsStream || leadsUtils.leadStream
  };

  const infiniteProps = {
    list: [...table],
    hasNextPage: false,
    fetching: false,
    getNextPage: () => {
      return null;
    }
  };

  const length = (table && table.length) || 0;

  return (
    <React.Fragment>
      {!isInternalHeader && <PageHeader className="lead-table-header" title={title} />}
      <Card
        {...cardProps}
        controlButtons={
          <Button
            onClick={onDownloadClick}
            type="button"
            buttonStyle="card"
            style={{ marginRight: '1rem' }}
          >
            Export Leads
          </Button>
        }
      >
        <StyledTable>
          <InfiniteList {...infiniteProps}>
            <Table
              headerHeight={55}
              headerClassName="table-header"
              rowClassName="table-row"
              rowHeight={50}
              rowCount={length}
              sort={sort}
              sortBy={sortBy}
              sortDirection={sortDirection}
              rowGetter={({ index }) => table[index]}
              height={length * 50}
              width={0}
            >
              {tableColumns &&
                tableColumns.map(column => (
                  <Column
                    key={column.dataKey}
                    cellRenderer={Rows.Link}
                    dataKey={column.dataKey}
                    label={column.label}
                    headerRenderer={headerRenderer}
                    width={200}
                    flexGrow={2}
                  />
                ))}
            </Table>
          </InfiniteList>
        </StyledTable>
      </Card>
    </React.Fragment>
  );
};

LeadTableView.propTypes = {
  table: PropTypes.array,
  sortBy: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  sort: PropTypes.func.isRequired,
  onDownloadClick: PropTypes.func.isRequired
};

LeadTableView.defaultProps = {};

export default LeadTableView;
