import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { PartialCheckbox } from "shared/InputGroups/Checkbox";
import Button from "shared/Buttons";

import { reducerUtil } from "base-client";
import { actions as listActions, reducerData as listData } from "leadsTwo";

const SelectControls = ({ isLocked, checkState, ...props }) => {
  return (
    <div>
      {props.isButton ? (
        <Button buttonStyle="select" onClick={props.onChange}>
          <PartialCheckbox
            isLocked={checkState === -1 && isLocked}
            checkState={checkState}
            {...props}
          />
        </Button>
      ) : (
        <PartialCheckbox
          isLocked={checkState === -1 && isLocked}
          {...props}
          checkState={checkState}
        />
      )}
    </div>
  );
};

SelectControls.propTypes = {
  isButton: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

SelectControls.defaultProps = {
  isButton: false,
};

const mapStateToProps = (state) => {
  const selected = reducerUtil.getSlice(listData, listData.selected, state) || [];
  const userList = reducerUtil.getSlice(listData, listData.list, state) || [];
  let checkState = PartialCheckbox.checkRules.unchecked;
  if (userList.length > 0 && selected.length === userList.length)
    checkState = PartialCheckbox.checkRules.checked;
  else if (selected.length > 0) checkState = PartialCheckbox.checkRules.partial;
  return { checkState };
};

const mapDispatchToProps = (dispatch) => ({
  onChange: () => dispatch(listActions.selectAll()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectControls);
