import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../SvgIcon';

const DownloadAnalyticsIcon = (props) => (
  <SvgIcon {...props}>
		<path d="M19,8.001h-1v2h1v5H5v-5h1v-2H5c-1.104,0-2,0.896-2,2v9c0,1.102,0.896,2,2,2h6v1H8v2h8v-2h-3v-1h6 c1.104,0,2-0.898,2-2v-9C21,8.897,20.104,8.001,19,8.001z M5,19.001v-2h14v2H5z"/>
		<polygon points="16.707,7.708 15.293,6.294 13,8.587 13,0.001 11,0.001 11,8.587 8.707,6.294 7.293,7.708 12,12.415 "/>
  </SvgIcon>
)

DownloadAnalyticsIcon.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  viewBox: PropTypes.string.isRequired,
};

DownloadAnalyticsIcon.defaultProps = {
  width: 24,
  height: 24,
  viewBox: '0 0 24 24',
};

export default DownloadAnalyticsIcon;
