import { api, config, reducerUtil } from 'base-client';

import { reducerData as tenantData } from 'tenant';
import { configMap } from 'configurations';

const checkTenant = () => async dispatch => {
  const disabled = dispatch(
    config.actions.getData(configMap.disabled.name, configMap.disabled.tenant.name)
  );

  if (disabled) return;

  try {
    const result = await dispatch(api.actions.get('tenantAdmin/tenant'));
    const { tenant_id: tenant } = result[0];
    await dispatch(getTenantAccess(tenant));
  } catch (error) {
    dispatch(config.actions.error(error));
  }
};

const getTenantAccess = tenantId => async dispatch => {
  try {
    const response = await dispatch(api.actions.get(`brandedSites/id/${tenantId}`));
    if (!response) {
      dispatch(reducerUtil.setSlice(tenantData, tenantData.tenant, tenantId));
    } else {
      const { tenant_id: tenant, settings } = response;
      const { emailRecipients, projectSettings, apiEnabled } = settings || {};

      const projectsDisabledFlag = dispatch(
        config.actions.getData(configMap.disabled.name, configMap.disabled.projects.name)
      );
      const { showSubmittal } = projectSettings || {};

      const enableFeatures = {
        messages: !!emailRecipients,
        projects: !projectsDisabledFlag && showSubmittal,
        api: !!apiEnabled,
      };

      const results = {
        tenant,
        enableFeatures
      };
      dispatch(reducerUtil.setSlice(tenantData, tenantData.tenant, tenant));
      dispatch(reducerUtil.setSlice(tenantData, tenantData.enableFeatures, enableFeatures));
      return results;
    }
  } catch (error) {
    if (error.status === 302) {
      dispatch(reducerUtil.setSlice(tenantData, tenantData.tenant, tenantId));
      return;
    }

    dispatch(config.actions.error(error));
  }
};

export default { checkTenant, getTenantAccess };
