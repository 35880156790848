import React from 'react';
import styled from 'styled-components';

import sbManagerStyles from 'utils/globalStyles';

const Wrapper = styled.div`
  padding: ${props => (props.top ? '2rem 2rem 0 2rem' : null)};
`;

const Title = styled.h4`
  color: ${sbManagerStyles.greyMedium};
  font-size: 1.1rem;
  line-height: 1.6;
  text-transform: uppercase;
`;

const Content = styled.p`
  color: ${sbManagerStyles.greyDarkest};
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2.5rem;
`;
  
const Suffix = styled.span`
  font-size: 1.4rem;
`;
  
const Prefix = styled.span`
  font-size: 1.4rem;
`;

const ProjectInfoCard = ({ title, content, prefix, suffix, defaultValue, ...props }) => {
  return (
    <div>
      <Wrapper {...props}>
        <Title>{title}</Title>
        <Content>
          <Prefix>{content ? prefix : null}</Prefix>
          {content || defaultValue} <Suffix>{content ? suffix : null}</Suffix>
        </Content>
      </Wrapper>
    </div>
  );
};

export default ProjectInfoCard;
