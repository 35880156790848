import React from 'react';
import styled from 'styled-components';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import PropTypes from 'prop-types';

import sbManagerStyles, { graphColors } from 'utils/globalStyles';

const BarChartContainer = styled.div`
  background-color: ${() => sbManagerStyles.white};
  padding: 1.5rem 3rem 3rem;
  color: ${() => sbManagerStyles.greyDarkest};
  height: 30rem;
  width: 100%;
  .bar-card {
    height: 30rem;
  }
  .highcharts-container,
  .highcharts-container svg {
    width: 100% !important;
  }
`;

const Title = styled.h4`
  color: ${() => sbManagerStyles.greyDarkest};
  font-size: 1.8rem;
  font-weight: 500;
  text-align: center;
  margin-bottom: 2rem;
`;

const NoDataWrapper = styled.div`
  padding: 0 2rem;
  color: ${() => sbManagerStyles.greyMedium};
  display: inline-flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
`;

const Bar = ({ title, data }) => {
  const options = {
    chart: {
      type: 'bar'
    },
    colors: graphColors,
    credits: {
      enabled: false
    },
    legend: {
      enabled: false
    },
    title: {
      text: ''
    },
    xAxis: {
      categories: data.map(({ name }) => name),
      tickLength: 0
    },
    yAxis: {
      allowDecimals: false,
      min: 0,
      tickWidth: 4,
      tickColor: sbManagerStyles.greyDark,
      tickLength: 6,
      title: {
        text: null
      }
    },
    tooltip: {
      formatter: function() {
        return `Downloads by <strong>${this.x}</strong>: <strong>${this.y}</strong>`;
      }
    },
    plotOptions: {
      series: {
        stacking: 'normal'
      }
    },
    series: [
      {
        data: data.map(({ y }) => y)
      }
    ]
  };

  const containerProps = { className: 'bar-card' };

  return (
    <BarChartContainer className="bar-card-wrapper">
      <Title>{title}</Title>
      {data && data.length > 0 ? (
        <HighchartsReact highcharts={Highcharts} {...{ options, containerProps }} />
      ) : (
        <NoDataWrapper className="bar-card">
          No data to display. Please try selecting different filters.
        </NoDataWrapper>
      )}
    </BarChartContainer>
  );
};

Bar.propTypes = {
  data: PropTypes.array
};

Bar.defaultProps = {
  data: []
};

export default Bar;
