import uuid from 'uuid/v4';
import { api, config, reducerUtil } from 'base-client';

import timeUtil from 'utils/newTimeUtil';
import { reducerData as messagesData, Message } from 'directMessages';
import { messageTypes } from 'directMessages/utils';

const getMessages = ({ startDate, endDate, userId, messageType, page, limit }, key) => async (
  dispatch,
  getState
) => {
  //notify start fetching
  const fetchId = uuid();
  dispatch(reducerUtil.setSlice(messagesData, messagesData.fetchId, fetchId));

  let filters = [];

  if (startDate) {
    filters.push(`filter[created_at][start]=${startDate.toISOString()}`);
  }

  if (endDate) {
    filters.push(`filter[created_at][end]=${endDate.toISOString()}`);
  }

  if (userId) {
    filters.push(`filter[user_id][eq]=${userId}`);
  }

  if (messageType) {
    filters.push(`filter[email_type][eq]=${messageType}`);
  }

  if (page) {
    filters.push(`page=${page}`);
  }

  if (limit) {
    filters.push(`limit=${limit}`);
  } else {
    filters.push('limit=100');
  }

  filters.push('order[created_at]=desc');

  try {
    const messages = await dispatch(api.actions.get(`users/email?${filters.join('&')}`));

    const state = getState();
    if (fetchId !== reducerUtil.getSlice(messagesData, messagesData.fetchId, state)) return;

    const { emails } = messages || {};
    const formattedEmails = formatEmails(emails);

    // get current list if not restarting
    if (page > 1) {
      const currMessages =
        reducerUtil.getSlice(messagesData, key || messagesData.messages, state) || {};
      const { emails: currEmails } = currMessages || [];
      dispatch(
        reducerUtil.setSlice(messagesData, key || messagesData.messages, {
          ...messages,
          emails: [...currEmails, ...formattedEmails]
        })
      );
    } else {
      dispatch(
        reducerUtil.setSlice(messagesData, key || messagesData.messages, {
          ...messages,
          emails: formattedEmails
        })
      );
    }
    // allow another fetching
    dispatch(reducerUtil.setSlice(messagesData, messagesData.fetchId, undefined));
  } catch (error) {
    dispatch(config.actions.error(error));
  }
};

const displayEmailType = emailType => {
  for (let i = 0; i < messageTypes.length; i++) {
    let mt = messageTypes[i];
    if (mt.id === emailType) {
      return mt.label;
    }
  }
};

const formatEmails = emails => {
  if (!emails) return null;

  return emails.map(
    e =>
      new Message({
        id: e.id,
        name: `${e.metadata.firstName} ${e.metadata.lastName}`,
        fromAddress: `${e.from_address}`,
        message: e.metadata.message,
        messageType: displayEmailType(e.email_type),
        datetime: timeUtil.toMoment(e.created_at).format('M/D/YY, h:mma'),
        userId: e.user_id,
        productName: e.metadata.productName,
        productId: e.product_id,
        projectName: e.metadata.projectName,
        projectValue: e.metadata.value,
        projectSegment: e.metadata.segment,
        projectLocation: e.metadata.projectLocation,
        projectStage: e.metadata.stage,
        productCountNeeded: e.metadata.productCount
      })
  );
};

export default { getMessages };
