/* vendor imports */
import React from 'react';
import PropTypes from 'prop-types';

/* system imports */
import Button from '../../../../Buttons';

const Manual = ({ setName, createProduct, name }) => (
  <div>
    <h4 className="mb-1">Name the Product</h4>
    <div className="form-group input--block">
      <input className="form-control" type="text" value={name} onChange={setName} />
    </div>
    <Button buttonStyle="create" onClick={createProduct}>
      Create Product
    </Button>
  </div>
);

Manual.propTypes = {
  setName: PropTypes.func.isRequired,
  createProduct: PropTypes.func.isRequired,
  name: PropTypes.string
};

Manual.defaultProps = {
  name: ''
};

export default Manual;
