import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { actions as loginActions } from 'login';

const GoogleLogin = ({ dispatch }) => {
  useEffect(() => {
    dispatch(loginActions.googleLogin());
  }, []);

  return null;
};

GoogleLogin.propTypes = {
  dispatch: PropTypes.func.isRequired
};

export default connect()(GoogleLogin);
