import { api, config, reducerUtil } from 'base-client';

import { reducerData as jobsData } from 'jobs';
import { urlFileDownload } from 'utils/fileDownload';

const maxNotifications = 10;

const fixStatus = {
  inprogress: 'In Progress',
  completed: 'Completed',
  failed: 'Failed',
  ready: 'Ready',
  cancelled: 'Canceled',
  retry: 'Retrying'
};

const jobNames = [
  'Publish',
  'Import Revit',
  'Export Assets',
  'Export Data',
  'Update Revit',
  'Unpublish',
  'Send Email',
  'Import Products',
  'Import Shared Parameters'
];

const pending = [fixStatus.ready, fixStatus.retry, fixStatus.inprogress];

const oneSecond = 1000;
const delay = 20 * oneSecond;
const pollDelay = 5 * oneSecond;
let timer;

const getJobs = () => async dispatch => {
  if (timer) clearTimeout(timer);
  try {
    await dispatch(getJobsList());
    timer = setTimeout(() => dispatch(getJobs()), delay);
  } catch {
    if (timer) clearTimeout(timer);
  }
};

const getJobsList = () => async dispatch => {
  try {
    const result = await dispatch(
      api.actions.get(
        `jobs?limit=20&order[created_at]=desc&filter[status][in]=${Object.keys(fixStatus).join(
          ','
        )}&filter[name][in]=${jobNames.join(',')}`
      )
    );
    const { jobs } = result || {};
    if (jobs) {
      const list = jobs.map(
        ({ id, name, status, external_id, created_at, updated_at, JobStages: stages }) => {
          const result = stages && stages[stages.length - 1].results;
          return {
            id,
            name,
            filename: typeof result === 'string' ? result : undefined,
            status: fixStatus[status],
            created: created_at,
            updated: updated_at,
            productId: external_id
          };
        }
      );
      dispatch(addNotifications(list));
      dispatch(reducerUtil.setSlice(jobsData, jobsData.list, list));
    }
  } catch (error) {
    dispatch(config.actions.error(error));
    throw error;
  }
};

const addNotifications = newList => (dispatch, getState) => {
  const state = getState();
  const list = reducerUtil.getSlice(jobsData, jobsData.list, state);
  const notifications = reducerUtil.getSlice(jobsData, jobsData.notifications, state);

  const newIds = newList.filter(({ id, status }) => {
    if (list) return !list.find(item => item.id === id);
    return pending.includes(status);
  });

  if (newIds.length <= 0) return;

  const newNotifications = newIds
    .concat(notifications || [])
    .filter((id, index, array) => array.indexOf(id) === index)
    .slice(0, maxNotifications);

  dispatch(reducerUtil.setSlice(jobsData, jobsData.notifications, newNotifications));
};

const cancelJob = id => async dispatch => {
  try {
    await dispatch(api.actions.post(`jobs/${id}/cancel`, JSON.stringify({})));
    return dispatch(getJobsList());
  } catch (error) {
    dispatch(config.actions.error(error));
  }
};

const retryJob = id => async dispatch => {
  try {
    await dispatch(api.actions.post(`jobs/${id}/retry`, JSON.stringify({})));
    return dispatch(getJobsList());
  } catch (error) {
    dispatch(config.actions.error(error));
  }
};

const pollJob = (id, callback) => dispatch => {
  const pollTimer = setTimeout(async () => {
    try {
      const { job } = await dispatch(api.actions.get(`jobs/${id}`));
      clearTimeout(pollTimer);
      if (job.status === 'completed') dispatch(callback(job));
      else if (job.status !== 'failed') dispatch(pollJob(id, callback));
    } catch (error) {
      dispatch(config.actions.error(error));
      clearTimeout(pollTimer);
      dispatch(pollJob(id, callback));
    }
  }, pollDelay);
};

const downloadJob = job => dispatch => {
  const filename = job.JobStages[job.JobStages.length - 1].results;
  return dispatch(downloadFile(filename));
};

const downloadFile = filename => async dispatch => {
  try {
    const result = await dispatch(api.actions.get(`bulk/results/${filename}`));
    urlFileDownload(result, filename);
  } catch (error) {
    dispatch(config.actions.error(error));
  }
};

export default {
  getJobs,
  cancelJob,
  retryJob,
  pollJob,
  downloadJob,
  downloadFile
};
