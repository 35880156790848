import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../SvgIcon';

const CollapseIcon = (props) => (
  <SvgIcon {...props}>
    <g className="icon--inactive">
      <path className="color--medium" d="M17,11.1H5.4L7,9.5c0.4-0.4,0.4-1,0-1.4l0,0c-0.4-0.4-1-0.4-1.4,0l-3.3,3.3c-0.4,0.4-0.4,1,0,1.4l3.3,3.3
        c0.4,0.4,1,0.4,1.4,0h0c0.4-0.4,0.4-1,0-1.4l-1.6-1.6H17c0.6,0,1-0.4,1-1v0C18,11.5,17.6,11.1,17,11.1z"/>
      <path className="color--medium" d="M21,20.1L21,20.1c0.6,0,1-0.4,1-1v-14c0-0.6-0.4-1-1-1h0c-0.6,0-1,0.4-1,1v14C20,19.6,20.4,20.1,21,20.1z"
        />
    </g>
  </SvgIcon>
)

CollapseIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

CollapseIcon.defaultProps = {
  width: 22,
  height: 22
};

export default CollapseIcon;
