import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from './../SvgIcon';

const StarIcon = (props) => (
  <SvgIcon {...props}>
		<path d="M21.924,8.618C21.77,8.245,21.404,8.001,21,8.001h-5.382l-2.724-5.447c-0.339-0.677-1.45-0.677-1.789,0 L8.382,8.001H3c-0.404,0-0.77,0.244-0.924,0.617c-0.154,0.373-0.069,0.805,0.217,1.09l4.584,4.584l-1.838,6.435 c-0.115,0.403,0.033,0.835,0.371,1.082c0.34,0.248,0.797,0.257,1.144,0.024L12,18.203l5.445,3.63 c0.168,0.112,0.361,0.168,0.555,0.168c0.207,0,0.414-0.064,0.59-0.192c0.338-0.247,0.486-0.679,0.371-1.082l-1.838-6.435 l4.584-4.584C21.993,9.423,22.078,8.991,21.924,8.618z"/>
  </SvgIcon>
)

StarIcon.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  viewBox: PropTypes.string.isRequired,
};

StarIcon.defaultProps = {
  width: 24,
  height: 24,
  viewBox: '0 0 24 24',
};

export default StarIcon;