import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { SortDirection } from 'react-virtualized';

import { EVENT_TYPE_KEYS } from 'eventsLog/utils';
import DownloadsTable from './DownloadsTable';
import PageViewsTable from './PageViewsTable';
import ProductViewsTable from './ProductViewsTable';
import ReferralsTable from './ReferralsTable';
import SearchesTable from './SearchesTable';
import SubmittalsTable from './SubmittalsTable';
import BrochuresTable from './BrochuresTable';

import { actions as eventsLogActions } from 'eventsLog';

import { InfiniteList } from 'shared-features-client';

const Container = styled.div`
  flex: 0 0 86%;
  margin-top: 0;
  min-height: calc(100vh - 21rem);
`;

const EventsLogView = ({
  list,
  meta,
  fetchId,
  sortBy,
  sortDir,
  setSort,
  eventType,
  dispatch,
  ...rest
}) => {
  const length = (list && list.length) || 0;

  let hasNextPage = false;
  if (meta) {
    const {
      pagination: { page, pageCount }
    } = meta;
    hasNextPage = page < pageCount;
  }

  const rowCount = hasNextPage ? length + 1 : length + 0;

  const getTable = props => {
    switch (eventType) {
      case EVENT_TYPE_KEYS.DOWNLOAD:
        return <DownloadsTable {...props} />;
      case EVENT_TYPE_KEYS.SEARCH:
        return <SearchesTable {...props} />;
      case EVENT_TYPE_KEYS.PRODUCT_VIEW:
        return <ProductViewsTable {...props} />;
      case EVENT_TYPE_KEYS.PAGE_VIEW:
        return <PageViewsTable {...props} />;
      case EVENT_TYPE_KEYS.REFERRAL:
        return <ReferralsTable {...props} />;
      case EVENT_TYPE_KEYS.SUBMITTAL:
        return <SubmittalsTable {...props} />;
      case EVENT_TYPE_KEYS.BROCHURE:
        return <BrochuresTable {...props} />;
      default:
        break;
    }
  };

  const tableProps = {
    rowCount,
    data: [...list],
    sortBy,
    sortDirection: sortDir === 'asc' ? SortDirection.ASC : SortDirection.DESC,
    sort: ({ sortBy, sortDirection }) =>
      dispatch(eventsLogActions.setSort({ sortBy, sortDir: sortDirection.toLowerCase() })),
    ...rest
  };

  const infiniteProps = {
    list,
    hasNextPage,
    fetching: !!fetchId,
    getNextPage: () => dispatch(eventsLogActions.nextPage())
  };

  return (
    <Container className="card">
      <InfiniteList {...infiniteProps}>{getTable(tableProps)}</InfiniteList>
    </Container>
  );
};

EventsLogView.propTypes = {
  list: PropTypes.array,
  meta: PropTypes.object,
  fetchId: PropTypes.string,
  sortBy: PropTypes.string,
  sortDir: PropTypes.string,
  dispatch: PropTypes.func.isRequired
};

EventsLogView.defaultTypes = {
  list: []
};

export default connect()(EventsLogView);
