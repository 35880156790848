import { history } from 'base-client';

const toTitleCase = str =>
  str &&
  str.replace &&
  str.replace(/\w\S*/g, txt => {
    if (txt) return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });

const emptyToDash = str => str || '-';

const numberToDash = number => {
  return number !== null ? number : '-';
};

// simple function to sort array of objects
const sortByKey = (array, key) =>
  array.sort((a, b) => {
    const x = `${a[key]}`.toLowerCase();
    const y = `${b[key]}`.toLowerCase();

    return x < y ? -1 : x > y ? 1 : 0;
  });

const trimQuery = query => {
  if (typeof query !== 'string') return query;
  while (query.charAt(0) === '?') query = query.substr(1);
  return query;
};

const getlocationQuery = () => {
  const {
    location: { search: queryJson }
  } = history;

  return queryJson ? JSON.parse(decodeURIComponent(trimQuery(queryJson))) : {};
};

const roundUpNumber = val => {
  let result = val;
  let suffix = '';

  if (val / 1000000000 > 1) {
    suffix = 'b';
    result = Math.round(val / 1000000000);
  } else if (val / 1000000 > 1) {
    suffix = 'm';
    result = Math.round(val / 1000000);
  } else if (val / 1000 > 1) {
    suffix = 'k';
    result = Math.round(val / 1000);
  }
  return {
    value: result,
    suffix
  };
};

export default toTitleCase;

export { emptyToDash, numberToDash, sortByKey, toTitleCase, getlocationQuery, roundUpNumber };
