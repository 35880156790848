import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { CheckboxFilter, RadioFilter } from 'shared/Filters';
import { VISITOR_TYPE_ATTRIBUTE } from '../utils';

const Container = styled.div`
  flex: 0 0 14%;
  font-size: 1.4rem;
`;

const Header = styled.h3`
  font-size: 1.6rem;
  margin: 0 0 1.5rem;
`;

const Filters = ({ filters, selected, radioSelections, includeAnonymous, ...props }) => {
  const renderFilter = filters && filters.length > 0;

  return (
    <Container>
      <Header>Refine By</Header>
      {renderFilter &&
        filters.map(({ attribute, name, facets }) => {
          const { facets: selectedFacets } =
            selected.find(({ attribute: selectedAttribute }) => selectedAttribute === attribute) ||
            {};

          const filterProps = {
            name,
            attribute,
            showMore: {
              disabled: false
            },
            facets: facets.map(({ attribute: name, key, count }) => ({
              key,
              name,
              count,
              checked:
                attribute === VISITOR_TYPE_ATTRIBUTE
                  ? !includeAnonymous
                  : !!(selectedFacets && selectedFacets.find(facet => facet === key))
            }))
          };
          return radioSelections.includes(attribute) ? (
            <RadioFilter key={attribute} {...filterProps} {...props} disabledSelectAll={true} />
          ) : (
            <CheckboxFilter key={attribute} {...filterProps} {...props} disabledSelectAll={true} />
          );
        })}
    </Container>
  );
};

Filters.propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      attribute: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      facets: PropTypes.arrayOf(
        PropTypes.shape({
          attribute: PropTypes.string.isRequired,
          count: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        })
      )
    })
  ),
  selected: PropTypes.arrayOf(
    PropTypes.shape({
      attribute: PropTypes.string.isRequired,
      facets: PropTypes.arrayOf(PropTypes.string).isRequired
    })
  ),
  radioSelections: PropTypes.arrayOf(PropTypes.string),
  includeAnonymous: PropTypes.bool
};

Filters.defaultProps = {
  filters: [],
  selected: [],
  radioSelections: [],
  includeAnonymous: true
};

export default Filters;
