import React from 'react';
import styled from 'styled-components';

import { Column } from 'shared-features-client';

import sbManagerStyles from 'utils/globalStyles';
import { isEmpty } from '../utils';

import Address from './Address';

const StyledProjectContact = styled(Column)`
  font-size: 1.2rem;
  margin-bottom: 2rem;
`;

const Title = styled.h4`
  color: ${sbManagerStyles.greyMedium};
  text-transform: uppercase;
`;

const Company = styled.div`
  font-weight: 900;
  font-size: 2rem;
  line-height: 4rem;
`;

const Contact = styled.div`
  font-weight: 1.4rem;
`;

const Email = styled.div`
  color: ${sbManagerStyles.blueDark};
  margin-bottom: 2rem;
`;

const ContactNumber = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const Label = styled.div`
  font-weight: bold;
  width: 6rem;
`;

const ProjectContact = ({ contactInformation, type }) => {
  if (!contactInformation || isEmpty(contactInformation)) return null;
  const { companyName, contactName, email, phone, fax, address } = contactInformation;

  return (
    <StyledProjectContact mdUp={6}>
      <Title>{type}</Title>
      <Company>{companyName || null}</Company>
      <Contact>{contactName || null}</Contact>
      <Email>{email || null}</Email>
      <ContactNumber>
        <Label>Phone</Label>
        <div>{phone || null}</div>
      </ContactNumber>
      <ContactNumber>
        <Label>Fax</Label>
        <div>{fax || null}</div>
      </ContactNumber>
      <Address address={address} />
    </StyledProjectContact>
  );
};

export default ProjectContact;
