import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reducerUtil } from 'base-client';

import sbManagerStyles from 'utils/globalStyles';
import { DropdownFilter } from 'shared/Filters';
import RadioGroup from 'shared/InputGroups/RadioGroup'
import Filter from './Filter';

import { actions as analyticActions, reducerData as analyticsData } from "../../../analyticsDashboard"

const Container = styled.div`
  flex: 0 0 100%;
  font-size: 1.6rem;
  padding: 20px 15px;
  .selectLocation {
    svg {
      fill: ${() => sbManagerStyles.greyMedium};
      stroke-width: 0;
    }
  }
`;

const Header = styled.h3`
  display: flex;
  justify-content: space-between;
  font-size: 1.6rem;
  margin: 0 0 1.5rem;
  text-transform: uppercase;
  color: #77899e;
  font-weight: normal;
`;

const ToggleElm = styled.button`
  cursor: pointer;
  color: #0a7be4;
  text-transform: uppercase;
  font-size: 1rem;
  background: transparent;
  border: none;
  font-weight: 400;
  outline: none;
  padding-right: 0;
  padding-left: 0;
`;

const customStyles = {
  control: (styles, { isFocused }) => ({
    ...styles,
    minHeight: 32,
    borderColor: isFocused ? sbManagerStyles.greyLight : sbManagerStyles.greyLight
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
    ...styles,
    color: isSelected ? sbManagerStyles.greyDarkest01 : sbManagerStyles.greyDarkest,
    backgroundColor: isSelected ? sbManagerStyles.greyLight : sbManagerStyles.white,
    padding: '5px 8px'
  }),
  input: styles => ({ ...styles }),
  placeholder: styles => ({ ...styles, color: sbManagerStyles.greyMedium }),
  singleValue: (styles, { data }) => ({ ...styles }),
  menu: styles => ({
    ...styles,
    marginTop: 2,
    marginBottom: 0,
    boxShadow: 'none',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: sbManagerStyles.greyLight
  })
};

const viewOptions = [
  "bar", "pie"
]

const AnalyticsFilters = ({ changeVisualization, industries, locations, resetFilters = false, occupations, viewToggle, visualization = "bar" }) => (
  <Container className="filters">
    {viewToggle && (
      <>
        <Header>Views</Header>
        <RadioGroup options={viewOptions} selectedOption={visualization} controlFunc={changeVisualization}></RadioGroup>
        <br /><br />
      </>
    )}
    <Header>
      Filters
      {resetFilters && <ToggleElm
        type="button"
        className="toggleBtn"
        onClick={() =>
          resetFilters()
        }
      >
        Reset Filters
      </ToggleElm>}
    </Header>
    {locations && (
      <DropdownFilter {...locations} {...{ customStyles, placeholder: 'Select country...' }} />
    )}
    {industries && <Filter key={industries.attribute} {...industries} />}
    {occupations && <Filter key={occupations.attribute} {...occupations} />}
  </Container>
)


AnalyticsFilters.propTypes = {
  locations: PropTypes.object,
  industries: PropTypes.object,
  occupations: PropTypes.object,
  viewToggle: PropTypes.bool
};

AnalyticsFilters.defaultProps = {
  locations: undefined,
  industries: undefined,
  occupations: undefined,
  viewToggle: false
};

const mapStateToProps = state => ({
  visualization: reducerUtil.getSlice(analyticsData, analyticsData.visualization, state)
})

const mapDispatchToProps = dispatch => ({
  changeVisualization: e => dispatch(analyticActions.updateVisualization(e.target.value))
})

export default connect(mapStateToProps, mapDispatchToProps)(AnalyticsFilters);
