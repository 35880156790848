import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { DropdownButton, DropdownMenuItem } from 'shared/Buttons';
import { actions as searchActions } from 'productList';

const BulkControls = ({ enable, dispatch }) => (
  <DropdownButton title="Bulk Actions" disabled={!enable} id="ProductsHeaderControlsDropdownMore">
    <DropdownMenuItem eventKey="1" onClick={() => dispatch(searchActions.publishSelected())}>
      Bulk Publish
    </DropdownMenuItem>
    <DropdownMenuItem eventKey="2" onClick={() => dispatch(searchActions.unpublishSelected())}>
      Bulk Unpublish
    </DropdownMenuItem>
    {/* <DropdownMenuItem eventKey="3" onClick={() => dispatch(searchActions.downloadSelected())}>
      Bulk Download
    </DropdownMenuItem> */}
    <DropdownMenuItem eventKey="4" onClick={() => dispatch(searchActions.exportSelected())}>
      Bulk Export to CSV
    </DropdownMenuItem>
    <DropdownMenuItem eventKey="5" onClick={() => dispatch(searchActions.deleteSelected())}>
      Bulk Delete
    </DropdownMenuItem>
  </DropdownButton>
);

BulkControls.propTypes = {
  enable: PropTypes.bool,
  dispatch: PropTypes.func.isRequired
};

BulkControls.defaultProps = {
  enable: false
};

export default connect()(BulkControls);
