import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from './../SvgIcon';

const AddCircleIcon = (props) => (
  <SvgIcon {...props}>
		<path d="M12,2.001c-5.514,0-10,4.486-10,10s4.486,10,10,10s10-4.486,10-10S17.514,2.001,12,2.001z
       M12,20.001c-4.411,0-8-3.589-8-8s3.589-8,8-8s8,3.589,8,8S16.411,20.001,12,20.001z"/>
    <polygon points="13,7.001 11,7.001 11,11.001 7,11.001 7,13.001 11,13.001 11,17.001 13,17.001 13,13.001 
      17,13.001 17,11.001 13,11.001     "/>
  </SvgIcon>
)

AddCircleIcon.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  viewBox: PropTypes.string.isRequired,
};

AddCircleIcon.defaultProps = {
  width: 18,
  height: 18,
  viewBox: '0 0 24 24',
};

export default AddCircleIcon;

