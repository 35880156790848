export const parseRedirectRoutes = ({ routes }) => {
  try {
    const parsedRoutes = JSON.parse(routes);
    return parsedRoutes.filter((x) => x?.to && x?.from);
  } catch (e) {
    console.error(e);
    return [];
  }
};

export const getTokenAndServiceKeyFromLocalStorage = () => {
  const token = localStorage.getItem("token");
  const serviceKeyJsonStr = localStorage.getItem("serviceKey");
  try {
    const parsedServiceKeyObject = JSON.parse(serviceKeyJsonStr);
    const serviceKey = parsedServiceKeyObject[token];

    const isString =
      typeof serviceKey === "string" || serviceKey instanceof String;

    const isNotEmpty =
      isString && serviceKey !== "null" && serviceKey !== "undefined";

    if (isNotEmpty) {
      return { token, serviceKey };
    }

    return { token };
  } catch (e) {
    console.error(e);
    return { token };
  }
};

export const setServiceKeyAndTokenPairInLocalStorage = ({
  token,
  serviceKey,
}) => {
  localStorage.setItem("serviceKey", JSON.stringify({ [token]: serviceKey }));
};

export const removeServiceKeyAndTokenPairInLocalStorage = () => {
  localStorage.removeItem("serviceKey");
};
