import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../SvgIcon';

const Management = (props) => (
  <SvgIcon {...props} >
    <path id="shadow" className="color--light" d="M1 18C0.4 18 0 18.4167 0 19.0417V21.9583C0 22.5833 0.4 23 1 23H23C23.6 23 24 22.5833 24 21.9583V19.0417C24 18.4167 23.6 18 23 18H14V19.0417L13 20H3L2 19.0417V18H1Z"/>
    <g className="icon--active">
    <path d="M1,1V11H23V1ZM13,7H4V5h9Zm3,0a1,1,0,1,1,1-1A1,1,0,0,1,16,7Zm3,0a1,1,0,1,1,1-1A1,1,0,0,1,19,7Z"/><path d="M22.69,18.37l1.14-1-1-1.73-1.45.49a3.64664,3.64664,0,0,0-1.08-.63L20,14H18l-.3,1.49a3.64645,3.64645,0,0,0-1.08.63l-1.45-.49-1,1.73,1.14,1a3.33714,3.33714,0,0,0,0,1.26l-1.14,1,1,1.73,1.45-.49a3.64535,3.64535,0,0,0,1.08.63L18,24h2l.3-1.49a3.64645,3.64645,0,0,0,1.08-.63l1.45.49,1-1.73-1.14-1A3.38931,3.38931,0,0,0,22.69,18.37ZM19,21a2,2,0,1,1,2-2A2.00591,2.00591,0,0,1,19,21Z"/><path d="M12,19H4V17h8.2937A7.00761,7.00761,0,0,1,15.408,13H1V23H13.26A6.96194,6.96194,0,0,1,12,19Z"/>
    </g>
    <g className="icon--inactive">
    <path d="M1,1V11H23V1ZM13,7H4V5h9Zm3,0a1,1,0,1,1,1-1A1,1,0,0,1,16,7Zm3,0a1,1,0,1,1,1-1A1,1,0,0,1,19,7Z"/><path d="M22.69,18.37l1.14-1-1-1.73-1.45.49a3.64664,3.64664,0,0,0-1.08-.63L20,14H18l-.3,1.49a3.64645,3.64645,0,0,0-1.08.63l-1.45-.49-1,1.73,1.14,1a3.33714,3.33714,0,0,0,0,1.26l-1.14,1,1,1.73,1.45-.49a3.64535,3.64535,0,0,0,1.08.63L18,24h2l.3-1.49a3.64645,3.64645,0,0,0,1.08-.63l1.45.49,1-1.73-1.14-1A3.38931,3.38931,0,0,0,22.69,18.37ZM19,21a2,2,0,1,1,2-2A2.00591,2.00591,0,0,1,19,21Z"/><path d="M12,19H4V17h8.2937A7.00761,7.00761,0,0,1,15.408,13H1V23H13.26A6.96194,6.96194,0,0,1,12,19Z"/>
    </g>
  </SvgIcon>
)

Management.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

Management.defaultProps = {
  width: 22,
  height: 22
};


export default Management;
