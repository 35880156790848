import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../SvgIcon';

const TabsGroupBaseIcon = (props) => (
  <SvgIcon {...props}>
  </SvgIcon>
)

TabsGroupBaseIcon.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  viewBox: PropTypes.string.isRequired,
};

TabsGroupBaseIcon.defaultProps = {
  width: 34,
  height: 34,
  viewBox: '0 0 34 34',
};

export default TabsGroupBaseIcon;
