/* vendor imports */
import React from 'react';
import PropTypes from 'prop-types';

/* system imports */
import Field from '../components';

class Container extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.getData = this.getData.bind(this);
  }
  UNSAFE_componentWillMount() {
    this.getData(this.props);
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.getData(nextProps);
  }
  getData(props) {
    const {
      pristine,
      setDirty,
      changeType,
      selectedAttributeType,
      revitOption,
      discipline,
      revitType,
      distributionType,
      attributeType
    } = props;
    const attributeTypes = attributeType.map(item => ({
      key: item.id,
      display: item.name
    }));
    let checkType = selectedAttributeType;
    if (checkType === 'assetselection' || checkType === 'assetlink') checkType = 'url';
    if (checkType === 'datetime') checkType = 'text';
    const disciplines = [];
    distributionType.forEach(item => {
      const typeDiscipline = item.distributionData.discipline;
      if (
        item.attributeType_id === checkType &&
        !disciplines.find(disipline => disipline.key === typeDiscipline)
      ) {
        disciplines.push({ key: typeDiscipline, display: typeDiscipline });
      }
    });
    const checkDiscipline = discipline || (disciplines[0] && disciplines[0].key) || 'common';
    const revitTypes = distributionType
      .filter(item => {
        return item.distributionData.discipline.toLowerCase() === checkDiscipline.toLowerCase();
      })
      .map(item => ({ key: item.id, display: item.name }));
    disciplines.sort((a, b) => a.display.localeCompare(b.display));
    revitTypes.sort((a, b) => a.display.localeCompare(b.display));
    this.setState({ attributeTypes, disciplines, revitTypes });
    if (revitOption && !revitTypes.find(item => item.key === revitType)) {
      changeType(revitTypes[0].key);
      setDirty(true);
    } else setDirty(!pristine);
  }
  render() {
    const { handleSubmit, sharedParameter, ...props } = this.props;
    const { attributeTypes, ...distributionProps } = this.state;
    return (
      <form onSubmit={handleSubmit}>
        <Field.Name {...props} />
        {sharedParameter && <Field.SharedParameterGuid {...props} />}
        <Field.Group {...props} />
        <Field.Type {...props} {...{ attributeTypes }} />
        {/* <Field.Rules {...props} /> */}
        <Field.Distribution {...props} {...distributionProps} />
      </form>
    );
  }
}

Container.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  changeType: PropTypes.func.isRequired,
  setDirty: PropTypes.func.isRequired,
  selectedAttributeType: PropTypes.string,
  distributionType: PropTypes.arrayOf({
    name: PropTypes.string.isRequired,
    distributionData: PropTypes.shape({
      discipline: PropTypes.string
    }),
    attributeTypeKey: PropTypes.string.isRequired,
    distributionAttributeType_id: PropTypes.string.isRequired
  }),
  attributeType: PropTypes.arrayOf({
    name: PropTypes.string.isRequired,
    attributeTypeKey: PropTypes.string.isRequired
  }),
  discipline: PropTypes.string,
  revitType: PropTypes.string,
  revitOption: PropTypes.bool,
  pristine: PropTypes.bool,
  sharedParameter: PropTypes.string
};

Container.defaultProps = {
  selectedAttributeType: null,
  distributionType: [],
  attributeType: [],
  discipline: null,
  revitType: null,
  revitOption: false,
  pristine: true,
  sharedParameter: null
};

export default Container;
