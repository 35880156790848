import { connect } from 'react-redux';
import { history } from 'base-client';

import NavigationToParentView from '../components/NavigationToParentView';

const mapDispatchToProps = dispatch => ({
  push: url => history.push(url)
});

export default connect(
  null,
  mapDispatchToProps
)(NavigationToParentView);
