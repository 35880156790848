import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { LinkButton } from 'shared/Buttons';
import LeadScore from './LeadScore';

import BlankAvatar from '../assets/blank_avatar.png';

const LeadCard = ({ user, showScore }) => {
  const {
    url,
    image,
    name,
    email,
    phone,
    leadStage,
    industry,
    occupation,
    company,
    city,
    state,
    country
  } = user || {};

  const CardContainer = styled(LinkButton)`
    background: white;
    border-radius: 2px;
    height: 100%;
    width: 100%;
    box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.05), 0px 2px 6px rgba(0, 0, 0, 0.1);
    ${props => (props.useLink ? 'cursor: pointer;' : 'cursor: default;')}
    overflow: hidden;
    & > .checkbox {
      position: absolute;
      z-index: $z-top;
      top: 1.5rem;
      left: 2.5rem;
      right: 0;
    }
    margin-top: 2rem;
  `;
  const UserContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  `;

  const StyledUserImage = styled.img`
    width: 7rem;
    height: 7rem;
    border-radius: 100px;
  `;

  const UserImageContainer = styled.div`
    margin-top: 2rem;
    margin-bottom: 1.5rem;
    margin-right: auto;
    margin-left: auto;
  `;
  const UserName = styled.div`
    color: #343e47;
    min-height: 5%;
    min-height: 2rem;
    font-size: 2rem;
    font-weight: 600;
    text-align: center;
  `;
  const UserOccupation = styled.div`
    min-height: 7%;
    font-size: 1.4rem;
    color: #343e47;
    font-weight: 600;
    text-align: center;
  `;
  const UserCompany = styled.div`
    min-height: 5%;
    font-size: 1.2rem;
    color: #343e47;
    text-align: center;
    font-weight: 400;
    margin-bottom: 3%;
  `;
  const UserLocationIndustry = styled.div`
    min-height: 5%;
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 3%;
  `;
  const LocationIndustrySeperator = styled.div`
    border-right: 1px solid #77899e;
    border-left: 1px solid #77899e;
  `;
  const UserIndustry = styled.div`
    min-height: 5%;
    font-size: 1.2rem;
    color: #77899e;
    margin-left: 0.4rem;
    text-align: left;
    width: 50%;
  `;
  const UserLocation = styled.div`
    min-height: 5%;
    font-size: 1.2rem;
    color: #77899e;
    margin-right: 0.4rem;
    text-align: right;
    width: 50%;
  `;
  const UserEmail = styled.div`
    min-height: 5%;
    color: #1385f0;
    font-size: 1.2rem;
    text-align: center;
  `;
  const UserPhone = styled.div`
    min-height: 5%;
    font-size: 1.2rem;
    color: #77899e;
    text-align: center;
    margin-bottom: 2%;
  `;

  const fallbackImage = () => {
    setRenderImage(BlankAvatar);
  };

  const [renderImage, setRenderImage] = useState(image || BlankAvatar);
  useEffect(() => {
    if (!renderImage) {
      setRenderImage(image || BlankAvatar);
    }
  }, []);

  return (
    <CardContainer
      to={url || '#'}
      useLink={!!url}
      onClick={e => (!!url ? null : e.preventDefault())}
    >
      <UserContainer>
        <UserImageContainer>
          <StyledUserImage src={renderImage} onError={fallbackImage} alt={name} />
        </UserImageContainer>
        <UserName>{name}</UserName>
        <UserOccupation>{occupation}</UserOccupation>
        <UserCompany>{company}</UserCompany>
        <UserLocationIndustry>
          <UserLocation>{city || country || state}</UserLocation>
          <LocationIndustrySeperator />
          <UserIndustry>{industry}</UserIndustry>
        </UserLocationIndustry>
        <UserEmail>{email}</UserEmail>
        <UserPhone>{phone}</UserPhone>
        {showScore && <LeadScore stage={leadStage} />}
      </UserContainer>
    </CardContainer>
  );
};

export default connect()(LeadCard);
