import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from './../SvgIcon';

const CloseCircleIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M 10 -7.24792e-08C 4.486 -7.24792e-08 0 4.486 0 10C 0 15.514 4.486 20 10 20C 15.514 20 20 15.514 20 10C 20 4.486 15.514 -7.24792e-08 10 -7.24792e-08ZM 14.707 13.293L 13.293 14.707L 10 11.414L 6.707 14.707L 5.293 13.293L 8.586 10L 5.293 6.707L 6.707 5.293L 10 8.586L 13.293 5.293L 14.707 6.707L 11.414 10L 14.707 13.293Z" />
  </SvgIcon>
)

CloseCircleIcon.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  viewBox: PropTypes.string.isRequired,
};

CloseCircleIcon.defaultProps = {
  width: 21,
  height: 20,
  viewBox: '0 0 20 21',
};

export default CloseCircleIcon;